import React from 'react';
import ErrorComponent from './ErrorComponent';
import { ErrorComponentProps } from './type';
import { WithChildren } from '90.quickConnect.Models/siteAdmin/models/common/withChildren';

class ErrorBoundary extends React.PureComponent<WithChildren, ErrorComponentProps> {
  constructor(props: WithChildren) {
    super(props);
    this.state = {
      error: '',
    };
  }

  componentDidCatch(error: Error): void {
    this.setState({
      error: error.toString(),
    });
  }

  render(): React.ReactNode {
    const { error } = this.state;
    const { children } = this.props;
    if (error) {
      // You can render any custom fallback UI
      return <ErrorComponent error={error} />;
    }
    return children;
  }
}

export { default as ErrorComponent } from './ErrorComponent';
export default ErrorBoundary;
