import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, IconButton, Stack, Typography, useTheme } from '@mui/material';
import PrintOutlinedIcon from '@mui/icons-material/PrintOutlined';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import { OrganizationToTreeViewNodes } from '../../Users/Forms/hooks';
import useData from './hooks';
import ResDocuments from './documents';
import ResCharacList from './characteristic';
import CharacDialog from './CharacDialog';
import { EmailContext } from './context';
import {
  ActionButtonsContainer,
  CardContainerStyle,
  downBoxStyle,
  upContainerStyle,
  InputBoxStyle,
  typographyStyles,
  upBoxStyle,
  innerBoxStyle,
} from './styles';
import ContentHeader from '70.quickConnect.Components/siteAdmin/Other/ContentHeader';
import RessourceIcon from '00.app/icons/RessourceIcon';
import MenuWrapper from '10.quickConnect.app/siteAdmin/components/shared/MenuWrapper';
import QCTextInput from '70.quickConnect.Components/siteAdmin/Inputs/QCTextInput';
import QCTreeViewAutocomplete from '70.quickConnect.Components/siteAdmin/Inputs/QCTreeViewAutocomplete';
import { TreeViewNode } from '70.quickConnect.Components/siteAdmin/Other/TreeView/types';
import QCButtonGlobal from '70.quickConnect.Components/siteAdmin/Buttons/QCButtonGlobal';
import QCIconButton from '70.quickConnect.Components/siteAdmin/Buttons/QCIconButton';
import QCFabCorbeille from '70.quickConnect.Components/siteAdmin/Buttons/QCFabCorbeille';
import SaveConfirmationDialog from '70.quickConnect.Components/siteAdmin/Other/SaveConfirmationDialog';
import DeleteConfirmationDialog from '70.quickConnect.Components/siteAdmin/Other/DeleteConfirmationDialog';

const ResourceSettings = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const {
    resSettingsData,
    updateResourceData,
    setUpdateResourceData,
    handleDelete,
    handleSave,
    handleDownload,
    saveDispatch,
    openSaveDialog,
    deleteDispatch,
    openDeleteDialog,
    handleChangeResName,
    handleChangeResQcTagCode,
    handleChangeResOrg,
    handleChangeResId,
    handlePrint,
    openCharacDialog,
    setOpenCharacDialog,
    currentEmail,
    setCurrentEmail,
    emails,
    setEmails,
  } = useData();
  return (
    <MenuWrapper>
      {resSettingsData?.canSave && <SaveConfirmationDialog dispatch={saveDispatch} open={openSaveDialog} />}
      {resSettingsData?.canDelete && <DeleteConfirmationDialog dispatch={deleteDispatch} open={openDeleteDialog} />}
      <ContentHeader>
        <ContentHeader.BackButton />
        <ContentHeader.TitlesGroup>
          <ContentHeader.OverTitle>{t('entityData.editor.designInstance.title')}</ContentHeader.OverTitle>
          <ContentHeader.Title startIcon={<RessourceIcon />}>{resSettingsData?.data.title}</ContentHeader.Title>
        </ContentHeader.TitlesGroup>
      </ContentHeader>
      <Box sx={upBoxStyle}>
        <Box sx={upContainerStyle}>
          <Box sx={InputBoxStyle}>
            <QCTextInput
              editable
              value={updateResourceData ? updateResourceData.title : ''}
              label={t('entityData.entities.table.title')}
              dispatch={(value: string) => {
                handleChangeResName(value);
              }}
            />
          </Box>
          <Box sx={InputBoxStyle}>
            <QCTreeViewAutocomplete
              label={t('entityData.editor.designInstance.placeholder.organization')}
              allData={OrganizationToTreeViewNodes(resSettingsData?.availableOrganizationalUnits ?? [])}
              value={updateResourceData ? updateResourceData.organizationUnitId : ''}
              dispatch={(value: TreeViewNode) => {
                handleChangeResOrg(value);
              }}
            />
          </Box>
          <Box sx={InputBoxStyle}>
            <QCTextInput
              editable
              value={updateResourceData ? updateResourceData.code : ''}
              label={t('entityData.editor.newInstance.placeholder.code')}
              dispatch={(value: string) => {
                handleChangeResId(value);
              }}
            />
          </Box>
          <Box sx={InputBoxStyle}>
            <QCTextInput
              editable
              value={updateResourceData ? updateResourceData.qcTagCode : ''}
              label={t('entityData.entities.table.qcTagCode')}
              dispatch={(value: string) => {
                handleChangeResQcTagCode(value);
              }}
            />
          </Box>
        </Box>
        <Stack gap={2} flexBasis="24%" marginTop="40px">
          <QCButtonGlobal
            className="print-button"
            startIcon={<PrintOutlinedIcon />}
            variant="contained"
            value={t('entityData.editor.designInstance.actions.print')}
            sx={{ minWidth: '220px', width: '220px', textAlign: 'left' }}
            action={() => handlePrint()}
          />
          <QCButtonGlobal
            className="mydeclarations-button"
            startIcon={<FeedOutlinedIcon />}
            variant="outlined"
            value={t('entityData.editor.designInstance.actions.myDeclarations')}
            sx={{ width: '220px', textAlign: 'left' }}
          />
        </Stack>
      </Box>
      <Box sx={downBoxStyle}>
        <EmailContext.Provider
          value={{
            currentEmail: currentEmail,
            setCurrentEmail: setCurrentEmail,
            emails: emails,
            setEmails: setEmails,
          }}
        >
          <Box sx={CardContainerStyle(theme)}>
            <Typography gutterBottom sx={typographyStyles}>
              {t('entityData.editor.designInstance.attributeTitle')}
            </Typography>
            <IconButton sx={{ position: 'absolute', right: 5, top: 5 }} onClick={() => setOpenCharacDialog(true)}>
              <FullscreenIcon />
            </IconButton>
            <Box sx={innerBoxStyle}>
              <ResCharacList
                updateResourceData={updateResourceData}
                resSettingsData={resSettingsData}
                setUpdateResourceData={setUpdateResourceData}
              />
            </Box>
          </Box>
          <CharacDialog open={openCharacDialog} dispatch={setOpenCharacDialog}>
            <ResCharacList
              updateResourceData={updateResourceData}
              resSettingsData={resSettingsData}
              setUpdateResourceData={setUpdateResourceData}
            />
          </CharacDialog>
        </EmailContext.Provider>
        <ResDocuments documents={resSettingsData?.documents} />
      </Box>
      <Box sx={ActionButtonsContainer}>
        <QCFabCorbeille onClickFunc={handleDelete}></QCFabCorbeille>
        <QCIconButton
          Icon={<DownloadOutlinedIcon />}
          onClickFunc={handleDownload}
          variantValue="outlined"
          sx={{ color: theme.palette.primary.main }}
        />
        <QCIconButton Icon={<SaveOutlinedIcon />} onClickFunc={handleSave} variantValue="contained" />
      </Box>
    </MenuWrapper>
  );
};

export default ResourceSettings;
