import { useCallback, useMemo } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { I18nEntityType } from '../enum';
import { ExportI18nRequest, GetI18nRequest, ImportBinaryRequest, UpdateI18NEntityRequest } from './request';
import { GetI18nResponse, GetSupportedLanguagesResponse } from './responses';
import { ApiInternationalization } from '40.quickConnect.DataAcces/siteAdmin/apiRoutes/internationalization';
import QCHttpRequest from '40.quickConnect.DataAcces/siteAdmin/QCHttpRequest';
import { SupportedLanguage } from '90.quickConnect.Models/siteAdmin/models/internationalization/supportedLanguage';
import { EntitySchema } from '90.quickConnect.Models/siteAdmin/models/families/entitySchema';
import { extractFileName } from '80.quickConnect.core/siteAdmin/helpers/extractFileName';

const useTraductionServices = () => {
  const requestHandler = useMemo(() => new QCHttpRequest(), []);
  const { t } = useTranslation();
  const getSupportedLanguages = async (): Promise<SupportedLanguage[] | undefined> => {
    try {
      const response = await requestHandler.get<GetSupportedLanguagesResponse>(ApiInternationalization.getInfos);

      return Object.entries(response.data.infos.SupportedLanguages).map(([lg, val]) => ({
        lang: lg,
        value: val,
      }));
    } catch (error) {
      if (axios.isAxiosError(error)) toast.error(error.response?.data.message);
    }
  };

  const getI18n = async (request: GetI18nRequest) => {
    if (request.entityId !== '')
      try {
        const response = await requestHandler.post<GetI18nResponse, GetI18nRequest>(
          ApiInternationalization.geti18n,
          request,
        );
        return response.data.i18nEntity;
      } catch (error) {
        if (axios.isAxiosError(error)) toast.error(error.response?.data.message);
      }
  };

  const saveI18n = useCallback(
    async (request: UpdateI18NEntityRequest) => {
      try {
        const response = await requestHandler.post<EntitySchema, UpdateI18NEntityRequest>(
          ApiInternationalization.savei18n,
          request,
        );
        if (response.status === 200) toast.success(t('internationalization.edit.success'));
        return response.data;
      } catch (error) {
        if (axios.isAxiosError(error)) toast.error(error.response?.data.message);
      }
    },
    [requestHandler, t],
  );

  const exportI18n = useCallback(
    async (request: ExportI18nRequest) => {
      try {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const response = await requestHandler.post<any, ExportI18nRequest>(ApiInternationalization.exporti18n, request);
        const newBlob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), response.data], {
          type: 'text/csv;charset=utf-8',
        });
        const fileName = extractFileName(response, 'attachment');
        const data = window.URL.createObjectURL(newBlob);
        const link = document.createElement('a');
        link.href = data;
        link.download = fileName;
        // nécessaire car link.click() ne marche pas dans les dernières versions de firefox
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        setTimeout(function () {
          // Pour Firefox il est nécessaire de retarder le revoking de l'ObjectURL
          window.URL.revokeObjectURL(data);
          link.remove();
        }, 3000);
      } catch (error) {
        if (axios.isAxiosError(error)) toast.error(error.message);
      }
    },
    [requestHandler],
  );

  const importI18n = useCallback(
    async (request: ImportBinaryRequest, entityType: I18nEntityType) => {
      try {
        const url = ApiInternationalization.importi18n.replace(/:entityType/, entityType.toString());
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const response = await requestHandler.post<any, ImportBinaryRequest>(url, request, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });
        toast.success(t('internationalization.upload.import'));
        if (response.status === 200) {
          toast.success(t('plannings.popup.import.importsuccess'));
          return response.data;
        }
      } catch (error) {
        if (axios.isAxiosError(error)) toast.error(error.response?.data.message);
      }
    },
    [requestHandler, t],
  );

  return {
    getSupportedLanguages,
    getI18n,
    saveI18n,
    exportI18n,
    importI18n,
  };
};

export default useTraductionServices;
