import React, { Children, ReactNode } from 'react';
import { Box } from '@mui/material';
import { ControlsStyle } from '../style';

const Controls = ({ children }: { children: ReactNode }) => {
  return (
    <>
      {Children.map(children, (child) => (
        <Box component="span" sx={ControlsStyle}>
          {child}
        </Box>
      ))}
    </>
  );
};

export default Controls;
