import API_HOST from './apiHost';
import { ApiRoutesType } from './type';

const ApiClients: ApiRoutesType = {
  getClients: `${API_HOST}customer/getcustomers`,
  getClientSettings: `${API_HOST}customer/getcustomer/`,
  updateClientSettings: `${API_HOST}customer/updatecustomer`,
  deleteClient: `${API_HOST}customer/deletecustomer`,
  createClient: `${API_HOST}customer/createcustomer`,
};

export default ApiClients;
