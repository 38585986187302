import { SxProps, Theme } from '@mui/material';

export const TraductionCardSx = (theme: Theme): SxProps => {
  return {
    border: `2px solid ${theme.palette.secondary.main}`,
    borderRadius: '8px',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    height: 'fit-content',
    overflow: 'hidden',
  };
};

export const TraductionTabsSx = (theme: Theme): SxProps => {
  return {
    backgroundColor: theme.palette.tabsHeaderBackground.main,
    borderBottom: `2px solid ${theme.palette.secondary.main}`,
    // '.MuiTabs-flexContainer': {
    //   height: '-100px',
    // },
  };
};

export const outerBoxStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  height: 'fit-content',
  gap: 5,
};
