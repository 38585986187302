import React, { useState } from 'react';
import { Box, Breadcrumbs, Link } from '@mui/material';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import SubMenu from '../../shared/SubMenu';
import MenuWrapper from '../../shared/MenuWrapper';
import { isSubMenuRowFolder, SubMenuRow } from '../../shared/SubMenu/types';
import { OrganizationIcon } from '00.app/icons/OrganizationIcon';

// Ce composant sert uniquement à tester l'affichage du sous menu sous forme de dossiers, il est utile à aucun autre composant ou page

// eslint-disable-next-line
const TestSubMenu = () => {
  const [a, setA] = useState({ option: '', rowId: '' });
  const [rA, setRA] = useState<{ option: string; rowsId: string[] }>({ option: '', rowsId: [] });
  const [addclick, setAddclick] = useState('');
  const [ind, setInd] = useState('');
  const [vf, setVf] = useState(false);

  const [rows, setRows] = useState<SubMenuRow[]>(
    [
      'COLAS',
      'Filière annexe',
      'Siège C2S',
      'Site de maintenance de test de nom très long très très long',
      'COLAS',
      'Filière annexe',
      'Siège C2S',
      'Site de maintenance',
      'COLAS',
      'Filière annexe',
      'Siège C2S',
      'Site de maintenance',
      // 'COLAS',
      // 'Filière annexe',
      // 'Siège C2S',
      // 'Site de maintenance',
      // 'COLAS',
      // 'Filière annexe',
      // 'Siège C2S',
      // 'Site de maintenance',
    ].map((el, index) => ({
      id: `${index}sfzegrg${index}`,
      name: el,
      type: 'folder',
      startIcon: <OrganizationIcon />,
      childrenCount: 10,
    })),
  );

  const handleRowClick = (id: string) => {
    setInd(id);
    // console.log(id);
    setRows((curr) => {
      const newCurr = Array.from(curr);

      const indexToChange = newCurr.findIndex((el) => el.id === id);
      if (indexToChange !== -1) {
        const toChange = newCurr[indexToChange];
        if (isSubMenuRowFolder(toChange)) {
          toChange.childrenItems = ['Enfant 1', 'Formulaire 123', 'Exemple 2', 'Enfant 4'].map(
            (el, index): SubMenuRow => ({
              id: `${el}${index}elaetazdf${el}`,
              name: el,
              type: 'folder',
              // startIcon: <OrganizationIcon />,
              childrenCount: 0,
              // childrenItems:
            }),
          );
        }
        newCurr[indexToChange] = toChange;
      } else {
        // Si l'id est un enfant
        newCurr.forEach((el) => {
          if (isSubMenuRowFolder(el) && el.childrenItems) {
            el.childrenItems.forEach((eli) => {
              if (eli.id === id && isSubMenuRowFolder(eli)) {
                eli.childrenItems = ['Enfant 1', 'Formulaire 123', 'Exemple 2', 'Enfant 4'].map((elIt, index) => ({
                  id: `${elIt}${index}elItnc`,
                  name: elIt,
                  type: 'item',
                  startIcon: <InsertDriveFileOutlinedIcon />,
                  childrenCount: 0,
                }));
              } else {
                // if (isSubMenuRowFolder(eli) && eli.childrenItems) {
                //   eli.childrenItems.forEach((rowel) => {
                //     if (rowel.id === id && isSubMenuRowFolder(rowel)) {
                //       rowel.childrenItems = ['Enfant 1', 'Formulaire 123', 'Exemple 2', 'Enfant 4'].map(
                //         (ri, index) => ({
                //           id: `${ri}${index}nqoo0982rowel`,
                //           name: ri,
                //           type: 'folder',
                //           startIcon: <InsertDriveFileOutlinedIcon />,
                //           childrenCount: 0,
                //         }),
                //       );
                //     } else {
                //       if (isSubMenuRowFolder(rowel) && rowel.childrenItems) {
                //         rowel.childrenItems.forEach((roweli) => {
                //           if (roweli.id === id && isSubMenuRowFolder(roweli)) {
                //             roweli.childrenItems = ['Enfant 1', 'Formulaire 123', 'Exemple 2', 'Enfant 4'].map(
                //               (ri2, index) => ({
                //                 id: `${ri2}${index}roweli$ùcwsnc`,
                //                 name: ri2,
                //                 type: 'folder',
                //                 startIcon: <InsertDriveFileOutlinedIcon />,
                //                 childrenCount: 0,
                //               }),
                //             );
                //           } else {
                //             if (isSubMenuRowFolder(roweli) && roweli.childrenItems) {
                //               roweli.childrenItems.forEach((rowel2) => {
                //                 if (rowel2.id === id && isSubMenuRowFolder(rowel2)) {
                //                   rowel2.childrenItems = ['Enfant 1', 'Formulaire 123', 'Exemple 2', 'Enfant 4'].map(
                //                     (ri3, index) => ({
                //                       id: `${ri3}${index}nqazuteaztwooaisnc`,
                //                       name: ri3,
                //                       type: 'folder',
                //                       startIcon: <InsertDriveFileOutlinedIcon />,
                //                       childrenCount: 0,
                //                     }),
                //                   );
                //                 } else {
                //                   if (isSubMenuRowFolder(rowel2) && rowel2.childrenItems) {
                //                     rowel2.childrenItems.forEach((roweli4) => {
                //                       if (roweli4.id === id && isSubMenuRowFolder(roweli4)) {
                //                         roweli4.childrenItems = [
                //                           'Enfant 1',
                //                           'Formulaire 123',
                //                           'Exemple 2',
                //                           'Enfant 4',
                //                         ].map((ri4, index) => ({
                //                           id: `${ri4}${index}nqooaikhqqiosnchuaoawnc`,
                //                           name: ri4,
                //                           type: 'folder',
                //                           startIcon: <InsertDriveFileOutlinedIcon />,
                //                           childrenCount: 10,
                //                         }));
                //                       } else {
                //                         if (isSubMenuRowFolder(roweli4) && roweli4.childrenItems) {
                //                           roweli4.childrenItems.forEach((rowel5) => {
                //                             if (rowel5.id === id && isSubMenuRowFolder(rowel5)) {
                //                               rowel5.childrenItems = [
                //                                 'Enfant 1',
                //                                 'Formulaire 123',
                //                                 'Exemple 2',
                //                                 'Enfant 4',
                //                               ].map((ri6, index) => ({
                //                                 id: `${ri6}${index}nqqada<hooaisnc`,
                //                                 name: ri6,
                //                                 type: 'folder',
                //                                 startIcon: <InsertDriveFileOutlinedIcon />,
                //                                 childrenCount: 0,
                //                               }));
                //                             } else {
                //                               if (isSubMenuRowFolder(rowel5) && rowel5.childrenItems) {
                //                                 rowel5.childrenItems.forEach((roweli6) => {
                //                                   if (roweli6.id === id && isSubMenuRowFolder(roweli6)) {
                //                                     roweli6.childrenItems = [
                //                                       'Enfant 1',
                //                                       'Formulaire 123',
                //                                       'Exemple 2',
                //                                       'Enfant 4',
                //                                     ].map((ri7, index) => ({
                //                                       id: `${ri7}${index}nqooairi7qsdqscwsnc`,
                //                                       name: ri7,
                //                                       type: 'folder',
                //                                       startIcon: <InsertDriveFileOutlinedIcon />,
                //                                       childrenCount: 0,
                //                                     }));
                //                                   } else {
                //                                     if (isSubMenuRowFolder(roweli6) && roweli6.childrenItems) {
                //                                       roweli6.childrenItems.forEach((rowel7) => {
                //                                         if (rowel7.id === id && isSubMenuRowFolder(rowel7)) {
                //                                           rowel7.childrenItems = [
                //                                             'Enfant 1',
                //                                             'Formulaire 123',
                //                                             'Exemple 2',
                //                                             'Enfant 4',
                //                                           ].map((ri8, index) => ({
                //                                             id: `${ri8}${index}nri8aqazuteaztwooaisnc`,
                //                                             name: ri8,
                //                                             type: 'folder',
                //                                             startIcon: <InsertDriveFileOutlinedIcon />,
                //                                             childrenCount: 0,
                //                                           }));
                //                                         } else {
                //                                           if (isSubMenuRowFolder(rowel7) && rowel7.childrenItems) {
                //                                             rowel7.childrenItems.forEach((roweli8) => {
                //                                               if (roweli8.id === id && isSubMenuRowFolder(roweli8)) {
                //                                                 roweli8.childrenItems = [
                //                                                   'Enfant 1',
                //                                                   'Formulaire 123',
                //                                                   'Exemple 2',
                //                                                   'Enfant 4',
                //                                                 ].map((ri9, index) => ({
                //                                                   id: `${ri9}${index}nqooaikhqri9qiosnchuaoawnc`,
                //                                                   name: ri9,
                //                                                   type: 'form',
                //                                                   startIcon: <InsertDriveFileOutlinedIcon />,
                //                                                   childrenCount: 10,
                //                                                 }));
                //                                               }
                //                                             });
                //                                           }
                //                                         }
                //                                       });
                //                                     }
                //                                   }
                //                                 });
                //                               }
                //                             }
                //                           });
                //                         }
                //                       }
                //                     });
                //                   }
                //                 }
                //               });
                //             }
                // }
                // });
                // }
                // }
                // });
                // }
              }
            });
          }
        });
      }
      return newCurr;
    });
  };

  return (
    <MenuWrapper>
      <Box display="flex" marginLeft="1.5vw">
        <SubMenu
          // getSearchValue={setAddclick}
          onRowClicked={handleRowClick}
          ennableFolders
          getIsViewFolder={setVf}
          rows={rows}
          rowActions={[{ text: 'Supprimer' }, { text: 'Modifier' }]}
          getSelectedRowAction={setA}
          sortingTypes={['Alphabétique', 'Type', 'Favoris']}
          multipleActions={['Supprimer', 'Imprimer'].map((el) => ({ text: el }))}
          getSelectedMultipleAction={setRA}
          handleAddClick={() => {
            setAddclick('clic');
            alert('test');
          }}
          addIcon={<OrganizationIcon />}
          addText="creer une organisation"
          path={
            <Breadcrumbs>
              <Link underline="none" color="inherit">
                Utilisateurs
              </Link>
              <Link underline="none" color="inherit">
                Siège C2S
              </Link>
            </Breadcrumbs>
          }
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', width: '100%' }}>
          {/* <Header /> */}
          <Box sx={{ position: 'relative', right: 5 }}>
            {a.option} {a.rowId} <br />
            {vf.toString()} <br />
            {rA.option} {rA.rowsId.map((r) => r)} <br />
            {addclick} <br />
            {ind}
          </Box>
        </Box>
      </Box>
    </MenuWrapper>
  );
};

export default TestSubMenu;
