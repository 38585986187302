import { GetCompagnonsResponse, GetVersionsResponse } from './services/responses';

export const defautVersionList: GetVersionsResponse = {
  parameters: [],
  count: 0,
  totalCount: 0,
  errorCode: null,
  isThereMoreItems: false,
  message: null,
  researchToken: null,
  status: 0,
};

export const defautCompagnonList: GetCompagnonsResponse = {
  data: [],
  count: 0,
  totalCount: 0,
  errorCode: null,
  isThereMoreItems: false,
  message: null,
  researchToken: null,
  status: 0,
};
