import { Collapse, Dialog, Step, StepContent, Stepper } from '@mui/material';
import React from 'react';
import { QCDialogFormContext } from './context';
import QCStepFooter from './StepFooter';
import QCStepHeader from './StepHeader';
import { QCDialogFormProps } from './types';
import { dialogPaperStyle, stepContentStyle } from './style';
import QCStepBody from './StepBody';
import QCDialogFormText from './Components/QCDialogFormText';
import QCDialogFormTextArea from './Components/QCDialogFormTextArea';
import QCDialogFormDate from './Components/QCDialogFormDate';
import QCDialogFormAutocomplete from './Components/QCDialogFormAutocomplete';
import QCDialogFormPassword from './Components/QCDialogFormPassword';
import QCDialogFormCheckButton from './Components/QCDialogFormCheckButton';
import useData from './hooks';
import QCDialogFormComponent from './Components/QCDialogFormComponent';
import QCDialogFormPhoto from './Components/QCDialogFormPhoto';
import QCDialogFormTreeViewAutocomplete from './Components/QCDialogFormTreeViewAutocomplete';
import QCDialogFormSelect from './Components/QCDIalogFormSelect';
import QCDialogFormBoolRadio from './Components/QCDialogFormBoolRadio';
import QCDialogFormDocument from './Components/QCDialogFormDocument';
import QCDialogFormNotification from './Components/QCDialogFormNotification';

/**
 * Composant permettant de générer une dialog contenant plusieurs champs à remplir et pouvant être décomposer en différentes étapes
 *
 * @function
 * @param {boolean} open State permettant de décrire si la dialog est ouverte ou non
 * @param {action} handleConfirm Fonction permettant le traitement des données récupérées dans la dialog lors de sa complétion
 * @param {action} toggleIsOpen Fonction permettant d'actualiser l'ouverture de la dialog
 * @param {number} columns Nombre de colonnes sur lesquels vont s'étendre les champs de la dialog
 * @param {React.ReactNode} children Champs composants la dialog, ils sont appelé grace au compoundComponents pattern par <DialogForm.Composant />
 * 
 * @example
 * // return Une dialog form contenant 3 étapes
 * <QCDialogForm open={true} handleConfirm={setData}>
      <QCDialogForm.Body
        title="Step 1"
        desc="Description de la step 1"
        index={1}
        displayStepping={true}
        nbColumn={1}
      >
        <QCDialogForm.Text label="Nom" />
        <QCDialogForm.Text label="Prénom" mandatory={true} />
      </QCDialogForm.Body>
      <QCDialogForm.Body
        title="Step 2"
        desc="Description de la step 2"
        index={2}
        displayStepping={true}
        nbColumn={2}
      >
        <QCDialogForm.Text label="Taille" />
        <QCDialogForm.Text label="Age" />
        <QCDialogForm.Choice label="Genre" mandatory={true} options={['Masculin', 'Féminin']} />
        <QCDialogForm.Date label="Date" mandatory={true} maxDate={'-3y'} minDate={'+3y'} />
      </QCDialogForm.Body>
      <QCDialogForm.Body desc="Description de la step 3" displayStepping={true} index={3} nbColumn={1}>
        <QCDialogForm.TextArea label="Histoire" mandatory={true} />
      </QCDialogForm.Body>
    </QCDialogForm>
 */

const QCDialogForm = ({
  open,
  handleConfirm,
  children,
  toggleIsOpen,
  columns = [1, 1, 1, 1, 1],
}: QCDialogFormProps) => {
  const {
    stepProps,
    activeStep,
    nextStep,
    prevStep,
    allDataArray,
    setAllData,
    addFieldAllDataArray,
    updateAllDataArray,
    setActiveStep,
    resetAllDataArray,
    deleteFieldAllDataArray,
  } = useData();

  if (Array.isArray(children)) {
    return (
      <QCDialogFormContext.Provider
        value={{
          activeStep: activeStep,
          nextStep: nextStep,
          prevStep: prevStep,
          setActiveStep: setActiveStep,
          steps: Array.isArray(children) ? children.length : 1,
          toogleIsOpen: toggleIsOpen,
          handleConfirm: handleConfirm,
          allData: allDataArray,
          setAllData: setAllData,
          addFieldAllDataArray: addFieldAllDataArray,
          updateAllDataArray: updateAllDataArray,
          resetAllDataArray: resetAllDataArray,
          deleteFieldAllDataArray: deleteFieldAllDataArray,
        }}
      >
        <Dialog
          open={open}
          PaperProps={{
            sx: dialogPaperStyle(columns[activeStep]),
          }}
        >
          <Stepper
            activeStep={activeStep}
            connector={null}
            orientation="vertical"
            sx={{ height: '100%', width: '100%' }}
          >
            {children.map((child, index) => {
              return (
                <Step key={index} active={index === activeStep}>
                  <StepContent sx={stepContentStyle} TransitionComponent={Collapse}>
                    {child}
                  </StepContent>
                </Step>
              );
            })}
          </Stepper>
        </Dialog>
      </QCDialogFormContext.Provider>
    );
  } else {
    return (
      <QCDialogFormContext.Provider
        value={{
          activeStep: activeStep,
          nextStep: nextStep,
          prevStep: prevStep,
          setActiveStep: setActiveStep,
          steps: Array.isArray(children) ? children.length : 1,
          toogleIsOpen: toggleIsOpen,
          handleConfirm: handleConfirm,
          allData: allDataArray,
          setAllData: setAllData,
          addFieldAllDataArray: addFieldAllDataArray,
          updateAllDataArray: updateAllDataArray,
          resetAllDataArray: resetAllDataArray,
          deleteFieldAllDataArray: deleteFieldAllDataArray,
        }}
      >
        <Dialog
          open={open}
          PaperProps={{
            sx: dialogPaperStyle(columns[activeStep]),
          }}
        >
          <Stepper activeStep={activeStep} connector={null} orientation="vertical" sx={{ height: '100%' }}>
            <Step {...stepProps}>
              <StepContent sx={stepContentStyle}>{children}</StepContent>
            </Step>
          </Stepper>
        </Dialog>
      </QCDialogFormContext.Provider>
    );
  }
};

QCDialogForm.Header = QCStepHeader;
QCDialogForm.Footer = QCStepFooter;
QCDialogForm.Body = QCStepBody;
QCDialogForm.Text = QCDialogFormText;
QCDialogForm.TextArea = QCDialogFormTextArea;
QCDialogForm.Date = QCDialogFormDate;
QCDialogForm.Choice = QCDialogFormAutocomplete;
QCDialogForm.TreeViewChoice = QCDialogFormTreeViewAutocomplete;
QCDialogForm.Password = QCDialogFormPassword;
QCDialogForm.Component = QCDialogFormComponent;
QCDialogForm.Photo = QCDialogFormPhoto;
QCDialogForm.CheckButton = QCDialogFormCheckButton;
QCDialogForm.Select = QCDialogFormSelect;
QCDialogForm.BoolRadio = QCDialogFormBoolRadio;
QCDialogForm.Documents = QCDialogFormDocument;
QCDialogForm.Notifications = QCDialogFormNotification;

export default QCDialogForm;
