import React from 'react';
import { SvgIcon, useTheme } from '@mui/material';
import { ReactComponent as Circle } from './Circle.svg';
import useIsMobile from '80.quickConnect.core/shared/hooks/useIsMobile';
import { dippedGreen900 } from '00.app/theme/colors';

export const CircleIcon = () => {
  const isMobile = useIsMobile();
  const {
    palette: { mode: muiMode },
  } = useTheme();
  return (
    <>
      {isMobile ? (
        <></>
      ) : (
        <SvgIcon
          sx={{
            fontSize: 565,
            height: 345,
            position: 'absolute',
            zIndex: -1,
            color: muiMode === 'light' ? '#E3F2F0' : dippedGreen900,
          }}
          component={Circle}
          inheritViewBox
        />
      )}
    </>
  );
};
