import React from 'react';
import { useTranslation } from 'react-i18next';
import { OrganizationToTreeViewNodes } from '../Forms/hooks';
import { AddUserFormProps } from './types';
import { useData } from './hooks';
import QCDialogForm from '70.quickConnect.Components/siteAdmin/Other/QCDialogForm';
import useControls from '90.quickConnect.Models/siteAdmin/inputValidator/controls';

const AddUserForm = ({ open, handleConfirm, toggleIsOpen }: AddUserFormProps) => {
  const { t } = useTranslation();
  const { isAzureAD, optionsAuthMethod, optionsOrgs, handleChangeAuthMethod } = useData(open);

  return (
    <QCDialogForm open={open} handleConfirm={handleConfirm} toggleIsOpen={toggleIsOpen}>
      <QCDialogForm.Body
        title={t('users.designer.title')}
        desc="Informations techniques"
        index={1}
        nbColumn={1}
        displayStepping
      >
        <QCDialogForm.Choice
          label={t('users.designer.form.placeholder.methodAuth')}
          options={optionsAuthMethod.map((option) => option.name)}
          dispatch={(value: string) => handleChangeAuthMethod(value)}
          mandatory
        />
        <QCDialogForm.TreeViewChoice
          label={t('users.users.table.organization')}
          mandatory
          allData={OrganizationToTreeViewNodes(optionsOrgs)}
        />
        <QCDialogForm.Text label={t('users.designer.form.placeholder.login')} mandatory control={useControls().login} />
        <QCDialogForm.Password
          label={t('users.designer.form.placeholder.password')}
          mandatory
          hidden={isAzureAD}
          control={useControls().password}
        />
        <QCDialogForm.CheckButton label={t('users.designer.form.active')} />
      </QCDialogForm.Body>
      <QCDialogForm.Body
        title={t('users.designer.title')}
        desc="Informations personnells"
        index={2}
        nbColumn={2}
        displayStepping
      >
        <QCDialogForm.Text label={t('users.designer.form.placeholder.lastName')} mandatory />
        <QCDialogForm.Text label={t('users.designer.form.placeholder.firstName')} mandatory />
        <QCDialogForm.Text label={t('users.designer.form.placeholder.email')} mandatory control={useControls().email} />
        <QCDialogForm.Text label={t('users.designer.form.placeholder.phone')} mandatory control={useControls().phone} />
        <QCDialogForm.Text label={t('users.designer.form.placeholder.job')} />
      </QCDialogForm.Body>
    </QCDialogForm>
  );
};

export default AddUserForm;
