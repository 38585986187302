import React from 'react';
import { TextField, TextFieldProps } from '@mui/material';
import { ReadOnlyTextFieldProps } from './types';

export const ReadOnlyTextField = ({
  value = 'non renseigné',
  type = 'text',
  ...props
}: ReadOnlyTextFieldProps & TextFieldProps) => {
  return (
    <TextField
      sx={{ my: 2 }}
      variant="standard"
      InputProps={{ readOnly: true }}
      type={type}
      value={value ?? 'non renseigné'}
      {...props}
      fullWidth
    />
  );
};
