import { grey3, grey6 } from '00.app/theme/colors';

export const outerBoxStyle = {
  borderLeft: `2px solid ${grey6}`,
  mb: '20px',
};

export const innerBoxStyle = {
  ml: '15px',
};

export const textStyle = {
  color: grey3,
};
