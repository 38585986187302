// Colors From BlackFoot -------------------------------------------------//

// Green
export const dippedGreen50 = '#FAFFFD';
export const dippedGreen100 = '#EAFEF8';
export const dippedGreen200 = '#BEF3E5';
export const dippedGreen300 = '#8DEBD3';
export const dippedGreen400 = '#62E4C5';
export const dippedGreen500 = '#17DCB8';
export const dippedGreen600 = '#00CAAA';
export const dippedGreen700 = '#00B59A';
export const dippedGreen800 = '#00A08A';
export const dippedGreen900 = '#007C6E';

// Brown
export const kraft50 = '#FFFDDA';
export const kraft100 = '#FFF7D4';
export const kraft200 = '#FFEECC';
export const kraft300 = '#efd6ad';
export const kraft400 = '#D9BF9E';
export const kraft500 = '#BAA181';
export const kraft600 = '#987950';
export const kraft700 = '#7A6446';
export const kraft800 = '#584529';
export const kraft900 = '#352407';

// Red
export const terraCotta50 = '#FFE6C1';
export const terraCotta100 = '#FFC2A5';
export const terraCotta200 = '#F19F83';
export const terraCotta300 = '#D17E5F';
export const terraCotta400 = '#BA6546';
export const terraCotta500 = '#A1492A';
export const terraCotta600 = '#953F26';
export const terraCotta700 = '#83311B';
export const terraCotta800 = '#752418';
export const terraCotta900 = '#651310';

// Grey
export const slateGrey50 = '#FFFFFF';
export const slateGrey100 = '#F9FFFA';
export const slateGrey200 = '#F3F9F5';
export const slateGrey300 = '#E5EAE6';
export const slateGrey400 = '#C1C6C2';
export const slateGrey500 = '#A3A8A4';
export const slateGrey600 = '#797E7A';
export const slateGrey700 = '#464B47';
export const slateGrey800 = '#464B47';
export const slateGrey900 = '#262A26';

// New Colors

// Green
export const green0 = '#F5FAF9';
export const green1 = '#E3F2F0';
export const green2 = '#C1E9E1';
export const green3 = '#91E3D4';
export const green4 = '#17DCB8';
export const green5 = '#00B090';

// Red
export const red0 = '#FCF4F1';
export const red1 = '#FCF0EC';
export const red2 = '#F5D5C9';
export const red3 = '#ECB7A4';
export const red4 = '#D17E5F';
export const red5 = '#B95935';

// Brown
export const brown0 = '#FCF7F1';
export const brown1 = '#FAF5EB';
export const brown2 = '#E6D8C3';
export const brown3 = '#CDB99B';
export const brown4 = '#B39875';
export const brown5 = '#896B45';

// Grey
export const grey0 = '#F7F8F7';
export const grey1 = '#EFF1F0';
export const grey2 = '#D1D6D2';
export const grey3 = '#A7ADA8';
export const grey4 = '#828783';
export const grey5 = '#616963';
export const grey6 = '#464B47';

export const borderLightColor = '#CDB99B';
export const textBlackColor = slateGrey800;
export const pureWhiteColor = '#fff';
export const pureWhiteColor100 = '#F9FFFA';
export const ghostWhite = '#f5f6fa';
export const blackColor = '#0b0b0b';
export const orangeRedCrayola = '#ff9800';
export const lightSkyBlue = '#90caf9';
export const circularProgressColor = '#666A66';
