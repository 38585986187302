import { SxProps } from '@mui/material';

export const mainBox: SxProps = {
  width: '40%',
  height: 'fit-parent',
  // overflow: 'auto',
};

export const textStyle: SxProps = {
  fontSize: '2em',
  fontWeight: 'bold',
  textTransform: 'capitalize',
};
