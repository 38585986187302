import { GetOrganizationalUnitListResponse, Organization, User } from './types';
import { IdentityProviders } from '90.quickConnect.Models/siteAdmin/enums/user/identityProviders';

export const defaultGetOrganizationalUnitListResponse: GetOrganizationalUnitListResponse = {
  organizationalUnits: [],
  count: 0,
  totalCount: 0,
  isThereMoreItems: false,
  researchToken: null,
  status: 0,
  message: null,
  errorCode: null,
};

export const defaultOrganization: Organization = {
  id: '',
  eTag: '',
  createdAt: new Date(),
  updatedAt: new Date(),
  name: '',
  parentId: '',
  parentCode: '',
  parentName: '',
  code: '',
  description: '',
  address: '',
  zipCode: '',
  town: '',
  country: '',
  siret: '',
  email: '',
  logo: '',
  companionQCId: '',
  correlationId: '',
  isActive: true,
  isHeadQuarter: false,
  level: 0,
};

export const defaultUser: User = {
  id: '',
  eTag: '',
  createdAt: new Date(),
  isSelectable: false,
  isSelected: false,
  userUPN: '',
  lastName: '',
  firstName: '',
  job: '',
  phone: '',
  email: '',
  isActive: false,

  profils: [],
  profilsTxt: '',
  organizationalUnit: defaultOrganization,
  organizationalUnitTxt: '',

  password: '',
  authMethod: IdentityProviders.QuickConnect,

  token: '',
  rights: 0,
  customerName: '',
};
