import React, { Suspense } from 'react';
import './App.css';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useLocation } from 'react-router-dom';
import 'react-toastify/dist/ReactToastify.css';
import { observer } from 'mobx-react';
import { useHooks } from './hooks';
import LoginSkeleton from './LoginSkeleton';
import { StyledContainer } from './styles';
import MenuSkeleton from './MenuSkeleton';
import ErrorBoundary from '10.quickConnect.app/siteAdmin/components/shared/ErrorBoundary';
import RoutesBuilder from '10.quickConnect.app/siteAdmin/routes/routesBuilder';

function App() {
  // eslint-disable-next-line no-empty-pattern
  const { theme } = useHooks();
  const { pathname } = useLocation();

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Suspense fallback={pathname !== '/' ? <MenuSkeleton /> : <LoginSkeleton />}>
        <ErrorBoundary>
          <RoutesBuilder />

          <StyledContainer position="bottom-left" />
        </ErrorBoundary>
      </Suspense>
    </ThemeProvider>
  );
}

export default observer(App);
