import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, List, ListItem, ListItemText, TextField, Typography, useTheme } from '@mui/material';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { CircleIcon } from '../../MyAccount/icons/Cercle';
import AddClientForm from '../Forms/addClient';
import { findNamesByIds } from '../helpers';
import useClientSettingsData from './hooks';
import { Limit } from './types';
import { btnBoxStyles, gridContainerStyles, gridItemStyles, textFieldStyles, typographyStyles } from './styles';
import MenuWrapper from '10.quickConnect.app/siteAdmin/components/shared/MenuWrapper';
import SaveConfirmationDialog from '70.quickConnect.Components/siteAdmin/Other/SaveConfirmationDialog';
import ContentHeader from '70.quickConnect.Components/siteAdmin/Other/ContentHeader';
import QCSwitch from '70.quickConnect.Components/siteAdmin/Inputs/QCSwitch';
import QCFabCorbeille from '70.quickConnect.Components/siteAdmin/Buttons/QCFabCorbeille';
import QCIconButton from '70.quickConnect.Components/siteAdmin/Buttons/QCIconButton';
import DeleteConfirmWithNames from '70.quickConnect.Components/siteAdmin/Other/DeleteConfirmWithNamesDialog';

const ClientSettings = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    openSaveDialog,
    setOpenSaveDialog,
    saveDispatch,
    openDeleteDialog,
    setOpenDeleteDialog,
    deleteDispatch,
    clientData,
    handleChangeFeatures,
    handleChangeLimits,
    isAddClientDialogOpen,
    setIsAddClientDialogOpen,
    handleConfirmAddClient,
    clients,
    selectedRowsMultipleAction,
  } = useClientSettingsData();

  return (
    <MenuWrapper>
      <SaveConfirmationDialog dispatch={saveDispatch} open={openSaveDialog} />
      {selectedRowsMultipleAction === undefined ? (
        <DeleteConfirmWithNames
          open={openDeleteDialog}
          dispatch={deleteDispatch}
          namesOfElementToDelete={clientData?.customer.name}
        />
      ) : (
        <DeleteConfirmWithNames
          open={openDeleteDialog}
          dispatch={deleteDispatch}
          namesOfElementToDelete={findNamesByIds(clients, selectedRowsMultipleAction?.rowsId)}
        />
      )}
      <AddClientForm
        open={isAddClientDialogOpen}
        toggleIsOpen={setIsAddClientDialogOpen}
        handleConfirm={(value) => handleConfirmAddClient(value)}
      />
      <Box sx={{ m: '2vh', position: 'relative' }}>
        <ContentHeader>
          <ContentHeader.BackButton />
          <ContentHeader.TitlesGroup>
            <ContentHeader.OverTitle>{t('customer.customers.table.name')}</ContentHeader.OverTitle>
            <ContentHeader.Title>{clientData?.customer.name}</ContentHeader.Title>
          </ContentHeader.TitlesGroup>
        </ContentHeader>
        <Box sx={{ marginLeft: '10px' }}>
          <Typography gutterBottom sx={typographyStyles}>
            {t('customer.customerDesigner.featuresTitle')}
          </Typography>
          <List>
            {clientData?.customer.availableFeatures.map((feat) => (
              <ListItem key={feat.id}>
                <QCSwitch
                  checked={clientData?.customer.enabledFeatures.includes(feat.id)}
                  onChange={(event: React.ChangeEvent, checked: boolean) => {
                    handleChangeFeatures(checked, feat);
                  }}
                />
                <ListItemText primary={feat.description} />
              </ListItem>
            ))}
          </List>

          <Typography gutterBottom sx={typographyStyles}>
            {t('customer.customerDesigner.entityLimit')}
          </Typography>
          <Box sx={gridContainerStyles}>
            {clientData?.limits.map((limit: Limit) => (
              <Box key={limit.id} sx={gridItemStyles(theme)}>
                <Typography textTransform="capitalize">{limit.name.toLocaleLowerCase()}</Typography>
                <TextField
                  variant="standard"
                  type="number"
                  value={limit.limitValue}
                  size="small"
                  InputProps={{
                    disableUnderline: true,
                  }}
                  sx={textFieldStyles}
                  onChange={(event) => {
                    handleChangeLimits(event, limit);
                  }}
                />
              </Box>
            ))}
          </Box>
          <Box sx={btnBoxStyles}>
            <QCFabCorbeille buttonType="btn-fab" onClickFunc={() => setOpenDeleteDialog(true)} />
            <QCIconButton
              className="save"
              Icon={<SaveOutlinedIcon />}
              variantValue="contained"
              onClickFunc={() => setOpenSaveDialog(true)}
            />
          </Box>
        </Box>
      </Box>
      <CircleIcon />
    </MenuWrapper>
  );
};

export default observer(ClientSettings);
