import { GetHistoryListResponse } from './types';

export const defaultGetHistoryListResponse: GetHistoryListResponse = {
  mobiles: [],
  count: 0,
  totalCount: 0,
  isThereMoreItems: false,
  researchToken: null,
  status: 0,
  message: null,
  errorCode: null,
};
