import { SxProps, Theme } from '@mui/material';
import { green1, terraCotta300 } from '00.app/theme/colors';

export const primatyTextStyles = (isSpecial: boolean | undefined, theme: Theme): SxProps => {
  return {
    fontWeight: 'bold',
    color: isSpecial ? terraCotta300 : theme.palette.textLink.main,
    textTransform: 'uppercase',
    wordBreak: 'break-word',
  };
};

export const secondaryTextStyles = (isHover: boolean, isDraft: boolean | undefined, theme: Theme): SxProps => {
  return {
    color: isDraft ? terraCotta300 : isHover ? theme.palette.itemListHover.main : theme.palette.text.secondary,
    fontStyle: 'italic',
    textTransform: 'capitalize',
  };
};

export const listItemStyles: SxProps = {
  minHeight: '50px',
  '& .MuiButtonBase-root': {
    borderRadius: '6px',
  },
  '&:hover .MuiButtonBase-root': {
    backgroundColor: green1,
    borderRadius: '6px',
  },
};

export const listItemSecondaryActionBoxStyles: SxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
};
