import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Button, Dialog, TextField, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { DeleteActionType } from '../DeleteConfirmationDialog/type';
import { elementWidth } from './const';
import { DeleteConfirmWithNamesProps } from './types';
import useData from './hooks';

const DeleteConfirmWithNames = ({
  open,
  dispatch,
  namesOfElementToDelete,
  trashAvailable = false,
}: DeleteConfirmWithNamesProps) => {
  const { t } = useTranslation();
  const { isDisabled, checkNames, handleNames } = useData(namesOfElementToDelete);

  const handleClickDelete = () => {
    dispatch({ type: DeleteActionType.Delete });
  };

  const handleClickCancel = () => {
    dispatch({ type: DeleteActionType.Cancel });
  };

  const handleClickTrash = () => {
    dispatch({ type: DeleteActionType.SendToTrash });
  };

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          width: '450px',
          height: '350px',
          backgroundColor: 'background',
          border: '2px solid #E3F2F0',
          borderRadius: '16px',
        },
      }}
    >
      <Box
        sx={{
          // width: elementWidth,
          // maxWidth: elementWidth,
          textAlign: 'center',
          marginTop: trashAvailable ? 0 : '2vh',
          marginBottom: trashAvailable ? '0.6vh' : '2vh',
          marginX: '2vh',
        }}
      >
        <h2>{t('uicomponents.deleteConfirmationDialog.Text')}</h2>
        {namesOfElementToDelete === undefined ? (
          <Typography>{t('Veuillez choisir les éléments que vous voulez supprimer')}</Typography>
        ) : (
          <Box>
            <Typography>{t('uicomponents.deleteConfirmationDialog.sousText')}</Typography>
            <Typography>{t('uicomponents.deleteConfirmationDialog.NameConfirm')}</Typography>
            <Typography sx={{ fontStyle: 'italic', fontWeight: 'bold' }}>{handleNames()}</Typography>
            <TextField variant="outlined" onChange={checkNames} />
          </Box>
        )}
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            gap: 2,
          }}
        >
          <Button
            disabled={isDisabled}
            onClick={handleClickDelete}
            variant="contained"
            sx={{
              fontWeight: 'bold',
              width: elementWidth,
              minWidth: 'fit-content',
            }}
          >
            {t('uicomponents.deleteConfirmationDialog.Delete')}
          </Button>
          {trashAvailable && (
            <Button
              onClick={handleClickTrash}
              startIcon={<DeleteIcon />}
              variant="contained"
              sx={{
                fontWeight: 'bold',
                width: elementWidth,
                minWidth: 'fit-content',
              }}
            >
              {t('bin.viewer.title')}
            </Button>
          )}
          <Button
            onClick={handleClickCancel}
            variant="outlined"
            sx={{
              width: elementWidth,
              minWidth: 'fit-content',
            }}
          >
            {t('uicomponents.deleteConfirmationDialog.Cancel')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default DeleteConfirmWithNames;
