/* eslint-disable */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { GridColDef, GridColumns, GridRowId, GridValidRowModel } from '@mui/x-data-grid';
import { Box, Button, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';

import { AxiosError } from 'axios';
import { defaultRoleListResponse } from './const';
import { GetRoleListResponse } from './services/response';
import { RoleRowActions } from './enum';
import useRoleService from './services/roles';
import useEffectOnce from '80.quickConnect.core/shared/hooks/useEffectOnce';
import QCKebabMenu from '70.quickConnect.Components/siteAdmin/Other/QCKebabMenu';
import { useDeleteDialog } from '80.quickConnect.core/siteAdmin/hooks/useDeleteDialog';
import { DeleteActionType } from '70.quickConnect.Components/siteAdmin/Other/DeleteConfirmationDialog/type';
import { SupportedLanguage } from '90.quickConnect.Models/siteAdmin/models/internationalization/supportedLanguage';
import { AllDataElementType } from '70.quickConnect.Components/siteAdmin/Other/QCDialogForm/types';
import { ChoiceMenuOptionType } from '70.quickConnect.Components/siteAdmin/Inputs/QCChoiceMenu/type';
import { element } from 'prop-types';
import Roles from '.';
import { useSafeNavigation } from '80.quickConnect.core/siteAdmin/hooks/useSafeNavigation';

const useRoleData = () => {
  const { getRoleList, deleteRole, getSupportedLanguages, createRole } = useRoleService();
  const { t } = useTranslation();
  const navigate = useSafeNavigation();

  const [roleList, setRoleList] = useState<GetRoleListResponse>(defaultRoleListResponse);
  const [initialRoleList, setInitialRoleList] = useState<GetRoleListResponse>(defaultRoleListResponse);

  const [isLoading, setIsLoading] = useState(false);

  const [pageSize, setPageSize] = useState(10);

  const [openDeleteDialog, setOpenDeleteDialog, deleteState, deleteDispatch] = useDeleteDialog();
  const [openDeleteDepDialog, setOpenDeleteDepDialog, deleteDepState, deleteDepDispatch] = useDeleteDialog();

  const [selectedMultipleActions, setSelectedMultipleActions] = useState<{
    option: string;
    rowIds: GridRowId[];
  }>({ option: RoleRowActions.NONE, rowIds: [] });

  const [openDeleteMultDialog, setOpenDeleteMultDialog, deleteMultState, deleteMultDispatch] = useDeleteDialog();
  const [openDeleteDepMultDialog, setOpenDeleteDepMultDialog, deleteDepMultState, deleteDepMultDispatch] =
    useDeleteDialog();

  const [selectedRowAction, setSelectedRowAction] = useState<{
    option: string;
    rowId: GridRowId;
  }>({ option: RoleRowActions.NONE, rowId: '' });

  const [supportedLanguages, setSupportedLanguages] = useState<Array<SupportedLanguage>>([]);

  const sortingTypes = ['alphabetique'];
  const [selectedSortingType, getSelectedSortingType] = useState(sortingTypes[0]);

  const [isAddRoleDialogOpen, setIsAddRoleDialogOpen] = useState(false);

  const theme = useTheme();

  const initRoleList = useCallback(async () => {
    setIsLoading(true);
    const result = await getRoleList();

    setRoleList(result);
    setInitialRoleList(result);
    setIsLoading(false);
  }, [setRoleList, getRoleList]);

  const initializeCols = useCallback(() => {
    const initCols: GridColumns = [
      {
        field: 'name',
        headerName: t('profil.designer.placeholder.name'),
        flex: 1,
        renderCell: (params) => (
          <Button
            variant="text"
            onClick={() => {
              navigate(`${params.id}`);
            }}
            sx={{ textTransform: 'none', color: theme.palette.text.primary }}
          >
            {params.value}
          </Button>
        ),
      },
      { field: 'desc', headerName: t('profil.profils.table.description'), flex: 1 },
    ];
    return initCols;
    // eslint-disable-next-line
  }, []);

  const [rows, setRows] = useState<GridValidRowModel[]>([]);

  useEffect(() => {
    const initRows: GridValidRowModel[] = [];
    roleList.profils.forEach((profil) => {
      initRows.push({
        id: profil.id,
        name: profil.name,
        desc: profil.description,
        isInternal: profil.isInternal,
      });
    });
    setRows(initRows);
    // eslint-disable-next-line
  }, [roleList]);

  const [cols, setCols] = useState<GridColumns>(initializeCols());

  const [searchText, setSearchText] = useState('');

  useEffectOnce(() => {
    initRoleList();
  });

  useMemo(() => {
    setRoleList({
      profils: initialRoleList.profils.filter((profil) =>
        profil.name.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()),
      ),
      errorCode: roleList.errorCode,
      message: roleList.message,
      status: roleList.status,
    });
    // eslint-disable-next-line
  }, [searchText]);

  const isBuiltins = useCallback(
    (isInternal: GridRowId) => {
      if (isInternal ?? false) {
        return true;
      }
      return false;
    },
    // eslint-disable-next-line
    [roleList],
  );

  const KebabMenuCellCustom: GridColDef = {
    field: 'kebab',
    renderCell: (params) => (
      <Box sx={{ '.MuiButtonBase-root': { padding: 0 } }}>
        <QCKebabMenu
          options={[
            {
              text: t('profil.profils.table.modify'),
              color: isBuiltins(params.row.isInternal) ? theme.palette.text.disabled : undefined,
              value: isBuiltins(params.row.isInternal) ? RoleRowActions.MODIFY_INTERNAL : RoleRowActions.MODIFY,
            },
            {
              text: t('profil.profils.table.delete'),
              color: isBuiltins(params.row.isInternal) ? theme.palette.text.disabled : undefined,
              value: isBuiltins(params.row.isInternal) ? RoleRowActions.DELETE_INTERNAL : RoleRowActions.DELETE,
            },
          ]}
          dispatch={(value: string) => {
            setSelectedRowAction({ option: value, rowId: params.id });
          }}
        />
      </Box>
    ),
    renderHeader: () => null,
    sortable: false,
    flex: 0.15,
  };

  const multipleActions: ChoiceMenuOptionType[] = [
    { text: t('profil.profils.table.delete'), value: RoleRowActions.DELETE },
  ];

  useEffect(() => {
    switch (selectedMultipleActions.option) {
      case RoleRowActions.DELETE:
        let isInternal = false;
        selectedMultipleActions.rowIds.forEach((elem) => {
          if (
            roleList.profils
              .filter((element) => element.isInternal)
              .map((elem) => elem.id)
              .includes(elem.toString())
          )
            isInternal = true;
        });
        if (isInternal) toast.error(t('profil.builtins.delete.multiple'));
        else setOpenDeleteMultDialog(true);
    }
  }, [selectedMultipleActions]);

  useEffect(() => {
    switch (selectedRowAction.option) {
      case RoleRowActions.DELETE:
        setOpenDeleteDialog(true);
        break;
      case RoleRowActions.MODIFY:
        navigate(`${selectedRowAction.rowId}`);
        break;
      case RoleRowActions.MODIFY_INTERNAL:
        toast.error(t('profil.builtins.modify'));
        break;
      case RoleRowActions.DELETE_INTERNAL:
        toast.error(t('profil.builtins.delete'));
        break;
      default:
    }
    // eslint-disable-next-line
  }, [selectedRowAction]);

  useEffect(() => {
    switch (deleteState.choice) {
      case DeleteActionType.Delete:
        const post = async () => {
          try {
            await deleteRole({ ids: [selectedRowAction.rowId.toString()], forceDeletion: false });
            await initRoleList();
          } catch (error) {
            const axiosError = error as AxiosError;
            if (axiosError.response?.status === 422) setOpenDeleteDepDialog(true);
          }
        };
        setOpenDeleteDialog(false);

        post();
        break;
      default:
        setOpenDeleteDialog(false);
    }
    // eslint-disable-next-line
  }, [deleteState]);

  useEffect(() => {
    switch (deleteDepState.choice) {
      case DeleteActionType.Delete:
        deleteRole({ ids: [selectedRowAction.rowId.toString()], forceDeletion: true });
        setOpenDeleteDepDialog(false);
        initRoleList();
        break;
      default:
        setOpenDeleteDepDialog(false);
    }
    // eslint-disable-next-line
  }, [deleteDepState]);

  useEffect(() => {
    switch (deleteMultState.choice) {
      case DeleteActionType.Delete:
        const post = async () => {
          try {
            await deleteRole({
              ids: selectedMultipleActions.rowIds as string[],
              forceDeletion: false,
            });
            await initRoleList();
          } catch (error) {
            const axiosError = error as AxiosError;
            if (axiosError.response?.status === 422) setOpenDeleteDepDialog(true);
          }
          setOpenDeleteMultDialog(false);
        };

        post();
        break;
      default:
        setOpenDeleteMultDialog(false);
    }
    // eslint-disable-next-line
  }, [deleteMultState]);

  useEffect(() => {
    switch (deleteDepMultState.choice) {
      case DeleteActionType.Delete:
        deleteRole({ ids: selectedMultipleActions.rowIds as string[], forceDeletion: true });
        setOpenDeleteDepMultDialog(false);
        initRoleList();
        break;
      default:
        setOpenDeleteDepMultDialog(false);
    }
    // eslint-disable-next-line
  }, [deleteDepMultState]);

  const initSuppLanguages = useCallback(async () => {
    const languages = await getSupportedLanguages();
    if (languages) {
      setSupportedLanguages(languages);
    }
    // eslint-disable-next-line
  }, []);

  useEffectOnce(() => {
    initSuppLanguages();
  });

  const handleConfirm = async (data: AllDataElementType[]) => {
    const defaultLanguage = data[0].data
      .find((element) => element.label === t('common.internationalization.defaultLanguage'))
      ?.value.toString();
    const description = data[0].data
      .find((element) => element.label === t('profil.designer.placeholder.description'))
      ?.value.toString();
    const name = data[0].data
      .find((element) => element.label === t('profil.designer.placeholder.name'))
      ?.value.toString();

    try {
      await createRole({
        defaultLanguage: defaultLanguage?.toLocaleLowerCase() ?? '',
        description: description ?? '',
        name: name ?? '',
      });
    } catch (error) {
      return false;
    }
    initRoleList();
    return true;
  };

  return {
    isLoading,
    initRoleList,
    cols,
    setCols,
    rows,
    setSearchText,
    pageSize,
    setPageSize,
    KebabMenuCellCustom,
    setSelectedRowAction,
    openDeleteDialog,
    setOpenDeleteDialog,
    deleteState,
    deleteDispatch,
    openDeleteDepDialog,
    setOpenDeleteDepDialog,
    deleteDepState,
    deleteDepDispatch,
    supportedLanguages,
    setSupportedLanguages,
    handleConfirm,
    isAddRoleDialogOpen,
    setIsAddRoleDialogOpen,
    selectedSortingType,
    getSelectedSortingType,
    sortingTypes,
    multipleActions,
    setSelectedMultipleActions,
    openDeleteMultDialog,
    deleteMultDispatch,
    openDeleteDepMultDialog,
    deleteDepMultDispatch,
    initialRoleList,
  };
};

export default useRoleData;
