import axios, { AxiosResponse } from 'axios';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import {
  DeleteUserRequest,
  DesignUserRequest,
  GetUserListRequest,
  ImportBinaryRequest,
  UpdateUserRequest,
  CreateUserRequest,
} from './requests';
import {
  DeleteUserResponse,
  DesignUserResponse,
  GetUserListResponse,
  ImportBinaryResponse,
  UpdateUserResponse,
  CreateUserResponse,
} from './responses';
import ApiUser from '40.quickConnect.DataAcces/siteAdmin/apiRoutes/users';
import QCHttpRequest from '40.quickConnect.DataAcces/siteAdmin/QCHttpRequest';
import { isResponseValid } from '40.quickConnect.DataAcces/siteAdmin/responseValidator';

export const useUserServices = () => {
  const requestHandler = new QCHttpRequest();

  const { t } = useTranslation();
  const defaultGetUsersRequest: GetUserListRequest = {
    start: 0,
    length: 100,
    orderBy: [{ direction: 0, column: 'name' }],
    count: 1,
    filters: [],
  };

  const defaultGetUserListResponse: GetUserListResponse = {
    users: [],
    totalCount: 0,
    count: 0,
    message: '',
    status: 500,
  };

  const getUsers = async (request: GetUserListRequest = defaultGetUsersRequest): Promise<GetUserListResponse> => {
    try {
      const response = await requestHandler.post<GetUserListResponse, GetUserListRequest>(ApiUser.getUserList, request);
      const isValid = isResponseValid(response.data);

      if (isValid) {
        // ajout de l'attribut fullName dans l'user
        response.data.users = response.data.users.map((user) => ({
          ...user,
          fullName: `${user.firstName} ${user.lastName}`,
        }));
        return response.data;
      }
    } catch (error) {
      if (axios.isAxiosError(error)) toast.error(`${error.response?.data?.message}`);
    }

    return defaultGetUserListResponse;
  };

  const getUserDesign = async (request: DesignUserRequest): Promise<DesignUserResponse | null> => {
    try {
      const response = await requestHandler.post<DesignUserResponse, DesignUserRequest>(ApiUser.designUser, request);

      const isValid = isResponseValid(response.data);

      if (isValid) {
        return response.data;
      }
    } catch (error) {
      if (axios.isAxiosError(error)) toast.error(`${error.response?.data?.message}`);
    }
    return null;
  };

  const updateUser = async (request: UpdateUserRequest) => {
    try {
      const response = await requestHandler.post<UpdateUserResponse, UpdateUserRequest>(ApiUser.updateUser, request);

      const isValid = isResponseValid(response.data, t('userSettings.designer.popup.userUpdate.success'), t);

      if (isValid) {
        return response.data;
      }
    } catch (error) {
      if (axios.isAxiosError(error)) toast.error(`${error.response?.data?.message}`);
    }
  };

  const deleteUser = async (request: DeleteUserRequest) => {
    try {
      const response = await requestHandler.post<DeleteUserResponse, DeleteUserRequest>(ApiUser.deleteUser, request);

      if (response.status === 200 && isResponseValid(response.data)) {
        toast.success(t('users.actions.deleteSuccess'));
      }
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) toast.error(`${error.response?.data?.message}`);
    }
  };

  // Permet d'importer les donées en envoyant le binaire csv
  const importBinary = async (request: ImportBinaryRequest) => {
    const input = new FormData();
    input.append('fileContent', request.fileContent);
    console.log(input);

    try {
      const response = await requestHandler.post<ImportBinaryResponse, FormData>(ApiUser.import, input);
      if (response.status === 200 && isResponseValid(response.data)) {
        toast.success(response.data.message);
        toast.success('succes');
      }
    } catch (error) {
      if (axios.isAxiosError(error)) toast.error(`${error.response?.data?.message}`);
    }
  };

  const extractFileName = (response: AxiosResponse, target: string): string => {
    let filename = '';
    const disposition = response.headers['content-disposition'];
    if (disposition && disposition.indexOf(target) !== -1) {
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(disposition);
      if (matches != null && matches[1]) {
        filename = matches[1].replace(/['"]/g, '');
      }
    }
    return filename;
  };

  const getModelImport = async () => {
    try {
      //eslint-disable-next-line
      const response = await requestHandler.get<any>(ApiUser.exportCsvSample);
      const fileName = extractFileName(response, 'attachment');

      const newBlob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), response.data], {
        type: 'text/csv;charset=utf-8',
      });
      const data = window.URL.createObjectURL(newBlob);
      const link = document.createElement('a');
      link.href = data;
      link.download = fileName !== undefined ? fileName + '.csv' : 'importUserModel.csv';
      // nécessaire car link.click() ne marche pas dans les dernières versions de firefox
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(function () {
        // Pour Firefox il est nécessaire de retarder le revoking de l'ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 3000);
    } catch (error) {
      console.log('erreur: ', error);
    }
  };

  const createUser = async (request: CreateUserRequest) => {
    try {
      const response = await requestHandler.post<CreateUserResponse, CreateUserRequest>(ApiUser.createUser, request);
      if (response.status === 200) {
        toast.success(t('users.actions.createSuccess'));
        return response;
      }
    } catch (error) {
      if (axios.isAxiosError(error)) toast.error(t(`${error.response?.data?.message}`));
    }
  };

  return { getUsers, getUserDesign, updateUser, deleteUser, importBinary, getModelImport, createUser };
};
