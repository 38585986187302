import React from 'react';
import { useTranslation } from 'react-i18next';
import { OrganizationToTreeViewNodes } from '../../Users/Forms/hooks';
import { AddResourceFormProps } from './types';
import useData from './hooks';
import QCDialogForm from '70.quickConnect.Components/siteAdmin/Other/QCDialogForm';
import { EntitySchemaAttribute } from '90.quickConnect.Models/siteAdmin/models/families/entitySchemaAttribute';
import { EntitySchemaAttributeTypes } from '90.quickConnect.Models/siteAdmin/enums/family/entitySchemaAttributeType';
import { AttributeValue } from '90.quickConnect.Models/siteAdmin/models/common/entities';

const AddResourceForm = ({ open, handleConfirm, toggleIsOpen, entitySchemaId }: AddResourceFormProps) => {
  const { t } = useTranslation();
  const { optionsOrgs, schemaAttributs, sharedLists } = useData(entitySchemaId, open);

  return (
    <QCDialogForm open={open} handleConfirm={handleConfirm} toggleIsOpen={toggleIsOpen}>
      <QCDialogForm.Body
        title="Création d'une nouvelle ressource"
        desc="Informations générales"
        index={1}
        displayStepping={true}
        nbColumn={1}
      >
        <QCDialogForm.Text label={t('entityData.entities.table.title')} mandatory />
        <QCDialogForm.Text label={t('entityData.editor.newInstance.placeholder.code')} mandatory />
        <QCDialogForm.TreeViewChoice
          label={t('entityData.editor.designInstance.placeholder.organization')}
          allData={OrganizationToTreeViewNodes(optionsOrgs)}
          mandatory
        />
        <QCDialogForm.Text label={t('entityData.entities.table.qcTagCode')} />
      </QCDialogForm.Body>
      <QCDialogForm.Body
        title="Création d'une nouvelle ressource"
        desc="Caractéristiques"
        index={2}
        displayStepping={true}
        nbColumn={1}
      >
        {schemaAttributs.map((attribut: EntitySchemaAttribute) => {
          switch (attribut.type) {
            case EntitySchemaAttributeTypes.Bool:
              return (
                <QCDialogForm.BoolRadio
                  key={attribut.id}
                  label={attribut.name}
                  attributData={
                    {
                      attributeId: attribut.id,
                      type: attribut.type,
                    } as AttributeValue
                  }
                />
              );
            case EntitySchemaAttributeTypes.Int:
              return (
                <QCDialogForm.Text
                  key={attribut.id}
                  label={attribut.name}
                  type="number"
                  attributData={
                    {
                      attributeId: attribut.id,
                      type: attribut.type,
                    } as AttributeValue
                  }
                />
              );
            case EntitySchemaAttributeTypes.Decimal:
              return (
                <QCDialogForm.Text
                  key={attribut.id}
                  label={attribut.name}
                  type="number"
                  attributData={
                    {
                      attributeId: attribut.id,
                      type: attribut.type,
                    } as AttributeValue
                  }
                />
              );
            case EntitySchemaAttributeTypes.DateTime:
              return (
                <QCDialogForm.Text
                  key={attribut.id}
                  label={attribut.name}
                  type="datetime-local"
                  attributData={
                    {
                      attributeId: attribut.id,
                      type: attribut.type,
                    } as AttributeValue
                  }
                />
              );
            case EntitySchemaAttributeTypes.String:
              return (
                <QCDialogForm.Text
                  key={attribut.id}
                  label={attribut.name}
                  attributData={
                    {
                      attributeId: attribut.id,
                      type: attribut.type,
                    } as AttributeValue
                  }
                />
              );
            case EntitySchemaAttributeTypes.SharedList:
              return (
                <QCDialogForm.Choice
                  key={attribut.id}
                  label={attribut.name}
                  options={
                    sharedLists.find((list) => list.id === attribut.sharedListId)?.data.map((el) => el.label) ?? []
                  }
                  attributData={
                    {
                      attributeId: attribut.id,
                      type: attribut.type,
                    } as AttributeValue
                  }
                />
              );
            case EntitySchemaAttributeTypes.Date:
              return (
                <QCDialogForm.Text
                  key={attribut.id}
                  label={attribut.name}
                  type="date"
                  attributData={
                    {
                      attributeId: attribut.id,
                      type: attribut.type,
                    } as AttributeValue
                  }
                />
              );
            case EntitySchemaAttributeTypes.SharedListHierarchical:
              return (
                <QCDialogForm.Choice
                  key={attribut.id}
                  label={attribut.name}
                  options={
                    sharedLists.find((list) => list.id === attribut.sharedListId)?.data.map((el) => el.label) ?? []
                  }
                  attributData={
                    {
                      attributeId: attribut.id,
                      type: attribut.type,
                    } as AttributeValue
                  }
                />
              );
            case EntitySchemaAttributeTypes.Email:
              return (
                <QCDialogForm.Notifications
                  key={attribut.id}
                  label={attribut.name}
                  attributData={
                    {
                      attributeId: attribut.id,
                      type: attribut.type,
                    } as AttributeValue
                  }
                />
              );
          }
        })}
      </QCDialogForm.Body>
    </QCDialogForm>
  );
};

export default AddResourceForm;
