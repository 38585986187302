import React, { useMemo } from 'react';
import { Breadcrumbs, Link } from '@mui/material';
import { observer } from 'mobx-react';
import { v4 as uuid } from 'uuid';
import { useStore } from '30.quickConnect.Store/siteAdmin';
import { useSafeNavigation } from '80.quickConnect.core/siteAdmin/hooks/useSafeNavigation';

const SubMenuBreadcrumbs = () => {
  const {
    SubMenuStore: { subMenuPath, setRows, setCurrentSubMenuRow, reduceSubMenuPath, routeBase },
  } = useStore();

  const navigate = useSafeNavigation();

  const route = /^(?<url>\/[A-z]+)\/*/g.exec(routeBase)?.groups;

  const breadcrumbsDisplayArray = useMemo(() => {
    return [subMenuPath[0], subMenuPath[subMenuPath.length - 2], subMenuPath[subMenuPath.length - 1]];
    // eslint-disable-next-line
  }, [subMenuPath.length]);

  return (
    <Breadcrumbs>
      {subMenuPath.length > 2
        ? breadcrumbsDisplayArray.map((link, index) => {
            return (
              <Link
                key={uuid()}
                onClick={() => {
                  setRows(link.rows);
                  reduceSubMenuPath(link);
                  setCurrentSubMenuRow(link.row);
                  navigate(link.id === 'root' ? (route !== undefined ? route.url : '') : `${routeBase}/${link.id}`);
                }}
                underline="none"
                color="inherit"
                sx={
                  index === breadcrumbsDisplayArray.length - 1
                    ? { fontWeight: 'bold', cursor: 'pointer' }
                    : { cursor: 'pointer' }
                }
              >
                {index === 1 ? '...' : link.name}
              </Link>
            );
          })
        : subMenuPath.map((link, index) => {
            return (
              <Link
                key={uuid()}
                onClick={() => {
                  setRows(link.rows);
                  reduceSubMenuPath(link);
                  setCurrentSubMenuRow(link.row);
                  navigate(link.id === 'root' ? (route !== undefined ? route.url : '') : `${routeBase}/${link.id}`);
                }}
                underline="none"
                color="inherit"
                sx={
                  index === subMenuPath.length - 1 ? { fontWeight: 'bold', cursor: 'pointer' } : { cursor: 'pointer' }
                }
              >
                {link.name}
              </Link>
            );
          })}
    </Breadcrumbs>
  );
};

export default observer(SubMenuBreadcrumbs);
