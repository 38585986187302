import React, { ChangeEvent } from 'react';
import { Box, Checkbox, Divider, List, Stack, Typography } from '@mui/material';
import { observer } from 'mobx-react';
import { SubMenuContext } from './const';
import { renderSubMenuRow } from './help';
import { useData } from './hooks';
import { SubMenuProps } from './types';
import {
  AddButtonContainer,
  ListContainerStyle,
  PathStyle,
  SubMenuContainerStyle,
  ToolsContainerStyle,
} from './styles';
import SubMenuVirtualizedList from './SubMenuVirtualizedList';
import QCSwitch from '70.quickConnect.Components/siteAdmin/Inputs/QCSwitch';
import QCSortingMenu from '70.quickConnect.Components/siteAdmin/Other/QCSortingMenu';
import SearchBar from '70.quickConnect.Components/siteAdmin/Inputs/SearchBar';
import QCSubMenuAddButton from '70.quickConnect.Components/siteAdmin/Buttons/QCSubMenuAddButton';
import QCKebabMenu from '70.quickConnect.Components/siteAdmin/Other/QCKebabMenu';

/**
 * Composant pour le sous-menu. Possède trois zones: le chemin, passé dans la props path, la zone d'outils de tri/recherche et la liste de d'items.
 * Ceux peuvent de types : folder, item ou form. La différence entre item et form est le bouton éditer sur le type form.
 * Folder est pour représenter un dossier qui peut prendre en props des childrenItems.
 * Le switch vue dossier ne transforme pas directement une liste de dossier en une liste d'items. Il faut récupérer getIsViewFolder puis modifier les rows à l'extérieur
 * @component
 * @prop {SubMenuRow[]}rows - Liste des items à afficher
 * @prop {string[]} sortingTypes - Types de tris possible (pour l'affichage)
 * @prop {Dispatch<SetStateAction<string>>} getSelectedSortingType - Dispatch du type de tri sélectionné
 * @prop {string[]} multipleActions - Liste des actions multiples possible
 * @prop {Dispatch} getSelectedMultipleAction - Dispatch de l'action multiple choisie
 * @prop {string[]} rowActions - Liste des actions affiché pour le menu par ligne
 * @prop {Dispatch} getSelectedRowAction - Dispatch de l'action ligne choisie
 * @prop {Dispatch<SetStateAction<string>>} getSearchValue - Dispatch du champ de recherche
 * @prop {ReactElement} path - Chemin affiché en haut du sous-menu, utiliser Breadcrumbs de Mui
 * @prop {MouseEventHandler} handleAddClick - Fonction exécutée quand on clique sur le bouton ajouter, si undefined celui-ci n'apparait pas
 * @prop {ReactElement<IconProps>} addIcon - Icone du bouton ajouter
 * @prop {string} addText - Texte du bouton ajouter
 * @prop {function} onRowClicked - Fonction appelée quand un item est cliqué dans la liste
 * @prop {boolean} ennableFolders - Autorise les dossier, permet d'afficher le switch vue dossier
 * @prop {Dispatch<SetStateAction<boolean>> | never} getIsViewFolder - Dispatch booléen de la valeur du switch vue dossier. Disponible seulement si ennableFolders est à true
 */

const SubMenu = ({
  rows,
  sortingTypes,
  getSelectedSortingType,
  multipleActions,
  getSelectedMultipleAction,
  rowActions,
  getSelectedRowAction,
  getSearchValue,
  path,
  handleAddClick,
  addIcon,
  addText,
  onRowClicked,
  ennableFolders,
  getIsViewFolder,
}: SubMenuProps) => {
  const {
    theme,
    t,
    showMultipleActions,
    setShowMultipleActions,
    selectedRowsId,
    handleActionCheckboxClick,
    handleRowCheckboxClick,
    handleMultipleActionSelectedOption,
    openedFolders,
    setOpenedFolders,
    mainRef,
    rightRef,
    stackRef,
    x,
  } = useData(rows, getSelectedMultipleAction);

  return (
    <SubMenuContext.Provider
      value={{
        rowActions: rowActions,
        setSelectedRowAction: getSelectedRowAction ?? (() => {}),
        onRowClicked: onRowClicked,
        showCheckbox: showMultipleActions,
        openedFolders: openedFolders,
        setOpenedFolders: setOpenedFolders,
        handleRowCheckboxClick: handleRowCheckboxClick,
        selectedRowsId: selectedRowsId,
        width: x,
      }}
    >
      <Box ref={mainRef} display="flex" flexDirection="row" justifyContent={'flex-start'} width="fit-content">
        <Box className="resizable" sx={SubMenuContainerStyle(theme, x)}>
          <Box sx={PathStyle}>{path}</Box>

          <Stack ref={stackRef} direction="column" sx={ToolsContainerStyle(theme, x)}>
            <Stack
              direction="row"
              justifyContent={ennableFolders ? 'space-between' : 'flex-end'}
              sx={{ marginRight: '9px' }}
            >
              {ennableFolders && (
                <Box sx={{ display: 'flex' }}>
                  <label>
                    <QCSwitch onChange={(event: ChangeEvent, checked: boolean) => getIsViewFolder(checked)} />
                    <Typography display="inline" lineHeight={2.5}>
                      {t('submenu.folderView')}
                    </Typography>
                  </label>
                </Box>
              )}
              {sortingTypes && <QCSortingMenu options={sortingTypes} dispatch={getSelectedSortingType ?? (() => {})} />}
            </Stack>
            <Box sx={{ marginX: '9px' }}>
              <SearchBar dispatch={getSearchValue ?? (() => {})} />
            </Box>

            {/* Choix d'actions multiples */}
            {multipleActions && (
              <Stack direction="row" justifyContent="space-between" alignItems="center" flexWrap="wrap">
                <Checkbox
                  disabled={!showMultipleActions}
                  checked={selectedRowsId.length === rows.length}
                  indeterminate={selectedRowsId.length > 0 && selectedRowsId.length < rows.length}
                  onClick={handleActionCheckboxClick}
                />

                {x > 287 ? (
                  <Typography
                    display="inline"
                    sx={{ color: showMultipleActions ? 'auto' : 'rgb(170, 170, 170)' /*theme.palette.grey[400]*/ }}
                  >
                    {t('datatable.multipleActions')}
                  </Typography>
                ) : null}
                <QCSwitch
                  onChange={(event: ChangeEvent, checked: boolean) => setShowMultipleActions(checked)}
                  checked={showMultipleActions}
                />
                <QCKebabMenu
                  options={multipleActions}
                  dispatch={(value: string) => {
                    handleMultipleActionSelectedOption(value.toString());
                  }}
                  disabled={!showMultipleActions}
                />
              </Stack>
            )}
          </Stack>
          <Box sx={ListContainerStyle}>
            {ennableFolders ? (
              <List>{rows.map((el) => renderSubMenuRow(el, selectedRowsId.includes(el.id)))}</List>
            ) : (
              <SubMenuVirtualizedList rows={rows} />
            )}
          </Box>
          {handleAddClick && (
            <Box sx={AddButtonContainer}>
              <Box
                sx={{
                  backgroundColor: theme.palette.toolsBackground.main,
                  borderRadius: '8px',
                  opacity: 1,
                }}
              >
                <QCSubMenuAddButton onClick={handleAddClick} icon={addIcon} text={addText ?? ''} />
              </Box>
            </Box>
          )}
        </Box>
        <Divider
          ref={rightRef}
          className="resizer resizer-r"
          sx={{ cursor: 'col-resize', border: '1px solid #00000000', width: '4px' }}
        ></Divider>
      </Box>
    </SubMenuContext.Provider>
  );
};

export default observer(SubMenu);
