import { useTranslation } from 'react-i18next';
import { useCallback, useMemo, useState, useRef, useReducer, useEffect, ChangeEvent } from 'react';
import useTraductionServices from './services/traductions';
import { defaultSupportedLang, defaultTitle, defaultTranslateEntity } from './const';
import { GetI18nRequest } from './services/request';
import { CountType } from './TraductionCard/types';
import { I18nEntity } from './types';
import { getOverTitle, getUrlParams } from './helpers';
import { I18nEntityType } from './enum';
import useEffectOnce from '80.quickConnect.core/shared/hooks/useEffectOnce';
import { SupportedLanguage } from '90.quickConnect.Models/siteAdmin/models/internationalization/supportedLanguage';
import { saveConfirmationReducer } from '70.quickConnect.Components/siteAdmin/Other/SaveConfirmationDialog/type';
import { resetConfirmationReducer } from '70.quickConnect.Components/siteAdmin/Other/ResetConfirmationDialog/types';
import { importConfirmationReducer } from '70.quickConnect.Components/siteAdmin/Other/ImportConfirmationDialog/types';

const useTraductionData = () => {
  const { getSupportedLanguages, getI18n, saveI18n, exportI18n, importI18n } = useTraductionServices();
  const { t } = useTranslation();

  const [supportedLanguages, setSupportedLanguages] = useState<Array<SupportedLanguage>>([]);
  const [i18nEntity, setI18nEntity] = useState<I18nEntity>(defaultTranslateEntity);
  const [defaultLang, setDefaultLang] = useState<SupportedLanguage>(defaultSupportedLang);

  const [openSaveDialog, setOpenSaveDialog] = useState<boolean>(false);
  const [saveState, saveDispatch] = useReducer(saveConfirmationReducer, { isSaved: false });

  const [openResetDialog, setOpenResetDialog] = useState<boolean>(false);
  const [resetState, resetDispatch] = useReducer(resetConfirmationReducer, { isReset: false });

  const [openImportDialog, setOpenImportDialog] = useState<boolean>(false);
  const [importState, importDispatch] = useReducer(importConfirmationReducer, { isImport: false });

  const initailI18nEntity = useRef<I18nEntity>(defaultTranslateEntity);
  const FileInputRef = useRef<HTMLInputElement | null>(null);
  const importedDoc = useRef<File | undefined>(undefined);

  const handleFileInput = () => {
    FileInputRef.current?.click();
  };

  const uploadFile = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const file = event.target.files.item(0);
      if (file) {
        importedDoc.current = file;
        setOpenImportDialog(true);
      }
    }
  };

  const initI18nEntity = useCallback(
    async (entityRequest: GetI18nRequest) => {
      const newEntity = await getI18n(entityRequest);
      const languages = await getSupportedLanguages();
      const changedLangs = languages?.map((lg) => {
        if (lg.value === newEntity?.defaultLanguage) {
          setDefaultLang({
            lang: lg.lang,
            value: 'default',
          });
          return {
            lang: lg.lang,
            value: 'default',
          };
        }
        return lg;
      });
      setSupportedLanguages(changedLangs ?? []);
      setI18nEntity(newEntity ?? defaultTranslateEntity);
      initailI18nEntity.current = newEntity ?? defaultTranslateEntity;
    },
    [getI18n, getSupportedLanguages, setI18nEntity, setDefaultLang],
  );

  const updateI18Entity = useCallback((field: string, value: string, language: string) => {
    setI18nEntity((prev) => {
      const newI18nEntity = { ...prev };
      const newTrans = prev.translate.map((tr) => {
        if (tr.name === field) {
          const newValues = tr.values.map((vl) => {
            if (vl.lang === language) {
              return {
                lang: vl.lang,
                value: value,
              } as SupportedLanguage;
            }
            return vl;
          });
          return {
            name: tr.name,
            values: newValues,
          };
        }
        return tr;
      });
      newI18nEntity.translate = newTrans;
      return newI18nEntity;
    });
  }, []);

  useEffectOnce(() => {
    initI18nEntity(getUrlParams(t));
  });

  const destChampsCount = useMemo(() => {
    const champs: CountType[] = [];
    supportedLanguages.forEach((lang) => {
      let count = 0;
      i18nEntity.translate.forEach((tr) => {
        const findValue = tr.values.find((vl) => vl.lang === lang.value);
        if (findValue && findValue.value && findValue.value.length > 0) {
          count += 1;
        }
      });
      champs.push({
        count: count,
        total: i18nEntity.translate.length,
        lang: lang.value,
      });
    });
    return champs;
  }, [i18nEntity, supportedLanguages]);

  const resetI18nEntity = useCallback(() => {
    setI18nEntity(initailI18nEntity.current);
  }, [setI18nEntity, initailI18nEntity]);

  useEffect(() => {
    setOpenSaveDialog(false);
    if (saveState.isSaved) {
      const req = { ...getUrlParams(t), i18nEntity };
      saveI18n(req).then((res) => {
        if (res) initailI18nEntity.current = i18nEntity;
      });
    }
    //eslint-disable-next-line
  }, [saveState]);

  useEffect(() => {
    setOpenResetDialog(false);
    if (resetState.isReset) {
      resetI18nEntity();
    }
    //eslint-disable-next-line
  }, [resetState]);

  const exportI18nEntity = useCallback(() => {
    const params = getUrlParams(t);
    exportI18n({ entityType: params.entityType, idToExport: [params.entityId] });
  }, [exportI18n, t]);

  const importI18nEntity = useCallback(() => {
    const params = getUrlParams(t);
    if (importedDoc.current && params.entityType !== I18nEntityType.None) {
      importI18n({ fileContent: importedDoc.current }, params.entityType);
    }
  }, [importedDoc, t, importI18n]);

  useEffect(() => {
    setOpenImportDialog(false);
    if (importState.isImport) {
      importI18nEntity();
    }
    importedDoc.current = undefined;
    //eslint-disable-next-line
  }, [importState]);

  const getTitle = useCallback(() => {
    return getOverTitle(t) ?? defaultTitle;
  }, [t]);

  return {
    supportedLanguages,
    i18nEntity,
    updateI18Entity,
    destChampsCount,
    defaultLang,
    openSaveDialog,
    setOpenSaveDialog,
    saveDispatch,
    openResetDialog,
    setOpenResetDialog,
    resetDispatch,
    exportI18nEntity,
    FileInputRef,
    handleFileInput,
    uploadFile,
    openImportDialog,
    importDispatch,
    getTitle,
  };
};

export default useTraductionData;
