import { Dispatch, SetStateAction } from 'react';
import { SharedListDataType, SharedListType } from '90.quickConnect.Models/siteAdmin/enums/sharedList/sharedListType';
import { SharedListData } from '90.quickConnect.Models/siteAdmin/models/sharedList/sharedList';

export type SharedListSettingsContextType = {
  sharedListElements: SharedListData[];

  setSharedListElements: Dispatch<SetStateAction<SharedListData[]>>;

  dataType: SharedListDataType | undefined;

  setDataType: Dispatch<SetStateAction<SharedListDataType | undefined>>;

  sharedListType: SharedListType;

  setSharedListType: Dispatch<SetStateAction<SharedListType>>;

  // pour ajouter un élément à sous-élement on passe le label complet à la dialog
  addElementLabel: string | undefined;

  setAddElementLabel: (newValue: string | undefined) => void;

  setOpenAddElementDialog: Dispatch<SetStateAction<boolean>>;

  // on prend seulement la valeur initiale car il faut que l'API soit synchronisée
  initialToBeTranslated: boolean;
};

const undefinedDispatch = () => {
  throw new Error('SharedList Settings Context not initialized');
};

export const defaultContextValue: SharedListSettingsContextType = {
  sharedListElements: [],

  setSharedListElements: undefinedDispatch,

  dataType: undefined,

  setDataType: undefinedDispatch,

  sharedListType: SharedListType.Simple,

  setSharedListType: undefinedDispatch,

  addElementLabel: undefined,

  setOpenAddElementDialog: undefinedDispatch,

  //eslint-disable-next-line
  setAddElementLabel: (value: string | undefined) => undefinedDispatch(),

  initialToBeTranslated: false,
};
