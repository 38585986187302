import { Badge, BottomNavigation, BottomNavigationAction, Box } from '@mui/material';
import React from 'react';

import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { NavElementName } from '../types';
import { navElements } from '../const';
import PlusMenu from './PlusMenu';
import { useData } from './hooks';
import { BottmMenuStyles } from './styles';
import { getRoleValue } from '80.quickConnect.core/siteAdmin/helpers/getRoleValue';
import { pureWhiteColor } from '00.app/theme/colors';
import { useSafeNavigation } from '80.quickConnect.core/siteAdmin/hooks/useSafeNavigation';

const BottomMenu = () => {
  const { t } = useTranslation();
  const navigate = useSafeNavigation();

  const { valueNavIndex, setValueNavIndex, mapNavNamesToRoutes, setFocusedBtn, role } = useData();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, btnName: NavElementName) => {
    if (btnName != 'plus') setFocusedBtn(btnName);
    if (btnName == 'plus') setAnchorEl(event.currentTarget);
  };

  return (
    <Box sx={BottmMenuStyles}>
      <BottomNavigation
        showLabels
        value={valueNavIndex}
        onChange={(event: React.SyntheticEvent, newValue: NavElementName) => {
          setValueNavIndex(newValue);
          navigate(mapNavNamesToRoutes(newValue));
        }}
      >
        {navElements.map((navEl, index: number) => {
          if (['home', 'forms', 'activities', 'declarations', 'plus'].includes(navEl.name)) {
            return (
              getRoleValue(role) >= getRoleValue(navEl.authorizationRole) && (
                <BottomNavigationAction
                  data-cy={navEl.name}
                  key={index}
                  label={t(`navigation.menu.${navEl.name}`)}
                  value={navEl.name}
                  icon={
                    navEl.name == 'activities' ? (
                      <Badge badgeContent={100} color="primary">
                        {navEl.Icon}
                      </Badge>
                    ) : (
                      navEl.Icon
                    )
                  }
                  onClick={(event) => handleClick(event, navEl.name)}
                  sx={{ color: pureWhiteColor }}
                />
              )
            );
          }
        })}
      </BottomNavigation>
      <PlusMenu open={open} setAnchorEl={setAnchorEl} />
    </Box>
  );
};

export default observer(BottomMenu);
