import React from 'react';
import { CLIENT, CLIENTS, VERSION, VERSIONS } from '../paths';
import { LinkRouteType } from '../types';
import ADMINISTRATOR_ROUTES from './administratorRoutes';
import VersionQC from '10.quickConnect.app/siteAdmin/components/domains/VersionQC/Home';
import VersionQCUpdate from '10.quickConnect.app/siteAdmin/components/domains/VersionQC/Update';
import Clients from '10.quickConnect.app/siteAdmin/components/domains/Clients';
import ClientSettings from '10.quickConnect.app/siteAdmin/components/domains/Clients/ClientSettings';

const C2SADMINISTRATOR_ROUTES: LinkRouteType[] = [
  // A compléter quand les composants seront créés
  ...ADMINISTRATOR_ROUTES,

  { path: CLIENTS, key: 'CLIENTS', element: <Clients /> },
  { path: CLIENT, key: 'CLIENT', element: <ClientSettings /> },

  { path: VERSIONS, key: 'VERSIONS', element: <VersionQCUpdate /> },
  { path: VERSION, key: 'VERSION', element: <VersionQC /> },
];

export default C2SADMINISTRATOR_ROUTES;
