import { createContext, useContext } from 'react';
import FamilySettingsStore from './store';

export const createFamilySettingsStore = (): /*
  simpleSharedLists: SharedList[],
  hierarchicalSharedLists: SharedList[]*/ /* attributes: EntitySchemaAttribute[]*/
FamilySettingsStore => new FamilySettingsStore(/*simpleSharedLists, hierarchicalSharedLists /*attributes*/);

export const FamilySettingsStoreContext = createContext<FamilySettingsStore>({} as FamilySettingsStore);

export const useFamilySettingsStore = (): FamilySettingsStore => useContext(FamilySettingsStoreContext);
export const FamilySettingsStoreProvider = FamilySettingsStoreContext.Provider;
