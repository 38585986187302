import { SxProps } from '@mui/material';

export const boxInputStyle: SxProps = {
  display: 'flex',
  justifyContent: 'space-evenly',
  alignItems: 'flex-end',
};

export const typographyStyles: SxProps = {
  fontWeight: '600',
  fontSize: '1.5rem',
};

export const cardStyle: SxProps = {
  p: 2,
};
