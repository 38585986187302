import React from 'react';
import { Box, Stack } from '@mui/material';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import TranslateIcon from '@mui/icons-material/Translate';
import { observer } from 'mobx-react';
import FamilyAttributesCard from './FamilyAttributesCard';
import { createFamilySettingsStore, FamilySettingsStoreProvider } from './FamilySettingsStore';
import { useFamilySettingsData } from './hooks';
import { ActionButtonsContainer } from './styles';
import WebhooksCard from './WebhooksCard';
import MenuWrapper from '10.quickConnect.app/siteAdmin/components/shared/MenuWrapper';
import ContentHeader from '70.quickConnect.Components/siteAdmin/Other/ContentHeader';
import FamilyIcon from '00.app/icons/FamilyIcon';
import QCTextInput from '70.quickConnect.Components/siteAdmin/Inputs/QCTextInput';
import QCSelect from '70.quickConnect.Components/siteAdmin/Inputs/QCSelect';
import { capitalize } from '80.quickConnect.core/shared/helpers/capitalize';
import QCFabCorbeille from '70.quickConnect.Components/siteAdmin/Buttons/QCFabCorbeille';
import QCIconButton from '70.quickConnect.Components/siteAdmin/Buttons/QCIconButton';
import SaveConfirmationDialog from '70.quickConnect.Components/siteAdmin/Other/SaveConfirmationDialog';
import DeleteConfirmationDialog from '70.quickConnect.Components/siteAdmin/Other/DeleteConfirmationDialog';
import { green4 } from '00.app/theme/colors';

const FamilySettings = () => {
  const {
    t,
    pageFamily,
    editedFamily,
    supportedLanguages,
    languageValue,
    familySetter,
    parentName,
    handleSave,
    openSaveDialog,
    saveDispatch,
    handleDelete,
    openDeleteDialog,
    deleteDispatch,
    handleTranslate,
  } = useFamilySettingsData();

  return (
    <MenuWrapper>
      <DeleteConfirmationDialog open={openDeleteDialog} dispatch={deleteDispatch} trashAvailable />
      <SaveConfirmationDialog open={openSaveDialog} dispatch={saveDispatch} />

      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <ContentHeader>
          <ContentHeader.BackButton />
          <ContentHeader.TitlesGroup>
            <ContentHeader.OverTitle>{t('entitySchema.entityDesigner.overTitle')}</ContentHeader.OverTitle>
            <ContentHeader.Title startIcon={<FamilyIcon />}>{pageFamily?.name}</ContentHeader.Title>
          </ContentHeader.TitlesGroup>
        </ContentHeader>

        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, marginRight: '1.5vw' }}>
          <QCFabCorbeille onClickFunc={handleDelete}></QCFabCorbeille>
          <QCIconButton
            Icon={<TranslateIcon sx={{ color: green4 }} />}
            onClickFunc={handleTranslate}
            variantValue="outlined"
          />
          <QCIconButton Icon={<SaveOutlinedIcon />} onClickFunc={handleSave} variantValue="contained" />
        </Box>
      </Box>

      <Stack sx={{ paddingY: '18px', position: 'relative' }}>
        <Box sx={{ display: 'flex', gap: 2, marginRight: '1vw' }}>
          <QCTextInput
            label={t('entitySchema.entityDesigner.entitySchema.placeholder.name')}
            value={editedFamily?.name}
            dispatch={(value: string) => familySetter(value, 'name')}
          />
          <QCTextInput
            label={t('entitySchema.entityDesigner.entitySchema.placeholder.description')}
            value={editedFamily?.description}
            dispatch={(value: string) => familySetter(value, 'description')}
          />
          <QCTextInput
            label={t('entitySchema.entityDesigner.entitySchema.placeholder.parent')}
            placeholder={parentName}
            editable={false}
          />
          <Box sx={{ flexGrow: 1.5 }}>
            <QCSelect
              label={t('common.internationalization.defaultLanguage')}
              options={supportedLanguages.map((lang) => capitalize(lang.lang))}
              setOption={(value: string) => {
                const newLang = supportedLanguages.find((el) => capitalize(el.lang) === value);
                if (newLang) {
                  familySetter(newLang.value, 'defaultLanguage');
                }
              }}
              value={languageValue}
              defaultValue={languageValue}
            />
          </Box>
        </Box>

        <Stack direction="row" gap={1.5} marginRight="1vw">
          <FamilyAttributesCard />

          <WebhooksCard />
        </Stack>
        <Box sx={ActionButtonsContainer}></Box>
      </Stack>
    </MenuWrapper>
  );
};

const ObserverFamilySettings = observer(FamilySettings);

const FamilySettingsWithStore = () => {
  const familySettingsStore = createFamilySettingsStore(/*simpleSharedLists, hierarchicalSharedLists*/);
  return (
    <FamilySettingsStoreProvider value={familySettingsStore}>
      <ObserverFamilySettings />
    </FamilySettingsStoreProvider>
  );
};

export default FamilySettingsWithStore;
