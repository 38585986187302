import React, { useState } from 'react';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import InfoIcon from '@mui/icons-material/Info';
import { Tooltip, useTheme } from '@mui/material';
import { QCInfoBubbleProps } from './types';

//    Points qui permettent de créer la petite flèche en haut
// const popperTopRight = '98.8% 10%, 99.5% 11%,100% 14%';
// const popper = `50% 0%, 55% 10%, ${popperTopRight}, 100% 100%,0 100%, 0 10%, 45% 10%`;
const popper = '50% 0%, 55% 10%, 100% 10%, 100% 100%, 0 100%, 0 10%, 45% 10%';

const QCInfoBubble = ({ text, fontSize = 'small' }: QCInfoBubbleProps) => {
  const theme = useTheme();
  const [hover, setHover] = useState(false);
  return (
    <Tooltip
      title={text}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      sx={{
        color: theme.palette.primary.main,
        textAlign: 'center',
      }}
      PopperProps={{
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, -14],
            },
          },
        ],
      }}
      componentsProps={{
        tooltip: {
          sx: {
            paddingTop: '5%',
            bgcolor: theme.palette.fieldBackground.main, //'#EFF1F0',
            color: '#A7ADA8',
            fontSize: fontSize === 'small' ? 'auto' : fontSize === 'medium' ? '16px' : '20px',

            // borderRadius: '2px',
            // border: 'solid 2px',
            // borderColor: theme.palette.choiceMenuBorder.main,

            lineHeight: '1.6',
            clipPath: {
              xs: 'none',
              sm: `polygon(${popper})`,
              // md: `polygon(${popper})`,
            },
            //},
          },
        },
        popper: {
          sx: {
            color: '#A7ADA8',
          },
        },
      }}
    >
      {hover ? <InfoIcon fontSize={fontSize} /> : <InfoOutlinedIcon fontSize={fontSize} />}
    </Tooltip>
  );
};

export default QCInfoBubble;
