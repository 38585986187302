export const isJSON = (json: unknown) => {
  let item = typeof json !== 'string' ? JSON.stringify(json) : json;
  try {
    item = JSON.parse(item);
  } catch (e) {
    return false;
  }
  if (typeof item === 'object' && item !== null) {
    return true;
  }
  return false;
};
