import { useTranslation } from 'react-i18next';
import { FormPublishState } from '90.quickConnect.Models/siteAdmin/enums/forms/formPublicState';

export const useReadFormPublishedState = () => {
  const { t } = useTranslation();
  const readFormPublishedState = (state: FormPublishState) => {
    switch (state) {
      case FormPublishState.Draft:
        return t('form.publishState.draft');
      case FormPublishState.Published:
        return t('form.publishState.published');
      case FormPublishState.Test:
        return t('form.publishState.test');
      case FormPublishState.Depreciated:
        return t('form.publishState.Depreciated');
    }
  };

  return { readFormPublishedState };
};
