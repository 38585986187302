import axios, { AxiosRequestConfig, AxiosResponse, CreateAxiosDefaults, RawAxiosRequestHeaders } from 'axios';
import { baseHeaders } from './const';

class QCHttpRequest {
  baseURL: string;

  baseHeaders: RawAxiosRequestHeaders = baseHeaders;

  baseBody = null;

  baseAxiosConfig: CreateAxiosDefaults;

  axiosInstance;

  constructor() {
    if (process.env.REACT_APP_QC_API_ENDPOINT) {
      this.baseURL = process.env.REACT_APP_QC_API_ENDPOINT;
    } else {
      throw new Error("Variable d'environnement non trouvé pour l'initialisation de la classe QCHTTPRequest");
    }

    this.baseHeaders = baseHeaders;

    this.baseAxiosConfig = {
      baseURL: this.baseURL,
      withCredentials: true,
    };

    this.axiosInstance = axios.create(this.baseAxiosConfig);
  }

  async get<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    const newConfig = { ...this.baseAxiosConfig, ...config } as AxiosRequestConfig;
    const response = await this.axiosInstance.get<T, AxiosResponse<T>>(url, newConfig);
    return response;
  }

  async post<T, D>(url: string, data?: D, config?: AxiosRequestConfig): Promise<AxiosResponse<T>> {
    const newConfig = { ...this.baseAxiosConfig, ...config } as AxiosRequestConfig;
    return this.axiosInstance.post<T, AxiosResponse<T>, D>(url, data, newConfig);
    // this.axiosInstance
    //   .post(url, newConfig)
    //   .then((response) => {
    //     return response;
    //   })
    //   .catch((err) => {
    //     console.log(' depuis classe' + err);
    //     if (err.response.status === 401) {
    //       console.log('erreur 401');
    //     } else {
    //       return err.response;
    //     }
    //   })
    //   .finally(() => {
    //     console.log('finally class');
    //   });
  }

  async put<D>(url: string, data?: D, config?: AxiosRequestConfig) {
    const newConfig = { ...this.baseAxiosConfig, ...config, ...data } as AxiosRequestConfig;

    try {
      const response = await this.axiosInstance.put(url, newConfig);
      return response;
      // eslint-disable-next-line
    } catch (err: any) {
      console.log(' depuis classe' + err);
      if (err.response.status === 401) {
        console.log('erreur 401');
      } else {
        return err.response;
      }
    } finally {
      console.log('class finally');
    }
  }

  async delete(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse> {
    const newConfig = { ...this.baseAxiosConfig, ...config } as AxiosRequestConfig;
    const response = await this.axiosInstance.delete(url, newConfig);
    return response;
  }

  onError(errorCode: number) {
    switch (errorCode) {
      case 500:
      default:
    }
  }
}

export default QCHttpRequest;

// export interface QCHttpAxiosClientInterface {
//   baseURL: string;

//   baseHeaders: RawAxiosRequestHeaders;

//   baseAxiosConfig: CreateAxiosDefaults;

//   get<T>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse<T>>;

//   post<T, D>(url: string, data?: D, config?: AxiosRequestConfig): Promise<AxiosResponse<T>>;

//   put<D>(url: string, data?: D, config?: AxiosRequestConfig): Promise<any>;

//   delete(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse>;
// }
