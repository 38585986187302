import { SxProps } from '@mui/system';
import { kraft500, kraft400 } from '00.app/theme/colors';

export const MainBoxMenuStyle = (isOpenSideMenu: boolean): SxProps => {
  return {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    mx: '1.5vw',
    my: '2vh',
    height: '96vh',
    width: isOpenSideMenu ? '250px' : '64px',
    borderTopLeftRadius: '16px',
    backgroundColor: kraft500,
  };
};

export const OpenedMenuLogoBoxStyle: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  width: '100%',
  marginTop: '10px',
  '& .MuiSkeleton-circular': {
    position: 'absolute',
    right: 10,
  },
};

export const FoldedMenuLogoBoxStyle: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: '10px',
  marginTop: '10px',
  width: '100%',
};

export const MenuBottomBoxStyle = (isOpenSideMenu: boolean): SxProps => {
  return {
    display: 'flex',
    flexDirection: isOpenSideMenu ? 'row' : 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '10px',
    p: '5px',
    width: '100%',
  };
};

export const MenuMiddleBoxStyle: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  gap: '10px',
};

export const MenuBtnStyle = (isOpenSideMenu: boolean): SxProps => {
  return {
    width: isOpenSideMenu ? '70%' : 40,
    height: isOpenSideMenu ? 50 : 40,
    border: `1px solid ${kraft400}`,
  };
};
