import React from 'react';
import { Box, Typography } from '@mui/material';
import { RenderCellesProps } from '../types';
import { boxStyle, typographyText } from './styels';
import RessourceIcon from '00.app/icons/RessourceIcon';

const AssociatedResCell = ({ params }: RenderCellesProps) => {
  return (
    <>
      {params.row.entityInstanceStringExtract && (
        <Box sx={boxStyle}>
          <RessourceIcon fontSize="small" />
          <Typography sx={typographyText}>{params.row.entityInstanceStringExtract}</Typography>
        </Box>
      )}
    </>
  );
};

export default AssociatedResCell;
