import { SxProps } from '@mui/material';

export const countCardTextBox: SxProps = {
  display: 'flex',
  width: 121,
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  color: '#464B47', // slateGrey800
  '& :first-of-type': {
    fontWeight: '700',
    fontSize: '30px',
    lineHeight: '36px',
  },
  '& :second-of-type': {
    fontWeight: '400',
    fontSize: '14px',
    lineHeight: '14px',
  },
};

export const iconBox = (iconType: string): SxProps => {
  return {
    width: 'fit-content',
    height: 'fit-content',
    color: iconType.toLowerCase() == 'close' ? '#D17E5F' : '#17DCB8',
    // #D17E5F : red4, #17DCB8 : green4
  };
};
