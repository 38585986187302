import { PaletteOptions } from '@mui/material';
import createPalette from '@mui/material/styles/createPalette';
import {
  dippedGreen500,
  kraft400,
  terraCotta400,
  dippedGreen700,
  orangeRedCrayola,
  lightSkyBlue,
  slateGrey800,
  ghostWhite,
  textBlackColor,
  borderLightColor,
  dippedGreen400,
  dippedGreen200,
  dippedGreen800,
  terraCotta300,
  brown2,
  brown4,
  brown0,
  grey2,
  grey0,
  slateGrey900,
  slateGrey50,
  slateGrey700,
  kraft700,
  pureWhiteColor,
  slateGrey400,
  terraCotta200,
  kraft500,
  kraft200,
  kraft600,
  green1,
  green5,
  grey4,
  green0,
  slateGrey600,
  grey1,
  grey6,
  brown5,
  brown1,
  brown3,
} from './colors';

export const lightPalette = createPalette({
  mode: 'light',
  primary: {
    main: dippedGreen500,
  },
  secondary: {
    main: brown2,
  },
  error: {
    main: terraCotta400,
  },
  success: {
    main: dippedGreen700,
  },
  warning: {
    main: orangeRedCrayola,
  },
  info: {
    main: lightSkyBlue,
  },
  grey: {
    main: slateGrey800,
  },
  topBar: {
    main: ghostWhite,
  },
  text: {
    primary: textBlackColor,
    secondary: textBlackColor,
    disabled: slateGrey400,
  },
  divider: slateGrey800,
  background: {
    default: pureWhiteColor,
    paper: ghostWhite,
  },
  borderGroup: {
    main: borderLightColor,
  },
  backGroundGroup: {
    main: dippedGreen500,
  },
  lightThemeTextColor: {
    main: ghostWhite,
    light: ghostWhite,
    dark: slateGrey800,
  },
  iconOutlinedColor: {
    main: dippedGreen400,
  },
  iconOutlinedHoverColor: {
    main: dippedGreen200,
  },
  iconContainedHoverColor: {
    main: dippedGreen800,
  },
  iconContainedColor: {
    main: dippedGreen500,
  },
  containedTextColor: {
    main: slateGrey800,
  },
  textLink: {
    main: slateGrey800,
  },
  iconBinButtonColor: {
    main: terraCotta300,
  },
  inputDefaultBorderColor: {
    main: kraft400,
  },
  inputFileColors: {
    main: brown4,
    light: brown0,
  },
  inputListFileColors: {
    main: grey2,
    light: grey0,
  },
  inputListFileIconColors: {
    main: grey2,
    light: slateGrey900,
  },
  labelDefault: {
    main: slateGrey800,
  },
  fieldBackground: {
    main: slateGrey50,
  },
  fieldPlaceholder: {
    main: slateGrey700,
  },
  switchBackground: {
    main: green0,
  },
  iconBtnOutlinedColor: {
    main: dippedGreen500,
  },
  iconBtnContainedColor: {
    main: slateGrey800,
  },
  switchBorderSecondary: {
    main: kraft500,
  },
  menuBorder: {
    main: grey2,
  },
  toolsBackground: {
    main: grey0,
  },
  itemListHover: {
    main: green1,
  },
  dividerDialogForm: {
    main: green1,
  },
  mandatoryText: {
    main: green5,
  },
  backgroundColorAlerteChip: {
    main: terraCotta300,
    contrastText: pureWhiteColor,
  },
  backgroundColorIncidentChip: {
    main: brown2,
    contrastText: slateGrey700,
  },
  noFinishedIconColor: {
    main: grey4,
  },
  finishedImportsIconColor: {
    main: terraCotta300,
  },
  finishedExportsIconColor: {
    main: dippedGreen500,
  },
  attributeBackground: {
    main: grey1,
  },
  widgetHeaderBackground: {
    main: brown1,
  },
  tabsHeaderBackground: {
    main: brown0,
  },
  bgColorWorkFlowChip: {
    main: brown4,
    contrastText: pureWhiteColor,
  },
} as PaletteOptions);

export const darkPalette = createPalette({
  mode: 'dark',
  primary: {
    main: green5,
  },
  secondary: {
    main: brown5,
  },
  error: {
    main: terraCotta300,
  },
  success: {
    main: dippedGreen500,
  },
  warning: {
    main: orangeRedCrayola,
  },
  info: {
    main: lightSkyBlue,
  },
  grey: {
    main: slateGrey800,
  },
  topBar: {
    main: pureWhiteColor,
  },
  text: {
    primary: pureWhiteColor,
    secondary: pureWhiteColor,
    disabled: slateGrey600,
  },
  divider: '#fff',
  background: {
    default: slateGrey900,
    paper: slateGrey900,
  },
  borderGroup: {
    main: kraft700,
  },
  backGroundGroup: {
    main: kraft400,
  },
  lightThemeTextColor: {
    main: ghostWhite,
    light: slateGrey800,
    dark: ghostWhite,
  },
  iconOutlinedColor: {
    main: dippedGreen400,
  },
  iconOutlinedHoverColor: {
    main: dippedGreen200,
  },
  iconContainedHoverColor: {
    main: dippedGreen800,
  },
  iconContainedColor: {
    main: dippedGreen500,
  },
  containedTextColor: {
    main: slateGrey800,
  },
  textLink: {
    main: slateGrey400,
  },
  iconBinButtonColor: {
    main: terraCotta200,
  },
  inputDefaultBorderColor: {
    main: kraft700,
    dark: kraft500,
  },
  inputFileColors: {
    main: kraft200,
    light: kraft600,
  },
  inputListFileColors: {
    main: grey2,
    light: slateGrey900,
  },
  inputListFileIconColors: {
    main: slateGrey700,
    light: slateGrey900,
  },
  labelDefault: {
    main: slateGrey50,
  },
  fieldBackground: {
    main: slateGrey800,
  },
  fieldPlaceholder: {
    main: slateGrey400,
  },
  switchBackground: {
    main: green0,
  },
  iconBtnOutlinedColor: {
    main: dippedGreen500,
  },
  iconBtnContainedColor: {
    main: slateGrey800,
  },
  switchBorderSecondary: {
    main: kraft600,
  },
  menuBorder: {
    main: slateGrey700,
  },
  toolsBackground: {
    main: '#2b332d',
  },
  itemListHover: {
    main: green5,
  },
  dividerDialogForm: {
    main: green1,
  },
  mandatoryText: {
    main: green5,
  },
  backgroundColorAlerteChip: {
    main: terraCotta300,
    contrastText: pureWhiteColor,
  },
  backgroundColorIncidentChip: {
    main: brown2,
    contrastText: slateGrey700,
  },
  noFinishedIconColor: {
    main: grey4,
  },
  finishedImportsIconColor: {
    main: terraCotta300,
  },
  finishedExportsIconColor: {
    main: dippedGreen500,
  },
  attributeBackground: {
    main: grey6,
  },
  widgetHeaderBackground: {
    main: brown4,
  },
  tabsHeaderBackground: {
    main: brown3,
  },
  bgColorWorkFlowChip: {
    main: brown4,
    contrastText: pureWhiteColor,
  },
} as PaletteOptions);
