import { Box, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import QCInputHeader from '../QCInputHeader';
import { falseValue, trueValue } from './const';
import { BoolRadioInputProps } from './types';

const BoolRadioInput = ({ label, info, mandatory, dispatch, value }: BoolRadioInputProps) => {
  const { t } = useTranslation();

  return (
    <Box>
      {label && <QCInputHeader label={label} info={info} mandatory={mandatory} />}
      <RadioGroup row onChange={dispatch} value={value}>
        <FormControlLabel value={trueValue} control={<Radio />} label={t('common.message.yes')} />
        <FormControlLabel value={falseValue} control={<Radio />} label={t('common.message.no')} />
      </RadioGroup>
    </Box>
  );
};

export default BoolRadioInput;
