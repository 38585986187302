import React from 'react';
import { Box } from '@mui/material';
import { useTraductionContext } from '../context';
import QCCountCard from '70.quickConnect.Components/siteAdmin/Other/QCCountCard';

const TradDestFilledFieldsCount = () => {
  const { destLang, destChampsCount } = useTraductionContext();
  return (
    <>
      {destChampsCount.map((ch) => (
        <Box key={ch.lang} component="div" hidden={ch.lang !== destLang.value} sx={{ alignSelf: 'center' }}>
          <QCCountCard count={ch.count} total={ch.total} />
        </Box>
      ))}
    </>
  );
};

export default TradDestFilledFieldsCount;
