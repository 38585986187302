import React from 'react';
import { Box, Typography } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import BarChartIcon from '@mui/icons-material/BarChart';
import useData from './hooks';
import FormDataCard from './FormDataCard';
import { ProgressBar } from './ProgressBar';
import ContentHeader from '70.quickConnect.Components/siteAdmin/Other/ContentHeader';
import MenuWrapper from '10.quickConnect.app/siteAdmin/components/shared/MenuWrapper';
import QCButtonGlobal from '70.quickConnect.Components/siteAdmin/Buttons/QCButtonGlobal';
import { DownloadReportDialog } from '70.quickConnect.Components/siteAdmin/Other/DownloadReportDialog';

const DelcarationContent = () => {
  const {
    t,
    declarationPage,
    goToPageConcepteur,
    declaRef,
    openDownloadDialog,
    handleOpenDownloadDialog,
    handleCloseDownloadDialog,
  } = useData();
  return (
    <MenuWrapper>
      <ContentHeader>
        <ContentHeader.BackButton />
        <ContentHeader.TitlesGroup>
          <ContentHeader.OverTitle>{t('declaration.detail.title')}</ContentHeader.OverTitle>
          <ContentHeader.Title>{declarationPage?.stringExtract}</ContentHeader.Title>
        </ContentHeader.TitlesGroup>
        <ContentHeader.Controls>
          <QCButtonGlobal
            className="concepteur-button"
            startIcon={<EditOutlinedIcon />}
            action={goToPageConcepteur}
            value={t('form.detail.tabs.designer')}
          />
        </ContentHeader.Controls>
      </ContentHeader>
      <Box sx={{ margin: 10, display: 'flex', gap: 5 }}>
        <ProgressBar formEditedData={declarationPage?.formEditedData} targetRef={declaRef} />
        <Box>
          <Box sx={{ display: 'flex' }}>
            <AccountCircleIcon sx={{ color: '#B39875' }} />
            <Typography sx={{ color: '#B39875', fontWeight: 'bold' }}>
              {declarationPage?.user.firstName} {declarationPage?.user.lastName}
            </Typography>
          </Box>
          <Box sx={{ overflow: 'auto', height: '70vh' }} ref={declaRef}>
            {declarationPage?.formEditedData.map((data, index) => (
              <a key={data.id} id={`declaration-${data.id}`}>
                <FormDataCard
                  key={data.id}
                  index={index + 1}
                  totalLength={declarationPage?.formEditedData.length}
                  data={data}
                />
              </a>
            ))}
          </Box>
        </Box>
        <Box sx={{ display: 'flex', flexDirection: 'column', position: 'absolute', right: 20, bottom: 20, gap: 1 }}>
          <QCButtonGlobal
            className="statistics-button"
            variant="outlined"
            startIcon={<BarChartIcon />}
            value={t('declaration.detail.statistics')}
          />
          <QCButtonGlobal
            className="download-report-button"
            startIcon={<ContentPasteIcon />}
            value={t('declaration.report.actions.download')}
            action={handleOpenDownloadDialog}
          />
          <DownloadReportDialog
            open={openDownloadDialog}
            onClose={handleCloseDownloadDialog}
            printableTemplate={
              declarationPage && declarationPage.printableTemplates.length > 0
                ? declarationPage?.printableTemplates[0]
                : undefined
            }
          />
        </Box>
      </Box>
    </MenuWrapper>
  );
};

export default DelcarationContent;
