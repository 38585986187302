import { makeAutoObservable, observable } from 'mobx';

class HeaderStore {
  tabsValueImportExport: 'my' | 'organization';

  tabsValueAlertes: 'my' | 'organization';

  tabsValueActivities: 'my' | 'organization';

  constructor() {
    this.tabsValueImportExport = 'my';
    this.tabsValueAlertes = 'my';
    this.tabsValueActivities = 'my';

    makeAutoObservable(
      this,
      {
        tabsValueActivities: observable,
        tabsValueAlertes: observable,
        tabsValueImportExport: observable,
      },
      { autoBind: true },
    );
  }

  resetStore = () => {
    this.setTabsValueActivities('my');
    this.setTabsValueAlertes('my');
    this.setTabsValueImportExport('my');
  };

  setTabsValueImportExport = (value: 'my' | 'organization') => {
    this.tabsValueImportExport = value;
  };

  setTabsValueAlertes = (value: 'my' | 'organization') => {
    this.tabsValueAlertes = value;
  };

  setTabsValueActivities = (value: 'my' | 'organization') => {
    this.tabsValueActivities = value;
  };
}

export default HeaderStore;
