import axios from 'axios';
import { toast } from 'react-toastify';
import {
  DashboarDetailRequest,
  DashboardInfoRequest,
  GetDeclarationsListRequest,
  GetFormListRequest,
  GetJobStepUserResultsRequest,
} from './request';
import {
  DashboardDetailResponse,
  DashboardInfoResponse,
  GetDeclarationsListResponse,
  GetFormListResponse,
  GetJobStepUserResultsResponse,
} from './response';
import { AlertPreview, DeclarationPreview, FormPreview, isAlertPreviewArray, JobPreview } from './types';
import { useStore } from '30.quickConnect.Store/siteAdmin';
import { ApiForms } from '40.quickConnect.DataAcces/siteAdmin/apiRoutes/forms';
import QCHttpRequest from '40.quickConnect.DataAcces/siteAdmin/QCHttpRequest';
import { isResponseValid } from '40.quickConnect.DataAcces/siteAdmin/responseValidator';
import { ApiDeclarations } from '40.quickConnect.DataAcces/siteAdmin/apiRoutes/declarations';
import { ApiJobs } from '40.quickConnect.DataAcces/siteAdmin/apiRoutes/jobs';
import { ApiDashboard } from '40.quickConnect.DataAcces/siteAdmin/apiRoutes/dashboard';

const FormsPreviewNumber = 6;

export const useHomeServices = () => {
  const {
    LoginStore: { signInInfos },
  } = useStore();
  const userUpn = signInInfos.userUPN;

  const httpRequest = new QCHttpRequest();

  // Forms
  const getForms = async (request: GetFormListRequest) => {
    try {
      const response = await httpRequest.post<GetFormListResponse, GetFormListRequest>(ApiForms.getFormList, request);
      const isValid = isResponseValid(response.data);

      if (isValid) return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) toast.error(error.response?.data?.message);
    }
  };

  const formRequest: GetFormListRequest = {
    length: 6,
    ownerID: userUpn,
    ofThisType: [0, 1, 2, 3],
  };

  const getFormsPreview = async (): Promise<FormPreview[]> => {
    const data = await getForms(formRequest);
    if (!data) return [];

    const forms: FormPreview[] = data.forms.map((form) => ({ ...form }));
    forms.splice(FormsPreviewNumber - 1, forms.length - FormsPreviewNumber);

    return forms;
  };

  // declarations

  const getDeclarations = async (request: GetDeclarationsListRequest) => {
    try {
      const response = await httpRequest.post<GetDeclarationsListResponse, GetDeclarationsListRequest>(
        ApiDeclarations.getDeclarationList,
        request,
      );

      const isValid = isResponseValid(response.data);
      if (isValid) return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) toast.error(error.response?.data?.message);
    }
  };

  const declarationsRequest: GetDeclarationsListRequest = {
    count: 1,
    filters: [],
    columns: [
      { key: 'formType', value: true },
      { key: 'editedAt', value: true },
      { key: 'id', value: true },
      { key: 'stringExtract', value: true },
      { key: 'entityInstanceStringExtract', value: true },
      { key: 'organizationalUnitStringExtract', value: true },
      { key: 'userUPN', value: true },
    ],
    length: 6,
    lightSelect: true,
    orderBy: [{ direction: 1, column: 'editedAt' }],
    start: 0,
    textFilter: null,
    wantToken: true,
  };

  const getDeclarationsPreview = async (): Promise<DeclarationPreview[]> => {
    const data = await getDeclarations(declarationsRequest);
    if (!data) return [];

    const declarationsPreview: DeclarationPreview[] = data.declarations.map((declaration) => ({ ...declaration }));
    return declarationsPreview;
  };

  // jobs
  const getJobs = async (request: GetJobStepUserResultsRequest) => {
    try {
      const response = await httpRequest.post<GetJobStepUserResultsResponse, GetJobStepUserResultsRequest>(
        ApiJobs.getJobStepUserResults,
        request,
      );
      return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) toast.error(error.response?.data?.message);
    }
  };

  const jobsRequest = {
    columns: [
      { key: 'title', value: true },
      { key: 'state', value: true },
      { key: 'createdAt', value: true },
      { key: 'expirationDate', value: true },
      { key: 'size', value: true },
      { key: 'fileExtension', value: true },
      { key: 'id', value: false },
      { key: 'my-jobs.my-jobs.table.storageUri', value: true },
    ],
    count: 1,
    filters: [],
    length: 6,
    orderBy: [{ direction: 1, column: 'createdAt' }],
    start: 0,
    textFilter: null,
  };

  const getJobsPreview = async (): Promise<JobPreview[]> => {
    const data = await getJobs(jobsRequest);
    if (!data) return [];

    const jobsPreview: JobPreview[] = data.data.map((job) => ({ ...job }));
    return jobsPreview;
  };

  // alerts
  const getAlerts = async (request: DashboardInfoRequest) => {
    try {
      const dashboardResponse = await httpRequest.post<DashboardInfoResponse, DashboardInfoRequest>(
        ApiDashboard.getDashboardInfos,
        request,
      );

      const { indicators } = dashboardResponse.data;
      const indicatorId = indicators.find((indicator) => indicator.name === 'Incidents')?.id;
      if (!indicatorId) return;

      const alertsResponse = await httpRequest.post<DashboardDetailResponse, DashboarDetailRequest>(
        ApiDashboard.getDashboardDetails,
        {
          orderBy: [{ direction: 1, column: 'editedAt' }],
          start: 0,
          length: 6,
          count: 1,
          filters: [],
          indicatorId: indicatorId,
          textFilter: null,
        },
      );

      // console.log(alertsResponse.data.indicatorsResult);
      return alertsResponse.data;
    } catch (error) {
      if (axios.isAxiosError(error)) toast.error(error.response?.data.message);
    }
  };

  const getAlertsPreview = async (): Promise<AlertPreview[]> => {
    const data = await getAlerts({ organizationUnitId: null });
    if (!data) return [];
    const { indicatorsResult } = data;

    const alerts = JSON.parse(indicatorsResult);
    if (!Array.isArray(alerts) && !isAlertPreviewArray(alerts)) return [];

    const alertsPreview: AlertPreview[] = [];
    alerts.forEach((alert) => alertsPreview.push({ ...alert }));

    return alertsPreview;
  };

  return { getFormsPreview, getDeclarationsPreview, getJobsPreview, getAlertsPreview };
};
