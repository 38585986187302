import React, { Dispatch, SetStateAction } from 'react';
import useAddFamilyDialogFormData from './hooks';
import QCDialogForm from '70.quickConnect.Components/siteAdmin/Other/QCDialogForm';
import { capitalize } from '80.quickConnect.core/shared/helpers/capitalize';

const AddFamilyDialogForm = ({
  open,
  toggleOpen,
}: {
  open: boolean;
  toggleOpen: Dispatch<SetStateAction<boolean>>;
}) => {
  const { t, supportedLanguages, familyTreeView, handleConfirm } = useAddFamilyDialogFormData();
  return (
    <QCDialogForm open={open} toggleIsOpen={toggleOpen} handleConfirm={handleConfirm}>
      {/* <QCDialogForm.Header title="Ajouter une famille" desc="ajouter famille"></QCDialogForm.Header> */}
      <QCDialogForm.Body
        title={t('entitySchema.entities.actions.add')}
        desc={t('customerProvider.designer.form.general.title')}
        displayStepping
        nbColumn={2}
        index={1}
      >
        <QCDialogForm.Text label={t('entitySchema.entityDesigner.entitySchema.placeholder.name')} mandatory />
        <QCDialogForm.Text label={t('entitySchema.entityDesigner.entitySchema.placeholder.description')} mandatory />

        {familyTreeView.length > 0 ? (
          <QCDialogForm.TreeViewChoice
            allData={familyTreeView}
            label={t('entitySchema.entityDesigner.entitySchema.placeholder.parent')}
            // mandatory
          />
        ) : (
          <></>
        )}
        {supportedLanguages.length > 0 ? (
          <QCDialogForm.Choice
            label={t('common.internationalization.defaultLanguage')}
            options={supportedLanguages.map((lang) => capitalize(lang.lang))}
            mandatory
          />
        ) : (
          <></>
        )}
      </QCDialogForm.Body>
    </QCDialogForm>
  );
};

export default AddFamilyDialogForm;
