import React from 'react';

import { IconButton, useTheme } from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { BackButtonStyle } from '../style';
import type { BackButtonProps } from './type';
import { useSafeNavigation } from '80.quickConnect.core/siteAdmin/hooks/useSafeNavigation';

const BackButton = ({ route, action }: BackButtonProps) => {
  const theme = useTheme();
  const navigate = useSafeNavigation();

  const handleClick = () => {
    if (route) navigate(route);
    else if (action) action();
    else navigate(-1);
  };
  return (
    <IconButton sx={BackButtonStyle(theme)} onClick={handleClick}>
      <ArrowBackIcon />
    </IconButton>
  );
};

export default BackButton;
