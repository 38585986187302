import { useCallback, useMemo, useState } from 'react';
import { useHomeServices } from './services/service';
import { AlertPreview, DeclarationPreview, FormPreview, JobPreview } from './services/types';
import { useStore } from '30.quickConnect.Store/siteAdmin';
import { isWidgetType } from '70.quickConnect.Components/siteAdmin/Other/HomeWidget/types';

export const useHomeData = () => {
  const { getFormsPreview, getDeclarationsPreview, getJobsPreview, getAlertsPreview } = useHomeServices();

  const {
    LoginStore: { signInInfos },
    UserSettingsStore: { defaultsHomeWidgetTypes, setDefaultsHomeWidgetTypes },
  } = useStore();
  const organizationName = signInInfos.organizationalUnitName;

  const [formPreview, setFormPreview] = useState<FormPreview[]>([]);
  const [declarationPreview, setDeclarationPreview] = useState<DeclarationPreview[]>([]);
  const [jobsPreview, setJobsPreview] = useState<JobPreview[]>([]);
  const [alertsPreview, setAlertsPreview] = useState<AlertPreview[]>([]);

  const initFormData = useCallback(async () => {
    const forms = await getFormsPreview();
    setFormPreview(forms);
    //eslint-disable-next-line
  }, []);

  const initDeclData = useCallback(async () => {
    const decls = await getDeclarationsPreview();
    setDeclarationPreview(decls);
    //eslint-disable-next-line
  }, []);

  const initJobsData = useCallback(async () => {
    const jobs = await getJobsPreview();
    setJobsPreview(jobs);
    //eslint-disable-next-line
  }, []);

  const initAlertsData = useCallback(async () => {
    const alerts = await getAlertsPreview();
    setAlertsPreview(alerts);
    //eslint-disable-next-line
  }, []);

  useMemo(() => {
    initFormData();
    initDeclData();
    initJobsData();
    initAlertsData();

    // si local storage a été modifié à une valeur incorrecte
    if (!isWidgetType(defaultsHomeWidgetTypes[0])) setDefaultsHomeWidgetTypes('forms', 0);
    if (!isWidgetType(defaultsHomeWidgetTypes[1])) setDefaultsHomeWidgetTypes('alerts', 1);
    //eslint-disable-next-line
  }, [initFormData, initDeclData, initJobsData, initAlertsData]);

  return { organizationName, formPreview, declarationPreview, jobsPreview, alertsPreview, defaultsHomeWidgetTypes };
};
