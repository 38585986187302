import { useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import useInitSharedListSubMenu from '../hooks/useInitSubMenu';
import useSharedListsServices from '../services';
import { useDeleteDialog } from '80.quickConnect.core/siteAdmin/hooks/useDeleteDialog';
import { useStore } from '30.quickConnect.Store/siteAdmin';
import { DeleteActionType } from '70.quickConnect.Components/siteAdmin/Other/DeleteConfirmationDialog/type';

const useSharedListsHomeData = () => {
  const { t } = useTranslation();
  const { deleteSharedList } = useSharedListsServices();

  const {
    UserSettingsStore: { subscribedFeatures },
  } = useStore();
  const canSendToTrash = useMemo(() => subscribedFeatures['FEAT-007'], [subscribedFeatures]);

  const [openDeleteDialog, setOpenDeleteDialog, deleteState, deleteDispatch] = useDeleteDialog();
  const { openAddDialog, setOpenAddDialog, idsToDelete } = useInitSharedListSubMenu(setOpenDeleteDialog);

  useEffect(() => {
    setOpenDeleteDialog(false);
    switch (deleteState.choice) {
      case DeleteActionType.SendToTrash:
        if (idsToDelete.current.length > 0) {
          deleteSharedList({ ids: idsToDelete.current, sendToRecycleBin: true });
          idsToDelete.current = [];
        }
        break;
      case DeleteActionType.Delete:
        if (idsToDelete.current.length > 0) {
          deleteSharedList({ ids: idsToDelete.current, sendToRecycleBin: false });
          idsToDelete.current = [];
        }
        break;
      case DeleteActionType.Cancel:
        break;
      default:
        const exhaustiveCheck: never = deleteState.choice;
        throw Error(`Unknown delete state type: ${exhaustiveCheck}`);
    }
    //eslint-disable-next-line
  }, [deleteState]);
  return {
    t,
    openAddDialog,
    setOpenAddDialog,
    deleteDispatch,
    openDeleteDialog,
    canSendToTrash,
  };
};

export default useSharedListsHomeData;
