import React, { useContext } from 'react';
import { Box, Collapse, useTheme } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { ConnectionHistoryContext } from '../context';

// eslint-disable-next-line
const LastSyncCell = ({ params }: { params: GridRenderCellParams<any, any, any> }) => {
  const { selectedRowId } = useContext(ConnectionHistoryContext);
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        lineHeight: 1,
        height: params.row.id === selectedRowId ? '120px' : '70px',
        fontSize: '16px',
        gap: 1,
      }}
    >
      <p>{params.value.lastSync}</p>
      <p>
        <Collapse in={params.row.id === selectedRowId}>
          {params.row.id === selectedRowId ? (
            <Box sx={{ display: 'flex', flexDirection: 'column', gap: '8px', color: theme.palette.grey[400] }}>
              <span>Première Connection </span>
              {params.value.firstSync}
            </Box>
          ) : null}
        </Collapse>
      </p>
    </Box>
  );
};

export default LastSyncCell;
