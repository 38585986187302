import { TreeView } from '@mui/lab';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { createItem, findNode } from './hooks';
import { QCTreeViewProps } from './types';

const QCTreeView = ({ data, setValue, sx }: QCTreeViewProps) => {
  return (
    <TreeView
      aria-label="file system navigator"
      defaultCollapseIcon={<ExpandMoreIcon />}
      defaultExpandIcon={<ChevronRightIcon />}
      onNodeSelect={(event: React.SyntheticEvent, nodeIds: Array<string> | string) => {
        setValue(findNode(nodeIds instanceof Array ? nodeIds[0] : nodeIds, data));
      }}
      sx={sx().root}
    >
      {data.map((item) => createItem(item, sx().item))}
    </TreeView>
  );
};

export default QCTreeView;
