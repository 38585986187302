import { SxProps } from '@mui/system';

export const QCInputLabelStyle: SxProps = {
  marginBottom: '1vh',
  fontWeight: 'bold',
  fontSize: '14px',

  display: 'flex',
  alignItems: 'center',
  textTransform: 'uppercase',
};
