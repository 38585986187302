import React from 'react';
import { HOME, ERROR } from '../paths';
import { LinkRouteType } from '../types';
import CommingSoon from './comingSoon';
import Home from '10.quickConnect.app/siteAdmin/components/domains/Home';

const COMMON_ROUTES: LinkRouteType[] = [
  // A compléter quand les composants seront créés

  { path: ERROR, key: 'ERROR', element: <CommingSoon /> } as LinkRouteType,
  { path: HOME, key: 'HOME', element: <Home /> } as LinkRouteType,
];

export default COMMON_ROUTES;
