import { Box, SxProps, TextField, useTheme } from '@mui/material';
import React, { ChangeEvent, useCallback } from 'react';
import QCInputHeader from '../QCInputHeader';
import { InputContainerStyle } from '../style';
import { QCTextAreaProps } from './type';

import useControls from '90.quickConnect.Models/siteAdmin/inputValidator/controls';

const QCTextArea = ({
  label,
  info,
  placeholder,
  dispatch,
  mandatory,
  value,
  onChange,
  control,
  datacy,
}: QCTextAreaProps) => {
  const handleChanges = (change: ChangeEvent<HTMLTextAreaElement>) => {
    if (typeof dispatch !== 'undefined') {
      dispatch(change.target.value);
    }
    if (onChange) onChange(change);
  };
  const theme = useTheme();
  const { notNull } = useControls();

  const useSx = useCallback((): SxProps => {
    if (control) {
      if (control.id === notNull.id) {
        // if (control.regex.exec(value) !== null || value.length === 0) {
        const testRegex = Array.from(value?.match(control.regex) ?? []);
        if ((value ?? '').length === 0 || testRegex.length === 0) {
          return {
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: `${theme.palette.error.main} !important`,
            },
          };
        }
      } else {
        if (value !== '' && control.regex.exec(value ?? '') === null) {
          return {
            '.MuiOutlinedInput-notchedOutline': {
              borderColor: `${theme.palette.error.main} !important`,
            },
          };
        }
      }
    }
    return {};
    //eslint-disable-next-line
  }, [value, control]);

  return (
    <Box sx={InputContainerStyle(theme, mandatory)}>
      {label && <QCInputHeader label={label} info={control ? control.instructions : info} mandatory={mandatory} />}
      <TextField
        value={value}
        placeholder={placeholder}
        onChange={handleChanges}
        maxRows={6}
        multiline
        minRows={6}
        sx={useSx()}
        data-cy={datacy}
      />
    </Box>
  );
};

export default QCTextArea;
