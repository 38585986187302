import { useTranslation } from 'react-i18next';
import {
  EntitySchemaAttributeTypes,
  EntitySchemaAttributeTypesEnum,
} from '90.quickConnect.Models/siteAdmin/enums/family/entitySchemaAttributeType';

export const useReadFamilyAttributeType = () => {
  const { t } = useTranslation();

  const readFamilyAttributeType = (attributeType: EntitySchemaAttributeTypesEnum) => {
    switch (attributeType) {
      case EntitySchemaAttributeTypes.Bool:
        return t('core.helpers.common.entitySchemaAttribute.boolean');
      case EntitySchemaAttributeTypes.Date:
        return t('core.helpers.common.entitySchemaAttribute.date');
      case EntitySchemaAttributeTypes.DateTime:
        return t('core.helpers.common.entitySchemaAttribute.dateTime');
      case EntitySchemaAttributeTypes.Decimal:
        return t('core.helpers.common.entitySchemaAttribute.decimal');
      case EntitySchemaAttributeTypes.Email:
        return t('core.helpers.common.entitySchemaAttribute.email');
      case EntitySchemaAttributeTypes.Int:
        return t('core.helpers.common.entitySchemaAttribute.int');
      case EntitySchemaAttributeTypes.SharedList:
        return t('core.helpers.common.entitySchemaAttribute.sharedList');
      case EntitySchemaAttributeTypes.SharedListHierarchical:
        return t('core.helpers.common.entitySchemaAttribute.sharedListHierarchical');
      case EntitySchemaAttributeTypes.String:
        return t('core.helpers.common.entitySchemaAttribute.string');
      default:
        return t('core.helpers.common.entitySchemaAttribute.unknow');
    }
  };

  const readTranslation = (translation: string) => {
    switch (translation) {
      case t('core.helpers.common.entitySchemaAttribute.boolean'):
        return EntitySchemaAttributeTypes.Bool;
      case t('core.helpers.common.entitySchemaAttribute.date'):
        return EntitySchemaAttributeTypes.Date;
      case t('core.helpers.common.entitySchemaAttribute.dateTime'):
        return EntitySchemaAttributeTypes.DateTime;
      case t('core.helpers.common.entitySchemaAttribute.decimal'):
        return EntitySchemaAttributeTypes.Decimal;
      case t('core.helpers.common.entitySchemaAttribute.email'):
        return EntitySchemaAttributeTypes.Email;
      case t('core.helpers.common.entitySchemaAttribute.int'):
        return EntitySchemaAttributeTypes.Int;
      case t('core.helpers.common.entitySchemaAttribute.sharedList'):
        return EntitySchemaAttributeTypes.SharedList;
      case t('core.helpers.common.entitySchemaAttribute.sharedListHierarchical'):
        return EntitySchemaAttributeTypes.SharedListHierarchical;
      case t('core.helpers.common.entitySchemaAttribute.string'):
        return EntitySchemaAttributeTypes.String;
      default:
        return 0;
    }
  };

  return { readFamilyAttributeType, readTranslation };
};
