import { useMemo, useState } from 'react';
import { ControlListElementType, MandatoryListElementType } from './type';

const useData = () => {
  const [mandatoryList, setMandatoryList] = useState<MandatoryListElementType[]>([]);

  const addMandatoryField = ({ label, filled }: MandatoryListElementType) => {
    if (
      mandatoryList.filter(({ label: comparedLabel }) => {
        return comparedLabel === label;
      }).length === 0
    ) {
      setMandatoryList((prevMandatoryList) => [
        ...prevMandatoryList,
        { label: label, filled: filled } as MandatoryListElementType,
      ]);
    }
  };

  const deleteMandatoryField = (label: string) => {
    if (mandatoryList.filter((mandatoryField) => mandatoryField.label == label).length != 0) {
      setMandatoryList((prevMandatoryList) => {
        return prevMandatoryList.filter((mandatoryField) => mandatoryField.label != label);
      });
    }
  };

  const setFilled = (label: string, filled: boolean) => {
    const mandatoryListCopy = [];

    for (const value of mandatoryList) {
      mandatoryListCopy.push({ label: value.label, filled: value.label === label ? filled : value.filled });
    }

    setMandatoryList(mandatoryListCopy);
  };

  const isAllMandatoryFieldsFilled = useMemo(() => {
    if (mandatoryList.length === 0) return true;
    return (
      mandatoryList.filter(({ filled }) => {
        return filled === false;
      }).length === 0
    );
  }, [mandatoryList]);

  const [controlList, setControlList] = useState<ControlListElementType[]>([]);

  const addControlledField = ({ label, valid }: ControlListElementType) => {
    if (
      controlList.filter(({ label: comparedLabel }) => {
        return comparedLabel === label;
      }).length === 0
    ) {
      setControlList((prevList) => [...prevList, { label: label, valid: valid } as ControlListElementType]);
    }
  };

  const setValid = (label: string, valid: boolean) => {
    const controlListCopy = [];

    for (const value of controlList) {
      controlListCopy.push({ label: value.label, valid: value.label === label ? valid : value.valid });
    }

    setControlList(controlListCopy);
  };

  const isAllControlledFieldValid = useMemo(() => {
    if (controlList.length === 0) return true;
    return (
      controlList.filter(({ valid }) => {
        return valid === false;
      }).length === 0
    );
  }, [controlList]);

  return {
    mandatoryList,
    setMandatoryList,
    addMandatoryField,
    deleteMandatoryField,
    setFilled,
    isAllMandatoryFieldsFilled,
    controlList,
    addControlledField,
    setValid,
    isAllControlledFieldValid,
  };
};

export default useData;
