import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import { UseDataProps } from './types';

import { useStore } from '30.quickConnect.Store/siteAdmin';

const useData = (): UseDataProps => {
  // On récupère le store
  const {
    LoginStore: {
      logOutAsync,
      signInInfos: { userUPN },
      sendLogsToServerAsync,
    },
  } = useStore();

  // On définit le state
  const { t } = useTranslation();

  const onLogOut = useCallback(async () => {
    // Envoi des logs
    const sentLogs = await sendLogsToServerAsync(userUPN);
    if (sentLogs) {
      await logOutAsync(t);
    }
  }, [logOutAsync, t, sendLogsToServerAsync, userUPN]);

  return {
    onLogOut,
  };
};

export default useData;
