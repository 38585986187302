import React from 'react';
import { SxProps } from '@mui/system';
import { InputLabel, TextField, styled } from '@mui/material';

import { brown2, kraft400, kraft600, pureWhiteColor, slateGrey800 } from '00.app/theme/colors';

export const MainBoxStyle: SxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'row',
  backgroundColor: pureWhiteColor,
  backgroundPosition: '50%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundImage: 'url(assets/login_background.jpg)',
  border: {
    xs: '16px solid white',
    sm: '32px solid white',
    md: '48px solid white',
    lg: '64px solid white',
  },
  height: '100vh',
  width: '100vw',
};

export const CardStyle: SxProps = {
  width: { xs: '80vw', md: '80vw' },
  maxWidth: { xs: 500, sm: 500, md: 600, lg: 600 },
  padding: { xs: '16px', md: '32px' },
  backgroundPosition: '50%',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  borderRadius: '18px',
  backgroundImage: 'url(assets/kraft_grid.webp)',
  color: pureWhiteColor,
  fontSize: { xs: '0.8em', md: '1.3em' },
};

export const ArrowStyle: SxProps = {
  position: 'absolute',
  top: 0,
  right: 0,
};

export const BallStyle: SxProps = {
  position: 'absolute',
  bottom: {
    xs: '40px',
    sm: '70px',
    md: '90px',
    lg: '105px',
  },
  right: {
    xs: '35px',
    sm: '65px',
    md: '85px',
    lg: '110px',
  },
};

export const QcLogoStyle: React.CSSProperties = {
  width: '30%',
};

export const CardMediaStyle: SxProps = {
  width: { xs: '30vw', sm: '30vw', md: '12vw', lg: '12vw' },
  margin: { xs: '1vh auto', sm: '1vh auto', md: '2vh auto', lg: '2vh auto' },
};

export const TypoStyle: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  padding: '0.5em',
  color: pureWhiteColor,
  fontSize: { md: '1em' },
};

export const TypoAppVersionStyle: SxProps = () => ({
  ...TypoStyle,
  fontSize: { sm: '0.8em', md: '1em' },
  fontWeight: 'bold',
  color: pureWhiteColor,
});

export const CustomInputLabelStyle = styled(InputLabel)`
  margin: 1em auto 0.8em;
  text-transform: uppercase;
  font-weight: 500;
  color: ${pureWhiteColor};
`;

export const CardActionsStyle: SxProps = {
  display: 'flex',
  justifyContent: 'flex-end',
  padding: 0,
  '& .MuiButton-endIcon > :nth-of-type(1)': {
    fontSize: '1.35em',
  },
  '& .MuiButton-text': {
    fontSize: { xs: '0.7em', md: '1em' },
  },
};

export const CustomTextFieldStyle = styled(TextField)({
  '& .MuiInputBase-root': {
    backgroundColor: pureWhiteColor,
    color: slateGrey800,
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderWidth: '2px',
      borderColor: kraft400,
    },
    '&:hover fieldset': {
      borderWidth: '2px',
      borderColor: kraft600,
    },
    '&.Mui-focused fieldset': {
      borderWidth: '2px',
      borderColor: kraft600,
    },
    '& .MuiInputAdornment-root': {
      '& .MuiButtonBase-root': {
        '& .MuiSvgIcon-root': {
          color: slateGrey800,
        },
      },
    },
  },
});

export const CguBoxStyle: SxProps = {
  display: 'flex',
  justifyContent: 'flex-start',
  fontStyle: 'italic',
  '& .MuiTypography-root': {
    fontSize: { md: '1em' },
  },
};

export const CguLinkStyle: SxProps = {
  display: 'flex',
  alignSelf: 'center',
  fontStyle: 'italic',
  fontSize: { md: '1em' },
};

export const ForgotPasswordStyle: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  fontSize: '0.9em',
};

export const FooterStyle: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  padding: '16px',
};

export const LoginBackStyle: SxProps = {
  paddingLeft: 0,
  color: brown2,
  fontStyle: 'italic',
  textTransform: 'none',
  fontSize: '1em',
  '& .MuiButton-startIcon > :nth-of-type(1)': {
    fontSize: '1.34em',
  },
};
