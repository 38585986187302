import React from 'react';
import {
  Backdrop,
  Box,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  CircularProgress,
  FormControl,
  IconButton,
  InputAdornment,
  Slide,
  Typography,
} from '@mui/material';
// import { useTheme } from '@mui/system';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { ArrowForward, Visibility, VisibilityOff } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';

import { Arrow, Ball } from './icons';

import {
  CardStyle,
  MainBoxStyle,
  TypoStyle,
  TypoAppVersionStyle,
  CardMediaStyle,
  CustomInputLabelStyle,
  CustomTextFieldStyle,
  CardActionsStyle,
  QcLogoStyle,
  ForgotPasswordStyle,
  FooterStyle,
  LoginBackStyle,
} from './styles';
import useData from './hooks';
import { LoginName } from './const';
import useIsMobile from '80.quickConnect.core/shared/hooks/useIsMobile';
import QCButtonGlobal from '70.quickConnect.Components/siteAdmin/Buttons/QCButtonGlobal';

const Login = () => {
  const { NODE_ENV, REACT_APP_ENV, REACT_APP_VERSION } = process.env;
  const appVersion = //10;
    NODE_ENV !== 'production' ? `${REACT_APP_ENV} version ${REACT_APP_VERSION}` : `version ${REACT_APP_VERSION}`;
  const { t } = useTranslation();

  const {
    signInRequest: { userUPN, password },
    setUserUPN,
    setPassword,
    connect,
    showPassword,
    setShowPassword,
    // isLogging,
    isOpenBackdrop,
    showPasswordField,
    changeAuthentificationMethod,
  } = useData();

  const isMobile = useIsMobile();
  // const { breakpoints } = useTheme();

  return (
    <Box data-cy={LoginName} sx={MainBoxStyle}>
      <Arrow />
      {!isMobile && <Ball />}
      <Box>
        <Card sx={CardStyle}>
          <form id="login" onSubmit={(e) => e.preventDefault()}>
            <CardMedia
              component="img"
              alt="france-piste-cyclable-transfrontaliere"
              image="/assets/logo_QC_medium_standard_HD.png"
              style={QcLogoStyle}
              sx={CardMediaStyle}
            />
            <CardContent>
              <Typography variant="body2" sx={TypoStyle}>
                {t('descriptionApp')}
              </Typography>
              {!showPasswordField ? (
                <Box>
                  <CustomInputLabelStyle sx={{ fontSize: { md: '1em' }, fontWeight: 'bold' }}>
                    {t('login.firstStep.id')}
                  </CustomInputLabelStyle>
                  <CustomTextFieldStyle
                    data-cy="userUPN-input"
                    margin="none"
                    required
                    fullWidth
                    id="email"
                    name="email"
                    autoComplete="email"
                    autoFocus={!showPasswordField}
                    value={userUPN}
                    onChange={(event: React.ChangeEvent<{ value: string }>) => setUserUPN(event.target.value)}
                    onKeyDown={(event: React.KeyboardEvent): void => {
                      if (event.key === 'Enter') connect();
                    }}
                  />
                </Box>
              ) : (
                <QCButtonGlobal
                  sx={LoginBackStyle}
                  startIcon={<ArrowBackIcon />}
                  variant="text"
                  className="login-back"
                  data-cy="arrow-back-login"
                  value={userUPN}
                  action={changeAuthentificationMethod}
                />
              )}
              <Slide direction="left" in={showPasswordField} mountOnEnter unmountOnExit>
                <Box position={showPasswordField ? 'relative' : 'absolute'}>
                  <CustomInputLabelStyle htmlFor="outlined-adornment-password" sx={{ fontWeight: 'bold' }}>
                    {t('password')}
                  </CustomInputLabelStyle>
                  <FormControl variant="outlined" fullWidth>
                    <CustomTextFieldStyle
                      data-cy="password-input"
                      id="outlined-adornment-password"
                      type={showPassword ? 'text' : 'password'}
                      value={password}
                      autoFocus={showPasswordField}
                      onChange={(event: React.ChangeEvent<{ value: string }>) => setPassword(event.target.value)}
                      required
                      autoComplete="new-password"
                      onKeyDown={(event: React.KeyboardEvent): void => {
                        if (event.key === 'Enter') connect();
                      }}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                              onMouseDown={() => setShowPassword(!showPassword)}
                              edge="end"
                            >
                              {showPassword ? <Visibility /> : <VisibilityOff />}
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                    />
                  </FormControl>
                </Box>
              </Slide>
            </CardContent>
            <Box sx={FooterStyle}>
              <Box sx={ForgotPasswordStyle}>
                <Box component="span">{t('login.forgotPassword.button')}</Box>
                <Box component="span">{t('login.forgotPassword.contact')}</Box>
              </Box>
              <CardActions sx={CardActionsStyle}>
                <QCButtonGlobal
                  data-cy="login-btn"
                  variant="contained"
                  action={connect}
                  className="login-btn"
                  endIcon={<ArrowForward />}
                  value={!showPasswordField ? t('login.forgotPassword.firstStep.next') : t('login.secondStep.validate')}
                />
              </CardActions>
            </Box>
          </form>
        </Card>
        <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
          <Typography sx={TypoAppVersionStyle}>{appVersion}</Typography>
        </Box>
      </Box>
      <Backdrop open={isOpenBackdrop}>
        <CircularProgress />
      </Backdrop>
    </Box>
  );
};

export default observer(Login);
