import { SxProps } from '@mui/system';

export const MainBox = { display: 'flex', justifyContent: 'center', alignItems: 'center', flexGrow: 1 };

export const Content: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  height: '200px',
  width: '400px !important',
  justifyContent: 'center',
  alignItems: 'center',
  gap: '20px',
  whiteSpace: 'pre',
  textAlign: 'center',
  fontWeight: 'bold',
  fontSize: '1.5em',
};
