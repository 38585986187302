import { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import { AxiosError } from 'axios';
import { DeleteDeclarationsRequest, GetDeclarationRequest, GetDeclarationsListRequest } from './requests';
import { DeleteDeclarationsResponse, GetDeclarationResponse, GetDeclarationsListResponse } from './responses';
import { ApiDeclarations } from '40.quickConnect.DataAcces/siteAdmin/apiRoutes/declarations';
import QCHttpRequest from '40.quickConnect.DataAcces/siteAdmin/QCHttpRequest';
import { isResponseValid } from '40.quickConnect.DataAcces/siteAdmin/responseValidator';
import { extractFileName } from '80.quickConnect.core/siteAdmin/helpers/extractFileName';

const useServices = () => {
  const requestHandler = useMemo(() => new QCHttpRequest(), []);

  const getDeclarationHistoriques = useCallback(
    async (request: GetDeclarationsListRequest) => {
      const response = await requestHandler.post<GetDeclarationsListResponse, GetDeclarationsListRequest>(
        ApiDeclarations.getDeclarationList,
        request,
      );
      if (isResponseValid(response.data)) {
        return response.data;
      }
    },
    [requestHandler],
  );

  const deleteDeclarations = useCallback(
    async (request: DeleteDeclarationsRequest) => {
      const response = await requestHandler.post<DeleteDeclarationsResponse, DeleteDeclarationsRequest>(
        ApiDeclarations.deleteDeclarations,
        request,
      );
      if (isResponseValid(response.data)) {
        return response.data;
      }
    },
    [requestHandler],
  );

  const getDeclaration = useCallback(
    async (request: GetDeclarationRequest) => {
      const response = await requestHandler.post<GetDeclarationResponse, GetDeclarationRequest>(
        ApiDeclarations.getDeclaration,
        request,
      );

      if (isResponseValid(response.data)) {
        return response.data;
      }
    },
    [requestHandler],
  );

  /**
   * WARNING : la récupération du fichier ne marche pas bien car ce fichier téléchargé est illisible !
   */
  const downloadTemplate = useCallback(
    async (customtemplateid: string, declarationId: string, aspdf: boolean) => {
      const params = aspdf
        ? {
            customtemplateid: customtemplateid,
            declarationId: declarationId,
            aspdf: aspdf,
          }
        : {
            customtemplateid: customtemplateid,
            declarationId: declarationId,
          };
      try {
        const response = await requestHandler.get<File>(ApiDeclarations.downloadTemplate, {
          params: params,
        });
        if (response.status === 200) {
          const filename = extractFileName(response, 'attachment');
          const blob = new Blob([response.data], {
            type: aspdf ? 'application/pdf' : 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          });
          return new File([blob], filename);
        }
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      }
    },
    [requestHandler],
  );

  return {
    getDeclarationHistoriques,
    deleteDeclarations,
    getDeclaration,
    downloadTemplate,
  };
};

export default useServices;
