import { I18nEntity } from './types';

export const defaultTranslateEntity: I18nEntity = {
  id: '',
  description: '',
  name: '',
  defaultLanguage: '',
  entityId: '',
  entityType: 0,
  eTag: '',
  fields: [],
  translate: [],
};

export const defaultSupportedLang = {
  lang: 'français',
  value: 'default',
};

export const defaultTitle = 'DefaultOverTitle';
