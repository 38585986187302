import React from 'react';
import {
  ACTIVITIES,
  ALERTS,
  CHANGEPASSWORD,
  DECLARATION,
  DECLARATIONS,
  ENTITY,
  ENTITYTRANSLATION,
  EQUIPMENTS,
  EQUIPMENTSETTINGS,
  IMPORTSEXPORTS,
  MYACCOUNT,
  ROLETRANSLATION,
  SCAN,
  SHAREDLISTTRANSLATION,
  TODOLIST,
  TODOLISTS,
} from '../paths';
import { LinkRouteType } from '../types';
import ComingSoon from './comingSoon';
import COMMON_ROUTES from './commonRoutes';
import MyAccount from '10.quickConnect.app/siteAdmin/components/domains/MyAccount';
import RessourcesHome from '10.quickConnect.app/siteAdmin/components/domains/Ressources/Home';
import ResourcesList from '10.quickConnect.app/siteAdmin/components/domains/Ressources/ResourcesList';
import ResourceSettings from '10.quickConnect.app/siteAdmin/components/domains/Ressources/ResourceSettings';
import TraductionPage from '10.quickConnect.app/siteAdmin/components/shared/TraductionPage';
import DeclarationHistory from '10.quickConnect.app/siteAdmin/components/domains/DeclarationHistory';
import DelcarationContent from '10.quickConnect.app/siteAdmin/components/domains/DeclarationHistory/DeclarationContent';

const ANALYST_ROUTES: LinkRouteType[] = [
  // A compléter quand les composants seront créés
  ...COMMON_ROUTES,

  { path: MYACCOUNT, key: 'MYACCOUNT', element: <MyAccount /> } as LinkRouteType,
  { path: CHANGEPASSWORD, key: 'CHANGEPASSWORD', element: <ComingSoon /> } as LinkRouteType,

  { path: DECLARATIONS, key: 'DECLARATIONS', element: <DeclarationHistory /> } as LinkRouteType,
  { path: DECLARATION, key: 'DECLARATION', element: <DelcarationContent /> } as LinkRouteType,
  { path: SCAN, key: 'SCAN', element: <ComingSoon /> } as LinkRouteType,

  { path: ALERTS, key: 'ALERTS', element: <ComingSoon /> } as LinkRouteType,
  { path: ACTIVITIES, key: 'ACTIVITIES', element: <ComingSoon /> } as LinkRouteType,
  { path: IMPORTSEXPORTS, key: 'IMPORTSEXPORTS', element: <ComingSoon /> } as LinkRouteType,

  { path: ENTITY, key: 'ENTITY', element: <ResourcesList /> } as LinkRouteType,
  { path: EQUIPMENTS, key: 'EQUIPMENTS', element: <RessourcesHome /> } as LinkRouteType,
  { path: TODOLISTS, key: 'TODOLISTS', element: <ComingSoon /> } as LinkRouteType,

  //READONLY
  { path: EQUIPMENTSETTINGS, key: 'EQUIPMENTSETTINGS', element: <ResourceSettings /> } as LinkRouteType,
  { path: TODOLIST, key: 'TODOLIST', element: <ComingSoon /> } as LinkRouteType,

  //EXPORT ONLY
  { path: ENTITYTRANSLATION, key: 'ENTITYTRANSLATION', element: <TraductionPage /> } as LinkRouteType,
  { path: ROLETRANSLATION, key: 'ROLETRANSLATION', element: <TraductionPage /> } as LinkRouteType,
  { path: SHAREDLISTTRANSLATION, key: 'SHAREDLISTTRANSLATION', element: <TraductionPage /> } as LinkRouteType,
];

export default ANALYST_ROUTES;
