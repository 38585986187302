import React from 'react';
import { Box, Button, IconButton, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';

import { useData } from '../hooks';
import { buttonStyles } from '../styles';
import { QCMenuButtonProps } from './types';
import { useSafeNavigation } from '80.quickConnect.core/siteAdmin/hooks/useSafeNavigation';

const QCMenuButton = ({ text, icon, route, isOpenSideMenu }: QCMenuButtonProps) => {
  const theme = useTheme();
  const { focusedBtn, setFocusedBtn } = useData();

  const { t } = useTranslation();
  const navigate = useSafeNavigation();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: isOpenSideMenu ? 'stretch' : 'center',
      }}
    >
      {isOpenSideMenu ? (
        <Button
          data-cy={`${text}`}
          startIcon={icon}
          sx={text === focusedBtn ? buttonStyles(theme, true, true) : buttonStyles(theme, false, true)}
          onClick={() => {
            setFocusedBtn(text);
            navigate(route);
          }}
        >
          {t(`navigation.menu.${text}`)}
        </Button>
      ) : (
        <IconButton
          data-cy={`${text}`}
          sx={text === focusedBtn ? buttonStyles(theme, true, false) : buttonStyles(theme, false, false)}
          onClick={() => {
            setFocusedBtn(text);
            navigate(route);
          }}
        >
          {icon}
        </IconButton>
      )}
    </Box>
  );
};

export default observer(QCMenuButton);
