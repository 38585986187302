import React, { useCallback } from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Box, Drawer, IconButton, useTheme } from '@mui/material';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { adminNavElements, dataNavElements, navElements } from '../const';
import {
  ButtonsBoxStyles,
  buttonStyles,
  DrawerBoxStyle,
  FoldedMenuBoxStyle,
  MenuBoxStyle,
  OpenedMenuBoxStyle,
} from './styles';
import { useData } from './hooks';
import QCMenuCollapse from './Collapses';
import QCMenuButton from './Buttons';
import { getRoleValue } from '80.quickConnect.core/siteAdmin/helpers/getRoleValue';
import { QCLogoM, QCLogoS } from '00.app/icons/QCLogoIcon';
import QCFabCorbeille from '70.quickConnect.Components/siteAdmin/Buttons/QCFabCorbeille';
import QCButtonGlobal from '70.quickConnect.Components/siteAdmin/Buttons/QCButtonGlobal';
import { BIN } from '10.quickConnect.app/siteAdmin/routes/paths';
import { pureWhiteColor } from '00.app/theme/colors';
import { Features } from '90.quickConnect.Models/siteAdmin/enums/features/features';
import { useSafeNavigation } from '80.quickConnect.core/siteAdmin/hooks/useSafeNavigation';

const SideMenu = () => {
  const { isOpenSideMenu, setIsOpenSideMenu, focusedBtn, setFocusedBtn, role, subscribedFeatures } = useData();
  const theme = useTheme();
  const { t } = useTranslation();
  const navigate = useSafeNavigation();

  const toggleMenu = useCallback(() => {
    setIsOpenSideMenu(!isOpenSideMenu);
  }, [isOpenSideMenu, setIsOpenSideMenu]);

  return (
    <Drawer
      anchor={theme.direction === 'ltr' ? 'left' : 'right'}
      variant="permanent"
      open={isOpenSideMenu}
      sx={DrawerBoxStyle(theme, isOpenSideMenu)}
    >
      <Box sx={MenuBoxStyle}>
        {isOpenSideMenu ? (
          <Box sx={OpenedMenuBoxStyle}>
            <QCLogoM />
            <IconButton data-cy="arrow-btn" disableFocusRipple onClick={toggleMenu}>
              <ChevronLeftIcon sx={{ color: pureWhiteColor }} />
            </IconButton>
          </Box>
        ) : (
          <Box sx={FoldedMenuBoxStyle}>
            <QCLogoS />
            <IconButton data-cy="arrow-btn" disableFocusRipple onClick={toggleMenu}>
              <ChevronRightIcon sx={{ color: pureWhiteColor }} />
            </IconButton>
          </Box>
        )}
        <Box sx={ButtonsBoxStyles}>
          {navElements.map((navEl) => {
            switch (navEl.name) {
              case 'home':
              case 'forms':
              case 'declarations':
              case 'scan':
                return (
                  getRoleValue(role) >= getRoleValue(navEl.authorizationRole) && (
                    <QCMenuButton
                      key={navEl.name}
                      text={navEl.name}
                      icon={navEl.Icon}
                      route={navEl.path}
                      isOpenSideMenu={isOpenSideMenu}
                    />
                  )
                );
              case 'data':
              case 'admin':
                return (
                  getRoleValue(role) >= getRoleValue(navEl.authorizationRole) && (
                    <QCMenuCollapse
                      key={navEl.name}
                      text={navEl.name}
                      icon={navEl.Icon}
                      routes={navEl.name == 'data' ? dataNavElements : adminNavElements}
                      isOpenSideMenu={isOpenSideMenu}
                    />
                  )
                );
            }
          })}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: isOpenSideMenu ? 'space-between' : 'center' }}>
          {subscribedFeatures[Features.Bin] && (
            <QCFabCorbeille
              buttonType="btn-icon"
              onClickFunc={() => {
                setFocusedBtn('bin');
                navigate(BIN);
              }}
              sx={
                focusedBtn == 'bin'
                  ? buttonStyles(theme, true, isOpenSideMenu)
                  : buttonStyles(theme, false, isOpenSideMenu)
              }
            />
          )}
          {isOpenSideMenu && (
            <QCButtonGlobal
              className="QuickConnect Version"
              variant="text"
              value={t('navigation.version', { number: '0.0.1beta' })}
              sx={{ fontSize: '100%', fontWeight: 'normal', color: pureWhiteColor, textTransform: 'none' }}
            />
          )}
        </Box>
      </Box>
    </Drawer>
  );
};

export default observer(SideMenu);
