import React from 'react';
import { Box, Tab, Tabs, useTheme } from '@mui/material';
import DoneIcon from '@mui/icons-material/Done';
import { useTraductionContext } from '../context';
import { TraductionTabsSx } from '../styles';
import useData from './hooks';
import { green4 } from '00.app/theme/colors';

const TraductionLangsTabs = () => {
  const { sourceLang, destLang, supportedLangs } = useTraductionContext();
  const theme = useTheme();
  const { handleTabsChange, checkChamps } = useData();

  return (
    <Tabs
      value={destLang.value}
      onChange={handleTabsChange}
      variant="scrollable"
      scrollButtons="auto"
      sx={TraductionTabsSx(theme)}
    >
      {supportedLangs
        .filter((elem) => elem.lang !== sourceLang.lang)
        .map((elem) => (
          <Tab
            icon={checkChamps(elem.value) ? <DoneIcon sx={{ color: green4 }} /> : <></>}
            iconPosition="start"
            label={
              <Box component={'p'} sx={{ fontWeight: 'bold' }}>
                {elem.lang}
              </Box>
            }
            value={elem.value}
            key={elem.value}
          />
        ))}
    </Tabs>
  );
};

export default TraductionLangsTabs;
