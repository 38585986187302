import { Dispatch } from 'react';

export type ResetConfirmationProps = {
  open: boolean;
  dispatch: Dispatch<ResetAction>;
};

export enum ResetActionType {
  Reset = 'Reset',
  Cancel = 'Cancel',
}

export type ResetAction = {
  type: ResetActionType;
  payload?: number;
};

export type ReducerResetState = {
  isReset: boolean;
};

export const resetConfirmationReducer = (state: ReducerResetState, action: ResetAction): ReducerResetState => {
  switch (action.type) {
    case ResetActionType.Reset:
      return { isReset: true };
    case ResetActionType.Cancel:
      return { isReset: false };
  }
};
