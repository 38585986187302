import { styled, Switch } from '@mui/material';
import { kraft700 } from '00.app/theme/colors';

const QCSwitch = styled(Switch)(({ theme, checked }) => ({
  padding: 8,
  '& .MuiSwitch-track': {
    border: `2px solid`,
    borderRadius: '14px 14px 14px 14px',
    content: '""',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    width: '40px',
    height: '20px',
    opacity: '100',
    boxShadow: 'none',
    '&:before, &:after': {},
    '&:before': {},
    '&:after': {},
    borderColor: checked ? theme.palette.primary.main : theme.palette.switchBorderSecondary.main,
    backgroundColor: theme.palette.background.default,
    '& .Mui-checked': {
      backgroundColor: theme.palette.background.default,
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: 'none',
    width: '14px',
    height: '14px',
    marginLeft: '2px',
    marginTop: '3px',
    backgroundColor: checked ? theme.palette.background.default : kraft700,
    opacity: '100',
  },
  '& .MuiSwitch-switchRoot': {
    backgroundColor: theme.palette.background.default,
  },
}));

// const QCSwitch = ({ dispatch, checked = false, ...props }: QCSwitchProps & SwitchProps) => {
//   const theme = useTheme();
//   const [isChecked, setIsChecked] = useState<boolean>(checked);
//   const handleChange = (event: ChangeEvent<HTMLInputElement>, checkedValue: boolean) => {
//     setIsChecked(checkedValue);
//     if (dispatch) dispatch(checkedValue);
//   };

//   // useEffect(() => {
//   //   setIsChecked(checked);
//   // }, [checked]);

//   return (
//     <QCStyledSwitch
//       checked={isChecked}
//       onChange={handleChange}
//       {...props}
//       sx={{
//         '& .MuiSwitch-switchRoot': {
//           backgroundColor: theme.palette.background.default,
//         },
//         // '& .MuiSwitch-switchBase': {
//         //   color: theme.palette.background.default,
//         // },
//         '& .MuiSwitch-track': {
//           borderColor: checked ? theme.palette.primary.main : theme.palette.switchBorderSecondary.main,
//           backgroundColor: theme.palette.background.default,
//           '& .Mui-checked': {
//             backgroundColor: theme.palette.background.default,
//           },
//         },
//         '& .MuiSwitch-thumb': {
//           backgroundColor: checked ? theme.palette.background.default : kraft700,
//           opacity: '100',
//         },
//       }}
//     />
//   );
// };

export default QCSwitch;
