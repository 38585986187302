import { IconButton } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import React, { Dispatch } from 'react';

/**
 * Une petite icône croix qui est affichée au bout de chaque élément dans la liste au survol de la souris.
 * @component
 * @param {Dispatch<unknown>} [action] - L'action déclenchée quand l'icône est cliquée.
 * @example
 * const handleClick = () => {...}
 * <QCIconClear action={handleClick} />
 */
const QCIconClear = ({ action }: { action?: Dispatch<unknown> }) => {
  return (
    <IconButton size="small" onClick={action}>
      <ClearIcon sx={{ fontSize: '10px' }} color="primary" />
    </IconButton>
  );
};

export default QCIconClear;
