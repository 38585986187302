import { useCallback, useEffect, useReducer, useState } from 'react';
import { useParams } from 'react-router';
import { DesignResourceRequest, PrintResourceRequest, UpdateResourceRequest } from '../services/requests';
import useResourcesServices from '../services/resources';
import { DesignResourceResponse } from '../services/response';
import { EntitySchemaAttributeTypes } from '90.quickConnect.Models/siteAdmin/enums/equipments/entitySchemaAttributeTypes';
import { useDeleteDialog } from '80.quickConnect.core/siteAdmin/hooks/useDeleteDialog';
import { saveConfirmationReducer } from '70.quickConnect.Components/siteAdmin/Other/SaveConfirmationDialog/type';
import { DeleteActionType } from '70.quickConnect.Components/siteAdmin/Other/DeleteConfirmationDialog/type';
import { TreeViewNode } from '70.quickConnect.Components/siteAdmin/Other/TreeView/types';
import { useSafeNavigation } from '80.quickConnect.core/siteAdmin/hooks/useSafeNavigation';
import { TargetData } from '90.quickConnect.Models/siteAdmin/models/common/entities';

const useData = () => {
  const { entityId, equipmentId } = useParams();
  const navigate = useSafeNavigation();
  const { getDesignResourceData, updateResource, deleteResource, printResource } = useResourcesServices();
  const [openCharacDialog, setOpenCharacDialog] = useState<boolean>(false);
  const [resSettingsData, setResSettingsData] = useState<DesignResourceResponse | undefined>(undefined);
  const [updateResourceData, setUpdateResourceData] = useState<UpdateResourceRequest | undefined>(undefined);
  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [saveState, saveDispatch] = useReducer(saveConfirmationReducer, { isSaved: false });
  const [openDeleteDialog, setOpenDeleteDialog, deleteState, deleteDispatch] = useDeleteDialog();

  useEffect(() => {
    switch (saveState.isSaved) {
      case true:
        setOpenSaveDialog(false);
        if (updateResourceData) {
          updateResource(updateResourceData);
        }
        break;
      case false:
        setOpenSaveDialog(false);
        break;
    }
    //eslint-disable-next-line
  }, [saveState]);

  useEffect(() => {
    switch (deleteState.choice) {
      case DeleteActionType.Delete:
        setOpenDeleteDialog(false);
        if (updateResourceData && updateResourceData.id) {
          deleteResource(
            {
              ids: [updateResourceData.id],
              entitySchemaId: updateResourceData.entitySchemaId,
              sendToRecycleBin: false,
            },
            updateResourceData.name,
          );
          navigate(-1);
        }
        break;
      case DeleteActionType.Cancel:
      default:
        setOpenDeleteDialog(false);
        break;
    }
    //eslint-disable-next-line
  }, [deleteState]);

  const initResource = useCallback(async () => {
    const request = {
      id: equipmentId,
      entitySchemaId: entityId,
    } as DesignResourceRequest;
    const resource = await getDesignResourceData(request);
    setResSettingsData(resource);
    const initUpdateResource = {
      AddDtoToResponse: true,
      code: resource?.data.code,
      eTag: resource?.data.eTag,
      entitySchemaId: resource?.data.entitySchemaId,
      id: resource?.data.id,
      isActive: resource?.data.isActive,
      name: resource?.data.name,
      organizationUnitId: resource?.data.organizationalUnitId,
      qcTagCode: resource?.data.qcTagCode,
      title: resource?.data.title,
      attributes: resource?.data.attributes,
    };
    initUpdateResource.attributes?.forEach(
      (att) =>
        (att.type =
          resource?.schema.attributes.find((el) => el.id == att.attributeId)?.type ??
          EntitySchemaAttributeTypes.String),
    );
    setUpdateResourceData(initUpdateResource as UpdateResourceRequest);
    //eslint-disable-next-line
  }, [equipmentId, entityId]);

  useEffect(() => {
    initResource();
  }, [initResource]);

  const handleDelete = () => {
    if (resSettingsData?.canDelete) setOpenDeleteDialog(true);
  };

  const handleSave = () => {
    if (resSettingsData?.canSave) setOpenSaveDialog(true);
  };

  const handleDownload = () => {
    console.log('à faire, télécharger');
  };

  const handleChangeResName = (value: string) => {
    setUpdateResourceData((prevRes) => {
      if (prevRes) {
        const newRes = { ...prevRes };
        newRes.title = value;
        return newRes;
      }
    });
  };

  const handleChangeResQcTagCode = (value: string) => {
    setUpdateResourceData((prevRes) => {
      if (prevRes) {
        const newRes = { ...prevRes };
        newRes.qcTagCode = value;
        return newRes;
      }
    });
  };

  const handleChangeResOrg = (value: TreeViewNode) => {
    setUpdateResourceData((prevRes) => {
      if (prevRes) {
        const newUpdatedUser = { ...prevRes };
        newUpdatedUser.ouId = value.id;
        return newUpdatedUser;
      }
    });
  };

  const handleChangeResId = (value: string) => {
    setUpdateResourceData((prevRes) => {
      if (prevRes) {
        const newRes = { ...prevRes };
        newRes.code = value;
        return newRes;
      }
    });
  };

  const handlePrint = async () => {
    if (entityId && equipmentId) {
      const res = await printResource({
        entitySchemaId: entityId,
        entityDataIds: [equipmentId],
      } as PrintResourceRequest);
      const resourceBlob = new Blob([res], { type: 'text/html;charset=UTF-8' });
      const url = window.URL.createObjectURL(resourceBlob);
      window.open(url);
      setTimeout(function () {
        URL.revokeObjectURL(url);
      }, 3000);
    }
  };

  // partager la saisi d'email
  const [currentEmail, setCurrentEmail] = useState<TargetData>({} as TargetData);
  const [emails, setEmails] = useState<TargetData[]>([]);

  return {
    resSettingsData,
    updateResourceData,
    setUpdateResourceData,
    handleDelete,
    handleSave,
    handleDownload,
    saveDispatch,
    openSaveDialog,
    deleteDispatch,
    openDeleteDialog,
    handleChangeResName,
    handleChangeResQcTagCode,
    handleChangeResOrg,
    handleChangeResId,
    handlePrint,
    openCharacDialog,
    setOpenCharacDialog,
    currentEmail,
    setCurrentEmail,
    emails,
    setEmails,
  };
};

export default useData;
