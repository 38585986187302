import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import { ControlProps } from '70.quickConnect.Components/siteAdmin/Other/QCDialogForm/StepBody/type';

const useControls = () => {
  const { t } = useTranslation();
  return {
    email: {
      id: 0,
      regex: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
      instructions: t('control.input.email'),
    } as ControlProps,
    password: {
      id: 1,
      regex:
        /^(?=.*[A-ZÀ-Ý])(?=.*[a-zà-ý])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.*[*.!@#$%^&(){}[\]:;<>,.?/~_ +-=|\\]).{8,}$/,
      instructions: (
        <Box>
          {t('control.input.password')}
          <ul>
            <li>{t('control.input.password.length')}</li>
            <li>{t('control.input.password.uppercase')}</li>
            <li>{t('control.input.password.lowercase')}</li>
            <li>{t('control.input.password.number')}</li>
            <li>{t('control.input.password.special')}</li>
          </ul>
        </Box>
      ),
    } as ControlProps,
    login: {
      id: 2,
      regex: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
      instructions: t('control.input.login'),
    } as ControlProps,
    phone: {
      id: 3,
      regex: /[0-9]+$/,
      instructions: t('control.input.phone'),
    },
    notNull: {
      id: 4,
      regex: /\S/gm,
      instructions: t('control.input.notnull'),
    },
  };
};

export default useControls;
