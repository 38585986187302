import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import axios from 'axios';
import { UseDataProps } from './types';
import { SignInRequest } from '40.quickConnect.DataAcces/siteAdmin/services/login/payloads/requests';
import {
  DispatcherResponse,
  SignInResponse,
} from '40.quickConnect.DataAcces/siteAdmin/services/login/payloads/responses';
import { useStore } from '30.quickConnect.Store/siteAdmin';
import { CustomAxiosError } from '40.quickConnect.DataAcces/siteAdmin/QCHttpRequest/types';
import useEffectOnce from '80.quickConnect.core/shared/hooks/useEffectOnce';
import { IdentityProviders } from '90.quickConnect.Models/siteAdmin/enums/user/identityProviders';
import { ApiAccount } from '40.quickConnect.DataAcces/siteAdmin/apiRoutes';
import { getRoleFromRigths } from '80.quickConnect.core/siteAdmin/helpers/getRoleFromRigths';

// eslint-disable-next-line
const useData = (): UseDataProps => {
  // On récupère le store
  const {
    LoginStore: {
      logInAsync,
      isLogging,
      setIsLogging,
      authenticationMethod,
      setAuthenticationMethod,
      verifyFieldUpnAndCgu,
      connectByMsal,
      signInInfos,
      dispatcherAsync,
      setUrlToUse,
      setSignInInfos,
      connected,
    },
    LoginMsalStore: { handleRedirectPromise },
    UserSettingsStore: { setRole },
  } = useStore();

  // On set le state
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [signInRequest, setsignInRequest] = useState<SignInRequest>({
    userUPN: signInInfos.userUPN,
    password: '',
    cgu: true,
  } as SignInRequest);

  const showPasswordField = useMemo(() => authenticationMethod === 0, [authenticationMethod]);

  const changeAuthentificationMethod = useCallback(() => {
    setAuthenticationMethod(
      authenticationMethod === IdentityProviders.AzureActiveDirectory
        ? IdentityProviders.QuickConnect
        : IdentityProviders.AzureActiveDirectory,
    );
  }, [setAuthenticationMethod, authenticationMethod]);

  // On ajoute des hooks
  const setUserUPN = (userUPN: string): void => {
    // eslint-disable-next-line
    setsignInRequest((prevState: any) => ({
      ...prevState,
      userUPN,
    }));
  };

  const setPassword = (password: string): void => {
    // eslint-disable-next-line
    setsignInRequest((prevState: any) => ({
      ...prevState,
      password,
    }));
  };

  /**
   * Permet de garder le BackDrop open dans le cas d'une connexion par MSAL
   */
  const isOpenBackdrop = useMemo(
    (): boolean => (!isLogging && !connectByMsal ? false : true),
    [isLogging, connectByMsal],
  );

  const connect = useCallback(async (): Promise<void> => {
    if (verifyFieldUpnAndCgu(signInRequest, t)) {
      try {
        if (process.env.REACT_APP_ENV === 'demo') {
          await logInAsync(signInRequest, t);
        } else {
          const result: DispatcherResponse | undefined = await dispatcherAsync({ userUpn: signInRequest.userUPN });
          if (result) {
            // if (result.availableEnvironment.length > 1) {
            //   const customerNames = result.availableEnvironment.map((item) => item.customerName);
            //   toast.error(t('dispatcher', { customerName: customerNames }));
            //   setIsLogging(false);
            // } else if (
            //   result.availableEnvironment.length == 1 &&
            //   process.env.REACT_APP_QC_API_ENDPOINT?.search(
            //     // eslint-disable-next-line
            //     (result.availableEnvironment[0] as any).urlQuickConnect as any,
            //   ) == -1
            // ) {
            //   // dans le cas où on va changer la base url

            //   setUrlToUse(`${result.availableEnvironment[0].urlQuickConnect}/api/`);

            //   await logInAsync(signInRequest, t);
            // } else {
            //   await logInAsync(signInRequest, t);
            // }
            setUrlToUse(`${result.availableEnvironment[0].urlQuickConnect}/api/`);
            await logInAsync(signInRequest, t);
          }
        }
      } catch (error) {
        // eslint-disable-next-line
        const errorAxios = error as CustomAxiosError<any>;
        if (errorAxios?.response?.status === 404) {
          // toast.error(t('logInAsync.userNotFound').toString());
          if (authenticationMethod === IdentityProviders.AzureActiveDirectory) changeAuthentificationMethod();
          else toast.error(t('logInAsync.errorWithMsg', { msg: t('logInAsync.userOrPwdNotFound') }));
          setIsLogging(false);
        }
        if (errorAxios?.response?.status === 400) {
          toast.error('erreur 400');
          setIsLogging(false);
        }
      }
    }
  }, [
    verifyFieldUpnAndCgu,
    signInRequest,
    t,
    dispatcherAsync,
    setIsLogging,
    setUrlToUse,
    logInAsync,
    changeAuthentificationMethod,
    authenticationMethod,
  ]);

  useEffectOnce(() => {
    if (connectByMsal) {
      handleRedirectPromise();
    }
  });

  useEffect(() => {
    const reconnect = async () => {
      if (connected) {
        const request: SignInRequest = { userUPN: '', password: '', cgu: true };
        axios
          .post<SignInResponse>(ApiAccount.Signin, request, { withCredentials: true })
          .then(async (response) => {
            if (200 <= response.status && response.status < 300) {
              setSignInInfos(response.data);
              const role = getRoleFromRigths(signInInfos.rights, signInInfos.customerName);
              // console.log(role);
              setRole(role);
            }
          })
          .catch((err: Error) => {
            console.error(err.message);
          });
      }
    };
    reconnect();
  }, [connected, setRole, setSignInInfos, signInInfos.customerName, signInInfos.rights]);

  return {
    signInRequest,
    setUserUPN,
    setPassword,
    connect,
    showPassword,
    setShowPassword,
    isOpenBackdrop,
    isLogging,
    showPasswordField,
    changeAuthentificationMethod,
  };
};

export default useData;
