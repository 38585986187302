import { useEffect, useMemo } from 'react';
import { useLocation, useRoutes } from 'react-router-dom';
import { observer } from 'mobx-react';
import axios from 'axios';
import { HOME, LOGIN, LOGIN_ADAL } from './paths';
import { LinkRouteType } from './types';
import USER_ROUTES from './roles/userRoutes';
import ANALYST_ROUTES from './roles/analystRoutes';
import DESIGNERINTER_ROUTES from './roles/designerInterRoutes';
import DESIGNER_ROUTES from './roles/designerRoutes';
import ADMINISTRATOR_ROUTES from './roles/administratorRoutes';
import C2SADMINISTRATOR_ROUTES from './roles/c2sAdministratorRoutes';
import ANONYMOUS_ROUTES from './roles/anonymousRoutes';
import { FEATURES_ROUTES } from './roles';
import { isPathInRoutes } from './utils/isPathInRoutes';
import { SignInResponse } from '40.quickConnect.DataAcces/siteAdmin/services/login/payloads/responses';
import { SignInRequest } from '40.quickConnect.DataAcces/siteAdmin/services/login/payloads/requests';
import { useStore } from '30.quickConnect.Store/siteAdmin';
import { QuickConnectRoles } from '90.quickConnect.Models/siteAdmin/enums/user/quickConnectRoles';
import { ApiAccount } from '40.quickConnect.DataAcces/siteAdmin/apiRoutes';
import { getRoleFromRigths } from '80.quickConnect.core/siteAdmin/helpers/getRoleFromRigths';
import { useSafeNavigation, useVerifiyFocusBtn } from '80.quickConnect.core/siteAdmin/hooks/useSafeNavigation';

const RoutesBuilder = () => {
  // On récupère connected et la fct initQuickConnectApp depuis le store
  const {
    LoginStore: { connected, signInInfos, setSignInInfos },
    UserSettingsStore: { role, setRole, setSubscribedFeatures },
    SubMenuStore: { setIsVisible: setIsSubMenuVisible },
  } = useStore();

  const navigate = useSafeNavigation();
  const verification = useVerifiyFocusBtn();

  const location = useLocation();

  useEffect(() => {
    if (!connected && location.pathname !== HOME && location.pathname !== LOGIN_ADAL && location.pathname !== LOGIN) {
      // Si on n'est pas connecté on renvoie vers le LOGIN
      // console.log('Montre moi le chemin' + location.pathname);
      if (location.pathname !== HOME) navigate(LOGIN);
    }
  }, [connected, location.pathname, role, navigate]);

  //TODO : ajouter le initQuickConnectApp
  // useEffect(() => {
  //   if (!connected) {
  //     initQuickConnectApp();
  //   }
  // }, [connected, initQuickConnectApp]);

  // L'utilisateur n'a pas accès aux même routes selon le rôle
  const routesBuilderMemo = useMemo<LinkRouteType[]>(() => {
    if (!connected) {
      return ANONYMOUS_ROUTES;
    }

    let routes: LinkRouteType[];
    switch (role) {
      case QuickConnectRoles.User:
        routes = USER_ROUTES;
        break;
      case QuickConnectRoles.Analyst:
        routes = ANALYST_ROUTES;
        break;
      case QuickConnectRoles.DesignerInter:
        routes = DESIGNERINTER_ROUTES;
        break;
      case QuickConnectRoles.Designer:
        routes = DESIGNER_ROUTES;
        break;
      case QuickConnectRoles.Administrator:
        routes = ADMINISTRATOR_ROUTES;
        break;
      case QuickConnectRoles.C2SAdministrator:
        routes = C2SADMINISTRATOR_ROUTES;
        break;
      default:
        routes = ANONYMOUS_ROUTES;
    }

    routes = [...routes, ...FEATURES_ROUTES];

    return routes;
  }, [connected, role]);

  useEffect(() => {
    //Si le path actuel n'est pas censé être accessible

    const reconnect = async () => {
      if (connected) {
        const request: SignInRequest = { userUPN: '', password: '', cgu: true };
        axios
          .post<SignInResponse>(ApiAccount.Signin, request, { withCredentials: true })
          .then(async (response) => {
            if (200 <= response.status && response.status < 300) {
              setSignInInfos(response.data);
              const getRole = getRoleFromRigths(signInInfos.rights, signInInfos.customerName);
              // console.log(role);
              setRole(getRole);
              setSubscribedFeatures(response.data.subscribedFeatures);
            }
          })
          .catch((err: Error) => {
            console.error(err.message);
          });
      }
    };

    let isReconnecting = false;
    if (connected && role === QuickConnectRoles.Anonymous) {
      isReconnecting = true;
      reconnect();
    }
    if (!isPathInRoutes(routesBuilderMemo, location.pathname) && !isReconnecting) {
      // console.log(routesBuilderMemo, location.pathname);
      navigate(HOME);
    } else {
      verification();
    }
    // else {
    //   navigate(location);
    // }
    setIsSubMenuVisible(location.pathname);
    // eslint-disable-next-line
  }, [
    location.pathname,
    routesBuilderMemo,
    connected,
    role,
    setRole,
    setSignInInfos,
    signInInfos.customerName,
    signInInfos.rights,
    setIsSubMenuVisible,
    setSubscribedFeatures,
  ]);

  const routesBuilder = useRoutes(routesBuilderMemo);
  return routesBuilder;
};

export default observer(RoutesBuilder);
