import { SxProps } from '@mui/material';

export const dialogHeaderStyle: SxProps = {
  display: 'flex',
  justifyContent: 'space-between',
  px: '10px',
};

export const innerBoxStyle = {
  overflow: 'auto',
  width: '100%',
  height: '94%',
};

export const dialogPaperStyle = {
  width: '50vw',
  height: '90vh',
  backgroundColor: 'background',
  border: '2px solid #E3F2F0',
  borderRadius: '16px',
  p: '20px',
  position: 'relative',
};
