import { v4 as uuidv4 } from 'uuid';
import { DraggableItem } from './DraggableListItem/type';

export const reorder = <T>(list: T[], startIndex: number, endIndex: number): T[] => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

// On associe un id à chaque props
export const initializeList = <P>(propsList: P[]): DraggableItem<P>[] => {
  const items: DraggableItem<P>[] = [];
  propsList.forEach((props) => {
    items.push({ props: props, id: uuidv4() });
  });
  return items;
};
