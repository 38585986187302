import React, { useMemo, useState } from 'react';
import { Box, Button } from '@mui/material';
import FilterAltOutlinedIcon from '@mui/icons-material/FilterAltOutlined';
import { QCSortingMenuProps } from './types';
import QCChoiceMenu from '70.quickConnect.Components/siteAdmin/Inputs/QCChoiceMenu';

import { ChoiceMenuOptionType } from '70.quickConnect.Components/siteAdmin/Inputs/QCChoiceMenu/type';

/**
 * Composant du tri.
 * @component
 * @param {string} currentOption - L'option actuelle.
 * @param {ChoiceMenuOptionType[]} options - Toutes les options possibles.
 * @param {React.Dispatch<React.SetStateAction<string>>} dispatch - Le dispatcheur pour modifier l'option.
 * @example
 * import { SortTypes } from "90.quickConnect.Models/siteAdmin/enums/sortTypes"
 * const [option, setOptions] = useState<string>(SortTypes.Alphabetical as string)
 * const sortingOptions: ChoiceMenuOptionType[] = (Object.values(SortTypes).map((value)=>{
 *    return {
 *      startIcon: option==value ? <FilterAltOutlinedIcon/> : null,
 *      text: value,
 *    } as ChoiceMenuOptionType
 * }))
 * <QCSortingMenu currentOption={option} dispatch={setOptions} options={sortingOptions} />
 */
const QCSortingMenu = ({ options, dispatch }: QCSortingMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const [selectedOption, setSelectedOption] = useState(options.length > 0 ? options[0] : '');

  const makeChoiceMenuOptions: ChoiceMenuOptionType[] = useMemo(() => {
    return options.map((option) => ({
      text: option,
      startIcon: option === selectedOption ? <FilterAltOutlinedIcon /> : null,
    }));
  }, [options, selectedOption]);

  return (
    <Box sx={{ width: 'fit-content' }}>
      <Button
        onClick={handleClick}
        sx={{ color: 'inherit', textTransform: 'none', paddingX: 0 }}
        startIcon={<FilterAltOutlinedIcon />}
      >
        {selectedOption}
      </Button>
      <QCChoiceMenu
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        sx={{ marginTop: '-5px' }}
        options={makeChoiceMenuOptions}
        anchorElState={[anchorEl, setAnchorEl]}
        dispatch={(newOption: string) => {
          dispatch(newOption);
          setSelectedOption(newOption);
        }}
      />
    </Box>
  );
};

export default QCSortingMenu;
