import React from 'react';
import { IconButton, styled } from '@mui/material';
import { IconButtonProps } from './types';

const QCIconButtonSkeleton = ({ onClickFunc, className, Icon }: IconButtonProps) => {
  return (
    <IconButton className={className} onClick={onClickFunc}>
      {Icon}
    </IconButton>
  );
};

/**
 * Un bouton pour ajouter des éléments.
 * @component
 * @param {function} onClickFunc - Fonction déclenchée quand le bouton est cliqué.
 * @param {string | undefined} [className] - Nom de la classification
 * @param {"text" | "contained" | "outlined" | undefined} variantValue - Texte qui définit la varainte de bouton.
 * @param {React.ReactNode} Icon - L'icône de bouton.
 * @example <caption>Exemple QCFabAjout en varainte "contained"</caption>
 * const handleClcik = (event) => {...}
 * <QCIconButton onClickFunc={handleClick} className='fab-ajout-contained' variant='contained' Icon={<UneIcone />} />
 * @example <caption>Exemple QCFabAjout en variant "outlined"</caption>
 * const handleClick = (event) => {...}
 * <QCIconButton onClickFunc={handleClick} className='fab-ajout-outlined' variant='outlined' Icon={<UneIcone />} />
 */
const QCIconButton = styled(QCIconButtonSkeleton)(({ theme, variantValue }) => {
  const { palette } = theme;
  const iconContainedColor = palette.iconBtnContainedColor.main;
  const iconOutlinedColor = palette.iconBtnOutlinedColor.main;
  const btnOutlinedColor = palette.iconOutlinedColor.main;
  const btnOutlinedHoverColor = palette.iconOutlinedHoverColor.main;
  const btnContainedColor = palette.iconContainedColor.main;
  const btnContainedHoverColor = palette.iconContainedHoverColor.main;
  switch (variantValue) {
    case 'contained':
      return {
        boxSizing: 'border-box',
        background: btnContainedColor,
        border: `2px solid ${btnContainedColor}`,
        borderRadius: '8px',
        height: 'fit-content',
        width: 'fit-content',
        minWidth: '30px',
        minHeight: '30px',
        boxShadow: 'none',
        '&:hover': {
          border: `2px solid ${btnContainedHoverColor}`,
          background: btnContainedHoverColor,
          boxShadow: 'none',
        },
        '& .MuiIcon-root': {
          color: iconContainedColor,
        },
      };
    case 'outlined':
    default:
      return {
        boxSizing: 'border-box',
        border: `2px solid ${btnOutlinedColor}`,
        borderRadius: '8px',
        height: 'fit-content',
        width: 'fit-content',
        boxShadow: 'none',
        // minWidth: 44,
        // minHeight: 44,
        '&:hover': {
          border: `2px solid ${btnOutlinedColor}`,
          background: btnOutlinedHoverColor,
          boxShadow: 'none',
        },
        '& .MuiIcon-root': {
          color: iconOutlinedColor,
        },
      };
  }
});

export default QCIconButton;
