import { SxProps, Theme } from '@mui/material';

export const AttributesCardStyle = (theme: Theme): SxProps => ({
  overflow: 'hidden',
  backgroundColor: theme.palette.toolsBackground.main,
  border: 'solid 2px',
  borderColor: theme.palette.menuBorder.main,
  borderRadius: '8px',
  paddingLeft: '20px',
  marginTop: '20px',
  paddingTop: '10px',
  paddingBottom: '50px',
  paddingRight: '5px',
  height: '96vh',
  width: '50%',
  flexGrow: 1,
  position: 'relative',
});

export const AttributeListStyle: SxProps = {
  display: 'flex',
  position: 'relative',
  flexDirection: 'column',
  gap: 2,
  overflow: 'scroll',
  height: '100%',
  marginTop: '10px',
  paddingRight: '10px',
};
