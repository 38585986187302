import React from 'react';
import { Box, Divider, IconButton } from '@mui/material';
import { useTranslation } from 'react-i18next';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import ImportExportOutlinedIcon from '@mui/icons-material/ImportExportOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import { HeaderMainStyle } from './styles';
import useData from './hooks';
import QCDropDown from './QCDropDown';
import { testDataActivities, testDataImportsExports } from './QCDropDown/const';
import QCButtonGlobal from '70.quickConnect.Components/siteAdmin/Buttons/QCButtonGlobal';

const Header = () => {
  const { t } = useTranslation();
  const {
    accountNavigation,
    anchorEl,
    isOpenImportExport,
    setIsOpenImportExport,
    handleClickImportExport,
    isOpenAlertes,
    setIsOpenAlerte,
    handleClickAlertes,
    isOpenActivities,
    setIsOpenActivities,
    handleClickActivities,
    setTabsValueAlerts,
    setTabsValueActivities,
    setTabsValueImportExport,
    tabsValueAlerts,
    tabsValueActivities,
    tabsValueImportExport,
    alertsData,
  } = useData();

  return (
    <Box sx={HeaderMainStyle()}>
      <QCDropDown
        anchorEl={anchorEl}
        data={testDataImportsExports}
        open={isOpenImportExport}
        setOpen={setIsOpenImportExport}
        headerName="importsExports"
        tabsValue={tabsValueImportExport}
        setTabsValue={setTabsValueImportExport}
        renderButton={
          <QCButtonGlobal
            className="import-export-button"
            startIcon={<ImportExportOutlinedIcon sx={{ transform: 'scaleX(-1)' }} />}
            variant="text"
            value={t('navigation.menu.importsExports')}
            action={handleClickImportExport}
          />
        }
      />
      <QCDropDown
        anchorEl={anchorEl}
        data={alertsData}
        open={isOpenAlertes}
        setOpen={setIsOpenAlerte}
        headerName="alerts"
        tabsValue={tabsValueAlerts}
        setTabsValue={setTabsValueAlerts}
        renderButton={
          <QCButtonGlobal
            className="alerte-button"
            startIcon={<WarningAmberOutlinedIcon />}
            variant="text"
            value={t('navigation.menu.alerts')}
            action={handleClickAlertes}
          />
        }
      />
      <Divider orientation="vertical" flexItem sx={{ width: 0, height: '45px', alignSelf: 'center' }} />
      <QCDropDown
        anchorEl={anchorEl}
        data={testDataActivities}
        open={isOpenActivities}
        setOpen={setIsOpenActivities}
        headerName="activities"
        tabsValue={tabsValueActivities}
        setTabsValue={setTabsValueActivities}
        renderButton={
          <IconButton className="activity-button" onClick={handleClickActivities}>
            <NotificationsNoneIcon />
          </IconButton>
        }
      />
      <IconButton className="account-button" onClick={accountNavigation}>
        <AccountCircleOutlinedIcon />
      </IconButton>
    </Box>
  );
};

export default Header;
