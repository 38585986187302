import { Dispatch } from 'react';

export type SaveConfirmationProps = {
  open: boolean;
  dispatch: Dispatch<SaveAction>;
};

export enum SaveActionType {
  Save = 'Save',
  Cancel = 'Cancel',
}

export type SaveAction = {
  type: SaveActionType;
  payload?: number;
};

export type ReducerSaveState = {
  isSaved: boolean;
};

export const saveConfirmationReducer = (state: ReducerSaveState, action: SaveAction): ReducerSaveState => {
  switch (action.type) {
    case SaveActionType.Save:
      return { isSaved: true };
    case SaveActionType.Cancel:
      return { isSaved: false };
  }
};
