import { Organization } from '../types';
import { TreeViewNode } from '70.quickConnect.Components/siteAdmin/Other/TreeView/types';

export const OrganizationToTreeViewNodes = (initialList: Organization[]) => {
  const layers: TreeViewNode[][] = [];

  initialList.forEach((item) => {
    if (layers[item.level] === undefined)
      layers.push([
        {
          id: item.id,
          name: item.name,
          parentId: item.parentId,
          parentName: item.parentName,
          children: [],
        } as TreeViewNode,
      ]);
    else
      layers[item.level].push({
        id: item.id,
        name: item.name,
        parentId: item.parentId,
        parentName: item.parentName,
        children: [],
      } as TreeViewNode);
  });

  for (let i = 0; i < layers.length - 1; i++) {
    layers[layers.length - 1 - i].map((children) => {
      layers[layers.length - 2 - i].map((parent) => {
        if (parent.id === children.parentId) {
          parent.children.push(children);
        }
      });
    });
  }

  return layers[0];
};

// for (let i = 0; i < layers.length - 1; i++) {
//   layers[layers.length - 1 - i].map((children) => {
//     layers[layers.length - 2 - i].map((parent) => {
//       if (
//         parent.type === 'family' &&
//         children.type === 'family' &&
//         parent.id === children.parentId &&
//         parent.childrenItems !== undefined
//       ) {
//         parent.childrenItems.push(children);
//         parent.childrenCount++;
//       }
//     });
//   });
// }
