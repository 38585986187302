import { DataType } from './types';

export const testDataAlterts: DataType[] = [
  {
    title: 'panne grue colas',
    description: "Aujourd'hui à 16h01 / Pour Haj Kacem",
    tags: 'alert',
  },
  {
    title: 'tractopelle - incident',
    description: "Aujourd'hui à 13h40 / Pour Equipe technique",
    tags: 'incident',
  },
  {
    title: 'c2s pièces manquantes',
    description: "Aujourd'hui à 12h23 / Pour Jean Roquefort",
    tags: 'alert',
  },
];

export const testDataActivities: DataType[] = [
  {
    title: 'c2s script call',
    description: 'workflow',
    date: "aujourd'hui",
    time: '16h30',
  },
  {
    title: 'les 5 règles de sécurités',
    description: 'têches à faire',
    date: "aujourd'hui",
    time: '13h24',
  },
  {
    title: 'script c2s tractopelle',
    description: 'Formulaire en brouillon',
    date: 'hier',
    time: '17h39',
    tags: 'draft',
  },
];

export const testDataImportsExports: DataType[] = [
  {
    title: 'machines chantier mans',
    description: 'historique/tractopelle',
    tags: 'exports',
  },
  {
    title: 'checking matinal -colas',
    description: 'tâches à faire',
    tags: 'imports',
  },
  {
    title: 'liste des candidats',
    description: 'ressources/candidats',
    tags: 'exports',
  },
];
