import React from 'react';
import { SvgIcon } from '@mui/material';
import { ReactComponent as QCLogoSmall } from './QCLogoS.svg';
import { ReactComponent as QCLogoMedium } from './QCLogoM.svg';
import { ReactComponent as QCLogoSmallMedium } from './QCLogoSM.svg';

export const QCLogoS = () => {
  return (
    <SvgIcon
      sx={{
        fontSize: {
          xs: 30,
          sm: 35,
          md: 40,
          lg: 45,
          xl: 50,
        },
      }}
      component={QCLogoSmall}
      inheritViewBox
    />
  );
};

export const QCLogoSM = () => {
  return (
    <SvgIcon
      sx={{
        fontSize: {
          xs: 80,
          sm: 95,
          md: 100,
          lg: 115,
          xl: 120,
        },
      }}
      component={QCLogoSmallMedium}
      inheritViewBox
    />
  );
};

export const QCLogoM = () => {
  return (
    <SvgIcon
      sx={{
        fontSize: {
          xs: 120,
          sm: 130,
          md: 140,
          lg: 150,
          xl: 160,
        },
      }}
      component={QCLogoMedium}
      inheritViewBox
    />
  );
};
