import { useEffect, useMemo } from 'react';
import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useTheme } from '@mui/material';
import useInitSharedListSubMenu from '../hooks/useInitSubMenu';
import useSharedListsServices from '../services';
import { UpdateSharedListRequest } from '../services/requests';
import useSupportedLanguages from '../../Ressources/useSupportedLanguages';
import { SharedList, SharedListData } from '90.quickConnect.Models/siteAdmin/models/sharedList/sharedList';
import { SharedListDataType, SharedListType } from '90.quickConnect.Models/siteAdmin/enums/sharedList/sharedListType';
import { getSharedListDataType } from '80.quickConnect.core/siteAdmin/helpers/sharedListData';
import { useDeleteDialog } from '80.quickConnect.core/siteAdmin/hooks/useDeleteDialog';
import { useStore } from '30.quickConnect.Store/siteAdmin';
import { DeleteActionType } from '70.quickConnect.Components/siteAdmin/Other/DeleteConfirmationDialog/type';
import { useSafeNavigation } from '80.quickConnect.core/siteAdmin/hooks/useSafeNavigation';

const useSharedListSettingsData = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useSafeNavigation();
  const { sharedListId } = useParams();
  const { getSharedList, updateSharedList, deleteSharedList } = useSharedListsServices();

  const {
    UserSettingsStore: { subscribedFeatures },
  } = useStore();
  const canSendToTrash = useMemo(() => subscribedFeatures['FEAT-007'], [subscribedFeatures]);

  const [openDeleteDialog, setOpenDeleteDialog, deleteState, deleteDispatch] = useDeleteDialog();

  const {
    openAddDialog: openAddSharedListDialog,
    setOpenAddDialog: setOpenAddSharedListDialog,
    idsToDelete,
    initSharedLists,
  } = useInitSharedListSubMenu(setOpenDeleteDialog);

  const [pageSharedList, setPageSharedList] = useState<SharedList>();

  const initialToBeTranslated = useRef(false);

  const [sharedListType, setSharedListType] = useState<SharedListType>(SharedListType.Simple);

  const { supportedLanguages, languageValue } = useSupportedLanguages(pageSharedList?.defaultLanguage);

  const [sharedListElements, setSharedListElements] = useState<SharedListData[]>([]);
  const [dataType, setDataType] = useState<SharedListDataType | undefined>(undefined);

  const [openAddElementDialog, setOpenAddElementDialog] = useState(false);

  const addElementLabel = useRef<string | undefined>(undefined);

  const setAddElementLabel = (newValue: string | undefined) => {
    addElementLabel.current = newValue;
  };

  useEffect(() => {
    (async () => {
      const data = await getSharedList({ id: sharedListId });

      if (data) {
        setPageSharedList(data);
        setSharedListType(data.listType);
        setSharedListElements(data.data);
        initialToBeTranslated.current = data.toBeTranslated;

        data.data.some((el) => {
          if (!el.data) return false;
          setDataType(getSharedListDataType(el.data));
          return true;
        });
      }
    })();
    //eslint-disable-next-line
  }, [sharedListId]);

  const handleSave = useCallback(() => {
    if (!pageSharedList) return;

    const request: UpdateSharedListRequest = { ...pageSharedList, SharedListItems: sharedListElements };
    updateSharedList(request);
  }, [pageSharedList, updateSharedList, sharedListElements]);

  useEffect(() => {
    setOpenDeleteDialog(false);
    switch (deleteState.choice) {
      case DeleteActionType.SendToTrash:
        if (idsToDelete.current.length > 0) {
          deleteSharedList({ ids: idsToDelete.current, sendToRecycleBin: true });
          idsToDelete.current = [];
          initSharedLists();
          return;
        }
        if (!pageSharedList) return;
        deleteSharedList({ ids: [pageSharedList.id], sendToRecycleBin: true });
        navigate('/sharedlists');
        break;
      case DeleteActionType.Delete:
        if (idsToDelete.current.length > 0) {
          deleteSharedList({ ids: idsToDelete.current, sendToRecycleBin: false });
          idsToDelete.current = [];
          initSharedLists();
          return;
        }
        if (!pageSharedList) return;
        deleteSharedList({ ids: [pageSharedList.id], sendToRecycleBin: false });
        navigate('/sharedlists');
        break;
      case DeleteActionType.Cancel:
        break;
      default:
        const exhaustiveCheck: never = deleteState.choice;
        throw Error(`Unknown delete state type: ${exhaustiveCheck}`);
    }
    //eslint-disable-next-line
  }, [deleteState]);

  return {
    t,
    theme,
    pageSharedList,
    setPageSharedList,
    sharedListType,
    setSharedListType,
    supportedLanguages,
    languageValue,
    handleSave,
    dataType,
    setDataType,
    sharedListElements,
    setSharedListElements,
    addElementLabel,
    setAddElementLabel,
    openAddElementDialog,
    setOpenAddElementDialog,
    openAddSharedListDialog,
    setOpenAddSharedListDialog,
    initialToBeTranslated,
    openDeleteDialog,
    setOpenDeleteDialog,
    deleteDispatch,
    canSendToTrash,
  };
};

export default useSharedListSettingsData;
