import React, { useContext, useMemo, useState } from 'react';
import { Box, Collapse, IconButton, Stack, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { observer } from 'mobx-react';
import { v4 as uuidv4 } from 'uuid';
import SharedListSettingsContext from '../../SharedListSettingsContext';
import AddDataDialog from '../AddDataDialog';
import SharedListDataChip from '../SharedListDataChip';
import { ElementContainerStyle, LabelValueContainerStyle, SharedListLabelStyle, ValueContainerStyle } from './style';
import { parseCsvSharedListData } from '80.quickConnect.core/siteAdmin/helpers/sharedListData';
import { SharedListDataType } from '90.quickConnect.Models/siteAdmin/enums/sharedList/sharedListType';

const SimpleSharedListElement = ({ label }: { label: string }) => {
  const theme = useTheme();
  const { sharedListElements, setSharedListElements, dataType } = useContext(SharedListSettingsContext);
  const element = useMemo(() => sharedListElements.find((el) => el.label === label), [label, sharedListElements]);
  const displayLabel = useMemo(() => label.split('\\')[label.split('\\').length - 1], [label]);
  const [showData, setShowData] = useState(false);

  const [openAddDataDialog, setOpenAddDataDialog] = useState(false);
  const handleAddData = () => {
    setOpenAddDataDialog(true);
  };

  if (!element) return <></>;

  const renderElementData = () => {
    if (element.data === undefined) return <></>;
    switch (dataType) {
      case SharedListDataType.Csv:
        if (typeof element.data !== 'string') throw new Error('SharedListDataType should be string');
        const values = parseCsvSharedListData(element.data);
        return values.map((el, index) => (
          <SharedListDataChip
            key={uuidv4()}
            type={SharedListDataType.Csv}
            value={el}
            elementLabel={label}
            index={index}
          />
        ));

      case SharedListDataType.Json:
        return Object.entries(element.data).map(([key, value]) => (
          <SharedListDataChip
            key={key}
            type={SharedListDataType.Json}
            keyName={key}
            value={value}
            elementLabel={label}
          />
        ));
    }
  };

  const handleOpenCollapse = () => {
    setShowData(!showData);
  };

  const handleDeleteElement = () => {
    const indexToDelete = sharedListElements.findIndex((el) => el.label === label);
    const newSharedListElements = [...sharedListElements];
    newSharedListElements.splice(indexToDelete, 1);
    setSharedListElements(newSharedListElements);
  };

  return (
    <Stack sx={ElementContainerStyle(theme)}>
      <Box display="flex" justifyContent="space-between">
        <Box sx={LabelValueContainerStyle}>
          <FiberManualRecordIcon
            sx={{
              fontSize: '0.4rem',
              marginX: '5px',
            }}
          />
          <Box sx={SharedListLabelStyle(showData)}>{displayLabel}</Box>
          <Box sx={ValueContainerStyle(theme, showData)}>{element.value}</Box>
          {element.data ? (
            <IconButton
              onClick={handleOpenCollapse}
              sx={{ marginLeft: '5px', alignContent: 'baseline', paddingTop: '1px' }}
              disableRipple
            >
              {showData ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          ) : (
            <></>
          )}
          {/* Box pour le ripple du bouton */}
          <Box sx={{ paddingBottom: '8', marginTop: '-1px' }}>
            <IconButton
              sx={{ alignContent: 'baseline', padding: '1px', color: theme.palette.grey[500] }}
              onClick={handleAddData}
            >
              <AddIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>
        <IconButton
          sx={{ alignContent: 'baseline', paddingTop: '2px', color: theme.palette.error.main }}
          disableRipple
          onClick={handleDeleteElement}
        >
          <DeleteOutlinedIcon fontSize="small" />
        </IconButton>
      </Box>

      <Collapse in={showData}>
        <Box sx={{ overflowX: 'scroll' }}>{renderElementData()}</Box>
      </Collapse>

      {openAddDataDialog && (
        <AddDataDialog open={openAddDataDialog} setOpen={setOpenAddDataDialog} elementLabel={label} />
      )}
    </Stack>
  );
};

export default observer(SimpleSharedListElement);
