export const boxStyle = {
  display: 'flex',
  gap: 1,
  alignItems: 'center',
};

export const typographyText = {
  whiteSpace: 'normal',
  wordBreak: 'break-all',
};
