import React, { ChangeEvent, MouseEvent } from 'react';
import { Box, IconButton, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import ClearIcon from '@mui/icons-material/Clear';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { DndProvider, DropTargetMonitor, useDrop } from 'react-dnd';
import { HTML5Backend, NativeTypes } from 'react-dnd-html5-backend';
import QCIconButton from '../../Buttons/QCIconButton';
import { FileInputProps } from './types';
import { useListFilesBoxSxProps, useAddFileBoxSxprops } from './styles';

/**
 * Composant pour uploader les fichiers.
 * @component
 * @param {File[]} files - La liste des fichiers.
 * @param {React.Dispatch<React.SetStateAction<File[]>>} setFiles - Le dispatch pour modifier la liste des fichiers.
 * @example
 * const [files, setFiles] = useState<File[]>([])
 * <QCFileInput files={files} setFiles={setFiles} />
 */
const QCFileInput = ({ files, setFiles }: FileInputProps) => {
  const { t } = useTranslation();

  const uploadFiles = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length) {
      const [...inputFiles] = event.target.files;
      setFiles(files.concat(inputFiles));
    }
  };
  const FilesInput = React.useRef<HTMLInputElement | null>(null);

  const handleFilesInput = () => {
    FilesInput.current?.click();
  };

  const readFile = (index: number, event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    const theFile = files[index];
    const blob = new Blob([theFile], { type: theFile.type });
    const fileUrl = URL.createObjectURL(blob);
    window.open(decodeURIComponent(fileUrl), '_blank', 'noopener,noreferrer');
    setTimeout(function () {
      URL.revokeObjectURL(fileUrl);
    }, 3000);
  };

  const deleteFile = (index: number, event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setFiles(files.filter((file) => file != files[index]));
  };

  const AddFilesComponent = (props: { filesList: File[] }) => {
    // eslint-disable-next-line
    const [, dropRef] = useDrop(
      () => ({
        accept: [NativeTypes.FILE],
        drop: (item: { files: File[] }) => setFiles(props.filesList.concat(item.files)),
        collect: (monitor: DropTargetMonitor) => ({
          canDrop: !!monitor.canDrop(),
          isOver: !!monitor.isOver(),
        }),
      }),
      [],
    );
    return (
      <Box sx={useAddFileBoxSxprops} ref={dropRef} component="label">
        <input type="file" multiple onChange={uploadFiles} hidden />
        {t('uicomponents.qcfileinput.textinitialcard')}
      </Box>
    );
  };

  if (files.length == 0) {
    return (
      <DndProvider backend={HTML5Backend}>
        <AddFilesComponent filesList={files} />
      </DndProvider>
    );
  }

  return (
    <Box sx={useListFilesBoxSxProps}>
      <List sx={{ overflow: 'auto' }}>
        {files &&
          files.map((file: File, index: number) => {
            return (
              <ListItem
                key={index}
                secondaryAction={
                  <>
                    <IconButton name="view" title="voir" color="inherit" onClick={(e) => readFile(index, e)}>
                      <VisibilityOutlinedIcon name="view" />
                    </IconButton>
                    <IconButton
                      name="delete"
                      title="supprimer"
                      color={'inputListFileIconColors'}
                      onClick={(e) => deleteFile(index, e)}
                    >
                      <ClearIcon name="delete" />
                    </IconButton>
                  </>
                }
              >
                <ListItemAvatar>
                  <AttachFileIcon />
                </ListItemAvatar>
                <ListItemText sx={{ '& .MuiTypography-root': { fontWeight: 'bold' } }} primary={file.name} />
              </ListItem>
            );
          })}
      </List>
      <input ref={FilesInput} type="file" multiple onChange={uploadFiles} hidden />
      <QCIconButton
        variantValue="outlined"
        onClickFunc={handleFilesInput}
        Icon={<AddIcon />}
        sx={{ position: 'absolute', bottom: '15px', right: '15px', width: 44, height: 44 }}
      />
    </Box>
  );
};

export default QCFileInput;
