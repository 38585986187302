import React from 'react';
import { QCDialogformSelectProps } from './types';
import QCSelect from '70.quickConnect.Components/siteAdmin/Inputs/QCSelect';

const QCDialogFormSelect = ({ label, options, setOption, value, defaultValue, mandatory }: QCDialogformSelectProps) => {
  return (
    <QCSelect
      label={label}
      options={options}
      setOption={setOption}
      value={value}
      defaultValue={defaultValue}
      mandatory={mandatory}
    />
  );
};

export default QCDialogFormSelect;
