import React from 'react';
import { OrganizationIcon } from '00.app/icons/OrganizationIcon';
import { Organization } from '10.quickConnect.app/siteAdmin/components/domains/Users/types';
import { SubMenuRowItem } from '10.quickConnect.app/siteAdmin/components/shared/SubMenu/types';

export const organizationalUnitsParser = (initialList: Organization[]) => {
  const layers: SubMenuRowItem[][] = [];
  // const layers = { level: 0, node: initialList[0], children: [] };
  initialList.map((OU) => {
    if (layers[OU.level] === undefined)
      layers.push([
        {
          id: OU.id,
          parentId: OU.parentId,
          name: OU.name,
          type: 'family',
          startIcon: <OrganizationIcon />,
          childrenCount: 0,
          childrenItems: [],
        },
      ]);
    else
      layers[OU.level].push({
        id: OU.id,
        parentId: OU.parentId,
        name: OU.name,
        type: 'family',
        startIcon: <OrganizationIcon />,
        childrenCount: 0,
        childrenItems: [],
      });
  });

  for (let i = 0; i < layers.length - 1; i++) {
    layers[layers.length - 1 - i].map((children) => {
      layers[layers.length - 2 - i].map((parent) => {
        if (
          parent.type === 'family' &&
          children.type === 'family' &&
          parent.id === children.parentId &&
          parent.childrenItems !== undefined
        ) {
          parent.childrenItems.push(children);
          parent.childrenCount++;
        }
      });
    });
  }

  return layers[0];
};
