import React from 'react';
import { Box, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import AddResourceForm from '../AddResourceForm/addResource';
import AddFamilyDialogForm from '../AddFamilyDialogForm';
import { actionsToChoiceMenuOption, ResMultipleActions, ResourceRowActions } from '../actions';
import useData from './hooks';
import MenuWrapper from '10.quickConnect.app/siteAdmin/components/shared/MenuWrapper';
import QCButtonGlobal from '70.quickConnect.Components/siteAdmin/Buttons/QCButtonGlobal';
import QCFabCorbeille from '70.quickConnect.Components/siteAdmin/Buttons/QCFabCorbeille';
import QCIconButton from '70.quickConnect.Components/siteAdmin/Buttons/QCIconButton';
import ContentHeader from '70.quickConnect.Components/siteAdmin/Other/ContentHeader';
import DataTable from '70.quickConnect.Components/siteAdmin/Other/DataTable';
import DeleteConfirmationDialog from '70.quickConnect.Components/siteAdmin/Other/DeleteConfirmationDialog';
import FamilyIcon from '00.app/icons/FamilyIcon';
import RessourceIcon from '00.app/icons/RessourceIcon';
import QCSortingMenu from '70.quickConnect.Components/siteAdmin/Other/QCSortingMenu';

const ResourcesList = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const {
    columns,
    gridrows,
    setSearchValue,
    setRowAction,
    setRowsAction,
    isLoading,
    currentSubMenuRow,
    handleNavigate,
    openDeleteDialog,
    deleteDispatch,
    handleOpenDeleteDialog,
    requestHandler,
    sortingProps,
    sortingOptions,
    setOptions,
    isAddResourceFormOpen,
    setIsAddResourceFormOpen,
    entityId,
    handleConfirmAddResource,
    openAddFamilyDialog,
    setOpenAddFamilyDialog,
  } = useData();

  return (
    <MenuWrapper>
      <DeleteConfirmationDialog dispatch={deleteDispatch} open={openDeleteDialog} trashAvailable />
      <AddFamilyDialogForm open={openAddFamilyDialog} toggleOpen={setOpenAddFamilyDialog} />
      <AddResourceForm
        open={isAddResourceFormOpen}
        toggleIsOpen={setIsAddResourceFormOpen}
        handleConfirm={(value) => handleConfirmAddResource(value)}
        entitySchemaId={entityId ?? ''}
      />
      <Box
        sx={{
          display: 'flex',
          position: 'relative',
          flexDirection: 'column',
          flex: 1,
          marginX: '2vh',
          marginBottom: '2vh',
          justifyContent: 'space-around',
          gap: 3,
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="flex-end">
          <ContentHeader>
            <ContentHeader.TitlesGroup>
              <ContentHeader.OverTitle>{t('family.viewer.overTitle')}</ContentHeader.OverTitle>
              <ContentHeader.Title startIcon={<FamilyIcon />}>{currentSubMenuRow?.name}</ContentHeader.Title>
            </ContentHeader.TitlesGroup>

            <ContentHeader.Controls>
              <QCIconButton
                Icon={<SettingsOutlinedIcon />}
                onClickFunc={() => {
                  handleNavigate('settings');
                }}
                variantValue="outlined"
                sx={{ color: theme.palette.primary.main, marginBottom: '-5px' }}
              ></QCIconButton>
            </ContentHeader.Controls>
          </ContentHeader>

          <QCFabCorbeille onClickFunc={handleOpenDeleteDialog} />
        </Box>

        <DataTable
          title={t('resources.resources.title')}
          rowActions={actionsToChoiceMenuOption([ResourceRowActions.Edit, ResourceRowActions.Delete])}
          onSelectRowAction={setRowAction}
          multipleActions={actionsToChoiceMenuOption([ResMultipleActions.Print, ResMultipleActions.Delete])}
          onSelectMultipleAction={setRowsAction}
          columns={columns}
          rows={gridrows}
          getSearchValue={setSearchValue}
          isLoading={isLoading}
          requestHandler={requestHandler}
          sortingProps={sortingProps}
          renderAfterTitle={
            <Box display="flex" gap={1}>
              <QCButtonGlobal
                className="export-resource"
                startIcon={<DownloadOutlinedIcon />}
                value={t('form.detail.label.modelImportInbox')}
                variant="outlined"
                // action={handleImportModelDownload}
                datacy="export-resource"
              />
            </Box>
          }
          renderAfterSearchBar={
            <Box display="flex" p={2} minWidth="140px">
              <QCSortingMenu dispatch={setOptions} options={sortingOptions} />
            </Box>
          }
        />

        <Box sx={{ position: 'absolute', bottom: '3vh', left: '3vh' }}>
          <QCButtonGlobal
            className="add-resource"
            variant="contained"
            startIcon={<RessourceIcon />}
            endIcon={<AddIcon />}
            value={t('resources.resources.addText')}
            datacy="add-resource"
            action={() => setIsAddResourceFormOpen(true)}
          ></QCButtonGlobal>
        </Box>
      </Box>
    </MenuWrapper>
  );
};

export default observer(ResourcesList);
