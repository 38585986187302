import React from 'react';
import { Backdrop, Box, Checkbox, CircularProgress, Divider, List, ListItem, ListItemText, Stack } from '@mui/material';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import NotificationsNoneIcon from '@mui/icons-material/NotificationsNone';
import { ActionButtonsContainer } from '../OrganizationSettings/styles';
import MenuWrapper from '../../shared/MenuWrapper';
import { UserSettingsType } from '../Users/types';
import { useData } from './hooks';
import { CardContainerStyle, CardTextStyle, InputBoxStyle } from './styles';
import UserParamsListItem from './UserParamsListItem';
import { OrganizationIcon } from '00.app/icons/OrganizationIcon';
import ContentHeader from '70.quickConnect.Components/siteAdmin/Other/ContentHeader';
import DeleteConfirmationDialog from '70.quickConnect.Components/siteAdmin/Other/DeleteConfirmationDialog';
import SaveConfirmationDialog from '70.quickConnect.Components/siteAdmin/Other/SaveConfirmationDialog';
import QCTextInput from '70.quickConnect.Components/siteAdmin/Inputs/QCTextInput';
import QCSelect from '70.quickConnect.Components/siteAdmin/Inputs/QCSelect';
import QCButtonGlobal from '70.quickConnect.Components/siteAdmin/Buttons/QCButtonGlobal';
import QCFabCorbeille from '70.quickConnect.Components/siteAdmin/Buttons/QCFabCorbeille';
import QCIconButton from '70.quickConnect.Components/siteAdmin/Buttons/QCIconButton';
import QCTreeViewAutocomplete from '70.quickConnect.Components/siteAdmin/Inputs/QCTreeViewAutocomplete';
import { TreeViewNode } from '70.quickConnect.Components/siteAdmin/Other/TreeView/types';

//eslint-disable-next-line
const UserSettings = () => {
  const {
    t,
    theme,
    pageUser,
    updatedUser,
    setUpdatedUser,
    openSaveDialog,
    saveDispatch,
    openDeleteDialog,
    deleteDispatch,
    handleSave,
    handleDelete,
    isProfilChecked,
    handleProfilCheck,
    isParamChecked,
    handleParamCheck,
    handleParamChange,
    organizationOptions,
    isOpenBackDrop,
  } = useData();
  return (
    <MenuWrapper>
      {pageUser?.canSave && <SaveConfirmationDialog dispatch={saveDispatch} open={openSaveDialog} />}
      {pageUser?.canDelete && <DeleteConfirmationDialog dispatch={deleteDispatch} open={openDeleteDialog} />}
      <Box
        sx={{
          marginLeft: { xs: '2vw' /*lg: '10vw' */ },
          // overflow: 'hidden',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <ContentHeader>
          <ContentHeader.BackButton />
          <ContentHeader.TitlesGroup>
            <ContentHeader.OverTitle>{t('userSettings.picker.title')}</ContentHeader.OverTitle>
            <ContentHeader.Title startIcon={<OrganizationIcon />}>
              {pageUser?.userToDesign.firstName + ' ' + pageUser?.userToDesign.lastName}
            </ContentHeader.Title>
          </ContentHeader.TitlesGroup>
        </ContentHeader>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            flexWrap: 'wrap',
            marginX: '3vw',
            marginTop: '3vh',
          }}
        >
          <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 2, flexBasis: '73%' }}>
            <Box sx={InputBoxStyle}>
              <QCTextInput
                editable
                value={updatedUser ? updatedUser.lastName : ''}
                label={t('users.designer.form.placeholder.lastName')}
                dispatch={(value: string) =>
                  setUpdatedUser((currUpdatedUser) => {
                    if (currUpdatedUser) {
                      const newUpdatedUser = { ...currUpdatedUser };
                      newUpdatedUser.lastName = value;
                      return newUpdatedUser;
                    }
                  })
                }
              />
            </Box>
            <Box sx={InputBoxStyle}>
              <QCTextInput
                editable
                value={updatedUser ? updatedUser.firstName : ''}
                label={t('users.designer.form.placeholder.firstName')}
                dispatch={(value: string) =>
                  setUpdatedUser((currUpdatedUser) => {
                    if (currUpdatedUser) {
                      const newUpdatedUser = { ...currUpdatedUser };
                      newUpdatedUser.firstName = value;
                      return newUpdatedUser;
                    }
                  })
                }
              />
            </Box>
            <Box sx={InputBoxStyle}>
              <QCTextInput
                editable
                value={updatedUser ? updatedUser.job : ''}
                label={t('users.designer.form.placeholder.job')}
                dispatch={(value: string) =>
                  setUpdatedUser((currUpdatedUser) => {
                    if (currUpdatedUser) {
                      const newUpdatedUser = { ...currUpdatedUser };
                      newUpdatedUser.job = value;
                      return newUpdatedUser;
                    }
                  })
                }
              />
            </Box>
            <Box sx={InputBoxStyle}>
              <QCTreeViewAutocomplete
                label={t('users.users.table.organization')}
                allData={organizationOptions}
                value={updatedUser ? updatedUser.ouId : ''}
                dispatch={(value: TreeViewNode) =>
                  setUpdatedUser((currUpdatedUser) => {
                    if (currUpdatedUser) {
                      const newUpdatedUser = { ...currUpdatedUser };
                      newUpdatedUser.ouId = value.id;
                      return newUpdatedUser;
                    }
                  })
                }
              />
            </Box>
            <Box sx={InputBoxStyle}>
              <QCTextInput
                editable={false}
                placeholder={updatedUser ? updatedUser.userUPN : ''}
                value={updatedUser ? updatedUser.userUPN : ''}
                label={t('users.designer.form.login')}
              />
            </Box>
            <Box sx={InputBoxStyle}>
              {/* Non modifiable */}
              <QCSelect
                setOption={() => {}}
                options={[pageUser?.currentAuthMethods.value ?? '']}
                defaultValue={pageUser?.currentAuthMethods.value}
                label={t('users.designer.form.placeholder.methodAuth')}
              />
            </Box>
            <Box sx={InputBoxStyle}>
              <QCTextInput
                editable
                value={updatedUser ? updatedUser.phone : ''}
                label={t('users.designer.form.placeholder.phone')}
                dispatch={(value: string) =>
                  setUpdatedUser((currUpdatedUser) => {
                    if (currUpdatedUser) {
                      const newUpdatedUser = { ...currUpdatedUser };
                      newUpdatedUser.phone = value;
                      return newUpdatedUser;
                    }
                  })
                }
              />
            </Box>
            <Box sx={InputBoxStyle}>
              <QCTextInput
                editable
                value={updatedUser ? updatedUser.email : ''}
                label={t('users.designer.form.placeholder.email')}
                dispatch={(value: string) =>
                  setUpdatedUser((currUpdatedUser) => {
                    if (currUpdatedUser) {
                      const newUpdatedUser = { ...currUpdatedUser };
                      newUpdatedUser.email = value;
                      return newUpdatedUser;
                    }
                  })
                }
              />
            </Box>
          </Box>

          <Stack gap={2} flexBasis="24%" marginTop="40px">
            <QCButtonGlobal
              className="declaration-button"
              startIcon={<DescriptionOutlinedIcon />}
              variant="contained"
              value={t('userSettings.designer.action.seeDeclarations')}
              sx={{ width: 'fit-content' }}
            />
            <QCButtonGlobal
              className="activities-button"
              startIcon={<NotificationsNoneIcon />}
              variant="outlined"
              value={t('userSettings.designer.action.seeWaitingActivities')}
              sx={{ width: '220px', textAlign: 'left' }}
            />
          </Stack>
        </Box>

        <Box sx={{ display: 'flex', flexWrap: 'wrap', marginX: '3vw', gap: 2, marginTop: '3vh' }}>
          <Box sx={CardContainerStyle(theme)}>
            <Box sx={CardTextStyle}>
              <h2>Rôles</h2>
              {updatedUser ? t('users.designer.selectedProfils', { number: updatedUser.profilIds.length }) : ''}
            </Box>
            <List
              sx={{
                position: 'relative',
                height: '85%',
                overflow: 'scroll',
              }}
            >
              {updatedUser &&
                pageUser?.availableProfils.map((profil, index) => (
                  <div key={profil.id}>
                    <ListItem key={profil.id} sx={{ paddingY: 0, paddingLeft: 0 }}>
                      <Checkbox
                        checked={isProfilChecked(profil.id)}
                        onChange={(event, checked) => handleProfilCheck(profil.id, checked)}
                        sx={{ marginRight: '10px' }}
                      />
                      <ListItemText>{profil.name}</ListItemText>
                    </ListItem>
                    {index !== pageUser.availableProfils.length - 1 && (
                      <Divider
                        variant="inset"
                        sx={{ borderColor: theme.palette.menuBorder.main, marginLeft: '50px', width: '80%' }}
                        component="li"
                      />
                    )}
                  </div>
                ))}
            </List>
          </Box>
          <Box sx={CardContainerStyle(theme)}>
            <Box sx={CardTextStyle}>
              <h2>Caractéristiques</h2>
              {updatedUser ? t('users.designer.selectedProfils', { number: updatedUser.userParams.length }) : ''}
            </Box>

            <List
              sx={{
                position: 'relative',
                height: '85%',
                overflow: 'scroll',
              }}
            >
              {updatedUser &&
                pageUser &&
                pageUser.availableUserParameter.map((param, index) => (
                  <div key={param.id}>
                    <UserParamsListItem
                      checked={isParamChecked(param.id)}
                      baseParam={param}
                      associatedParam={updatedUser.userParams.find((el) => el.userParameterId === param.id)}
                      availableSharedLists={
                        param.userParameterType === UserSettingsType.SharedList
                          ? pageUser.availableSharedLists
                          : undefined
                      }
                      onParamCheck={(event, checked) => handleParamCheck(param.id, checked)}
                      getNewValue={(value) => handleParamChange(value, param.id)}
                    />

                    {index !== pageUser.availableUserParameter.length - 1 && (
                      <Divider
                        variant="inset"
                        sx={{ borderColor: theme.palette.menuBorder.main, marginLeft: '50px', width: '80%' }}
                        component="li"
                      />
                    )}
                  </div>
                ))}
            </List>
          </Box>
        </Box>

        <Box sx={ActionButtonsContainer}>
          <QCFabCorbeille onClickFunc={handleDelete}></QCFabCorbeille>
          <QCIconButton Icon={<SaveOutlinedIcon />} onClickFunc={handleSave} variantValue="contained" />
        </Box>
      </Box>
      <Backdrop open={isOpenBackDrop}>
        <CircularProgress />
      </Backdrop>
    </MenuWrapper>
  );
};

export default UserSettings;
