import { useContext, useEffect, useState } from 'react';
import { QCDialogFormContext } from '../../context';
import { BodyContext } from '../../StepBody/context';
import { AttributeValue } from '90.quickConnect.Models/siteAdmin/models/common/entities';

const useData = (label: string, attributData?: AttributeValue) => {
  const { setFilled, index } = useContext(BodyContext);
  const { allData, updateAllDataArray, addFieldAllDataArray } = useContext(QCDialogFormContext);

  const [defaultBool, setDefaultBool] = useState<boolean | undefined>(undefined);

  useEffect(() => {
    if (attributData) {
      attributData.value = new Boolean(defaultBool);
      addFieldAllDataArray(label, attributData, index);
    } else {
      addFieldAllDataArray(label, defaultBool as boolean, index);
    }
    //eslint-disable-next-line
  }, [defaultBool, addFieldAllDataArray, label, index]);

  useEffect(() => {
    if (allData[index - 1] && allData[index - 1].data) {
      const inputData = allData[index - 1].data;
      const valueIndex = inputData.findIndex((el) => el.label === label);
      if (valueIndex !== -1) {
        const { value } = inputData[valueIndex];
        if (typeof value == 'boolean') {
          setDefaultBool(value);
        }
      }
    }
  }, [allData, index, label]);

  const handleChange = (value: AttributeValue | boolean) => {
    if (typeof value === 'boolean') {
      setFilled(label, true);
      updateAllDataArray(label, value, index);
      setDefaultBool(value);
    } else {
      setFilled(label, true);
      updateAllDataArray(label, value, index);
      setDefaultBool(value.value.valueOf() as boolean);
    }
  };
  return { defaultBool, handleChange };
};

export default useData;
