import { useContext, useEffect, useState } from 'react';
import { QCDialogFormContext } from '../../context';
import { BodyContext } from '../../StepBody/context';
import { ControlProps } from '../../StepBody/type';

const useData = (label: string, mandatory: boolean, control?: ControlProps, hidden?: boolean) => {
  const [showPassword, setShowPassword] = useState<boolean>(false);

  const { addMandatoryField, deleteMandatoryField, setFilled, index, addControlledField, setValid } =
    useContext(BodyContext);
  const { allData, updateAllDataArray, addFieldAllDataArray, deleteFieldAllDataArray } =
    useContext(QCDialogFormContext);

  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    addFieldAllDataArray(label, defaultValue, index);
  }, [defaultValue, addFieldAllDataArray, label, index]);

  useEffect(() => {
    allData.sort((a, b) => a.step - b.step);
    if (allData[index - 1] && allData[index - 1].data) {
      const inputData = allData[index - 1].data;
      const valueIndex = inputData.findIndex((el) => el.label === label);
      if (valueIndex !== -1) {
        const { value } = inputData[valueIndex];
        if (typeof value === 'string') {
          setDefaultValue(value);
          if (!hidden && mandatory) {
            addMandatoryField({ label: label, filled: value !== '' });
          }
          if (control) {
            addControlledField({ label: label, valid: control.regex.exec(value) != null || value === '' });
          }
          if (hidden) {
            if (mandatory) deleteMandatoryField(label);
            deleteFieldAllDataArray(label, index);
          }
        }
      }
    }
  }, [
    allData,
    addMandatoryField,
    control,
    deleteMandatoryField,
    deleteFieldAllDataArray,
    addControlledField,
    index,
    label,
    mandatory,
    hidden,
  ]);

  useEffect(() => {
    setFilled(label, defaultValue !== '');
    if (control) setValid(label, control.regex.exec(defaultValue) != null || (!mandatory && defaultValue === ''));
    // eslint-disable-next-line
  }, [defaultValue]);

  const handleChange = (value: string) => {
    setFilled(label, value === '' ? false : true);
    if (control) setValid(label, control.regex.exec(value) != null || (!mandatory && value === ''));
    updateAllDataArray(label, value, index);
    setDefaultValue(value);
  };
  return { showPassword, defaultValue, handleChange, setShowPassword };
};

export default useData;
