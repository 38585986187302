import { QuickConnectRoles } from '90.quickConnect.Models/siteAdmin/enums/user/quickConnectRoles';

export const getRoleValue = (role: QuickConnectRoles) => {
  switch (role) {
    case QuickConnectRoles.C2SAdministrator:
      return 6;
    case QuickConnectRoles.Administrator:
      return 5;
    case QuickConnectRoles.Designer:
      return 4;
    case QuickConnectRoles.DesignerInter:
      return 3;
    case QuickConnectRoles.Analyst:
      return 2;
    case QuickConnectRoles.User:
      return 1;
    default:
      return 0;
  }
};
