import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GridColumns, GridRowId, GridSortModel, GridValidRowModel } from '@mui/x-data-grid';
import useServices from './services/declarationHistory';
import {
  actionsToChoiceMenuOption,
  DeclarationsListHeaderName,
  DeclarationsMultipleActions,
  DeclarationsRowActions,
  defaultDeclarationHistoriques,
} from './const';
import { GetDeclarationsListResponse } from './services/responses';
import NameDeclaCell from './RenderCells/NameDeclaration';
import AssociatedResCell from './RenderCells/AssociatedRessource';
import useEffectOnce from '80.quickConnect.core/shared/hooks/useEffectOnce';
import { toReadableDate } from '80.quickConnect.core/siteAdmin/helpers/toReadableDate';
import { useSafeNavigation } from '80.quickConnect.core/siteAdmin/hooks/useSafeNavigation';
import { getReplacedPath } from '80.quickConnect.core/shared/helpers/getReplacedPath';
import { DECLARATION } from '10.quickConnect.app/siteAdmin/routes/paths';
import { useDeleteDialog } from '80.quickConnect.core/siteAdmin/hooks/useDeleteDialog';
import { DeleteActionType } from '70.quickConnect.Components/siteAdmin/Other/DeleteConfirmationDialog/type';
import { ColumnFilterType } from '90.quickConnect.Models/shared/enums/columnFilterType';

// eslint-disable-next-line
const useData = () => {
  const { t } = useTranslation();
  const navigate = useSafeNavigation();
  const { getDeclarationHistoriques, deleteDeclarations } = useServices();
  const [openDeleteDialog, setOpenDeleteDialog, deleteState, deleteDispatch] = useDeleteDialog();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [declarationHistoriques, setDeclarationHistoriques] =
    useState<GetDeclarationsListResponse>(defaultDeclarationHistoriques);
  const [rows, setRows] = useState<GridValidRowModel[]>([]);
  const [rowCount, setRowcount] = useState(declarationHistoriques.totalCount);
  const [actualPage, setActualPage] = useState(0);
  // sorting orderBy
  const [actualInstructions, setActualInstructions] = useState([
    {
      direction: 1,
      column: 'editedAt',
    },
  ]);
  // textFilter
  const [actualFilter, setActualFilter] = useState('');

  // DataTable Row Action
  const [rowAction, setRowAction] = useState<{ option: string; rowId: GridRowId }>({ option: '', rowId: '' });
  const rowActionOptions = useMemo(() => actionsToChoiceMenuOption(Object.values(DeclarationsRowActions)), []);
  const [rowsAction, setRowsAction] = useState<{ option: string; rowIds: GridRowId[] }>({ option: '', rowIds: [] });
  const multipleActionOptions = useMemo(
    () => actionsToChoiceMenuOption(Object.values(DeclarationsMultipleActions)),
    [],
  );

  const pageSize = useMemo(() => 7, []);

  const cols = useMemo(() => {
    const initCols: GridColumns = [
      {
        field: DeclarationsListHeaderName.declarationName,
        headerName: t('declaration.detail.table.name'),
        flex: 2,
        renderCell: (params) => <NameDeclaCell params={params} />,
      },
      {
        field: DeclarationsListHeaderName.equipmentName,
        headerName: t('declaration.detail.linkedEquipement.title'),
        flex: 1.5,
        renderCell: (params) => <AssociatedResCell params={params} />,
      },
      {
        field: DeclarationsListHeaderName.date,
        headerName: t('declaration.detail.table.createdAt'),
        flex: 1,
        renderCell: (params) => {
          return <span>{toReadableDate(params.row.editedAt)}</span>;
        },
      },
    ];
    return initCols;
  }, [t]);

  const columns = useMemo(
    () => [
      {
        key: 'formType',
        value: true,
      },
      {
        key: 'editedAt',
        value: true,
      },
      {
        key: 'id',
        value: false,
      },
      {
        key: 'stringExtract',
        value: true,
      },
      {
        key: 'entityInstanceStringExtract',
        value: true,
      },
      {
        key: 'organizationalUnitStringExtract',
        value: true,
      },
      {
        key: 'userUpn',
        value: true,
      },
    ],
    [],
  );

  const filters = useMemo(
    () => [
      { columnName: 'editedAt', filters: [], type: 10 },
      {
        columnName: 'stringExtract',
        filters: actualFilter !== '' ? [actualFilter] : [],
        type: ColumnFilterType.TextContains,
      },
      {
        columnName: 'entityInstanceStringExtract',
        filters: [],
        type: 5,
      },
      { columnName: 'organizationalUnitStringExtract', filters: [], type: 5 },
      { columnName: 'userUpn', filters: [], type: 5 },
    ],
    [actualFilter],
  );

  const updateDeclarationsTable = useCallback(
    async (length: number, start: number, instruction: { direction: number; column: string }[]) => {
      setIsLoading(true);

      const result = await getDeclarationHistoriques({
        columns: columns,
        length: length,
        start: start > -1 ? start : 0,
        orderBy: instruction,
        textFilter: null,
        filters: filters,
        count: 1,
        lightSelect: true,
        wantToken: true,
      });
      setRows(result?.declarations ?? []);
      setRowcount(result?.totalCount ?? 0);
      setDeclarationHistoriques(result ?? defaultDeclarationHistoriques);
      setIsLoading(false);
    },
    [setDeclarationHistoriques, getDeclarationHistoriques, columns, filters],
  );

  // initialiser la table
  useEffectOnce(() => {
    updateDeclarationsTable(pageSize, 0, actualInstructions);
  });

  useEffect(() => {
    updateDeclarationsTable(pageSize, 0, actualInstructions);
    // eslint-disable-next-line
  }, [actualFilter]);

  // détecter le rowAction
  useEffect(() => {
    switch (rowAction.option) {
      case DeclarationsRowActions.Delete:
        setOpenDeleteDialog(true);
        break;
      case DeclarationsRowActions.View:
        if (rowAction.rowId && rowAction.rowId.toString().length > 0) {
          const path = getReplacedPath(DECLARATION, [rowAction.rowId.toString()]);
          navigate(path);
        }
        break;
      default:
        break;
    }
    // eslint-disable-next-line
  }, [rowAction]);

  useEffect(() => {
    if (rowsAction.option === DeclarationsMultipleActions.Delete && rowsAction.rowIds.length > 0) {
      setOpenDeleteDialog(true);
    }
    // else if (rowsAction.option === DeclarationsMultipleActions.Send && rowsAction.rowIds.length > 0) {
    //   // handlePrint(rowsAction.rowIds as string[]);
    // }
    //eslint-disable-next-line
  }, [rowsAction]);

  /** Supprimer les éléments */
  useEffect(() => {
    (async () => {
      let sendToTrash = false;
      switch (deleteState.choice) {
        // @ts-expect-error intentional fall through : diminuer la taille de fonction
        case DeleteActionType.SendToTrash:
          sendToTrash = true;
        // fallsthrough
        case DeleteActionType.Delete:
          setOpenDeleteDialog(false);
          //on regarde si ça vient de la datatable
          if (rowAction?.option === DeclarationsRowActions.Delete && rowAction.rowId !== '') {
            const ret = await deleteDeclarations({ ids: [rowAction.rowId.toString()], sendToRecycleBin: sendToTrash });
            setRowAction({ option: '', rowId: '' });
            if (ret !== undefined) {
              updateDeclarationsTable(pageSize, 0, actualInstructions);
              setActualPage(0);
            }
          } else if (rowsAction.option === DeclarationsMultipleActions.Delete && rowsAction.rowIds.length > 0) {
            const ret = await deleteDeclarations({ ids: rowsAction.rowIds as string[], sendToRecycleBin: sendToTrash });
            setRowsAction({ option: '', rowIds: [] });
            if (ret !== undefined) {
              updateDeclarationsTable(pageSize, 0, actualInstructions);
              setActualPage(0);
            }
          }
          break;
        case DeleteActionType.Cancel:
        default:
          setOpenDeleteDialog(false);
          break;
      }
    })();
    //eslint-disable-next-line
  }, [deleteState]);

  const dataTableCallBack = useCallback(
    (page: number) => {
      updateDeclarationsTable(pageSize, page * pageSize - pageSize, actualInstructions);
      setActualPage(page);
    },
    // eslint-disable-next-line
    [actualInstructions, updateDeclarationsTable, actualFilter],
  );

  const requestHandler = {
    paginationMode: 'server',
    rowCount: rowCount,
    onPageChange: dataTableCallBack,
  };

  const sortingHandler = useCallback(
    (model: GridSortModel) => {
      const instruction =
        model[0] !== undefined
          ? [
              {
                column: model[0].field,
                direction: model[0].sort === 'asc' ? 0 : 1,
              },
            ]
          : [
              {
                column: 'editedAt',
                direction: 0,
              },
            ];
      setActualInstructions(instruction);
      setActualPage(0);
      updateDeclarationsTable(pageSize, 0, instruction);
    },
    // eslint-disable-next-line
    [updateDeclarationsTable, actualFilter],
  );

  const sortingProps = {
    sortingMode: 'server',
    onSortModelChange: sortingHandler,
    page: actualPage,
  };

  // // expand cells in table
  // const [expanded, setExpanded] = useState(false);

  // const expandCell: GridColDef = {
  //   field: 'expand',
  //   renderCell: (params) => <ExpandButtonCell params={params} dispatch={setExpanded} state={expanded} />,
  //   renderHeader: () => null,
  //   sortable: false,
  //   flex: 0.5,
  // };

  return {
    t,
    rows,
    cols,
    isLoading,
    pageSize,
    setActualFilter,
    requestHandler,
    sortingProps,
    setRowAction,
    rowActionOptions,
    setRowsAction,
    multipleActionOptions,
    openDeleteDialog,
    deleteDispatch,
  };
};

export default useData;
