import { useCallback } from 'react';
import { toast } from 'react-toastify';
import axios, { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { defautVersionList } from '../const';
import {
  CreateVersionRequest,
  DeleteVersionRequest,
  GetVersionByIdRequest,
  GetVersionsRequest,
  UpdateVersionRequest,
} from './requests';
import {
  CreateVersionResponse,
  defaultVersion,
  DeleteVersionResponse,
  GetVersionByIdResponse,
  GetVersionsResponse,
  UpdateVersionResponse,
  Version,
} from './responses';
import ApiVersion from '40.quickConnect.DataAcces/siteAdmin/apiRoutes/versions';
import QCHttpRequest from '40.quickConnect.DataAcces/siteAdmin/QCHttpRequest';
import { isResponseValid } from '40.quickConnect.DataAcces/siteAdmin/responseValidator';

const useVersionServices = () => {
  const RequestHandler = new QCHttpRequest();
  const { t } = useTranslation();

  const getVersionList = useCallback(async (request: GetVersionsRequest): Promise<GetVersionsResponse> => {
    let ret = defautVersionList;
    const post = async () => {
      try {
        const response = await RequestHandler.post<GetVersionsResponse, GetVersionsRequest>(
          ApiVersion.getVersionList,
          request,
        );
        const isValid = isResponseValid(response.data);
        if (isValid) {
          ret = response.data;
        }
      } catch (error) {
        toast.error(`${(error as AxiosError<GetVersionsResponse, unknown>).response?.data?.message}`);
        ret = defautVersionList;
      }
    };
    await post();
    return ret;
    // eslint-disable-next-line
  }, []);

  const getVersionById = useCallback(async (id: string): Promise<Version> => {
    let ret: Version = defaultVersion;
    const post = async () => {
      try {
        const request: GetVersionByIdRequest = {
          id: id,
        };
        const response = await RequestHandler.post<GetVersionByIdResponse, GetVersionByIdRequest>(
          ApiVersion.getVersion,
          request,
        );
        const isValid = isResponseValid(response.data);
        if (isValid) {
          ret = response.data.parameter;
        }
      } catch (error) {
        toast.error(`${(error as AxiosError<GetVersionByIdResponse, unknown>).response?.data?.message}`);
        ret = defaultVersion;
      }
    };
    await post();
    return ret;
    // eslint-disable-next-line
  }, []);

  const createVersion = async (request: CreateVersionRequest) => {
    try {
      const response = await RequestHandler.post<CreateVersionResponse, CreateVersionRequest>(
        ApiVersion.createVersion,
        request,
      );
      if (response.status === 200) {
        toast.success(t('userSettings.designer.popup.create.success'));
        return response;
      }
    } catch (error) {
      if (axios.isAxiosError(error)) toast.error(t(`${error.response?.data?.message}`));
    }
  };

  const deleteVersion = async (request: DeleteVersionRequest) => {
    const requestHandler = new QCHttpRequest();

    try {
      const response = await requestHandler.post<DeleteVersionResponse, DeleteVersionRequest>(
        ApiVersion.deleteVersion,
        request,
      );

      isResponseValid(response.data, t('userSettings.designer.popup.delete.success'), t);
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast.error(`${error.response?.data?.message}`);
      }
    }
  };

  const updateVersion = async (request: UpdateVersionRequest) => {
    const requestHandler = new QCHttpRequest();

    requestHandler
      .post<UpdateVersionResponse, UpdateVersionRequest>(ApiVersion.updateVersion, request)
      .then((response) => {
        const isValid = isResponseValid(response.data, t('version.setting.save.succes'), t);

        if (isValid) {
          return response.data.parameter;
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(`${error.response?.data?.message}`);
      });

    return defaultVersion;
  };

  return { getVersionList, createVersion, deleteVersion, getVersionById, updateVersion };
};

export default useVersionServices;
