import { Box } from '@mui/material';
import React, { useContext } from 'react';
import { ResCharacContext } from '../context';
import { characBoxStyle } from '../styles';
import useData from './hooks';
import BoolRadioInput from '70.quickConnect.Components/siteAdmin/Inputs/BoolRadioInput';
import { AttributeValue } from '90.quickConnect.Models/siteAdmin/models/common/entities';

const CharacBoolInput = ({ att }: { att: AttributeValue }) => {
  const { getLabel, setUpdateResourceData } = useContext(ResCharacContext);
  const { getValue, handleChange } = useData(setUpdateResourceData);

  return (
    <Box sx={characBoxStyle}>
      <BoolRadioInput
        label={getLabel(att.attributeId)}
        value={getValue(att)}
        dispatch={(event) => {
          handleChange(event.target.value, att.attributeId);
        }}
      />
    </Box>
  );
};

export default CharacBoolInput;
