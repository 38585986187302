import React, { ChangeEvent } from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { Box, MenuItem, useTheme } from '@mui/material';
import QCInputHeader from '../QCInputHeader';
import { InputContainerStyle } from '../style';
import { SelectProps } from './types';

const QCSelect = ({ label, defaultValue, options, setOption, info, mandatory, onChange, selectProps }: SelectProps) => {
  const handleChange = (event: SelectChangeEvent) => {
    setOption(event.target.value as string);
    if (onChange) onChange(event as ChangeEvent);
  };
  const theme = useTheme();

  return (
    <Box sx={InputContainerStyle(theme, mandatory)}>
      {label && <QCInputHeader label={label} info={info} />}
      <Select
        value={defaultValue ?? options[0]}
        onChange={handleChange}
        {...Object.entries(selectProps ?? {}).filter(([key]) => key !== 'onChange')}
      >
        {options.map((option: string, index: number) => {
          return (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          );
        })}
      </Select>
    </Box>
  );
};

export default QCSelect;
