import { Dispatch, SetStateAction, useReducer, useState } from 'react';
import {
  ReducerSaveState,
  SaveAction,
  saveConfirmationReducer,
} from '70.quickConnect.Components/siteAdmin/Other/SaveConfirmationDialog/type';

type SaveDialogHookReturnType = [
  openSaveDialog: boolean,
  setOpenSaveDialog: Dispatch<SetStateAction<boolean>>,
  saveState: ReducerSaveState,
  saveDispatch: Dispatch<SaveAction>,
];
const useSaveDialog = (): SaveDialogHookReturnType => {
  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [saveState, saveDispatch] = useReducer(saveConfirmationReducer, { isSaved: false });

  return [openSaveDialog, setOpenSaveDialog, saveState, saveDispatch];
};

export default useSaveDialog;
