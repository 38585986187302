import React from 'react';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { Box, Dialog, IconButton, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { typographyStyles } from '../styles';
import { CharacDialogType } from './types';
import { dialogHeaderStyle, innerBoxStyle, dialogPaperStyle } from './styles';

const CharacDialog = ({ open, dispatch, children }: CharacDialogType) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: dialogPaperStyle,
      }}
    >
      <Box sx={dialogHeaderStyle}>
        <Typography gutterBottom sx={typographyStyles}>
          {t('entityData.editor.designInstance.attributeTitle')}
        </Typography>
        <IconButton onClick={() => dispatch(false)}>
          <FullscreenExitIcon />
        </IconButton>
      </Box>
      <Box sx={innerBoxStyle}>{children}</Box>
    </Dialog>
  );
};

export default CharacDialog;
