/**
 * Fonction pour construire les routes de navigation
 * @param {string} path - La route de navigation contenant ":id" à remplacer
 * @param {string[]} ids - Les identifiants remplaçant les positions de ":id"
 * @returns La route remplacée
 * @example
 * const path = "/equipments/:entityId/:equipmentId/settings"
 * const ids = ["Id_famille", "Id_equipment"]
 * const route = getReplacedPath(path, ids)
 * // route = "/equipments/Id_famille/Id_equipment/settings"
 */
export const getReplacedPath = (path: string, ids: string[]) => {
  const regex = new RegExp(/(?<id>:[^/]*)/, 'g');
  const matchedStrs = path.match(regex);
  // rien matched
  if (matchedStrs === null) return path;
  // nombre of matched strings != ids length
  if (matchedStrs.length !== ids.length) {
    throw Error('The ids length does not correspond to the number of id to be replaced');
  }
  const mapObject = Object.fromEntries(matchedStrs.map((matched, i) => [matched, ids[i]]));
  return path.replace(regex, function (match: string) {
    return mapObject[match];
  });
};
