import { useCallback } from 'react';
import { useTraductionContext } from '../context';

const useData = () => {
  const { supportedLangs, setDestLang, destChampsCount } = useTraductionContext();

  const handleTabsChange = useCallback(
    (event: React.SyntheticEvent, newValue: string) => {
      setDestLang((prev) => supportedLangs.find((lg) => lg.value === newValue) ?? prev);
    },
    [supportedLangs, setDestLang],
  );

  const checkChamps = useCallback(
    (language: string) => {
      const findChamp = destChampsCount.find((ch) => ch.lang === language);
      if (findChamp && findChamp.count === findChamp.total) {
        return true;
      }
      return false;
    },
    [destChampsCount],
  );

  return {
    handleTabsChange,
    checkChamps,
  };
};

export default useData;
