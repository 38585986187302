import React from 'react';
import { Button, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { QCSubMenuAddButtonProps } from './type';
import { buttonSubMenuAdd } from './styles';

const QCSubMenuAddButton = ({ text, icon, onClick }: QCSubMenuAddButtonProps) => {
  // const [expanded, setExpanded] = useState(false);

  return (
    // <div onMouseEnter={() => setExpanded(true)} onMouseLeave={() => setExpanded(false)} onClick={onClick}>
    <Button variant="outlined" sx={buttonSubMenuAdd} onClick={onClick}>
      <Stack direction="row" gap={1}>
        {icon}
        <p className="addText">{text.toLocaleUpperCase()}</p>
        <AddIcon></AddIcon>
      </Stack>
    </Button>
    // </div>
  );
};

export default QCSubMenuAddButton;
