import React, { ChangeEvent, ChangeEventHandler, useEffect, useState } from 'react';
import { Box, ListItem, Checkbox, IconButton, Collapse } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { AssociatedUserSettings, BaseUserSettings, UserSettingsType } from '../../Users/types';
import { renderParameter } from './help';
import { SharedList } from '90.quickConnect.Models/siteAdmin/models/sharedList/sharedList';

const UserParamsListItem = ({
  checked,
  baseParam,
  associatedParam,
  availableSharedLists,
  onParamCheck,
  getNewValue,
}: {
  checked: boolean;
  baseParam: BaseUserSettings;
  associatedParam?: AssociatedUserSettings;
  availableSharedLists?: SharedList[];
  onParamCheck: (event: ChangeEvent, checked: boolean) => void;
  getNewValue: (value: string) => void;
}) => {
  const [open, setOpen] = useState(false);

  const [associatedSharedList, setAssociatedSharedList] = useState<SharedList>();

  const handleCollapseClick = () => {
    setOpen((currOpen) => !currOpen);
  };

  // pour les params de type sharedList
  useEffect(() => {
    if (
      baseParam.userParameterType === UserSettingsType.SharedList &&
      availableSharedLists &&
      typeof baseParam.value === 'string'
    ) {
      const listJSON = JSON.parse(baseParam.value);
      const sharedListToAssociate = availableSharedLists.find((list) => list.id === listJSON.listId);
      setAssociatedSharedList(sharedListToAssociate);
    }
    //eslint-disable-next-line
  }, []);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    switch (baseParam.userParameterType) {
      case UserSettingsType.Boolean:
        getNewValue(event.target.checked ? 'true' : 'false');
        break;
      case UserSettingsType.Int: {
        if (typeof baseParam.value !== 'string') return;
        const paramValue = JSON.parse(baseParam.value);
        // on vérifie que c'est un type UserSettingsIntValue
        if (typeof paramValue === 'object' && paramValue !== null && !Array.isArray(paramValue)) {
          if (
            event.target.value.includes('.') ||
            (paramValue.min !== undefined && paramValue.min > parseInt(event.target.value)) ||
            (paramValue.max !== undefined && paramValue.max < parseInt(event.target.value))
          ) {
            return;
          }
          getNewValue(event.target.value);
        }
        break;
      }
      case UserSettingsType.Decimal: {
        if (typeof baseParam.value !== 'string') return;
        const paramValue = JSON.parse(baseParam.value);
        // on vérifie que c'est un type UserSettingsIntValue
        if (typeof paramValue === 'object' && paramValue !== null && !Array.isArray(paramValue)) {
          if (
            (paramValue.min !== undefined && paramValue.min > parseFloat(event.target.value)) ||
            (paramValue.max !== undefined && paramValue.max < parseFloat(event.target.value))
          ) {
            return;
          }
          getNewValue(event.target.value);
        }
        break;
      }
      case UserSettingsType.Text:
        getNewValue(event.target.value);
        break;
      case UserSettingsType.SharedList:
        if (associatedSharedList) {
          const associatedData = associatedSharedList.data.find((data) => data.label === event.target.value);
          if (associatedData) getNewValue(associatedData.value);
        }
    }
  };
  return (
    <>
      <ListItem sx={{ paddingY: 0, paddingLeft: 0 }}>
        <Checkbox checked={checked} sx={{ marginRight: '10px' }} onChange={onParamCheck} />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '83%' }}>
          {baseParam.name}
          {checked && (
            <IconButton onClick={handleCollapseClick}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          )}
        </Box>
      </ListItem>

      {checked && (
        <Collapse in={open}>{renderParameter(handleChange, baseParam, associatedParam, associatedSharedList)}</Collapse>
      )}
    </>
  );
};

export default UserParamsListItem;
