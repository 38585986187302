import React from 'react';
import useData from './hooks';
import QCAutocomplete from '70.quickConnect.Components/siteAdmin/Inputs/QCAutocomplete';
import { QCAutocompleteProps } from '70.quickConnect.Components/siteAdmin/Inputs/QCAutocomplete/type';
import { AttributeValue } from '90.quickConnect.Models/siteAdmin/models/common/entities';

const QCDialogFormAutocomplete = ({
  label,
  mandatory,
  options,
  info,
  dispatch,
  emptyOption = true,
  attributData,
}: QCAutocompleteProps & { label: string; attributData?: AttributeValue }) => {
  const { handleChange, defaultOption } = useData(label, mandatory ?? false, options, attributData);
  return (
    <QCAutocomplete
      label={label}
      dispatch={(value: string) => {
        if (attributData) {
          attributData.value = new String(value);
          handleChange(attributData);
        } else {
          handleChange(value);
        }
        if (dispatch) dispatch(value);
      }}
      mandatory={mandatory}
      options={emptyOption ? ['', ...options] : options}
      className="dialog-autocomplete"
      info={info}
      value={defaultOption}
    />
  );
};

export default QCDialogFormAutocomplete;
