import { UseDataProps } from './types';
import { useStore } from '30.quickConnect.Store/siteAdmin';

export const useData = (): UseDataProps => {
  const {
    UserSettingsStore: { role, subscribedFeatures },
    SideMenuStore: { isOpenSideMenu, setIsOpenSideMenu, focusedBtn, setFocusedBtn, openedCollapse, setOpenedCollapse },
  } = useStore();

  return {
    isOpenSideMenu,
    setIsOpenSideMenu,
    focusedBtn,
    setFocusedBtn,
    openedCollapse,
    setOpenedCollapse,
    role,
    subscribedFeatures,
  };
};
