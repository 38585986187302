import React from 'react';
import { Box, Paper, SxProps, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import { countCardTextBox, iconBox } from './styles';
import { CountCardProps } from './types';

/**
 * Composant pour afficher les champs complétés.
 * @component
 * @param {number} count - Le nombre des champs complétés.
 * @param {number} total - Le nombre total.
 * @example
 * const count: number = 12
 * const total: number = 43
 * <QCCountCard count={count} total={total} />
 */
const QCCountCard = ({ count, total }: CountCardProps) => {
  const calcul = (countValue: number, totalValue: number) => {
    return countValue == totalValue;
  };
  const style = useTheme().components?.QcCountCard;
  const sx = (): SxProps | undefined => {
    if (calcul(count, total)) {
      return style?.variants.complete;
    } else {
      return style?.variants.incomplete;
    }
  };

  return (
    <Paper sx={sx()}>
      <Box>{calcul(count, total) ? <CheckIcon sx={iconBox('Check')} /> : <CloseIcon sx={iconBox('Close')} />}</Box>
      <Box sx={countCardTextBox}>
        <span>
          {count}/{total}
        </span>
        <span style={{ marginBottom: '10px', textAlign: 'center' }}>
          {/*t('countCard.Text')*/}champs
          <br /> complétés
        </span>
      </Box>
    </Paper>
  );
};

export default QCCountCard;
