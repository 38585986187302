import { SxProps } from '@mui/material';

export const docBoxStyle: SxProps = {
  overflow: 'auto',
  width: '95%',
  minHeight: '52vh',
  height: 'fit-content',
  mx: '5px',
  paddingRight: '5px',
};
