import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { familyNodeParser } from '../familyNodeParser';
import { SubMenuFamilyRowAction } from '../MenuActions/family';
import { useRessourcesServices } from '../services/services';
import FamilyIcon from '00.app/icons/FamilyIcon';
import { SubMenuRow } from '10.quickConnect.app/siteAdmin/components/shared/SubMenu/types';
import { useStore } from '30.quickConnect.Store/siteAdmin';
import useEffectOnce from '80.quickConnect.core/shared/hooks/useEffectOnce';
import { Family } from '90.quickConnect.Models/siteAdmin/models/families/family';
import { useSafeNavigation } from '80.quickConnect.core/siteAdmin/hooks/useSafeNavigation';
import { useDeleteDialog } from '80.quickConnect.core/siteAdmin/hooks/useDeleteDialog';
import { DeleteActionType } from '70.quickConnect.Components/siteAdmin/Other/DeleteConfirmationDialog/type';
import { getReplacedPath } from '80.quickConnect.core/shared/helpers/getReplacedPath';
import { ENTITYSETTINGS } from '10.quickConnect.app/siteAdmin/routes/paths';

export const useRessourcesHomeData = () => {
  const { t } = useTranslation();
  const navigate = useSafeNavigation();
  const {
    SubMenuStore: {
      setRows,
      setSubMenuPath,
      setRouteBase,
      setAddText,
      setAddIcon,
      setRowActions,
      selectedRowAction,
      currentSubMenuRow,
      setSelectedRowAction,
      selectedRowsMultipleAction,
      setSelectedRowsMultipleAction,
      setMultipleActions,
      setHandleAddClick,
    },
  } = useStore();

  const { getFamilies, deleteFamily } = useRessourcesServices();

  const [families, setFamilies] = useState<Family[]>([]);

  const [openAddFamilyDialog, setOpenAddFamilyDialog] = useState(false);

  const [openDeleteDialog, setOpenDeleteDialog, deleteState, deleteDispatch] = useDeleteDialog();

  const initFamiliesData = useCallback(async () => {
    setRows([]);
    const data = await getFamilies({
      count: 1,
      filters: [],
      length: 0,
      loadAttributes: false,
      name: '',
      start: 0,
      textFilter: null,
      orderBy: [],
    });

    if (data && data.length > 0) {
      const layers: SubMenuRow[][] = familyNodeParser(data);
      setFamilies(data);
      setRows(layers[0]);
      setRouteBase('/equipments/entity');
      setSubMenuPath([
        {
          id: 'root',
          name: t('navigation.menu.equipments'),
          rows: layers[0],
        },
      ]);
      setAddText(t('entitySchema.entities.actions.add'));
      setAddIcon(<FamilyIcon />);
      setRowActions(Object.values(SubMenuFamilyRowAction));
      setMultipleActions([{ text: t('submenu.multipleAction.delete') }]);
      setHandleAddClick(() => setOpenAddFamilyDialog(true));
    }
    //eslint-disable-next-line
  }, []);

  // gestion d'une action sous-menu
  useEffect(() => {
    if (!selectedRowAction) return;
    switch (selectedRowAction.option) {
      case t(SubMenuFamilyRowAction.Delete):
        setOpenDeleteDialog(true);
        break;
      case t(SubMenuFamilyRowAction.Settings):
        setSelectedRowAction({ option: '', rowId: '' });
        // navigate(`/equipments/entity/${selectedRowAction.rowId}/settings`);
        navigate(getReplacedPath(ENTITYSETTINGS, [selectedRowAction.rowId]));
        break;
    }
    //eslint-disable-next-line
  }, [selectedRowAction]);

  useEffect(() => {
    if (!selectedRowsMultipleAction) return;
    switch (selectedRowsMultipleAction.option) {
      case t(SubMenuFamilyRowAction.Delete):
        setOpenDeleteDialog(true);
        break;
    }
    //eslint-disable-next-line
  }, [selectedRowsMultipleAction]);

  const handleDeleteFamily = async (ids: string[], sendToRecycleBin: boolean) => {
    await deleteFamily({ ids: ids, sendToRecycleBin: sendToRecycleBin });
    initFamiliesData();
  };

  useEffect(() => {
    let sendToRecycleBin = false;
    switch (deleteState.choice) {
      // @ts-expect-error intentional fall through : diminuer la taille de fonction
      case DeleteActionType.SendToTrash:
        sendToRecycleBin = true;
      // fallsthrough
      case DeleteActionType.Delete:
        setOpenDeleteDialog(false);
        if (selectedRowAction && selectedRowAction.rowId) {
          handleDeleteFamily([selectedRowAction.rowId], sendToRecycleBin);
          setSelectedRowAction(undefined);
        } else if (selectedRowsMultipleAction && selectedRowsMultipleAction.rowsId) {
          handleDeleteFamily(selectedRowsMultipleAction.rowsId, sendToRecycleBin);
          setSelectedRowsMultipleAction(undefined);
        } else if (currentSubMenuRow) {
          handleDeleteFamily([currentSubMenuRow.id], sendToRecycleBin);
        }
        break;
      case DeleteActionType.Cancel:
      default:
        setOpenDeleteDialog(false);
        break;
    }
    //eslint-disable-next-line
  }, [deleteState]);

  useEffectOnce(() => {
    initFamiliesData();
  });
  return {
    t,
    families,
    openAddFamilyDialog,
    setOpenAddFamilyDialog,
    openDeleteDialog,
    deleteDispatch,
  };
};
