import API_HOST from './apiHost';
import { ApiRoutesType } from './type';

const API_ACCOUNT = `${API_HOST}Account/`;

const ApiAccount: ApiRoutesType = {
  ValidateToken: `${API_ACCOUNT}validateToken`,
  Signin: `${API_ACCOUNT}signin`,
  Signout: `${API_ACCOUNT}signout`,
};

// export const Signin = ApiAccount.Signin;
// export const ValidateToken = ApiAccount.ValidateToken;
// export const Signout = ApiAccount.Signout;

export default ApiAccount;

// class ApiAccount {
//   static ValidateToken: string = `${API_ACCOUNT}validateToken`;
//   static Signin: string = `${API_ACCOUNT}signin`;
//   static Signout: string = `${API_ACCOUNT}signout`;
// }
