import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';

import { ErrorComponentProps } from './type';
import { Name } from './const';

const ErrorComponent = ({ error }: ErrorComponentProps) => {
  const { t } = useTranslation();
  return (
    <Box data-cy={Name}>
      <h3>
        <Typography marginY="5vh" marginX="2vw">
          {t('common.error.oups.title')}
        </Typography>
      </h3>
      <Typography marginX="2vw">{error}</Typography>
    </Box>
  );
};

export default ErrorComponent;
