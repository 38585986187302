import React from 'react';
import { TreeItem } from '@mui/lab';
import { SxProps } from '@mui/system';
import { TreeViewNode } from './types';

export const createItem = (Item: TreeViewNode, sx: SxProps) => {
  return (
    <TreeItem nodeId={Item.id} label={Item.name} key={Item.id} sx={sx}>
      {Item.children.map((item) => createItem(item, sx))}
    </TreeItem>
  );
};

const searchNode = (nodeId: string, node: TreeViewNode): undefined | TreeViewNode => {
  if (node.id === nodeId) return node;

  if (node.children.length === 0) return undefined;

  for (let i = 0; i < node.children.length; i++) {
    const res = searchNode(nodeId, node.children[i]);

    if (res !== undefined) {
      return res;
    }
  }
  return undefined;
};

export const findNode = (nodeId: string, nodeList: TreeViewNode[]) => {
  if (nodeList.length === 0) return { children: [], id: '0', name: 'test', parentId: '', parentName: '' };
  for (let i = 0; i < nodeList.length; i++) {
    const res = searchNode(nodeId, nodeList[i]);

    if (res !== undefined) {
      return res;
    }
  }
  return nodeList[0];
};
