import { SxProps, Theme } from '@mui/material';
import { grey2 } from '00.app/theme/colors';

export const outterBoxStyles = (theme: Theme): SxProps => {
  return {
    display: 'flex',
    flexDirection: 'column',
    border: `1px solid ${theme.palette.borderGroup.main}`,
    borderRadius: '10px',
    minWidth: '40vw',
    width: 'fit-content',
    backgroundColor: theme.palette.background.paper,
  };
};

export const innerBoxStyles = (): SxProps => {
  return {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'stretch',
    margin: '10px',
    position: 'relative',
  };
};

export const dividerStyles = (): SxProps => {
  return {
    display: 'flex',
    alignSelf: 'center',
    borderColor: grey2,
    width: '90%',
  };
};

export const btnSeeMoreStyles = (theme: Theme): SxProps => {
  return {
    // position: 'relative',
    backgroundColor: theme.palette.background.paper,
    mb: '7px',
    mr: '7px',
    mt: '-10px',
  };
};

export const dateTimeBoxStyles = (): SxProps => {
  return {
    display: 'flex',
    alignItems: 'stretch',
    flexDirection: 'column',
  };
};

export const typographyStyles = (theme: Theme): SxProps => {
  return {
    color: theme.palette.text.secondary,
    fontStyle: 'italic',
    fontWeight: 400,
    textTransform: 'capitalize',
  };
};
