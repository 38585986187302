import React from 'react';
import { Box, Skeleton } from '@mui/material';
import {
  FoldedMenuLogoBoxStyle,
  MainBoxMenuStyle,
  MenuBottomBoxStyle,
  MenuBtnStyle,
  MenuMiddleBoxStyle,
  OpenedMenuLogoBoxStyle,
} from './styles';
import { useStore } from '30.quickConnect.Store/siteAdmin';
import { kraft400 } from '00.app/theme/colors';

const MenuSkeleton = () => {
  const {
    SideMenuStore: { isOpenSideMenu },
  } = useStore();

  return (
    <Box sx={MainBoxMenuStyle(isOpenSideMenu)}>
      <Box sx={isOpenSideMenu ? OpenedMenuLogoBoxStyle : FoldedMenuLogoBoxStyle}>
        <Skeleton
          variant="rectangular"
          animation="wave"
          sx={{
            width: isOpenSideMenu ? '50%' : '90%',
            height: isOpenSideMenu ? 70 : 50,
            border: `1px solid ${kraft400}`,
          }}
        />
        <Skeleton variant="circular" animation="wave" width={40} height={40} sx={{ border: `1px solid ${kraft400}` }} />
      </Box>

      <Box sx={MenuMiddleBoxStyle}>
        <Skeleton variant="rectangular" animation="wave" sx={MenuBtnStyle(isOpenSideMenu)} />
        <Skeleton variant="rectangular" animation="wave" sx={MenuBtnStyle(isOpenSideMenu)} />
        <Skeleton variant="rectangular" animation="wave" sx={MenuBtnStyle(isOpenSideMenu)} />
        <Skeleton variant="rectangular" animation="wave" sx={MenuBtnStyle(isOpenSideMenu)} />
        <Skeleton variant="rectangular" animation="wave" sx={MenuBtnStyle(isOpenSideMenu)} />
        <Skeleton variant="rectangular" animation="wave" sx={MenuBtnStyle(isOpenSideMenu)} />
      </Box>

      <Box sx={MenuBottomBoxStyle(isOpenSideMenu)}>
        <Skeleton variant="circular" animation="wave" width={40} height={40} sx={{ border: `1px solid ${kraft400}` }} />
        {isOpenSideMenu && (
          <Skeleton
            variant="text"
            animation="wave"
            sx={{ fontSize: '2rem', width: 70, border: `1px solid ${kraft400}` }}
          />
        )}
      </Box>
    </Box>
  );
};

export default MenuSkeleton;
