import { DeleteRoleResponse, GetRoleListResponse } from './services/response';
import { Role } from './types';

export const defaultRoleList: Role[] = [];

export const defaultRoleListResponse: GetRoleListResponse = {
  profils: [],
  message: null,
  status: 0,
  errorCode: null,
};

export const defaultDeleteRoleResponse: DeleteRoleResponse = { results: [], status: 0, message: null, errorCode: null };
