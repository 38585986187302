export const BinElementType = {
  Unknow: 0,
  OrganizationUnit: 1,
  Profil: 2,
  User: 3,
  Form: 4,
  EntitySchema: 5,
  EntityInstance: 6,
  Declaration: 7,
  Inbox: 8,
  SharedList: 9,
  Webhook: 10,
  Field: 11,
  UserParameter: 12,
  QCVar: 13,
  EntitySchemaAttributes: 14,
} as const;

export type BinElementTypeEnum = typeof BinElementType[keyof typeof BinElementType];
