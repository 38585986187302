import { SxProps } from '@mui/material';

export const buttonSubMenuAdd: SxProps = {
  textTransform: 'none',
  boxSizing: 'border-box',
  border: '2px solid',
  borderRadius: '8px',
  transition: '0.5s',
  '.addText': {
    display: 'none',
    transition: '0.5s',
  },
  ':hover': {
    '.addText': {
      transition: '0.5s',
      display: 'block',
    },
    border: '2px solid',
  },
};
