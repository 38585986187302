import React from 'react';
import useData from './hooks';
import NotificationInput from '70.quickConnect.Components/siteAdmin/Inputs/NotificationInput';
import { QCTextInputProps } from '70.quickConnect.Components/siteAdmin/Inputs/QCTextInput/type';
import { AttributeValue } from '90.quickConnect.Models/siteAdmin/models/common/entities';

const QCDialogFormNotification = ({
  label,
  info,
  attributData,
  mandatory,
}: QCTextInputProps & { attributData: AttributeValue }) => {
  const { handleChange } = useData(label, mandatory ?? false, attributData);
  return (
    <NotificationInput
      label={label}
      info={info}
      dispatch={(value) => {
        attributData.value = new String(JSON.stringify(value));
        handleChange(attributData);
      }}
    />
  );
};

export default QCDialogFormNotification;
