import { observer } from 'mobx-react';
import React from 'react';
import MenuWrapper from '10.quickConnect.app/siteAdmin/components/shared/MenuWrapper';

// Composant pour tester les routes
const CommingSoon = () => {
  return (
    <>
      <MenuWrapper>{}</MenuWrapper>
    </>
  );
};

export default observer(CommingSoon);
