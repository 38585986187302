import { Box } from '@mui/system';
import { observer } from 'mobx-react';
import React from 'react';
import { Card } from '@mui/material';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import MenuWrapper from '../../../shared/MenuWrapper';
import { useData } from './hooks';
import { ActionButtonsContainer, EditContainerStyle, InputsCardStyle } from './styles';
import ContentHeader from '70.quickConnect.Components/siteAdmin/Other/ContentHeader';
import DeleteConfirmationDialog from '70.quickConnect.Components/siteAdmin/Other/DeleteConfirmationDialog';
import SaveConfirmationDialog from '70.quickConnect.Components/siteAdmin/Other/SaveConfirmationDialog';
import QCTextInput from '70.quickConnect.Components/siteAdmin/Inputs/QCTextInput';
import QCTextArea from '70.quickConnect.Components/siteAdmin/Inputs/QCTextArea';
import QCAutocomplete from '70.quickConnect.Components/siteAdmin/Inputs/QCAutocomplete';
import QCFabCorbeille from '70.quickConnect.Components/siteAdmin/Buttons/QCFabCorbeille';
import QCIconButton from '70.quickConnect.Components/siteAdmin/Buttons/QCIconButton';

const VersionQCUpdate = () => {
  const {
    saveDispatch,
    openDeleteDialog,
    openSaveDialog,
    deleteDispatch,
    pageVersion,
    t,
    newPageVersion,
    setNewPageVersion,
    compagnonOptions,
    handleDelete,
    handleSave,
    theme,
    control,
  } = useData();
  return (
    <MenuWrapper>
      <SaveConfirmationDialog dispatch={saveDispatch} open={openSaveDialog} />
      <DeleteConfirmationDialog dispatch={deleteDispatch} open={openDeleteDialog} />
      <Box>
        <ContentHeader>
          <ContentHeader.BackButton />
          <ContentHeader.TitlesGroup>
            <ContentHeader.OverTitle>{t('version.setting.overtitle')}</ContentHeader.OverTitle>
            <ContentHeader.Title>{pageVersion?.name}</ContentHeader.Title>
          </ContentHeader.TitlesGroup>
        </ContentHeader>
      </Box>
      <Box sx={EditContainerStyle}>
        <Card sx={InputsCardStyle(theme)}>
          <h2>{t('version.setting.cardtitle')}</h2>
          <Box width="300px">
            <QCTextInput
              editable
              value={newPageVersion ? newPageVersion.name : ''}
              label={t('setting.designer.placeholder.name')}
              dispatch={(value: string) => {
                setNewPageVersion((currentVersion) => {
                  if (currentVersion) {
                    const newVersion = { ...currentVersion };
                    newVersion.name = value;
                    return newVersion;
                  }
                });
              }}
              control={control.notNull}
              datacy="version-name"
            ></QCTextInput>
          </Box>
          <Box width="300px">
            <QCTextInput
              editable
              value={newPageVersion ? newPageVersion.description : ''}
              label={t('setting.designer.placeholder.description')}
              dispatch={(value: string) => {
                setNewPageVersion((currentVersion) => {
                  if (currentVersion) {
                    const newVersion = { ...currentVersion };
                    newVersion.description = value;
                    return newVersion;
                  }
                });
              }}
              datacy="version-desc"
            ></QCTextInput>
          </Box>
          <Box width="300px">
            <QCAutocomplete
              options={compagnonOptions.map((elem) => elem.name)}
              value={newPageVersion ? newPageVersion.companionQCId : ''}
              label={t('setting.designer.placeholder.companionId')}
              dispatch={(value: string) => {
                setNewPageVersion((currentVersion) => {
                  if (currentVersion) {
                    const newVersion = { ...currentVersion };
                    newVersion.companionQCId = value;
                    return newVersion;
                  }
                });
              }}
              datacy="version-companion"
            ></QCAutocomplete>
          </Box>
          <Box width="300px">
            <QCTextArea
              value={newPageVersion ? newPageVersion.paramValue : ''}
              label={t('setting.designer.placeholder.value')}
              dispatch={(value: string) => {
                setNewPageVersion((currentVersion) => {
                  if (currentVersion) {
                    const newVersion = { ...currentVersion };
                    newVersion.paramValue = value;
                    return newVersion;
                  }
                });
              }}
              control={control.notNull}
              datacy="version-value"
            ></QCTextArea>
          </Box>
        </Card>
      </Box>
      <Box sx={ActionButtonsContainer}>
        <QCFabCorbeille onClickFunc={handleDelete}></QCFabCorbeille>
        <QCIconButton Icon={<SaveOutlinedIcon />} onClickFunc={handleSave} variantValue="contained" />
      </Box>
    </MenuWrapper>
  );
};

export default observer(VersionQCUpdate);
