import React from 'react';
import { Box, Stack, useTheme } from '@mui/material';
import QCInfoBubble from '../../Other/QCInfoBubble';
import { QCInputHeaderProps } from './type';
import { QCInputLabelStyle } from './style';

const QCInputHeader = ({ label, info, mandatory }: QCInputHeaderProps) => {
  const theme = useTheme();
  return (
    <Stack direction={'row'} justifyContent="space-between">
      <Box
        sx={QCInputLabelStyle}
        color={mandatory ? theme.palette.mandatoryText.main : theme.palette.labelDefault.main}
      >
        {label}
        {mandatory ? (
          <Box component={'span'} color={theme.palette.warning.main} marginLeft={'2px'}>
            {' *'}
          </Box>
        ) : (
          <></>
        )}
      </Box>
      {info && <QCInfoBubble text={info} />}
    </Stack>
  );
};

export default QCInputHeader;
