import { Box, useTheme } from '@mui/material';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import AddIcon from '@mui/icons-material/Add';
import { CircleIcon } from '../icons/Cercle';
import AddOUDialog from '../Forms/addOU';
import AddUserForm from '../AddUserDialogForm';
import { Content, MainBox } from './style';
import useData from './hooks';
import QCButtonGlobal from '70.quickConnect.Components/siteAdmin/Buttons/QCButtonGlobal';
import { OrganizationIcon } from '00.app/icons/OrganizationIcon';
import MenuWrapper from '10.quickConnect.app/siteAdmin/components/shared/MenuWrapper';
import useUsersData from '10.quickConnect.app/siteAdmin/components/domains/Users/hooks';
import useEffectOnce from '80.quickConnect.core/shared/hooks/useEffectOnce';
import DeleteConfirmationDialog from '70.quickConnect.Components/siteAdmin/Other/DeleteConfirmationDialog';
import { AllDataElementType } from '70.quickConnect.Components/siteAdmin/Other/QCDialogForm/types';

const NoOrganizationalUnitFocusedContent = () => {
  const { t } = useTranslation();

  const { isAddUserDialogOpen, handleAddUser, handleConfirmAddUser, setIsAddUserDialogOpen } = useData();

  const {
    initOrganizationalUnitList,
    handleConfirmaddOUDialog,
    isAddOUDialogOpen,
    setIsAddOUDialogOpen,
    openDeleteDialog,
    deleteDispatch,
  } = useUsersData();

  useEffectOnce(() => {
    initOrganizationalUnitList();
  });

  const handleConfirm = useCallback(
    async (value: AllDataElementType[]) => {
      await handleConfirmaddOUDialog(value);
      return true;
    },
    [handleConfirmaddOUDialog],
  );
  return (
    <MenuWrapper>
      <DeleteConfirmationDialog dispatch={deleteDispatch} open={openDeleteDialog} />
      <Box sx={MainBox}>
        <Box sx={Content}>
          {t('users.home').replace(/,/, ',\n')}
          <QCButtonGlobal
            className="OU-creation"
            value={t('organization.organizations.actions.add')}
            startIcon={<OrganizationIcon />}
            endIcon={<AddIcon />}
            sx={{ width: '350px' }}
            action={() => setIsAddOUDialogOpen(true)}
          />
          <QCButtonGlobal
            className="user-creation"
            value={t('users.users.form.actions.addUser')}
            startIcon={<AccountCircleOutlinedIcon />}
            endIcon={<AddIcon />}
            variant="outlined"
            sx={{ width: '350px', background: useTheme().palette.background.default }}
            action={handleAddUser}
          />
          <AddUserForm
            open={isAddUserDialogOpen}
            handleConfirm={(value) => handleConfirmAddUser(value)}
            toggleIsOpen={setIsAddUserDialogOpen}
          />
        </Box>
        <CircleIcon />
      </Box>
      <AddOUDialog open={isAddOUDialogOpen} handleConfirm={handleConfirm} toggleIsOpen={setIsAddOUDialogOpen} />
    </MenuWrapper>
  );
};

export default observer(NoOrganizationalUnitFocusedContent);
