import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { useStore } from '30.quickConnect.Store/siteAdmin';
import QCHttpRequest from '40.quickConnect.DataAcces/siteAdmin/QCHttpRequest';
import ApiUser from '40.quickConnect.DataAcces/siteAdmin/apiRoutes/users';

const useServices = () => {
  const {
    LoginStore: { signInInfos },
  } = useStore();

  const getAuthenticationMethode = (method: number) => {
    return method === 0 ? 'Quick Connect' : 'Azure AD';
  };

  const changePasswordRequest = useCallback(
    async (currentPassword: string, newPassword: string) => {
      const requestHandler = new QCHttpRequest();
      const request = {
        userUPN: signInInfos.userUPN,
        currentPassword: currentPassword,
        newPassword: newPassword,
      };
      try {
        const result = await requestHandler.post(ApiUser.changeUserPwd, request, { withCredentials: true });
        if (result) return result.status;
      } catch (error) {
        const err = error as AxiosError;
        return err.response?.status;
      }
    },
    [signInInfos.userUPN],
  );

  const changePassword = useCallback(
    async (currentPassword: string, newPassword: string) => {
      let ret = 0;
      const post = async () => {
        const result = await changePasswordRequest(currentPassword, newPassword);
        ret = result ?? 0;
      };

      await post();
      return ret;
    },
    [changePasswordRequest],
  );

  return { signInInfos, getAuthenticationMethode, changePassword };
};

export default useServices;
