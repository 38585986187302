import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { GetDataListRequest, GetDataListResponse, GetDataListResponseForAdmin } from './types';
import QCHttpRequest from '40.quickConnect.DataAcces/siteAdmin/QCHttpRequest';
import { API_GET_INCIDENTS_LIST } from '40.quickConnect.DataAcces/siteAdmin/apiRoutes/header';
import { useStore } from '30.quickConnect.Store/siteAdmin';

const useServices = () => {
  const { t } = useTranslation();

  const {
    LoginStore: { signInInfos },
  } = useStore();

  const getAlertsListRequest = useCallback(
    async (start: number, length: number, mineOrOrga: 'my' | 'organization') => {
      const ResquestHandler = new QCHttpRequest();
      const requestBody = {
        count: 5,
        start: start,
        length: length,
        orderBy: [
          {
            direction: 1,
            column: 'editedAt',
          },
        ],
        filters:
          mineOrOrga === 'my'
            ? [
                {
                  columnName: 'userUPN',
                  filters: [signInInfos.userUPN],
                },
              ]
            : [
                {
                  columnName: 'organizationalUnitStringExtract',
                  filters: [signInInfos.organizationalUnitName],
                },
              ],
        textFilter: '',
        indicatorId: '8819AAAD-5195-4C94-8449-661A74FAA085',
      };
      const result = await ResquestHandler.post<GetDataListResponse, GetDataListRequest>(
        API_GET_INCIDENTS_LIST,
        requestBody,
        { withCredentials: true },
      )
        .then(async (response) => {
          if (response.status === 200) {
            const { count, errorCode, indicatorsResult, isThereMoreItems, researchToken, status, totalCount } =
              response.data;
            return {
              count,
              errorCode,
              indicatorsResult,
              isThereMoreItems,
              researchToken,
              status,
              totalCount,
              mineOrOrga,
            } as GetDataListResponseForAdmin;
          }
        })
        .catch(async (error) => {
          toast.error(`${t('shared.message.notifyError.badRequest', { errorMessage: error.response.data.message })}`);
        });
      return result;
    },
    [t, signInInfos.organizationalUnitName, signInInfos.userUPN],
  );

  const getAlertList = useCallback(
    async (start: number, length: number, mineOrOrga: 'my' | 'organization') => {
      let alertsList = {} as GetDataListResponseForAdmin;
      alertsList = (await getAlertsListRequest(start, length, mineOrOrga)) ?? ({} as GetDataListResponseForAdmin);
      return alertsList;
    },
    [getAlertsListRequest],
  );

  return {
    getAlertList,
  };
};

export default useServices;
