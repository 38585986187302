import { useContext, useEffect, useState } from 'react';
import { QCDialogFormContext } from '../../context';
import { BodyContext } from '../../StepBody/context';

const useData = (label: string) => {
  const { setFilled, index } = useContext(BodyContext);
  const { allData, updateAllDataArray, addFieldAllDataArray } = useContext(QCDialogFormContext);

  const [isActive, SetIsActive] = useState<boolean>(true);

  useEffect(() => {
    addFieldAllDataArray(label, isActive, index);
  }, [isActive, addFieldAllDataArray, label, index]);

  useEffect(() => {
    if (allData[index - 1] && allData[index - 1].data) {
      const inputData = allData[index - 1].data;
      const valueIndex = inputData.findIndex((el) => el.label === label);
      if (valueIndex !== -1) {
        const { value } = inputData[valueIndex];
        if (typeof value == 'boolean') {
          SetIsActive(value);
        }
      }
    }
  }, [allData, index, label]);

  const handleChange = (value: boolean) => {
    setFilled(label, true);
    updateAllDataArray(label, value, index);
    SetIsActive(value);
  };
  return { isActive, handleChange };
};

export default useData;
