import { t } from 'i18next';
import { DocMultiplesActions, DocRowActions } from './const';
import { ChoiceMenuOptionType } from '70.quickConnect.Components/siteAdmin/Inputs/QCChoiceMenu/type';

export const actionsToChoiceMenuOption = (actions: DocRowActions[] | DocMultiplesActions[]): ChoiceMenuOptionType[] => {
  return actions.map((action) => ({ text: t(action), value: action }));
};

export const transformFileSize = (size: number) => {
  if (Math.floor(size / Math.pow(10, 9)) > 0) {
    return (size / Math.pow(10, 9)).toFixed(2) + '(go)';
  } else if (Math.floor(size / Math.pow(10, 6)) > 0) {
    return (size / Math.pow(10, 6)).toFixed(2) + '(mo)';
  } else if (Math.floor(size / Math.pow(10, 3)) > 0) {
    return (size / Math.pow(10, 3)).toFixed(2) + '(ko)';
  } else {
    return size.toString();
  }
};
