import { useCallback, useMemo, useState } from 'react';
import { useRessourcesServices } from './services/services';
import { SupportedLanguage } from '90.quickConnect.Models/siteAdmin/models/internationalization/supportedLanguage';
import useEffectOnce from '80.quickConnect.core/shared/hooks/useEffectOnce';
import { capitalize } from '80.quickConnect.core/shared/helpers/capitalize';

const useSupportedLanguages = (defaultLanguage?: string) => {
  const { getSupportedLanguages } = useRessourcesServices();

  const [supportedLanguages, setSupportedLanguages] = useState<Array<SupportedLanguage>>([]);

  const languageValue = useMemo(
    () =>
      defaultLanguage
        ? capitalize(supportedLanguages.find((el) => el.value === defaultLanguage)?.lang ?? '')
        : supportedLanguages[0]
        ? capitalize(supportedLanguages[0].lang)
        : '',
    [defaultLanguage, supportedLanguages],
  );

  const initSuppLanguages = useCallback(async () => {
    const languages = await getSupportedLanguages();
    if (languages) {
      setSupportedLanguages(languages);
    }
  }, [getSupportedLanguages, setSupportedLanguages]);

  useEffectOnce(() => {
    initSuppLanguages();
  });

  return { supportedLanguages, languageValue };
};

export default useSupportedLanguages;
