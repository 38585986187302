import React, { cloneElement, MouseEventHandler, useContext, useEffect, useState } from 'react';

import { Box, Checkbox, useTheme } from '@mui/material';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import { SubMenuContext } from '../const';
import { IconAndNameStyle, LabelContainerStyle, ListItemContainerStyle, ListItemNameStyle } from '../ListItemStyles';
import { SubMenuListItemProps } from './types';
import QCKebabMenu from '70.quickConnect.Components/siteAdmin/Other/QCKebabMenu';
import QCIconButton from '70.quickConnect.Components/siteAdmin/Buttons/QCIconButton';
import { useStore } from '30.quickConnect.Store/siteAdmin';
import { useSafeNavigation } from '80.quickConnect.core/siteAdmin/hooks/useSafeNavigation';

/**
 * Composant pour le list item du sous-menu
 * Utilise le contexte du sous-menu.
 * @component
 * @prop {string} id
 * @prop {string} name
 * @prop {ReactElement<IconProps>} startIcon
 * @prop {number} childrenCount - N'est affiché que si supérieur à zéro
 * @prop {boolean} isChecked
 * @prop {string} type - 'item' ou 'form'. Si 'form' un bouton éditer apparaît lors du hover
 */
const SubMenuListItem = ({
  id,
  name,
  startIcon,
  childrenCount,
  isChecked = false,
  type = 'item',
  childrenItems,
}: SubMenuListItemProps) => {
  const { rowActions, setSelectedRowAction, onRowClicked, showCheckbox, handleRowCheckboxClick } =
    useContext(SubMenuContext);

  const {
    SubMenuStore: { setRows, pushSubMenuPath, setCurrentSubMenuRow, routeBase },
  } = useStore();
  const navigate = useSafeNavigation();

  const theme = useTheme();

  const [selectedOption, setSelectOption] = useState('');
  const [isKebabMenuOpen, setIsKebabMenuOpen] = useState(false);

  const handleRowClick: MouseEventHandler = () => {
    if (onRowClicked) onRowClicked(id);
    if (type === 'family' && childrenItems) {
      setRows(childrenItems);
      const currentRow = {
        id: id,
        name: name ?? '',
        rows: childrenItems,
        childrenCount: childrenItems.length,
        type: 'family' as const,
        startIcon: <></>,
        row: {
          id: id,
          name: name ?? '',
          startIcon: startIcon ?? <></>,
          childrenCount: childrenCount ?? 0,
          type: 'family' as const,
          childrenItems: childrenItems,
        },
      };
      pushSubMenuPath(currentRow);
      setCurrentSubMenuRow(currentRow);
    }
    navigate(`${routeBase}/${id}`);
  };

  const handleEditClick = () => {
    setSelectedRowAction({ option: 'edit-form', rowId: id });
  };

  useEffect(() => {
    if (rowActions?.map((mappedAction) => mappedAction.text).includes(selectedOption)) {
      setSelectedRowAction({ option: selectedOption, rowId: id });
      setIsKebabMenuOpen(false);
    }
    //eslint-disable-next-line
  }, [setSelectedRowAction, selectedOption, id]);

  return (
    <Box sx={ListItemContainerStyle(theme, isKebabMenuOpen)}>
      <Box sx={LabelContainerStyle} onClick={showCheckbox ? () => {} : handleRowClick} data-cy={name}>
        <label>
          <Box sx={IconAndNameStyle}>
            {showCheckbox && (
              <Checkbox checked={isChecked} onClick={() => handleRowCheckboxClick(id)} sx={{ marginLeft: '-10px' }} />
            )}
            {startIcon && cloneElement(startIcon, { fontSize: 'small' })}
            <Box sx={ListItemNameStyle}>{name}</Box>
            {childrenCount !== 0 && (
              <Box sx={{ color: theme.palette.primary.main, fontSize: '0.925em' }}>{childrenCount}</Box>
            )}
          </Box>
        </label>
      </Box>

      {type === 'form' && (
        <QCIconButton
          Icon={<EditOutlinedIcon fontSize="small" />}
          className="edit-button"
          variantValue="contained"
          onClickFunc={handleEditClick}
          sx={{
            padding: 0,
          }}
        />
      )}

      {rowActions && (
        <Box className="kebabMenu">
          <QCKebabMenu
            size="small"
            options={rowActions}
            dispatch={setSelectOption}
            setIsMenuOpen={setIsKebabMenuOpen}
          />
        </Box>
      )}
    </Box>
  );
};

export default SubMenuListItem;
