import { t } from 'i18next';
import { ChoiceMenuOptionType } from '70.quickConnect.Components/siteAdmin/Inputs/QCChoiceMenu/type';

export enum ResMultipleActions {
  Delete = 'submenu.multipleAction.delete',
  Print = 'submenu.multipleAction.print',
}

export enum ResourceRowActions {
  Delete = 'submenu.multipleAction.delete',
  Edit = 'submenu.multipleAction.modify',
}

export const actionsToChoiceMenuOption = (
  actions: ResMultipleActions[] | ResourceRowActions[],
): ChoiceMenuOptionType[] => {
  return actions.map((action) => ({ text: t(action), value: action }));
};
