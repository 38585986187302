import React, { ChangeEventHandler, KeyboardEventHandler, useContext, useEffect, useState } from 'react';
import { Box, useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import SharedListSettingsContext from '../../SharedListSettingsContext';
import { DataChipStyle, DataKeyStyle, DataValueStyle } from './styles';
import { SharedListDataChipProps } from './types';
import { SharedListDataType } from '90.quickConnect.Models/siteAdmin/enums/sharedList/sharedListType';
import {
  parseCsvSharedListData,
  parseStringToCsvSharedListData,
} from '80.quickConnect.core/siteAdmin/helpers/sharedListData';

const SharedListDataChip = ({ keyName, value, type, elementLabel, index }: SharedListDataChipProps) => {
  const theme = useTheme();

  const { sharedListElements, setSharedListElements, dataType } = useContext(SharedListSettingsContext);

  const [editInput, setEditInput] = useState(false);

  const [newValue, setNewValue] = useState<string>(`${value}`);

  const handleValueClick = () => {
    setEditInput(true);
  };

  const handleInputConfirm: KeyboardEventHandler = (event) => {
    if (dataType === undefined) {
      throw new Error('DataType of SharedList should not be undefined');
    }
    if (event.key === 'Enter') {
      setEditInput(false);
      const elToChangeIndex = sharedListElements.findIndex((el) => el.label === elementLabel);
      const newElements = sharedListElements;
      const dataToChange = newElements[elToChangeIndex].data;
      // si la data n'existe pas on peut pas la modifier
      if (!dataToChange) {
        return;
      }

      switch (dataType) {
        case SharedListDataType.Csv:
          if (typeof dataToChange !== 'string') throw new Error('SharedListDataType should be string');
          const values = parseCsvSharedListData(dataToChange);
          if (!index) return;
          values[index] = newValue;
          const dataString = parseStringToCsvSharedListData(values);
          newElements[elToChangeIndex].data = dataString;
          setSharedListElements(newElements);
          break;
        case SharedListDataType.Json:
          // TODO
          break;
        default:
          const exhaustiveCheck: never = dataType;
          throw new Error(`Unhandled Shared List Data Type: ${exhaustiveCheck}`);
      }
      // dataToChange.
      // newElements[elToChangeIndex]
    }
  };

  const handleValueChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setNewValue(event.target.value);
  };

  // applique l'animation de scroll si la value est plus grande que le chip
  useEffect(() => {
    document.querySelectorAll('.shared-list-data-chip-container').forEach((el) => {
      const htmlEl = el as HTMLElement;
      const valueEl = htmlEl.querySelector('.shared-list-data-chip-value') as HTMLElement;
      if (valueEl === null) return;
      if (valueEl.offsetWidth > htmlEl.offsetWidth) {
        valueEl.classList.add('scroll-animation');
      }
      //mettre l'input à fit-content ne marche pas :(
      // const valueInput = valueEl.querySelector('.input-edit-shared-list-data-value') as HTMLElement;
      // if (valueInput === null || valueInput.style === null) return;
      // valueInput.style.width = valueInput.offsetWidth + 'px';
    });
  }, []);

  return (
    <Box sx={DataChipStyle(theme, editInput)} className="shared-list-data-chip-container">
      {type === SharedListDataType.Json ? (
        <Box component="span" sx={DataKeyStyle(theme)}>
          {keyName} :
        </Box>
      ) : (
        <></>
      )}
      <Box
        component="span"
        sx={DataValueStyle(theme, type === SharedListDataType.Json)}
        onClick={handleValueClick}
        className="shared-list-data-chip-value"
      >
        {editInput ? (
          <input
            className="input-edit-shared-list-data-value"
            type="text"
            value={newValue}
            onChange={handleValueChange}
            onKeyUp={handleInputConfirm}
          />
        ) : (
          newValue
        )}
      </Box>
    </Box>
  );
};

export default observer(SharedListDataChip);
