export enum FieldNames {
  DocName = 'name',
  DocSize = 'size',
  Status = 'state',
}

export enum DocRowActions {
  Delete = 'core.mock.screenActionUI.downloadManager.delete',
  View = 'core.mock.screenActionUI.downloadManager.view',
  Send = 'core.mock.screenActionUI.downloadManager.send',
}

export enum DocMultiplesActions {
  Delete = 'core.mock.screenActionUI.downloadManager.delete',
  Send = 'core.mock.screenActionUI.downloadManager.send',
}

export const APP_NAME_STORAGE = 'QuickConnectBackOffice';

export const DOWNLOAD_MANAGER_MAXIMUN_PROGRESS_VALUE = 100;
