import { SxProps } from '@mui/system';
import { grey3 } from '00.app/theme/colors';

export const descriptionFirstPartStyle: SxProps = {
  '.description-first': {
    fontWeight: '400',
    fontStyle: 'italic',
    textTransform: 'capitalize',
  },

  '.description-second': {
    color: grey3,
    fontStyle: 'italic',
    // textTransform: 'capitalize',
  },
};

export const titleStyle: SxProps = {
  fontWeight: 'bold',
  textTransform: 'uppercase',
  wordBreak: 'break-word',
};
