import React from 'react';
import { Box, Stack } from '@mui/material';
import MenuWrapper from '../../shared/MenuWrapper';
import { useHomeData } from './hook';
import HomeWidget from '70.quickConnect.Components/siteAdmin/Other/HomeWidget';

const Home = () => {
  const { organizationName, formPreview, declarationPreview, jobsPreview, alertsPreview, defaultsHomeWidgetTypes } =
    useHomeData();
  return (
    <MenuWrapper>
      <Stack sx={{ display: 'flex', alignItems: 'flex-start', position: 'relative', gap: 3, marginLeft: '2vw' }}>
        <Box sx={{ display: 'flex', justifyContent: 'flex-start', width: '1125px' }}>
          <h1>{organizationName}</h1>
        </Box>

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-start',
            alignItems: 'center',
            height: '100%',
            gap: 4,
            flexWrap: 'wrap',
          }}
        >
          <HomeWidget
            defaultType={defaultsHomeWidgetTypes[0]}
            widgetIndex={0}
            data={{
              formsPreview: formPreview,
              declarationsPreview: declarationPreview,
              jobsPreview: jobsPreview,
              alertsPreview: alertsPreview,
            }}
          />
          <HomeWidget
            defaultType={defaultsHomeWidgetTypes[1]}
            widgetIndex={1}
            data={{
              formsPreview: formPreview,
              declarationsPreview: declarationPreview,
              jobsPreview: jobsPreview,
              alertsPreview: alertsPreview,
            }}
          />
        </Box>
      </Stack>
    </MenuWrapper>
  );
};

export default Home;
