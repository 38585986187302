export const Features = {
  Workflows: 'FEAT-001',
  DocTemplates: 'FEAT-002',
  RFID: 'FEAT-003',
  Inbox: 'FEAT-004',
  ModeOffline: 'FEAT-005',
  Catalogs: 'FEAT-006',
  Bin: 'FEAT-007',
} as const;

export type FeaturesType = typeof Features[keyof typeof Features];
