import { useCallback, useMemo } from 'react';
import { useTraductionContext } from '../context';

const useData = (isSourceLang?: boolean) => {
  const { sourceLang, destLang } = useTraductionContext();

  const currentLang = useMemo(
    () => (isSourceLang ? sourceLang.value : destLang.value),
    [isSourceLang, sourceLang, destLang],
  );

  const getFieldValue = useCallback(
    (
      values: {
        lang: string;
        value: string;
      }[],
    ) => {
      console.log('clang', currentLang);
      return values.find((vl) => vl.lang === currentLang)?.value ?? '';
    },
    [currentLang],
  );

  return {
    currentLang,
    getFieldValue,
  };
};

export default useData;
