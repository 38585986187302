import React, { Dispatch, SetStateAction, useCallback, useEffect, useMemo } from 'react';
import { Box, Stack, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';
import { AttributeListId } from '../const';
import { useFamilySettingsStore } from '../FamilySettingsStore';
import FamilyAttributeItem from './FamilyAttributeItem';
import { AttributeListStyle, AttributesCardStyle } from './styles';
import { FamilyAttributeItemProps } from './FamilyAttributeItem/types';
import QCIconButton from '70.quickConnect.Components/siteAdmin/Buttons/QCIconButton';
import { EntitySchemaAttribute } from '90.quickConnect.Models/siteAdmin/models/families/entitySchemaAttribute';
import DraggableList from '70.quickConnect.Components/siteAdmin/Other/DraggableList';
import usePrevious from '80.quickConnect.core/shared/hooks/usePrevious';

const FamilyAttributesCard = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { attributes, addAttribute, changeAttribute, reorderAttributes } = useFamilySettingsStore();

  const newAttribute: EntitySchemaAttribute = useMemo(
    () => ({
      id: uuidv4(),
      columnName: '',
      createdAt: new Date(),
      customerId: '',
      entityId: '',
      eTag: uuidv4(),
      isRequired: false,
      propertyName: '',
      value: '',
      isSelected: false,
      isSelectable: true,
      isSearchable: false,
      description: '',
      listOrder: attributes.length,
      name: '',
      sharedListId: undefined,
      type: 1,
    }),
    [attributes.length],
  );

  //scroll à la fin quand un attribut est ajouté
  const previousAttributesLength = usePrevious(attributes.length);
  useEffect(() => {
    if (attributes.length > previousAttributesLength && previousAttributesLength !== 0) {
      setTimeout(() => document.getElementById(AttributeListId)?.scroll({ top: 9999999, behavior: 'smooth' }));
    }
    //eslint-disable-next-line
  }, [attributes.length]);

  // console.log(attributes);
  const renderAttributes = useCallback(() => {
    return (
      <Box sx={AttributeListStyle} id={AttributeListId}>
        <DraggableList<FamilyAttributeItemProps>
          renderComponent={(item) => <FamilyAttributeItem {...item.props}></FamilyAttributeItem>}
          componentsProps={attributes
            .slice()
            .sort((a, b) => a.listOrder - b.listOrder)
            .map((att) => {
              return {
                attributeId: att.id,
                dispatchChangedAttribute: changeAttribute,
              };
            })}
          dispatchProps={
            ((newProps: FamilyAttributeItemProps[]) => {
              reorderAttributes(newProps.map((el) => el.attributeId));
            }) as Dispatch<SetStateAction<FamilyAttributeItemProps[]>>
          }
        />
      </Box>
    );
    //eslint-disable-next-line
  }, [attributes, attributes.length]);

  return (
    <Box sx={AttributesCardStyle(theme)}>
      <Stack
        sx={{ flexDirection: 'row', alignContent: 'center', justifyContent: 'space-between', '>h2': { marginY: 0 } }}
      >
        <h2>{t('entitySchema.entityDesigner.entitySchema.attributes.title')}</h2>
        <QCIconButton
          Icon={<AddIcon />}
          onClickFunc={() => addAttribute(newAttribute as EntitySchemaAttribute)}
          variantValue="outlined"
          sx={{ color: theme.palette.primary.main, backgroundColor: theme.palette.background.default }}
        />
      </Stack>

      {renderAttributes()}
    </Box>
  );
};

export default observer(FamilyAttributesCard);
