import API_HOST from './apiHost';

const ApiResource = {
  getAllResources: `${API_HOST}entities/datas/getalldatas`,
  deleteResource: `${API_HOST}entities/datas/deletedata`,
  designResource: `${API_HOST}entities/datas/designentityData`,
  createResource: `${API_HOST}entities/datas/createdata`,
  updateResource: `${API_HOST}entities/datas/updatedata`,
  indexFiles: `${API_HOST}entities/datas/IndexFiles`,
  deleteDocuments: `${API_HOST}entities/datas/deletedocument`,
  storageDocument: `${API_HOST}storage/document/`,
  printResource: `${API_HOST}entities/datas/printentitydatas`,
};

export default ApiResource;
