import { FormPublishState } from '90.quickConnect.Models/siteAdmin/enums/forms/formPublicState';
import { FormType } from '90.quickConnect.Models/siteAdmin/enums/forms/formType';
import { JobStepUserResultStateEnum } from '90.quickConnect.Models/siteAdmin/enums/jobs/jobStepUserResultStateEnum';
import { WithId } from '90.quickConnect.Models/siteAdmin/models/common/withId';

export type FormPreview = WithId & {
  name: string;
  formType: FormType;
  published: FormPublishState;
  lastModificationDate: string;
};

export type DeclarationPreview = WithId & {
  userUpn: string; // auteur
  stringExtract: string; // titre déclaration
  editedAt: string;
  formType: FormType;
};

export type JobPreview = WithId & {
  title: string;
  contentType: string;
  state: JobStepUserResultStateEnum;
  expirationDate: string;
};

export type AlertPreview = WithId & {
  userUPN: string;
  stringExtract: string;
  formType: FormType;
  editedAt: string;
  organizationalUnitStringExtract: string;
};

export const isAlertPreview = (alert: unknown): alert is AlertPreview => {
  if (
    typeof alert === 'object' &&
    alert !== null &&
    !Array.isArray(alert) &&
    'userUPN' in alert &&
    'stringExtract' in alert &&
    'formType' in alert &&
    'editedAt' in alert &&
    'organizationalUnitStringExtract' in alert
  )
    return true;

  return false;
};

export const isAlertPreviewArray = (alerts: unknown[]): alerts is AlertPreview[] => {
  let isType = true;
  alerts.forEach((alert) => {
    if (!isAlertPreview(alert)) isType = false;
  });

  return isType;
};
