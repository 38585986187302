import React from 'react';
import { Box, IconButton, ListItem, ListItemButton, ListItemText, useTheme } from '@mui/material';
import { QCListItemProps } from './types';
import QCIconClear from './QCIconClear';
import { listItemSecondaryActionBoxStyles, listItemStyles, primatyTextStyles, secondaryTextStyles } from './styles';

/**
 * Composant pour présenter l'élément de la liste dans HeaderDropDown.
 * @component
 * @param {React.ReactNode} renderEndInfos - Le composant  à la fin.
 * @param {string} titleText - Le titre.
 * @param {string} [descriptionText] - La description.
 * @param {React.Dispatch<unknown>} [actionClick] - L'action déclenchée quand on clique l'élément de la liste.
 * @param {React.Dispatch<unknown>} [actionClear] - L'action déclenchée quand on clique l'icône croix.
 * @param {boolean} [isAlert] - La valeur booléenne indiquant si l'élément est de type "alerte".
 * Il y a deux types d'éléments: "alerte" et "incident".
 * @param {boolean} [isImports] - La valeur booléenne indiquant si l'élément est de type "imports".
 * Il y a deux types d'éléments: "imports" et "exports".
 * @param {boolean} [isDraft] - La valeur booléenne indiquant si l'élément est en brouillon.
 * @example <caption>Exemple QCListItem - Alerte</caption>
 * const alertChip = <Chip color='backgroundColorAlerteChip' label="Alerte" size='small' />
 * const incidentChip = <Chip color='backgroundColorIncidentChip' label="Incident" size='small' />
 * <QCListItem
 *  isAlert
 *  titleText='panne grue colas'
 *  descriptionText="Aujourd'hui à 16h01 / Pour Haj Kacem"
 *  renderEndInfos={alertChip}
 * />
 * @example <caption>Exemple QCListItem - Activités</caption>
 * <QCListItem
 *  isDraft
 *  titleText='liste des candidats'
 *  descriptionText="ressources/candidats"
 *  renderEndInfos={
 *      <Box sx={{display: 'flex', alignItems: 'stretch', flexDirection: 'column'}}>
 *          <Typography align='right' sx={{color: 'text.primary', fontStyle: 'italic', fontWeight: 400}}>Aujourd&apos;hui</Typography>
 *          <Typography align='right' sx={{color: 'text.primary', fontStyle: 'italic', fontWeight: 400}}>16H30</Typography>
 *      </Box>
 *  }
 * />
 * @example <caption>Exemple QCListItem - Imports Exports</caption>
 * const finishedImports = <DownloadOutlinedIcon color='finishedImportsIconColor' />
 * const finishedExports = <UploadOutlinedIcon color='finishedExportsIconColor'/>
 * const unfinishedExports =
 *      <Box sx={{display: 'flex', alignItems: 'center', }}>
 *          <QCGradientCircularProgress />
 *          <UploadOutlinedIcon color='noFinishedIconColor' />
 *      </Box>
 *
 * <QCListItem
 *  titleText='liste des candidats'
 *  descriptionText="ressources/candidats"
 *  renderEndInfos={unfinishedExports}
 * />
 */
const QCListItem = ({
  renderEndInfos,
  titleText,
  descriptionText,
  actionClick,
  actionClear,
  isAlert,
  isImports,
  isDraft,
}: QCListItemProps) => {
  const [isHover, setIsHover] = React.useState<boolean>(false);
  const theme = useTheme();

  const handleMouseEnter = () => {
    setIsHover(true);
  };

  const handleMouseLeave = () => {
    setIsHover(false);
  };

  return (
    <ListItem
      secondaryAction={
        <Box sx={listItemSecondaryActionBoxStyles}>
          {renderEndInfos}
          {isHover ? <QCIconClear action={actionClear} /> : <IconButton sx={{ width: '20px' }}></IconButton>}
        </Box>
      }
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      disablePadding
      dense
      sx={listItemStyles}
    >
      <ListItemButton onClick={actionClick}>
        <ListItemText
          primary={titleText}
          secondary={descriptionText}
          primaryTypographyProps={{
            sx: primatyTextStyles(isAlert || isImports, theme),
          }}
          secondaryTypographyProps={{
            sx: secondaryTextStyles(isHover, isDraft, theme),
          }}
        />
      </ListItemButton>
    </ListItem>
  );
};

export default QCListItem;
