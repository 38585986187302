import React from 'react';
import useData from './hooks';
import QCChecboxButtonField from '70.quickConnect.Components/siteAdmin/Inputs/QCChecboxButton';

const QCDialogFormCheckButton = ({ label }: { label: string }) => {
  const { isActive, handleChange } = useData(label);
  return (
    <QCChecboxButtonField
      isCheck={isActive}
      label={label}
      dispatch={(value: boolean) => {
        handleChange(value);
      }}
    />
  );
};

export default QCDialogFormCheckButton;
