import { Box, useTheme } from '@mui/material';
import React from 'react';
import LightModeIcon from '@mui/icons-material/LightMode';
import NightlightOutlinedIcon from '@mui/icons-material/NightlightOutlined';
import { AccountHeaderProps } from './type';
import { AccountHeaderStyleProps } from './style';
import QCButtonGlobal from '70.quickConnect.Components/siteAdmin/Buttons/QCButtonGlobal';
import { useStore } from '30.quickConnect.Store/siteAdmin';
import ContentHeader from '70.quickConnect.Components/siteAdmin/Other/ContentHeader';

const AccountHeader = ({ t }: AccountHeaderProps) => {
  const {
    palette: { mode: muiMode },
  } = useTheme();

  const {
    UserSettingsStore: { switchMuiMode },
  } = useStore();

  return (
    <Box className="header-main" marginBottom={'25px'}>
      <Box className="header-title" sx={AccountHeaderStyleProps}>
        <ContentHeader>
          <ContentHeader.Title>{t('navigation.menu.myAccount')}</ContentHeader.Title>
          <ContentHeader.Controls>
            <QCButtonGlobal
              className="switch-mui-mode-button"
              startIcon={muiMode === 'light' ? <LightModeIcon /> : <NightlightOutlinedIcon />}
              action={switchMuiMode}
              value={t(muiMode === 'light' ? 'account.theme.light' : 'account.theme.dark')}
            />
          </ContentHeader.Controls>
        </ContentHeader>
      </Box>
      <Box sx={{ whiteSpace: 'pre-line' }}>{t('account.header.content')}</Box>
    </Box>
  );
};

export default AccountHeader;
