import React, { useContext } from 'react';
import { IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { Box } from '@mui/system';
import { dialogFormHeaderStyle } from '../style';
import { QCDialogFormContext } from '../context';
import { StepHeaderProps } from './type';

const QCStepHeader = ({ title, desc }: StepHeaderProps) => {
  const { toogleIsOpen, setActiveStep, resetAllDataArray } = useContext(QCDialogFormContext);
  return (
    <Box className="dialog-form-header" sx={dialogFormHeaderStyle}>
      <Box className="title-field" sx={{ textAlign: 'center', alignSelf: 'center', gridArea: '1 / 2 / 2 / 3' }}>
        {title !== undefined ? (
          <Box className="title" component={'strong'}>
            {title}
          </Box>
        ) : (
          <></>
        )}
        <Box className="desc">{desc}</Box>
      </Box>
      <IconButton
        className="cross-field"
        sx={{ gridArea: '1 / 3 / 2 / 4' }}
        onClick={() => {
          resetAllDataArray();
          setActiveStep(0);
          toogleIsOpen(false);
        }}
      >
        <CloseIcon />
      </IconButton>
    </Box>
  );
};

export default QCStepHeader;
