import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Dialog, Button } from '@mui/material';
import {} from '@mui/system';
import { SaveActionType, SaveConfirmationProps } from './type';

const SaveConfirmationDialog = ({ open, dispatch }: SaveConfirmationProps) => {
  const { t } = useTranslation();

  const elementWidth = 'fit-parent';
  // const [isOpen, setIsOpen] = useState(open);

  const handleClickSave = () => {
    dispatch({ type: SaveActionType.Save });
    // setIsOpen(false);
  };

  const handleClickCancel = () => {
    dispatch({ type: SaveActionType.Cancel });
    // setIsOpen(false);
  };
  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          width: '387px',
          height: '286px',

          backgroundColor: 'background',

          border: '2px solid #E3F2F0',
          borderRadius: '16px',
        },
      }}
    >
      <Box
        sx={{
          // width: elementWidth,
          // maxWidth: elementWidth,
          textAlign: 'center',
          marginTop: '2vh',
          marginBottom: '2vh',
          marginX: '4vh',
          //   textJustify: 'inter-word',
        }}
      >
        <h2>{t('uicomponents.saveConfirmationDialog.Text')}</h2>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            gap: 2,
          }}
        >
          <Button
            onClick={handleClickSave}
            variant="contained"
            sx={{
              fontWeight: 'bold',
              width: elementWidth,
              minWidth: 'fit-content',
            }}
          >
            {t('uicomponents.saveConfirmationDialog.Save')}
          </Button>

          <Button
            onClick={handleClickCancel}
            variant="outlined"
            sx={{
              width: elementWidth,
              minWidth: 'fit-content',
            }}
          >
            {t('uicomponents.saveConfirmationDialog.Cancel')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default SaveConfirmationDialog;
