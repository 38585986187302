import { Box } from '@mui/material';
import React from 'react';
import useData from './hooks';
import QCInputHeader from '70.quickConnect.Components/siteAdmin/Inputs/QCInputHeader';
import QCFileInput from '70.quickConnect.Components/siteAdmin/Inputs/QCFileInput';

const QCDialogFormDocument = ({
  label,
  info,
  mandatory = false,
}: {
  label: string;
  info?: string;
  mandatory?: boolean;
}) => {
  const { files, handleChange } = useData(label, mandatory ?? false);
  return (
    <Box height={'80%'}>
      {label && <QCInputHeader label={label} info={info} mandatory={mandatory} />}
      <QCFileInput files={files} setFiles={(value) => handleChange(value)} />
    </Box>
  );
};

export default QCDialogFormDocument;
