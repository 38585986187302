import { action, makeAutoObservable, observable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import RootStore from '..';
import { NavElementName } from '10.quickConnect.app/siteAdmin/components/shared/Menu/types';
import { CollapseOpenType } from '10.quickConnect.app/siteAdmin/components/shared/Menu/SideMenu/Collapses/types';
import CustomLogger from '80.quickConnect.core/siteAdmin/logger/customLogger';

class SideMenuStore {
  isOpenSideMenu = false;

  focusedBtn: NavElementName = 'home';

  openedCollapse: CollapseOpenType = null;

  RootStore: RootStore;

  logger: CustomLogger;

  constructor(rootStore: RootStore, logger: CustomLogger, storageKey: string) {
    this.RootStore = rootStore;
    this.logger = logger;
    makeAutoObservable(
      this,
      {
        isOpenSideMenu: observable,
        focusedBtn: observable,
        openedCollapse: observable,

        setIsOpenSideMenu: action,
        setFocusedBtn: action,
        setOpenedCollapse: action,
      },
      { autoBind: true },
    );
    void makePersistable(this, {
      name: storageKey,
      properties: ['isOpenSideMenu'],
      storage: window.sessionStorage,
    });
  }

  setIsOpenSideMenu = (isOpenSideMenu: boolean) => (this.isOpenSideMenu = isOpenSideMenu);

  setFocusedBtn = (focusedBtn: NavElementName) => (this.focusedBtn = focusedBtn);

  setOpenedCollapse = (openedCollapse: CollapseOpenType) => (this.openedCollapse = openedCollapse);
}

export default SideMenuStore;
