import React from 'react';
import { Box } from '@mui/system';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import MenuWrapper from '../../shared/MenuWrapper';
import useRoleData from './hooks';
import { PageSx } from './styles';
import AddRoleForm from './addRoleForm';
import ContentHeader from '70.quickConnect.Components/siteAdmin/Other/ContentHeader';
import DataTable from '70.quickConnect.Components/siteAdmin/Other/DataTable';
import QCSortingMenu from '70.quickConnect.Components/siteAdmin/Other/QCSortingMenu';
import QCButtonGlobal from '70.quickConnect.Components/siteAdmin/Buttons/QCButtonGlobal';
import DeleteConfirmationDialog from '70.quickConnect.Components/siteAdmin/Other/DeleteConfirmationDialog';

const Roles = () => {
  const { t } = useTranslation();
  const {
    rows,
    cols,
    setSearchText,
    isLoading,
    pageSize,
    KebabMenuCellCustom,
    deleteDispatch,
    openDeleteDialog,
    deleteDepDispatch,
    openDeleteDepDialog,
    handleConfirm,
    getSelectedSortingType,
    isAddRoleDialogOpen,
    setIsAddRoleDialogOpen,
    sortingTypes,
    multipleActions,
    setSelectedMultipleActions,
    openDeleteMultDialog,
    deleteMultDispatch,
    openDeleteDepMultDialog,
    deleteDepMultDispatch,
  } = useRoleData();

  return (
    <MenuWrapper>
      <Box sx={PageSx}>
        <ContentHeader>
          <ContentHeader.Title>{t('navigation.menu.roles')}</ContentHeader.Title>
        </ContentHeader>
        <DataTable
          rows={rows}
          columns={cols}
          getSearchValue={setSearchText}
          // onSelectRowAction={setSelectedRowAction}
          // rowActions={actionsToChoiceMenuOtion([UserRowActions.Delete, UserRowActions.Edit])}
          isLoading={isLoading}
          isSearchBar={true}
          isReadOnly={false}
          pageSize={pageSize}
          renderAfterSearchBar={
            <Box marginLeft={2} sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
              <QCSortingMenu options={sortingTypes} dispatch={getSelectedSortingType ?? (() => {})} />
            </Box>
          }
          kebabMenu={KebabMenuCellCustom}
          multipleActions={multipleActions}
          onSelectMultipleAction={setSelectedMultipleActions}
        />
        <Box
          sx={{
            position: 'absolute',
            ml: '15px',
            bottom: '3.5vh',
          }}
        >
          <QCButtonGlobal
            className="add-user"
            variant="contained"
            endIcon={<AddIcon />}
            value="Ajouter un role"
            action={() => {
              setIsAddRoleDialogOpen(true);
            }}
            datacy="add-user"
          ></QCButtonGlobal>
          <AddRoleForm open={isAddRoleDialogOpen} handleConfirm={handleConfirm} toggleIsOpen={setIsAddRoleDialogOpen} />
        </Box>
      </Box>
      <DeleteConfirmationDialog open={openDeleteDialog} dispatch={deleteDispatch} />
      <DeleteConfirmationDialog
        open={openDeleteDepDialog}
        dispatch={deleteDepDispatch}
        title="profil.designer.popup.delete.error.body"
      />
      <DeleteConfirmationDialog open={openDeleteMultDialog} dispatch={deleteMultDispatch} />
      <DeleteConfirmationDialog
        open={openDeleteDepMultDialog}
        dispatch={deleteDepMultDispatch}
        title="profil.designer.popup.delete.error.body"
      />
    </MenuWrapper>
  );
};

export default observer(Roles);
