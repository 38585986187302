import { Box, Button, styled, useTheme } from '@mui/material';
import React from 'react';
import QCInputHeader from '../QCInputHeader';
import {
  backgroundBoxStyles,
  containedButtonStyles,
  innerBoxStyles,
  outlinedButtonStyles,
  outterBoxStyles,
} from './styles';
import { QCSwitchSelectProps } from './types';

/**
 * Composant switch pour dropdown tabs ou le type de liste.
 * @component
 * @param {string} [label] - Le texte du label.
 * @param {string} text1 - Le texte affiché sur le bouton à gauche.
 * @param {string} text2 - Le texte affiché sur le bouton à droite.
 * @param {string} value1 - La valeur du bouton à gauche.
 * @param {string} value2 - La valeur du bouton à droite.
 * @param {string} option - L'état d'option.
 * @param {React.Dispatch<React.SetStateAction<string>>} dispatch - Le dispatcheur pour modifier l'état d'option actuelle.
 * @param {string} [info] - Les informations indicatives.
 * @param {string} [className] - Le nom de classification.
 * @param {"contained" | "outlined" | undefined} variant - Le variant du bouton.
 * @param {boolean} [disabled] - La valeur booléenne pour déterminer si les boutons sont désactivés.
 * @example <caption>Exemple dans DropdownTabs</caption>
 * const [ option, setOption ] = React.useState<string>('my')
 * <QCSwitchSelect2
 *  text1={'option 1'}
 *  text2={'option 2'}
 *  value1={'option1'}
 *  value2={'option2'}
 *  option={option}
 *  dispatch={setOption}
 *  variant="contained"
 * />
 * @example <caption>Exemple Type de Liste</caption>
 * const [ option, setOption ] = React.useState<string>('')
 * const disabled = false // change selon besoin
 * <QCSwitchSelect2
 *  text1={'option 1'}
 *  text2={'option 2'}
 *  value1={'option1'}
 *  value2={'option2'}
 *  option={option}
 *  dispatch={setOption}
 *  variant="outlined"
 *  disabled={disabled}
 * />
 */
const QCSwitchSelectSkeleton = ({
  label,
  text1,
  text2,
  value1,
  value2,
  option,
  dispatch,
  info,
  className,
  variant,
  disabled,
}: QCSwitchSelectProps) => {
  const theme = useTheme();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    dispatch(event.currentTarget.value);
  };
  const isSelected = (value: string) => {
    if (option) return option == value;

    return false;
  };
  return (
    <Box className={className}>
      <Box className="outterBox">
        {label && <QCInputHeader label={label} info={info} />}
        <Box className="innerBox">
          <Box className="backgroundBox"></Box>
          <Button
            className="option1"
            value={value1}
            onClick={handleClick}
            disabled={variant == 'outlined' && disabled}
            variant={variant == 'contained' && isSelected(value1) ? 'contained' : 'outlined'}
            sx={
              variant == 'contained'
                ? containedButtonStyles(theme, isSelected(value1))
                : outlinedButtonStyles(theme, isSelected(value1))
            }
          >
            {text1}
          </Button>
          <Button
            className="option2"
            value={value2}
            onClick={handleClick}
            disabled={variant == 'outlined' && disabled}
            variant={variant == 'contained' && isSelected(value2) ? 'contained' : 'outlined'}
            sx={
              variant == 'contained'
                ? containedButtonStyles(theme, isSelected(value2))
                : outlinedButtonStyles(theme, isSelected(value2))
            }
          >
            {text2}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const QCSwitchSelect = styled(QCSwitchSelectSkeleton)(({ theme, variant }) => {
  return {
    '& .outterBox': {
      ...outterBoxStyles,
    },
    '& .innerBox': {
      ...innerBoxStyles(theme, variant),
    },
    '& .backgroundBox': {
      ...backgroundBoxStyles(theme),
    },
  };
});

export default QCSwitchSelect;
