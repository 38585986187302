import React, { useEffect } from 'react';
import { Divider, useTheme } from '@mui/material';
import { useState, MouseEventHandler } from 'react';
import { useTranslation } from 'react-i18next';

import { WidgetElementsDef } from './const';
import { dividerStyle } from './styles';
import { WidgetType } from './types';
import { useReadFormPublishedState } from '80.quickConnect.core/siteAdmin/hooks/readFormPublishState';
import { useReadFormType } from '80.quickConnect.core/siteAdmin/hooks/readFormType';
import { useStore } from '30.quickConnect.Store/siteAdmin';
import { useSafeNavigation } from '80.quickConnect.core/siteAdmin/hooks/useSafeNavigation';

export const useHomeWidgetData = (defaultType: WidgetType, widgetIndex: 0 | 1) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useSafeNavigation();

  const { readFormType, drawFormType } = useReadFormType();
  const { readFormPublishedState } = useReadFormPublishedState();

  const {
    UserSettingsStore: { setDefaultsHomeWidgetTypes },
  } = useStore();

  const [openWidgetTypeSelect, setOpenWidgetTypeSelect] = useState(false);
  const [selectAnchorEl, setSelectAnchorEl] = useState<null | HTMLElement>(null);
  const [selectedWidgetType, setSelectedWidgetType] = useState<WidgetType>(defaultType);

  const handleWidgetChange: MouseEventHandler<HTMLButtonElement> = (event) => {
    setOpenWidgetTypeSelect((currOpenSelect) => !currOpenSelect);
    setSelectAnchorEl(event.currentTarget);
  };

  const handleSeeMoreClick = () => {
    navigate(WidgetElementsDef[selectedWidgetType].link);
  };

  const listDivider = (index: number, length: number) => {
    return index != length - 1 && <Divider sx={dividerStyle(theme)} />;
  };

  useEffect(() => {
    //sauvegarde des types de widgets quand on quitte la page
    return () => {
      setDefaultsHomeWidgetTypes(selectedWidgetType, widgetIndex);
    };
  }, [selectedWidgetType, widgetIndex, setDefaultsHomeWidgetTypes]);

  return {
    t,
    theme,
    readFormType,
    drawFormType,
    readFormPublishedState,
    openWidgetTypeSelect,
    selectAnchorEl,
    setSelectAnchorEl,
    selectedWidgetType,
    setSelectedWidgetType,
    handleWidgetChange,
    handleSeeMoreClick,
    listDivider,
  };
};
