import { IconButton } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import React from 'react';
import QCChoiceMenu from '../../Inputs/QCChoiceMenu';
import { QCKebabMenuProps } from './type';

const QCKebabMenu = ({ options, dispatch, size = 'medium', setIsMenuOpen, disabled }: QCKebabMenuProps) => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    if (setIsMenuOpen) {
      setIsMenuOpen(true);
    }
  };

  const handleMenuClose = () => {
    if (setIsMenuOpen) setIsMenuOpen(false);
  };

  return (
    <>
      <IconButton onClick={handleClick} sx={{ color: 'inherit' }} size={size} disabled={disabled}>
        <MoreVertIcon fontSize={size} />
      </IconButton>
      <QCChoiceMenu
        options={options}
        anchorElState={[anchorEl, setAnchorEl]}
        dispatch={dispatch}
        onMenuClose={handleMenuClose}
      />
    </>
  );
};

export default QCKebabMenu;
