import { useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CreateFamilyRequest } from '../services/requests';
import { useRessourcesServices } from '../services/services';
import useSupportedLanguages from '../useSupportedLanguages';
import { familiesToTreeViewNodes } from './familiesToTreeViewNodes';
import { TreeViewNode } from '70.quickConnect.Components/siteAdmin/Other/TreeView/types';
import useEffectOnce from '80.quickConnect.core/shared/hooks/useEffectOnce';
import { AllDataElementType } from '70.quickConnect.Components/siteAdmin/Other/QCDialogForm/types';
import { Family } from '90.quickConnect.Models/siteAdmin/models/families/family';
import { useSafeNavigation } from '80.quickConnect.core/siteAdmin/hooks/useSafeNavigation';
import { ENTITYSETTINGS } from '10.quickConnect.app/siteAdmin/routes/paths';
import { getReplacedPath } from '80.quickConnect.core/shared/helpers/getReplacedPath';

const useAddFamilyDialogFormData = () => {
  const { t } = useTranslation();
  const navigate = useSafeNavigation();

  const { getFamilies, createFamily } = useRessourcesServices();

  const { supportedLanguages } = useSupportedLanguages();

  const [familyTreeView, setFamilyTreeView] = useState<TreeViewNode[]>([]);

  const families = useRef<Family[]>([]);

  const initFamilyTreeView = useCallback(async () => {
    const data = await getFamilies({
      count: 1,
      filters: [],
      length: 0,
      loadAttributes: false,
      name: '',
      start: 0,
      textFilter: null,
      orderBy: [],
    });

    if (data && data.length > 0) {
      families.current = data;
      const layers: TreeViewNode[] = familiesToTreeViewNodes(data);
      setFamilyTreeView(layers);
    }
    //eslint-disable-next-line
  }, []);

  useEffectOnce(() => {
    initFamilyTreeView();
  });

  const handleConfirm = useCallback(
    async (data: AllDataElementType[]) => {
      const addFamilyRequest: CreateFamilyRequest = {
        description: '',
        isCacheable: false,
        name: '',
        qrCodeType: 0,
        attributes: [],
        webhookIds: [],
        defaultLanguage: '',
      };

      data[0].data.forEach((el) => {
        switch (el.label) {
          case t('entitySchema.entityDesigner.entitySchema.placeholder.name'):
            if (typeof el.value === 'string') addFamilyRequest.name = el.value;
            break;

          case t('entitySchema.entityDesigner.entitySchema.placeholder.description'):
            if (typeof el.value === 'string') addFamilyRequest.description = el.value;
            break;

          case t('entitySchema.entityDesigner.entitySchema.placeholder.parent'):
            // on vérifie que la famille avec cet id existe bien
            const family = families.current.find((fam) => fam.id === el.value);
            if (family) addFamilyRequest.parentId = family.id;
            break;
          case t('common.internationalization.defaultLanguage'):
            const language = supportedLanguages.find((lg) => lg.lang === el.value);
            if (language) addFamilyRequest.defaultLanguage = language.value;

            break;
        }
      });

      const responseData = await createFamily(addFamilyRequest);
      if (responseData) {
        // navigate(`/equipments/entity/${responseData.schema.id}/settings`);
        navigate(getReplacedPath(ENTITYSETTINGS, [responseData.schema.id]));
        return true;
      }
      return false;
    },
    //eslint-disable-next-line
    [families.current, createFamily, supportedLanguages, t, navigate],
  );

  return {
    t,
    supportedLanguages,
    familyTreeView,
    handleConfirm,
  };
};

export default useAddFamilyDialogFormData;
