import React from 'react';
import { Box, Grow, IconButton } from '@mui/material';
import { DragIndicator } from '@mui/icons-material';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { observer } from 'mobx-react';
import { useFamilyAttributeItemData } from './hooks';
import { AttributeFormStyle } from './styles';
import { FamilyAttributeItemProps } from './types';
import { EntitySchemaAttribute } from '90.quickConnect.Models/siteAdmin/models/families/entitySchemaAttribute';
import QCTextInput from '70.quickConnect.Components/siteAdmin/Inputs/QCTextInput';
import QCSelect from '70.quickConnect.Components/siteAdmin/Inputs/QCSelect';
import { EntitySchemaAttributeTypes } from '90.quickConnect.Models/siteAdmin/enums/family/entitySchemaAttributeType';
import QCAutocomplete from '70.quickConnect.Components/siteAdmin/Inputs/QCAutocomplete';

const FamilyAttributeItem = ({ attributeId }: FamilyAttributeItemProps) => {
  const {
    t,
    theme,
    readFamilyAttributeType,
    readTranslation,
    attribute,
    setAttribute,
    changeAttribute,
    getSharedListValue,
    sharedListOptions,
    sharedListSelectAppears,
    simpleSharedLists,
    hierarchicalSharedLists,
    handleDeleteClick,
    hasFocus,
    setHasFocus,
    inputControl,
  } = useFamilyAttributeItemData({ attributeId });

  return (
    <Box sx={AttributeFormStyle(theme, hasFocus)}>
      <QCTextInput
        label={t('entitySchema.entityDesigner.entitySchema.attributes.placeholder.name')}
        control={inputControl.notNull}
        value={attribute.name}
        dispatch={(value: string) => {
          changeAttribute(value, 'name', attributeId);
          setAttribute((att) => {
            const newAtt: EntitySchemaAttribute = { ...att };
            newAtt.name = value;
            return newAtt;
          });
        }}
        textFieldProps={{ onFocus: () => setHasFocus(true), onBlur: () => setHasFocus(false) }}
      />
      <QCTextInput
        label={t('entitySchema.entityDesigner.entitySchema.attributes.placeholder.description')}
        value={attribute.description}
        dispatch={(value: string) => {
          changeAttribute(value, 'description', attributeId);
          setAttribute((att) => {
            const newAtt: EntitySchemaAttribute = { ...att };
            newAtt.description = value;
            return newAtt;
          });
        }}
        textFieldProps={{ onFocus: () => setHasFocus(true), onBlur: () => setHasFocus(false) }}
      />
      <QCSelect
        label={t('entitySchema.entityDesigner.entitySchema.attributes.label.controlType')}
        options={Object.values(EntitySchemaAttributeTypes).map((ent) => readFamilyAttributeType(ent))}
        value={readFamilyAttributeType(attribute.type)}
        defaultValue={readFamilyAttributeType(attribute.type)}
        setOption={(value: string) => {
          const attType = readTranslation(value);
          if (attType !== 0) {
            changeAttribute(attType, 'type', attributeId);
            setAttribute((att) => {
              const newAtt: EntitySchemaAttribute = { ...att };
              newAtt.type = attType;
              return newAtt;
            });
          }
        }}
      />
      <Grow appear={false} in={sharedListSelectAppears} style={{ transformOrigin: '0 0 0' }} timeout={500}>
        <div>
          <QCAutocomplete
            label={t('entitySchema.entityDesigner.entitySchema.attributes.label.sharedListToUse')}
            value={getSharedListValue()}
            options={sharedListOptions}
            dispatch={(value: string) => {
              const newSharedListId = simpleSharedLists
                .concat(hierarchicalSharedLists)
                .find((list) => list.name === value)?.id;
              changeAttribute(newSharedListId, 'sharedListId', attributeId);
              setAttribute((att) => {
                const newAtt: EntitySchemaAttribute = { ...att };
                newAtt.sharedListId = newSharedListId;
                return newAtt;
              });
            }}
            autocompleteProps={{ onFocus: () => setHasFocus(true), onBlur: () => setHasFocus(false) }}
          />
        </div>
      </Grow>

      <IconButton
        sx={{ position: 'absolute', right: '10px', top: '5%', color: theme.palette.error.main }}
        onClick={handleDeleteClick}
      >
        <DeleteOutlinedIcon />
      </IconButton>
      <DragIndicator sx={{ position: 'absolute', right: '18px', top: '50%', color: theme.palette.grey[400] }} />
    </Box>
  );
};

export default observer(FamilyAttributeItem);
