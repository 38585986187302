import { createContext, useContext } from 'react';
import { TraductionContextType } from './types';

export const TraductionContext = createContext<TraductionContextType>({} as TraductionContextType);

export function useTraductionContext() {
  const context = useContext(TraductionContext);
  if (!context) {
    throw new Error(`Traduction compound components cannot be rendered outside the Traduction component`);
  }
  return context;
}
