// import dayjs, { Dayjs } from 'dayjs';
import { useCallback, useMemo, useState } from 'react';
// import { TreeViewNode } from '../TreeView/types';
import { AllDataElementType, DataValueType } from './types';

const useData = () => {
  const stepProps: { completed?: boolean } = {};

  const [activeStep, setActiveStep] = useState(0);

  const nextStep = () => {
    setActiveStep(activeStep + 1);
  };

  const prevStep = () => {
    setActiveStep(activeStep - 1);
  };

  const [allDataArray, setAllDataArray] = useState<AllDataElementType[]>([]);

  const getAllDataArray = useCallback(() => allDataArray, [allDataArray]);

  const setAllData = useCallback(
    (state: AllDataElementType[]) => {
      setAllDataArray(state);
    },
    [setAllDataArray],
  );

  useMemo(() => {
    setAllDataArray([]);
  }, [setAllDataArray]);

  const addFieldAllDataArray = (label: string, data: DataValueType, index: number) => {
    let dataToSet: AllDataElementType[] = [];
    if (getAllDataArray().length === 0) {
      dataToSet = [{ step: 1, data: [{ label: label, value: data }] }];
    } else if (getAllDataArray().length < index) {
      dataToSet = [...getAllDataArray(), { step: index, data: [{ label: label, value: data }] }];
    } else {
      const stepIndex = allDataArray.findIndex((element) => element.step === index);
      dataToSet = getAllDataArray();
      if (dataToSet[stepIndex].data.findIndex((element) => element.label === label) === -1)
        dataToSet[stepIndex].data.push({ label: label, value: data });
    }
    setAllDataArray(dataToSet);
  };

  const deleteFieldAllDataArray = (label: string, index: number) => {
    const currentAllData = getAllDataArray();
    if (currentAllData.length >= index) {
      const stepToDeleteIndex = currentAllData.findIndex((data) => data.step == index);
      const dataAfterDelete = currentAllData[stepToDeleteIndex].data.filter((el) => el.label !== label);
      currentAllData[stepToDeleteIndex] = { step: index, data: dataAfterDelete };
      setAllDataArray(currentAllData);
    }
  };

  const updateAllDataArray = (label: string, data: DataValueType, index: number) => {
    const stepIndex = allDataArray.findIndex((element) => element.step === index);

    const step = allDataArray[stepIndex];
    const stepDataCopy: {
      label: string;
      value: DataValueType;
    }[] = [];

    const allDataArrayCopy = allDataArray.filter((element) => element !== allDataArray[stepIndex]);
    for (const field of step.data) {
      stepDataCopy.push({ label: field.label, value: field.label === label ? data : field.value });
    }

    allDataArrayCopy.push({ step: index, data: stepDataCopy });

    setAllDataArray(allDataArrayCopy);
  };

  const resetAllDataArray = () => {
    const copy = allDataArray.map((step) => {
      return {
        step: step.step,
        data: step.data.map((data) => {
          return {
            label: data.label,
            value: '',
          };
        }),
      } as AllDataElementType;
    });

    setAllDataArray(copy);
  };

  return {
    stepProps,
    activeStep,
    nextStep,
    prevStep,
    allDataArray,
    setAllData,
    addFieldAllDataArray,
    updateAllDataArray,
    setActiveStep,
    resetAllDataArray,
    deleteFieldAllDataArray,
  };
};

export default useData;
