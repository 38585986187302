import { Box, Collapse, useTheme } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import CloseIcon from '@mui/icons-material/Close';
import React, { useContext } from 'react';
import { ConnectionHistoryContext } from '../context';

// eslint-disable-next-line
const CurrentVersionCell = ({ params }: { params: GridRenderCellParams<any, any, any> }) => {
  const { selectedRowId } = useContext(ConnectionHistoryContext);
  const theme = useTheme();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        lineHeight: 1,
        height: params.row.id === selectedRowId ? '120px' : '70px',
        fontSize: '16px',
        gap: 1,
      }}
    >
      <p>{params.value.currentAppVersionCode}</p>
      <div>
        <Collapse in={params.row.id === selectedRowId}>
          {params.row.id === selectedRowId ? (
            <Box
              component={'span'}
              sx={{ display: 'flex', flexDirection: 'column', gap: '8px', color: theme.palette.grey[400] }}
            >
              <span>Ancienne version </span>
              {params.value.previousAppVersionCode !== null ? params.value.previousAppVersionCode : <CloseIcon />}
            </Box>
          ) : null}
        </Collapse>
      </div>
    </Box>
  );
};

export default CurrentVersionCell;
