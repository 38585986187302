import { useTranslation } from 'react-i18next';
import { BinElementType, BinElementTypeEnum } from '90.quickConnect.Models/siteAdmin/enums/recycleBin/elementType';

export const useReadElementType = () => {
  const { t } = useTranslation();

  const getBinElementTypeText = (binElementType: BinElementTypeEnum): string => {
    switch (binElementType) {
      case BinElementType.Unknow:
        return t('common.helpers.workflow.state.none');
      case BinElementType.OrganizationUnit:
        return t('common.helpers.binElement.state.organizationalUnit');
      case BinElementType.Declaration:
        return t('common.helpers.binElement.state.declaration');
      case BinElementType.EntityInstance:
        return t('common.helpers.binElement.state.equipment');
      case BinElementType.EntitySchema:
        return t('common.helpers.binElement.state.schema');
      case BinElementType.EntitySchemaAttributes:
        return t('common.helpers.binElement.state.entitySchemaAttributes');
      case BinElementType.Field:
        return t('common.helpers.binElement.state.field');
      case BinElementType.Form:
        return t('common.helpers.binElement.state.form');
      case BinElementType.Inbox:
        return t('common.helpers.binElement.state.inbox');
      case BinElementType.Profil:
        return t('common.helpers.binElement.state.profil');
      case BinElementType.QCVar:
        return t('common.helpers.binElement.state.qcvar');
      case BinElementType.SharedList:
        return t('common.helpers.binElement.state.sharedList');
      case BinElementType.User:
        return t('common.helpers.binElement.state.user');
      case BinElementType.UserParameter:
        return t('common.helpers.binElement.state.userParameter');
      case BinElementType.Webhook:
        return t('common.helpers.binElement.state.webhook');
    }
  };

  return { getBinElementTypeText };
};
