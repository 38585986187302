import { Box } from '@mui/material';
import React from 'react';
import useData from './hooks';
import QCPhotoInput from '70.quickConnect.Components/siteAdmin/Inputs/QCPhotoInput';
import QCInputHeader from '70.quickConnect.Components/siteAdmin/Inputs/QCInputHeader';

const QCDialogFormPhoto = ({
  label,
  info,
  mandatory = false,
}: {
  label: string;
  info?: string;
  mandatory?: boolean;
}) => {
  const { files, setFiles, handleChange } = useData(label, mandatory ?? false);
  return (
    <Box height={'80%'}>
      {label && <QCInputHeader label={label} info={info} mandatory={mandatory} />}
      <QCPhotoInput files={files} setFiles={setFiles} handleChange={handleChange} />
    </Box>
  );
};

export default QCDialogFormPhoto;
