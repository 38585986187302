import React from 'react';
import { Box } from '@mui/material';
import { ContentHeaderProps } from './type';
import { ContenHeaderStyle } from './style';
import BackButton from './BackButton';
import TitlesGroup from './TitlesGroup';
import Title from './Title';
import OverTitle from './OverTitle';
import Controls from './Controls';

/**
 * @component ContentHeader - Compound Component
 * @param {ReactNote} [children] - Composants : BackButton, TitlesGroup, OverTitle, Title, Controls
 *
 * @example
 * return (
 *  <ContentHeader>
 *    <ContentHeader.BackButton />
 *    <ContentHeader.TitlesGroup>
 *      <ContentHeader.OverTitle>Paramètre de catalogue</ContentHeader.OverTitle>
 *      <ContentHeader.Title>
 *        <ListIcon fontSize="large" /> Salesforce
 *      </ContentHeader.Title>
 *    </ContentHeader.TitlesGroup>
 *
 *    <ContentHeader.Controls>
 *      <QCFavIcon dispatch={setFav} />
 *      <QCButtonGlobal value="Exemple" className="Exemple" />
 *    </ContentHeader.Controls>
 *  </ContentHeader>
 * )x
 */

const ContentHeader = ({ children }: ContentHeaderProps) => {
  return <Box sx={ContenHeaderStyle()}>{children}</Box>;
};

ContentHeader.BackButton = BackButton;
ContentHeader.TitlesGroup = TitlesGroup;
ContentHeader.Title = Title;
ContentHeader.OverTitle = OverTitle;
ContentHeader.Controls = Controls;

export default ContentHeader;
