import { action, makeAutoObservable, observable } from 'mobx';
import { isPersisting, makePersistable } from 'mobx-persist-store';
import { PublicClientApplication } from '@azure/msal-browser';
import axios from 'axios';
import { toast } from 'react-toastify';
import { TFunction } from 'react-i18next';
// import { API_VALIDATE_TOKEN, API_SIGNIN, API_HOST } from '40.quickConnect.DataAccess/axios/apiRoutes';
// import CustomLogger from '80.quickConnect.Core/logger/customLogger';
import RootStore from '../..';
import { SignOutResponse } from '40.quickConnect.DataAcces/siteAdmin/services/login/payloads/responses';
import { ApiAccount } from '40.quickConnect.DataAcces/siteAdmin/apiRoutes';
import { MsalInfos } from '90.quickConnect.Models/siteAdmin/models/user/msalConfig';
import { MsalAuthConfig } from '90.quickConnect.Models/siteAdmin/models/user/MsalAuthConfig';
import mapMsalAuthConfig from '90.quickConnect.Models/siteAdmin/mapping/others/mapAADConfig';
import CustomLogger from '80.quickConnect.core/siteAdmin/logger/customLogger';
import { CustomAxiosError } from '40.quickConnect.DataAcces/siteAdmin/QCHttpRequest/types';
import { IdentityProviders } from '90.quickConnect.Models/siteAdmin/enums/user/identityProviders';

const defaultMsalInfos = {} as MsalInfos;
const { ValidateToken, Signin } = ApiAccount;

const defaultMsalAuthConfig = {
  auth: {
    clientId: '',
    clientSecret: '',
    redirectUri: '',
    authority: '',
  },
  cache: {
    cacheLocation: '',
    storeAuthStateInCookie: false,
  },
};

class LoginMsalStore {
  logger: CustomLogger;

  RootStore: RootStore;

  msalInfos: MsalInfos = defaultMsalInfos;

  // isLogoutByMsal = false;

  msalAuthConfig: MsalAuthConfig = defaultMsalAuthConfig;

  constructor(rootStore: RootStore, logger: CustomLogger, storageKey: string) {
    this.logger = logger;
    this.RootStore = rootStore;

    makeAutoObservable(
      this,
      {
        msalInfos: observable,
        msalAuthConfig: observable,
        // isLogoutByMsal: observable,
        setMsalAuthConfig: action,
        // setIsLogoutByMsal: action,
      },
      {
        autoBind: true,
      },
    );

    void makePersistable(this, {
      name: storageKey,
      properties: ['msalInfos', 'msalAuthConfig'],
      storage: window.sessionStorage,
    });
  }

  get isPersisting() {
    return isPersisting(this);
  }

  resetStore = (): void => {
    this.msalInfos = defaultMsalInfos;
    this.msalAuthConfig = defaultMsalAuthConfig;
  };

  setMsalAuthConfig = (msalAuthConfig: MsalAuthConfig) => (this.msalAuthConfig = msalAuthConfig);

  /**
   * Permet de créer la redirection dans le cas d'une connexion en SSO avec l'AAD.
   * @param msalInfos AADConfig providers d'identité de l'utilisateur au sein du portail Azure
   * @returns Promise<void>
   */

  redirectLogin = async (msalInfos: MsalInfos): Promise<void> => {
    try {
      this.logger.resetInitDateTimeApp();
      // Mémoriser les infos en parametres (utilisation de makePersistable)
      this.msalInfos = msalInfos;
      // Utiliser l'instance de Msal
      // Appeler la fonction loginRedirect de Msal
      if (this.isPersisting) {
        await this.handleRedirectPromise();
      }
      // eslint-disable-next-line
    } catch (error: any) {
      this.logger.error('redirectLogin Failed', { CrashDump: error });
    }
  };

  handleRedirectPromise = async (): Promise<void> => {
    try {
      // Récupérer les informations du store
      this.setMsalAuthConfig(mapMsalAuthConfig(this.msalInfos));

      // LAncer l'instance pca
      const pca = new PublicClientApplication(this.msalAuthConfig);

      // Vérifions que l'account est présent (l'utilisateur s'est déjà connecté).
      const [existingAccount] = pca.getAllAccounts();

      // si il y a un compte existant pour ce userUPN
      if (existingAccount && existingAccount.username === this.msalInfos.userUPN) {
        const accesTokenRequest = {
          scopes: ['openid'],
          account: existingAccount,
        };

        const accessTokenResponse = await pca.acquireTokenSilent(accesTokenRequest);

        const { idToken } = accessTokenResponse;

        // Appel à L'API validateToken
        this.validateTokenAsync(idToken);
      } else {
        // Lancer la methode HandleRedirectPromise du pca
        const response = await pca.handleRedirectPromise();

        if (response !== null) {
          const [account] = pca.getAllAccounts();
          console.error('passe apres getAllAcounts');

          const accesTokenRequest = {
            scopes: ['openid'],
            account,
          };

          const accessTokenResponse = await pca.acquireTokenSilent(accesTokenRequest);

          const { idToken } = accessTokenResponse;

          // Appel à L'API validateToken
          this.validateTokenAsync(idToken);

          // Display signed-in user content, call API, etc.
          //  this.signInAsync();
        } else {
          // In case multiple accounts exist, you can select
          const currentAccounts = pca.getAllAccounts();

          if (currentAccounts.length === 0) {
            // no accounts signed-in, attempt to sign a user in

            await pca.loginRedirect({
              scopes: ['openid'],
              loginHint: this.msalInfos.userUPN,
            });
          }
        }
      }
    } catch (error) {
      console.error('erreur fdp handleRedirectPromise : ' + error);
      this.logger.error(error);
    }
  };

  /**
   * Appelle l'API de QuickConnect avec un Bearer Token afin de se connecter.
   * @param idToken Token généré par le SSO d'Azure
   * @returns Promise<void>
   */
  validateTokenAsync = async (idToken: string): Promise<void> => {
    try {
      // Récupération d'un bearerToken
      const responseBearerToken = await axios.post(
        this.RootStore.CommonStore.chooseBaseUrl(ValidateToken),

        {},
        {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${idToken}`,
          },
        },
      );

      if (responseBearerToken.status < 200 || responseBearerToken.status >= 300) return;

      // Récupération d'un QC Cookie
      const loginResponse = await axios.post(
        this.RootStore.CommonStore.chooseBaseUrl(Signin),
        {
          userUPN: this.msalInfos.userUPN,
          password: '',
          cgu: true,
        },
        {
          withCredentials: true,
        },
      );

      if (200 <= loginResponse.status && loginResponse.status < 300) {
        this.RootStore.LoginStore.setSignInInfos(loginResponse.data);
      } else await this.logger.error(`réponse du serveur KO pour la connexion : ${loginResponse.status}`);
    } catch (error) {
      this.logger.error(error);
    }
  };

  logoutMsalAsync = async (t: TFunction) => {
    this.RootStore.LoginStore.setConnectByMsal(false);
    const pca = new PublicClientApplication(this.msalAuthConfig);

    const [account] = pca.getAllAccounts();

    const logoutRequest = {
      account,
    };

    this.RootStore.resetAllObservables();
    await this.RootStore.clearAllDomainStores();

    pca.logoutRedirect(logoutRequest);

    axios
      .post<SignOutResponse>(
        this.RootStore.CommonStore.chooseBaseUrl(ApiAccount.Signout),
        {},
        {
          withCredentials: true,
        },
      )
      .then(async (response) => {
        if (200 <= response.status && response.status < 300) {
          this.RootStore.LoginStore.setAuthenticationMethod(IdentityProviders.AzureActiveDirectory);
        } else await this.logger.error(`réponse du serveur KO pour la déconnexion : ${response.status}`);
      })
      .catch(async (error) => {
        // eslint-disable-next-line
        const errorAxios = error as CustomAxiosError<any>;
        const errorMsg = errorAxios?.response
          ? `${errorAxios?.response?.status} ${errorAxios?.response?.statusText}`
          : errorAxios?.message;
        await this.logger.error(errorMsg);
        toast.error(
          t('logOutAsync.errorWithMsg', {
            msg: errorMsg,
          }).toString(),
        );
      })
      // finally non appelé lors de la deco msal
      .finally(async () => {
        this.RootStore.resetAllObservables();
        await this.RootStore.clearAllDomainStores();
      });
  };
}

export default LoginMsalStore;
