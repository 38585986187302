import { useContext, useEffect, useState } from 'react';
import { QCDialogFormContext } from '../../context';
import { BodyContext } from '../../StepBody/context';
import { TreeViewNode } from '70.quickConnect.Components/siteAdmin/Other/TreeView/types';

const useData = (label: string, mandatory?: boolean) => {
  const { addMandatoryField, setFilled, index } = useContext(BodyContext);
  const { allData, updateAllDataArray, addFieldAllDataArray } = useContext(QCDialogFormContext);

  const [defaultOption, setDefaultOption] = useState('');

  useEffect(() => {
    addFieldAllDataArray(label, defaultOption, index);
  }, [defaultOption, addFieldAllDataArray, label, index]);

  useEffect(() => {
    if (allData[index - 1] && allData[index - 1].data) {
      const inputData = allData[index - 1].data;
      const valueIndex = inputData.findIndex((el) => el.label === label);
      if (valueIndex !== -1) {
        const { value } = inputData[valueIndex];
        if (typeof value === 'string') {
          setDefaultOption(value);
          if (mandatory) {
            addMandatoryField({ label: label, filled: value !== '' });
          }
        }
      }
    }
  }, [allData, addMandatoryField, index, label, mandatory]);

  const handleChange = (value: TreeViewNode) => {
    setFilled(label, value.name === '' ? false : true);
    updateAllDataArray(label, value.id, index);
    setDefaultOption(value.id);
  };

  return { handleChange, defaultOption };
};

export default useData;
