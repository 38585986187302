import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { BrowserRouter } from 'react-router-dom';
import reportWebVitals from './reportWebVitals';
import App from '00.app/App';
import '80.quickConnect.core/siteAdmin/utils/';
import { createStore, StoreProvider } from '30.quickConnect.Store/siteAdmin';
import 'animate.css';
import { createLogger } from '80.quickConnect.core/siteAdmin/logger';

const logger = createLogger();

const rootStore = createStore(logger);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <StoreProvider value={rootStore}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </StoreProvider>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
