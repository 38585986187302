import { SxProps, Theme } from '@mui/material';
import { pureWhiteColor } from '00.app/theme/colors';

export const ContenHeaderStyle = (): SxProps => {
  return {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'flex-start',
    position: 'relative',
    marginX: '10px',
    marginY: '7px',
    gap: '1vw',
  };
};

export const SquareSvgStyle: SxProps = {
  // backgroundColor: 'white',
  position: 'absolute',
  top: '20px',
  left: '46px',
  color: 'red',
  scale: '2.5',
  zIndex: '-1',
};

export const BackButtonStyle = (theme: Theme): SxProps => {
  return {
    borderRadius: '50%',
    backgroundColor: theme.palette.primary.main,
    // marginX: '5px',
    marginTop: '10px',
    width: '47px',
    height: '47px',
    color: pureWhiteColor,
    ':hover': {
      backgroundColor: theme.palette.primary.main,
    },
  };
};

export const TitlesContainerStyle: SxProps = {
  backgroundColor: 'transparent',
  display: 'flex',
  flexWrap: 'nowrap',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  // marginX: '7px',
  whiteSpace: 'nowrap',
  gap: '0',
};

export const OverTitleStyle = (theme: Theme): SxProps => {
  return {
    display: 'inline',
    fontSize: '1em',
    lineHeight: '1.5em',
    color: theme.palette.grey[400],
    whiteSpace: 'nowrap',
  };
};

export const TitleStyle = (): SxProps => {
  return {
    alignItems: 'center',
    margin: '0',
    padding: 'none',
    whiteSpace: 'nowrap',
    '& > h1': {
      verticalAlign: 'middle',
      display: 'inline-flex',
      marginY: '0',
      paddingY: '0',
      lineHeight: '1em',
    },
  };
};

export const ControlsStyle: SxProps = {
  verticalAlign: 'middle',
  display: 'inline-flex',
  alignContent: 'flex-end',
  flexWrap: 'wrap',
};
