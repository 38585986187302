import { useContext, useEffect, useState } from 'react';
import { QCDialogFormContext } from '../../context';
import { BodyContext } from '../../StepBody/context';
import { AttributeValue } from '90.quickConnect.Models/siteAdmin/models/common/entities';

const useData = (label: string, mandatory: boolean, attributData: AttributeValue) => {
  const { addMandatoryField, setFilled, index, addControlledField } = useContext(BodyContext);
  const { allData, updateAllDataArray, addFieldAllDataArray } = useContext(QCDialogFormContext);

  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    attributData.value = new String(defaultValue);
    addFieldAllDataArray(label, defaultValue, index);
    //eslint-disable-next-line
  }, [defaultValue, addFieldAllDataArray, label, index]);

  useEffect(() => {
    if (allData[index - 1] && allData[index - 1].data) {
      const inputData = allData[index - 1].data;
      const valueIndex = inputData.findIndex((el) => el.label === label);
      if (valueIndex !== -1) {
        const { value } = inputData[valueIndex];
        if (typeof value === 'string') {
          setDefaultValue(value);
          if (mandatory) {
            addMandatoryField({ label: label, filled: value !== '' });
          }
        }
      }
    }
  }, [allData, addMandatoryField, addControlledField, index, label, mandatory]);

  useEffect(() => {
    setFilled(label, defaultValue !== '');
    // eslint-disable-next-line
  }, [defaultValue]);

  const handleChange = (value: AttributeValue) => {
    const strValue = String(value.value);
    console.log('notifiation string', strValue);
    setFilled(label, strValue === '' ? false : true);
    updateAllDataArray(label, value, index);
    setDefaultValue(strValue);
  };
  return { handleChange, defaultValue };
};

export default useData;
