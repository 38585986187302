import { Box, IconButton, Typography } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import React, { useMemo } from 'react';
import { useTraductionContext } from '../context';
import useData from './hooks';
import { TradFieldsListType } from './types';
import { mainBox, textStyle } from './styles';
import QCChoiceMenu from '70.quickConnect.Components/siteAdmin/Inputs/QCChoiceMenu';
import { green4 } from '00.app/theme/colors';

const TradFieldsList = ({ children, isSourceLang }: TradFieldsListType) => {
  const { sourceLang, setSourceLang, supportedLangs, destLang, setDestLang } = useTraductionContext();
  const currentLang = useMemo(
    () => (isSourceLang ? sourceLang.lang : destLang.lang),
    [isSourceLang, sourceLang, destLang],
  );
  const { anchorEl, setAnchorEl, handleClick, handleMenuClose } = useData();

  return (
    <Box sx={mainBox}>
      <Box sx={{ display: 'flex' }}>
        <Typography sx={textStyle}>{currentLang}</Typography>
        {isSourceLang && (
          <>
            <IconButton onClick={handleClick}>
              <KeyboardArrowDownIcon sx={{ color: green4 }} />
            </IconButton>
            <QCChoiceMenu
              options={supportedLangs.map((language) => ({
                text: language.lang[0].toLocaleUpperCase() + language.lang.substring(1),
              }))}
              anchorElState={[anchorEl, setAnchorEl]}
              onMenuClose={handleMenuClose}
              dispatch={(value: string) => {
                if (value.toLocaleLowerCase() === destLang.lang) {
                  const tempSourceLang = sourceLang;
                  setDestLang(tempSourceLang);
                }
                setSourceLang(
                  (prev) => supportedLangs.find((language) => language.lang === value.toLocaleLowerCase()) ?? prev,
                );
              }}
            />
          </>
        )}
      </Box>
      {children}
      {/* {React.cloneElement(children, {currentLang: currentLang})} */}
    </Box>
  );
};

export default TradFieldsList;
