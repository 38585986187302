import { t } from 'i18next';
import { ChoiceMenuOptionType } from '70.quickConnect.Components/siteAdmin/Inputs/QCChoiceMenu/type';

export enum UserMultipleActions {
  Delete = 'users.designer.form.actions.delete',
}

export enum UserRowActions {
  Delete = 'users.designer.form.actions.delete',
  Edit = 'users.users.table.actions.edit',
}

export const actionsToChoiceMenuOtion = (actions: UserMultipleActions[] | UserRowActions[]): ChoiceMenuOptionType[] => {
  return actions.map((action) => ({ text: t(action), value: action }));
};

export enum OrganizationRowAction {
  Delete = 'common.actions.delete',
  Settings = 'navigation.menu.settings',
}

export enum MultipleActions {
  Delete = 'common.actions.delete',
}
