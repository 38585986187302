import { SxProps } from '@mui/material';

export const BottmMenuStyles: SxProps = {
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 2,
  '& .MuiBottomNavigation-root': {
    backgroundImage: 'url(assets/bottomMenuBackground.png)',
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
  },
};
