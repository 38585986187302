import { SxProps, Theme } from '@mui/material';

export const InputBoxStyle: SxProps = {
  width: { xs: '250px' },
};

export const upContainerStyle: SxProps = {
  display: 'flex',
  flexWrap: 'wrap',
  gap: 2,
  flexBasis: '70%',
};

export const typographyStyles: SxProps = {
  fontWeight: '600',
  fontSize: '1.5rem',
};

export const CardContainerStyle = (theme: Theme): SxProps => ({
  backgroundColor: theme.palette.toolsBackground.main,
  border: 'solid 2px',
  borderColor: theme.palette.menuBorder.main,
  borderRadius: '8px',
  paddingLeft: '20px',
  paddingTop: '10px',
  paddingRight: '5px',
  paddingBottom: '5px',
  width: '95%',
  height: '52vh',
  position: 'relative',
});

export const innerBoxStyle: SxProps = {
  overflow: 'auto',
  width: '100%',
  height: '90%',
};

export const upBoxStyle = {
  display: 'flex',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  marginX: '3vw',
  marginTop: '3vh',
};

export const downBoxStyle: SxProps = {
  display: 'grid',
  gridTemplateColumns: {
    xs: '1fr',
    sm: '1fr',
    md: '1fr',
    lg: '1fr 1fr',
    xl: '1fr 1fr',
  },
  rowGap: '10px',
  marginX: '3vw',
  marginTop: '3vh',
};

export const ActionButtonsContainer: SxProps = {
  position: { md: 'absolute' },
  right: '20px',
  bottom: '30px',
  display: 'flex',
  flexDirection: { md: 'column' },
  alignContent: 'center',
  marginBottom: { xs: '32px', md: '0' },
  gap: '8px',
};
