import React from 'react';
import { Box, styled, Tooltip, tooltipClasses, TooltipProps } from '@mui/material';
import { ProgressBarProps } from './types';
import { useProgressBarData } from './hooks';

const QCDeclarationProgressTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#B39875',
    borderRadius: '8px 8px 8px 0px',
    fontWeight: 'bold',
    fontSize: '14px',
  },
});

export const ProgressBar = ({ formEditedData = [], targetRef }: ProgressBarProps) => {
  const { readingStep } = useProgressBarData(targetRef);

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', height: '75vh', overflow: 'auto' }}>
      {formEditedData.map((data, index) => (
        <QCDeclarationProgressTooltip key={data.id} title={data.label} placement="right">
          <a key={data.id} href={`#declaration-${data.id}`}>
            <Box
              sx={{
                width: '7px',
                height: '30px',
                borderRadius: '3px',
                backgroundColor: index < readingStep ? '#17DCBB' : '#EFF1F0',
                m: '2px',
                '&:hover': {
                  backgroundColor: '#B39875',
                },
              }}
            ></Box>
          </a>
        </QCDeclarationProgressTooltip>
      ))}
    </Box>
  );
};
