import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import useServices from './services';
import { AllDataElementType } from '70.quickConnect.Components/siteAdmin/Other/QCDialogForm/types';

const useData = () => {
  const { t } = useTranslation();
  const { changePassword } = useServices();

  const [isUpdatePasswordFormOpen, setIsUpdatePasswordFormOpen] = useState(false);
  const [isOpenBackdrop, setIsOpenBackdrop] = useState(false);

  const toggleIsUpdatePasswordFormOpen = useCallback(
    (open: boolean) => {
      setIsUpdatePasswordFormOpen(
        open !== isUpdatePasswordFormOpen ? !isUpdatePasswordFormOpen : isUpdatePasswordFormOpen,
      );
    },
    [setIsUpdatePasswordFormOpen, isUpdatePasswordFormOpen],
  );

  const [allData, setAllData] = useState<AllDataElementType[]>([]);

  const handleConfirm = useCallback(
    async (value: AllDataElementType[]) => {
      setAllData(value);
      setIsOpenBackdrop(true);

      const currentPassword = value[0].data.find(
        (elem) => elem.label === t('users.editPassword.form.placeholder.oldPassword'),
      )?.value;
      const newPassword = value[0].data.find(
        (elem) => elem.label === t('login.forgotPassword.secondStep.newPassword.label'),
      )?.value;
      const confirmPassword = value[0].data.find(
        (elem) => elem.label === t('login.forgotPassword.secondStep.confirmNewPassword.placeholder'),
      )?.value;
      if (process.env.REACT_APP_ENV !== 'dev') {
        if ((newPassword?.toString().length ?? 0) < 8) {
          setIsOpenBackdrop(false);
          toast.error(t('login.forgotPassword.secondStep.newPassword.length', { length: 8 }));
          return false;
        }

        if (newPassword?.toString().match(/[a-zà-ý]{1}/g) === null) {
          setIsOpenBackdrop(false);
          toast.error(t('login.forgotPassword.secondStep.newPassword.lowercase'));
          return false;
        }
        if (newPassword?.toString().match(/[A-ZÀ-Ý]{1}/g) === null) {
          setIsOpenBackdrop(false);
          toast.error(t('login.forgotPassword.secondStep.newPassword.uppercase'));
          return false;
        }
        if (newPassword?.toString().match(/[*.!@#$%^&(){}[\]:;<>,.?/~_ +-=|\\]{1}/gm) === null) {
          setIsOpenBackdrop(false);
          toast.error(t('login.forgotPassword.secondStep.newPassword.special'));
          return false;
        }
      }

      if (newPassword !== confirmPassword) {
        setIsOpenBackdrop(false);
        toast.error(t('login.forgotPassword.secondStep.confirmNewPassword.match'));
        return false;
      }

      const result = await changePassword(currentPassword?.toString() ?? '', newPassword?.toString() ?? '');
      setIsOpenBackdrop(false);
      if (result === 200) {
        toast.success(t('users.editPassword.popup.save.success'));
        return true;
      } else {
        toast.error(t('account.password.badUserPassword'));
        return false;
      }
    },
    [setAllData, changePassword, setIsOpenBackdrop, t],
  );

  const assignName = (myString: string | null | undefined) => {
    switch (typeof myString) {
      case 'string':
        if (myString === '') {
          return t('common.message.unknowData');
        } else return myString;
      case 'undefined':
        return t('common.message.notFoundData');
      default:
        return t('common.message.unknowData');
    }
  };

  return {
    assignName,
    isOpenBackdrop,
    toggleIsUpdatePasswordFormOpen,
    isUpdatePasswordFormOpen,
    handleConfirm,
    allData,
  };
};

export default useData;
