import { Box } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Backdrop, CircularProgress } from '@mui/material';
import MenuWrapper from '../../shared/MenuWrapper';
import AccountContent from './AccountContent';
import AccountHeader from './AccountHeader';
import UpdatePasswordDialog from './UpdatePasswordForm';
import useData from './hook';

const MyAccount = () => {
  const { t } = useTranslation();
  const { toggleIsUpdatePasswordFormOpen, isOpenBackdrop, isUpdatePasswordFormOpen, handleConfirm } = useData();

  return (
    <MenuWrapper>
      <Box>
        <Box className="account-main" paddingX={{ xs: '30px', md: '90px' }} paddingBottom="30px">
          <AccountHeader t={t} />
          <AccountContent t={t} toggleIsUpdatePasswordFormOpen={toggleIsUpdatePasswordFormOpen} />
        </Box>
        <UpdatePasswordDialog
          open={isUpdatePasswordFormOpen}
          handleConfirm={handleConfirm}
          toggleIsOpen={toggleIsUpdatePasswordFormOpen}
        />
        <Backdrop open={isOpenBackdrop}>
          <CircularProgress />
        </Backdrop>
      </Box>
    </MenuWrapper>
  );
};

export default MyAccount;
