import { Box, Dialog } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import useSupportedLanguages from '../../Ressources/useSupportedLanguages';
import { CreateSharedListRequest } from '../services/requests';
import useSharedListsServices from '../services';
import { defaultCreateSharedListRequest } from './const';
import { CreateSharedListDialogProps } from './types';
import QCTextInput from '70.quickConnect.Components/siteAdmin/Inputs/QCTextInput';
import { SharedListType } from '90.quickConnect.Models/siteAdmin/enums/sharedList/sharedListType';
import QCSwitchSelect from '70.quickConnect.Components/siteAdmin/Inputs/QCSwitchSelect';
import { capitalize } from '80.quickConnect.core/shared/helpers/capitalize';
import QCSelect from '70.quickConnect.Components/siteAdmin/Inputs/QCSelect';
import QCButtonGlobal from '70.quickConnect.Components/siteAdmin/Buttons/QCButtonGlobal';

const CreateSharedListDialog = ({ open, setOpen }: CreateSharedListDialogProps) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { createSharedList } = useSharedListsServices();
  const [newSharedList, setNewSharedList] = useState<CreateSharedListRequest>(defaultCreateSharedListRequest);
  const { supportedLanguages, languageValue } = useSupportedLanguages(newSharedList.defaultLanguage);

  const handleCreateSharedList = async () => {
    const data = await createSharedList(newSharedList);
    if (data) {
      setOpen(false);
      navigate(`/sharedlists/${data.sharedList.id}`);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          minWidth: '200px',
          maxWidth: '450px',
          paddingX: '20px',
          paddingY: '20px',
          width: '20vw',
          gap: '10px',
        }}
      >
        <QCTextInput
          label={t('sharedList.sharedLists.table.name')}
          value={newSharedList.name}
          dispatch={(value: string) => {
            const newPageSharedList: CreateSharedListRequest = { ...newSharedList };
            newPageSharedList.name = value;
            setNewSharedList(newPageSharedList);
          }}
        />
        <QCTextInput
          label={t('sharedList.sharedLists.table.description')}
          value={newSharedList.description}
          dispatch={(value: string) => {
            const newPageSharedList: CreateSharedListRequest = { ...newSharedList };
            newPageSharedList.description = value;
            setNewSharedList(newPageSharedList);
          }}
        />
        <QCSwitchSelect
          label={t('sharedList.sharedLists.table.type')}
          text1={t('sharedList.designer.form.type.simple')}
          text2={t('sharedList.designer.form.type.hierarchical')}
          value1="simple"
          value2="hierarchique"
          dispatch={(value: string) => {
            if (value !== 'simple' && value !== 'hierarchique') return;
            const newPageSharedList: CreateSharedListRequest = { ...newSharedList };
            newPageSharedList.listType = value === 'simple' ? SharedListType.Simple : SharedListType.Hierarchical;
            setNewSharedList(newPageSharedList);
          }}
          option={newSharedList.listType === SharedListType.Hierarchical ? 'hierarchique' : 'simple'}
          variant="outlined"
          sx={{
            marginTop: '4.69px',
            ' .innerBox': {
              height: '56px',
            },
          }}
        />
        <QCSelect
          label={t('common.internationalization.defaultLanguage')}
          options={supportedLanguages.map((lg) => capitalize(lg.lang))}
          value={languageValue}
          defaultValue={languageValue}
          setOption={(value: string) => {
            const newLang = supportedLanguages.find((el) => capitalize(el.lang) === value);
            if (newLang) {
              const newPageSharedList: CreateSharedListRequest = { ...newSharedList };
              newPageSharedList.defaultLanguage = newLang.value;
              setNewSharedList(newPageSharedList);
            }
          }}
        />
        <Box
          sx={{
            marginTop: '20px',
            display: 'flex',
            gap: '10px',
            width: '100%',
            justifyContent: 'space-around',
            alignContent: 'center',
          }}
        >
          <QCButtonGlobal
            className="add-sharedlist-button"
            value="Ajouter"
            variant="contained"
            sx={{ flexGrow: 1 }}
            action={handleCreateSharedList}
          />
          <QCButtonGlobal
            className="cancel-add-sharedlist-button"
            value="Annuler"
            variant="outlined"
            sx={{ flexGrow: 1 }}
            action={() => setOpen(false)}
          />
        </Box>
      </Box>
    </Dialog>
  );
};

export default CreateSharedListDialog;
