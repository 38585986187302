import { RawAxiosRequestHeaders } from 'axios';
import { FormLibVersion } from 'const';
import { getSessionId } from '80.quickConnect.core/shared/helpers';

export const baseHeaders = {
  'QC-CompanionId': process.env.REACT_APP_QC_COMPANION_ID ?? '',
};

export const declarationBaseHeader: RawAxiosRequestHeaders = {
  ...baseHeaders,
  'QC-LibVersion': FormLibVersion,
  'QC-SessionId': getSessionId(),
};
