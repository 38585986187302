import { Box, IconButton, Typography } from '@mui/material';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import React, { useCallback, useMemo } from 'react';
import { ImgStyle } from './styles';

export const ReadOnlyFileField = ({ file }: { file: any }) => {
  const { distantUri, fileName, type } = useMemo(() => ({ ...file }), [file]);

  const handleClick = useCallback(() => {
    window.open(distantUri);
  }, [distantUri]);

  return (
    <Box
      sx={{
        border: '2px dashed #B39875',
        borderRadius: '7px',
        backgroundColor: '#FAF5EB',
        width: '40vw',
        height: 'fit-content',
        p: 1,
        m: 2,
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'flex-start',
        }}
      >
        {type.includes('image') && <img src={distantUri} style={ImgStyle} onClick={handleClick} />}
        {type.includes('application') ||
          (type.includes('text') && (
            <IconButton onClick={handleClick}>
              <AttachFileIcon />
            </IconButton>
          ))}
        {}
        <Typography sx={{}}>{fileName}</Typography>
      </Box>
    </Box>
  );
};
