import React from 'react';
import { GridFooter, GridFooterContainer } from '@mui/x-data-grid';

const CustomFooter = () => {
  return (
    <GridFooterContainer
      sx={{ display: 'flex', flexDirection: 'column', flex: 1, justifyContent: 'flex-end', alignItems: 'flex-end' }}
    >
      <GridFooter />
    </GridFooterContainer>
  );
};

export default CustomFooter;
