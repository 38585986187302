import React from 'react';
import { Button, styled, SxProps } from '@mui/material';
import { QCButtonGlobalProps } from './type';
import { grey3 } from '00.app/theme/colors';

const QCButtonGlobalSkeleton = ({
  value,
  datacy,
  startIcon,
  endIcon,
  action,
  className,
  disable,
}: QCButtonGlobalProps) => {
  // Ce Bouton a:

  // - 3 props facultatives :
  //     -> value: string (Le texte du Bouton)
  //     -> startIcon: MuiIcon (L’icône à gauche du texte)
  //     -> endIcon: MuiIcon (L’icône à droite du texte)

  // - une props action qui sera la logique du bouton
  // - une props variant qui permettra de déterminer le style du bouton :
  //     -> text : Pour les liens
  //     -> contained: Pour les boutons de type "filled"
  //     -> outlined: Pour les boutons de type "outlined"
  return (
    <Button
      startIcon={startIcon}
      data-cy={datacy}
      endIcon={endIcon}
      onClick={action}
      className={className}
      disabled={disable}
    >
      {value}
    </Button>
  );
};

const QCButtonGlobal = styled(QCButtonGlobalSkeleton)(({ theme, variant, disable }) => {
  const { palette } = theme;
  const iconContainedColor = palette.iconContainedColor.main;
  const iconContainedHoverColor = palette.iconContainedHoverColor.main;
  const iconOutlinedHoverColor = palette.iconOutlinedHoverColor.main;
  const containedTextColor = palette.containedTextColor.main;
  const lightThemeTextColor = palette.textLink.main;

  switch (variant) {
    case 'text':
      return {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        whiteSpace: 'nowrap',
        minWidth: 'max-content',
        alignItems: 'center',
        padding: '8px 10px',
        fontWeight: 'bold',

        border: 'none',
        color: lightThemeTextColor,
        background: 'none',

        '&:hover': {
          color: iconContainedColor,
          background: 'none',
        } as SxProps,
      };
    case 'outlined':
      return {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '8px 10px',
        fontWeight: 'bold',

        border: `2px solid `,
        borderRadius: '8px',

        borderColor: disable ? grey3 : iconContainedColor,
        color: iconContainedColor,

        '&:hover': {
          border: `2px solid `,
          borderColor: iconContainedColor,
          backgroundColor: iconOutlinedHoverColor,
        } as SxProps,
      };
    default:
      return {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '8px 10px',
        fontWeight: 'bold',
        // whiteSpace: 'nowrap',
        minWidth: 'max-content',

        border: `2px solid `,
        borderRadius: '8px',

        backgroundColor: iconContainedColor,

        color: containedTextColor,
        borderColor: iconContainedColor,

        '&:hover': {
          borderColor: iconContainedHoverColor,
          backgroundColor: iconContainedHoverColor,
        } as SxProps,
      };
  }
});

export default QCButtonGlobal;
