import { PaletteMode } from '@mui/material';
import { action, makeAutoObservable, observable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import RootStore from '..';
import { QuickConnectRoles } from '90.quickConnect.Models/siteAdmin/enums/user/quickConnectRoles';
import CustomLogger from '80.quickConnect.core/siteAdmin/logger/customLogger';
import { SubscribedFeaturesType } from '90.quickConnect.Models/siteAdmin/models/features/features';
import { WidgetType } from '70.quickConnect.Components/siteAdmin/Other/HomeWidget/types';

class UserSettingsStore {
  logger: CustomLogger;

  muiMode: PaletteMode = 'light';

  role = QuickConnectRoles.Anonymous;

  RootStore: RootStore;

  subscribedFeatures: SubscribedFeaturesType = {
    'FEAT-001': false,
    'FEAT-002': false,
    'FEAT-003': false,
    'FEAT-004': false,
    'FEAT-005': false,
    'FEAT-006': false,
    'FEAT-007': false,
  };

  defaultsHomeWidgetTypes: WidgetType[];

  constructor(rootStore: RootStore, logger: CustomLogger, storageKey: string) {
    this.RootStore = rootStore;
    this.logger = logger;
    this.defaultsHomeWidgetTypes = ['forms', 'alerts'];
    makeAutoObservable(
      this,
      {
        muiMode: observable,
        role: observable,
        subscribedFeatures: observable,
        defaultsHomeWidgetTypes: observable,

        switchMuiMode: action,
        setRole: action,
        setSubscribedFeatures: action,
        setDefaultsHomeWidgetTypes: action,
      },
      { autoBind: true },
    );
    void makePersistable(this, {
      name: storageKey,
      properties: ['muiMode', 'defaultsHomeWidgetTypes'],
      storage: window.localStorage,
    });
  }

  resetStore = (): void => {
    this.muiMode = 'light';
    this.setRole(QuickConnectRoles.Anonymous);
  };

  switchMuiMode = (): void => {
    this.muiMode = this.muiMode === 'dark' ? 'light' : 'dark';
  };

  setRole = (role: QuickConnectRoles): void => {
    console.error('nouveau role : ' + role);
    this.role = role;
  };

  setSubscribedFeatures = (subscribedFeatures: SubscribedFeaturesType) => {
    this.subscribedFeatures = { ...subscribedFeatures };
  };

  setDefaultsHomeWidgetTypes = (widgetType: WidgetType, index: 0 | 1) => {
    this.defaultsHomeWidgetTypes[index] = widgetType;
  };
}

export default UserSettingsStore;
