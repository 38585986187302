import React from 'react';
import { Box, Button, Dialog } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { DeleteConfirmationProps, DeleteActionType } from './type';

// Flexible pour la majorité des appareils sauf pour ceux avec largeur très petite et tablettes largeur grande
// Ex : Galaxy Fold & Surface Pro 7

const DeleteConfirmationDialog = ({
  open,
  dispatch,
  trashAvailable = false,
  title = 'uicomponents.deleteConfirmationDialog.Text',
}: DeleteConfirmationProps) => {
  const { t } = useTranslation();

  const elementWidth = 'fit-parent';

  const handleClickDelete = () => {
    dispatch({ type: DeleteActionType.Delete });
  };

  const handleClickCancel = () => {
    dispatch({ type: DeleteActionType.Cancel });
  };

  const handleClickTrash = () => {
    dispatch({ type: DeleteActionType.SendToTrash });
  };

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          width: '387px',
          height: '286px',

          backgroundColor: 'background',

          border: '2px solid #E3F2F0',
          borderRadius: '16px',
        },
      }}
    >
      <Box
        sx={{
          // width: elementWidth,
          // maxWidth: elementWidth,
          textAlign: 'center',
          marginTop: trashAvailable ? 0 : '2vh',
          marginBottom: trashAvailable ? '0.6vh' : '2vh',
          marginX: '2vh',
        }}
      >
        <h2>{t(title)}</h2>
      </Box>

      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            gap: 2,
          }}
        >
          <Button
            onClick={handleClickDelete}
            variant="contained"
            sx={{
              fontWeight: 'bold',
              width: elementWidth,
              minWidth: 'fit-content',
            }}
          >
            {t('uicomponents.deleteConfirmationDialog.Delete')}
          </Button>
          {trashAvailable && (
            <Button
              onClick={handleClickTrash}
              startIcon={<DeleteIcon />}
              variant="contained"
              sx={{
                fontWeight: 'bold',
                width: elementWidth,
                minWidth: 'fit-content',
              }}
            >
              {t('bin.viewer.title')}
            </Button>
          )}
          <Button
            onClick={handleClickCancel}
            variant="outlined"
            sx={{
              width: elementWidth,
              minWidth: 'fit-content',
            }}
          >
            {t('uicomponents.deleteConfirmationDialog.Cancel')}
          </Button>
        </Box>
      </Box>
    </Dialog>
  );
};

export default DeleteConfirmationDialog;
