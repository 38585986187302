import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Dialog, Button } from '@mui/material';
import {} from '@mui/system';
import { ResetActionType, ResetConfirmationProps } from './types';

const ResetConfirmationDialog = ({ open, dispatch }: ResetConfirmationProps) => {
  const { t } = useTranslation();

  const handleClickReset = () => {
    dispatch({ type: ResetActionType.Reset });
  };

  const handleClickCancel = () => {
    dispatch({ type: ResetActionType.Cancel });
  };
  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          width: '387px',
          height: '286px',
          backgroundColor: 'background',
          border: '2px solid #E3F2F0',
          borderRadius: '16px',
        },
      }}
    >
      <Box
        sx={{
          // width: elementWidth,
          // maxWidth: elementWidth,
          textAlign: 'center',
          marginTop: '2vh',
          // marginBottom: '2vh',
          marginX: '4vh',
          //   textJustify: 'inter-word',
        }}
      >
        <h2>{t('internationalization.popup.discardChanges.confirm')}</h2>
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2,
          width: '100%',
        }}
      >
        <Button
          onClick={handleClickReset}
          variant="contained"
          sx={{
            fontWeight: 'bold',
            width: '50%',
            minWidth: 'fit-content',
          }}
        >
          {t('common.message.yes')}
        </Button>

        <Button
          onClick={handleClickCancel}
          variant="outlined"
          sx={{
            width: '50%',
            minWidth: 'fit-content',
          }}
        >
          {t('common.message.no')}
        </Button>
      </Box>
    </Dialog>
  );
};

export default ResetConfirmationDialog;
