import { Box, FormControlLabel, Radio, RadioGroup } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const ReadOnlyBoolField = ({ value }: { value: number | boolean }) => {
  const { t } = useTranslation();
  return (
    <Box sx={{ my: 2 }}>
      <RadioGroup row value={!!value}>
        <FormControlLabel value={true} control={<Radio />} label={t('common.message.yes')} />
        <FormControlLabel value={false} control={<Radio />} label={t('common.message.no')} />
      </RadioGroup>
    </Box>
  );
};
