import React, { useCallback, useMemo } from 'react';
import { EditedData } from '../../services/responses';
import { ReadOnlyTextField } from './ReadOnlyTextField';
import { ReadOnlyBoolField } from './ReadOnlyBoolField';
import { ReadOnlyFileField } from './ReadOnlyFileField';
import { FieldTypeText } from '90.quickConnect.Models/siteAdmin/enums/fileType';

const useData = (data: EditedData) => {
  const { fieldType, value } = useMemo(() => {
    return { ...data };
  }, [data]);

  const getField = useCallback(() => {
    //TODO : finir les composants pour les types restants

    switch (fieldType.toLowerCase()) {
      case FieldTypeText.Text.toLowerCase():
      case FieldTypeText.Label.toLowerCase():
      case FieldTypeText.Numeric.toLowerCase():
      case FieldTypeText.Digits.toLowerCase():
      case FieldTypeText.RfidReader.toLowerCase():
      case FieldTypeText.ReadOnlyValue.toLowerCase():
      case FieldTypeText.Slider.toLowerCase():
      case FieldTypeText.Counter.toLowerCase():
        return <ReadOnlyTextField value={value} />;
      case FieldTypeText.Time.toLowerCase():
        return <ReadOnlyTextField type="time" value={String(value).slice(11, 19)} />;
      case FieldTypeText.DateTime.toLowerCase():
        return <ReadOnlyTextField type="datetime-local" value={String(value).slice(0, 16)} />;
      case FieldTypeText.CheckBox.toLowerCase():
        return <ReadOnlyBoolField value={value} />;
      case FieldTypeText.Address.toLowerCase():
        const {
          streetNumber = '',
          street = '',
          complement = '',
          zipCode = '',
          city = '',
          country = '',
          coordinates = '',
        } = { ...value };
        return (
          <ReadOnlyTextField
            multiline
            value={
              streetNumber +
              ' ' +
              street +
              ', ' +
              complement +
              '\n' +
              zipCode +
              ', ' +
              city +
              ', ' +
              country +
              ', ' +
              coordinates
            }
          />
        );
      case FieldTypeText.Attachment.toLowerCase():
      case FieldTypeText.Photo.toLowerCase():
      case FieldTypeText.Signature.toLowerCase():
      case FieldTypeText.Draw.toLowerCase():
      case FieldTypeText.FixedAttachment.toLowerCase():
      case FieldTypeText.FixedImage.toLowerCase():
        return Array.isArray(value) ? (
          <>
            {value.map((vl) => (
              <ReadOnlyFileField key={vl.id} file={vl} />
            ))}
          </>
        ) : (
          <ReadOnlyFileField file={value} />
        );
      case FieldTypeText.RadioList.toLowerCase():
        return <ReadOnlyTextField value={value?.label} />;
      default:
        return <ReadOnlyTextField value={'default value'} />;
    }
  }, [fieldType, value]);

  return {
    getField,
  };
};

export default useData;
