import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { OrganizationRowAction } from './actions';
import useServices from './services/organization';
import { Organization } from './types';
import { CreateOrganizationRequest } from './services/requests';
import { useStore } from '30.quickConnect.Store/siteAdmin';
import { organizationalUnitsParser } from '80.quickConnect.core/siteAdmin/helpers/organizationalUnitsParser';
import { DeleteActionType } from '70.quickConnect.Components/siteAdmin/Other/DeleteConfirmationDialog/type';
import { AllDataElementType } from '70.quickConnect.Components/siteAdmin/Other/QCDialogForm/types';
import useEffectOnce from '80.quickConnect.core/shared/hooks/useEffectOnce';
import { OrganizationIcon } from '00.app/icons/OrganizationIcon';
import { useDeleteDialog } from '80.quickConnect.core/siteAdmin/hooks/useDeleteDialog';
import { useSafeNavigation } from '80.quickConnect.core/siteAdmin/hooks/useSafeNavigation';

const useUsersData = () => {
  const {
    SubMenuStore: {
      switchIsVisible,
      setRows,
      setSubMenuPath,
      subMenuPath,
      setRouteBase,
      setRowActions,
      selectedRowAction,
      setSelectedRowAction,
      setAddIcon,
      setHandleAddClick,
      setSortingTypes,
      setAddText,
      setMultipleActions,
    },
    OrganizationalUnitStore: { setallOUList },
  } = useStore();

  const { getOrganizationalUnitList, createOrganizationalUnit, deleteOrganization } = useServices();
  const [isAddUserDialogOpen, setIsAddUserDialogOpen] = useState<boolean>(false);
  const [isAddOUDialogOpen, setIsAddOUDialogOpen] = useState(false);
  const { t } = useTranslation();
  const navigate = useSafeNavigation();

  const [allOU, setAllOU] = useState<Organization[]>([]);

  const initOrganizationalUnitList = useCallback(async () => {
    const ret = await getOrganizationalUnitList();
    setallOUList(ret.organizationalUnits);
    const rows = organizationalUnitsParser(ret.organizationalUnits);
    setSubMenuPath([
      {
        id: 'root',
        name: t('setting.settings.tabs.users'),
        rows: rows,
      },
    ]);
    setRows(rows);
    setRouteBase('/users/organization');
    setRowActions(Object.values(OrganizationRowAction));
  }, [getOrganizationalUnitList, setRows, setSubMenuPath, t, setRouteBase, setRowActions, setallOUList]);

  const [openDeleteDialog, setOpenDeleteDialog, deleteState, deleteDispatch] = useDeleteDialog();

  useEffectOnce(() => {
    setAddIcon(<OrganizationIcon />);
    setHandleAddClick(() => setIsAddOUDialogOpen(true));
    setSortingTypes([
      t('submenu.sortingType.alphabetic'),
      t('submenu.sortingType.type'),
      t('submenu.sortingType.favourite'),
    ]);
    setAddText(t('organization.organizations.actions.add'));
    setMultipleActions([{ text: t('submenu.multipleAction.delete') }, { text: t('submenu.multipleAction.print') }]);
  });

  useEffect(() => {
    if (!selectedRowAction) return;
    switch (selectedRowAction.option) {
      case t(OrganizationRowAction.Delete):
        setOpenDeleteDialog(true);
        break;
      case t(OrganizationRowAction.Settings):
        navigate(`/users/organization/${selectedRowAction.rowId}/settings`);
        setSelectedRowAction(undefined);
        break;
      default:
        break;
    }
    //eslint-disable-next-line
  }, [selectedRowAction]);

  useEffect(() => {
    switch (deleteState.choice) {
      case DeleteActionType.Delete:
        setOpenDeleteDialog(false);
        if (selectedRowAction && selectedRowAction.rowId) {
          deleteOrganization({ ids: [selectedRowAction.rowId] }, '');
          navigate('/users');
        }
        setSelectedRowAction(undefined);
        break;
      case DeleteActionType.Cancel:
      default:
        setOpenDeleteDialog(false);
        break;
    }
    //eslint-disable-next-line
  }, [deleteState]);

  const handleConfirmaddOUDialog = async (value: AllDataElementType[]) => {
    const OUName = value[0].data.find((elem) => elem.label === t('organization.designer.placeholder.name'))?.value;
    const OUSiret = value[0].data.find(
      (elem) => elem.label === t('organization.designer.attribut.placeholder.siret'),
    )?.value;
    const OUEmail = value[0].data.find(
      (elem) => elem.label === t('organization.designer.attribut.placeholder.email'),
    )?.value;
    const OUParent = value[0].data.find(
      (elem) => elem.label === t('organization.designer.placeholder.organization'),
    )?.value;

    // console.log(allOUList);

    const OUAddress = value[1].data.find(
      (elem) => elem.label === t('organization.designer.attribut.placeholder.address'),
    )?.value;
    const OUZipCode = value[1].data.find(
      (elem) => elem.label === t('organization.designer.attribut.placeholder.zipCode'),
    )?.value;
    const OUTown = value[1].data.find(
      (elem) => elem.label === t('organization.designer.attribut.placeholder.town'),
    )?.value;
    const OUCountry = value[1].data.find(
      (elem) => elem.label === t('organization.designer.attribut.placeholder.country'),
    )?.value;

    const OULogo = value[2].data.find((elem) => elem.label === t('organization.designer.logo.title'))?.value as File[];
    const fileReader = new FileReader();

    if (OULogo && OULogo[0]) {
      fileReader.readAsDataURL(OULogo[0]);

      fileReader.onload = async () => {
        const base64 = fileReader.result;

        const requests: CreateOrganizationRequest = {
          name: OUName?.toString() ?? '',
          siret: OUSiret?.toString() ?? '',
          email: OUEmail?.toString() ?? '',
          parentId: OUParent?.toString() ?? '',
          address: OUAddress?.toString() ?? '',
          zipCode: OUZipCode?.toString() ?? '',
          town: OUTown?.toString() ?? '',
          country: OUCountry?.toString() ?? '',
          logo: base64,
          correlationId: '',
          isActive: true,
          isHeadQuarter: false,
        };

        const response = await createOrganizationalUnit(requests);
        if (response) await initOrganizationalUnitList();
      };
    } else {
      const requests: CreateOrganizationRequest = {
        name: OUName?.toString() ?? '',
        siret: OUSiret?.toString() ?? '',
        email: OUEmail?.toString() ?? '',
        parentId: OUParent?.toString() ?? '',
        address: OUAddress?.toString() ?? '',
        zipCode: OUZipCode?.toString() ?? '',
        town: OUTown?.toString() ?? '',
        country: OUCountry?.toString() ?? '',
        logo: '',
        correlationId: '',
        isActive: true,
        isHeadQuarter: false,
      };

      const response = await createOrganizationalUnit(requests);
      if (response) await initOrganizationalUnitList();
    }
  };

  return {
    switchIsVisible,
    initOrganizationalUnitList,
    setRows,
    setSubMenuPath,
    t,
    subMenuPath,
    isAddOUDialogOpen,
    setIsAddOUDialogOpen,
    allOU,
    handleConfirmaddOUDialog,
    openDeleteDialog,
    deleteDispatch,
    setAllOU,
    isAddUserDialogOpen,
    setIsAddUserDialogOpen,
  };
};

export default useUsersData;
