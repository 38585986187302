import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';

export const AccountContentMainSxProps: SxProps = {
  display: 'flex',
  flexDirection: { xs: 'column', lg: 'row' },
  gridColumnGap: '20px',
  gridRowGap: '20px',
  gridTemplateRows: '1fr',
  '& .panel': {
    flex: '1 1 auto',
    height: 'fit-content',
  },
};

export const AccountContentCardSxProps = (theme: Theme): SxProps => ({
  display: 'flex',
  flexDirection: 'column',
  border: `2px solid ${theme.palette.menuBorder.main}`,
  borderRadius: '8px',
  paddingX: { xs: '15px', lg: '30px' },
  paddingBottom: '30px',
  boxShadow: 'none',
  gap: '10px',
  '& .contact-content': {
    display: 'flex',
    flexDirection: { xs: 'column', lg: 'row' },
    '& .MuiBox-root': {
      flex: '1 1 0',
    },
  },
});

export const AccountContentPasswordSxProps: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  fontWeight: 'bold',
  '& .password-form': {
    marginTop: '10px',
    marginLeft: 0,
    width: 'fit-content',
  },
};
