import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import QCButtonGlobal from '../QCButtonGlobal';
import useData from './hooks';

const LogoutButton = () => {
  const { onLogOut } = useData();
  const { t } = useTranslation();
  return (
    <Box>
      <QCButtonGlobal
        className="LogoutButton"
        variant="outlined"
        value={t('header.tooltip.disconnect')}
        action={onLogOut}
      />
    </Box>
  );
};

export default LogoutButton;
