import React from 'react';
import useData from './hooks';
import QCTreeViewAutocomplete from '70.quickConnect.Components/siteAdmin/Inputs/QCTreeViewAutocomplete';
import { QCTreeViewAutocompleteProps } from '70.quickConnect.Components/siteAdmin/Inputs/QCTreeViewAutocomplete/type';

const QCDialogFormTreeViewAutocomplete = ({
  label,
  mandatory,
  info,
  allData,
}: QCTreeViewAutocompleteProps & { label: string }) => {
  const { handleChange, defaultOption } = useData(label, mandatory);
  return (
    <QCTreeViewAutocomplete
      label={label}
      dispatch={handleChange}
      mandatory={mandatory}
      className="dialog-autocomplete"
      info={info}
      value={defaultOption}
      allData={allData}
    />
  );
};

export default QCDialogFormTreeViewAutocomplete;
