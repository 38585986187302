import React from 'react';
import { observer } from 'mobx-react';

import { useTranslation } from 'react-i18next';
import { Box, Divider, Drawer, useTheme } from '@mui/material';
import { adminNavElements, dataNavElements, headerNavElements, navElements } from '../../const';
import { ButtonsBoxStyles, buttonStyles, MenuBoxStyle, OpenedMenuBoxStyle } from '../../SideMenu/styles';
import QCMenuCollapse from '../../SideMenu/Collapses';
import QCMenuButton from '../../SideMenu/Buttons';
import { useData } from './hooks';
import { DrawerStyles } from './styles';
import { PlusMenuProps } from './types';
import QCButtonGlobal from '70.quickConnect.Components/siteAdmin/Buttons/QCButtonGlobal';
import QCFabCorbeille from '70.quickConnect.Components/siteAdmin/Buttons/QCFabCorbeille';
import { BIN } from '10.quickConnect.app/siteAdmin/routes/paths';
import { QCLogoM } from '00.app/icons/QCLogoIcon';
import { pureWhiteColor } from '00.app/theme/colors';
import { getRoleValue } from '80.quickConnect.core/siteAdmin/helpers/getRoleValue';
import { Features } from '90.quickConnect.Models/siteAdmin/enums/features/features';
import { useSafeNavigation } from '80.quickConnect.core/siteAdmin/hooks/useSafeNavigation';

const PlusMenu = ({ open, setAnchorEl }: PlusMenuProps) => {
  const { t } = useTranslation();
  const navigate = useSafeNavigation();

  const theme = useTheme();
  const { focusedBtn, setFocusedBtn, role, subscribedFeatures } = useData();

  return (
    <Drawer anchor="right" open={open} onClose={() => setAnchorEl(null)} sx={DrawerStyles}>
      <Box sx={MenuBoxStyle}>
        <Box sx={OpenedMenuBoxStyle}>
          <QCLogoM />
        </Box>
        <Box sx={ButtonsBoxStyles}>
          {navElements.map((navEl, index: number) => {
            if (
              ['scan', 'data', 'admin'].includes(navEl.name) &&
              getRoleValue(role) >= getRoleValue(navEl.authorizationRole)
            ) {
              return !navEl.hasSubMenu ? (
                <QCMenuButton
                  key={index}
                  text={navEl.name}
                  icon={navEl.Icon}
                  route={navEl.path}
                  isOpenSideMenu={true}
                />
              ) : (
                <QCMenuCollapse
                  key={index}
                  text={navEl.name}
                  icon={navEl.Icon}
                  routes={navEl.name == 'data' ? dataNavElements : adminNavElements}
                  isOpenSideMenu={true}
                />
              );
            }
          })}
          <Divider
            sx={{ display: 'flex', alignSelf: 'center', borderColor: pureWhiteColor, width: '78%' }}
            variant="middle"
          />
          {headerNavElements.map((navEl, index: number) => {
            if (!['activities'].includes(navEl.name)) {
              return (
                <QCMenuButton
                  key={index}
                  text={navEl.name}
                  icon={navEl.Icon}
                  route={navEl.path}
                  isOpenSideMenu={true}
                />
              );
            }
          })}
        </Box>
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          {subscribedFeatures[Features.Bin] && (
            <QCFabCorbeille
              buttonType="btn-icon"
              onClickFunc={() => {
                setFocusedBtn('bin');
                navigate(BIN);
              }}
              sx={focusedBtn == 'bin' ? buttonStyles(theme, true, true) : buttonStyles(theme, false, true)}
            />
          )}
          <QCButtonGlobal
            className="QuickConnect Version"
            variant="text"
            value={t('navigation.version', { number: '0.9.8' })}
            sx={{ fontSize: '100%', fontWeight: 'normal', color: pureWhiteColor, textTransform: 'none' }}
          />
        </Box>
      </Box>
    </Drawer>
  );
};

export default observer(PlusMenu);
