import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Collapse, List, Stack, Box, useTheme } from '@mui/material';
import FolderOutlinedIcon from '@mui/icons-material/FolderOutlined';
import { ListItemContainerStyle, ListItemNameStyle, IconAndNameStyle } from '../ListItemStyles';
import { SubMenuContext } from '../const';
import { renderSubMenuRow } from '../help';
import { SubMenuListFolderProps } from './types';
import QCKebabMenu from '70.quickConnect.Components/siteAdmin/Other/QCKebabMenu';

/**
 * Composant pour le list item de type folder du sous-menu
 * Utilise le contexte du sous-menu.
 * N'a pas de checkbox pour les actions multiples
 * @component
 * @prop {string} id
 * @prop {string} name
 * @prop {number} childrenCount - N'est affiché que si supérieur à zéro
 * @prop {SubMenuRow[]} childrenItems
 */
const SubMenuListFolder = ({ id, name, childrenCount, childrenItems }: SubMenuListFolderProps) => {
  const { rowActions, onRowClicked, openedFolders, setOpenedFolders, setSelectedRowAction, selectedRowsId } =
    useContext(SubMenuContext);

  const theme = useTheme();

  const [selectedOption, setSelectOption] = useState('');
  const [isKebabMenuOpen, setIsKebabMenuOpen] = useState(false);

  const [openCollapse, setOpenCollapse] = useState(openedFolders.includes(id));

  const hasChildren = useMemo(() => {
    return !!childrenItems && childrenItems.length !== 0;
  }, [childrenItems]);

  useEffect(() => {
    if (rowActions?.map((mappedAction) => mappedAction.text).includes(selectedOption)) {
      setSelectedRowAction({ option: selectedOption, rowId: id });
      setIsKebabMenuOpen(false);
    }
  }, [setSelectedRowAction, selectedOption, id, rowActions]);

  const handleRowClick = () => {
    if (hasChildren) {
      if (openedFolders.includes(id)) {
        setOpenedFolders((curr) => {
          const newCurr = Array.from(curr);
          newCurr.splice(newCurr.indexOf(id), 1);
          return newCurr;
        });
        setOpenCollapse(true);
      } else {
        setOpenedFolders((curr) => {
          const newCurr = Array.from(curr);
          newCurr.push(id);
          return newCurr;
        });
        setOpenCollapse(false);
      }
    }
    // // onRowClicked permet de récupérer les enfants, donc s'il y en a déjà on ne l'appelle pas
    else if (onRowClicked) {
      onRowClicked(id);

      // on ouvre le collapse
      setOpenedFolders((curr) => {
        const newCurr = Array.from(curr);
        newCurr.push(id);
        return newCurr;
      });
      setOpenCollapse(true);
    }
  };

  return (
    <Stack direction="column" gap={0}>
      <Box sx={ListItemContainerStyle(theme, isKebabMenuOpen)}>
        <Box sx={IconAndNameStyle} onClick={handleRowClick} data-cy={name}>
          {<FolderOutlinedIcon />}
          <Box sx={ListItemNameStyle}>{name}</Box>
          {childrenCount !== 0 && <Box sx={{ color: '#91E3D4', fontSize: '0.875em' }}>{childrenCount}</Box>}
        </Box>

        {rowActions && (
          <Box className="kebabMenu">
            <QCKebabMenu
              size="small"
              options={rowActions}
              dispatch={setSelectOption}
              setIsMenuOpen={setIsKebabMenuOpen}
            />
          </Box>
        )}
      </Box>

      {/* <Collapse in={openCollapse}> */}
      <Collapse in={openCollapse} sx={{ marginLeft: '10px' }}>
        {hasChildren && (
          <>
            <List
              sx={{
                ':last-child': {
                  ':before': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    top: '5px',
                    height: '10px',
                    left: 0,
                    border: '2px solid',
                    borderColor: theme.palette.background.default,
                  },
                  ':after': {
                    content: '""',
                    display: 'block',
                    position: 'absolute',
                    bottom: '5px',
                    height: '20px',
                    left: 0,
                    border: '2px solid',
                    borderColor: theme.palette.background.default,
                  },
                },
              }}
            >
              {childrenItems?.map((el) => (
                <Box key={el.id} borderLeft="solid 1px" borderColor={theme.palette.grey[300]} sx={{}}>
                  {renderSubMenuRow(el, selectedRowsId.includes(el.id))}
                </Box>
              ))}
            </List>
            {/* <List>
              <Divider variant="inset" sx={{ width: '80%', marginLeft: '10px', borderBottomWidth: 2 }} />
              {childrenItems?.map((el) => (
                <>{renderSubMenuRow(el, selectedRowsId.includes(el.id))}</>
              ))}
            </List> */}
          </>
        )}
      </Collapse>
    </Stack>
  );
};

export default SubMenuListFolder;
