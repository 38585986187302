import React, { ChangeEvent, useCallback } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { GridColumns, GridRowId, GridValidRowModel } from '@mui/x-data-grid';
import { Box, CircularProgress, Typography } from '@mui/material';
import useDocServices from '../../services/documents';
import { EquipmentIndexFilesRequest, StorageDocRequest } from '../../services/requests';
import { APP_NAME_STORAGE, DocMultiplesActions, DocRowActions, FieldNames } from './const';
import { InputFileType } from './types';
import { transformFileSize } from './helpers';
import { DeleteActionType } from '70.quickConnect.Components/siteAdmin/Other/DeleteConfirmationDialog/type';
import { useDeleteDialog } from '80.quickConnect.core/siteAdmin/hooks/useDeleteDialog';
import { DocumentQC } from '90.quickConnect.Models/siteAdmin/models/common/qcDoc';
import { CommonFileState } from '90.quickConnect.Models/siteAdmin/enums/commonFileState';
import { Guid } from '80.quickConnect.core/siteAdmin/helpers/generateUid';

const useData = (documents: DocumentQC[]) => {
  const { entityId, equipmentId } = useParams();
  const { t } = useTranslation();
  const { sendDocsRequest, deleteDocsRequest, uploadDoc } = useDocServices();
  const [rows, setRows] = useState<GridValidRowModel[]>([]);
  const [inputFiles, setInputFiles] = useState<InputFileType[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const [rowAction, setRowAction] = useState<{ option: string; rowId: GridRowId }>({ option: '', rowId: '' });
  const [rowsAction, setRowsAction] = useState<{ option: string; rowIds: GridRowId[] }>({ option: '', rowIds: [] });

  useEffect(() => {
    setInputFiles(documents);
  }, [documents]);

  const readFile = (id: GridRowId) => {
    const theFile = inputFiles.find((fl) => fl.id === (id as string));
    if (theFile && theFile.storageUri) window.open(theFile.storageUri, '_blank', 'noopener,noreferrer');
    else if (theFile && theFile.file) {
      const blob = new Blob([theFile.file], { type: theFile.file.type });
      const fileUrl = URL.createObjectURL(blob);
      window.open(decodeURIComponent(fileUrl), '_blank', 'noopener,noreferrer');
      setTimeout(function () {
        URL.revokeObjectURL(fileUrl);
      }, 3000);
    }
  };

  const columns: GridColumns = useMemo(
    () => [
      {
        field: FieldNames.DocName,
        headerName: t('shared.downloadManager.table.name'),
        flex: 2,
      },
      {
        field: FieldNames.DocSize,
        headerName: t('shared.downloadManager.table.size'),
        flex: 1.5,
        renderCell: (params) => <Typography>{transformFileSize(params.formattedValue)}</Typography>,
      },
      {
        field: FieldNames.Status,
        headerName: t('shared.downloadManager.table.status'),
        flex: 1.5,
        renderCell: (params) => {
          switch (params.formattedValue) {
            case CommonFileState.unload:
              return <Typography>{t('core.helpers.helpers.fileState.unload')}</Typography>;
            case CommonFileState.loaded:
              return <Typography>{t('core.helpers.helpers.fileState.loaded')}</Typography>;
            case CommonFileState.loading:
              return (
                <Box>
                  <Typography>{t('core.helpers.helpers.fileState.loading')}</Typography>
                  <CircularProgress color="inherit" size={20} />
                </Box>
              );
            case CommonFileState.loadingFailed:
              return <Typography>{t('core.helpers.helpers.fileState.loadingFailed')}</Typography>;
            case CommonFileState.processing:
              return (
                <Box>
                  <Typography>{t('core.helpers.helpers.fileState.processing')}</Typography>
                  <CircularProgress color="inherit" size={20} />
                </Box>
              );
          }
        },
      },
    ],
    [t],
  );

  const [openDeleteDialog, setOpenDeleteDialog, deleteState, deleteDispatch] = useDeleteDialog();

  const handleSendDocs = useCallback(
    async (ids: string | string[]) => {
      const file = inputFiles.filter((el) => ids.includes(el.id));
      const req = {
        schemaId: entityId,
        instanceId: equipmentId,
        indexes: file.map((fl) => {
          return {
            docName: fl?.name,
            docTitle: fl?.title,
            docContentType: fl?.type,
            correlationId: fl?.documentId,
            applicationName: APP_NAME_STORAGE,
          };
        }),
      } as EquipmentIndexFilesRequest;
      const response = await sendDocsRequest(req);
      if (response && file.length > 0) {
        for (let i = 0; i < file.length; i++) {
          const request = {
            fileContent: file[i].file,
          } as StorageDocRequest;
          setInputFiles((prev) => {
            const changed = [...prev];
            changed.forEach((f) => {
              if (ids.includes(f.id)) f.state = CommonFileState.loading;
            });
            return changed;
          });
          const res = await uploadDoc(request, response.storagesInfos[i].uri, file[i], setInputFiles);
          if (res) {
            setInputFiles((prev) => {
              const changed = [...prev];
              const newDocId = response.storagesInfos[i].uri.split('/');
              changed.forEach((f) => {
                if (ids.includes(f.id)) {
                  f.state = CommonFileState.loaded;
                  f.storageUri = response.storagesInfos[i].uri;
                  f.documentId = newDocId[newDocId.length - 1];
                }
              });
              return changed;
            });
          }
        }
      }
    },
    [entityId, equipmentId, inputFiles, sendDocsRequest, uploadDoc],
  );

  // Envoyer un doc
  useEffect(() => {
    if (rowAction?.option === DocRowActions.Delete && rowAction.rowId !== '') {
      setOpenDeleteDialog(true);
    } else if (rowAction?.option === DocRowActions.Send && rowAction.rowId !== '') {
      handleSendDocs(rowAction.rowId as string);
    } else if (rowAction?.option === DocRowActions.View && rowAction.rowId !== '') {
      readFile(rowAction.rowId);
    }
    //eslint-disable-next-line
  }, [rowAction]);
  // Envoyer pl docs
  useEffect(() => {
    if (rowsAction.option === DocMultiplesActions.Delete && rowsAction.rowIds.length > 0) {
      setOpenDeleteDialog(true);
    } else if (rowsAction.option === DocMultiplesActions.Send && rowsAction.rowIds.length > 0) {
      handleSendDocs(rowsAction.rowIds as string[]);
    }
    //eslint-disable-next-line
  }, [rowsAction]);

  useEffect(() => {
    switch (deleteState.choice) {
      case DeleteActionType.Delete:
        setOpenDeleteDialog(false);
        //on regarde si ça vient de la datatable
        if (rowAction?.option === DocRowActions.Delete && rowAction.rowId !== '') {
          const docToDelete = inputFiles.find((el) => el.id === rowAction.rowId);
          if (docToDelete && docToDelete.state === CommonFileState.loaded) {
            deleteDocsRequest(docToDelete.documentId, docToDelete.title);
          }
          setInputFiles((prev) => {
            const changed = prev.filter((d) => d.id !== rowAction.rowId);
            return changed;
          });
          setRowAction({ option: '', rowId: '' });
        } else if (rowsAction?.option === DocMultiplesActions.Delete && rowsAction.rowIds.length > 0) {
          const docsToDelete = inputFiles.filter((el) => rowsAction.rowIds.includes(el.id));
          if (docsToDelete.length > 0) {
            for (let i = 0; i < docsToDelete.length; i++) {
              if (docsToDelete[i].state === CommonFileState.loaded) {
                deleteDocsRequest(docsToDelete[i].documentId, docsToDelete[i].title);
              }
              setInputFiles((prev) => {
                const changed = prev.filter((d) => !rowsAction.rowIds.includes(d.id));
                return changed;
              });
            }
            setRowsAction({ option: '', rowIds: [] });
          }
        }
        break;
      case DeleteActionType.Cancel:
      default:
        setOpenDeleteDialog(false);
        break;
    }
    //eslint-disable-next-line
  }, [deleteState]);

  const FilesInput = React.useRef<HTMLInputElement | null>(null);

  const handleFilesInput = () => {
    FilesInput.current?.click();
  };

  const uploadFiles = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length) {
      const [...files] = event.target.files;
      setInputFiles(
        inputFiles.concat(
          files.map((fl) => {
            const id = uuidv4();
            const docId = Guid();
            return {
              id: id,
              documentId: docId,
              title: fl.name,
              name: fl.name,
              LastModified: new Date(),
              size: fl.size,
              type: fl.type,
              state: CommonFileState.unload,
              file: fl,
            } as InputFileType;
          }),
        ),
      );
    }
  };

  useEffect(() => {
    setRows(inputFiles);
  }, [inputFiles]);

  useEffect(() => {
    setRows(
      inputFiles.filter((doc) => {
        return doc.name.toLocaleLowerCase().includes(searchValue.toLocaleLowerCase());
      }),
    );
    //eslint-disable-next-line
  }, [searchValue]);

  return {
    columns,
    rows,
    setSearchValue,
    setRowAction,
    setRowsAction,
    openDeleteDialog,
    deleteDispatch,
    FilesInput,
    handleFilesInput,
    uploadFiles,
  };
};

export default useData;
