/**
 * Liste les différents types de champ possible
 */
export enum FieldType {
  Unknow = 0,
  Text = 1,
  Label = 2,
  CheckBox = 3,
  Combo = 5,
  HierarchicalList = 6,
  CheckBoxList = 7,
  RadioList = 8,
  Group = 9,
  Dialog = 10,
  Include = 11,
  Attachment = 12,
  Photo = 13,
  Audio = 14,
  Draw = 15,
  Slider = 16,
  Geolocation = 17,
  Compute = 18,
  DateTime = 19,
  Separator = 20,
  FixedImage = 21,
  Array = 22,
  Signature = 23,
  CodeReader = 24,
  Counter = 25,
  Plugin = 26,
  Numeric = 27,
  Time = 28,
  Alert = 29,
  RepeatableGroup = 30,
  ReadOnlyValue = 31,
  Address = 32,
  Digits = 35,
  Step = 36,
  RfidReader = 37,
  Notification = 38,
  DataSource = 39,
  TodoList = 40,
  FixedAttachment = 41,
  Action = 42,
}

export enum FieldTypeText {
  Unknow = 'Unknow',
  Text = 'Text',
  Label = 'Label',
  CheckBox = 'CheckBox',
  Combo = 'Combo',
  HierarchicalList = 'HierarchicalList',
  CheckBoxList = 'CheckBoxList',
  RadioList = 'RadioList',
  Group = 'Group',
  Dialog = 'Dialog',
  Include = 'Include',
  Attachment = 'Attachment',
  Photo = 'Photo',
  Audio = 'Audio',
  Draw = 'Draw',
  Slider = 'Slider',
  Geolocation = 'Geolocation',
  Compute = 'Compute',
  DateTime = 'DateTime',
  Separator = 'Separator',
  FixedImage = 'FixedImage',
  Array = 'Array',
  Signature = 'Signature',
  CodeReader = 'CodeReader',
  Counter = 'Counter',
  Plugin = 'Plugin',
  Numeric = 'Numeric',
  Time = 'Time',
  Alert = 'Alert',
  RepeatableGroup = 'RepeatableGroup',
  ReadOnlyValue = 'ReadOnlyValue',
  Address = 'Address',
  Digits = 'Digits',
  Step = 'Step',
  RfidReader = 'RfidReader',
  Notification = 'Notification',
  DataSource = 'DataSource',
  TodoList = 'TodoList',
  FixedAttachment = 'FixedAttachment',
  Action = 'Action',
}
