import { SxProps, Theme } from '@mui/material';
import { grey1, grey4 } from '00.app/theme/colors';

export const btnBoxStyles: SxProps = {
  position: 'absolute',
  top: '3vh',
  right: 0,
  display: 'flex',
  justifyContent: 'space-between',
  gap: '10px',
  flexDirection: 'column',
};

export const textFieldStyles: SxProps = {
  input: {
    fontWeight: 'bold',
    textAlign: 'end',
  },
};

export const typographyStyles: SxProps = {
  fontWeight: '600',
  fontSize: '1.5rem',
};

export const gridContainerStyles: SxProps = {
  display: 'grid',
  gridTemplateColumns: {
    xs: 'repeat(1, 1fr)',
    sm: 'repeat(1, 1fr)',
    md: 'repeat(2, 1fr)',
    lg: 'repeat(2, 1fr)',
  },
  columnGap: '15px',
  rowGap: '10px',
};

export const gridItemStyles = (theme: Theme): SxProps => {
  return {
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: theme.palette.mode === 'light' ? grey1 : grey4,
    px: '10px',
    py: '2px',
    borderRadius: '10px',
  };
};
