import { SxProps, Theme } from '@mui/material';
import { brown1, brown4, slateGrey50 } from '00.app/theme/colors';

export const outterBoxStyles: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  margin: '5px',
  heigth: '70%',
  maxWidth: 'fit-parent',
};

export const innerBoxStyles = (theme: Theme, variant: 'contained' | 'outlined' | undefined): SxProps => {
  return {
    display: 'flex',
    position: 'relative',
    width: '100%',
    '&:hover > .backgroundBox': {
      backgroundColor: variant == 'contained' ? brown1 : theme.palette.switchBackground.main,
    },
  };
};

export const backgroundBoxStyles = (theme: Theme): SxProps => {
  return {
    zIndex: 'auto',
    width: '100%',
    height: '100%',
    position: 'absolute',
    borderRadius: '8px',
    border: '2px solid',
    borderColor: theme.palette.secondary.main,
  };
};

export const outlinedButtonStyles = (theme: Theme, isSelected: boolean): SxProps => {
  const primaryColor = theme.palette.primary.main;
  const secondaryColor = theme.palette.secondary.main;
  const switchBgColor = theme.palette.switchBackground.main;
  return {
    color: isSelected ? theme.palette.labelDefault.main : secondaryColor,
    minWidth: 'fit-content',
    flexGrow: 1,
    border: isSelected ? `2px solid ${primaryColor}` : 'none',
    borderRadius: '8px',
    backgroundColor: isSelected ? switchBgColor : '#00000000',
    '&.MuiButtonBase-root:hover': {
      border: isSelected ? `2px solid ${primaryColor}` : 'none',
      borderRadius: '8px',
      backgroundColor: isSelected ? switchBgColor : '#00000000',
    },
    '&.Mui-disabled': {
      color: isSelected ? theme.palette.labelDefault.main : secondaryColor,
      border: isSelected ? `2px solid ${primaryColor}` : 'none',
      backgroundColor: isSelected ? switchBgColor : '#00000000',
    },
  };
};

export const containedButtonStyles = (theme: Theme, isSelected: boolean): SxProps => {
  const secondaryColor = theme.palette.secondary.main;
  return {
    color: isSelected ? slateGrey50 : secondaryColor,
    minWidth: 'fit-content',
    flexGrow: 1,
    borderRadius: '8px',
    border: isSelected ? `2px solid ${brown4}` : 'none',
    backgroundColor: isSelected ? brown4 : '#00000000',
    '&.MuiButtonBase-root:hover': {
      border: isSelected ? `2px solid ${brown4}` : 'none',
      borderRadius: '8px',
      backgroundColor: isSelected ? brown4 : '#00000000',
    },
  };
};
