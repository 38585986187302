import { useState } from 'react';

const useData = () => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  // eslint-disable-next-line
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    if (setIsMenuOpen) {
      setIsMenuOpen(true);
    }
  };

  const handleMenuClose = () => {
    if (setIsMenuOpen) setIsMenuOpen(false);
  };

  return {
    anchorEl,
    setAnchorEl,
    handleClick,
    handleMenuClose,
  };
};

export default useData;
