import { Box } from '@mui/material';
import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import MenuWrapper from '../MenuWrapper';
import { ArrowIcon } from './icons';
import TraductionCard from './TraductionCard';
import TraductionLangsTabs from './TraductionCard/TraductionLangsTabs';
import TradFieldsList from './TraductionCard/TradFieldsList';
import TraductionField from './TraductionCard/TraductionField';
import { btnsBoxStyle, MainBox, tabPanelStyle, traductionFieldsStyle } from './styles';
import useTraductionData from './hooks';
import ContentHeader from '70.quickConnect.Components/siteAdmin/Other/ContentHeader';
import QCButtonGlobal from '70.quickConnect.Components/siteAdmin/Buttons/QCButtonGlobal';
import QCIconButton from '70.quickConnect.Components/siteAdmin/Buttons/QCIconButton';
import QCFabCorbeille from '70.quickConnect.Components/siteAdmin/Buttons/QCFabCorbeille';
import SaveConfirmationDialog from '70.quickConnect.Components/siteAdmin/Other/SaveConfirmationDialog';
import ResetConfirmationDialog from '70.quickConnect.Components/siteAdmin/Other/ResetConfirmationDialog';
import ImportConfirmationDialog from '70.quickConnect.Components/siteAdmin/Other/ImportConfirmationDialog';

const TraductionPage = () => {
  const { t } = useTranslation();
  const {
    supportedLanguages,
    i18nEntity,
    updateI18Entity,
    destChampsCount,
    defaultLang,
    openSaveDialog,
    setOpenSaveDialog,
    saveDispatch,
    openResetDialog,
    setOpenResetDialog,
    resetDispatch,
    exportI18nEntity,
    FileInputRef,
    handleFileInput,
    uploadFile,
    openImportDialog,
    importDispatch,
    getTitle,
  } = useTraductionData();
  return (
    <MenuWrapper>
      <Box sx={MainBox()}>
        <ContentHeader>
          <ContentHeader.BackButton />
          <ContentHeader.TitlesGroup>
            <ContentHeader.OverTitle>{getTitle()}</ContentHeader.OverTitle>
            <ContentHeader.Title>{t('translation.title')}</ContentHeader.Title>
          </ContentHeader.TitlesGroup>
        </ContentHeader>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
          <QCButtonGlobal
            className="import-button"
            variant="contained"
            value={t('internationalization.viewer.importFile')}
            startIcon={<DownloadOutlinedIcon />}
            action={handleFileInput}
          />
          <input ref={FileInputRef} type="file" onChange={uploadFile} hidden />
          <QCButtonGlobal
            className="export-button"
            variant="outlined"
            value={t('internationalization.viewer.exportFile')}
            startIcon={<UploadOutlinedIcon />}
            action={exportI18nEntity}
          />
        </Box>
        <Box sx={{ position: 'relative' }}>
          <TraductionCard
            supportedLangs={supportedLanguages ?? []}
            destChampsCount={destChampsCount}
            defaultLang={defaultLang}
          >
            <TraductionLangsTabs />
            <Box sx={tabPanelStyle}>
              <TradFieldsList isSourceLang>
                <Box sx={traductionFieldsStyle}>
                  {i18nEntity.translate.map((entry, index) => (
                    <TraductionField
                      key={entry.name + index.toString()}
                      fieldName={entry.name}
                      tradValues={entry.values}
                      OnChange={updateI18Entity}
                      isSource
                    />
                  ))}
                </Box>
              </TradFieldsList>
              <ArrowIcon sx={{ flexShrink: 1 }} />
              <TradFieldsList>
                <Box sx={traductionFieldsStyle}>
                  {i18nEntity.translate.map((entry, index) => (
                    <TraductionField
                      key={index.toString() + entry.name}
                      fieldName={entry.name}
                      tradValues={entry.values}
                      OnChange={updateI18Entity}
                    />
                  ))}
                </Box>
              </TradFieldsList>
            </Box>
          </TraductionCard>
          <Box sx={btnsBoxStyle}>
            <QCFabCorbeille buttonType="btn-fab" onClickFunc={() => setOpenResetDialog(true)} />
            <QCIconButton
              variantValue="contained"
              onClickFunc={() => setOpenSaveDialog(true)}
              Icon={<SaveOutlinedIcon />}
            />
          </Box>
          <ResetConfirmationDialog open={openResetDialog} dispatch={resetDispatch} />
          <SaveConfirmationDialog open={openSaveDialog} dispatch={saveDispatch} />
          <ImportConfirmationDialog open={openImportDialog} dispatch={importDispatch} />
        </Box>
      </Box>
    </MenuWrapper>
  );
};

export default observer(TraductionPage);
