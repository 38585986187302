import React, { cloneElement } from 'react';
import { ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import { descriptionFirstPartStyle, titleStyle } from './styles';
import { WidgetListItemProps } from './types';

const WidgetListItem = ({
  id,
  title,
  descriptionFirst,
  descriptionSecond,
  startIcon,
  endIcon,
}: WidgetListItemProps) => {
  return (
    <ListItem key={id} secondaryAction={endIcon} sx={{ minHeight: '72px' }}>
      {startIcon && (
        <ListItemAvatar sx={{ padding: 'none', minWidth: '36px' }}>
          {cloneElement(startIcon, { fontSize: 'medium' })}
        </ListItemAvatar>
      )}
      <ListItemText
        primary={title}
        secondary={
          <>
            <span className="description-first">{descriptionFirst}</span>
            <span className="description-second">{descriptionSecond}</span>
          </>
        }
        primaryTypographyProps={{
          sx: titleStyle,
        }}
        secondaryTypographyProps={{
          sx: descriptionFirstPartStyle,
        }}
        sx={{ padding: 0 }}
      />
    </ListItem>
  );
};

export default WidgetListItem;
