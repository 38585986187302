import React from 'react';
import useData from './hooks';
import QCTextArea from '70.quickConnect.Components/siteAdmin/Inputs/QCTextArea';
import { QCTextAreaProps } from '70.quickConnect.Components/siteAdmin/Inputs/QCTextArea/type';

const QCDialogFormTextArea = ({ label, info, placeholder, mandatory }: QCTextAreaProps & { label: string }) => {
  const { handleChange, defaultValue } = useData(label, mandatory ?? false);
  return (
    <QCTextArea
      label={label}
      info={info}
      placeholder={placeholder}
      dispatch={handleChange}
      mandatory={mandatory}
      className="dialog-text-input"
      value={defaultValue}
    />
  );
};

export default QCDialogFormTextArea;
