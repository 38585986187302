import { SxProps } from '@mui/system';

export const mainBoxSx: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  marginX: '5vw',
  gap: 3,

  '.MuiDataGrid-virtualScrollerContent': {
    overflowY: 'scroll',
    scrollbarWidth: 'thin',
  },
};
