import { t } from 'i18next';
import { useCallback } from 'react';

import { CreateUserRequest } from '../services/requests';
import { useUserServices } from '../services/users';
import useUserData from '../hooks';
import { AllDataElementType } from '70.quickConnect.Components/siteAdmin/Other/QCDialogForm/types';
import { IdentityProviders } from '90.quickConnect.Models/siteAdmin/enums/user/identityProviders';
import { useSafeNavigation } from '80.quickConnect.core/siteAdmin/hooks/useSafeNavigation';

const useData = () => {
  const { createUser } = useUserServices();
  const { isAddUserDialogOpen, setIsAddUserDialogOpen } = useUserData();
  const navigate = useSafeNavigation();

  const handleAddUser = () => {
    setIsAddUserDialogOpen((prevOpen) => !prevOpen);
  };

  const handleConfirmAddUser = useCallback(
    async (allData: AllDataElementType[]) => {
      const request = {} as CreateUserRequest;
      allData.forEach((stepData) => {
        stepData.data.forEach((el) => {
          switch (el.label) {
            case t('users.designer.form.options.modeAuth'):
              if (typeof el.value === 'string') {
                if (el.value.includes('QuickConnect')) {
                  request.userProvider = IdentityProviders.QuickConnect;
                  request.isQuickConnectUser = true;
                } else {
                  request.userProvider = IdentityProviders.AzureActiveDirectory;
                  request.isQuickConnectUser = false;
                }
              }
              break;
            case t('users.users.table.organization'):
              if (typeof el.value === 'string') request.ouId = el.value;
              break;
            case t('users.designer.form.placeholder.login'):
              if (typeof el.value === 'string') request.UserUPN = el.value;
              break;
            case t('users.designer.form.placeholder.password'):
              if (typeof el.value === 'string') request.password = el.value;
              break;
            case t('users.designer.form.active'):
              if (typeof el.value === 'boolean') request.isActive = el.value;
              break;
            case t('users.designer.form.placeholder.lastName'):
              if (typeof el.value === 'string') request.lastName = el.value;
              break;
            case t('users.designer.form.placeholder.firstName'):
              if (typeof el.value === 'string') request.firstName = el.value;
              break;
            case t('users.designer.form.placeholder.email'):
              if (typeof el.value === 'string') request.email = el.value;
              break;
            case t('users.designer.form.placeholder.phone'):
              if (typeof el.value === 'string') request.phone = el.value;
              break;
            case t('users.designer.form.placeholder.job'):
              if (typeof el.value === 'string') request.job = el.value;
          }
        });
      });
      const response = await createUser(request);
      if (response) {
        navigate(`/users/${response.data.user.id}/settings`);
        return true;
      }
      return false;
    },
    [createUser, navigate],
  );

  return {
    isAddUserDialogOpen,
    handleAddUser,
    handleConfirmAddUser,
    setIsAddUserDialogOpen,
  };
};

export default useData;
