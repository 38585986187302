import { NavigateOptions, To, useNavigate } from 'react-router';
import { useStore } from '30.quickConnect.Store/siteAdmin';
import { NavElementName } from '10.quickConnect.app/siteAdmin/components/shared/Menu/types';

export const NavElementNameOptionsArray = [
  'home',
  'forms',
  'declarations',
  'scan',
  'data',
  'users',
  'equipments',
  'sharedlists',
  'todolists',
  'catalogs',
  'admin',
  'settings',
  'connections',
  'roles',
  'clients',
  'bin',
  'versions',
  'activities',
  'myAccount',
  'importsExports',
  'alerts',
  'plus',
];

export const useVerifiyFocusBtn = () => {
  const navigateRouter = useNavigate();
  const {
    SideMenuStore: { setFocusedBtn },
  } = useStore();
  const verification = () => {
    const location = /(?<button>(\/$)|(\/[^/0-9]+($|\/)))/.exec(window.location.pathname)?.groups;
    if (location === undefined) {
      navigateRouter('home');
      setFocusedBtn('home');
    } else {
      // console.log('focusedBtn:', focusedBtn);
      if (location.button === '/') {
        setFocusedBtn('home');
      } else if (location.button.lastIndexOf('/') !== 0) {
        setFocusedBtn(location.button.substring(1, location.button.length - 1) as NavElementName);
      } else {
        setFocusedBtn(location.button.substring(1) as NavElementName);
      }
    }
  };

  return verification;
};

// Fonction permettant de naviger dans la page tout en changeant le focus du menu

export const useSafeNavigation = () => {
  const navigateRouter = useNavigate();

  const verification = useVerifiyFocusBtn();

  const navigate = (url: To | number, options?: NavigateOptions) => {
    if (typeof url === 'number') navigateRouter(url);
    else navigateRouter(url, options);
    // console.log(window.location.pathname);
    verification();
  };

  return navigate;
};
