import React, { Dispatch, MouseEventHandler, SetStateAction, useCallback, useMemo, useState } from 'react';
import {
  GridColDef,
  GridColumnHeaderParams,
  GridColumns,
  GridEventListener,
  GridLocaleText,
  GridRowId,
  GridRowsProp,
  GridSelectionModel,
} from '@mui/x-data-grid';
import { Box, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { UseDataProps } from './types';
import DefaultKebabMenuCell from './DefaultKebabMenu';
import { ChoiceMenuOptionType } from '70.quickConnect.Components/siteAdmin/Inputs/QCChoiceMenu/type';
import { SortTypes } from '90.quickConnect.Models/siteAdmin/enums/sortTypes';

export const useData = (
  columns: GridColumns,
  rows: GridRowsProp,
  kebabMenu: GridColDef | undefined,
  rowActions: ChoiceMenuOptionType[] | undefined,
  // eslint-disable-next-line
  multipleActions: ChoiceMenuOptionType[] | undefined,
  onSelectRowAction: Dispatch<SetStateAction<{ option: string; rowId: GridRowId }>> | undefined,
  onSelectMultipleAction: Dispatch<SetStateAction<{ option: string; rowIds: GridRowId[] }>> | undefined,
  // eslint-disable-next-line
  setIsExpanding?: React.Dispatch<React.SetStateAction<boolean>>,
): UseDataProps => {
  const { t } = useTranslation();
  const theme = useTheme();

  // laisser ça ici?
  const [sorting, setSorting] = useState<string>(SortTypes.Alphabetical as string);

  const defaultKebabMenuCol: GridColDef = {
    field: 'kebabMenu',
    // headerName: 'KebabMenu',
    width: 10,
    renderCell: (params) => (
      <DefaultKebabMenuCell params={params} options={rowActions ?? []} onSelectRowAction={onSelectRowAction} />
    ),
    renderHeader: () => null,
    sortable: false,
  };

  const initializeCols = useCallback(
    // eslint-disable-next-line
    (dataColumns: GridColumns, defaultKebabMenuColumn: GridColDef<any, any, any>): GridColumns => {
      let newCols = Array.from(dataColumns);

      // customisation des headers
      newCols.forEach((column: GridColDef) => {
        column.renderHeader = (params: GridColumnHeaderParams) => (
          <Box sx={{ whiteSpace: 'normal', lineHeight: 'normal' }}>{params.colDef.headerName?.toLocaleUpperCase()}</Box>
        );
      });

      // s'il n'y a pas d'action définie pour les lignes, on n'affiche pas le menu
      if (rowActions) {
        newCols = [...newCols, kebabMenu ?? defaultKebabMenuColumn];
      } else if (kebabMenu) {
        newCols = [...newCols, kebabMenu];
      }
      return newCols;
    },
    [kebabMenu, rowActions],
  );

  const [cols, setCols] = useState<GridColumns>(initializeCols(columns, defaultKebabMenuCol));

  const [selectedRowsId, setSelectedRowsId] = useState<GridRowId[]>([]);

  // Désactive la séléction de ligne lorsqu'on clique sur le kebab menu
  const handleCellClick: GridEventListener<'cellClick'> = (params, event) => {
    if (params.colDef.field === 'kebabMenu') {
      event.defaultMuiPrevented = true;
    }
  };

  // Pour modifier les textes de base de DataGrid
  const dataGridLocaleText: Partial<GridLocaleText> = useMemo(
    () => ({
      columnHeaderSortIconLabel: t('datatable.sortByColum'),
      footerRowSelected: (count: number) => t('datatable.footerRowSelected', { selectedRows: count }),
    }),
    [t],
  );

  // Permet de récupérer les lignes sélectionnées
  const handleRowSelectionChange = (selectedIds: GridSelectionModel) => {
    setSelectedRowsId(selectedIds);
  };

  const handleActionCheckboxClick: MouseEventHandler = () => {
    if (selectedRowsId.length > 0) {
      setSelectedRowsId([]);
    } else setSelectedRowsId(rows.map((row) => row.id));
  };

  // Ancre du menu pour les actions multiples
  const [actionMenuAnchor, setActionMenuAnchor] = useState<HTMLElement | null>(null);

  const handleMultipleActionClick: MouseEventHandler<HTMLElement> = (event) => {
    setActionMenuAnchor(event.currentTarget);
  };

  const handleMultipleActionSelectedOption = (option: string) => {
    if (onSelectMultipleAction) onSelectMultipleAction({ option: option, rowIds: selectedRowsId });
  };

  return {
    sorting,
    setSorting,
    t,
    theme,
    cols,
    setCols,
    selectedRowsId,
    setSelectedRowsId,
    handleCellClick,
    dataGridLocaleText,
    handleRowSelectionChange,
    handleActionCheckboxClick,
    handleMultipleActionClick,
    actionMenuAnchor,
    setActionMenuAnchor,
    handleMultipleActionSelectedOption,
  };
};
