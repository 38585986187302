import { AxiosResponse } from 'axios';

export const extractFileName = (response: AxiosResponse, target: string): string => {
  let filename = '';
  const disposition = response.headers['content-disposition'];
  if (disposition && disposition.indexOf(target) !== -1) {
    const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
    const matches = filenameRegex.exec(disposition);
    if (matches != null && matches[1]) {
      filename = matches[1].replace(/['"]/g, '');
    }
  }
  return filename;
};
