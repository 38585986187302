import { FiltersType } from './types';
import { ColumnFilterType } from '90.quickConnect.Models/shared/enums/columnFilterType';
import { KeyValuePair } from '90.quickConnect.Models/shared/models/keyValuePair';

export enum FieldNames {
  organizationName = 'name',
  resourceTitle = 'title',
  identifierCode = 'code',
  tag = 'qcTagCode',
}

export const defaultFilters: FiltersType[] = [
  {
    columnName: FieldNames.organizationName,
    filters: [],
    type: ColumnFilterType.TextContains,
  },
  {
    columnName: FieldNames.resourceTitle,
    filters: [],
    type: ColumnFilterType.TextContains,
  },
  {
    columnName: FieldNames.identifierCode,
    filters: [],
    type: ColumnFilterType.TextContains,
  },
  {
    columnName: FieldNames.tag,
    filters: [],
    type: ColumnFilterType.TextContains,
  },
];

export const defaultColumns: KeyValuePair<string, boolean>[] = [
  { key: 'name', value: true },
  { key: 'schemaId', value: false },
  { key: 'instanceId', value: false },
  { key: 'title', value: true },
  { key: 'code', value: true },
  { key: 'qcTagCode', value: true },
];
