import { ChangeEvent, useState } from 'react';
import { delimiter } from './const';

const useData = (namesOfElementToDelete: string | string[] | undefined) => {
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const isEqualNames = (value: string): boolean => {
    const reg = new RegExp(delimiter + '\\s*', 'g');
    const valueWithoutSpaces = value.trim().replace(reg, delimiter);
    if (Array.isArray(namesOfElementToDelete)) {
      const valueToList = valueWithoutSpaces.split(delimiter);
      return (
        valueToList.length === namesOfElementToDelete.length &&
        namesOfElementToDelete.every((el) => valueToList.includes(el))
      );
    } else {
      return namesOfElementToDelete === valueWithoutSpaces;
    }
  };

  const handleNames = () => {
    if (Array.isArray(namesOfElementToDelete)) {
      return namesOfElementToDelete.join(delimiter);
    } else {
      return namesOfElementToDelete;
    }
  };

  const checkNames = (event: ChangeEvent<HTMLInputElement>) => {
    setIsDisabled(!isEqualNames(event.currentTarget.value));
  };
  return {
    isDisabled,
    checkNames,
    handleNames,
  };
};

export default useData;
