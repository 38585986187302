import { Box } from '@mui/material';
import React from 'react';
import MenuWrapper from '../../shared/MenuWrapper';
import useData from './hooks';
import ContentHeader from '70.quickConnect.Components/siteAdmin/Other/ContentHeader';
import DataTable from '70.quickConnect.Components/siteAdmin/Other/DataTable';
import QCAutocomplete from '70.quickConnect.Components/siteAdmin/Inputs/QCAutocomplete';
import DeleteConfirmationDialog from '70.quickConnect.Components/siteAdmin/Other/DeleteConfirmationDialog';

const DeclarationHistory = () => {
  const {
    t,
    rows,
    cols,
    isLoading,
    pageSize,
    setActualFilter,
    requestHandler,
    sortingProps,
    setRowAction,
    rowActionOptions,
    setRowsAction,
    multipleActionOptions,
    openDeleteDialog,
    deleteDispatch,
  } = useData();

  return (
    <MenuWrapper>
      <Box>
        <ContentHeader>
          <ContentHeader.Title>{t('home.widget.declarations.title')}</ContentHeader.Title>
        </ContentHeader>
        <DataTable
          rows={rows}
          columns={cols}
          getSearchValue={setActualFilter}
          rowActions={rowActionOptions}
          onSelectRowAction={setRowAction}
          multipleActions={multipleActionOptions}
          onSelectMultipleAction={setRowsAction}
          // kebabMenu={expandCell}
          requestHandler={requestHandler}
          sortingProps={sortingProps}
          isLoading={isLoading}
          isSearchBar
          pageSize={pageSize}
          renderAfterSearchBar={
            <Box>
              <QCAutocomplete
                options={['Aucun groupement', 'Groupement par formulaire', 'Groupement par formulaire à étapes']}
              />
            </Box>
          }
        />
        <DeleteConfirmationDialog open={openDeleteDialog} dispatch={deleteDispatch} trashAvailable />
      </Box>
    </MenuWrapper>
  );
};

export default DeclarationHistory;
