import { Box } from '@mui/material';
import React, { CSSProperties, useContext, useEffect, useState } from 'react';
import { FixedSizeList } from 'react-window';
import { SubMenuContext } from '../const';
import { renderSubMenuRow } from '../help';
import { SubMenuRow } from '../types';

export type SubMenuVirtualizedListProps = {
  rows: SubMenuRow[];
};

const SubMenuVirtualizedList = ({ rows }: SubMenuVirtualizedListProps) => {
  const [listHeight, setListHeight] = useState(700);

  const { selectedRowsId, width } = useContext(SubMenuContext);

  const Row = ({ index, style }: { index: number; style: CSSProperties }) => {
    return <div style={style}>{renderSubMenuRow(rows[index], selectedRowsId.includes(rows[index].id))}</div>;
  };

  useEffect(() => {
    const listContainer = document.querySelector('.sub-menu-virtualized-list-container') as HTMLElement;
    const listContainerHeight = listContainer.clientHeight;
    setListHeight(listContainerHeight);
  }, []);
  return (
    <Box
      sx={{ display: 'flex', justifyContent: 'center', height: '100%' }}
      className="sub-menu-virtualized-list-container"
    >
      <FixedSizeList height={listHeight} width={width * 0.98} itemCount={rows.length} itemSize={40}>
        {Row}
      </FixedSizeList>
    </Box>
  );
};

export default SubMenuVirtualizedList;
