import { Box, Menu, MenuItem, useTheme } from '@mui/material';
import React, { MouseEvent } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { QCChoiceMenuProps, ChoiceMenuOptionType } from './type';

const QCChoiceMenu = ({ anchorElState, options, dispatch, onMenuClose, ...menuProps }: QCChoiceMenuProps) => {
  const theme = useTheme();

  const [anchorEl, setAnchorEl] = anchorElState;

  const open = Boolean(anchorEl);

  const isIcon = options.filter((value) => value.startIcon !== undefined).length;

  const handleClose = () => {
    setAnchorEl(null);
    if (onMenuClose) onMenuClose();
  };

  const handleChoice = (event: MouseEvent<HTMLElement>) => {
    if (dispatch) dispatch(event.currentTarget.id);
    setAnchorEl(null);
  };

  return (
    <Menu
      {...menuProps}
      anchorEl={anchorEl}
      open={open}
      onClose={handleClose}
      sx={{
        '& .MuiPaper-root': {
          border: 'solid 2px #D1D6D2',
          borderColor: theme.palette.menuBorder.main,
          borderRadius: '8px',
          backgroundColor: 'inherited',
          boxShadow: 'none',
        },
        ...menuProps.sx,
      }}
    >
      {options.map((option: ChoiceMenuOptionType /*, index: number*/) => (
        <MenuItem
          id={option.value ?? option.text}
          onClick={handleChoice}
          key={uuidv4()}
          sx={{
            color: option.color === 'undefined' ? '#A7ADA8' : option.color,
            paddingLeft: '10px',
            paddingRight: '10px',
            gap: isIcon === 0 ? '0px' : '7px',
          }}
        >
          <Box sx={{ width: isIcon === 0 ? '0px' : '24px', position: 'relative', top: '3px' }}>{option.startIcon}</Box>
          {option.text}
        </MenuItem>
      ))}
    </Menu>
  );
};

export default QCChoiceMenu;
