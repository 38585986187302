import { useCallback, useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useTranslation } from 'react-i18next';
import useServices from '../services/declarationHistory';
import { Declaration } from '../services/responses';
import { useSafeNavigation } from '80.quickConnect.core/siteAdmin/hooks/useSafeNavigation';
import useEffectOnce from '80.quickConnect.core/shared/hooks/useEffectOnce';

const useData = () => {
  const { getDeclaration, downloadTemplate } = useServices();
  const { declarationId } = useParams();
  const navigate = useSafeNavigation();
  const { t } = useTranslation();
  const [declarationPage, setDeclarationPage] = useState<Declaration>();
  const [openDownloadDialog, setOpenDownloadDialog] = useState(false);
  const [downloadParams, setDownloadParams] = useState({
    isConfirmDownload: false,
    isFormatPdf: false,
  });

  const initDeclationPage = useCallback(async () => {
    if (!declarationId) {
      navigate(-1);
      return;
    }
    const res = await getDeclaration({ id: declarationId });
    if (!res) navigate(-1);
    setDeclarationPage(res);
  }, [getDeclaration, declarationId, navigate]);

  useEffectOnce(() => {
    initDeclationPage();
  });

  const goToPageConcepteur = useCallback(() => {
    // à ajouter la route associée
    if (declarationPage?.formId) {
      console.log('à faire, navigation à la page concepteur associé');
    }
  }, [declarationPage?.formId]);

  const declaRef = useRef<HTMLDivElement>(null);

  const downloadDeclarationReport = useCallback(async () => {
    if (declarationId && downloadParams.isConfirmDownload) {
      const file = await downloadTemplate('', declarationId, downloadParams.isFormatPdf);
      if (!file) return;
      const data = window.URL.createObjectURL(file);
      const link = document.createElement('a');
      link.href = data;
      link.download = file.name;
      // nécessaire car link.click() ne marche pas dans les dernières versions de firefox
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(function () {
        // Pour Firefox il est nécessaire de retarder le revoking de l'ObjectURL
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 3000);
      setDownloadParams((prevState) => ({
        ...prevState,
        isConfirmDownload: false,
      }));
    }
  }, [declarationId, downloadTemplate, downloadParams]);

  const handleOpenDownloadDialog = useCallback(() => {
    setOpenDownloadDialog(true);
  }, []);

  const handleCloseDownloadDialog = useCallback((asPdf: boolean, isConfirm: boolean) => {
    setOpenDownloadDialog(false);
    setDownloadParams({
      isConfirmDownload: isConfirm,
      isFormatPdf: asPdf,
    });
  }, []);

  useEffect(() => {
    downloadDeclarationReport();
  }, [downloadDeclarationReport]);

  return {
    t,
    declarationPage,
    goToPageConcepteur,
    declaRef,
    openDownloadDialog,
    handleOpenDownloadDialog,
    handleCloseDownloadDialog,
    downloadDeclarationReport,
  };
};

export default useData;
