import React from 'react';
import useData from './hooks';
import { QCInputProps } from '70.quickConnect.Components/siteAdmin/Inputs/type';
import { AttributeValue } from '90.quickConnect.Models/siteAdmin/models/common/entities';
import BoolRadioInput from '70.quickConnect.Components/siteAdmin/Inputs/BoolRadioInput';
import { trueValue } from '70.quickConnect.Components/siteAdmin/Inputs/BoolRadioInput/const';

const QCDialogFormBoolRadio = ({
  label,
  mandatory,
  info,
  attributData,
}: QCInputProps & { label: string; mandatory?: boolean; attributData?: AttributeValue }) => {
  const { defaultBool, handleChange } = useData(label, attributData);

  return (
    <BoolRadioInput
      label={label}
      info={info}
      mandatory={mandatory}
      value={String(defaultBool)}
      dispatch={(event) => {
        if (attributData) {
          attributData.value = new Boolean(event.target.value === trueValue);
          handleChange(attributData);
        } else {
          handleChange(event.target.value === trueValue);
        }
      }}
    />
  );
};

export default QCDialogFormBoolRadio;
