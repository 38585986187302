import { getLanguageCode } from '80.quickConnect.core/shared/helpers';

export const toReadableDate = (isoDate: string) => {
  const matches = /(?<year>[0-9]{4})-(?<month>[0-9]{2})-(?<day>[0-9]{2})T(?<hour>[0-9]{2}):(?<minutes>[0-9]{2}):/g.exec(
    isoDate,
  );
  if (getLanguageCode() === 'fr') {
    return matches !== null
      ? matches[3].toString() +
          '/' +
          matches[2].toString() +
          '/' +
          matches[1].toString() +
          '\n' +
          matches[4] +
          'h' +
          matches[5]
      : '';
  } else {
    return matches !== null
      ? matches[1].toString() +
          '/' +
          matches[2].toString() +
          '/' +
          matches[3].toString() +
          '\n' +
          matches[4] +
          ':' +
          matches[5]
      : '';
  }
};
