import { SxProps, Theme } from '@mui/material';

export const WebhooksCardStyle = (theme: Theme): SxProps => ({
  position: 'relative',
  overflow: 'hidden',
  backgroundColor: theme.palette.toolsBackground.main,
  border: 'solid 2px',
  borderColor: theme.palette.menuBorder.main,
  borderRadius: '8px',
  paddingX: '20px',
  marginTop: '20px',
  paddingTop: '10px',
  flexGrow: 1,
});

export const WebhookListStyle: SxProps = {
  fontWeight: '500',
  fontSize: '14px',
  textTransform: 'uppercase',
  lineHeight: '3.5rem',
};
