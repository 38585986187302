import React, { cloneElement } from 'react';
import { Box } from '@mui/material';
import { TitleStyle } from '../style';
import { TitleProps } from './type';

const Title = ({ children, startIcon }: TitleProps) => {
  return (
    <Box sx={TitleStyle()}>
      <h1>
        {startIcon ? cloneElement(startIcon, { fontSize: 'large', sx: { marginRight: '5px' } }) : null}
        {children}
      </h1>
    </Box>
  );
};

export default Title;
