import { Dispatch } from 'react';

export enum DeleteActionType {
  Delete = 'Delete',
  Cancel = 'Cancel',
  SendToTrash = 'SendToTrash',
}

export type DeleteAction = {
  type: DeleteActionType;
  payload?: number;
};

export type ReducerState = {
  choice: DeleteActionType;
};

// Cas ou la corbeille est disponible à définir
export const deleteConfirmationReducer = (state: ReducerState, action: DeleteAction): ReducerState => {
  switch (action.type) {
    case DeleteActionType.Delete:
      // console.log('Delete');
      return { choice: DeleteActionType.Delete };
    case DeleteActionType.Cancel:
      // console.log('Cancel');
      return { choice: DeleteActionType.Cancel };
    case DeleteActionType.SendToTrash:
      // console.log('Trash');
      return { choice: DeleteActionType.SendToTrash };
  }
};

export type DeleteConfirmationProps = {
  open: boolean;
  dispatch: Dispatch<DeleteAction>;
  trashAvailable?: boolean;
  title?: string;
};
