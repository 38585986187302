import { LogLevel } from '@azure/msal-browser';
import { MsalAuthConfig } from '../../models/user/MsalAuthConfig';
import { MsalInfos } from '90.quickConnect.Models/siteAdmin/models/user/msalConfig';

const mapMsalAuthConfig = (msalInfos: MsalInfos): MsalAuthConfig => ({
  auth: {
    clientId: msalInfos.spa.clientId ?? '',
    clientSecret: msalInfos.spa.clientSecret ?? '',
    authority: msalInfos.authority ?? '',
    redirectUri: msalInfos.spa.redirectUri ?? '',
  },
  cache: {
    cacheLocation: 'sessionStorage',
    storeAuthStateInCookie: false,
  },
  system: {
    loggerOptions: {
      // eslint-disable-next-line
      loggerCallback: (level: any, message: any, containsPii: any) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
        }
      },
    },
  },
});

export default mapMsalAuthConfig;
