import React from 'react';
import ImportExportIcon from '@mui/icons-material/ImportExport';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import QrCodeScannerIcon from '@mui/icons-material/QrCodeScanner';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import SourceOutlinedIcon from '@mui/icons-material/SourceOutlined';
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import RestoreOutlinedIcon from '@mui/icons-material/RestoreOutlined';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { NavElement } from './types';
import {
  USERS,
  EQUIPMENTS,
  SHAREDLISTS,
  TODOLISTS,
  CATALOGS,
  CLIENTS,
  CONNECTIONS,
  ROLES,
  SETTINGS,
  VERSION,
  HOME,
  FORMS,
  DECLARATIONS,
  SCAN,
  ACTIVITIES,
  MYACCOUNT,
  IMPORTSEXPORTS,
  ALERTS,
} from '10.quickConnect.app/siteAdmin/routes/paths';
import { QuickConnectRoles } from '90.quickConnect.Models/siteAdmin/enums/user/quickConnectRoles';

export const dataNavElements: NavElement[] = [
  { name: 'users', path: USERS, authorizationRole: QuickConnectRoles.Administrator, hasSubMenu: false },
  { name: 'equipments', path: EQUIPMENTS, authorizationRole: QuickConnectRoles.Analyst, hasSubMenu: false },
  { name: 'sharedlists', path: SHAREDLISTS, authorizationRole: QuickConnectRoles.DesignerInter, hasSubMenu: false },
  { name: 'todolists', path: TODOLISTS, authorizationRole: QuickConnectRoles.Administrator, hasSubMenu: false },
  { name: 'catalogs', path: CATALOGS, authorizationRole: QuickConnectRoles.Administrator, hasSubMenu: false },
];

export const adminNavElements: NavElement[] = [
  { name: 'settings', path: SETTINGS, authorizationRole: QuickConnectRoles.Administrator, hasSubMenu: false },
  { name: 'connections', path: CONNECTIONS, authorizationRole: QuickConnectRoles.Administrator, hasSubMenu: false },
  { name: 'roles', path: ROLES, authorizationRole: QuickConnectRoles.Administrator, hasSubMenu: false },
  { name: 'clients', path: CLIENTS, authorizationRole: QuickConnectRoles.C2SAdministrator, hasSubMenu: false },
  { name: 'versions', path: VERSION, authorizationRole: QuickConnectRoles.C2SAdministrator, hasSubMenu: false },
];

export const headerNavElements: NavElement[] = [
  {
    name: 'importsExports',
    path: IMPORTSEXPORTS,
    authorizationRole: QuickConnectRoles.User,
    Icon: <ImportExportIcon sx={{ transform: 'scaleX(-1)' }} />,
    hasSubMenu: false,
  },
  {
    name: 'alerts',
    path: ALERTS,
    authorizationRole: QuickConnectRoles.User,
    Icon: <WarningAmberIcon />,
    hasSubMenu: false,
  },
  {
    name: 'activities',
    path: ACTIVITIES,
    authorizationRole: QuickConnectRoles.User,
    Icon: <NotificationsNoneOutlinedIcon />,
    hasSubMenu: false,
  },
  {
    name: 'myAccount',
    path: MYACCOUNT,
    authorizationRole: QuickConnectRoles.User,
    Icon: <AccountCircleOutlinedIcon />,
    hasSubMenu: false,
  },
];

export const navElements: NavElement[] = [
  {
    name: 'home',
    path: HOME,
    authorizationRole: QuickConnectRoles.Analyst,
    Icon: <HomeOutlinedIcon />,
    hasSubMenu: false,
  },
  {
    name: 'forms',
    path: FORMS,
    authorizationRole: QuickConnectRoles.DesignerInter,
    Icon: <InsertDriveFileOutlinedIcon />,
    hasSubMenu: false,
  },
  ...headerNavElements,
  {
    name: 'declarations',
    path: DECLARATIONS,
    authorizationRole: QuickConnectRoles.Analyst,
    Icon: <RestoreOutlinedIcon />,
    hasSubMenu: false,
  },
  {
    name: 'scan',
    path: SCAN,
    authorizationRole: QuickConnectRoles.Analyst,
    Icon: <QrCodeScannerIcon />,
    hasSubMenu: false,
  },

  {
    name: 'data',
    path: '',
    authorizationRole: QuickConnectRoles.Analyst,
    Icon: <SourceOutlinedIcon />,
    hasSubMenu: true,
  },
  ...dataNavElements,

  {
    name: 'admin',
    path: '',
    authorizationRole: QuickConnectRoles.Administrator,
    Icon: <AdminPanelSettingsOutlinedIcon />,
    hasSubMenu: true,
  },
  ...adminNavElements,

  { name: 'plus', path: '', authorizationRole: QuickConnectRoles.User, Icon: <MoreHorizIcon />, hasSubMenu: false },
];
