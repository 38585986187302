import { Dispatch } from 'react';

export type ImportConfirmationProps = {
  open: boolean;
  dispatch: Dispatch<ImportAction>;
};

export enum ImportActionType {
  Import = 'Import',
  Cancel = 'Cancel',
}

export type ImportAction = {
  type: ImportActionType;
  payload?: number;
};

export type ReducerImportState = {
  isImport: boolean;
};

export const importConfirmationReducer = (state: ReducerImportState, action: ImportAction): ReducerImportState => {
  switch (action.type) {
    case ImportActionType.Import:
      return { isImport: true };
    case ImportActionType.Cancel:
      return { isImport: false };
  }
};
