import { Box } from '@mui/system';
import React, { useContext, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { QCDialogFormContext } from '../context';
import { BodyContext } from '../StepBody/context';
import { dialogFormFooterStyle } from '../style';
import { StepFooterProps } from './type';
import QCButtonGlobal from '70.quickConnect.Components/siteAdmin/Buttons/QCButtonGlobal';

const QCStepFooter = ({ displayStepping, index }: StepFooterProps) => {
  const { steps, nextStep, prevStep, toogleIsOpen, allData, setActiveStep, resetAllDataArray } =
    useContext(QCDialogFormContext);

  const { t } = useTranslation();
  const { handleConfirm: onEnd } = useContext(QCDialogFormContext);
  const { isAllMandatoryFieldsFilled, isAllControlledFieldValid } = useContext(BodyContext);

  const handleConfirm = async () => {
    const end = await onEnd(allData);
    console.log(end);
    toogleIsOpen(!end);
    setActiveStep(0);
    resetAllDataArray();
  };

  const notAllFilled = () => {
    toast.error('Tout remplir');
  };

  const verifications = useMemo(
    () => isAllMandatoryFieldsFilled && isAllControlledFieldValid,
    //eslint-disable-next-line
    [isAllControlledFieldValid, isAllMandatoryFieldsFilled],
  );

  return (
    <Box className="dialog-form-footer" sx={dialogFormFooterStyle}>
      {index > 1 ? (
        <QCButtonGlobal className="prev-button" value={t('dialogForm.prevPage')} action={prevStep} />
      ) : (
        <></>
      )}
      {displayStepping ? <Box className="stepping">{`Etape ${index.toString()}/${steps}`}</Box> : <></>}
      {index < steps ? (
        <QCButtonGlobal
          className="next-button"
          variant={!verifications ? 'text' : 'contained'}
          value={t('dialogForm.nextPage')}
          action={verifications ? nextStep : notAllFilled}
        />
      ) : (
        <>
          <QCButtonGlobal
            className="confirm-button"
            variant={!verifications ? 'text' : 'contained'}
            value={t('dialogForm.confirm')}
            action={verifications ? handleConfirm : notAllFilled}
          />
        </>
      )}
    </Box>
  );
};

export default QCStepFooter;
