import { Box, useTheme } from '@mui/material';
import React from 'react';
import Editor, { EditorProps } from '@monaco-editor/react';
import { InputContainerStyle } from '../style';

const QCCodeInput = (props: EditorProps) => {
  const theme = useTheme();

  return (
    <Box
      className="qc-code-editor"
      sx={{
        ...InputContainerStyle,
        width: props.width ?? '100%',
        textAlign: 'start',
        border: `3px solid ${theme.palette.secondary.main}`,
        borderRadius: '10px',
        overflow: 'hidden',
      }}
    >
      <Editor
        width="100%"
        defaultLanguage={props.defaultLanguage ?? 'json'}
        defaultValue={props.defaultValue ?? '{}'}
        theme={theme.palette.mode === 'light' ? 'light' : 'vs-dark'}
        options={{ minimap: { enabled: false } }}
        {...props}
      />
    </Box>
  );
};

// const QCCodeInput = styled(QCCodeInputSkeleton)(({ theme }) => ({
//   ...InputContainerStyle,
//   textAlign: 'start',
//   width: '100%',
//   border: `3px solid ${theme.palette.secondary.main}`,
//   borderRadius: '10px',
//   overflow: 'hidden',
// }));

export default QCCodeInput;
