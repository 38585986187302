import { IdentityProviders } from '90.quickConnect.Models/siteAdmin/enums/user/identityProviders';
import { EntityBase } from '90.quickConnect.Models/siteAdmin/models/common/entityBase';
import { TranslationStatus } from '90.quickConnect.Models/siteAdmin/models/common/translationStatus';
export type GetOrganizationalUnitListResponse = {
  organizationalUnits: Organization[];

  count: number;

  totalCount: number;

  isThereMoreItems: boolean;

  researchToken: string | null;

  status: number;

  message: string | null;

  errorCode: number | null;
};

export type Organization = EntityBase & {
  name: string;
  parentId?: string;
  parentCode?: string;
  parentName?: string;
  code?: string;
  description?: string;
  address?: string;
  zipCode?: string;
  town?: string;
  country?: string;
  siret: string;
  email: string;
  logo: string;
  companionQCId: string;
  correlationId: string;
  isActive: boolean;
  isHeadQuarter: boolean;
  level: number;
};

export type Profil = EntityBase & {
  isInternal: boolean;
  name: string;
  description: string;
  languageStatus: TranslationStatus[];
  defaultLanguage: string;
};

export type User = EntityBase &
  WithFullName & {
    isSelectable: boolean;
    isSelected: boolean;
    userUPN: string;
    lastName: string;
    firstName: string;
    job: string;
    phone: string;
    email: string;
    isActive: boolean;

    profils: Profil[];
    profilsTxt: string;
    organizationalUnit: Organization;
    organizationalUnitTxt: string;

    password: string;
    authMethod: IdentityProviders;

    token: string;
    rights: number;
    customerName: string;
  };

export type WithFullName = {
  fullName?: string;
};

export type BaseUserSettings = EntityBase & {
  name: string;
  description: string;
  userParameterType: UserSettingsType;
  value: UserSettingsValue;
};

export type UserSettingsValue = UserSettingsNumberValue | boolean | string;

type UserSettingsNumberValue = {
  min?: number;
  max?: number;
  defaultValue?: number;
};

export enum UserSettingsType {
  None,
  Boolean,
  Int,
  Decimal,
  SharedList,
  Text,
}

export type AssociatedUserSettings = {
  userParameterId: string;
  value: string;
};

export type AuthMethod = {
  name: string;
  providerValue: IdentityProviders;
};
