import axios from 'axios';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { BinApi } from './apiRoutes';
import { DeleteBinElementsRequest, GetBinElementsRequest, RestoreBinElementRequest } from './request';
import { DeleteBinElementsResponse, GetBinElementsResponse, RestoreBinElementsResponse } from './response';
import QCHttpRequest from '40.quickConnect.DataAcces/siteAdmin/QCHttpRequest';
import { isResponseValid } from '40.quickConnect.DataAcces/siteAdmin/responseValidator';

export const useBinServices = () => {
  const { t } = useTranslation();
  const requestHandler = new QCHttpRequest();

  const getBinElements = async (request: GetBinElementsRequest) => {
    try {
      const response = await requestHandler.post<GetBinElementsResponse, GetBinElementsRequest>(
        BinApi.getBinElements,
        request,
      );
      const isValid = isResponseValid(response.data);
      if (isValid) return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) toast.error(error.response?.data.message);
    }
  };

  const deleteBinElements = async (request: DeleteBinElementsRequest) => {
    try {
      const response = await requestHandler.post<DeleteBinElementsResponse, DeleteBinElementsRequest>(
        BinApi.eraseBinElements,
        request,
      );
      const isValid = isResponseValid(response.data, t('bin.popup.delete.success'), t);
      if (isValid) return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) toast.error(error.response?.data.message);
    }
  };

  const restoreBinElements = async (request: RestoreBinElementRequest) => {
    try {
      const response = await requestHandler.post<RestoreBinElementsResponse, RestoreBinElementRequest>(
        BinApi.restoreBinElements,
        request,
      );
      const isValid = isResponseValid(response.data, t('bin.popup.restore.success'), t);
      if (isValid) return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) toast.error(error.response?.data.message);
    }
  };

  return { getBinElements, deleteBinElements, restoreBinElements };
};
