import API_HOST from './apiHost';
import { ApiRoutesType } from './type';

const API_ORGANIZATION = `${API_HOST}OU/`;

const ApiOrganization: ApiRoutesType = {
  getOrganizationalUnit: `${API_ORGANIZATION}getorganizationalunit`,
  getOrganizationalUnitList: `${API_ORGANIZATION}getorganizationalunitlist`,
  getCurrentUserOrganizationalUnitList: `${API_ORGANIZATION}getcurrentuserorganizationalunitlist`,
  updateOrganizationalUnit: `${API_ORGANIZATION}updateorganizationalunit`,
  createOrganizationalUnit: `${API_ORGANIZATION}createorganizationalunit`,
  deleteOrganizationalUnit: `${API_ORGANIZATION}deleteorganizationalunit`,
};

export default ApiOrganization;
