import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';

export const EditContainerStyle: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  // marginX: { xs: '2vw', lg: '10vw' },
  marginTop: { xs: '32px' },
  paddingX: '2vw',
  gap: 1,
};

export const InputsCardStyle = (theme: Theme) => ({
  display: 'flex',
  flexDirection: 'column',
  width: { xs: '90%', sm: 'fit-content' },
  maxWidth: '753px',
  backgroundColor: theme.palette.toolsBackground.main,
  border: `2px solid ${theme.palette.menuBorder.main}`,
  borderRadius: '8px',
  paddingX: { xs: '15px', sm: '50px' },
  paddingBottom: '30px',
  boxShadow: 'none',
  position: 'relative',
  justifyContent: 'center',
});

export const InputsGridContainerStyle: SxProps = {
  display: 'grid',
  gridTemplateColumns: {
    xs: 'repeat(1, 1fr)',
    sm: 'repeat(2, 1fr)',
    md: 'repeat(auto-fill, 1fr)',
    lg: 'repeat(2, 1fr)',
  },
  gridTemplateRows: {
    xs: 'repeat(2, 1fr)',
    sm: 'repeat(3, 1fr)',
    md: 'repeat(auto-fill, 1fr)',
    lg: 'repeat(3, 1fr)',
  },
  gridColumnGap: '50px',
  gridRowGap: '10px',
};

export const ActionButtonsContainer: SxProps = {
  position: { md: 'absolute' },
  right: '20px',
  bottom: '30px',
  display: 'flex',
  flexDirection: { md: 'column' },
  alignContent: 'center',
  marginBottom: { xs: '32px', md: '0' },
  gap: '8px',
};
