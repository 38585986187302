import { createContext, useContext } from 'react';
import RootStore from './RootStore';
import CustomLogger from '80.quickConnect.core/siteAdmin/logger/customLogger';

export const createStore = (logger: CustomLogger): RootStore => new RootStore(logger);

export const StoreContext = createContext<RootStore>({} as RootStore);

export const useStore = (): RootStore => useContext(StoreContext);
export const StoreProvider = StoreContext.Provider;
