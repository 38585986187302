import React from 'react';
import { LinkRouteType } from '../types';
import { HOME, LOGIN, LOGIN_ADAL } from '../paths';
import Login from '10.quickConnect.app/siteAdmin/components/domains/Login';
import LoginAdal from '10.quickConnect.app/siteAdmin/components/domains/Login/LoginAdal';

const ANONYMOUS_ROUTES: LinkRouteType[] = [
  // A compléter quand les composants seront créés
  { path: HOME, key: 'HOME', element: <Login /> } as LinkRouteType,
  { path: LOGIN, key: 'LOGIN', element: <Login /> } as LinkRouteType,
  { path: LOGIN_ADAL, key: 'LOGIN_ADAL', element: <LoginAdal /> } as LinkRouteType,
];

export default ANONYMOUS_ROUTES;
