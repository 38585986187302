import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { GetSharedListsRequest } from '../../SharedLists/services/requests';
import { DeleteFamilyRequest, GetWebhooksRequest, UpdateFamilyRequest } from '../services/requests';
import { useRessourcesServices } from '../services/services';
import { useFamilySettingsStore } from './FamilySettingsStore';
import { NewEntitySchemaAttribute } from './FamilyAttributesCard/types';
import { Family } from '90.quickConnect.Models/siteAdmin/models/families/family';
import useEffectOnce from '80.quickConnect.core/shared/hooks/useEffectOnce';
import { SupportedLanguage } from '90.quickConnect.Models/siteAdmin/models/internationalization/supportedLanguage';
import { capitalize } from '80.quickConnect.core/shared/helpers/capitalize';
import { useDeleteDialog } from '80.quickConnect.core/siteAdmin/hooks/useDeleteDialog';
import useSaveDialog from '80.quickConnect.core/siteAdmin/hooks/useSaveDialog';
import { SharedListType } from '90.quickConnect.Models/siteAdmin/enums/sharedList/sharedListType';
import { DeleteActionType } from '70.quickConnect.Components/siteAdmin/Other/DeleteConfirmationDialog/type';
import { useSafeNavigation } from '80.quickConnect.core/siteAdmin/hooks/useSafeNavigation';

export const useFamilySettingsData = () => {
  const { t } = useTranslation();
  const navigate = useSafeNavigation();
  const { entityId } = useParams();
  const { getFamily, getSupportedLanguages, updateFamily, deleteFamily, getSharedLists, getWebhooks } =
    useRessourcesServices();
  const {
    setAttributes,
    attributes,
    newAttributesIds,
    setSharedLists,
    setWebhooks,
    familyWebhookIds,
    setFamilyWebhookIds,
  } = useFamilySettingsStore();

  const [pageFamily, setPageFamily] = useState<Family>();
  const [editedFamily, setEditedFamily] = useState<Family>();

  const [supportedLanguages, setSupportedLanguages] = useState<Array<SupportedLanguage>>([]);

  const [parentName, setParentName] = useState<string>(
    t('entitySchema.entityDesigner.entitySchema.placeholder.parent.noParent'),
  );

  // appelé lors de la modification d'un input
  const familySetter = useCallback(
    <K extends keyof Family>(newValue: Family[K], attributeToChange: K) => {
      setEditedFamily((currEditedFam) => {
        if (!currEditedFam) return;
        const newEditedFam: Family = { ...currEditedFam };
        newEditedFam[attributeToChange] = newValue;
        return newEditedFam;
      });
    },
    [setEditedFamily],
  );

  // contient la valeur sélectionnée de la langue par défaut
  const languageValue = useMemo(
    () =>
      editedFamily?.defaultLanguage
        ? capitalize(supportedLanguages.find((el) => el.value === editedFamily.defaultLanguage)?.lang ?? '')
        : supportedLanguages[0]
        ? capitalize(supportedLanguages[0].lang)
        : '',
    [editedFamily, supportedLanguages],
  );

  const initFamily = useCallback(async () => {
    if (!entityId) {
      navigate(-1);
      return;
    }
    const schema = await getFamily({ id: entityId });
    if (schema) {
      setPageFamily(schema);
      setEditedFamily(schema);
      setAttributes(schema.attributes);
      setFamilyWebhookIds(schema.webhookIds);
      if (schema.parentId) {
        const parentSchema = await getFamily({ id: schema.parentId });
        setParentName(parentSchema?.name ?? t('entitySchema.entityDesigner.entitySchema.placeholder.parent.noParent'));
      }
    }
    //eslint-disable-next-line
  }, [entityId]);

  const initSuppLanguages = useCallback(async () => {
    const languages = await getSupportedLanguages();
    if (languages) {
      setSupportedLanguages(languages);
    }
  }, [getSupportedLanguages, setSupportedLanguages]);

  const initSharedLists = useCallback(async () => {
    const request: GetSharedListsRequest = {
      count: 1,
      filters: [],
      length: 1000,
      start: 0,
      textFilter: null,
    };
    const data = await getSharedLists(request);

    if (data) {
      const simpleSharedLists = data.filter((list) => list.listType === SharedListType.Simple);
      const hierarchicalSharedLists = data.filter((list) => list.listType === SharedListType.Hierarchical);
      setSharedLists(simpleSharedLists, hierarchicalSharedLists);
    }

    //eslint-disable-next-line
  }, []);

  const initWebhooks = useCallback(async () => {
    const request: GetWebhooksRequest = {
      start: 0,
      length: 1000,
      orderBy: null,
      textFilter: null,
      count: 1,
      filters: [],
    };
    const data = await getWebhooks(request);
    if (data) {
      setWebhooks(data.webhooks);
    }
    //eslint-disable-next-line
  }, []);

  useEffectOnce(() => {
    initFamily();
    initSuppLanguages();
    initSharedLists();
    initWebhooks();
  });

  const [openSaveDialog, setOpenSaveDialog, saveState, saveDispatch] = useSaveDialog();

  // on sauvegarde les attribut du store dans editedFamily, puis on ouvre la dialog
  const handleSave = () => {
    if (attributes.find((el) => el.name === '')) {
      toast.error('Merci de remplir les champs obligatoires');
      return;
    }
    familySetter(attributes, 'attributes');
    familySetter(familyWebhookIds, 'webhookIds');
    setOpenSaveDialog(true);
  };

  useEffect(() => {
    switch (saveState.isSaved) {
      case false:
        setOpenSaveDialog(false);
        break;
      case true:
        if (editedFamily) {
          const updateRequest: UpdateFamilyRequest = { ...editedFamily } as UpdateFamilyRequest;
          updateRequest.attributes = editedFamily.attributes.map((att) => {
            const attToSend = newAttributesIds.find((newid) => newid === att.id);
            if (attToSend) {
              const newAttToSend: NewEntitySchemaAttribute = {
                description: att.description,
                isSearchable: false,
                isSelectable: true,
                isSelected: false,
                listOrder: att.listOrder,
                name: att.name,
                sharedListId: att.sharedListId,
                type: att.type,
              };
              return newAttToSend;
            }
            return att;
          });
          updateFamily(updateRequest);
        }
        setOpenSaveDialog(false);
        break;
    }
    //eslint-disable-next-line
  }, [saveState]);

  const handleTranslate = () => {
    navigate(window.location.pathname.replace('settings', 'translation'));
  };

  // suppression de la famille

  const [openDeleteDialog, setOpenDeleteDialog, deleteState, deleteDispatch] = useDeleteDialog();

  const handleDelete = () => {
    setOpenDeleteDialog(true);
  };

  useEffect(() => {
    setOpenDeleteDialog(false);
    switch (deleteState.choice) {
      case DeleteActionType.SendToTrash:
        if (!pageFamily) break;
        const sendToTrashRequest: DeleteFamilyRequest = {
          ids: [pageFamily.id],
          sendToRecycleBin: true,
        };
        deleteFamily(sendToTrashRequest);
        navigate(-1);
        break;
      case DeleteActionType.Cancel:
        break;
      case DeleteActionType.Delete:
        if (!pageFamily) break;
        const deleteRequest: DeleteFamilyRequest = {
          ids: [pageFamily.id],
          sendToRecycleBin: false,
        };
        deleteFamily(deleteRequest);
        navigate(-1);
        break;
    }
    //eslint-disable-next-line
  }, [deleteState]);

  return {
    t,
    pageFamily,
    editedFamily,
    supportedLanguages,
    languageValue,
    familySetter,
    parentName,
    handleSave,
    openSaveDialog,
    saveDispatch,
    handleDelete,
    openDeleteDialog,
    deleteDispatch,
    handleTranslate,
  };
};
