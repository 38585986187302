import React from 'react';
import { Box } from '@mui/material';
import { ResCharacListProps } from './types';
import { ResCharacContext } from './context';
import useData from './hooks';
import CharacBoolInput from './CharacBool';
import CharacDateInput from './CharacDate';
import CharacNumberInput from './CharacNumber';
import CharacDateTimeInput from './CharacDateTime';
import CharacEmailInput from './CharacEmail';
import CharacSharedListInput from './CharacSharedList';
import CharacTextInput from './CharacText';
import { EntitySchemaAttributeTypes } from '90.quickConnect.Models/siteAdmin/enums/equipments/entitySchemaAttributeTypes';

const ResCharacList = ({ updateResourceData, resSettingsData, setUpdateResourceData }: ResCharacListProps) => {
  const { getLabel } = useData(resSettingsData);
  return (
    <ResCharacContext.Provider
      value={{
        resSettingsData: resSettingsData,
        setUpdateResourceData: setUpdateResourceData,
        getLabel: getLabel,
      }}
    >
      <Box sx={{ overflow: 'auto' }}>
        {updateResourceData &&
          updateResourceData.attributes.map((att) => {
            switch (att.type) {
              case EntitySchemaAttributeTypes.Bool:
                return <CharacBoolInput key={att.attributeId} att={att} />;
              case EntitySchemaAttributeTypes.Date:
                return <CharacDateInput key={att.attributeId} att={att} />;
              case EntitySchemaAttributeTypes.DateTime:
                return <CharacDateTimeInput key={att.attributeId} att={att} />;
              case EntitySchemaAttributeTypes.Int:
              case EntitySchemaAttributeTypes.Decimal:
                return <CharacNumberInput key={att.attributeId} att={att} />;
              case EntitySchemaAttributeTypes.Email:
                return <CharacEmailInput key={att.attributeId} att={att} />;
              case EntitySchemaAttributeTypes.SharedList:
              case EntitySchemaAttributeTypes.SharedListHierarchical:
                return <CharacSharedListInput key={att.attributeId} att={att} />;
              case EntitySchemaAttributeTypes.String:
                return <CharacTextInput key={att.attributeId} att={att} />;
            }
          })}
      </Box>
    </ResCharacContext.Provider>
  );
};

export default ResCharacList;
