import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import MenuWrapper from '../../shared/MenuWrapper';
import { CircleIcon } from '../Users/icons/Cercle';
import useClientsData from './hooks';
import { Content, MainBox } from './styles';
import AddClientForm from './Forms/addClient';
import { findNamesByIds } from './helpers';
import QCButtonGlobal from '70.quickConnect.Components/siteAdmin/Buttons/QCButtonGlobal';
import DeleteConfirmWithNames from '70.quickConnect.Components/siteAdmin/Other/DeleteConfirmWithNamesDialog';

const Clients = () => {
  const { t } = useTranslation();
  const {
    openDeleteDialog,
    deleteDispatch,
    isAddClientDialogOpen,
    setIsAddClientDialogOpen,
    handleConfirmAddClient,
    clients,
    selectedRowsMultipleAction,
    selectedRowAction,
  } = useClientsData();

  return (
    <MenuWrapper>
      <DeleteConfirmWithNames
        open={openDeleteDialog}
        dispatch={deleteDispatch}
        namesOfElementToDelete={
          findNamesByIds(clients, selectedRowAction?.rowId) ??
          findNamesByIds(clients, selectedRowsMultipleAction?.rowsId)
        }
      />
      <Box sx={MainBox}>
        <Box sx={Content}>
          {t('customer.customers.home').replace(/,/, ',\n')}
          <QCButtonGlobal
            datacy="add_client"
            className="client-creation"
            value={t('customer.customerDesigner.addClient')}
            startIcon={<ManageAccountsIcon />}
            endIcon={<AddIcon />}
            variant="contained"
            sx={{ width: '350px' }}
            action={() => setIsAddClientDialogOpen(true)}
          />
          <AddClientForm
            open={isAddClientDialogOpen}
            toggleIsOpen={setIsAddClientDialogOpen}
            handleConfirm={(value) => handleConfirmAddClient(value)}
          />
        </Box>
        <CircleIcon />
      </Box>
    </MenuWrapper>
  );
};

export default observer(Clients);
