import React, { ChangeEventHandler } from 'react';
import { Box, Checkbox, TextField } from '@mui/material';
import { AssociatedUserSettings, BaseUserSettings, UserSettingsType } from '../../Users/types';
import QCTextArea from '70.quickConnect.Components/siteAdmin/Inputs/QCTextArea';
import QCSelect from '70.quickConnect.Components/siteAdmin/Inputs/QCSelect';
import { SharedList } from '90.quickConnect.Models/siteAdmin/models/sharedList/sharedList';

export const renderParameter = (
  onChange: ChangeEventHandler<HTMLInputElement>,
  baseParam: BaseUserSettings,
  associatedParam?: AssociatedUserSettings,
  associatedSharedList?: SharedList,
) => {
  switch (baseParam.userParameterType) {
    case UserSettingsType.Boolean:
      return (
        <Checkbox
          checked={associatedParam ? (associatedParam.value === 'true' ? true : false) : false}
          sx={{ marginLeft: '7%' }}
          onChange={onChange}
        />
      );

    case UserSettingsType.Int:
      return (
        <TextField
          type="number"
          //   inputProps={{ inputMode: 'numeric', pattern: `[${baseParam}]` }}
          value={associatedParam?.value}
          sx={{
            margin: '5px',
            marginLeft: '7%',
            height: '2em',
            width: '7em',
            '> * ': { height: '2em', width: '7em' },
          }}
          onChange={onChange}
        />
      );

    case UserSettingsType.Decimal:
      return (
        <TextField
          type="number"
          inputProps={{ step: '.01' }}
          value={associatedParam?.value}
          sx={{
            margin: '5px',
            marginLeft: '7%',
            height: '2em',
            width: '7em',
            '> * ': { height: '2em', width: '7em' },
          }}
          onChange={onChange}
        />
      );

    case UserSettingsType.Text:
      return (
        <Box sx={{ marginLeft: '7%', width: '80%' }}>
          <QCTextArea value={associatedParam?.value} onChange={onChange} />
        </Box>
      );

    case UserSettingsType.SharedList:
      if (associatedSharedList && associatedParam) {
        const labels = associatedSharedList.data.map((data) => data.label);
        const selectedList = associatedSharedList.data.find((data) => data.value === associatedParam.value);
        let selectedLabel: string | undefined;
        if (selectedList) {
          selectedLabel = selectedList.label;
        } else selectedLabel = undefined;

        // TODO : onChange doit renvoyer la valeur et non le label
        return (
          <Box sx={{ marginLeft: '7%', width: '80%' }}>
            <QCSelect defaultValue={selectedLabel} options={labels} setOption={() => {}} onChange={onChange} />
          </Box>
        );
      }

      return <></>;
    default:
      return <></>;
  }
};
