import { useContext, useEffect, useState } from 'react';
import { QCDialogFormContext } from '../../context';
import { BodyContext } from '../../StepBody/context';
import { ControlProps } from '../../StepBody/type';
import { AttributeValue } from '90.quickConnect.Models/siteAdmin/models/common/entities';

const useData = (
  label: string,
  mandatory: boolean,
  control?: ControlProps,
  attributData?: AttributeValue,
  type?: string,
) => {
  const { addMandatoryField, setFilled, index, addControlledField, setValid } = useContext(BodyContext);
  const { allData, updateAllDataArray, addFieldAllDataArray } = useContext(QCDialogFormContext);

  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    if (attributData) {
      if (type === 'number') {
        attributData.value = new Number(defaultValue);
      } else {
        attributData.value = new String(defaultValue);
      }
      addFieldAllDataArray(label, defaultValue, index);
    } else {
      addFieldAllDataArray(label, defaultValue, index);
    }
    //eslint-disable-next-line
  }, [defaultValue, addFieldAllDataArray, label, index]);

  useEffect(() => {
    if (allData[index - 1] && allData[index - 1].data) {
      const inputData = allData[index - 1].data;
      const valueIndex = inputData.findIndex((el) => el.label === label);
      if (valueIndex !== -1) {
        const { value } = inputData[valueIndex];
        if (typeof value === 'string') {
          setDefaultValue(value);
          if (mandatory) {
            addMandatoryField({ label: label, filled: value !== '' });
          }
          if (control !== undefined) {
            addControlledField({
              label: label,
              valid: control.regex.exec(value) != null || (!mandatory && value === ''),
            });
          }
        }
      }
    }
  }, [allData, addMandatoryField, addControlledField, index, label, mandatory, control]);

  useEffect(() => {
    setFilled(label, defaultValue !== '');
    if (control) setValid(label, control.regex.exec(defaultValue) != null || (!mandatory && defaultValue === ''));
    // eslint-disable-next-line
  }, [defaultValue]);

  const handleChange = (value: string | AttributeValue) => {
    if (typeof value === 'string') {
      setFilled(label, value === '' ? false : true);
      if (control) setValid(label, control.regex.exec(value) != null || (!mandatory && value === ''));
      updateAllDataArray(label, value, index);
      setDefaultValue(value);
    } else {
      const strValue = String(value.value);
      setFilled(label, strValue === '' ? false : true);
      if (control) setValid(label, control.regex.exec(strValue) != null || (!mandatory && strValue === ''));
      updateAllDataArray(label, value, index);
      setDefaultValue(strValue);
    }
  };
  return { handleChange, defaultValue };
};

export default useData;
