import { Dispatch, MouseEventHandler, ReactElement, SetStateAction } from 'react';
import { IconProps, Theme } from '@mui/material';
import { TFunction } from 'react-i18next';
import { ChoiceMenuOptionType } from '70.quickConnect.Components/siteAdmin/Inputs/QCChoiceMenu/type';

type FolderViewProps =
  | {
      ennableFolders: true;
      getIsViewFolder: Dispatch<SetStateAction<boolean>>;
    }
  | {
      ennableFolders?: undefined | false;
      getIsViewFolder?: never;
    };

export type SubMenuProps = FolderViewProps & {
  rows: SubMenuRow[];
  sortingTypes?: string[];
  getSelectedSortingType?: Dispatch<SetStateAction<string>>;
  multipleActions?: ChoiceMenuOptionType[];
  getSelectedMultipleAction?: Dispatch<SetStateAction<{ option: string; rowsId: string[] }>>;
  rowActions?: ChoiceMenuOptionType[];
  getSelectedRowAction?: Dispatch<SetStateAction<{ option: string; rowId: string }>>;
  getSearchValue?: (value: string) => void;
  path?: ReactElement;
  handleAddClick?: MouseEventHandler;
  addIcon?: ReactElement<IconProps>;
  addText?: string;
  onRowClicked?: (id: string) => void;
};

export type SubMenuContextType = {
  rowActions?: ChoiceMenuOptionType[];
  setSelectedRowAction: Dispatch<SetStateAction<{ option: string; rowId: string }>>;
  onRowClicked?: (id: string) => void;
  showCheckbox: boolean;
  openedFolders: string[];
  setOpenedFolders: Dispatch<SetStateAction<string[]>>;
  selectedRowsId: string[];
  handleRowCheckboxClick: (id: string) => void;
  width: number;
};

export type SubMenuRowBase = {
  id: string;
  name: string;
  childrenCount: number;
};

export type SubMenuRowItem = SubMenuRowBase &
  (
    | {
        type: 'item' | 'form';
        startIcon: ReactElement<IconProps>;
      }
    | {
        type: 'family'; // pareil que item mais peut avoir des enfants
        startIcon: ReactElement<IconProps>;
        childrenItems?: SubMenuRow[];
        parentId?: string;
      }
  );

export type SubMenuRowFolder = SubMenuRowBase & {
  type: 'folder';
  childrenItems?: SubMenuRow[];
};

export type SubMenuRow = SubMenuRowItem | SubMenuRowFolder;

export const isSubMenuRowItem = (row: SubMenuRow): row is SubMenuRowItem => {
  if (row.type === 'item' || row.type === 'form' || row.type === 'family') return true;
  return false;
};

export const isSubMenuRowFolder = (row: SubMenuRow): row is SubMenuRowFolder => {
  if (row.type === 'folder') return true;
  return false;
};

export type UseDataProps = {
  theme: Theme;
  t: TFunction;
  showMultipleActions: boolean;
  setShowMultipleActions: Dispatch<SetStateAction<boolean>>;
  rowHoverId: number;
  setRowHoverId: Dispatch<SetStateAction<number>>;
  selectedRowsIndex: number[];
  handleActionCheckboxClick: MouseEventHandler;
  handleRowCheckboxClick: (index: number) => void;
  actionMenuAnchor: HTMLElement | null;
  setActionMenuAnchor: Dispatch<SetStateAction<HTMLElement | null>>;
  handleMultipleActionClick: MouseEventHandler<HTMLElement>;
  handleMultipleActionSelectedOption: (option: string) => void;
};
