import { Dispatch } from 'react';
import { UpdateResourceRequest } from '../../../services/requests';
import { DesignResourceResponse } from '../../../services/response';
import { AttributeValue } from '90.quickConnect.Models/siteAdmin/models/common/entities';

const useData = (
  resSettingsData: DesignResourceResponse | undefined,
  setUpdateResourceData: Dispatch<React.SetStateAction<UpdateResourceRequest | undefined>>,
) => {
  const getValue = (attribut: AttributeValue) => {
    return attribut.value !== null ? (String(attribut.value) as string) : undefined;
  };

  const getOptions = (attId: string) => {
    const sharedListId = resSettingsData?.schema.attributes.find((el) => el.id === attId)?.sharedListId;
    const options =
      resSettingsData?.sharedLists.find((li) => li.id === sharedListId)?.data.map((opt) => opt.label) ?? [];
    return options;
  };

  const handleChange = (value: string, attId: string) => {
    setUpdateResourceData((prevRes) => {
      if (prevRes) {
        const newRes = { ...prevRes };
        const attIndex = newRes.attributes.findIndex((a) => a.attributeId === attId);
        newRes.attributes[attIndex].value = new String(value);
        return newRes;
      }
    });
  };

  return {
    getValue,
    getOptions,
    handleChange,
  };
};

export default useData;
