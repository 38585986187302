import { useCallback, useMemo } from 'react';
import { AxiosError } from 'axios';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { CreateClientResponse, GetClientResponse } from './responses';
import { CreateClientRequest, UpdateClientRequest } from './requests';
import QCHttpRequest from '40.quickConnect.DataAcces/siteAdmin/QCHttpRequest';
import ApiClients from '40.quickConnect.DataAcces/siteAdmin/apiRoutes/clients';

const useClientSettingsServices = () => {
  const params = useParams();
  const { t } = useTranslation();
  const requestHandler = useMemo(() => new QCHttpRequest(), []);

  const getClientRequest = useCallback(async () => {
    const result = await requestHandler
      .get(ApiClients.getClientSettings + params.clientId)
      .then((response) => {
        if (response.status === 200) return response.data as GetClientResponse;
      })
      .catch((error) => {
        const err = error as AxiosError;
        toast.error(err.message);
      });

    return result;
  }, [params, requestHandler]);

  const updateClientRequest = useCallback(
    async (request: UpdateClientRequest) => {
      const result = await requestHandler
        .post<GetClientResponse, UpdateClientRequest>(ApiClients.updateClientSettings, request)
        .then((response) => {
          if (response.status === 200) {
            toast.success(t('customer.customerDesigner.success.save', { customerName: response.data.customer.name }));
            return response.data;
          }
        })
        .catch((error) => {
          const err = error as AxiosError;
          toast.error(err.message);
        });
      return result;
    },
    [t, requestHandler],
  );

  const deleteClientRequest = useCallback(
    async (clientName: string) => {
      const res = requestHandler
        .get(ApiClients.deleteClient, { params: { Name: clientName } })
        .then((response) => {
          if (response.status === 200) {
            toast.success(t('customer.customerDesigner.success.delete'));
            return true;
          }
        })
        .catch((error) => {
          const err = error as AxiosError;
          toast.error(err.message);
          return false;
        });
      return res;
    },
    [t, requestHandler],
  );

  const createClientRequest = useCallback(
    async (request: CreateClientRequest) => {
      const result = requestHandler
        .post<CreateClientResponse, CreateClientRequest>(ApiClients.createClient, request)
        .then((response) => {
          if (response.status === 200) {
            toast.success(t('customer.customerDesigner.success.save', { customerName: response.data.customer.name }));
            return response.data;
          }
        })
        .catch((error) => {
          const err = error as AxiosError;
          toast.error(err.message);
        });
      return result;
    },
    [t, requestHandler],
  );

  return {
    getClientRequest,
    updateClientRequest,
    deleteClientRequest,
    createClientRequest,
  };
};

export default useClientSettingsServices;
