import { useState, useEffect, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useTheme } from '@mui/material';
import useOrganizationServices from '../Users/services/organization';
import { UpdateUserRequest } from '../Users/services/requests';
import { DesignUserResponse } from '../Users/services/responses';
import { useUserServices } from '../Users/services/users';
import { OrganizationToTreeViewNodes } from '../Users/Forms/hooks';
import { DeleteActionType } from '70.quickConnect.Components/siteAdmin/Other/DeleteConfirmationDialog/type';
import { TreeViewNode } from '70.quickConnect.Components/siteAdmin/Other/TreeView/types';
import useEffectOnce from '80.quickConnect.core/shared/hooks/useEffectOnce';
import { useDeleteDialog } from '80.quickConnect.core/siteAdmin/hooks/useDeleteDialog';
import useSaveDialog from '80.quickConnect.core/siteAdmin/hooks/useSaveDialog';
import { useSafeNavigation } from '80.quickConnect.core/siteAdmin/hooks/useSafeNavigation';

export const useData = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { userId } = useParams();
  const navigate = useSafeNavigation();

  const { getUserDesign, updateUser, deleteUser } = useUserServices();

  const { getOrganizationalUnitList } = useOrganizationServices();
  const [organizationOptions, setOrganizationOptions] = useState<TreeViewNode[]>([]);
  const [isOpenBackDrop, setIsOpenBackDrop] = useState(false);

  const [pageUser, setPageUser] = useState<DesignUserResponse | null>(null);
  const [updatedUser, setUpdatedUser] = useState<UpdateUserRequest>();

  const [openSaveDialog, setOpenSaveDialog, saveState, saveDispatch] = useSaveDialog();

  const [openDeleteDialog, setOpenDeleteDialog, deleteState, deleteDispatch] = useDeleteDialog();

  const [hasChanged, setHasChanged] = useState(false);

  const handleSave = () => {
    if (pageUser?.canSave) setOpenSaveDialog(true);
  };

  const handleDelete = () => {
    if (pageUser?.canDelete) setOpenDeleteDialog(true);
  };

  useEffect(() => {
    switch (saveState.isSaved) {
      case true:
        setOpenSaveDialog(false);
        if (updatedUser) {
          updateUser(updatedUser);
        }
        break;
      case false:
        setOpenSaveDialog(false);
        break;
    }
    //eslint-disable-next-line
  }, [saveState]);

  useEffect(() => {
    switch (deleteState.choice) {
      case DeleteActionType.Delete:
        setOpenDeleteDialog(false);
        if (pageUser) {
          deleteUser({ ids: [pageUser.userToDesign.id] });
          navigate(-1);
        }
        break;
      case DeleteActionType.Cancel:
      default:
        setOpenDeleteDialog(false);
        break;
    }
    //eslint-disable-next-line
  }, [deleteState]);

  const initUser = useCallback(async () => {
    if (!userId) {
      navigate(-1);
      return;
    }
    const request = {
      id: userId,
    };
    const user = await getUserDesign(request);
    if (user === null) {
      navigate(-1);
      return;
    }
    setPageUser(user);

    const { userToDesign, currentUserOrganizationalUnits, currentUserProfils, currentUserParameter } = user;
    setUpdatedUser({
      name: '',
      ...userToDesign,

      profilIds: currentUserProfils,
      ouId: currentUserOrganizationalUnits[0],
      userParams: currentUserParameter,
    });
    //eslint-disable-next-line
  }, [userId]);

  useEffect(() => {
    initUser();
  }, [initUser]);

  const isProfilChecked = useCallback(
    (profilId: string): boolean => {
      if (updatedUser?.profilIds.includes(profilId)) {
        return true;
      }
      return false;
    },
    [updatedUser],
  );

  const handleProfilCheck = (profilId: string, checked: boolean) => {
    if (!updatedUser) return;

    let newProfilIds = updatedUser.profilIds;

    if (checked) {
      newProfilIds.push(profilId);
    } else {
      newProfilIds = newProfilIds.filter((el) => el !== profilId);
    }

    // pour provoquer le rerender
    setHasChanged(!hasChanged);

    setUpdatedUser((currUpdatedUser) => {
      if (currUpdatedUser) {
        const newUpdatedUser = { ...currUpdatedUser };
        newUpdatedUser.profilIds = newProfilIds;
        return newUpdatedUser;
      }
    });
  };

  const isParamChecked = (paramId: string): boolean => {
    if (updatedUser?.userParams.map((param) => param.userParameterId).includes(paramId)) {
      return true;
    }

    return false;
  };

  const handleParamCheck = (paramId: string, checked: boolean) => {
    if (!updatedUser) return;

    let newParams = updatedUser.userParams;

    // si c'est checked; on rajoute le param
    if (checked && pageUser) {
      const paramToAdd = pageUser.availableUserParameter.find((el) => el.id === paramId);

      if (!paramToAdd) return;

      // si le paramètre possède une valeur par défaut, on le renseigne
      let defaultValue = '';
      if (
        typeof paramToAdd.value === 'object' &&
        paramToAdd.value !== null &&
        !Array.isArray(paramToAdd.value) &&
        'defaultValue' in paramToAdd.value
      ) {
        defaultValue = `${paramToAdd.value.defaultValue}`;
      }
      newParams.push({ userParameterId: paramId, value: defaultValue });
    } else {
      newParams = newParams.filter((el) => el.userParameterId !== paramId);
    }

    // pour provoquer le rerender
    setHasChanged(!hasChanged);
    setUpdatedUser((currUpdatedUser) => {
      if (currUpdatedUser) {
        const newUpdatedUser = { ...currUpdatedUser };
        newUpdatedUser.userParams = newParams;
        return newUpdatedUser;
      }
    });
  };

  const handleParamChange = (value: string, paramId: string) => {
    if (!updatedUser) return;
    const paramToChange = updatedUser.userParams.find((el) => el.userParameterId === paramId);
    // si c'est déjà un paramètre associé
    if (paramToChange) {
      const indexToChange = updatedUser.userParams.findIndex((el) => el.userParameterId === paramId);
      paramToChange.value = value;
      const newUserParams = updatedUser.userParams;
      newUserParams.splice(indexToChange, 1, paramToChange);
      setUpdatedUser((currUpdatedUsers) => {
        if (currUpdatedUsers) {
          const newUpdatedUser = { ...currUpdatedUsers };
          newUpdatedUser.userParams = newUserParams;
          return newUpdatedUser;
        }
      });
      setHasChanged(!hasChanged);
      return;
    }

    // s'il n'était pas associé avant
    const newUserParams = updatedUser.userParams;
    newUserParams.push({ userParameterId: paramId, value: value });
    setUpdatedUser((currUpdatedUser) => {
      if (currUpdatedUser) {
        const newUpdatedUser = { ...currUpdatedUser };
        newUpdatedUser.userParams = newUserParams;
        return newUpdatedUser;
      }
    });
    setHasChanged(!hasChanged);
  };

  const initOrganizationalUnitList = useCallback(async () => {
    setIsOpenBackDrop(true);
    const ret = await getOrganizationalUnitList();
    setOrganizationOptions(OrganizationToTreeViewNodes(ret.organizationalUnits));
    setIsOpenBackDrop(false);
  }, [getOrganizationalUnitList]);

  useEffectOnce(() => {
    initOrganizationalUnitList();
  });

  return {
    t,
    theme,
    pageUser,
    updatedUser,
    setUpdatedUser,
    openSaveDialog,
    saveDispatch,
    openDeleteDialog,
    deleteDispatch,
    handleSave,
    handleDelete,
    isProfilChecked,
    handleProfilCheck,
    isParamChecked,
    handleParamCheck,
    handleParamChange,
    organizationOptions,
    isOpenBackDrop,
  };
};
