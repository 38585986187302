import React from 'react';
import { Box, Dialog, Divider, IconButton, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import { useTranslation } from 'react-i18next';
import { DownloadReportDialogProps } from './types';
import { useDownloadReportDialogData } from './hooks';
import QCChecboxButtonField from '70.quickConnect.Components/siteAdmin/Inputs/QCChecboxButton';
import QCButtonGlobal from '70.quickConnect.Components/siteAdmin/Buttons/QCButtonGlobal';

export const DownloadReportDialog = ({ open, onClose, printableTemplate }: DownloadReportDialogProps) => {
  const { t } = useTranslation();
  const { isCheckedFormats, handleCheckFormatPdf, handleCheckFormatOfModel, handleClose } =
    useDownloadReportDialogData(onClose);

  return (
    <Dialog
      open={open}
      PaperProps={{
        sx: {
          width: '600px',
          height: '375px',
          backgroundColor: 'background',
          border: '2px solid #E3F2F0',
          borderRadius: '16px',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          marginX: '2vh',
          textAlign: 'center',
          position: 'relative',
          justifyContent: 'center',
          alignItems: 'center',
          my: 3,
        }}
      >
        <Typography sx={{ fontWeight: 400, fontSize: '20px' }}>{t('declaration.report.actions.download')}</Typography>
        <IconButton sx={{ position: 'absolute', right: 0 }} onClick={() => handleClose(false)}>
          <CloseIcon fontSize="small" />
        </IconButton>
      </Box>
      <Divider sx={{ border: '1px solid #E3F2F0' }} />
      <Box sx={{ mx: 5 }}>
        <Box sx={{ my: 2 }}>
          <Typography gutterBottom sx={{ textTransform: 'uppercase', fontWeight: 500 }}>
            {t('form.detail.popup.saveAs.template')}
          </Typography>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              alignItems: 'center',
              border: '2px solid #B39875',
              borderRadius: '8px',
              backgroundColor: '#FAF5EB',
              p: 1,
            }}
          >
            <ContentPasteIcon fontSize="small" />
            <Typography>{printableTemplate?.fileName}</Typography>
            <Typography>{printableTemplate?.description}</Typography>
          </Box>
        </Box>
        <Box sx={{ my: 2 }}>
          <Typography gutterBottom sx={{ textTransform: 'uppercase', fontWeight: 500 }}>
            {t('shared.message.promptMessageDownloadTemplates.title')}
          </Typography>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <QCChecboxButtonField
              isCheck={isCheckedFormats.isCheckedFormatPdf}
              label={t('shared.message.promptMessageDownloadTemplates.asPdf')}
              dispatch={handleCheckFormatPdf}
            />
            <QCChecboxButtonField
              isCheck={isCheckedFormats.isCheckedFormatModel}
              label={t('shared.message.promptMessageDownloadTemplates.asModelFormat')}
              dispatch={handleCheckFormatOfModel}
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', mx: 5 }}>
        <QCButtonGlobal className="button-cancel" variant="text" value="annuler" action={() => handleClose(false)} />
        <QCButtonGlobal className="button-confirm" value="Valider" action={() => handleClose(true)} />
      </Box>
    </Dialog>
  );
};
