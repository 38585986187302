import { SxProps, Theme } from '@mui/material';
import { pureWhiteColor, textBlackColor } from '00.app/theme/colors';
import MenuBackground from 'static/menuBackground.png';
import MenuBackgroundFolded from 'static/menuBackgroundfolded.png';

export const MenuBoxStyle: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'stretch',
  height: '100%',
};

export const DrawerBoxStyle = (theme: Theme, isOpenSideMenu: boolean): SxProps => {
  return {
    '& .MuiDrawer-paper': {
      mx: '1.5vw',
      my: '2vh',
      height: '96vh',
      borderRight: 'none',
      backgroundSize: 'contain',
      backgroundPosition: 'center center',
      backgroundImage: isOpenSideMenu ? `url(${MenuBackground})` : `url(${MenuBackgroundFolded})`,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.standard,
      }),
      ...{
        [theme.breakpoints.up('lg')]: {
          width: isOpenSideMenu ? '300px' : '80px',
          borderTopLeftRadius: theme.direction === 'ltr' ? 24 : 0,
          borderTopRightRadius: theme.direction === 'rtl' ? 24 : 0,
        },
        [theme.breakpoints.between('md', 'lg')]: {
          width: isOpenSideMenu ? '275px' : '64px',
          borderTopLeftRadius: theme.direction === 'ltr' ? 20 : 0,
          borderTopRightRadius: theme.direction === 'rtl' ? 20 : 0,
        },
        [theme.breakpoints.down('md')]: {
          width: isOpenSideMenu ? '250px' : '56px',
          borderTopLeftRadius: theme.direction === 'ltr' ? 16 : 0,
          borderTopRightRadius: theme.direction === 'rtl' ? 16 : 0,
        },
      },
      position: 'fixed',
      zIndex: 0,
      scrollbarWidth: 'none',
    },
  };
};

export const OpenedMenuBoxStyle: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  position: 'relative',
  '& .MuiIconButton-root': {
    position: 'absolute',
    right: 0,
  },
  '&:hover .MuiIconButton-root': {
    borderRadius: '50%',
  },
  '.MuiTouchRipple-ripple .MuiTouchRipple-child': {
    backgroundColor: 'transparent',
  },
};

export const FoldedMenuBoxStyle: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  marginTop: '1.5vw',
};

export const ButtonsBoxStyles: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'stretch',
};

export const buttonStyles = (theme: Theme, hasFocus: boolean, isOpenSideMenu: boolean): SxProps => {
  return {
    display: 'flex',
    justifyContent: isOpenSideMenu ? 'flex-start' : 'center',
    mx: '5%',
    my: '2%',
    color: hasFocus ? textBlackColor : pureWhiteColor,
    fontWeight: 'bold',
    fontSize: '100%',
    borderRadius: '8px',
    textAlign: 'start',
    ...(hasFocus && {
      backgroundColor: theme.palette.primary.main,
      '&:hover': {
        backgroundColor: theme.palette.primary.main,
      },
    }),
    ...{
      [theme.breakpoints.up('lg')]: {
        '& .MuiButton-iconSizeMedium': {
          '& > *:first-of-type': {
            fontSize: 35,
          },
          marginRight: theme.direction === 'rtl' ? 0 : 'inherited',
          marginLeft: theme.direction === 'rtl' ? '12px' : 'inherited',
        },
        '& .MuiSvgIcon-root': {
          fontSize: 35,
        },
      },
      [theme.breakpoints.between('md', 'lg')]: {
        '& .MuiButton-iconSizeMedium': {
          '& > *:first-of-type': {
            fontSize: 30,
          },
          marginRight: theme.direction === 'rtl' ? 0 : 'inherited',
          marginLeft: theme.direction === 'rtl' ? '10px' : 'inherited',
        },
        '& .MuiSvgIcon-root': {
          fontSize: 30,
        },
      },
      [theme.breakpoints.down('md')]: {
        '& .MuiButton-iconSizeMedium': {
          '& > *:first-of-type': {
            fontSize: 25,
          },
          marginRight: theme.direction === 'rtl' ? 0 : 'inherited',
          marginLeft: theme.direction === 'rtl' ? '8px' : 'inherited',
        },
        '& .MuiSvgIcon-root': {
          fontSize: 25,
        },
      },
    },
  };
};
