import React, { ChangeEvent, MouseEvent } from 'react';
import { Box, IconButton, List, ListItem, ListItemText } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import AddIcon from '@mui/icons-material/Add';
import { useTranslation } from 'react-i18next';
import { DndProvider, DropTargetMonitor, useDrop } from 'react-dnd';
import { HTML5Backend, NativeTypes } from 'react-dnd-html5-backend';
import QCIconButton from '../../Buttons/QCIconButton';
import { FileInputProps } from './types';
import { useListFilesBoxSxProps, useAddFileBoxSxprops } from './styles';

const QCPhotoInput = ({ files, setFiles, handleChange, maxFiles = 1 }: FileInputProps) => {
  const { t } = useTranslation();

  const uploadFiles = (event: ChangeEvent<HTMLInputElement>) => {
    if (event.target.files && event.target.files.length <= maxFiles && files.length < maxFiles) {
      const [...inputFiles] = event.target.files;
      setFiles(files.concat(inputFiles));
      handleChange(files.concat(inputFiles));
    }
  };
  const FilesInput = React.useRef<HTMLInputElement | null>(null);

  const handleFilesInput = () => {
    FilesInput.current?.click();
  };

  // const readFile = (index: number, event: MouseEvent<HTMLButtonElement>) => {
  //   event.preventDefault();
  //   const theFile = files[index];
  //   const blob = new Blob([theFile], { type: theFile.type });
  //   const fileUrl = URL.createObjectURL(blob);
  //   window.open(decodeURIComponent(fileUrl), '_blank', 'noopener,noreferrer');
  //   URL.revokeObjectURL(fileUrl);
  // };

  const deleteFile = (index: number, event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    setFiles(files.filter((file) => file != files[index]));
  };

  const AddFilesComponent = (props: { filesList: File[] }) => {
    // eslint-disable-next-line
    const [, dropRef] = useDrop(
      () => ({
        accept: [NativeTypes.FILE],
        drop: (item: { files: File[] }) => setFiles(props.filesList.concat(item.files)),
        collect: (monitor: DropTargetMonitor) => ({
          canDrop: !!monitor.canDrop(),
          isOver: !!monitor.isOver(),
        }),
      }),
      [],
    );
    return (
      <Box sx={useAddFileBoxSxprops} ref={dropRef} component="label">
        <input type="file" multiple onChange={uploadFiles} hidden />
        {t('uicomponents.qcfileinput.textinitialcard')}
      </Box>
    );
  };

  if (files.length == 0) {
    return (
      <DndProvider backend={HTML5Backend}>
        <AddFilesComponent filesList={files} />
      </DndProvider>
    );
  }

  return (
    <Box sx={useListFilesBoxSxProps}>
      <List sx={{ overflow: 'auto', scrollbarWidth: 'none' }}>
        {files &&
          files.map((file: File, index: number) => {
            return (
              <ListItem
                key={index}
                secondaryAction={
                  <>
                    <IconButton
                      name="delete"
                      title="supprimer"
                      color={'inputListFileIconColors'}
                      onClick={(e) => deleteFile(index, e)}
                    >
                      <ClearIcon name="delete" />
                    </IconButton>
                  </>
                }
              >
                <Box>
                  <img src={URL.createObjectURL(file)} width="90%" height="90%" />
                  <ListItemText sx={{ '& .MuiTypography-root': { fontWeight: 'bold' } }} primary={file.name} />
                </Box>
              </ListItem>
            );
          })}
      </List>
      <input ref={FilesInput} type="file" multiple onChange={uploadFiles} hidden />
      {files.length < maxFiles ? (
        <QCIconButton
          variantValue="outlined"
          onClickFunc={handleFilesInput}
          Icon={<AddIcon />}
          sx={{ position: 'absolute', bottom: '15px', right: '15px', width: 44, height: 44 }}
        />
      ) : (
        <></>
      )}
    </Box>
  );
};

export default QCPhotoInput;
