import Dexie, { Table } from 'dexie';
import { ClearDataBase } from './interface/clearDataBase';
import { LogConfiguration, TelemetryLogDB } from '80.quickConnect.core/siteAdmin/logger/type';
import CustomLogger from '80.quickConnect.core/siteAdmin/logger/customLogger';

export class LogDb extends Dexie implements ClearDataBase {
  logs!: Table<LogConfiguration>;

  // ATTENTION: Toute modification du schema doit être suivi d'une montée de version de la base de données afin de ne pas créer des crashs!! NE PAS CHANGER LES CLES PRIMAIRES DIRECTEMENT
  constructor() {
    super('logDb');
    this.version(1).stores({
      logs: 'id',
    });
  }

  async clearAllTables(logger: CustomLogger) {
    try {
      await this.logs.clear();
    } catch (error) {
      logger.error(error);
    }
  }

  /**
   *
   * @param logger Référence à la classe CustomLogger
   * @returns Promise<TelemetryLogDB | undefined>
   */
  getLogs = async (logger: CustomLogger): Promise<TelemetryLogDB | undefined> => {
    try {
      const logs = await this.logs.toArray();

      const items = logs.map((log) => log.item);
      const crashs: string[] = logs.reduce((acc: string[], current) => {
        return current.CrashDump !== null ? [...acc, current.CrashDump as string] : acc;
      }, []);

      return {
        Items: items,
        CrashDump: crashs.length === 0 ? null : crashs,
      } as TelemetryLogDB;
    } catch (error) {
      logger.error('La récupération des logs dans la table des logs à échoué', { CrashDump: error });
    }
  };
}

const logDb = new LogDb();

export default logDb;
