import { SubMenuRowItem } from '../../shared/SubMenu/types';

export const findNamesByIds = (entities: SubMenuRowItem[], Ids: string | string[] | undefined) => {
  if (Ids === undefined) return undefined;
  const foundEntities = entities.filter((el) => Ids.includes(el.id));
  switch (foundEntities.length) {
    case 0:
      return undefined;
    default:
      return foundEntities.map((el) => el.name);
  }
};
