import React, { useState } from 'react';
import { Box } from '@mui/material';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import MenuWrapper from '../../shared/MenuWrapper';
import useData from './hooks';
import { mainBoxSx } from './style';
import { ConnectionHistoryContext } from './context';
import DataTable from '70.quickConnect.Components/siteAdmin/Other/DataTable';
import ContentHeader from '70.quickConnect.Components/siteAdmin/Other/ContentHeader';

const ConnectionHistory = () => {
  const { rows, cols, setSearchText, expandCell, requestHandler, sortingProps, isLoading, pageSize } = useData();

  const [selectedRowId, setSelectedRowId] = useState(-1);
  const { t } = useTranslation();

  return (
    <MenuWrapper>
      <Box sx={mainBoxSx}>
        <ConnectionHistoryContext.Provider value={{ selectedRowId: selectedRowId, setSelectedRowId: setSelectedRowId }}>
          <ContentHeader>
            <ContentHeader.Title>{t('navigation.menu.connections')}</ContentHeader.Title>
          </ContentHeader>
          <DataTable
            rows={rows}
            columns={cols}
            getSearchValue={setSearchText}
            kebabMenu={expandCell}
            requestHandler={requestHandler}
            sortingProps={sortingProps}
            isLoading={isLoading}
            isSearchBar={false}
            isReadOnly={true}
            rowActions={[]}
            pageSize={pageSize}
          />
        </ConnectionHistoryContext.Provider>
      </Box>
    </MenuWrapper>
  );
};

export default observer(ConnectionHistory);
