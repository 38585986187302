import React from 'react';
import { useTranslation } from 'react-i18next';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import HelpCenterOutlinedIcon from '@mui/icons-material/HelpCenterOutlined';
import { FormType } from '90.quickConnect.Models/siteAdmin/enums/forms/formType';
import WorkflowIcon from '00.app/icons/WorkflowIcon';
import FormTemplateIcon from '00.app/icons/FormTemplateIcon';

export const useReadFormType = () => {
  const { t } = useTranslation();

  const readFormType = (formType: FormType) => {
    switch (formType) {
      case FormType.Form:
        return t('core.helpers.common.formType.form');
      case FormType.Template:
        return t('core.helpers.common.formType.template');
      case FormType.Workflow:
        return t('core.helpers.common.formType.workflow');
      case FormType.Unknow:
        return t('core.helpers.common.formType.unknow');
    }
  };

  //retourne une icone en fonction du type de formulaire
  const drawFormType = (formType: FormType) => {
    switch (formType) {
      case FormType.Form:
        return <InsertDriveFileOutlinedIcon />;
      case FormType.Workflow:
        return <WorkflowIcon />;
      case FormType.Template:
        return <FormTemplateIcon />;
      case FormType.Unknow:
      default:
        return <HelpCenterOutlinedIcon />;
    }
  };
  return { readFormType, drawFormType };
};
