import { SxProps } from '@mui/material';

export const DrawerStyles: SxProps = {
  '& .MuiDrawer-paper': {
    width: '65vw',
    maxWidth: '300px',
    borderLeft: 'none',
    borderTopLeftRadius: 16,
    backgroundSize: 'contain',
    backgroundPosition: 'center center',
    backgroundImage: 'url(assets/menuBackground.png)',
  },
};
