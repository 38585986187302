import { useContext, useEffect, useState } from 'react';
import { QCDialogFormContext } from '../../context';
import { BodyContext } from '../../StepBody/context';

const useData = (label: string, mandatory: boolean) => {
  const { addMandatoryField, setFilled, index } = useContext(BodyContext);
  const { allData, updateAllDataArray, addFieldAllDataArray } = useContext(QCDialogFormContext);
  const [defaultValue, setDefaultValue] = useState('');

  useEffect(() => {
    addFieldAllDataArray(label, defaultValue, index);
  }, [defaultValue, addFieldAllDataArray, label, index]);

  useEffect(() => {
    if (allData[index - 1] && allData[index - 1].data) {
      const inputData = allData[index - 1].data;
      const valueIndex = inputData.findIndex((el) => el.label === label);
      if (valueIndex !== -1) {
        const { value } = inputData[valueIndex];
        if (typeof value === 'string') {
          setDefaultValue(value);
          if (mandatory) {
            addMandatoryField({ label: label, filled: value !== '' });
          }
        }
      }
    }
  }, [allData, addMandatoryField, index, label, mandatory]);

  const handleChange = (value: string) => {
    setFilled(label, value === '' ? false : true);
    updateAllDataArray(label, value, index);
    setDefaultValue(value);
  };
  return { handleChange, defaultValue };
};

export default useData;
