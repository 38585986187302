import { useMemo, useState } from 'react';
import { SupportedLanguage } from '90.quickConnect.Models/siteAdmin/models/internationalization/supportedLanguage';

const useData = (defaultLang: SupportedLanguage) => {
  const [sourceLang, setSourceLang] = useState<SupportedLanguage>(defaultLang);
  const [destLang, setDestLang] = useState<SupportedLanguage>({
    lang: 'anglais',
    value: 'en',
  } as SupportedLanguage);

  const value = useMemo(
    () => ({
      sourceLang,
      setSourceLang,
      destLang,
      setDestLang,
    }),
    [sourceLang, destLang, setSourceLang, setDestLang],
  );

  return {
    value,
  };
};

export default useData;
