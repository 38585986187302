import dayjs, { Dayjs } from 'dayjs';
import { useContext, useEffect, useState } from 'react';
import { QCDialogFormContext } from '../../context';
import { BodyContext } from '../../StepBody/context';

const useData = (label: string, mandatory: boolean) => {
  const { addMandatoryField, setFilled, index } = useContext(BodyContext);
  const { allData, updateAllDataArray, addFieldAllDataArray } = useContext(QCDialogFormContext);

  const [defaultDate, setDefaultDate] = useState<Dayjs>(dayjs(new Date()));

  useEffect(() => {
    addFieldAllDataArray(label, defaultDate, index);
  }, [defaultDate, addFieldAllDataArray, label, index]);

  useEffect(() => {
    if (allData[index - 1] && allData[index - 1].data) {
      const inputData = allData[index - 1].data;
      const valueIndex = inputData.findIndex((el) => el.label === label);
      if (valueIndex !== -1) {
        const { value } = inputData[valueIndex];

        if (value instanceof Dayjs) setDefaultDate(value);
        if (mandatory) {
          addMandatoryField({ label: label, filled: true });
        }
      }
    }
  }, [allData, addMandatoryField, index, label, mandatory]);

  const handleChange = (value: Dayjs) => {
    setFilled(label, true);
    updateAllDataArray(label, value, index);
    setDefaultDate(value);
  };
  return { defaultDate, handleChange };
};

export default useData;
