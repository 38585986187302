import { UseDataProps } from './types';
import { useStore } from '30.quickConnect.Store/siteAdmin';
import { NavElementName } from '10.quickConnect.app/siteAdmin/components/shared/Menu/types';
import { ACTIVITIES, ALERTS, IMPORTSEXPORTS } from '10.quickConnect.app/siteAdmin/routes/paths';

export const useData = (): UseDataProps => {
  const {
    UserSettingsStore: { role },
  } = useStore();

  const mapTranslation = (name: NavElementName) => {
    switch (name) {
      case 'alerts':
        return {
          my: 'alerts.tabs.myAlerts',
          organization: 'alerts.tabs.alertsOfOrganization',
        };
      case 'activities':
        return {
          my: 'activities.tabs.myActivities',
          organization: 'activities.tabs.activitiesOfOrganization',
        };
      case 'importsExports':
      default:
        return {
          my: 'importsExports.tabs.myImportsExports',
          organization: 'importsExports.tabs.importsExportsOfOrganization',
        };
    }
  };

  const mapNavigation = (name: NavElementName) => {
    switch (name) {
      case 'activities':
        return ACTIVITIES;
      case 'alerts':
        return ALERTS;
      case 'importsExports':
      default:
        return IMPORTSEXPORTS;
    }
  };

  return {
    role,
    mapTranslation,
    mapNavigation,
  };
};
