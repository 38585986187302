import React from 'react';
import { Box } from '@mui/material';
import BottomMenu from './BottomMenu';
import SideMenu from './SideMenu';
import { useData } from './hooks';

const Menu = () => {
  const { isMobile } = useData();
  return <Box>{isMobile ? <BottomMenu /> : <SideMenu />}</Box>;
};

export default Menu;
