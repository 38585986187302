import React from 'react';
import { Box, IconButton, useTheme } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import { WebhookListItemProps } from './types';

const WebhookListItem = ({ webhook, onDelete }: WebhookListItemProps) => {
  const theme = useTheme();
  return (
    <Box component="li" sx={{ display: 'flex', justifyContent: 'space-between' }}>
      {webhook.name}
      <Box>
        <IconButton>
          <SettingsIcon />
        </IconButton>
        <IconButton onClick={() => onDelete(webhook.id)}>
          <CloseIcon sx={{ color: theme.palette.menuBorder.main }} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default WebhookListItem;
