/* eslint-disable */
import { createContext } from 'react';
import { ControlListElementType, MandatoryListElementType } from './type';

export const BodyContext = createContext({
  mandatoryList: [{}] as MandatoryListElementType[],
  addMandatoryField: ({ label, filled }: MandatoryListElementType) => {},
  deleteMandatoryField: (label: string) => {},
  setFilled: (label: string, filled: boolean) => {},
  isAllMandatoryFieldsFilled: false,
  index: 0,
  controlList: [{}] as ControlListElementType[],
  addControlledField: ({ label, valid }: ControlListElementType) => {},
  setValid: (label: string, valid: boolean) => {},
  isAllControlledFieldValid: false,
});
