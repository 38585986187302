import { useCallback, useMemo } from 'react';
import { AxiosError } from 'axios';
import { GetClientsResponse } from './responses';
import ApiClients from '40.quickConnect.DataAcces/siteAdmin/apiRoutes/clients';
import QCHttpRequest from '40.quickConnect.DataAcces/siteAdmin/QCHttpRequest';

const useClientsServices = () => {
  const requestHandler = useMemo(() => new QCHttpRequest(), []);
  const getClientsRequest = useCallback(async () => {
    try {
      const response = await requestHandler.get<GetClientsResponse>(ApiClients.getClients);
      if (response.status === 200) return response.data;
    } catch (error) {
      const err = error as AxiosError;
      return err.response?.status;
    }
  }, [requestHandler]);

  return {
    getClientsRequest,
  };
};

export default useClientsServices;
