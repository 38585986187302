export const capitalize = (toCapitalize: string, allWords = true): string => {
  if (!allWords) return toCapitalize.charAt(0).toLocaleUpperCase() + toCapitalize.slice(1);

  const arr: string[] = toCapitalize.split(' ');

  arr.forEach((word, index) => {
    arr[index] = word.charAt(0).toLocaleUpperCase() + word.slice(1);
  });

  return arr.join(' ');
};
