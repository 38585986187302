import { useCallback, useMemo, useReducer } from 'react';

export enum FormatType {
  FormatPdf = 'asPdf',
  FormatOfModel = 'asModel',
}

export type CheckedFormatState = {
  checkedFormat: FormatType;
};

export enum CheckFormatActionType {
  CheckPdf = 'CheckPdf',
  CheckModelFormat = 'CheckModelFormat',
}

export type CheckFormatAction = {
  type: CheckFormatActionType;
  payload?: unknown;
};

const reducer = (state: CheckedFormatState, action: CheckFormatAction) => {
  switch (action.type) {
    case CheckFormatActionType.CheckPdf:
      return { checkedFormat: FormatType.FormatPdf };
    case CheckFormatActionType.CheckModelFormat:
    default:
      return { checkedFormat: FormatType.FormatOfModel };
  }
};

export const useDownloadReportDialogData = (onClose: (asPdf: boolean, isConfirm: boolean) => void) => {
  const [state, dispatch] = useReducer(reducer, { checkedFormat: FormatType.FormatOfModel });

  const isCheckedFormats = useMemo(() => {
    return {
      isCheckedFormatPdf: state.checkedFormat === FormatType.FormatPdf,
      isCheckedFormatModel: state.checkedFormat === FormatType.FormatOfModel,
    };
  }, [state.checkedFormat]);

  const handleCheckFormatPdf = useCallback(() => {
    dispatch({ type: CheckFormatActionType.CheckPdf });
  }, []);

  const handleCheckFormatOfModel = useCallback(() => {
    dispatch({ type: CheckFormatActionType.CheckModelFormat });
  }, []);

  const handleClose = useCallback(
    (isConfirm: boolean) => {
      onClose(isCheckedFormats.isCheckedFormatPdf, isConfirm);
    },
    [onClose, isCheckedFormats.isCheckedFormatPdf],
  );

  return {
    isCheckedFormats,
    handleCheckFormatPdf,
    handleCheckFormatOfModel,
    handleClose,
  };
};
