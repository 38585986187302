import React from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Chip, Fade, Popper, Divider, Typography, ClickAwayListener, useTheme } from '@mui/material';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import QCListItem from './QCListItem';
import { QCDropDownProps } from './types';
import {
  btnSeeMoreStyles,
  dateTimeBoxStyles,
  dividerStyles,
  innerBoxStyles,
  outterBoxStyles,
  typographyStyles,
} from './styles';

import { useData } from './hooks';
import QCGradientCircularProgress from './QCGradientCircularProgress';
import QCButtonGlobal from '70.quickConnect.Components/siteAdmin/Buttons/QCButtonGlobal';
import QCSwitchSelect from '70.quickConnect.Components/siteAdmin/Inputs/QCSwitchSelect';
import { getRoleValue } from '80.quickConnect.core/siteAdmin/helpers/getRoleValue';
import { QuickConnectRoles } from '90.quickConnect.Models/siteAdmin/enums/user/quickConnectRoles';
import { useSafeNavigation } from '80.quickConnect.core/siteAdmin/hooks/useSafeNavigation';

/**
 * Composant Dropdown du Header "Activités", "Alertes" ou "ImportsExports".
 * @component
 * @param {boolean} open - La valeur booléenne déterminant l'ouverture ou fermeture de Dropdown.
 * @param {React.Dispatch<React.SetStateAction<boolean>>} setOpen - Le dispatcheur modifiant la valeur d'état "open".
 * @param {HTMLElement | null} anchorEl - L'élément ancré.
 * @param {NavElementName} headerName - Le nom du Header : "alerts", "activités" ou "importsExports".
 * @param {DataType[]} data - Les données récupérées pour le rendu.
 * @param {string} tabsValue - La valeur d'onglet choisi.
 * @param {React.Dispatch<React.SetStateAction<string>>} setTabsValue - Le dispatcheur modifiant l'état de Tabs.
 * @param {React.ReactNode} renderButton - Le bouton à rendre contrôlant l'ouverture et la fermeture de Dropdown.
 * @example
 * const [ anchorEl, setAnchorEl ] = React.useState<HTMLElement | null>(null)
 * const [open, setOpen] = React.useState<boolean>(false)
 * const [ tabsValue, setTabsValue ] = React.useState<string>("my")
 * const data: DataType[] = ...
 * const handleClick=(event: React.MouseEvent<HTMLButtonElement>) => {
 *  setAnchorEl(event.currentTarget)
 *  setOpen((prevOpen) => (!prevOpen))
 * }
 * <QCDropDown
 *  anchorEl={anchorEl}
 *  data={data}
 *  open={open}
 *  setOpen={setOpen}
 *  headerName="alerts"
 *  tabsValue={tabsValue}
 *  setTabsValue={setTabsValue}
 *   renderButton={
 *    <QCButtonGlobal
 *      className="alerte-button"
 *      startIcon={<WarningAmberOutlinedIcon />}
 *      variant="text"
 *      value={t('navigation.menu.alerts')}
 *      action={handleClick}
 *    />
 *  }
 * />
 */
const QCDropDown = ({
  open,
  setOpen,
  anchorEl,
  headerName,
  data,
  tabsValue,
  setTabsValue,
  renderButton,
}: QCDropDownProps) => {
  const { t } = useTranslation();

  const { role, mapTranslation, mapNavigation } = useData();

  const theme = useTheme();
  const navigate = useSafeNavigation();

  // à accomplir la fonction pour vérifier si les imports ou exports sont finis.
  const isFinished = true;

  return (
    <ClickAwayListener
      onClickAway={() => {
        setOpen(false);
      }}
    >
      <Box
        sx={{
          display: 'flex',
        }}
      >
        {renderButton}
        <Popper open={open} anchorEl={anchorEl} placement="bottom-end" transition>
          {({ TransitionProps }) => (
            <Fade {...TransitionProps}>
              <Box sx={outterBoxStyles(theme)}>
                <Box sx={innerBoxStyles()}>
                  {getRoleValue(role) >= getRoleValue(QuickConnectRoles.Administrator) && (
                    <QCSwitchSelect
                      text1={t(mapTranslation(headerName).my)}
                      text2={t(mapTranslation(headerName).organization)}
                      value1={'my'}
                      value2={'organization'}
                      option={tabsValue}
                      dispatch={setTabsValue}
                      variant="contained"
                    />
                  )}
                  {data.length > 0 ? (
                    data.map((element, index, arr) => {
                      switch (headerName) {
                        case 'activities':
                          return (
                            <Box key={index}>
                              <QCListItem
                                key={index}
                                isDraft={element.tags == 'draft'}
                                titleText={element.title}
                                descriptionText={element.description}
                                renderEndInfos={
                                  <Box sx={dateTimeBoxStyles()}>
                                    <Typography sx={typographyStyles(theme)} align="right">
                                      {element.date}
                                    </Typography>
                                    <Typography sx={typographyStyles(theme)} align="right">
                                      {element.time}
                                    </Typography>
                                  </Box>
                                }
                              />
                              {index != arr.length - 1 && <Divider sx={dividerStyles()} variant="middle" />}
                            </Box>
                          );
                        case 'alerts':
                          return (
                            <Box key={index}>
                              <QCListItem
                                key={index}
                                isAlert={element.tags == 'alert'}
                                titleText={element.title}
                                descriptionText={element.description}
                                renderEndInfos={
                                  <Chip
                                    color={
                                      element.tags == 'alert'
                                        ? 'backgroundColorAlerteChip'
                                        : 'backgroundColorIncidentChip'
                                    }
                                    label={element.tags == 'alert' ? t('alerts.tags.alert') : t('alerts.tags.incident')}
                                    size="small"
                                    sx={{ textTransform: 'capitalize' }}
                                  />
                                }
                              />
                              {index != arr.length - 1 && <Divider sx={dividerStyles()} variant="middle" />}
                            </Box>
                          );
                        case 'importsExports':
                          return (
                            <Box key={index}>
                              <QCListItem
                                key={index}
                                isImports={element.tags == 'imports'}
                                titleText={element.title}
                                descriptionText={element.description}
                                renderEndInfos={
                                  isFinished ? (
                                    element.tags == 'imports' ? (
                                      <DownloadOutlinedIcon color="finishedImportsIconColor" />
                                    ) : (
                                      <UploadOutlinedIcon color="finishedExportsIconColor" />
                                    )
                                  ) : (
                                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                      <QCGradientCircularProgress />
                                      {element.tags == 'imports' ? (
                                        <DownloadOutlinedIcon color="noFinishedIconColor" />
                                      ) : (
                                        <UploadOutlinedIcon color="noFinishedIconColor" />
                                      )}
                                    </Box>
                                  )
                                }
                              />
                              {index != arr.length - 1 && <Divider sx={dividerStyles()} variant="middle" />}
                            </Box>
                          );
                      }
                    })
                  ) : (
                    // à ajouter la traduction
                    <QCListItem titleText={'Rien à signaler'} />
                  )}
                </Box>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                  <QCButtonGlobal
                    className="btn-voir-plus"
                    variant="outlined"
                    endIcon={<ArrowForwardIcon />}
                    value={t('common.actions.seeMore')}
                    // à améliorer s'il y a des routes pour l'organisation et pour un certain user
                    action={() => navigate(mapNavigation(headerName))}
                    sx={btnSeeMoreStyles(theme)}
                  />
                </Box>
              </Box>
            </Fade>
          )}
        </Popper>
      </Box>
    </ClickAwayListener>
  );
};

export default QCDropDown;
