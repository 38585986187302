import React from 'react';
import { SubMenuRowItem } from '../../shared/SubMenu/types';
import { Family } from '90.quickConnect.Models/siteAdmin/models/families/family';
import FamilyIcon from '00.app/icons/FamilyIcon';

export const familyNodeParser = (initialList: Family[]) => {
  const layers: SubMenuRowItem[][] = [[]];

  initialList.forEach((family) => {
    if (family.parentId === null) {
      layers[0].push({
        type: 'family',
        id: family.id,
        name: family.name,
        childrenCount: 0,
        startIcon: <FamilyIcon />,
      });
    } else {
      for (let i = 0; i < layers.length; i++) {
        const index = layers[i].findIndex((el) => el.id === family.parentId);
        if (index !== -1) {
          if (layers[i + 1] === undefined) layers[i + 1] = [];
          layers[i + 1].push({
            type: 'family',
            id: family.id,
            parentId: family.parentId,
            name: family.name,
            childrenCount: 0,
            startIcon: <FamilyIcon />,
          });
        }
      }
    }
  });

  layers.forEach((layer, index) => {
    layer.forEach((family) => {
      let childrenCount = 0;
      const childrenItems: SubMenuRowItem[] = [];

      if (index + 1 < layers.length) {
        layers[index + 1].forEach((child) => {
          if (child.type === 'family' && child.parentId === family.id) {
            childrenCount += 1;
            childrenItems.push(child);
          }
        });
      }
      family.childrenCount = childrenCount;
      if (family.type === 'family') family.childrenItems = childrenItems;
    });
  });

  return layers;
};
