import React from 'react';
import { ChangeEventHandler, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import CheckIcon from '@mui/icons-material/Check';
import CrossIcon from '@mui/icons-material/Close';
import { Button, useTheme } from '@mui/material';
import { GridColumns, GridRowId, GridSortModel, GridValidRowModel } from '@mui/x-data-grid';
import { MultipleActions, OrganizationRowAction, UserRowActions } from '../actions';
import useOrganizationServices from '../services/organization';
import { GetUserListRequest, ImportBinaryRequest } from '../services/requests';
import { useUserServices } from '../services/users';
import { ColumnFilterType } from '90.quickConnect.Models/shared/enums/columnFilterType';
import { useStore } from '30.quickConnect.Store/siteAdmin';
import { DeleteActionType } from '70.quickConnect.Components/siteAdmin/Other/DeleteConfirmationDialog/type';
import { useDeleteDialog } from '80.quickConnect.core/siteAdmin/hooks/useDeleteDialog';
import { useSafeNavigation } from '80.quickConnect.core/siteAdmin/hooks/useSafeNavigation';

//eslint-disable-next-line
const useData = () => {
  const { organizationId } = useParams();
  const { t } = useTranslation();
  const navigate = useSafeNavigation();

  const {
    SubMenuStore: {
      currentSubMenuRow,
      selectedRowAction,
      setSelectedRowAction,
      selectedRowsMultipleAction,
      setSelectedRowsMultipleAction,
    },
  } = useStore();

  const { getUsers, deleteUser, importBinary, getModelImport } = useUserServices();

  const { deleteOrganization } = useOrganizationServices();

  const [rows, setRows] = useState<GridValidRowModel[]>([]);

  const [searchValue, setSearchValue] = useState('');

  const [rowAction, setRowAction] = useState<{ option: string; rowId: GridRowId }>({ option: '', rowId: '' });

  const [isLoading, setIsLoading] = useState(false);
  const [rowCount, setRowcount] = useState(0);
  const [actualPage, setActualPage] = useState(0);
  const [actualInstructions, setActualInstructions] = useState([{ direction: 0, column: 'firstName' }]);

  const [pageSize, setPageSize] = useState(8);

  const theme = useTheme();

  // const resizeObj = new ResizeObserver((element) => {
  //   setPageSize((prev) => {
  //     const newValue =
  //       Math.floor(element[0].target.clientHeight / 45) === 0 ? 7 : Math.floor(element[0].target.clientHeight / 45 - 1);
  //     if (prev === newValue - 1) return prev;
  //     return newValue;
  //   });
  // });
  // try {
  //   // eslint-disable-next-line
  //   resizeObj.observe(document.getElementsByClassName('MuiDataGrid-main').item(0)!);
  //   // eslint-disable-next-line
  // } catch {}

  const inputFile = useRef<HTMLInputElement | null>(null);

  // Fonction pour l'import
  const handleImportButtonClick = () => {
    if (inputFile.current) {
      inputFile.current.click();
    }
  };

  const handleFileChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.target.files) {
      // console.log(event);
      const [file] = event.target.files;
      const request: ImportBinaryRequest = {
        fileContent: file,
      };
      // console.log(file);
      importBinary(request);
    }
  };

  // Fonction pour le téléchargement de la définition

  const handleImportModelDownload = () => {
    getModelImport();
  };

  const handleNavigate = useCallback((route: string) => navigate(route), [navigate]);

  const columns: GridColumns = useMemo(
    () => [
      {
        field: 'fullName',
        headerName: t('user.users.table.fullName'),
        flex: 1.5,
        renderCell: (params) => (
          <Button
            variant="text"
            onClick={() => {
              navigate(`/users/${params.id}/settings`);
            }}
            sx={{ textTransform: 'none', color: theme.palette.text.primary }}
          >
            {params.value}
          </Button>
        ),
      },
      {
        field: 'userUPN',
        headerName: t('users.users.table.userupn'),
        flex: 2,
      },
      { field: 'job', headerName: t('user.users.table.role'), flex: 1.5 },
      {
        field: 'isActive',
        headerName: t('connector.designer.placeholder.isActive'),
        flex: 0.5,
        renderCell: (params) => <>{params.value ? <CheckIcon /> : <CrossIcon />}</>,
      },
    ],
    // eslint-disable-next-line
    [t],
  );

  const initUsers = useCallback(
    async (
      length: number,
      start: number,
      instruction: {
        column: string;
        direction: number;
      }[],
    ) => {
      if (currentSubMenuRow === undefined || currentSubMenuRow.id !== organizationId) handleNavigate('/users');

      setIsLoading(true);
      const defaultGetUsersRequest: GetUserListRequest = {
        start: start > -1 ? start : 0,
        length: length,
        orderBy: [...instruction],
        count: 1,
        filters: [
          {
            columnName: 'organizationalUnitTxt',
            filters: [currentSubMenuRow?.name ?? ''],
            type: ColumnFilterType.TextContains,
          },
        ],
        textFilter: searchValue,
      };
      const data = await getUsers(defaultGetUsersRequest);
      setRows(data.users);
      setRowcount(data.totalCount);
      setIsLoading(false);
    },
    [getUsers, setRows, searchValue, currentSubMenuRow, organizationId, handleNavigate],
  );

  useEffect(() => {
    initUsers(pageSize, 0, actualInstructions);
    //eslint-disable-next-line
  }, [currentSubMenuRow]);

  const [rowCountState, setRowCountState] = useState(rowCount);
  useEffect(() => {
    setRowCountState((prevRowCountState) => (rowCount !== undefined ? rowCount : prevRowCountState));
  }, [rowCount, setRowCountState]);

  const dataTableCallBack = useCallback(
    (page: number) => {
      initUsers(pageSize, page * pageSize - pageSize, actualInstructions);
      setActualPage(page);
    },
    // eslint-disable-next-line
    [actualInstructions, initUsers],
  );

  const getColumnName = (fieldName: string) => {
    switch (fieldName) {
      case 'fullName':
        return 'firstName';
      default:
        return fieldName;
    }
  };

  const sortingHandler = useCallback(
    (model: GridSortModel) => {
      const instruction =
        model[0] !== undefined
          ? [
              {
                column: getColumnName(model[0].field),
                direction: model[0].sort === 'asc' ? 0 : 1,
              },
            ]
          : [
              {
                column: 'firstName',
                direction: 0,
              },
            ];
      setActualInstructions(instruction);
      setActualPage(0);

      initUsers(pageSize, 0, instruction);
    },
    // eslint-disable-next-line
    [initUsers],
  );

  const [openDeleteDialog, setOpenDeleteDialog, deleteState, deleteDispatch] = useDeleteDialog();

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  useEffect(() => {
    if (rowAction?.option === UserRowActions.Delete && rowAction.rowId !== '') {
      handleOpenDeleteDialog();
      console.log('delete row action');
    } else if (rowAction?.option === UserRowActions.Edit && rowAction.rowId !== '') {
      navigate(`/users/${rowAction.rowId}/settings`);
    }
    //eslint-disable-next-line
  }, [rowAction, deleteUser, navigate]);

  useMemo(() => {
    setActualPage(0);
    initUsers(pageSize, 0, actualInstructions);
    // eslint-disable-next-line
  }, [searchValue]);

  // SubMenu Actions
  useEffect(() => {
    if (!selectedRowAction) return;
    switch (selectedRowAction.option) {
      case t(OrganizationRowAction.Delete):
        handleOpenDeleteDialog();
        break;
      case t(OrganizationRowAction.Settings):
        navigate(`/users/organization/${selectedRowAction.rowId}/settings`);
        setSelectedRowAction(undefined);
        break;
      default:
        break;
    }
    //eslint-disable-next-line
  }, [selectedRowAction]);

  useEffect(() => {
    switch (deleteState.choice) {
      case DeleteActionType.Delete:
        setOpenDeleteDialog(false);
        //on regarde si ça vient de la datatable
        if (rowAction?.option === UserRowActions.Delete && rowAction.rowId !== '') {
          deleteUser({ ids: [`${rowAction.rowId}`] });
          setRowAction({ option: '', rowId: '' });
          initUsers(pageSize, 0, actualInstructions);
        }
        // on regarde d'abord si le delete vient d'une ligne du subMenu
        else if (selectedRowAction && selectedRowAction.rowId) {
          deleteOrganization({ ids: [selectedRowAction.rowId] }, '');
          navigate('/users');
          setSelectedRowAction(undefined);
          return;
          // ou s'il vient des actions multiples
        } else if (selectedRowsMultipleAction) {
          deleteOrganization({ ids: selectedRowsMultipleAction.rowsId }, '');
          navigate('/users');
          setSelectedRowsMultipleAction(undefined);
          // sinon c'est l'organisation affichée
        } else if (currentSubMenuRow) {
          deleteOrganization({ ids: [currentSubMenuRow.id] }, currentSubMenuRow.name);
          navigate('/users');
        }
        break;
      case DeleteActionType.Cancel:
      default:
        setOpenDeleteDialog(false);
        break;
    }
    //eslint-disable-next-line
  }, [deleteState]);

  useEffect(() => {
    switch (selectedRowsMultipleAction?.option) {
      case t(MultipleActions.Delete):
        setOpenDeleteDialog(true);
        break;
      default:
        break;
    }
    //eslint-disable-next-line
  }, [selectedRowsMultipleAction]);

  useMemo(() => {
    initUsers(pageSize, actualPage * pageSize - pageSize, actualInstructions);
    // eslint-disable-next-line
  }, [pageSize]);

  const requestHandler = {
    paginationMode: 'server',
    rowCount: rowCountState,
    onPageChange: dataTableCallBack,
  };

  const sortingProps = {
    sortingMode: 'server',
    onSortModelChange: sortingHandler,
    page: actualPage,
  };

  return {
    columns,
    rows,
    setSearchValue,
    setRowAction,
    isLoading,
    handleImportButtonClick,
    handleFileChange,
    currentSubMenuRow,
    inputFile,
    handleNavigate,
    openDeleteDialog,
    deleteDispatch,
    handleOpenDeleteDialog,
    handleImportModelDownload,
    requestHandler,
    sortingProps,
    pageSize,
    setPageSize,
  };
};

export default useData;
