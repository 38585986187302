import { t } from 'i18next';
import { GetDeclarationsListResponse } from './services/responses';
import { ChoiceMenuOptionType } from '70.quickConnect.Components/siteAdmin/Inputs/QCChoiceMenu/type';

export enum DeclarationsListHeaderName {
  declarationName = 'stringExtract',
  equipmentName = 'entityInstanceStringExtract',
  date = 'editedAt',
  organization = 'organizationalUnitStringExtract',
  userUpn = 'userUpn',
}

export const defaultDeclarationHistoriques: GetDeclarationsListResponse = {
  count: 0,
  totalCount: 0,
  researchToken: undefined,
  declarations: [],
  status: 0,
  message: null,
};

export enum DeclarationsMultipleActions {
  Delete = 'common.actions.delete',
  // Download = 'core.mock.screenActionUI.template.download',
  // Export = 'common.actions.export',
  // Send = 'core.mock.screenActionUI.downloadManager.send',
}

export enum DeclarationsRowActions {
  Delete = 'common.actions.delete',
  View = 'core.mock.screenActionUI.downloadManager.view',
}

export const actionsToChoiceMenuOption = (
  actions: DeclarationsMultipleActions[] | DeclarationsRowActions[],
): ChoiceMenuOptionType[] => {
  return actions.map((action) => ({ text: t(action), value: action }));
};
