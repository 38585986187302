import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { characBoxStyle } from '../styles';
import { ResCharacContext } from '../context';
import useData from './hooks';
import { AttributeValue } from '90.quickConnect.Models/siteAdmin/models/common/entities';
import NotificationInput from '70.quickConnect.Components/siteAdmin/Inputs/NotificationInput';

const CharacEmailInput = ({ att }: { att: AttributeValue }) => {
  const { getLabel, setUpdateResourceData } = useContext(ResCharacContext);
  const { getValue, handleChange } = useData(setUpdateResourceData);

  return (
    <Box sx={characBoxStyle}>
      <NotificationInput
        label={getLabel(att.attributeId)}
        notifications={getValue(att)}
        dispatch={(value) => {
          handleChange(value, att.attributeId);
        }}
      />
    </Box>
  );
};

export default CharacEmailInput;
