import { useState, useRef, useEffect, useMemo, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useTheme } from '@mui/material';
import { useFamilySettingsStore } from '../../FamilySettingsStore';
import { defaultFamilyAttribute } from './const';
import { FamilyAttributeItemProps } from './types';
import { useReadFamilyAttributeType } from '80.quickConnect.core/siteAdmin/hooks/readFamilyAttributeType';
import { EntitySchemaAttributeTypes } from '90.quickConnect.Models/siteAdmin/enums/family/entitySchemaAttributeType';
import { EntitySchemaAttribute } from '90.quickConnect.Models/siteAdmin/models/families/entitySchemaAttribute';
import useControls from '90.quickConnect.Models/siteAdmin/inputValidator/controls';

export const useFamilyAttributeItemData = ({ attributeId }: FamilyAttributeItemProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { readFamilyAttributeType, readTranslation } = useReadFamilyAttributeType();

  const { attributes, changeAttribute, deleteAttribute, simpleSharedLists, hierarchicalSharedLists } =
    useFamilySettingsStore();

  const initAttribute = useCallback(() => {
    const attTemp = attributes.find((att) => att.id === attributeId);
    if (attTemp) return attTemp;

    return defaultFamilyAttribute(attributes.length);
    //eslint-disable-next-line
  }, [attributes.length]);

  const [attribute, setAttribute] = useState<EntitySchemaAttribute>(initAttribute());

  // permettent de sauvegarder la liste partagée sélectionné quand le type de contrôle est changé
  const savedSimpleSharedListName = useRef('');
  const savedHierarchicalSharedListName = useRef('');

  // initialisation des listes gardé en mémoire

  useEffect(() => {
    if (savedSimpleSharedListName.current === '' && simpleSharedLists.length > 0) {
      savedSimpleSharedListName.current = simpleSharedLists[0].name;
    }

    if (savedHierarchicalSharedListName.current === '' && hierarchicalSharedLists.length > 0) {
      savedHierarchicalSharedListName.current = hierarchicalSharedLists[0].name;
    }
  }, [simpleSharedLists, hierarchicalSharedLists]);

  // quand on sélectionne une nouvelle shared list
  useEffect(() => {
    const simpleList = simpleSharedLists.find((list) => list.id === attribute.sharedListId);
    if (simpleList) {
      savedSimpleSharedListName.current = simpleList.name;
      return;
    }

    const hierarchicalList = hierarchicalSharedLists.find((list) => list.id === attribute.sharedListId);
    if (hierarchicalList) {
      savedHierarchicalSharedListName.current = hierarchicalList.name;
    }
    //eslint-disable-next-line
  }, [attribute.sharedListId]);

  const getSharedListValue = (): string => {
    // on cherche d'abord avec le sharedListId de l'attribute
    let value: string | undefined = undefined;
    if (attribute.type === EntitySchemaAttributeTypes.SharedList) {
      value = simpleSharedLists.find((list) => list.id === attribute.sharedListId)?.name;
    } else if (attribute.type === EntitySchemaAttributeTypes.SharedListHierarchical) {
      value = hierarchicalSharedLists.find((list) => list.id === attribute.sharedListId)?.name;
    }

    if (value) return value;

    // si on trouve pas on prend le premier de la liste
    if (attribute.type === EntitySchemaAttributeTypes.SharedList) return savedSimpleSharedListName.current;

    if (attribute.type === EntitySchemaAttributeTypes.SharedListHierarchical)
      return savedHierarchicalSharedListName.current;

    return '';
  };

  const sharedListOptions = useMemo(
    () =>
      attribute.type === EntitySchemaAttributeTypes.SharedList
        ? simpleSharedLists.map((list) => list.name)
        : attribute.type === EntitySchemaAttributeTypes.SharedListHierarchical
        ? hierarchicalSharedLists.map((list) => list.name)
        : [],
    [attribute, simpleSharedLists, hierarchicalSharedLists],
  );

  const sharedListSelectAppears = useMemo(
    () =>
      attribute.type === EntitySchemaAttributeTypes.SharedList ||
      attribute.type === EntitySchemaAttributeTypes.SharedListHierarchical,
    [attribute],
  );

  const handleDeleteClick = () => {
    deleteAttribute(attributeId);
  };

  //si la card attribut est focus
  const [hasFocus, setHasFocus] = useState(false);

  const inputControl = useControls();

  return {
    t,
    theme,
    readFamilyAttributeType,
    readTranslation,
    attribute,
    setAttribute,
    changeAttribute,
    getSharedListValue,
    sharedListOptions,
    sharedListSelectAppears,
    simpleSharedLists,
    hierarchicalSharedLists,
    handleDeleteClick,
    hasFocus,
    setHasFocus,
    inputControl,
  };
};
