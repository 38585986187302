import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import {
  CreateSharedListRequest,
  DeleteSharedListRequest,
  GetSharedListRequest,
  GetSharedListsRequest,
  UpdateSharedListRequest,
} from './requests';
import {
  CreateSharedListResponse,
  DeleteSharedListResponse,
  GetSharedListResponse,
  GetSharedListsResponse,
  UpdateSharedListResponse,
} from './responses';
import { ApiSharedList } from '40.quickConnect.DataAcces/siteAdmin/apiRoutes/sharedList';
import QCHttpRequest from '40.quickConnect.DataAcces/siteAdmin/QCHttpRequest';
import { isResponseValid } from '40.quickConnect.DataAcces/siteAdmin/responseValidator';

const useSharedListsServices = () => {
  const { t } = useTranslation();
  const httpRequest = new QCHttpRequest();

  const getSharedLists = async (request: GetSharedListsRequest) => {
    try {
      const response = await httpRequest.post<GetSharedListsResponse, GetSharedListsRequest>(
        ApiSharedList.getSharedLists,
        request,
      );

      if (isResponseValid(response.data)) return response.data.sharedLists;
    } catch (error) {
      if (axios.isAxiosError(error)) toast.error(error.response?.data.message);
    }
  };

  const getSharedList = async (request: GetSharedListRequest) => {
    try {
      const response = await httpRequest.post<GetSharedListResponse, GetSharedListRequest>(
        ApiSharedList.getSharedList,
        request,
      );

      if (isResponseValid(response.data)) return response.data.sharedList;
    } catch (error) {
      if (axios.isAxiosError(error)) toast.error(error.response?.data.message);
    }
  };

  const updateSharedList = async (request: UpdateSharedListRequest) => {
    try {
      const response = await httpRequest.post<UpdateSharedListResponse, UpdateSharedListRequest>(
        ApiSharedList.updateSharedList,
        request,
      );

      const isValid = isResponseValid(
        response.data,
        t('sharedList.designer.popup.save.success', {
          msg: t('packaging.validate.columns.actionType.update'),
          sharedListName: response.data.sharedList.name,
        }),

        t,
      );
      if (isValid) {
        return response.data;
      }
    } catch (error) {
      if (axios.isAxiosError(error)) toast.error(error.response?.data.message);
    }
  };

  const createSharedList = async (request: CreateSharedListRequest) => {
    try {
      const response = await httpRequest.post<CreateSharedListResponse, CreateSharedListRequest>(
        ApiSharedList.createSharedList,
        request,
      );

      const isValid = isResponseValid(
        response.data,
        t('sharedList.designer.popup.save.success', {
          msg: t('entitySchema.entityDesigner.popup.saveEntity.create'),
          sharedListName: response.data.sharedList.name,
        }),
        t,
      );
      if (isValid) return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) toast.error(error.response?.data.message);
    }
  };

  const deleteSharedList = async (request: DeleteSharedListRequest) => {
    try {
      const response = await httpRequest.post<DeleteSharedListResponse, DeleteSharedListRequest>(
        ApiSharedList.deleteSharedList,
        request,
      );
      if (response.status === 200 && isResponseValid(response.data)) {
        toast.success(
          t('sharedList.designer.popup.save.success', {
            msg: t('sharedList.designer.popup.delete.title'),
            sharedListName: '',
          }),
        );
      }
    } catch (error) {
      if (axios.isAxiosError(error)) toast.error(error.response?.data.message);
    }
  };

  return { getSharedLists, getSharedList, createSharedList, updateSharedList, deleteSharedList };
};

export default useSharedListsServices;
