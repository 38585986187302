import {
  arSA,
  csCZ,
  daDK,
  deDE,
  enUS,
  esES,
  fiFI,
  frFR,
  hrHR,
  huHU,
  isIS,
  Localization,
  nlNL,
  roRO,
  skSK,
} from '@mui/material/locale';
import { getLanguageCode } from '80.quickConnect.core/shared/helpers';

const languageCode = getLanguageCode();

export const getMuiLocal = (): Localization => {
  switch (languageCode) {
    case 'nl':
      return nlNL;
    case 'de':
      return deDE;
    case 'en':
      return enUS;
    case 'ar':
      return arSA;
    case 'cs':
      return csCZ;
    case 'da':
      return daDK;
    case 'es':
      return esES;
    case 'fi':
      return fiFI;
    case 'hr':
      return hrHR;
    case 'hu':
      return huHU;
    case 'is':
      return isIS;
    case 'ro':
      return roRO;
    case 'sk':
      return skSK;
    case 'fr':
    default:
      return frFR;
  }
};
