import React from 'react';
import { Box, useTheme } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { UserRowActions, actionsToChoiceMenuOtion, UserMultipleActions } from '../actions';
import AddUserForm from '../AddUserDialogForm';
import useData from './hooks';
import { OrganizationIcon } from '00.app/icons/OrganizationIcon';
import useAddUserData from '10.quickConnect.app/siteAdmin/components/domains/Users/NoOrganizationalUnitFocused/hooks';
import MenuWrapper from '10.quickConnect.app/siteAdmin/components/shared/MenuWrapper';
import QCButtonGlobal from '70.quickConnect.Components/siteAdmin/Buttons/QCButtonGlobal';
import QCFabCorbeille from '70.quickConnect.Components/siteAdmin/Buttons/QCFabCorbeille';
import QCIconButton from '70.quickConnect.Components/siteAdmin/Buttons/QCIconButton';
import ContentHeader from '70.quickConnect.Components/siteAdmin/Other/ContentHeader';
import DataTable from '70.quickConnect.Components/siteAdmin/Other/DataTable';
import DeleteConfirmationDialog from '70.quickConnect.Components/siteAdmin/Other/DeleteConfirmationDialog';

const UserList = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { isAddUserDialogOpen, handleAddUser, handleConfirmAddUser, setIsAddUserDialogOpen } = useAddUserData();

  const {
    columns,
    rows,
    setSearchValue,
    setRowAction,
    isLoading,
    handleImportButtonClick,
    handleFileChange,
    currentSubMenuRow,
    inputFile,
    handleNavigate,
    openDeleteDialog,
    deleteDispatch,
    handleOpenDeleteDialog,
    handleImportModelDownload,
    requestHandler,
    sortingProps,
    pageSize,
  } = useData();

  return (
    <MenuWrapper>
      <DeleteConfirmationDialog dispatch={deleteDispatch} open={openDeleteDialog} />
      <Box
        sx={{
          display: 'flex',
          position: 'relative',
          flexDirection: 'column',
          flex: 1,
          marginX: '2vh',
          marginBottom: '2vh',
          // marginY: '5%',
          justifyContent: 'space-around',
          gap: 3,
          // alignContent: 'center',
        }}
      >
        <Box display="flex" justifyContent="space-between" alignItems="flex-end">
          <ContentHeader>
            <ContentHeader.TitlesGroup>
              <ContentHeader.OverTitle>{t('organization.viewer.overTitle')}</ContentHeader.OverTitle>
              <ContentHeader.Title startIcon={<OrganizationIcon />}>{currentSubMenuRow?.name}</ContentHeader.Title>
            </ContentHeader.TitlesGroup>

            <ContentHeader.Controls>
              <QCIconButton
                Icon={<SettingsOutlinedIcon />}
                onClickFunc={() => {
                  handleNavigate('settings');
                }}
                variantValue="outlined"
                sx={{ color: theme.palette.primary.main, marginBottom: '-5px' }}
              ></QCIconButton>
            </ContentHeader.Controls>
          </ContentHeader>

          <QCFabCorbeille onClickFunc={handleOpenDeleteDialog} />
        </Box>

        <DataTable
          title={t('users.users.title')}
          rowActions={actionsToChoiceMenuOtion([UserRowActions.Delete, UserRowActions.Edit])}
          onSelectRowAction={setRowAction}
          multipleActions={actionsToChoiceMenuOtion([UserMultipleActions.Delete])}
          columns={columns}
          rows={rows}
          getSearchValue={setSearchValue}
          isLoading={isLoading}
          requestHandler={requestHandler}
          sortingProps={sortingProps}
          pageSize={pageSize}
          renderAfterTitle={
            <Box display="flex" gap={1}>
              <QCButtonGlobal
                className="import-user"
                startIcon={<UploadOutlinedIcon />}
                value={t('common.actions.import')}
                action={handleImportButtonClick}
                datacy="import-user"
              />
              <QCButtonGlobal
                className="export-user"
                startIcon={<DownloadOutlinedIcon />}
                value={t('form.detail.label.modelImportInbox')}
                variant="outlined"
                action={handleImportModelDownload}
                datacy="export-user"
              />
            </Box>
          }
        />

        <input
          type="file"
          id="filePicker"
          ref={inputFile}
          style={{ display: 'none' }}
          accept=".csv"
          onChange={handleFileChange}
        />

        <Box sx={{ position: 'absolute', bottom: '3vh', left: '3vh' }}>
          <QCButtonGlobal
            className="add-user"
            variant="contained"
            startIcon={<AccountCircleIcon />}
            endIcon={<AddIcon />}
            value="Ajouter un utilisateur"
            action={handleAddUser}
            datacy="add-user"
          ></QCButtonGlobal>
          <AddUserForm
            open={isAddUserDialogOpen}
            handleConfirm={(value) => handleConfirmAddUser(value)}
            toggleIsOpen={setIsAddUserDialogOpen}
          />
        </Box>
      </Box>
    </MenuWrapper>
  );
};

export default observer(UserList);
