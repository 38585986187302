import { useEffect, useState } from 'react';
import { WindowSize } from '../helpers/type';

const isClient = typeof window === 'object';

const getSize = (): WindowSize => ({
  width: isClient ? window.innerWidth : undefined,
  height: isClient ? window.innerHeight : undefined,
});

export const useWindowSize = (): WindowSize => {
  const [windowSize, setWindowSize] = useState(getSize());
  useEffect(() => {
    if (!isClient) {
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {};
    }
    function handleResize(): void {
      setWindowSize(getSize());
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return windowSize;
};
export default useWindowSize;
