import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';

export const CardContainerStyle = (theme: Theme): SxProps => ({
  overflow: 'hidden',
  backgroundColor: theme.palette.toolsBackground.main,
  border: 'solid 2px',
  borderColor: theme.palette.menuBorder.main,
  borderRadius: '8px',
  paddingLeft: '20px',
  paddingTop: '10px',
  paddingRight: '5px',
  paddingBottom: '5px',
  width: '45%',
  height: '35vh',
  position: 'relative',
});

export const CardTextStyle: SxProps = {
  display: 'flex',
  flexWrap: 'wrap',
  alignItems: 'baseline',
  gap: 1,
  paddingY: '8px',
  '> h2': {
    margin: 0,
    marginLeft: '5px',
  },
};

export const InputBoxStyle: SxProps = {
  width: { xs: '250px' },
};
