import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { characBoxStyle } from '../styles';
import { ResCharacContext } from '../context';
import useData from './hooks';
import QCTextInput from '70.quickConnect.Components/siteAdmin/Inputs/QCTextInput';
import { AttributeValue } from '90.quickConnect.Models/siteAdmin/models/common/entities';

const CharacNumberInput = ({ att }: { att: AttributeValue }) => {
  const { getLabel, setUpdateResourceData } = useContext(ResCharacContext);
  const { getValue, handleChange } = useData(setUpdateResourceData);

  return (
    <Box sx={characBoxStyle}>
      <QCTextInput
        label={getLabel(att.attributeId)}
        type="number"
        value={getValue(att)}
        dispatch={(value: string) => {
          handleChange(value, att.attributeId);
        }}
      />
    </Box>
  );
};

export default CharacNumberInput;
