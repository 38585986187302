import React from 'react';
import { action, makeAutoObservable, observable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import RootStore from '..';
import { defaultGetOrganizationalUnitListResponse } from '10.quickConnect.app/siteAdmin/components/domains/Users/const';
import { GetOrganizationalUnitListResponse } from '10.quickConnect.app/siteAdmin/components/domains/Users/types';
import { SubMenuRow } from '10.quickConnect.app/siteAdmin/components/shared/SubMenu/types';

type SubMenuPath = {
  id: string;
  name: string;
  rows: SubMenuRow[];
  row?: SubMenuRow;
};

class SubMenuStore {
  data: GetOrganizationalUnitListResponse = defaultGetOrganizationalUnitListResponse; // autres types à ajoouter en fonction de l'utilisation du subMenu

  rows: SubMenuRow[] = [];

  initialRows: SubMenuRow[] = [];

  currentSubMenuRow: SubMenuRow | undefined = undefined;

  isVisible = false;

  routeBase = '';

  subMenuPath: SubMenuPath[] = [{ id: '', name: '', rows: [] }];

  rowActions: string[] = [];

  selectedRowAction: { option: string; rowId: string } | undefined = undefined;

  selectedRowsMultipleAction: { option: string; rowsId: string[] } | undefined = undefined;

  sortingTypes: string[] = [];

  multipleActions: { text: string }[] = [];

  addIcon: JSX.Element = (<></>);

  addText = '';

  handleAddClick: () => void = () => {};

  menuSize: number;

  getData: () => Promise<GetOrganizationalUnitListResponse> = async () => {
    return defaultGetOrganizationalUnitListResponse;
  };

  RootStore: RootStore;

  constructor(rootStore: RootStore, storageKey: string) {
    this.RootStore = rootStore;
    this.menuSize = 325;

    makeAutoObservable(
      this,
      {
        data: observable,
        isVisible: observable,
        subMenuPath: observable,
        currentSubMenuRow: observable,
        routeBase: observable,
        rowActions: observable,
        selectedRowAction: observable,
        selectedRowsMultipleAction: observable,
        menuSize: observable,

        setData: action,
        setIsVisible: action,
        switchIsVisible: action,
        setCurrentSubMenuRow: action,
        setRouteBase: action,
        setRowActions: action,
        setSelectedRowAction: action,
        setSelectedRowsMultipleAction: action,
        setAddIcon: action,
        setHandleAddClick: action,
        setSortingTypes: action,
        setAddText: action,
        setMultipleActions: action,
        setMenuSize: action,
      },
      { autoBind: true },
    );

    makePersistable(this, {
      name: storageKey,
      properties: [/*'data', 'subMenuPath', 'currentSubMenuRow', 'routeBase'*/ 'menuSize'],
      storage: window.localStorage,
    });
  }

  resetStore = () => {
    this.data = defaultGetOrganizationalUnitListResponse;
  };

  setData = (data: GetOrganizationalUnitListResponse) => {
    this.data = data;
  };

  setRows = (rows: SubMenuRow[]) => {
    this.rows = rows;
    this.initialRows = rows;
  };

  setIsVisible = (path: string) => {
    if (
      /^\/users.*(?<!settings)$/gm.exec(path) !== null ||
      /^\/versions.*(?<!settings)$/gm.exec(path) !== null ||
      /^\/clients.*$/gm.exec(path) !== null ||
      /^\/equipments.*(?<!settings|translation)$/gm.exec(path) !== null ||
      /^\/sharedlists.*(?<!translation)$/gm.exec(path) !== null
    ) {
      this.isVisible = true;
      return;
    }

    this.isVisible = false;
  };

  setRouteBase = (routeBase: string) => {
    this.routeBase = routeBase;
  };

  setGetData = (getDataAsync: () => Promise<GetOrganizationalUnitListResponse>) => {
    this.getData = getDataAsync;
  };

  switchIsVisible = () => {
    this.isVisible = !this.isVisible;
  };

  setSubMenuPath = (subMenuPath: SubMenuPath[]) => {
    this.subMenuPath = subMenuPath;
  };

  setCurrentSubMenuRow = (current: SubMenuRow | undefined) => {
    this.currentSubMenuRow = current;
  };

  setRowActions = (rowActions: string[]) => {
    this.rowActions = rowActions;
  };

  setSelectedRowAction = (selectedRowAction: { option: string; rowId: string } | undefined) => {
    this.selectedRowAction = selectedRowAction;
  };

  setSelectedRowsMultipleAction = (selectedRowsMultipleAction: { option: string; rowsId: string[] } | undefined) => {
    this.selectedRowsMultipleAction = selectedRowsMultipleAction;
  };

  pushSubMenuPath = (subMenuPath: SubMenuPath) => {
    this.subMenuPath.push(subMenuPath);
  };

  reduceSubMenuPath = (subMenuPath: SubMenuPath) => {
    this.setSubMenuPath(this.subMenuPath.slice(0, this.subMenuPath.indexOf(subMenuPath) + 1));
  };

  setSortingTypes = (sortingTypes: string[]) => {
    this.sortingTypes = sortingTypes;
  };

  setMultipleActions = (
    multipleActions: {
      text: string;
    }[],
  ) => {
    this.multipleActions = multipleActions;
  };

  setAddIcon = (addIcon: JSX.Element) => {
    this.addIcon = addIcon;
  };

  setAddText = (addText: string) => {
    this.addText = addText;
  };

  setHandleAddClick = (handleAddClick: () => void) => {
    this.handleAddClick = handleAddClick;
  };

  setInitialRows = (initialRows: SubMenuRow[]) => {
    this.initialRows = initialRows;
  };

  setRowsBySearch = (rows: SubMenuRow[]) => {
    this.rows = rows;
  };

  setMenuSize = (menuSize: number) => {
    this.menuSize = menuSize;
  };
}

export default SubMenuStore;
