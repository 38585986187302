import { useEffect, useMemo } from 'react';
import { createTheme } from '@mui/material';
import { pureWhiteColor, textBlackColor, slateGrey900 } from './theme/colors';
import { getComponents } from './theme/components';
import { getMuiLocal } from './theme/muiLocale';
import { UseDataProps } from './types';
import { lightPalette, darkPalette } from './theme/palettes';
import { useStore } from '30.quickConnect.Store/siteAdmin';
import useTabId from '80.quickConnect.core/shared/hooks/useTabId';
import { getLanguageCode } from '80.quickConnect.core/shared/helpers';

const local = getLanguageCode();

export const useHooks = (): UseDataProps => {
  // On récupère le store
  const {
    UserSettingsStore: { muiMode },
  } = useStore();

  // Permet de générer un Id pour chaque tabs du useSafeNavigationur
  useTabId();

  // Update the theme only if the mode changes
  const theme = useMemo(() => {
    const typoStd = {
      fontFamily: [
        'Rubik-var',
        '-apple-system',
        'BlinkMacSystemFont',
        'Segoe UI',
        'Roboto',
        'Oxygen',
        'Ubuntu',
        'Cantarell',
        'Fira Sans',
        'Droid Sans',
        'Helvetica Neue',
        'sans-serif',
      ].join(','),
    };

    const titleTypo = {
      ...typoStd,
      fontFamily: [...(typoStd.fontFamily as string).split(',').reverse(), 'N27-Regular'].reverse().join(','),
    };
    const partialTheme = createTheme();
    return createTheme(
      {
        typography: {
          h1: titleTypo,
          h2: titleTypo,
          h3: titleTypo,
          h4: titleTypo,
          h5: titleTypo,
          h6: titleTypo,
          subtitle1: typoStd,
          subtitle2: typoStd,
          body1: typoStd,
          body2: typoStd,
          button: typoStd,
          caption: typoStd,
          overline: typoStd,
        },
        palette: muiMode === 'light' ? lightPalette : darkPalette,
        components: getComponents(muiMode, partialTheme),
        direction: local === 'ar' ? 'rtl' : 'ltr',
      },
      getMuiLocal(), // locale FR pour Mui
    );
  }, [muiMode]);

  // On ajoute des hooks
  useEffect(() => {
    document.title = 'Quick Connect';
    document.getElementsByTagName('html')[0].setAttribute('dir', local === 'ar' ? 'rtl' : 'ltr');
  }, []);

  useEffect(() => {
    document.body.style.backgroundColor = muiMode === 'light' ? pureWhiteColor : slateGrey900;
    document.body.style.color = muiMode === 'light' ? textBlackColor : pureWhiteColor;
  }, [muiMode]);

  return { theme };
};
