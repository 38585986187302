const getLocalDateTime = (newDate: string) => {
  const dateToChecked = new Date(newDate);
  const now = new Date();
  const time = dateToChecked.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  const isSameYear = dateToChecked.getFullYear() == now.getFullYear();
  const isSameMonth = dateToChecked.getMonth() == now.getMonth();
  const isToday = isSameYear && isSameMonth && dateToChecked.getDate() == now.getDate();
  const isYesterday = isSameYear && isSameMonth && dateToChecked.getDate() == now.getDate() - 1;

  if (isToday) {
    // à ajouter la traduction
    return "Aujourd'hui à " + time;
  } else if (isYesterday) {
    // à ajouter la tracution
    return 'Hier à ' + time;
  } else {
    return dateToChecked.toLocaleString([], {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour: '2-digit',
      minute: '2-digit',
    });
  }
};

export default getLocalDateTime;
