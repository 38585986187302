import React from 'react';
import { observer } from 'mobx-react';
import { Box } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ListIcon from '@mui/icons-material/List';
import CreateSharedListDialog from '../CreateSharedListDialog';
import useSharedListsHomeData from './hooks';
import { Content, MainBox } from './style';
import CircleIcon from '00.app/icons/CircleIcon';
import MenuWrapper from '10.quickConnect.app/siteAdmin/components/shared/MenuWrapper';
import QCButtonGlobal from '70.quickConnect.Components/siteAdmin/Buttons/QCButtonGlobal';
import DeleteConfirmationDialog from '70.quickConnect.Components/siteAdmin/Other/DeleteConfirmationDialog';

const SharedListsHome = () => {
  const { t, openAddDialog, setOpenAddDialog, deleteDispatch, openDeleteDialog, canSendToTrash } =
    useSharedListsHomeData();

  return (
    <MenuWrapper>
      <DeleteConfirmationDialog dispatch={deleteDispatch} open={openDeleteDialog} trashAvailable={canSendToTrash} />
      <Box sx={MainBox}>
        <Box sx={Content}>
          {t('sharedList.home').replace(/,/, ',\n')}
          <QCButtonGlobal
            className="OU-creation"
            value={t('sharedList.home.add')}
            startIcon={<ListIcon />}
            endIcon={<AddIcon />}
            sx={{ width: '350px' }}
            action={() => {
              setOpenAddDialog(true);
            }}
            datacy="add-version-button"
          />
        </Box>
        <CircleIcon />
      </Box>
      <CreateSharedListDialog open={openAddDialog} setOpen={setOpenAddDialog} />
    </MenuWrapper>
  );
};

export default observer(SharedListsHome);
