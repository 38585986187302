import { Box, useTheme } from '@mui/material';
import React from 'react';
import { TraductionContext } from './context';
import useData from './hooks';
import { outerBoxStyle, TraductionCardSx } from './styles';
import TradDestFilledFieldsCount from './TradDestFilledFieldsCount';
import { TraductionCardType } from './types';

const TraductionCard = ({ supportedLangs, children, destChampsCount, defaultLang }: TraductionCardType) => {
  const { value } = useData(defaultLang);
  const theme = useTheme();

  return (
    <TraductionContext.Provider value={{ ...value, supportedLangs, destChampsCount }}>
      <Box sx={outerBoxStyle}>
        <Box sx={TraductionCardSx(theme)}>{children}</Box>
        <TradDestFilledFieldsCount />
      </Box>
    </TraductionContext.Provider>
  );
};

export default TraductionCard;
