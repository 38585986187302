import React from 'react';
import { Box, Typography } from '@mui/material';
import { FormDataCardPropsType } from './types';
import useData from './hooks';

const FormDataCard = ({ index, totalLength, data }: FormDataCardPropsType) => {
  const { getField } = useData(data);
  return (
    <Box sx={{ width: '45vw', my: 2 }}>
      <Box sx={{ display: 'flex', gap: 1 }}>
        <Box
          sx={{
            backgroundColor: '#C1E9E1',
            width: 'fit-content',
            height: 'fit-content',
            minWidth: '56px',
            display: 'flex',
            justifyContent: 'center',
            borderRadius: '7px',
          }}
        >
          <Typography sx={{ fontWeight: 400, fontSize: '20px' }}>
            {index}/{totalLength}
          </Typography>
        </Box>
        <Typography sx={{ fontWeight: 'bold', fontSize: '20px', wordBreak: 'break-all' }}>{data.label}</Typography>
      </Box>
      {/* différents types de champs selon fieldType */}
      {getField()}
    </Box>
  );
};

export default FormDataCard;
