import API_HOST from './apiHost';
import { ApiRoutesType } from './type';

const API_VERSION = `${API_HOST}Parameter/`;

const ApiVersion: ApiRoutesType = {
  getVersionList: `${API_VERSION}getglobalparameterlist`,
  getVersion: `${API_VERSION}getparameter`,
  createVersion: `${API_VERSION}createparameter`,
  deleteVersion: `${API_VERSION}deleteparameter`,
  updateVersion: `${API_VERSION}updateparameter`,
};

export default ApiVersion;
