import { GridColDef } from '@mui/x-data-grid';
import { v4 as uuidv4 } from 'uuid';
import { FormGroupTypes } from '90.quickConnect.Models/siteAdmin/enums/groupTypes';

export const QCDataTableName = 'QCDataTable';

export const groupingOptions: string[] = Object.values(FormGroupTypes);

export const testCols: GridColDef[] = [
  { field: 'userUpn', headerName: 'UserUPN', width: 150 },
  { field: 'mobileId', headerName: 'MobileId', width: 150 },
  { field: 'firstSync', headerName: 'Première Synchronisation', width: 250 },
  { field: 'lastSync', headerName: 'Dernière Synchronisation', width: 250 },
  { field: 'runningVersion', headerName: 'Version courante', width: 200 },
  { field: 'installationDate', headerName: 'Version courante installation', width: 275 },
  { field: 'previousVersion', headerName: 'Version précédente', width: 190 },
];

export const testRows = [
  {
    id: 1,
    userUpn: 'barnabe@qc.fr',
    modilId: uuidv4(),
    firstSync: '10/09/2021',
    runningVersion: '60.4',
    installationDate: '10/09/2021',
    previousVersion: '59.3',
  },
  {
    id: 2,
    userUpn: 'barnabe@qc.fr',
    modilId: uuidv4(),
    firstSync: '10/09/2021',
    runningVersion: '60.4',
    installationDate: '10/09/2021',
    previousVersion: '59.3',
  },
  {
    id: 3,
    userUpn: 'barnabe@qc.fr',
    modilId: uuidv4(),
    firstSync: '10/09/2021',
    runningVersion: '60.4',
    installationDate: '10/09/2021',
    previousVersion: '59.3',
  },
  {
    id: 4,
    userUpn: 'barnabe@qc.fr',
    modilId: uuidv4(),
    firstSync: '10/09/2021',
    runningVersion: '60.4',
    installationDate: '10/09/2021',
    previousVersion: '59.3',
  },
  {
    id: 5,
    userUpn: 'barnabe@qc.fr',
    modilId: uuidv4(),
    firstSync: '10/09/2021',
    runningVersion: '60.4',
    installationDate: '10/09/2021',
    previousVersion: '59.3',
  },
  {
    id: 6,
    userUpn: 'barnabe@qc.fr',
    modilId: uuidv4(),
    firstSync: '10/09/2021',
    runningVersion: '60.4',
    installationDate: '10/09/2021',
    previousVersion: '59.3',
  },
  {
    id: 7,
    userUpn: 'barnabe@qc.fr',
    modilId: uuidv4(),
    firstSync: '10/09/2021',
    runningVersion: '60.4',
    installationDate: '10/09/2021',
    previousVersion: '59.3',
  },
  {
    id: 8,
    userUpn: 'barnabe@qc.fr',
    modilId: uuidv4(),
    firstSync: '10/09/2021',
    runningVersion: '60.4',
    installationDate: '10/09/2021',
    previousVersion: '59.3',
  },
  {
    id: 9,
    userUpn: 'barnabe@qc.fr',
    modilId: uuidv4(),
    firstSync: '10/09/2021',
    runningVersion: '60.4',
    installationDate: '10/09/2021',
    previousVersion: '59.3',
  },
  {
    id: 10,
    userUpn: 'barnabe@qc.fr',
    modilId: uuidv4(),
    firstSync: '10/09/2021',
    runningVersion: '60.4',
    installationDate: '10/09/2021',
    previousVersion: '59.3',
  },
  {
    id: 11,
    userUpn: 'barnabe@qc.fr',
    modilId: uuidv4(),
    firstSync: '10/09/2021',
    runningVersion: '60.4',
    installationDate: '10/09/2021',
    previousVersion: '59.3',
  },
  {
    id: 12,
    userUpn: 'barnabe@qc.fr',
    modilId: uuidv4(),
    firstSync: '10/09/2021',
    runningVersion: '60.4',
    installationDate: '10/09/2021',
    previousVersion: '59.3',
  },
  {
    id: 13,
    userUpn: 'barnabe@qc.fr',
    modilId: uuidv4(),
    firstSync: '10/09/2021',
    runningVersion: '60.4',
    installationDate: '10/09/2021',
    previousVersion: '59.3',
  },
  {
    id: 14,
    userUpn: 'barnabe@qc.fr',
    modilId: uuidv4(),
    firstSync: '10/09/2021',
    runningVersion: '60.4',
    installationDate: '10/09/2021',
    previousVersion: '59.3',
  },
];
