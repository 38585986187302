import React from 'react';
import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';
import TopicOutlinedIcon from '@mui/icons-material/TopicOutlined';
import InsertDriveFileOutlinedIcon from '@mui/icons-material/InsertDriveFileOutlined';
import HistoryIcon from '@mui/icons-material/History';
import ImportExportOutlinedIcon from '@mui/icons-material/ImportExportOutlined';
import QrCodeScannerOutlinedIcon from '@mui/icons-material/QrCodeScannerOutlined';
import { WidgetElementsType } from './types';

export const WidgetElementsDef: WidgetElementsType = {
  activities: {
    type: 'activities',
    title: 'home.widget.activities.title',
    icon: <NotificationsOutlinedIcon />,
    link: '/activities',
  },
  alerts: {
    type: 'alerts',
    title: 'home.widget.alerts.title',
    icon: <WarningAmberOutlinedIcon />,
    link: '/alerts',
  },
  data: {
    type: 'data',
    title: 'home.widget.data.title',
    icon: <TopicOutlinedIcon />,
    link: '',
  },
  forms: {
    type: 'forms',
    title: 'home.widget.forms.title',
    icon: <InsertDriveFileOutlinedIcon />,
    link: '/forms',
  },
  declarations: {
    type: 'declarations',
    title: 'home.widget.declarations.title',
    icon: <HistoryIcon />,
    link: '/declarations',
  },
  importsexports: {
    type: 'importsexports',
    title: 'home.widget.importsexports.title',
    icon: <ImportExportOutlinedIcon />,
    link: '/importsandexports',
  },
  scans: {
    type: 'scans',
    title: 'home.widget.scans.title',
    icon: <QrCodeScannerOutlinedIcon />,
    link: '/scan',
  },
} as const;
