import API_HOST from './apiHost';
import { ApiRoutesType } from './type';

const API_COMPAGNON = `${API_HOST}Companion/`;

const ApiCompagnon: ApiRoutesType = {
  getCompagnonList: `${API_COMPAGNON}getcompanions`,
};

export default ApiCompagnon;
