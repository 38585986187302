import React from 'react';
import { Box, SxProps, useTheme } from '@mui/material';
import { ControlProps } from '../../StepBody/type';
import useData from './hooks';
import { QCTextInputProps } from '70.quickConnect.Components/siteAdmin/Inputs/QCTextInput/type';
import QCTextInput from '70.quickConnect.Components/siteAdmin/Inputs/QCTextInput';
import { AttributeValue } from '90.quickConnect.Models/siteAdmin/models/common/entities';

const useSx = (control: ControlProps | undefined, value: string): SxProps => {
  const theme = useTheme();
  if (control) {
    if (value !== '' && control.regex.exec(value) === null)
      return {
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: `${theme.palette.error.main} !important`,
        },
      };
  }
  return {};
};

const QCDialogFormText = ({
  label,
  type,
  placeholder,
  mandatory,
  control,
  attributData,
}: QCTextInputProps & { attributData?: AttributeValue }) => {
  const { handleChange, defaultValue } = useData(label, mandatory ?? false, control, attributData, type);
  return (
    <Box sx={useSx(control, defaultValue)}>
      <QCTextInput
        label={label}
        info={control?.instructions}
        placeholder={placeholder}
        dispatch={(value: string) => {
          if (attributData) {
            if (type === 'number') {
              attributData.value = new Number(value);
            } else {
              attributData.value = new String(value);
            }
            handleChange(attributData);
          } else {
            handleChange(value);
          }
        }}
        mandatory={mandatory}
        className="dialog-text-input"
        value={defaultValue}
        type={type}
      />
    </Box>
  );
};

export default QCDialogFormText;
