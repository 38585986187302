import React, { useEffect, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import { TextField, Box, useTheme } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers';
import QCInputHeader from '../QCInputHeader';
import { InputContainerStyle } from '../style';
import { DatePickerProps, QCDatePickerButtonSxPropsType } from './types';

/**
 * Composant pour saisir la date.
 * @component
 * @param {string | undefined} [label] - L'étiquette du composant
 * @param {dayjs.Dayjs | null} localDate - La valeur d'état, ctd la date choisie.
 * @param {string | undefined} [minDate] - La date minimum en formule de "yyyy-mm-jj".
 * @param {string | undefined} [maxDate] - La date maximum en formule de "yyyy-mm-jj".
 * @param {React.Dispatch<React.SetStateAction<dayjs.Dayjs>>} setDate - Le dispatch pour modifier l'état de date.
 * @param {string | undefined} [info] - Les informations indicatives
 * @example <caption>Exemple QCDatePicker - valeur initiale: valeur vide</caption>
 * import dayjs, { Dayjs } from 'dayjs';
 * const [ date, setDate ] = useState<Dayjs>({} as Dayjs)
 * <QCDatePicker localDate={date} setDate={setDate} minDate='2017-01-12' maxDate='2022-12-31'/>
 * @example <caption>Exemple QCDatePicker - valeur initiale: date actuelle</caption>
 * import dayjs, { Dayjs } from 'dayjs';
 * const [ date, setDate ] = useState<Dayjs>(dayjs(new Date()))
 * <QCDatePicker localDate={date} setDate={setDate} minDate='2017-01-12' maxDate='2022-12-31'/>
 */
const QCDatePicker = ({ label, localDate, minDate, maxDate, setDate, info, mandatory }: DatePickerProps) => {
  const [isChanged, setIsChanged] = useState(false);

  const style = useTheme().components?.QCDatePicker;
  const variantStyleChecked = style?.variants.checked;
  const variantStyleNotChecked = style?.variants.notChecked;

  const handleChange = (newDate: Dayjs | null) => {
    if (newDate && setDate) {
      setDate(newDate);
      setIsChanged(true);
    }
  };

  const [sx, setSx] = useState(variantStyleNotChecked as QCDatePickerButtonSxPropsType);

  useEffect(() => {
    if (isChanged) {
      setSx(variantStyleChecked as QCDatePickerButtonSxPropsType);
    }
  }, [isChanged, variantStyleChecked]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <Box sx={{ ...sx, ...InputContainerStyle }}>
        {label && <QCInputHeader label={label} info={info} mandatory={mandatory} />}
        <MobileDatePicker
          value={localDate}
          minDate={minDate ? dayjs(minDate) : undefined}
          maxDate={maxDate ? dayjs(maxDate) : undefined}
          onChange={handleChange}
          renderInput={(params) => <TextField {...params} />}
        />
      </Box>
    </LocalizationProvider>
  );
};

export default QCDatePicker;
