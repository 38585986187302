import { SxProps, Theme } from '@mui/material';

export const SharedListDesignContainer = (): SxProps => ({
  marginTop: '3vh',
});

export const SharedListAttributesContainer = (theme: Theme): SxProps => ({
  paddingX: '2vw',
  border: 'solid 2px',
  borderColor: theme.palette.secondary.main,
  borderTopLeftRadius: '8px',
  borderTopRightRadius: '8px',
});

export const InputsGridContainer: SxProps = {
  display: 'grid',
  flexWrap: 'wrap',
  gridTemplateColumns: 'repeat(2, 1fr)',
  gridTemplateRows: 'repeat(2, 1fr)',
  columnGap: '30px',
};

export const SharedListElementsContainer = (theme: Theme): SxProps => ({
  paddingY: '2vh',
  paddingX: '2vw',
  marginTop: '-2px',
  border: 'solid 2px',
  borderColor: theme.palette.menuBorder.main,
  borderBottomLeftRadius: '8px',
  borderBottomRightRadius: '8px',
});
