import { ChangeEvent, Dispatch, useContext, useState } from 'react';
import { SxProps, useTheme } from '@mui/material';
import { NotificationData, TargetData } from '90.quickConnect.Models/siteAdmin/models/common/entities';
import useControls from '90.quickConnect.Models/siteAdmin/inputValidator/controls';
import { EmailContext } from '10.quickConnect.app/siteAdmin/components/domains/Ressources/ResourceSettings/context';
import useEffectOnce from '80.quickConnect.core/shared/hooks/useEffectOnce';

const useData = (notifications: NotificationData, dispatch: Dispatch<unknown>) => {
  const { email } = useControls();
  const { currentEmail, setCurrentEmail, emails, setEmails } = useContext(EmailContext);
  const [showCollapse, setShowCollapse] = useState<boolean>(false);
  const [isValidEmail, setIsValidEmail] = useState<boolean>(false);

  useEffectOnce(() => {
    setEmails(notifications.selectedTargets);
  });

  const handleClickShowCollapse = () => {
    setShowCollapse((prevShow) => !prevShow);
  };

  const handleChangeInputEmail = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setCurrentEmail((prevEmail) => {
      return { ...prevEmail, target: event.target.value };
    });
    if (email.regex.exec(event.target.value) !== null) {
      setIsValidEmail(true);
    }
  };

  const handleChangeInputAlias = (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    setCurrentEmail((prevEmail) => {
      return { target: prevEmail.target ?? '', alias: event.target.value };
    });
  };

  const addEmail = () => {
    setEmails((prevEmails) => {
      const condition = prevEmails.findIndex(
        (not) =>
          not.target === currentEmail.target ||
          (not.alias !== undefined && not.alias !== '' && not.alias === currentEmail.alias),
      );
      if (condition === -1) {
        dispatch({
          selectedTargets: [...prevEmails, currentEmail],
          notificationType: notifications.notificationType,
        } as NotificationData);
        return [...prevEmails, currentEmail];
      }
      return prevEmails;
    });
    setCurrentEmail({} as TargetData);
  };

  const deleteEmail = (target: string, alias?: string) => {
    setEmails((prevEmails) => {
      if (prevEmails.findIndex((not) => not.target === target && not.alias === alias) !== -1) {
        const newEmails = prevEmails.filter((el) => el.target !== target || el.alias !== alias);
        dispatch({
          selectedTargets: newEmails,
          notificationType: notifications.notificationType,
        } as NotificationData);
        return newEmails;
      }
      return prevEmails;
    });
  };

  const useSx = (value: string): SxProps => {
    const theme = useTheme();
    if (value !== '' && email.regex.exec(value) === null) {
      return {
        '.MuiInput-underline:after': {
          color: `${theme.palette.error.main} !important`,
          borderBottomColor: `${theme.palette.error.main} !important`,
        },
        '.MuiInput-underline:before': {
          color: `${theme.palette.error.main} !important`,
          borderBottomColor: `${theme.palette.error.main} !important`,
        },
        '.MuiInputLabel-standard': {
          color: `${theme.palette.error.main} !important`,
        },
      };
    }
    return {};
  };

  return {
    showCollapse,
    handleClickShowCollapse,
    currentEmail,
    handleChangeInputEmail,
    handleChangeInputAlias,
    emails,
    addEmail,
    deleteEmail,
    useSx,
    isValidEmail,
  };
};

export default useData;
