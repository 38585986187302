import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import {
  GridEventListener,
  GridRenderCellParams,
  GridRowId,
  useGridApiContext,
  useGridApiEventHandler,
} from '@mui/x-data-grid';
import QCKebabMenu from '../../QCKebabMenu';
import { ChoiceMenuOptionType } from '70.quickConnect.Components/siteAdmin/Inputs/QCChoiceMenu/type';

const DefaultKebabMenuCell = ({
  params,
  options,
  onSelectRowAction,
}: {
  params: GridRenderCellParams;
  options: ChoiceMenuOptionType[];
  onSelectRowAction?: Dispatch<SetStateAction<{ option: string; rowId: GridRowId }>>;
}) => {
  const [isHover, setIsHover] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');

  const handleRowMouseEnter: GridEventListener<'rowMouseEnter'> = (eventParams) => {
    if (eventParams.row.id === params.id) setIsHover(true);
    else {
      setIsHover(false);
    }
  };

  const handleRowMouseLeave: GridEventListener<'rowMouseLeave'> = (eventParams) => {
    if (eventParams.row.id === params.id) setIsHover(false);
  };

  const apiRef = useGridApiContext();

  useEffect(() => {
    if (onSelectRowAction) onSelectRowAction({ option: selectedOption, rowId: params.id });
  }, [selectedOption, onSelectRowAction, params.id]); // eslint-disable react-hooks/exhaustive-deps

  useGridApiEventHandler(apiRef, 'rowMouseEnter', handleRowMouseEnter);
  useGridApiEventHandler(apiRef, 'rowMouseLeave', handleRowMouseLeave);
  return <>{isHover && <QCKebabMenu options={options} dispatch={setSelectedOption} />}</>;
};

export default DefaultKebabMenuCell;
