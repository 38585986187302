import { action, makeAutoObservable, observable } from 'mobx';
import { makePersistable } from 'mobx-persist-store';
import { TFunction } from 'react-i18next';
import axios from 'axios';
import { toast } from 'react-toastify';
import RootStore from '../index';
import {
  DispatcherResponse,
  SignInResponse,
  SignOutResponse,
} from '40.quickConnect.DataAcces/siteAdmin/services/login/payloads/responses';
import { DispatcherRequest, SignInRequest } from '40.quickConnect.DataAcces/siteAdmin/services/login/payloads/requests';
import { ResponseStatus } from '90.quickConnect.Models/siteAdmin/enums/requests/responseStatus';
import API_HOST from '40.quickConnect.DataAcces/siteAdmin/apiRoutes/apiHost';
import { IdentityProviders } from '90.quickConnect.Models/siteAdmin/enums/user/identityProviders';
import { MsalInfos } from '90.quickConnect.Models/siteAdmin/models/user/msalConfig';
import ApiAccount from '40.quickConnect.DataAcces/siteAdmin/apiRoutes/account';
import { CustomAxiosError } from '40.quickConnect.DataAcces/siteAdmin/QCHttpRequest/types';
import { API_DEV_DISPATCHER, API_PROD_DISPATCHER } from '40.quickConnect.DataAcces/siteAdmin/apiRoutes/info';
import { getRoleFromRigths } from '80.quickConnect.core/siteAdmin/helpers/getRoleFromRigths';
import CustomLogger from '80.quickConnect.core/siteAdmin/logger/customLogger';
import logDb from '40.quickConnect.DataAcces/siteAdmin/indexedDB/dbs/logDB';
import getSessionId from '80.quickConnect.core/shared/helpers/getSessionId';
import { API_POST_INSTRUMENTATION } from '40.quickConnect.DataAcces/siteAdmin/ClientapiRoutes';
import { APP_NAME } from 'const';
import { TelemetryLogsRequest } from '40.quickConnect.DataAcces/siteAdmin/services/log/payloads/requests';

const defaultSignInResponse: SignInResponse = {
  status: ResponseStatus.Pending,
  appInformation: { 'Nom du produit': 'QuickConnect', Copyright: '© C2S Bouygues', 'Numéro de version api': ' ' },
  authenticationMethod: 1,
  message: '',
  userUPN: '',
  isLoggedIn: false,
  firstName: '',
  lastName: '',
  job: '',
  organizationalUnitName: '',
  phone: '',
  email: '',
  rights: 0,
  customerName: '',
  providerSettings: {
    instance: '',
    domain: '',
    tenantId: '',
    authority: '',
    iOS: {
      clientId: '',
      clientSecret: '',
      redirectUri: '',
      callbackPath: '',
    },
    android: {
      clientId: '',
      clientSecret: '',
      redirectUri: '',
      callbackPath: '',
    },
    web: {
      clientId: '',
      clientSecret: '',
      redirectUri: '',
      callbackPath: '',
    },
    spa: {
      clientId: '',
      clientSecret: '',
      redirectUri: '',
      callbackPath: '',
    },
  },
  subscribedFeatures: {
    'FEAT-001': false,
    'FEAT-002': false,
    'FEAT-003': false,
    'FEAT-004': false,
    'FEAT-005': false,
    'FEAT-006': false,
    'FEAT-007': false,
  },
  userParameterValue: [],
};

class LoginStore {
  signInInfos: SignInResponse = defaultSignInResponse;

  connected = false;

  authenticationMethod: IdentityProviders = IdentityProviders.AzureActiveDirectory;

  isLogging = false;

  // Dans le cas d'une connexion SSO avec l'AAD en utilisant la redirection
  connectByMsal = false;

  // Utilisation du RootStore afin d'appeler les Stores d'identification (LoginMsalStore, etc...);
  RootStore: RootStore;

  isLoggingOut = false;

  logger: CustomLogger;

  urlToUse: string | undefined = API_HOST;

  constructor(rootStore: RootStore, logger: CustomLogger, storageKey: string) {
    this.logger = logger;
    this.RootStore = rootStore;

    makeAutoObservable(
      this,
      {
        signInInfos: observable,
        authenticationMethod: observable,
        isLogging: observable,
        isLoggingOut: observable,
        connected: observable,
        connectByMsal: observable,
        urlToUse: observable,
        resetStore: action,
        logInAsync: action,
        logOutAsync: action,
        setConnectByMsal: action,
        setSignInInfos: action,
        setAuthenticationMethod: action,
        setIsLogging: action,
        setIsLoggingOut: action,
        setUrlToUse: action,
      },
      { autoBind: true },
    );
    void makePersistable(this, {
      name: storageKey,
      properties: ['connected', 'connectByMsal', 'urlToUse', 'signInInfos', 'authenticationMethod'],
      storage: window.sessionStorage,
    });
  }

  resetStore = () => {
    this.setSignInInfos(defaultSignInResponse);
    this.setIsLogging(false);
    this.setIsLoggingOut(false);
    this.setAuthenticationMethod(IdentityProviders.AzureActiveDirectory);
    this.setConnectByMsal(false);
    this.setUrlToUse(undefined);
  };

  setConnectByMsal = async (isMsalRedirecting: boolean) => (this.connectByMsal = isMsalRedirecting);

  setIsLogging = (isLogging: boolean) => (this.isLogging = isLogging);

  setIsLoggingOut = (isLoggingOut: boolean) => (this.isLoggingOut = isLoggingOut);

  setSignInInfos = (signInInfos: SignInResponse) => {
    this.signInInfos = signInInfos;
    this.connected = signInInfos.isLoggedIn;
  };

  setUrlToUse = (url: string | undefined) => {
    this.urlToUse = url;
  };

  setAuthenticationMethod = (authenticationMethod: IdentityProviders) =>
    (this.authenticationMethod = authenticationMethod);

  verifyFieldUpnAndCgu = ({ userUPN, cgu }: SignInRequest, t: TFunction): boolean => {
    if (userUPN.trim() === '') {
      toast.error(t('logInAsync.errorUserUPN').toString());
      return false;
    }

    if (!cgu) {
      toast.error(t('logInAsync.errorCgu').toString());
      return false;
    }

    return true;
  };

  verifyFieldPwd = ({ password }: SignInRequest): boolean => {
    if (password.trim() == '') {
      return false;
    }
    return true;
  };

  logInAsync = async (request: SignInRequest, t: TFunction): Promise<void> => {
    this.logger.resetInitDateTimeApp();
    this.setIsLogging(true);
    axios
      .post<SignInResponse>(this.RootStore.CommonStore.chooseBaseUrl(ApiAccount.Signin), request, {
        withCredentials: true,
      })
      .then(async (response) => {
        if (200 <= response.status && response.status < 300) {
          // Si l'utilisateur n'a pas les droits requis on affiche une erreur
          if (
            this.authenticationMethod === IdentityProviders.QuickConnect &&
            (response.data.rights === 0 || response.data.rights === 1)
          ) {
            toast.error(t('common.error.login.unauthorized').toString());
          } else if (
            response.data?.authenticationMethod === IdentityProviders.AzureActiveDirectory &&
            !this.connectByMsal
          ) {
            // Utilisation du LoginMsalStore pour séparer le traitement du logIn par Msal

            this.setConnectByMsal(true);

            const {
              providerSettings: { spa, instance, domain, tenantId, authority },
              userUPN,
            } = response.data;

            const msalInfos: MsalInfos = {
              instance,
              domain,
              tenantId,
              authority,
              spa,
              userUPN,
            };

            this.RootStore.LoginMsalStore.redirectLogin(msalInfos);
            console.log();
          } else {
            // Connexion réussie. On redirige vers la page HOME
            this.setSignInInfos(response.data);
            const role = getRoleFromRigths(this.signInInfos.rights, this.signInInfos.customerName);
            this.RootStore.UserSettingsStore.setRole(role);
            this.RootStore.UserSettingsStore.setSubscribedFeatures(response.data.subscribedFeatures);
          }
        } else await this.logger.error(`réponse du serveur KO pour la connexion : ${response.status}`);
      })
      .catch(async (error) => {
        // eslint-disable-next-line
        const errorAxios = error as CustomAxiosError<any>;
        if (
          errorAxios?.response?.status === 401 &&
          this.authenticationMethod === IdentityProviders.QuickConnect &&
          this.verifyFieldPwd(request) === false
        ) {
          toast.error(t('logInAsync.errorPwd').toString());
        }
        if (
          errorAxios?.response?.status !== 404 &&
          errorAxios?.response?.data?.authenticationMethod === IdentityProviders.QuickConnect
        ) {
          // Pour éviter l'affichage du champ password quand l'userUPN est un user msal
          if (errorAxios?.response?.status === 400) {
            toast.error(
              `${t('shared.message.notifyError.badRequest', { errorMessage: errorAxios.response.data.message })}`,
            );
            this.setAuthenticationMethod(IdentityProviders.AzureActiveDirectory);
          } else if (errorAxios?.response?.status === 401) {
            this.setAuthenticationMethod(errorAxios.response.data.authenticationMethod);
          }
        } else {
          this.resetStore();
        }

        if (errorAxios?.response?.status === 404 && errorAxios?.response?.data?.errorCode === 'MSG_SQL_USERNOTFOUND') {
          await this.logger.error(errorAxios?.response?.data?.message);
          toast.error(t('logInAsync.userNotFound').toString());
        } else if (request.password) {
          const errorMsg = errorAxios?.response
            ? `${errorAxios?.response?.status} ${errorAxios?.response?.statusText}`
            : errorAxios?.message;
          await this.logger.error(errorMsg);
          toast.error(
            t('logInAsync.errorWithMsg', {
              msg: errorMsg,
            }).toString(),
          );
        }
      })
      .finally(() => {
        this.setIsLogging(false);
      });
  };

  logOutAsync = async (t: TFunction): Promise<void> => {
    // Vérification si l'utilisateur s'est connecté par l'AAD
    if (this.connectByMsal) {
      // On doit loggout l'utilisateur en faisant appel à Microsoft
      await this.RootStore.LoginMsalStore.logoutMsalAsync(t);

      // code jamais appelé à cause de la redirection msal
      this.setConnectByMsal(false);
      //
    } else {
      this.setIsLoggingOut(true);
      axios
        .post<SignOutResponse>(
          this.RootStore.CommonStore.chooseBaseUrl(ApiAccount.Signout),
          {},
          {
            withCredentials: true,
          },
        )
        .then(async (response) => {
          if (200 <= response.status && response.status < 300) {
            this.setAuthenticationMethod(IdentityProviders.AzureActiveDirectory);
          } else await this.logger.error(`réponse du serveur KO pour la déconnexion : ${response.status}`);
        })
        .catch(async (error) => {
          // eslint-disable-next-line
          const errorAxios = error as CustomAxiosError<any>;
          const errorMsg = errorAxios?.response
            ? `${errorAxios?.response?.status} ${errorAxios?.response?.statusText}`
            : errorAxios?.message;
          await this.logger.error(errorMsg);
          toast.error(
            t('logOutAsync.errorWithMsg', {
              msg: errorMsg,
            }).toString(),
          );
        })
        // finally non appelé lors de la deco msal
        .finally(async () => {
          this.RootStore.resetAllObservables();
          await this.RootStore.clearAllDomainStores();
        });
    }
  };

  dispatcherAsync = async (request: DispatcherRequest): Promise<DispatcherResponse | undefined> => {
    this.logger.resetInitDateTimeApp();
    this.setIsLogging(true);
    const { REACT_APP_ENV, REACT_APP_DEV_KEY_DISPATCHER, REACT_APP_PROD_KEY_DISPATCHER } = process.env;
    const url =
      REACT_APP_ENV === 'dev' || REACT_APP_ENV === 'int' || REACT_APP_ENV === 'demo'
        ? API_DEV_DISPATCHER
        : API_PROD_DISPATCHER;
    const key =
      REACT_APP_ENV === 'dev' || REACT_APP_ENV === 'int' || REACT_APP_ENV === 'demo'
        ? REACT_APP_DEV_KEY_DISPATCHER
        : REACT_APP_PROD_KEY_DISPATCHER;
    const { data } = await axios.post<DispatcherResponse>(`${url}`, request, {
      headers: { 'x-mobile-dispatcher-key': key ?? '' },
    });
    if (data) {
      return data;
    }
  };

  /**
   * Permet d'envoyer les logs à l'API via le endPoint "saveintrumentation"
   * @param {string} userUPN Identifiant de l'utilisateur
   * @returns True si ok
   */
  sendLogsToServerAsync = async (userUPN: string): Promise<boolean> => {
    try {
      // Récupération de tous les logs exceptés les logs errors dans la table indexedDB

      const telemetrylogDB = await logDb.getLogs(this.logger);

      if (telemetrylogDB) {
        // Construction de la requête
        const IMEI = getSessionId();
        const ApplicationName = APP_NAME;
        const { CrashDump, Items } = telemetrylogDB;
        const telemetryLogsRequest: TelemetryLogsRequest = {
          userUPN,
          IMEI,
          ApplicationName,
          CrashDump,
          Items,
        };

        // Récupération de la response
        const response = await axios.post(
          this.RootStore.CommonStore.chooseBaseUrl(API_POST_INSTRUMENTATION),
          telemetryLogsRequest,
          {
            withCredentials: true,
          },
        );

        if (200 > response.status && response.status >= 300) {
          this.logger.error(
            "La requête de l'instrumentation dans la function sendLogsToServerAsync dans le declarationStore a échoué",
          );
          return false;
        }
        // Destruction des logs
        await logDb.clearAllTables(this.logger);
        return true;
      } else {
        return true;
      }
    } catch (error) {
      this.logger.error(error);
      return false;
    }
  };
}

export default LoginStore;
