import { isJSON } from './json';
import { SharedListDataType } from '90.quickConnect.Models/siteAdmin/enums/sharedList/sharedListType';
import {
  HierarchicalDataChildren,
  HierarchicalSharedListsData,
  JsonObject,
  SharedListData,
} from '90.quickConnect.Models/siteAdmin/models/sharedList/sharedList';

export const parseJsonSharedListData = () => {};

export const parseCsvSharedListData = (data: string) => {
  const values = data.split(',');
  return values;
};

export const parseStringToCsvSharedListData = (values: string[]): string => {
  return values.join(',');
};

// retourne le type d'une data d'une liste partagée
export const getSharedListDataType = (data: string | JsonObject): SharedListDataType => {
  if (isJSON(data)) return SharedListDataType.Json;

  return SharedListDataType.Csv;
};

// le label d'un élément d'une liste doit être unique
export const doesLabelExist = (sharedListElements: SharedListData[], label: string) =>
  sharedListElements.some((el) => el.label === label);

export const elementsToText = (sharedListElements: SharedListData[]): string => {
  let result = '';
  let dataType: SharedListDataType | undefined = undefined;
  sharedListElements.some((el) => {
    if (!el.data) return false;
    dataType = getSharedListDataType(el.data);
    return true;
  });

  sharedListElements.forEach((el) => {
    if (el.data === undefined || dataType === undefined) {
      result = `${result}${el.label}¤${el.value}\n`;
      return;
    }
    switch (dataType) {
      case undefined:
        result = `${result}${el.label}¤${el.value}\n`;
        break;
      case SharedListDataType.Csv:
        result = `${result}${el.label}¤${el.value}¤"${el.data}"\n`;
        break;
      case SharedListDataType.Json:
        result = `${result}${el.label}¤${el.value}¤${JSON.stringify(el.data)}\n`;
        break;
      default:
        const exhaustiveCheck: never = dataType;
        throw new Error(`Unknown SharedListDataType: ${exhaustiveCheck}`);
    }
  });

  return result;
};

export const textToJson = (toJson: string) => {
  const jObj: JsonObject = {};
  const lineToJSON = toJson.split(',');
  for (let j = 0; j < lineToJSON.length; j++) {
    const jObjHeader = `col${j}`;
    jObj[jObjHeader] = isNaN(Number(lineToJSON[j])) ? lineToJSON[j] : Number(lineToJSON[j]);
  }
  return jObj;
};

export const textToElements = (text: string, dataType: SharedListDataType): SharedListData[] => {
  const data: SharedListData[] = [];
  const rows = text.split('\n');
  if (rows !== null && rows.length > 0) {
    rows.forEach((row) => {
      const e = row.split('¤');
      if (e != null && e[0] !== '') {
        if (e.length === 1) {
          // label == value
          data.push({ label: e[0], value: e[0] });
        } else if (e.length === 2) {
          data.push({ label: e[0], value: e[1] });
        } else if (e.length === 3) {
          if (isJSON(e[2])) {
            data.push({ label: e[0], value: e[1], data: JSON.parse(e[2]) });
          } else {
            let elementData: string | JsonObject;

            switch (dataType) {
              case SharedListDataType.Csv:
                const [, , dataString] = e;
                if (dataString[0] !== '"' || dataString[dataString.length - 1] !== '"')
                  throw new Error('Invalid format, brackets missing');
                // on enlève les guillemets
                elementData = dataString.slice(1, dataString.length - 1);
                console.log(elementData);
                break;
              case SharedListDataType.Json:
                elementData = textToJson(e[2]);
                break;
              default:
                const exhaustiveCheck: never = dataType;
                throw new Error(`Unhandled Data Type : ${exhaustiveCheck}`);
            }
            data.push({ label: e[0], value: e[1], data: elementData });
          }
        } else {
          throw new Error('Invalid Shared List Data Format');
        }
      }
    });
  }

  return data;
};

// entrée : les labels, sortie : enfant avec sous enfants
const pushLabels = (
  labels: string[],
  finalValue?: string,
  finalData?: string | JsonObject,
): HierarchicalDataChildren => {
  const children: HierarchicalDataChildren = [];

  if (labels.length > 1) children.push({ label: labels[0], children: pushLabels(labels.splice(1, labels.length - 1)) });
  else if (labels.length === 1) {
    children.push({ label: labels[0], value: finalValue ?? labels[0], data: finalData });
  }

  return children;
};

export const elementsToHierarchicalElements = (sharedListElements: SharedListData[]): HierarchicalSharedListsData[] => {
  const hierarchicalData: HierarchicalSharedListsData[] = [];

  //on ajoute le premier niveau
  sharedListElements.forEach((el) => {
    const labels = el.label.split('\\');

    // si l'élément est simple
    if (labels.length === 1) {
      hierarchicalData.push({ label: labels[0], value: el.value, data: el.data });
      return;
    }

    // si le label existe déjà
    // TODO : gérer les sous labels identiques
    const data = hierarchicalData.find((obj) => obj.label === labels[0]);
    if (data) {
      if ('value' in data || labels.length === 1) throw new Error('Element should have unique label');
      const children: HierarchicalDataChildren = pushLabels(labels.splice(1, labels.length - 1), el.value, el.data);
      data.children = children;
      const indexToChange = hierarchicalData.findIndex((obj) => obj.label === labels[0]);
      if (indexToChange === -1) throw new Error('Data has mutated during treatment');
      hierarchicalData[indexToChange] = data;
      return;
    }

    // s'il n'existait pas
    hierarchicalData.push({ label: labels[0], children: pushLabels(labels.splice(1, labels.length - 1)) });
  });

  console.log(hierarchicalData);
  return hierarchicalData;
};
