import { Theme, SxProps } from '@mui/material';

export const SharedListLabelStyle = (showData: boolean): SxProps => ({
  display: '-webkit-box',
  position: 'relative',
  width: 'fit-content',
  maxWidth: '50%',
  overflow: 'hidden',
  lineClamp: showData ? 'auto' : 2,
  WebkitLineClamp: showData ? 'auto' : 2,
  WebkitBoxOrient: 'vertical',
  textOverflow: 'ellipsis',
  textJustify: 'inter-word',
});

export const ElementContainerStyle = (theme: Theme): SxProps => ({
  width: '100%',
  marginY: '10px',
  paddingY: '10px',
  paddingX: '15px',
  backgroundColor: theme.palette.toolsBackground.main,
  position: 'relative',
  borderRadius: '6px',
  ':hover': {
    backgroundColor: theme.palette.itemListHover.main,
  },
});

export const LabelValueContainerStyle: SxProps = {
  display: 'inline-list-item',
  position: 'relative',
  width: '100%',
};

export const ValueContainerStyle = (theme: Theme, showData: boolean): SxProps => ({
  color: theme.palette.grey[400],
  marginLeft: '13px',
  width: 'fit-content',
  maxWidth: '45%',
  overflow: 'hidden',
  lineClamp: showData ? 'auto' : 2,
  WebkitLineClamp: showData ? 'auto' : 2,
  WebkitBoxOrient: 'vertical',
  textOverflow: 'ellipsis',
  textJustify: 'inter-word',
});

// display: 'inline-list-item',
// position: 'relative',
// width: 'fit-content',
// maxWidth: '45%',
// overflow: 'hidden',
// lineClamp: showData ? 'auto' : 2,
// WebkitLineClamp: showData ? 'auto' : 2,
// WebkitBoxOrient: 'vertical',
// textOverflow: 'ellipsis',
// textJustify: 'inter-word',
