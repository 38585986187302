import { Box, ClickAwayListener, Fade, IconButton, Popper, TextField, useTheme } from '@mui/material';
import React, { useState } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import QCInputHeader from '../QCInputHeader';
import { InputContainerStyle } from '../style';
import { QCTreeViewAutocompleteProps } from './type';
import { PopperStyle, TreeViewStyle } from './styles';
import QCTreeView from '70.quickConnect.Components/siteAdmin/Other/TreeView';
import { TreeViewNode } from '70.quickConnect.Components/siteAdmin/Other/TreeView/types';
import { findNode } from '70.quickConnect.Components/siteAdmin/Other/TreeView/hooks';

const QCTreeViewAutocomplete = ({
  dispatch,
  label,
  info,
  mandatory,
  className,
  allData,
  value: defaultValue,
}: QCTreeViewAutocompleteProps) => {
  const theme = useTheme();

  const [isOpen, setIsOpen] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);

  const handleChange = (value: TreeViewNode) => {
    if (dispatch) dispatch(value);
  };

  return (
    <Box sx={{ ...InputContainerStyle(theme, mandatory) }}>
      <ClickAwayListener
        onClickAway={() => {
          setIsOpen(false);
        }}
      >
        <Box className={className} sx={{ '.MuiFormControl-root': { display: 'flex', flexGrow: 1 } }}>
          {label && <QCInputHeader label={label} info={info} mandatory={mandatory} />}
          {/* utiliser notre QCTextField ici ? */}
          <TextField
            value={defaultValue !== undefined && defaultValue !== '' ? findNode(defaultValue, allData).name : ''}
            contentEditable={false}
            disabled
            sx={{
              '.Mui-disabled .MuiOutlinedInput-notchedOutline': {
                borderColor: `${theme.palette.secondary.main} !important`,
              },
            }}
            InputProps={{
              endAdornment: (
                <IconButton
                  onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                    setAnchorEl(event.currentTarget.parentElement);
                    setIsOpen((prevOpen) => !prevOpen);
                  }}
                >
                  {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                </IconButton>
              ),
            }}
          />
          <Popper anchorEl={anchorEl} open={isOpen} sx={PopperStyle(anchorEl)} placement="bottom" transition>
            {({ TransitionProps }) => (
              <Fade {...TransitionProps}>
                <Box>
                  <QCTreeView data={allData} setValue={handleChange} sx={TreeViewStyle} />
                </Box>
              </Fade>
            )}
          </Popper>
        </Box>
      </ClickAwayListener>
    </Box>
  );
};

export default QCTreeViewAutocomplete;
