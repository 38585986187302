import { useTheme } from '@mui/material';
import { Dispatch, MouseEventHandler, SetStateAction, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SubMenuRow } from './types';
import { useStore } from '30.quickConnect.Store/siteAdmin';

export const useData = (
  rows: SubMenuRow[],
  getSelectedMultipleAction: Dispatch<SetStateAction<{ option: string; rowsId: string[] }>> | undefined,
) => {
  const {
    SubMenuStore: { menuSize, setMenuSize },
  } = useStore();
  const theme = useTheme();
  const { t } = useTranslation();

  const [showMultipleActions, setShowMultipleActions] = useState(false);

  const [selectedRowsId, setSelectedRowsId] = useState<string[]>([]);

  const handleActionCheckboxClick: MouseEventHandler = () => {
    if (selectedRowsId.length > 0) {
      setSelectedRowsId([]);
    } else {
      setSelectedRowsId(rows.map((row) => row.id));
    }
  };

  const handleRowCheckboxClick = (id: string) => {
    if (selectedRowsId.includes(id)) {
      setSelectedRowsId((curr) => curr.filter((value) => value !== id));
    } else {
      setSelectedRowsId((curr) => [...curr, id]);
    }
  };

  // Ancre du menu pour les actions multiples
  const [actionMenuAnchor, setActionMenuAnchor] = useState<HTMLElement | null>(null);

  const handleMultipleActionClick: MouseEventHandler<HTMLElement> = (event) => {
    setActionMenuAnchor(event.currentTarget);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleMultipleActionSelectedOption = (option: string) => {
    if (getSelectedMultipleAction) getSelectedMultipleAction({ option: option, rowsId: selectedRowsId });
  };

  // // Pour les formulaires : boolean du mode dossier
  // const [isFolderViewMode, setIsFolderViewMode] = useState(true);

  // Permet de garder les dossiers ouverts lors des rerenders
  const [openedFolders, setOpenedFolders] = useState<string[]>([]);

  // Gestion de la taille du SubMenu

  const mainRef = useRef<HTMLDivElement>(null);
  const rightRef = useRef<HTMLHRElement>(null);
  const stackRef = useRef<HTMLDivElement>(null);

  const [x, setX] = useState(menuSize);

  useEffect(() => {
    let size = menuSize;
    const onMouseMoveRightResize = (event: MouseEvent) => {
      size = event.clientX - 325;

      setX(event.clientX - 325);
    };

    const onMouseDownRightResize = () => {
      document.addEventListener('mousemove', onMouseMoveRightResize);
    };

    const onMouseUpRightResize = () => {
      if (size != x) setMenuSize(size);
      document.removeEventListener('mousemove', onMouseMoveRightResize);
    };

    const resizerRight = rightRef.current;
    resizerRight?.addEventListener('mousedown', onMouseDownRightResize);
    resizerRight?.addEventListener('mouseup', onMouseUpRightResize);

    return () => {
      resizerRight?.removeEventListener('mousedown', onMouseDownRightResize);
      resizerRight?.removeEventListener('mouseup', onMouseUpRightResize);
    };
    // eslint-disable-next-line
  }, [menuSize]);

  return {
    theme,
    t,
    showMultipleActions,
    setShowMultipleActions,
    selectedRowsId,
    handleActionCheckboxClick,
    handleRowCheckboxClick,
    actionMenuAnchor,
    setActionMenuAnchor,
    handleMultipleActionClick,
    handleMultipleActionSelectedOption,
    openedFolders,
    setOpenedFolders,
    mainRef,
    stackRef,
    rightRef,
    x,
  };
};
