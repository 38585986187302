import { SxProps, useTheme } from '@mui/material';
import { useStore } from '30.quickConnect.Store/siteAdmin';

export const MainBox = () => {
  const theme = useTheme();
  const {
    SideMenuStore: { isOpenSideMenu },
  } = useStore();
  return {
    mt: '65px',
    height: `${window.innerHeight - 80}px`,
    maxHeight: `${window.innerHeight - 80}px`,
    transition: theme.transitions.create(['margin', 'width'], {
      easing: theme.transitions.easing.easeInOut,
      duration: theme.transitions.duration.standard,
    }),
    ...{
      [theme.breakpoints.up('lg')]: {
        width: `calc(${window.innerWidth}px - ${isOpenSideMenu ? `300px - 4vw` : `80px - 4vw`})`,
      },
      [theme.breakpoints.between('md', 'lg')]: {
        width: `calc(${window.innerWidth}px - ${isOpenSideMenu ? `275px - 4vw` : `64px - 4vw`})`,
      },
      [theme.breakpoints.down('md')]: {
        width: `calc(${window.innerWidth}px - ${isOpenSideMenu ? `250px - 4vw` : `56px - 4vw`})`,
      },
    },
    backgroundColor: theme.palette.background.default,
    border: `2px solid ${theme.palette.menuBorder.main}`,
    borderRadius: '8px',
    display: 'flex',
    flexDirection: 'column',
    gap: 5,
    position: 'absolute',
    p: 5,
  } as SxProps;
};

export const btnsBoxStyle = {
  display: 'flex',
  flexDirection: 'column',
  gap: 1,
  position: 'absolute',
  right: 0,
  bottom: 0,
};

export const tabPanelStyle = {
  display: 'flex',
  justifyContent: 'space-around',
  flexWrap: 'wrap',
  marginTop: '20px',
};

export const traductionFieldsStyle = {
  height: '45vh',
  overflow: 'auto',
};
