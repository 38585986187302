import React from 'react';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import { Box, Card, useTheme } from '@mui/material';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import { useNavigate } from 'react-router';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import TranslateIcon from '@mui/icons-material/Translate';
import { ActionButtonsContainer, EditContainerStyle, InputsCardStyle } from './style';
import useRoleSettingsData from './hooks';
import MenuWrapper from '10.quickConnect.app/siteAdmin/components/shared/MenuWrapper';
import ContentHeader from '70.quickConnect.Components/siteAdmin/Other/ContentHeader';
import QCTextInput from '70.quickConnect.Components/siteAdmin/Inputs/QCTextInput';
import QCTextArea from '70.quickConnect.Components/siteAdmin/Inputs/QCTextArea';
import QCAutocomplete from '70.quickConnect.Components/siteAdmin/Inputs/QCAutocomplete';
import { capitalize } from '80.quickConnect.core/shared/helpers/capitalize';
import QCFabCorbeille from '70.quickConnect.Components/siteAdmin/Buttons/QCFabCorbeille';
import QCIconButton from '70.quickConnect.Components/siteAdmin/Buttons/QCIconButton';
import DeleteConfirmationDialog from '70.quickConnect.Components/siteAdmin/Other/DeleteConfirmationDialog';
import SaveConfirmationDialog from '70.quickConnect.Components/siteAdmin/Other/SaveConfirmationDialog';
import QCButtonGlobal from '70.quickConnect.Components/siteAdmin/Buttons/QCButtonGlobal';

const RoleSettings = () => {
  const { t } = useTranslation();
  const {
    pageRole,
    newPageRole,
    setNewPageRole,
    supportedLanguages,
    handleConfirm,
    handleDelete,
    openDeleteDepDialog,
    openDeleteDialog,
    deleteDispatch,
    deleteDepDispatch,
    openSaveDialog,
    saveDispatch,
    control,
    getRoleDependenciesFile,
  } = useRoleSettingsData();
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <MenuWrapper>
      <ContentHeader>
        <ContentHeader.BackButton />
        <ContentHeader.TitlesGroup>
          <ContentHeader.OverTitle>{t('version.setting.overtitle')}</ContentHeader.OverTitle>
          <ContentHeader.Title>{pageRole?.name}</ContentHeader.Title>
        </ContentHeader.TitlesGroup>
      </ContentHeader>
      <Box sx={EditContainerStyle}>
        <Box sx={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
          <Card sx={InputsCardStyle(theme)}>
            <h2>{t('profil.designer.placeholder.description')}</h2>
            <Box>
              <QCTextInput
                editable
                value={newPageRole ? newPageRole.name : ''}
                label={t('profil.designer.placeholder.name')}
                dispatch={(value: string) => {
                  setNewPageRole((currentRole) => {
                    if (currentRole) {
                      const newRole = { ...currentRole };
                      newRole.name = value;
                      return newRole;
                    }
                  });
                }}
                control={control.notNull}
              />
            </Box>
            <Box>
              <QCTextArea
                value={newPageRole ? newPageRole.description : ''}
                label={t('profil.designer.placeholder.description')}
                dispatch={(value: string) => {
                  setNewPageRole((currentRole) => {
                    if (currentRole) {
                      const newRole = { ...currentRole };
                      newRole.description = value;
                      return newRole;
                    }
                  });
                }}
                control={control.notNull}
              />
            </Box>
            <Box width="300px">
              <QCAutocomplete
                options={supportedLanguages.map((elem) => capitalize(elem.lang))}
                value={
                  newPageRole
                    ? capitalize(
                        supportedLanguages.find((elem) => elem.value === newPageRole.defaultLanguage)?.lang ?? '',
                      )
                    : pageRole
                    ? capitalize(
                        supportedLanguages.find((elem) => elem.value === pageRole?.defaultLanguage)?.lang ?? '',
                      )
                    : capitalize(supportedLanguages[0]?.lang ?? '')
                }
                label={t('common.internationalization.defaultLanguage')}
                dispatch={(value: string) => {
                  setNewPageRole((currentRole) => {
                    if (currentRole) {
                      const newRole = { ...currentRole };
                      newRole.defaultLanguage = value;
                      return newRole;
                    }
                  });
                }}
                datacy="version-companion"
              />
            </Box>
          </Card>
          <Box>
            <QCButtonGlobal
              className="dependency-button"
              value={t('profil.designer.actions.getDependencies')}
              startIcon={<FileDownloadIcon />}
              action={() => {
                getRoleDependenciesFile();
              }}
            />
          </Box>
        </Box>
        <Box sx={ActionButtonsContainer}>
          <QCFabCorbeille onClickFunc={handleDelete}></QCFabCorbeille>
          {!pageRole?.isInternal ? (
            <QCIconButton
              Icon={<TranslateIcon color={'primary'} />}
              onClickFunc={() => navigate('translation')}
              variantValue="outlined"
            />
          ) : (
            <></>
          )}
          <QCIconButton Icon={<SaveOutlinedIcon />} onClickFunc={handleConfirm} variantValue="contained" />
          <DeleteConfirmationDialog open={openDeleteDialog} dispatch={deleteDispatch} />
          <DeleteConfirmationDialog
            open={openDeleteDepDialog}
            dispatch={deleteDepDispatch}
            title="profil.designer.popup.delete.error.body"
          />
          <SaveConfirmationDialog open={openSaveDialog} dispatch={saveDispatch} />
        </Box>
      </Box>
    </MenuWrapper>
  );
};

export default observer(RoleSettings);
