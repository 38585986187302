import React from 'react';
import { Box } from '@mui/material';
import MenuWrapper from '../../shared/MenuWrapper';
import { BinDataTableMultipleActions } from './const';
import { useBinData } from './hooks';
import DataTable from '70.quickConnect.Components/siteAdmin/Other/DataTable';
import ContentHeader from '70.quickConnect.Components/siteAdmin/Other/ContentHeader';

const Bin = () => {
  const { t, binElements, columns, setSelectedMultipleAction } = useBinData();

  return (
    <MenuWrapper>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          marginX: '5%',
          marginY: '5%',
          justifyContent: 'space-between',
          gap: 3,
        }}
      >
        <ContentHeader>
          <ContentHeader.TitlesGroup>
            <ContentHeader.Title>{t('bin.viewer.title')}</ContentHeader.Title>
          </ContentHeader.TitlesGroup>
        </ContentHeader>
        <DataTable
          columns={columns}
          rows={binElements}
          getSearchValue={() => {}}
          multipleActions={Object.values(BinDataTableMultipleActions).map((action) => ({ text: t(action) }))}
          onSelectMultipleAction={setSelectedMultipleAction}
          isSearchBar={false}
        />
      </Box>
    </MenuWrapper>
  );
};

export default Bin;
