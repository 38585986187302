import API_HOST from './apiHost';

/** API ROUTE qui récupère la liste d'activités */

const API_GET_DECLARATIONS_LIST = `${API_HOST}declaration/getdeclarationslist`;

/** API ROUTE qui récupère la liste d'alertes */

const API_GET_INCIDENTS_LIST = `${API_HOST}dashboard/getdashboarddetail`;

/** API ROUTE qui récupère la liste d'imports exports tâches */
// const API_GET_IMPORTSEXPORTS_LIST =

export { API_GET_DECLARATIONS_LIST, API_GET_INCIDENTS_LIST };
