import React from 'react';
import { ENTITYSETTINGS, NEWENTITY } from '../paths';
import { LinkRouteType } from '../types';
import ComingSoon from './comingSoon';
import DESIGNERINTER_ROUTES from './designerInterRoutes';
import FamilySettings from '10.quickConnect.app/siteAdmin/components/domains/Ressources/FamilySettings';

const DESIGNER_ROUTES: LinkRouteType[] = [
  // a compléter quand les composants seront créés
  ...DESIGNERINTER_ROUTES,

  { path: NEWENTITY, key: 'NEWENTITY', element: <ComingSoon /> },
  { path: ENTITYSETTINGS, key: 'ENTITYSETTINGS', element: <FamilySettings /> },
];

export default DESIGNER_ROUTES;
