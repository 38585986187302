import { AxiosError } from 'axios';
import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { defautCompagnonList } from '../const';
import { GetCompagnonsRequest } from './requests';
import { GetCompagnonsResponse } from './responses';
import ApiCompagnon from '40.quickConnect.DataAcces/siteAdmin/apiRoutes/compagnons';
import QCHttpRequest from '40.quickConnect.DataAcces/siteAdmin/QCHttpRequest';
import { isResponseValid } from '40.quickConnect.DataAcces/siteAdmin/responseValidator';

const useCompagnonServices = () => {
  const getCompagnonList = useCallback(async (request: GetCompagnonsRequest): Promise<GetCompagnonsResponse> => {
    let ret = defautCompagnonList;
    const post = async () => {
      const RequestHandler = new QCHttpRequest();

      try {
        const response = await RequestHandler.post<GetCompagnonsResponse, GetCompagnonsRequest>(
          ApiCompagnon.getCompagnonList,
          request,
        );
        const isValid = isResponseValid(response.data);
        if (isValid) {
          ret = response.data;
        }
      } catch (error) {
        toast.error(`${(error as AxiosError<GetCompagnonsResponse, unknown>).response?.data?.message}`);
        ret = defautCompagnonList;
      }
    };
    await post();
    return ret;
  }, []);

  return { getCompagnonList };
};

export default useCompagnonServices;
