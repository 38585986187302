import React, { ReactNode } from 'react';
import { ListChildComponentProps } from 'react-window';
import { ListItem } from '@mui/material';
import SubMenuListItem from './SubMenuListItem';
import SubMenuListFolder from './SubMenuListFolder';
import { isSubMenuRowFolder, isSubMenuRowItem, SubMenuRow } from './types';

export const renderSubMenuRow = (row: SubMenuRow, isChecked?: boolean): ReactNode => {
  if (isSubMenuRowItem(row)) {
    if (row.type === 'family') {
      return (
        <SubMenuListItem
          key={row.id}
          isChecked={isChecked}
          name={row.name}
          id={row.id}
          startIcon={row.startIcon}
          childrenCount={row.childrenCount}
          type={row.type}
          childrenItems={row.childrenItems}
        ></SubMenuListItem>
      );
    } else
      return (
        <SubMenuListItem
          key={row.id}
          isChecked={isChecked}
          name={row.name}
          id={row.id}
          startIcon={row.startIcon}
          childrenCount={row.childrenCount}
          type={row.type}
        ></SubMenuListItem>
      );
  } else if (isSubMenuRowFolder(row)) {
    return (
      <SubMenuListFolder
        key={row.id}
        id={row.id}
        name={row.name}
        childrenCount={row.childrenCount}
        childrenItems={row.childrenItems}
      ></SubMenuListFolder>
    );
  }
};

export function renderRow(props: ListChildComponentProps, row: SubMenuRow, isChecked?: boolean) {
  const { index, style } = props;

  return (
    <ListItem style={style} key={index} component="div" disablePadding>
      {renderSubMenuRow(row, isChecked)}
    </ListItem>
  );
}
