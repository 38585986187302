import { UseDataProps } from './types';
import useIsMobile from '80.quickConnect.core/shared/hooks/useIsMobile';

export const useData = (): UseDataProps => {
  /**
   * Vérifier si l'appareil est un mobile selon la largeur
   * @constant
   * @type {boolean}
   */
  const isMobile = useIsMobile();
  return {
    isMobile,
  };
};
