import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { SubMenuRowItem } from '../../shared/SubMenu/types';
import useClientsServices from './services/clients';
import { SubMenuRowActions } from './types';
import { GetClientsResponse } from './services/responses';
import { CreateClientRequest } from './services/requests';
import useClientSettingsServices from './services/clientSettings';
import { findNamesByIds } from './helpers';
import { useStore } from '30.quickConnect.Store/siteAdmin';
import { clientsParser } from '80.quickConnect.core/siteAdmin/helpers/clientsParser';
import useEffectOnce from '80.quickConnect.core/shared/hooks/useEffectOnce';
import { DeleteActionType } from '70.quickConnect.Components/siteAdmin/Other/DeleteConfirmationDialog/type';
import { useDeleteDialog } from '80.quickConnect.core/siteAdmin/hooks/useDeleteDialog';
import { AllDataElementType } from '70.quickConnect.Components/siteAdmin/Other/QCDialogForm/types';
import { useSafeNavigation } from '80.quickConnect.core/siteAdmin/hooks/useSafeNavigation';
import { CLIENT } from '10.quickConnect.app/siteAdmin/routes/paths';
import { getReplacedPath } from '80.quickConnect.core/shared/helpers/getReplacedPath';

const useClientsData = () => {
  const params = useParams();
  const { t } = useTranslation();
  const navigate = useSafeNavigation();
  const [openDeleteDialog, setOpenDeleteDialog, deleteState, deleteDispatch] = useDeleteDialog();
  const {
    SubMenuStore: {
      selectedRowAction,
      setSelectedRowAction,
      selectedRowsMultipleAction,
      setSelectedRowsMultipleAction,
      setRows,
      setSubMenuPath,
      setRouteBase,
      setRowActions,
      setAddIcon,
      setAddText,
      setHandleAddClick,
      setSortingTypes,
      setMultipleActions,
    },
  } = useStore();
  const { getClientsRequest } = useClientsServices();
  const { deleteClientRequest, createClientRequest } = useClientSettingsServices();
  const [clients, setClients] = useState<SubMenuRowItem[]>([]);
  const [isAddClientDialogOpen, setIsAddClientDialogOpen] = useState<boolean>(false);

  const initClientsList = useCallback(async () => {
    const res = (await getClientsRequest()) as GetClientsResponse;
    const rows = clientsParser(res.customers);
    setClients(rows);
    setRows(rows);
    setRouteBase('/clients');
    setSubMenuPath([
      {
        id: 'root',
        name: t('customer.customers.title'),
        rows: rows,
      },
    ]);
    setRowActions(Object.values(SubMenuRowActions));
  }, [t, getClientsRequest, setRows, setSubMenuPath, setRowActions, setRouteBase, setClients]);

  useEffectOnce(() => {
    initClientsList();
    setAddIcon(<ManageAccountsIcon />);
    setHandleAddClick(() => setIsAddClientDialogOpen(true));
    setSortingTypes([
      t('submenu.sortingType.alphabetic'),
      t('submenu.sortingType.type'),
      t('submenu.sortingType.favourite'),
    ]);
    setAddText(t('customer.customerDesigner.addClient'));
    setMultipleActions([{ text: t('submenu.multipleAction.delete') }, { text: t('submenu.multipleAction.print') }]);
  });

  useEffect(() => {
    switch (selectedRowAction?.option) {
      case t(SubMenuRowActions.Delete):
        setOpenDeleteDialog(true);
        break;
      case t(SubMenuRowActions.Settings):
        // navigate(`/clients/${selectedRowAction.rowId}`);
        navigate(getReplacedPath(CLIENT, [selectedRowAction.rowId]));
        setSelectedRowAction(undefined);
        break;
      default:
        break;
    }
    // eslint-disable-next-line
  }, [selectedRowAction]);

  useEffect(() => {
    switch (selectedRowsMultipleAction?.option) {
      case t(SubMenuRowActions.Delete):
        setOpenDeleteDialog(true);
        break;
      default:
        break;
    }
    //eslint-disable-next-line
  }, [selectedRowsMultipleAction]);

  const handleDelete = useCallback(async () => {
    setOpenDeleteDialog(false);
    if (deleteState.choice === DeleteActionType.Delete) {
      if (selectedRowAction) {
        const rowName = findNamesByIds(clients, selectedRowAction.rowId);
        if (rowName) {
          const res = await deleteClientRequest(rowName[0]);
          if (res === true && params.clientId === undefined) initClientsList();
          if (params.clientId !== undefined) navigate('/clients');
        }
        setSelectedRowAction(undefined);
      } else if (selectedRowsMultipleAction) {
        const rowsNames = findNamesByIds(clients, selectedRowsMultipleAction.rowsId);
        if (rowsNames) {
          let res: boolean | undefined;
          for (let i = 0; i < rowsNames.length; i++) {
            res = await deleteClientRequest(rowsNames[i]);
          }
          if (res === true && params.clientId === undefined) initClientsList();
          if (params.clientId !== undefined) navigate('/clients');
        }
        setSelectedRowsMultipleAction(undefined);
      }
    }
    // eslint-disable-next-line
  }, [deleteState, params]);

  useEffect(() => {
    handleDelete();
  }, [handleDelete]);

  const handleConfirmAddClient = useCallback(
    async (allData: AllDataElementType[]) => {
      const request = {} as CreateClientRequest;
      allData.forEach((stepData) => {
        stepData.data.forEach((el) => {
          switch (el.label) {
            case t('customer.customerDesigner.name'):
              if (typeof el.value === 'string') request.name = el.value;
              break;
            case t('customer.customerDesigner.pwd'):
              if (typeof el.value === 'string') request.adminPWD = el.value;
              break;
          }
        });
      });
      const response = await createClientRequest(request);
      if (response !== undefined) {
        await initClientsList();
        return true;
      }
      return false;
    },
    [t, createClientRequest, initClientsList],
  );

  return {
    openDeleteDialog,
    deleteDispatch,
    setOpenDeleteDialog,
    deleteState,
    isAddClientDialogOpen,
    setIsAddClientDialogOpen,
    handleConfirmAddClient,
    clients,
    selectedRowsMultipleAction,
    selectedRowAction,
  };
};

export default useClientsData;
