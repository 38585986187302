import React, { useEffect, useState } from 'react';

import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Button, useTheme } from '@mui/material';

import { QCChecboxButtonSxPropsType, QCChecboxButtonType } from './types';
import { green4 } from '00.app/theme/colors';

const QCChecboxButtonField = ({ label, dispatch, isCheck }: QCChecboxButtonType) => {
  // const [isCheck, setIsCheck] = useState<boolean>(false);

  const theme = useTheme();

  const style = useTheme().components?.QCCheckBoxButton;
  const variantStyleChecked = style?.variants.checked;
  const variantStyleNotChecked = style?.variants.notChecked;

  // console.log(variantStyleChecked);

  const [sx, setSx] = useState(Object.assign({}, style?.default, variantStyleChecked) as QCChecboxButtonSxPropsType);

  const handleClick = () => {
    dispatch(!isCheck);
  };

  useEffect(() => {
    // dispatch(isCheck);
    setSx(
      Object.assign(
        {},
        style?.default,
        isCheck ? variantStyleChecked : variantStyleNotChecked,
      ) as QCChecboxButtonSxPropsType,
    );
  }, [isCheck, dispatch, style?.default, variantStyleChecked, variantStyleNotChecked]);

  return (
    <Button
      sx={sx}
      variant="outlined"
      onClick={handleClick}
      startIcon={
        isCheck ? (
          <CheckBoxIcon sx={{ color: theme.palette.mode === 'light' ? green4 : 'inherit' }} />
        ) : (
          <CheckBoxOutlineBlankIcon color="inherit" />
        )
      }
    >
      {label}
    </Button>
  );
};

export default QCChecboxButtonField;
