import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { FormProps } from './types';
import { OrganizationToTreeViewNodes } from './hooks';
import QCDialogForm from '70.quickConnect.Components/siteAdmin/Other/QCDialogForm';
import { useStore } from '30.quickConnect.Store/siteAdmin';
import useControls from '90.quickConnect.Models/siteAdmin/inputValidator/controls';

const AddOUDialog = ({ open, handleConfirm, toggleIsOpen }: FormProps) => {
  const { t } = useTranslation();
  const {
    OrganizationalUnitStore: { allOUList },
  } = useStore();
  return (
    <QCDialogForm open={open} handleConfirm={handleConfirm} toggleIsOpen={toggleIsOpen} columns={[1, 1, 1]}>
      <QCDialogForm.Body
        displayStepping={true}
        nbColumn={1}
        index={1}
        desc={t('organization.organizations.actions.add')}
      >
        <QCDialogForm.Text label={t('organization.designer.placeholder.name')} mandatory />
        <QCDialogForm.Text label={t('organization.designer.attribut.placeholder.siret')} mandatory />
        <QCDialogForm.Text
          label={t('organization.designer.attribut.placeholder.email')}
          control={useControls().email}
        />
        <QCDialogForm.TreeViewChoice
          label={t('organization.designer.placeholder.organization')}
          mandatory
          allData={OrganizationToTreeViewNodes(allOUList)}
        />
      </QCDialogForm.Body>
      <QCDialogForm.Body
        displayStepping={true}
        nbColumn={1}
        index={2}
        desc={t('organization.organizations.actions.add')}
      >
        <QCDialogForm.Text label={t('organization.designer.attribut.placeholder.address')} mandatory />
        <QCDialogForm.Text label={t('organization.designer.attribut.placeholder.zipCode')} mandatory />
        <QCDialogForm.Text label={t('organization.designer.attribut.placeholder.town')} mandatory />
        <QCDialogForm.Text label={t('organization.designer.attribut.placeholder.country')} />
      </QCDialogForm.Body>
      <QCDialogForm.Body
        displayStepping={true}
        nbColumn={1}
        index={3}
        desc={t('organization.organizations.actions.add')}
      >
        <QCDialogForm.Photo label={t('organization.designer.logo.title')} />
      </QCDialogForm.Body>
    </QCDialogForm>
  );
};

export default observer(AddOUDialog);
