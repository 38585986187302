import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';
import { subMenuWidthNumber } from './const';

export const SubMenuContainerStyle = (theme: Theme, x: number): SxProps => ({
  position: 'relative',
  display: 'flex',
  flexDirection: 'column',
  paddingY: '2vh',
  height: '100vh',
  width: `${x}px`,
  minWidth: `${subMenuWidthNumber / 2}px`,
  borderRight: 'solid 2px ' + theme.palette.menuBorder.main,
  marginLeft: '-1.5vw',
});

export const PathStyle: SxProps = {
  fontSize: '1.2em',
  lineHeight: '105%',
  paddingX: '2vw',
  paddingY: '3vh',
};

export const ToolsContainerStyle = (theme: Theme, x: number): SxProps => ({
  width: `${x}px`,
  minWidth: `${subMenuWidthNumber / 2}px`,
  height: 'fit-content',
  paddingX: '1vw',
  boxSizing: 'border-box',
  border: 'solid 2px',
  borderColor: theme.palette.menuBorder.main,
  backgroundColor: theme.palette.toolsBackground.main,
});

export const ListContainerStyle: SxProps = {
  // paddingX: '1vw',
  // overflowY: 'scroll',
  // marginX: '7px',
  marginTop: '3px',
  paddingBottom: '30px',
  overflow: 'hidden',
  scrollbarWidth: 'thin',
};

export const AddButtonContainer: SxProps = {
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  zIndex: 5,
  right: 25,
  bottom: 20,
  height: 50,
  backgroundColor: 'rgba(0,0,0,0)',
};
