import React from 'react';
import { SubMenuRowItem } from '10.quickConnect.app/siteAdmin/components/shared/SubMenu/types';
import { Customer } from '90.quickConnect.Models/siteAdmin/models/user/customer';

export const clientsParser = (clients: Customer[]) => {
  const items: SubMenuRowItem[] = [];
  clients.map((client) =>
    items.push({
      type: 'item',
      id: client.id,
      name: client.name,
      childrenCount: 0,
      startIcon: <></>,
    }),
  );
  return items;
};
