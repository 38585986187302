import { useTheme } from '@mui/material';

export const useAddFileBoxSxprops = () => {
  const theme = useTheme();
  return {
    display: 'flex',
    border: `2px dashed ${
      theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.iconOutlinedHoverColor.main
    }`,
    borderRadius: '8px',
    backgroundColor: theme.palette.mode === 'light' ? theme.palette.grey[100] : theme.palette.grey[700],
    color: theme.palette.mode === 'light' ? theme.palette.primary.main : theme.palette.iconOutlinedHoverColor.main,
    fontWeight: 'bold',
    height: '100%',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'center',
  };
};

export const useListFilesBoxSxProps = () => {
  const theme = useTheme();
  return {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    borderRadius: '8px',
    border: '2px solid' + theme.palette.inputListFileColors.main,
    backgroundColor: theme.palette.inputListFileColors.light,
    color: theme.palette.textLink.main,
    fontWeight: 'bold',
    height: '100%',
    width: '100%',
  };
};

export const useFabSxProps = () => {
  const theme = useTheme();
  return {
    backgroundColor: theme.palette.backGroundGroup.main,
  };
};
