import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Box, Button, Collapse, IconButton, ListItemButton, useTheme } from '@mui/material';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from 'react-i18next';

import { useData } from '../hooks';
import { buttonStyles } from '../styles';
import { CollapseOpenType, QCMenuCollapseProps } from './types';
import { getRoleValue } from '80.quickConnect.core/siteAdmin/helpers/getRoleValue';
import { Features } from '90.quickConnect.Models/siteAdmin/enums/features/features';
import { useSafeNavigation } from '80.quickConnect.core/siteAdmin/hooks/useSafeNavigation';

const QCMenuCollapse = ({ text, icon, routes, isOpenSideMenu }: QCMenuCollapseProps) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { setIsOpenSideMenu, focusedBtn, setFocusedBtn, openedCollapse, setOpenedCollapse, role, subscribedFeatures } =
    useData();
  const navigate = useSafeNavigation();

  const [isOpenCollapse, setIsOpenCollapse] = useState<boolean>(false);

  /**
   * Vérifier si le bouton est concentré
   * @returns {boolean} Retourner true si le bouton est concentré, retourner false si non.
   */
  const isFocusedOnFold = (): boolean => {
    if (focusedBtn === text || routes.map(({ name }) => name).includes(focusedBtn)) {
      return true;
    }
    return false;
  };

  /** Une fonction qui permet d'ouvrir le menu et bouton collapse */
  const openSideMenuAndCollapse = () => {
    setIsOpenSideMenu(true);
    setIsOpenCollapse(true);
    setOpenedCollapse(text as CollapseOpenType);
  };

  /** Une fonction qui permet d'ouvrir et fermer le bouton collapse
   * et identifier le nom du bouton collapse actuellement ouvert
   */
  const toggleCollapse = () => {
    if (isOpenCollapse === true) {
      setOpenedCollapse(null);
    } else setOpenedCollapse(text as CollapseOpenType);

    setIsOpenCollapse((currentIsOpen) => !currentIsOpen);
  };
  /** Une fonction qui permet de laisser un seul bouton collapse ouvert. */
  useEffect(() => {
    if (openedCollapse != text) {
      setIsOpenCollapse(false);
    }
  }, [openedCollapse, text]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: isOpenSideMenu ? 'stretch' : 'center',
      }}
    >
      {isOpenSideMenu ? (
        <Button
          data-cy={text}
          sx={isFocusedOnFold() && !isOpenCollapse ? buttonStyles(theme, true, true) : buttonStyles(theme, false, true)}
          startIcon={icon}
          onClick={toggleCollapse}
        >
          {t(`navigation.menu.${text}`)}
          {isOpenCollapse ? <ExpandLess /> : <ExpandMore />}
        </Button>
      ) : (
        <IconButton
          data-cy={text}
          sx={isFocusedOnFold() ? buttonStyles(theme, true, false) : buttonStyles(theme, false, false)}
          onClick={openSideMenuAndCollapse}
        >
          {icon}
        </IconButton>
      )}
      {isOpenSideMenu && (
        <Collapse
          in={isOpenSideMenu && isOpenCollapse && text == openedCollapse}
          sx={{ borderLeft: '1px solid white', mx: '8%' }}
        >
          {routes &&
            routes.map(({ name, path, authorizationRole }) => {
              if (
                getRoleValue(role) >= getRoleValue(authorizationRole) &&
                (name !== 'catalogs' || subscribedFeatures[Features.Catalogs])
              )
                return (
                  <ListItemButton
                    data-cy={name}
                    onClick={() => {
                      setFocusedBtn(name);
                      navigate(path);
                    }}
                    key={uuidv4()}
                    sx={name === focusedBtn ? buttonStyles(theme, true, true) : buttonStyles(theme, false, true)}
                  >
                    {t(`navigation.menu.${name}`).toLocaleUpperCase()}
                  </ListItemButton>
                );
            })}
        </Collapse>
      )}
    </Box>
  );
};

export default observer(QCMenuCollapse);
