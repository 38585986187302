import React, { useMemo } from 'react';
import { GridColDef, GridColumns, GridSortModel, GridValidRowModel } from '@mui/x-data-grid';
import { useCallback, useEffect, useState } from 'react';
import { defaultGetHistoryListResponse } from './const';
import useServices from './services';
import { GetHistoryListResponse } from './types';
import LastSyncCell from './RenderCells/lastSync';
import CurrentAppVersionDateCell from './RenderCells/currentVersionDate';
import CurrentVersionCell from './RenderCells/currentVersion';
import useEffectOnce from '80.quickConnect.core/shared/hooks/useEffectOnce';
import ExpandButtonCell from '70.quickConnect.Components/siteAdmin/Other/DataTable/ExpandButton';
import { toReadableDate } from '80.quickConnect.core/siteAdmin/helpers/toReadableDate';

const useData = () => {
  const { getHistoryList } = useServices();

  const [historyListMobile, setHistoryListMobile] = useState<GetHistoryListResponse>(defaultGetHistoryListResponse);

  const [rowCount, setRowcount] = useState(historyListMobile.totalCount);

  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState(7);

  // const resizeObj = new ResizeObserver((element) => {
  //   setPageSize((prev) => {
  //     const newValue = Math.floor(element[0].target.clientHeight / 70 - 1);
  //     if (prev === newValue - 1) return prev;
  //     return newValue;
  //   });
  // });
  // try {
  //   // eslint-disable-next-line
  //   resizeObj.observe(document.getElementsByClassName('MuiDataGrid-main').item(0)!);
  //   // eslint-disable-next-line
  // } catch {}

  const setHistoryList = useCallback(
    async (length: number, start: number, instruction: { column: string; direction: number }[], filter: string) => {
      setIsLoading(true);

      const result = await getHistoryList(length, start > -1 ? start : 0, instruction, filter);

      setRowcount(result.totalCount);

      setHistoryListMobile(result);
      setIsLoading(false);
    },
    [setHistoryListMobile, getHistoryList],
  );

  const [expanded, setExpanded] = useState(false);

  const expandCell: GridColDef = {
    field: 'expand',
    renderCell: (params) => <ExpandButtonCell params={params} dispatch={setExpanded} state={expanded} />,
    renderHeader: () => null,
    sortable: false,
    flex: 0.5,
  };

  // Regrouper par UMEI/UID
  const initializeCols = useCallback(() => {
    const initCols: GridColumns = [
      { field: 'userUPN', headerName: 'UserUPN', flex: 2 },
      { field: 'imei', headerName: 'IMEI/UID', flex: 2 },
      {
        field: 'lastSync',
        headerName: 'Dernière Syncronisation',
        flex: 1.5,
        renderCell: (params) => <LastSyncCell params={params} />,
      },
      {
        field: 'currentVersion',
        headerName: 'Version courante',
        flex: 1,
        renderCell: (params) => <CurrentVersionCell params={params} />,
      },
      {
        field: 'currentVersionDate',
        headerName: 'Installation Version courante',
        flex: 1.5,
        renderCell: (params) => <CurrentAppVersionDateCell params={params} />,
      },
      { field: 'typeDevice', headerName: "Type d'appareil", flex: 1.5 },
    ];
    return initCols;
  }, []);

  const [rows, setRows] = useState<GridValidRowModel[]>([]);

  useEffect(() => {
    const initRows: GridValidRowModel[] = [];
    historyListMobile.mobiles.forEach((mobile) => {
      initRows.push({
        id: mobile.id,
        userUPN: mobile.userUPN,
        imei: mobile.imei,
        lastSync: { lastSync: toReadableDate(mobile.lastSyncAt), firstSync: toReadableDate(mobile.firstSyncAt) },
        currentVersion: {
          currentAppVersionCode: mobile.currentAppVersionCode,
          previousAppVersionCode: mobile.previousAppVersionCode,
        },
        currentVersionDate: {
          currentVersionDate: toReadableDate(mobile.currentAppVersionDate),
          previousVersionDate: toReadableDate(mobile.previousAppVersionDate),
        },
        typeDevice: mobile.typeDevice,
      });
    });
    setRows(initRows);
  }, [historyListMobile]);

  const [cols, setCols] = useState<GridColumns>(initializeCols());
  const [actualPage, setActualPage] = useState(0);

  const [actualInstructions, setActualInstructions] = useState([
    {
      column: 'lastSyncAt',
      direction: 0,
    },
  ]);

  const [actualFilter, setActualFilter] = useState('');

  const [searchText, setSearchText] = useState('');
  useMemo(() => {
    if (searchText.length > 2) {
      setActualFilter(searchText);
      setActualPage(0);
      setHistoryList(pageSize, 0, actualInstructions, searchText);
    }
    // eslint-disable-next-line
  }, [searchText, actualInstructions, setHistoryList]);

  useEffectOnce(() => {
    setHistoryList(pageSize, 0, actualInstructions, actualFilter);
  });

  const [rowCountState, setRowCountState] = useState(rowCount);
  useEffect(() => {
    setRowCountState((prevRowCountState) => (rowCount !== undefined ? rowCount : prevRowCountState));
  }, [rowCount, setRowCountState]);

  const dataTableCallBack = useCallback(
    (page: number) => {
      setHistoryList(pageSize, page * pageSize - pageSize, actualInstructions, actualFilter);
      setActualPage(page);
    },
    // eslint-disable-next-line
    [actualInstructions, setHistoryList, actualFilter],
  );

  const getColumnName = (fieldName: string) => {
    switch (fieldName) {
      case 'lastSync':
        return 'lastSyncAt';
      case 'currentVersion':
        return 'currentAppVersionCode';
      case 'currentVersionDate':
        return 'currentAppVersionDate';
      default:
        return fieldName;
    }
  };

  const sortingHandler = useCallback(
    (model: GridSortModel) => {
      const instruction =
        model[0] !== undefined
          ? [
              {
                column: getColumnName(model[0].field),
                direction: model[0].sort === 'asc' ? 0 : 1,
              },
            ]
          : [
              {
                column: 'lastSyncAt',
                direction: 0,
              },
            ];
      setActualInstructions(instruction);
      setActualPage(0);

      setHistoryList(pageSize, 0, instruction, actualFilter);
    },
    // eslint-disable-next-line
    [setHistoryList, actualFilter],
  );

  const requestHandler = {
    paginationMode: 'server',
    rowCount: rowCountState,
    onPageChange: dataTableCallBack,
  };

  const sortingProps = {
    sortingMode: 'server',
    onSortModelChange: sortingHandler,
    page: actualPage,
  };

  return {
    historyListMobile,
    setHistoryList,
    // ExpandCell,
    rows,
    cols,
    setSearchText,
    setCols,
    searchText,
    expandCell,
    expanded,
    setExpanded,
    requestHandler,
    sortingProps,
    isLoading,
    pageSize,
    setPageSize,
  };
};

export default useData;
