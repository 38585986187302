import React from 'react';
import { Box, Card, Stack } from '@mui/material';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
import MenuWrapper from '../../shared/MenuWrapper';
import { useData } from './hooks';
import { ActionButtonsContainer, EditContainerStyle, InputsCardStyle, InputsGridContainerStyle } from './styles';
import ContentHeader from '70.quickConnect.Components/siteAdmin/Other/ContentHeader';
import { OrganizationIcon } from '00.app/icons/OrganizationIcon';
import QCTextInput from '70.quickConnect.Components/siteAdmin/Inputs/QCTextInput';
import QCFabCorbeille from '70.quickConnect.Components/siteAdmin/Buttons/QCFabCorbeille';
import QCIconButton from '70.quickConnect.Components/siteAdmin/Buttons/QCIconButton';
import SaveConfirmationDialog from '70.quickConnect.Components/siteAdmin/Other/SaveConfirmationDialog';
import DeleteConfirmationDialog from '70.quickConnect.Components/siteAdmin/Other/DeleteConfirmationDialog';

const OrganizationSettings = () => {
  const {
    t,
    theme,
    pageOrganization,
    newPageOrganization,
    setNewPageOrganization,
    openSaveDialog,
    openDeleteDialog,
    handleSave,
    handleDelete,
    saveDispatch,
    deleteDispatch,
    control,
  } = useData();

  return (
    <MenuWrapper>
      <SaveConfirmationDialog dispatch={saveDispatch} open={openSaveDialog} />
      <DeleteConfirmationDialog dispatch={deleteDispatch} open={openDeleteDialog} />
      <Box marginLeft={{ xs: '2vw', lg: '10vw' }}>
        <ContentHeader>
          <ContentHeader.BackButton />
          <ContentHeader.TitlesGroup>
            <ContentHeader.OverTitle>{t('organization.settings.overtitle')}</ContentHeader.OverTitle>
            <ContentHeader.Title startIcon={<OrganizationIcon />}>{pageOrganization?.name}</ContentHeader.Title>
          </ContentHeader.TitlesGroup>
        </ContentHeader>
      </Box>

      <Box sx={EditContainerStyle}>
        <Stack direction="row" gap="0px 16px" flexWrap="wrap">
          <Box width="300px">
            <QCTextInput
              editable
              value={newPageOrganization ? newPageOrganization.name : ''}
              label={t('organization.designer.placeholder.name')}
              dispatch={(value: string) => {
                setNewPageOrganization((currentOrga) => {
                  if (currentOrga) {
                    const newOrga = { ...currentOrga };
                    newOrga.name = value;
                    return newOrga;
                  }
                });
              }}
            ></QCTextInput>
          </Box>
          <Box width="300px">
            <QCTextInput
              editable
              value={newPageOrganization ? newPageOrganization.description : ''}
              label={t('organization.designer.placeholder.description')}
              dispatch={(value: string) => {
                setNewPageOrganization((currentOrga) => {
                  if (currentOrga) {
                    const newOrga = { ...currentOrga };
                    newOrga.description = value;
                    return newOrga;
                  }
                });
              }}
            ></QCTextInput>
          </Box>
        </Stack>
        <Card sx={InputsCardStyle(theme)}>
          <h2>{t('organization.designer.attribut.title')}</h2>

          <Box sx={InputsGridContainerStyle}>
            {['address', 'zipCode', 'town', 'country', 'siret', 'email'].map((el) => {
              // tranformer ça en enum
              if (
                el === 'address' ||
                el === 'zipCode' ||
                el === 'town' ||
                el === 'country' ||
                el === 'siret' ||
                el === 'email'
              ) {
                return (
                  <Box key={el} sx={{ maxWidth: '300px' }}>
                    <QCTextInput
                      editable
                      className={`edit-orga-${el}-field`}
                      value={newPageOrganization ? newPageOrganization[el] : ''}
                      label={t(`organization.designer.attribut.placeholder.${el}`)}
                      dispatch={(value: string) => {
                        setNewPageOrganization((currentOrga) => {
                          if (currentOrga) {
                            const newOrga = { ...currentOrga };
                            newOrga[el] = value;
                            return newOrga;
                          }
                        });
                      }}
                      info={el === 'email' ? control.email.instructions : undefined}
                      control={el === 'email' ? control.email : undefined}
                    ></QCTextInput>
                  </Box>
                );
              }
            })}
          </Box>
        </Card>

        <Box sx={ActionButtonsContainer}>
          <QCFabCorbeille onClickFunc={handleDelete}></QCFabCorbeille>
          <QCIconButton Icon={<SaveOutlinedIcon />} onClickFunc={handleSave} variantValue="contained" />
        </Box>
      </Box>
    </MenuWrapper>
  );
};

export default OrganizationSettings;
