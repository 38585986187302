import { SxProps, Theme } from '@mui/material';

export const AttributeFormStyle = (theme: Theme, hasFocus: boolean): SxProps => ({
  position: 'relative',
  border: hasFocus ? `solid 2px ${theme.palette.menuBorder.main}` : 'none',
  width: '100%',
  maxHeight: 255,
  // zIndex: 2,
  display: 'grid',
  gridTemplateColumns: `repeat(2, 45%)`,
  gridTemplateRows: `repeat(2, 1fr)`,
  gap: '10px 25px',
  bgcolor: theme.palette.attributeBackground.main,
  borderRadius: '9px',
  padding: '20px',
});
