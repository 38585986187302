import {
  Box,
  Card,
  Collapse,
  IconButton,
  InputAdornment,
  ListItem,
  ListItemText,
  OutlinedInput,
  TextField,
  Typography,
} from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import PersonRemoveAlt1Icon from '@mui/icons-material/PersonRemoveAlt1';
import QCInputHeader from '../QCInputHeader';
import useData from './hooks';
import { NotificationInputProps } from './types';
import { cardStyle, boxInputStyle, typographyStyles } from './styles';
import { defaultNotification } from './const';

const NotificationInput = ({ label, info, notifications = defaultNotification, dispatch }: NotificationInputProps) => {
  const { t } = useTranslation();
  const {
    showCollapse,
    handleClickShowCollapse,
    currentEmail,
    handleChangeInputEmail,
    handleChangeInputAlias,
    emails,
    addEmail,
    deleteEmail,
    useSx,
    isValidEmail,
  } = useData(Object.keys(notifications).length > 0 ? notifications : defaultNotification, dispatch);

  return (
    <Box>
      {label && <QCInputHeader label={label} info={info} />}
      <OutlinedInput
        fullWidth
        contentEditable={false}
        type="text"
        endAdornment={
          <InputAdornment position="end">
            <IconButton onClick={handleClickShowCollapse} edge="end">
              {showCollapse ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </InputAdornment>
        }
        value={emails.map((el) => {
          if (el.alias) return `${el.target} (${el.alias})`;
          return el.target;
        })}
      />
      <Collapse in={showCollapse} timeout="auto" unmountOnExit>
        <Box sx={boxInputStyle}>
          <TextField
            type="email"
            label={t('email.dialog.placeholder.email')}
            value={currentEmail.target ?? ''}
            onChange={handleChangeInputEmail}
            variant="standard"
            sx={useSx(currentEmail.target ?? '')}
          />
          <TextField
            label={t('email.dialog.placeholder.alias')}
            value={currentEmail.alias ?? ''}
            onChange={handleChangeInputAlias}
            variant="standard"
          />
          <IconButton
            onClick={addEmail}
            disabled={currentEmail.target === undefined || currentEmail.target === '' || !isValidEmail}
          >
            <PersonAddAlt1Icon />
          </IconButton>
        </Box>
        <Card sx={cardStyle}>
          <Typography gutterBottom sx={typographyStyles}>
            {t('email.dialog.card')}
          </Typography>
          {emails &&
            emails.map((email) => {
              return (
                <ListItem key={email.target}>
                  <ListItemText
                    primary={
                      email.alias === undefined || email.alias === ''
                        ? email.target
                        : `${email.target} (${email.alias})`
                    }
                  />
                  <IconButton onClick={() => deleteEmail(email.target, email.alias)}>
                    <PersonRemoveAlt1Icon />
                  </IconButton>
                </ListItem>
              );
            })}
        </Card>
      </Collapse>
    </Box>
  );
};

export default NotificationInput;
