import { BaseListRequest } from '40.quickConnect.DataAcces/siteAdmin/services/baseRequests';

export type GetVersionsRequest = BaseListRequest;

export type GetCompagnonsRequest = BaseListRequest;

export type CreateVersionRequest = {
  name: string;
  description: string;
  paramValue: string;
  companionQCId: string;
  isGlobalParam: boolean;
};

export type DeleteVersionRequest = {
  ids: string[];
  sendToRecycleBin: boolean;
};

export type GetVersionByIdRequest = {
  id: string;
};

export type UpdateVersionRequest = {
  name: string;
  id: string;
  eTag: string;
  description: string;
  paramValue: string;
  companionQCId: string;
};

export const defaultGetVersionsRequest: GetVersionsRequest = {
  count: 1,
  filters: [],
  length: 100,
  orderBy: null,
  start: 0,
  textFilter: null,
  columns: [],
  entityType: 0,
  lightSelect: false,
};

export const defaultGetCompagnonsRequest: GetCompagnonsRequest = {
  count: 1,
  filters: [],
  length: 1000,
  orderBy: null,
  start: 0,
  textFilter: null,
  columns: [],
  entityType: 0,
  lightSelect: false,
};
