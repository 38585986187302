import React from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';
import { ReactComponent as Arrow } from './Arrow.svg';
import useIsMobile from '80.quickConnect.core/shared/hooks/useIsMobile';

export const ArrowIcon = (props: SvgIconProps) => {
  const isMobile = useIsMobile();

  return (
    <>
      {isMobile ? (
        <></>
      ) : (
        <SvgIcon
          {...props}
          sx={{
            ...props.sx,
            fontSize: 60,
          }}
          component={Arrow}
          inheritViewBox
        />
      )}
    </>
  );
};
