import React, { ReactNode, SetStateAction, useCallback } from 'react';
import { Box } from '@mui/system';
import { useTheme } from '@mui/material';
import { observer } from 'mobx-react';
import { useTranslation } from 'react-i18next';
import Menu from '../Menu';
import { CircleIcon } from '../../domains/MyAccount/icons/Cercle';
import SubMenu from '../SubMenu';
import Header from '../Header';
import useData from './hooks';
import useIsMobile from '80.quickConnect.core/shared/hooks/useIsMobile';
import SubMenuBreadcrumbs from '70.quickConnect.Components/siteAdmin/Other/SubMenuBreadcrumbs';

const MenuWrapper = ({ children }: { children: ReactNode | ReactNode[] }) => {
  const isMobile = useIsMobile();
  const theme = useTheme();
  const { t } = useTranslation();

  const {
    isOpenSideMenu,
    isVisible,
    rows,
    subMenuPath,
    rowActions,
    setSelectedRowAction,
    setSelectedRowsMultipleAction,
    sortingTypes,
    multipleActions,
    addIcon,
    handleAddClick,
    addText,
    getSearchValue,
  } = useData();

  const body = Array.isArray(children) ? children.map((child) => child) : children;

  const handleRowClickedChanged = useCallback(
    (value: SetStateAction<{ option: string; rowId: string }>) => {
      setSelectedRowAction(value as { option: string; rowId: string });
    },
    [setSelectedRowAction],
  );

  const handleMultipleActions = useCallback(
    (value: SetStateAction<{ option: string; rowsId: string[] }>) => {
      setSelectedRowsMultipleAction(value as { option: string; rowsId: string[] });
    },
    [setSelectedRowsMultipleAction],
  );

  if (!isMobile) {
    return (
      <Box display="flex" sx={{ flexDirection: 'column', flex: 1, marginBottom: '2vh' }}>
        <Menu />
        <Box
          maxHeight={'100vh'}
          sx={{
            display: 'flex',
            flexDirection: 'row',
            flexGrow: 1,
            transition: theme.transitions.create(['margin', 'width'], {
              easing: theme.transitions.easing.easeInOut,
              duration: theme.transitions.duration.standard,
            }),
            ...{
              [theme.breakpoints.up('lg')]: {
                ml: isOpenSideMenu ? 'calc(300px + 3vw)' : 'calc(80px + 3vw)',
              },
              [theme.breakpoints.between('md', 'lg')]: {
                ml: isOpenSideMenu ? 'calc(275px + 3vw)' : 'calc(64px + 3vw)',
              },
              [theme.breakpoints.down('md')]: {
                ml: isOpenSideMenu ? 'calc(250px + 3vw)' : 'calc(56px + 3vw)',
              },
            },
          }}
        >
          {isVisible ? (
            <SubMenu
              rows={rows}
              path={<SubMenuBreadcrumbs />}
              rowActions={rowActions.map((action) => ({ text: t(action) }))}
              getSelectedRowAction={handleRowClickedChanged}
              getSelectedMultipleAction={handleMultipleActions}
              sortingTypes={sortingTypes}
              multipleActions={multipleActions}
              addIcon={addIcon}
              addText={addText}
              handleAddClick={handleAddClick}
              getSearchValue={getSearchValue}
            />
          ) : (
            <></>
          )}
          <Box display="flex" flexDirection={'column'} flexGrow={1} sx={{ overflow: 'auto' }}>
            <Header />
            {body}
          </Box>
        </Box>
        {isVisible ? subMenuPath.length !== 1 ? <CircleIcon /> : <></> : <CircleIcon />}
      </Box>
    );
  } else {
    return (
      <Box maxHeight={'90vh'} sx={{ scrollbarWidth: 'thin' }}>
        {body}
        <Menu />
      </Box>
    );
  }
};

export default observer(MenuWrapper);
