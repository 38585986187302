import { Autocomplete, Box, TextField, useTheme } from '@mui/material';
import React, { SyntheticEvent } from 'react';
import QCInputHeader from '../QCInputHeader';
import { InputContainerStyle } from '../style';
import { QCAutocompleteProps } from './type';

const QCAutocomplete = ({
  label,
  dispatch,
  options,
  className,
  info,
  mandatory,
  datacy,
  value: defaultValue,
  autocompleteProps,
}: QCAutocompleteProps) => {
  const handleChange = (event: SyntheticEvent<Element, Event>, value: string | null) => {
    if (typeof value === 'string') {
      if (dispatch) {
        dispatch(value);
      }
    }
  };
  const theme = useTheme();
  return (
    <Box
      className={className}
      sx={{ ...InputContainerStyle(theme, mandatory), width: '100%', maxWidth: '100%', height: '100%' }}
    >
      {label && <QCInputHeader label={label} info={info} />}
      {/* utiliser notre QCTextField ici ? */}
      <Autocomplete
        defaultValue={options[0]}
        options={options}
        value={defaultValue}
        onChange={handleChange}
        renderInput={(params) => <TextField {...params} />}
        data-cy={datacy}
        {...autocompleteProps}
      />
    </Box>
  );
};

export default QCAutocomplete;
