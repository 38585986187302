import { AxiosError } from 'axios';
import { useCallback, useMemo } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import {
  CreateResourceRequest,
  DeleteResourceRequest,
  DesignResourceRequest,
  GetAllResourcesRequest,
  PrintResourceRequest,
  UpdateResourceRequest,
} from './requests';
import {
  CreateResourceResponse,
  DeleteResourceResponse,
  DesignResourceResponse,
  GetAllResourcesResponse,
  UpdateResourceResponse,
} from './response';
import ApiResource from '40.quickConnect.DataAcces/siteAdmin/apiRoutes/resources';
import QCHttpRequest from '40.quickConnect.DataAcces/siteAdmin/QCHttpRequest';

const defaultGetAllResourcesResponse: GetAllResourcesResponse = {
  count: 0,
  data: [],
  isThereMoreItems: false,
  message: null,
  researchToken: undefined,
  status: 0,
  totalCount: 0,
};

const useResourcesServices = () => {
  const { t } = useTranslation();
  const requestHandler = useMemo(() => new QCHttpRequest(), []);

  const getResources = useCallback(
    async (request: GetAllResourcesRequest): Promise<GetAllResourcesResponse> => {
      try {
        const response = await requestHandler.post<GetAllResourcesResponse, GetAllResourcesRequest>(
          ApiResource.getAllResources,
          request,
        );
        if (response.status === 200) return response.data;
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      }
      return defaultGetAllResourcesResponse;
    },
    [requestHandler],
  );

  const deleteResource = useCallback(
    async (request: DeleteResourceRequest, resourceName?: string) => {
      try {
        const response = await requestHandler.post<DeleteResourceResponse, DeleteResourceRequest>(
          ApiResource.deleteResource,
          request,
        );
        if (response.status === 200) {
          toast.success(t('entityData.editor.popup.delete.success', { name: resourceName }));
          return response.data;
        }
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      }
    },
    [t, requestHandler],
  );

  const getDesignResourceData = useCallback(
    async (request: DesignResourceRequest) => {
      try {
        const response = await requestHandler.post<DesignResourceResponse, DesignResourceRequest>(
          ApiResource.designResource,
          request,
        );
        if (response.status === 200) return response.data;
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      }
    },
    [requestHandler],
  );

  const createResource = useCallback(
    async (request: CreateResourceRequest) => {
      try {
        const response = await requestHandler.post<CreateResourceResponse, CreateResourceRequest>(
          ApiResource.createResource,
          request,
        );
        if (response.status === 200) {
          toast.success(t('entityData.editor.popup.create.success', { name: response.data.dto.title }));
          return response.data;
        }
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      }
    },
    [t, requestHandler],
  );

  const updateResource = useCallback(
    async (request: UpdateResourceRequest) => {
      try {
        const response = await requestHandler.post<UpdateResourceResponse, UpdateResourceRequest>(
          ApiResource.updateResource,
          request,
        );
        if (response.status === 200) {
          toast.success(t('entityData.editor.popup.update.success', { name: response.data.dto.title }));
          return response.data;
        }
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      }
    },
    [t, requestHandler],
  );

  const printResource = useCallback(
    async (request: PrintResourceRequest) => {
      try {
        // eslint-disable-next-line
        const response = await requestHandler.post<any, PrintResourceRequest>(ApiResource.printResource, request);
        if (response.status === 200) {
          return response.data;
        }
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      }
    },
    [requestHandler],
  );

  return {
    getResources,
    deleteResource,
    getDesignResourceData,
    createResource,
    updateResource,
    printResource,
  };
};

export default useResourcesServices;
