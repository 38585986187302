import React, { forwardRef } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Box, Button, Checkbox } from '@mui/material';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import QCInfoBubble from '../QCInfoBubble';
import CustomFooter from './CustomFooter';
import { QCDataTableName } from './const';
import { useData } from './hook';
import { DataTableProps } from './types';
import { DataTableContainerStyle, TitleAndRenderCompStyle, TitleStyle } from './style';
import QCChoiceMenu from '70.quickConnect.Components/siteAdmin/Inputs/QCChoiceMenu';
import SearchBar from '70.quickConnect.Components/siteAdmin/Inputs/SearchBar';

/**
 * @component DataTable
 * @param title - Titre de la DataTable
 * @param description - Description affichée dans une infobulle
 * @param columns - Définition des colonnes
 * @param rows - Données sous forme de lignes
 * @param kebabMenu - Pour changer la façon dont est render le menu des lignes, optionel il en existe déjà un par défaut
 * @param rowActions - Action affichées dans le kebab menu
 * @param multipleActions - Action affichées dans le menu actions multiples
 * @param renderAfterTitle - Composants optionnels qui seront rendu à droite du titre et l'infobubble
 * @param renderAfterSearchBar - Composants optionnels qui seront rendu à droite de la barre de recherche
 * @param renderSwitch - Composant rendu à droite en haut
 * @param onSelectRowAction - Dispatch de l'option choisie dans le menu ligne et de l'id de la ligne
 * @param onSelectMultipleAction - Dispatch de l'option choisie dans le menu des actions multiples et des ids des lignes sélectionnées
 * @param getSearchValue - Dispatch du champ de recherche
 * @param requestHandler - Objet contenant les toutes props necessaires à la gestion de la pagination par le serveur \{paginationMode: 'server'  , rowCount: rowCountState, number: (page: number) => void,\}
 * @param sortingProps - Objet contenant les toutes props necessaires à la gestion du tri par le serveur \{ sortingMode: 'server', onSortModelChange: (model: GridSortModel) => void,\}
 */

const DataTable = forwardRef(
  (
    {
      title,
      description,
      columns,
      rows,
      kebabMenu,
      rowActions,
      multipleActions,
      renderAfterTitle,
      renderAfterSearchBar,
      renderSwitch,
      onSelectRowAction,
      onSelectMultipleAction,
      getSearchValue,
      requestHandler,
      sortingProps,
      isLoading = false,
      isSearchBar = true,
      isReadOnly = false,
      pageSize = 5,
    }: DataTableProps,
    ref,
  ) => {
    const {
      t,
      theme,
      cols,
      selectedRowsId,
      handleCellClick,
      dataGridLocaleText,
      handleRowSelectionChange,
      handleActionCheckboxClick,
      handleMultipleActionClick,
      actionMenuAnchor,
      setActionMenuAnchor,
      handleMultipleActionSelectedOption,
    } = useData(columns, rows, kebabMenu, rowActions, multipleActions, onSelectRowAction, onSelectMultipleAction);
    return (
      <Box data-cy={QCDataTableName} sx={DataTableContainerStyle(theme)} component="div" ref={ref}>
        {/* DataTable : Titre, BulleInfo, Barre de recherche et composants optionnels */}
        <Box marginX="1vw" marginBottom="3vh">
          {(title || description || renderAfterTitle) && (
            <Box sx={TitleAndRenderCompStyle}>
              <Box sx={TitleStyle}>
                <h2>{title}</h2>
                {description && <QCInfoBubble text={description} fontSize="medium"></QCInfoBubble>}
              </Box>

              {renderAfterTitle}
            </Box>
          )}
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            {isSearchBar ? (
              <Box sx={{ flexGrow: 2 }}>
                <SearchBar dispatch={getSearchValue} />
              </Box>
            ) : (
              <></>
            )}
            {renderAfterSearchBar}
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>{renderSwitch}</Box>
        </Box>

        {/* Checkbox et bouton actions multiples */}
        {!isReadOnly ? (
          <Box component="span">
            <Checkbox
              checked={selectedRowsId.length === rows.length}
              indeterminate={selectedRowsId.length > 0 && selectedRowsId.length < rows.length}
              onClick={handleActionCheckboxClick}
            />
            <Button onClick={handleMultipleActionClick} sx={{ color: 'inherit', textTransform: 'inherit' }}>
              {t('datatable.multipleActions')}
              <ArrowForwardIosIcon
                fontSize="small"
                sx={{
                  margin: '5px',
                  fontSize: '12px',
                  color: theme.palette.primary.main,
                }}
              />
            </Button>
            <QCChoiceMenu
              anchorElState={[actionMenuAnchor, setActionMenuAnchor]}
              options={multipleActions ?? []}
              dispatch={(value: string) => {
                handleMultipleActionSelectedOption(value as string);
              }}
            />
          </Box>
        ) : (
          <></>
        )}

        <DataGrid
          disableSelectionOnClick={isReadOnly}
          columns={cols}
          rows={rows}
          pageSize={pageSize}
          rowsPerPageOptions={[pageSize]}
          checkboxSelection={!isReadOnly}
          autoHeight
          getRowHeight={() => 'auto'}
          disableColumnMenu
          loading={isLoading}
          onCellClick={handleCellClick}
          onSelectionModelChange={(selectedIds, details) => handleRowSelectionChange(selectedIds, details)}
          selectionModel={selectedRowsId}
          {...requestHandler}
          {...sortingProps}
          components={{ Footer: CustomFooter }}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            flex: 1,
            '&.MuiDataGrid-root .MuiDataGrid-cell': {
              wordWrap: 'break-word !important',
              whiteSpace: 'normal !important',
            },
            '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
              outline: 'none',
            },
            '&.MuiDataGrid-columnHeaderTitleContainerContent': {
              wordWrap: 'break-word !important',
              whiteSpace: 'normal !important',
              overflow: 'visible',
            },
            '&.MuiDataGrid-root .MuiDataGrid-columnHeader:focus-within': {
              outline: 'none',
            },
            '& .MuiDataGrid-columnSeparator': {
              display: 'none',
            },
            '&.MuiDataGrid-root': {
              border: 'none',
            },
            '&.MuiDataGrid-root .MuiDataGrid-columnHeaderCheckbox .MuiDataGrid-columnHeaderTitleContainer': {
              display: 'none',
            },
            '.MuiDataGrid-cell': {
              borderBottom: `solid 1px ${theme.palette.grey[300]}`,
              // marginBottom: '3px',
              padding: '1px',
            },
            '.MuiDataGrid-cellCheckbox': {
              border: 'none',
              marginRight: '10px',
              borderRight: `solid 1px ${theme.palette.grey[300]}`,
            },
            '.MuiDataGrid-columnHeaders': {
              borderBottom: 'none',
            },
            '.MuiDataGrid-columnHeader': {
              borderBottom: `solid 1px ${theme.palette.grey[300]}`,
            },
            '.MuiDataGrid-columnHeaderCheckbox': {
              marginRight: '10px',
              borderBottom: 'none',
            },
            '&.MuiDataGrid-virtualScrollerContent': {
              height: '500px',
            },
            '.MuiDataGrid-footerContainer': {
              borderTop: 'none',
            },
            '.MuiDataGrid-main': {
              maxHeight: '70vh',
              '.MuiDataGrid-virtualScrollerContent': {
                maxHeight: '60vh',
                '.MuiDataGrid-virtualScrollerRenderZone': {
                  maxHeight: 'inherit',
                  overflowY: 'scroll',
                  scrollbarWidth: 'thin',
                },
              },
            },
          }}
          localeText={dataGridLocaleText}
        />
      </Box>
    );
  },
);

DataTable.displayName = 'DataTable';

export default DataTable;
