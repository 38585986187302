import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';

export const ListItemContainerStyle = (theme: Theme, isMenuOpen: boolean): SxProps => ({
  display: 'flex',
  position: 'relative',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'center',
  borderRadius: '6px',
  paddingX: '10px',
  paddingY: '3px',
  gap: 1,
  ':hover': {
    backgroundColor: theme.palette.itemListHover.main,
  },
  ' > .kebabMenu': {
    visibility: isMenuOpen ? 'visible' : 'hidden',
  },
  ':hover > .kebabMenu': {
    visibility: 'visible',
  },
  ' > .edit-button': {
    visibility: 'hidden',
  },
  ':hover > .edit-button': {
    visibility: 'visible',
  },
});

export const LabelContainerStyle: SxProps = {
  display: 'flex',
  alignItems: 'center',
  position: 'relative',
  height: '30px',
  width: '80%',
  cursor: 'pointer',
};

export const IconAndNameStyle: SxProps = {
  display: 'flex',
  justifyContent: 'flex-start',
  position: 'relative',
  alignItems: 'center',
  gap: 1,
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  paddingY: '1.2em',
  height: '34px',
  cursor: 'pointer',
};

export const ListItemNameStyle: SxProps = {
  display: '-webkit-box',
  ' -webkit-box-orient': ' vertical',
  // whiteSpace: 'break-spaces',
  wordBreak: 'break-all',
  lineClamp: 2,
  WebkitLineClamp: 2,
  lineHeight: '1.1em',
  paddingY: '1em',
  width: 'fit-content',
  maxWidth: '100%',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
};
