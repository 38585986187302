import { Box, TextField, Typography } from '@mui/material';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import useData from './hooks';
import { innerBoxStyle, outerBoxStyle, textStyle } from './styles';
import { TraductionFieldType } from './types';

const TraductionField = React.memo(function TraductionField({
  fieldName,
  tradValues,
  OnChange,
  isSource,
}: TraductionFieldType) {
  const { currentLang, getFieldValue } = useData(isSource);

  const { t } = useTranslation();
  return (
    <Box sx={outerBoxStyle}>
      <Box sx={innerBoxStyle}>
        <Typography sx={textStyle}>{fieldName}</Typography>
        <TextField
          value={getFieldValue(tradValues)}
          placeholder={t('translation.translationField.placeholder')}
          onChange={(change: ChangeEvent<HTMLInputElement>) => {
            OnChange(fieldName, change.target.value, currentLang);
          }}
          variant="standard"
          InputProps={{ disableUnderline: true }}
        />
      </Box>
    </Box>
  );
});

export default TraductionField;
