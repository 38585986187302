import { useContext, useEffect, useState } from 'react';
import { QCDialogFormContext } from '../../context';
import { BodyContext } from '../../StepBody/context';
import { AttributeValue } from '90.quickConnect.Models/siteAdmin/models/common/entities';

const useData = (label: string, mandatory: boolean, options: string[], attributData?: AttributeValue) => {
  const { addMandatoryField, setFilled, index } = useContext(BodyContext);
  const { allData, updateAllDataArray, addFieldAllDataArray } = useContext(QCDialogFormContext);

  const [defaultOption, setDefaultOption] = useState(options[0]);

  useEffect(() => {
    if (attributData) {
      attributData.value = new String(defaultOption);
      addFieldAllDataArray(label, attributData, index);
    } else {
      addFieldAllDataArray(label, defaultOption, index);
    }
    //eslint-disable-next-line
  }, [defaultOption, addFieldAllDataArray, label, index]);

  useEffect(() => {
    if (allData[index - 1] && allData[index - 1].data) {
      const inputData = allData[index - 1].data;
      const valueIndex = inputData.findIndex((el) => el.label === label);
      if (valueIndex !== -1) {
        const { value } = inputData[valueIndex];
        if (typeof value === 'string') {
          setDefaultOption(value);
          if (mandatory) {
            addMandatoryField({ label: label, filled: value !== '' });
          }
        }
      }
    }
  }, [allData, addMandatoryField, index, label, mandatory]);

  const handleChange = (value: string | AttributeValue) => {
    if (typeof value === 'string') {
      setFilled(label, value === '' ? false : true);
      updateAllDataArray(label, value, index);
      setDefaultOption(value);
    } else {
      const strValue = String(value.value);
      setFilled(label, strValue === '' ? false : true);
      updateAllDataArray(label, value, index);
      setDefaultOption(strValue);
    }
  };

  return { handleChange, defaultOption };
};

export default useData;
