import { v4 as uuidv4 } from 'uuid';
import { encode as base64_encode } from 'base-64';
import getUNIXDateTimeWithTimeZoneOffset from '../helpers/getUNIXDateTimeWithTimeZoneOffset';
import { LogConfiguration, OptionalParamsLog } from './type';
import { LogLevel } from '90.quickConnect.Models/siteAdmin/enums/other/logLevel';
import { SeverityLevel } from '90.quickConnect.Models/siteAdmin/enums/other/severityLevel';
import logDb from '40.quickConnect.DataAcces/siteAdmin/indexedDB/dbs/logDB';

class CustomLogger {
  logLevelNumber: LogLevel;

  useConsole: boolean;

  initDateTimeApp: number;

  private static instance: CustomLogger | null = null;

  private constructor() {
    const { REACT_APP_LOG_LEVEL, REACT_APP_LOG_CONSOLE } = process.env;
    const stringLogLevel = REACT_APP_LOG_LEVEL ?? 'Debug';
    this.logLevelNumber = LogLevel[stringLogLevel as unknown as keyof typeof LogLevel];
    this.useConsole = REACT_APP_LOG_CONSOLE === true.toString();
    this.initDateTimeApp = performance.now();
    logDb.clearAllTables(this);
  }

  /**
   * Permet de retourner unse seule instance du customLogger. (Singleton Pattern)
   *
   * @static
   * @return {*}  {CustomLogger}
   * @memberof CustomLogger
   */
  public static getInstance(): CustomLogger {
    if (this.instance === null) {
      this.instance = new CustomLogger();
    }

    return this.instance;
  }

  /**
   * Permet de reset l'horodatage (utile dans le cas où on fait appel à des requêtes serveurs)
   */
  resetInitDateTimeApp = () => {
    this.initDateTimeApp = performance.now();
  };

  // eslint-disable-next-line
  serialize = (value: any) => {
    if (typeof value === 'function') {
      return value.toString();
    }
    if (value && value !== null && typeof value === 'object') {
      // eslint-disable-next-line
      var serializeObject: any = {};
      for (const [objectKey, objectValue] of Object.entries(value)) {
        serializeObject[objectKey] = this.serialize(objectValue);
      }
      return serializeObject;
    }

    return value;
  };

  formatLog = (
    message: string,
    severityLevel: SeverityLevel,
    optionalParamsLog?: OptionalParamsLog,
  ): LogConfiguration => ({
    id: uuidv4(),
    item: {
      Message: message,
      // MessageData: optionalParamsLog?.MessageData ?? null,
      EventDateTime: getUNIXDateTimeWithTimeZoneOffset(), // Todo: Changer ce format pour string Date UNIX + Offset
      Categorie: optionalParamsLog?.Categorie ?? 'General',
      EventID: optionalParamsLog?.EventID ?? 0,
      EventType: severityLevel,
      Duration: Math.round(performance.now() - this.initDateTimeApp),
    },
    // A voir pour installer base64 package.
    CrashDump:
      optionalParamsLog?.CrashDump && optionalParamsLog.CrashDump !== undefined
        ? base64_encode(JSON.stringify(optionalParamsLog.CrashDump))
        : null,
  });

  // eslint-disable-next-line
  log = async (message: any, optionalParams?: OptionalParamsLog) => {
    if (this.logLevelNumber < LogLevel.None) {
      if (this.useConsole) {
        // eslint-disable-next-line
        optionalParams !== undefined ? console.log(message, optionalParams) : console.log(message);
      }

      const itemLogConfiguration = this.formatLog(message, SeverityLevel.Verbose, optionalParams);

      await logDb.logs.add(itemLogConfiguration);
    }
  };

  // eslint-disable-next-line
  error = async (message: any, optionalParams?: OptionalParamsLog) => {
    if (this.logLevelNumber <= LogLevel.Error && this.logLevelNumber !== LogLevel.None) {
      if (this.useConsole) {
        // eslint-disable-next-line
        optionalParams !== undefined ? console.error(message, optionalParams) : console.log(message);
      }

      const itemLogConfiguration = this.formatLog(message, SeverityLevel.Error, optionalParams);

      try {
        await logDb.logs.add(this.serialize(itemLogConfiguration));
      } catch (error) {
        console.error(error);
      }
    }
  };

  // eslint-disable-next-line
  warn = async (message: any, optionalParams?: OptionalParamsLog) => {
    if (this.logLevelNumber <= LogLevel.Warning && this.logLevelNumber !== LogLevel.None) {
      if (this.useConsole) {
        // eslint-disable-next-line
        optionalParams !== undefined ? console.warn(message, optionalParams) : console.log(message);
      }

      const itemLogConfiguration = this.formatLog(message, SeverityLevel.Warning, optionalParams);

      try {
        await logDb.logs.add(this.serialize(itemLogConfiguration));
      } catch (error) {
        console.error(error);
      }
    }
  };

  // eslint-disable-next-line
  info = async (message: any, optionalParams?: OptionalParamsLog) => {
    if (this.logLevelNumber <= LogLevel.Information && this.logLevelNumber !== LogLevel.None) {
      if (this.useConsole) {
        // eslint-disable-next-line
        optionalParams !== undefined ? console.info(message, optionalParams) : console.log(message);
      }

      const itemLogConfiguration = this.formatLog(message, SeverityLevel.Information, optionalParams);

      try {
        await logDb.logs.add(this.serialize(itemLogConfiguration));
      } catch (error) {
        console.error(error);
      }
    }
  };

  // eslint-disable-next-line
  debug = async (message: any, optionalParams?: OptionalParamsLog) => {
    if (this.logLevelNumber <= LogLevel.Debug && this.logLevelNumber !== LogLevel.None) {
      if (this.useConsole) {
        // eslint-disable-next-line
        optionalParams !== undefined ? console.debug(message, optionalParams) : console.log(message);
      }

      const itemLogConfiguration = this.formatLog(message, SeverityLevel.Verbose, optionalParams);

      try {
        await logDb.logs.add(this.serialize(itemLogConfiguration));
      } catch (error) {
        console.error(error);
      }
    }
  };

  // eslint-disable-next-line
  trace = async (message: any, optionalParams?: OptionalParamsLog) => {
    if (this.logLevelNumber < LogLevel.None) {
      if (this.useConsole) {
        // eslint-disable-next-line
        optionalParams !== undefined ? console.log(message, optionalParams) : console.log(message);
      }

      const itemLogConfiguration = this.formatLog(message, SeverityLevel.Verbose, optionalParams);

      try {
        const serializedDataLog = this.serialize(itemLogConfiguration);
        await logDb.logs.add(serializedDataLog);
      } catch (error) {
        console.error(error);
      }
    }
  };
}

export default CustomLogger;
