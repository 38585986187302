import CustomLogger from '80.quickConnect.core/siteAdmin/logger/customLogger';

// eslint-disable-next-line
export const resetAllStorage = async (logger: CustomLogger) => {
  // window.sessionStorage.clear();
  // window.localStorage.clear();
  window.indexedDB.deleteDatabase('logDb');
  //   await equipmentsDb.clearAllTables(logger);
  //   await formsDb.clearAllTables(logger);
  //   await sharedListsDb.clearAllTables(logger);
  //   await declarationsDb.clearAllTables(logger);
  //   await attachmentDb.clearAllTables(logger);
};
