import React, { useContext, useState } from 'react';
import { useTheme, Box, Collapse, IconButton } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AddIcon from '@mui/icons-material/Add';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import SimpleSharedListElement from '../SimpleSharedListElement';
import SharedListSettingsContext from '../../SharedListSettingsContext';
import { SharedListLabelStyle } from '../SimpleSharedListElement/style';
import { HierarchicalItemProps } from './types';
import { ElementContainerStyle } from './style';

const HierarchicalItem = ({ hierarchicalChoice }: HierarchicalItemProps) => {
  const theme = useTheme();

  const { children, hierarchicalNodePath } = hierarchicalChoice;

  const { setAddElementLabel, setOpenAddElementDialog } = useContext(SharedListSettingsContext);

  const [open, setOpen] = useState(false);

  const handleOpenCollapse = () => {
    setOpen(!open);
  };

  const handleOpenAddElementDialog = () => {
    setAddElementLabel(hierarchicalNodePath.replaceAll('.', '\\'));
    setOpenAddElementDialog(true);
  };

  return (
    <Box sx={ElementContainerStyle(theme)}>
      <Box sx={{ display: 'flex', flexDirection: 'column' }}>
        <Box sx={{ display: 'flex' }}>
          <FolderOpenIcon />
          <Box sx={SharedListLabelStyle(true)}>{hierarchicalChoice.hierarchicalLabel}</Box>

          <IconButton
            onClick={handleOpenCollapse}
            sx={{ marginLeft: '5px', alignContent: 'baseline', padding: '0px' }}
            disableRipple
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
          {/* Box pour le ripple du bouton */}
          <Box sx={{ paddingBottom: '8', marginTop: '-1px' }}>
            <IconButton
              sx={{ alignContent: 'baseline', padding: '1px', color: theme.palette.grey[500] }}
              onClick={handleOpenAddElementDialog}
            >
              <AddIcon fontSize="small" />
            </IconButton>
          </Box>
        </Box>
        <Collapse
          in={open}
          sx={{
            paddingLeft: '10px',
            paddingY: '2px',
            // backgroundColor: theme.palette.menuBorder.main,
          }}
        >
          {children.map((child, index) =>
            child.children.length > 0 ? (
              <HierarchicalItem
                key={child.hierarchicalNodePath + child.hierarchicalLabel + index.toString()}
                hierarchicalChoice={child}
                // parentId={v4()}
              />
            ) : (
              <SimpleSharedListElement key={child.label + index.toString()} label={child.label} />
            ),
          )}
        </Collapse>
      </Box>
    </Box>
  );
};

export default HierarchicalItem;
