import React from 'react';
import { Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { observer } from 'mobx-react';
import AddFamilyDialogForm from '../AddFamilyDialogForm';
import { CircleIcon } from '../../Users/icons/Cercle';
import { useRessourcesHomeData } from './hooks';
import MenuWrapper from '10.quickConnect.app/siteAdmin/components/shared/MenuWrapper';
import QCButtonGlobal from '70.quickConnect.Components/siteAdmin/Buttons/QCButtonGlobal';
import FamilyIcon from '00.app/icons/FamilyIcon';
import DeleteConfirmationDialog from '70.quickConnect.Components/siteAdmin/Other/DeleteConfirmationDialog';

const RessourcesHome = () => {
  const { t, openAddFamilyDialog, setOpenAddFamilyDialog, openDeleteDialog, deleteDispatch } = useRessourcesHomeData();
  return (
    <MenuWrapper>
      <Stack
        justifyContent="center"
        sx={{
          height: '200px',
          width: '400px',
          marginTop: '24vh',
          marginX: '30%',
          gap: '20px',
          textAlign: 'center',
          h3: {
            fontSize: '1.5rem',
          },
        }}
      >
        <h3>{t('equipments.home')}</h3>
        <QCButtonGlobal
          className="add-family-button"
          startIcon={<FamilyIcon />}
          endIcon={<AddIcon />}
          value={t('entitySchema.entities.actions.add')}
          action={() => setOpenAddFamilyDialog(true)}
        />
        <CircleIcon sx={{ alignSelf: 'center' }} />
      </Stack>

      <AddFamilyDialogForm open={openAddFamilyDialog} toggleOpen={setOpenAddFamilyDialog} />
      <DeleteConfirmationDialog open={openDeleteDialog} dispatch={deleteDispatch} trashAvailable />
    </MenuWrapper>
  );
};

export default observer(RessourcesHome);
