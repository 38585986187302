import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';

export const dividerStyle = (theme: Theme): SxProps => ({
  display: 'flex',
  justifyContent: 'space-between',
  width: '95%',
  marginLeft: '2.5%',
  borderColor: theme.palette.grey[300],
});

export const headerStyle = (theme: Theme): SxProps => ({
  backgroundColor: theme.palette.widgetHeaderBackground.main,

  border: `solid 2px ${theme.palette.secondary.main}`,
  borderTopLeftRadius: '20px',
  borderTopRightRadius: '20px',

  display: 'flex',
  justifyContent: 'space-between',

  paddingX: '20px',
  alignContent: 'center',
  alignItems: 'center',
});

export const ListContainerStyle = (theme: Theme): SxProps => ({
  border: `solid 2px ${theme.palette.secondary.main}`,
  backgroundColor: theme.palette.background.default,
  borderTop: 'none',
  borderBottomLeftRadius: '20px',
  borderBottomRightRadius: '20px',
  height: '455px',
});
