import React from 'react';
import { Box, SxProps } from '@mui/system';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { IconButton, InputAdornment, TextField, useTheme } from '@mui/material';
import { ControlProps } from '../../StepBody/type';
import useData from './hooks';
import { QCTextInputProps } from '70.quickConnect.Components/siteAdmin/Inputs/QCTextInput/type';
import QCInputHeader from '70.quickConnect.Components/siteAdmin/Inputs/QCInputHeader';
import { InputContainerStyle } from '70.quickConnect.Components/siteAdmin/Inputs/style';

const useSx = (control: ControlProps | undefined, value: string): SxProps => {
  const theme = useTheme();
  if (control) {
    if (value !== '' && control.regex.exec(value) === null)
      return {
        '.MuiOutlinedInput-notchedOutline': {
          borderColor: `${theme.palette.error.main} !important`,
        },
      };
  }
  return {};
};

const QCDialogFormPassword = ({
  label,
  mandatory,
  control,
  hidden,
}: QCTextInputProps & { label: string; hidden?: boolean }) => {
  const { showPassword, defaultValue, handleChange, setShowPassword } = useData(
    label,
    mandatory ?? false,
    control,
    hidden,
  );

  const theme = useTheme();
  return (
    <Box sx={useSx(control, defaultValue)}>
      {!hidden ? (
        <Box sx={InputContainerStyle(theme, mandatory)}>
          {label && <QCInputHeader label={label} info={control?.instructions} mandatory={mandatory} />}
          <TextField
            data-cy="password-input"
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            value={defaultValue}
            onChange={(event: React.ChangeEvent<{ value: string }>) => handleChange(event.target.value)}
            autoComplete="new-password"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    onMouseDown={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Box>
      ) : null}
    </Box>
  );
};

export default QCDialogFormPassword;
