import { SxProps } from '@mui/system';

export const AccountHeaderStyleProps: SxProps = {
  display: 'flex',
  flexDirection: 'row',
  height: 'fit-content',
  alignItems: 'center',
  gap: '15px',
  '& .MuiButton-root': {
    transform: 'translate(0px, 5px)',
  },
};
