import React from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { GridColumns, GridRowId, GridSortModel, GridValidRowModel } from '@mui/x-data-grid';
import { CreateResourceRequest, GetAllResourcesRequest, PrintResourceRequest } from '../services/requests';
import { UserRowActions } from '../../Users/actions';
import useResourcesServices from '../services/resources';
import { useRessourcesServices } from '../services/services';
import { SubMenuFamilyRowAction } from '../MenuActions/family';
import { ResMultipleActions, ResourceRowActions } from '../actions';
import { familyNodeParser } from '../familyNodeParser';
import RenderCellBtn from './RenderCell';
import { FiltersType } from './types';
import { defaultColumns, defaultFilters, FieldNames } from './const';
import { useStore } from '30.quickConnect.Store/siteAdmin';
import { DeleteActionType } from '70.quickConnect.Components/siteAdmin/Other/DeleteConfirmationDialog/type';
import { useDeleteDialog } from '80.quickConnect.core/siteAdmin/hooks/useDeleteDialog';
import { SortTypes } from '90.quickConnect.Models/siteAdmin/enums/sortTypes';
import { AllDataElementType, DataValueType } from '70.quickConnect.Components/siteAdmin/Other/QCDialogForm/types';
import { AttributeValue } from '90.quickConnect.Models/siteAdmin/models/common/entities';
import { useSafeNavigation } from '80.quickConnect.core/siteAdmin/hooks/useSafeNavigation';
import useEffectOnce from '80.quickConnect.core/shared/hooks/useEffectOnce';
import { SubMenuRow } from '10.quickConnect.app/siteAdmin/components/shared/SubMenu/types';
import { getReplacedPath } from '80.quickConnect.core/shared/helpers/getReplacedPath';
import { ENTITYSETTINGS, EQUIPMENTS, EQUIPMENTSETTINGS } from '10.quickConnect.app/siteAdmin/routes/paths';

// eslint-disable-next-line
const useData = () => {
  const { entityId } = useParams();
  const { t } = useTranslation();
  const navigate = useSafeNavigation();
  const {
    SubMenuStore: {
      subMenuPath,
      setRows,
      currentSubMenuRow,
      selectedRowAction,
      setSelectedRowAction,
      selectedRowsMultipleAction,
      setSelectedRowsMultipleAction,
      setHandleAddClick,
    },
  } = useStore();
  const { getResources, deleteResource, createResource, printResource } = useResourcesServices();
  const { deleteFamily, getFamilies } = useRessourcesServices();
  const [gridrows, setGridRows] = useState<GridValidRowModel[]>([]);
  const [searchValue, setSearchValue] = useState('');
  // eslint-disable-next-line
  const [filters, setFilters] = useState<FiltersType[]>(defaultFilters);
  const [rowAction, setRowAction] = useState<{ option: string; rowId: GridRowId }>({ option: '', rowId: '' });
  const [rowsAction, setRowsAction] = useState<{ option: string; rowIds: GridRowId[] }>({ option: '', rowIds: [] });
  const [isLoading, setIsLoading] = useState(false);
  const [rowCount, setRowcount] = useState(0);
  const [actualPage, setActualPage] = useState(0);
  const [actualInstructions, setActualInstructions] = useState([
    { direction: 0, column: FieldNames.resourceTitle as string },
  ]);
  const [openAddFamilyDialog, setOpenAddFamilyDialog] = useState<boolean>(false);

  useEffectOnce(() => {
    setHandleAddClick(() => setOpenAddFamilyDialog(true));
  });

  const handleNavigate = useCallback((route: string) => navigate(route), [navigate]);

  const columns: GridColumns = useMemo(
    () => [
      { field: FieldNames.organizationName, headerName: t('entityData.entities.table.organization'), flex: 1.5 },
      {
        field: FieldNames.resourceTitle,
        headerName: t('entityData.entities.table.title'),
        flex: 1.5,
        renderCell: (params) => {
          return <RenderCellBtn params={params} />;
        },
      },
      {
        field: FieldNames.identifierCode,
        headerName: t('entityData.entities.table.code'),
        flex: 2,
      },
      {
        field: FieldNames.tag,
        headerName: t('entityData.entities.table.qcTagCode'),
        flex: 1.5,
      },
    ],
    [t],
  );

  const initResources = useCallback(
    async (
      length: number,
      start: number,
      instruction: {
        column: string;
        direction: number;
      }[],
    ) => {
      if (currentSubMenuRow === undefined || currentSubMenuRow.id !== entityId) {
        navigate(EQUIPMENTS);
        return;
      }
      setIsLoading(true);
      const defaultGetResourcesRequest: GetAllResourcesRequest = {
        start: start,
        length: length,
        orderBy: [...instruction],
        count: 1,
        filters: filters,
        columns: defaultColumns,
        textFilter: searchValue,
        lightSelect: true,
        wantToken: true,
        entitySchemaId: entityId,
      };
      const res = await getResources(defaultGetResourcesRequest);
      res.data.forEach((item) => (item.id = item.instanceId));
      setGridRows(res.data);
      setRowcount(res.totalCount);
      setIsLoading(false);
    },
    [getResources, setGridRows, searchValue, entityId, filters, currentSubMenuRow, navigate],
  );

  useEffect(() => {
    initResources(5, 0, actualInstructions);
    //eslint-disable-next-line
  }, [currentSubMenuRow]);

  const [rowCountState, setRowCountState] = useState(rowCount);
  useEffect(() => {
    setRowCountState((prevRowCountState) => (rowCount !== undefined ? rowCount : prevRowCountState));
  }, [rowCount, setRowCountState]);

  const dataTableCallBack = useCallback(
    (page: number) => {
      initResources(5, page * 5, actualInstructions);
      setActualPage(page);
    },
    [actualInstructions, initResources],
  );

  const sortingHandler = useCallback(
    (model: GridSortModel) => {
      const instruction =
        model[0] !== undefined
          ? [
              {
                column: model[0].field,
                direction: model[0].sort === 'asc' ? 0 : 1,
              },
            ]
          : [
              {
                column: FieldNames.resourceTitle,
                direction: 0,
              },
            ];
      setActualInstructions(instruction);
      setActualPage(0);
      initResources(5, 0, instruction);
    },
    [initResources],
  );

  const [openDeleteDialog, setOpenDeleteDialog, deleteState, deleteDispatch] = useDeleteDialog();

  const handleOpenDeleteDialog = () => {
    setOpenDeleteDialog(true);
  };

  const handlePrint = useCallback(
    async (ids: string[]) => {
      if (entityId) {
        const res = await printResource({
          entitySchemaId: entityId,
          entityDataIds: ids,
        } as PrintResourceRequest);
        const resourceBlob = new Blob([res], { type: 'text/html;charset=UTF-8' });
        const url = window.URL.createObjectURL(resourceBlob);
        window.open(url);
        setTimeout(function () {
          URL.revokeObjectURL(url);
        }, 3000);
      }
    },
    [entityId, printResource],
  );

  useEffect(() => {
    if (rowAction?.option === ResourceRowActions.Delete && rowAction.rowId !== '') {
      handleOpenDeleteDialog();
    } else if (rowAction?.option === ResourceRowActions.Edit && rowAction.rowId !== '') {
      // navigate(`/equipments/${entityId}/${rowAction.rowId}/settings`);
      navigate(getReplacedPath(EQUIPMENTSETTINGS, [entityId, rowAction.rowId] as string[]));
    }
    //eslint-disable-next-line
  }, [rowAction]);

  useEffect(() => {
    if (rowsAction.option === ResMultipleActions.Delete && rowsAction.rowIds.length > 0) {
      setOpenDeleteDialog(true);
    } else if (rowsAction.option === ResMultipleActions.Print && rowsAction.rowIds.length > 0) {
      handlePrint(rowsAction.rowIds as string[]);
    }
    //eslint-disable-next-line
  }, [rowsAction]);

  useMemo(() => {
    setActualPage(0);
    initResources(5, 0, actualInstructions);
    // eslint-disable-next-line
  }, [searchValue]);

  // SubMenu Actions
  useEffect(() => {
    if (!selectedRowAction) return;
    switch (selectedRowAction.option) {
      case t(SubMenuFamilyRowAction.Delete):
        handleOpenDeleteDialog();
        break;
      case t(SubMenuFamilyRowAction.Settings):
        // navigate(`/equipments/entity/${selectedRowAction.rowId}/settings`);
        navigate(getReplacedPath(ENTITYSETTINGS, [selectedRowAction.rowId]));
        setSelectedRowAction(undefined);
        break;
      default:
        break;
    }
    //eslint-disable-next-line
  }, [selectedRowAction]);

  useEffect(() => {
    switch (selectedRowsMultipleAction?.option) {
      case t(SubMenuFamilyRowAction.Delete):
        setOpenDeleteDialog(true);
        break;
      default:
        break;
    }
    //eslint-disable-next-line
  }, [selectedRowsMultipleAction]);

  const handleDeleteFamily = async (ids: string[], sendToRecycleBin: boolean) => {
    await deleteFamily({ ids: ids, sendToRecycleBin: sendToRecycleBin });
    navigate(EQUIPMENTS);
  };

  useEffect(() => {
    let sendToRecycleBin = false;
    switch (deleteState.choice) {
      // @ts-expect-error intentional fall through
      case DeleteActionType.SendToTrash:
        sendToRecycleBin = true;
      // fallsthrough
      case DeleteActionType.Delete:
        setOpenDeleteDialog(false);
        //on regarde si ça vient de la datatable
        if (rowAction?.option === UserRowActions.Delete && rowAction.rowId !== '' && entityId) {
          deleteResource({ ids: [`${rowAction.rowId}`], entitySchemaId: entityId, sendToRecycleBin: sendToRecycleBin });
          setRowAction({ option: '', rowId: '' });
          initResources(5, 0, actualInstructions);
        } else if (rowsAction?.option === UserRowActions.Delete && rowsAction.rowIds.length > 0 && entityId) {
          deleteResource({
            ids: rowsAction.rowIds.map((id) => id.toString()),
            entitySchemaId: entityId,
            sendToRecycleBin: sendToRecycleBin,
          });
          setRowsAction({ option: '', rowIds: [] });
          initResources(5, 0, actualInstructions);
        }
        // on regarde d'abord si le delete vient d'une ligne du subMenu
        else if (selectedRowAction && selectedRowAction.rowId) {
          handleDeleteFamily([selectedRowAction.rowId], sendToRecycleBin);
          setSelectedRowAction(undefined);
          return;
          // ou s'il vient des actions multiples
        } else if (selectedRowsMultipleAction) {
          handleDeleteFamily(selectedRowsMultipleAction.rowsId, sendToRecycleBin);
          setSelectedRowsMultipleAction(undefined);
          // sinon c'est la famille affichée
        } else if (currentSubMenuRow) {
          handleDeleteFamily([currentSubMenuRow.id], sendToRecycleBin);
        }
        break;
      case DeleteActionType.Cancel:
      default:
        setOpenDeleteDialog(false);
        break;
    }
    // eslint-disable-next-line
  }, [deleteState]);

  // eslint-disable-next-line
  const [option, setOptions] = useState<string>(SortTypes.Alphabetical as string);
  const sortingOptions: string[] = Object.values(SortTypes);

  const requestHandler = {
    paginationMode: 'server',
    rowCount: rowCountState,
    onPageChange: dataTableCallBack,
  };

  const sortingProps = {
    sortingMode: 'server',
    onSortModelChange: sortingHandler,
    page: actualPage,
  };

  const [isAddResourceFormOpen, setIsAddResourceFormOpen] = useState<boolean>(false);

  const isAttributeValue = (value: DataValueType): value is AttributeValue => {
    return (value as AttributeValue).attributeId !== undefined;
  };

  const handleConfirmAddResource = useCallback(
    async (allData: AllDataElementType[]) => {
      console.log('add resource all data', allData);
      const request = {
        entitySchemaId: entityId,
        AddDtoToResponse: true,
      } as CreateResourceRequest;
      request.attributes = [];
      allData.forEach((stepData) => {
        stepData.data.forEach((el) => {
          switch (el.label) {
            case t('entityData.entities.table.title'):
              if (typeof el.value === 'string') request.title = el.value;
              break;
            case t('entityData.editor.designInstance.placeholder.organization'):
              if (typeof el.value === 'string') request.organizationUnitId = el.value;
              break;
            case t('entityData.editor.newInstance.placeholder.code'):
              if (typeof el.value === 'string') request.code = el.value;
              break;
            case t('entityData.entities.table.qcTagCode'):
              if (typeof el.value === 'string') request.qcTagCode = el.value;
              break;
            default:
              if (isAttributeValue(el.value)) request.attributes.push(el.value);
              break;
          }
        });
      });
      const response = await createResource(request);
      if (response) {
        initResources(5, 0, actualInstructions);
        return true;
      }
      return false;
    },
    [createResource, entityId, t, actualInstructions, initResources],
  );

  const initFamiliesData = useCallback(async () => {
    const data = await getFamilies({
      count: 1,
      filters: [],
      length: 0,
      loadAttributes: false,
      name: '',
      start: 0,
      textFilter: null,
      orderBy: [],
    });
    if (data && data.length > 0) {
      const layers: SubMenuRow[][] = familyNodeParser(data);
      const newCurrentRow = layers[subMenuPath.length - 2].find((el) => el.id === entityId);
      if (newCurrentRow && newCurrentRow.type === 'family' && newCurrentRow.childrenItems) {
        setRows(newCurrentRow.childrenItems);
      }
    }
  }, [getFamilies, setRows, subMenuPath.length, entityId]);
  // actualiser les sous familles après l'ajout d'une famille
  useEffectOnce(() => {
    if (subMenuPath.length >= 2) initFamiliesData();
  });

  return {
    columns,
    gridrows,
    setSearchValue,
    setRowAction,
    setRowsAction,
    isLoading,
    currentSubMenuRow,
    handleNavigate,
    openDeleteDialog,
    deleteDispatch,
    handleOpenDeleteDialog,
    requestHandler,
    sortingProps,
    sortingOptions,
    setOptions,
    isAddResourceFormOpen,
    setIsAddResourceFormOpen,
    entityId,
    handleConfirmAddResource,
    openAddFamilyDialog,
    setOpenAddFamilyDialog,
  };
};

export default useData;
