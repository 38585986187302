import { useCallback } from 'react';
import { AxiosError } from 'axios';
import { GetHistoryListResponse } from './types';
import { defaultGetHistoryListResponse } from './const';
import QCHttpRequest from '40.quickConnect.DataAcces/siteAdmin/QCHttpRequest';
import { API_GET_SYNCHRONIZATIONLIST } from '40.quickConnect.DataAcces/siteAdmin/apiRoutes/connectionHistory';

const useServices = () => {
  const GetHistoryListRequest = useCallback(
    async (length: number, start: number, orderBy: { column: string; direction: number }[], filter: string) => {
      const requestHandler = new QCHttpRequest();
      const request = {
        count: 1,
        filters: [],
        orderBy: [...orderBy],
        length: length,
        start: start,
        textFilter: filter,
      };

      try {
        const result = await requestHandler.post(API_GET_SYNCHRONIZATIONLIST, request, { withCredentials: true });
        if (result) {
          return result.data as GetHistoryListResponse;
        }
      } catch (error) {
        const err = error as AxiosError;
        return err.response?.status;
      }
    },
    [],
  );

  const getHistoryList = useCallback(
    async (length: number, start: number, orderBy: { column: string; direction: number }[], filter: string) => {
      let ret = defaultGetHistoryListResponse;

      const post = async () => {
        const result = await GetHistoryListRequest(length, start, orderBy, filter);
        ret = (result as GetHistoryListResponse) ?? defaultGetHistoryListResponse;
      };
      await post();
      return ret;
    },
    [GetHistoryListRequest],
  );

  return { getHistoryList };
};

export default useServices;
