import React, { ReactNode } from 'react';
import { Box, useTheme } from '@mui/material';
import { OverTitleStyle } from '../style';

const OverTitle = ({ children }: { children: ReactNode }) => {
  const theme = useTheme();
  return <Box sx={OverTitleStyle(theme)}>{children}</Box>;
};

export default OverTitle;
