import { SxProps } from '@mui/system';

export const MainBoxLoginStyle: SxProps = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  border: {
    xs: '16px solid white',
    sm: '32px solid white',
    md: '48px solid white',
    lg: '64px solid white',
  },
  height: '100vh',
  width: '100vw',
};

export const CardLoginStyle: SxProps = {
  width: '100%',
  height: '100%',
};
