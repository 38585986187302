import { createContext } from 'react';
import type { SubMenuContextType } from './types';

export const SubMenuContext = createContext<SubMenuContextType>({
  setSelectedRowAction: () => {},
  showCheckbox: false,
  openedFolders: [],
  setOpenedFolders: () => {},
  handleRowCheckboxClick: () => {},
  selectedRowsId: [],
  width: 286,
});

export const subMenuWidth = '325px';
export const subMenuWidthNumber = 325;
