import { action, makeAutoObservable } from 'mobx';
import { stopPersisting } from 'mobx-persist-store';
import UserSettingsStore from './UserSettingsStore';
import CommonStore from './CommonStore';
import LoginStore from './LoginStore';
import LoginMsalStore from './LoginStore/LoginMsalStore';
import SideMenuStore from './SideMenuStore';
import HeaderStore from './HeaderStore';
import SubMenuStore from './SubMenuStore';
import OrganizationalUnitStore from './OrganizationlUnitStore';
import { resetAllStorage } from '40.quickConnect.DataAcces/siteAdmin/helpers';
import CustomLogger from '80.quickConnect.core/siteAdmin/logger/customLogger';

class RootStore {
  /* Les differents Stores */
  LoginStore: LoginStore;

  LoginMsalStore: LoginMsalStore;

  // FormStore: FormStore;

  // DeclarationStore: DeclarationStore;

  // DialogStore: DialogStore;

  CommonStore: CommonStore;

  SideMenuStore: SideMenuStore;

  // SessionStore: SessionStore;

  UserSettingsStore: UserSettingsStore;

  // EquipmentsStore: EquipmentsStore;

  // SharedListStore: SharedListStore;

  // InboxesStore: InboxesStore;

  // ComputeStore: ComputeStore;

  HeaderStore: HeaderStore;

  SubMenuStore: SubMenuStore;

  OrganizationalUnitStore: OrganizationalUnitStore;

  logger: CustomLogger;

  constructor(logger: CustomLogger) {
    this.logger = logger;
    makeAutoObservable(this, {
      clearAllDomainStores: action,
    });
    /* creer tous les stores */
    this.LoginStore = new LoginStore(this, logger, 'LoginStore');
    this.LoginMsalStore = new LoginMsalStore(this, logger, 'LoginMsalStore');

    this.UserSettingsStore = new UserSettingsStore(this, logger, 'UserSettingsStore');
    this.CommonStore = new CommonStore(this, logger);
    this.SideMenuStore = new SideMenuStore(this, logger, 'SideMenuStore');
    this.HeaderStore = new HeaderStore();
    this.SubMenuStore = new SubMenuStore(this, 'SubMenuStore');
    this.OrganizationalUnitStore = new OrganizationalUnitStore();
  }

  resetAllObservables = () => {
    /* reinitialiser tous les observables */
    this.LoginMsalStore.resetStore();
    this.LoginStore.resetStore();
    this.UserSettingsStore.resetStore();
    this.CommonStore.resetStore();
    this.HeaderStore.resetStore();
    this.SubMenuStore.resetStore();
  };

  clearAllDomainStores = async () => {
    /* stopPersisting les stores */
    stopPersisting(this.UserSettingsStore);
    stopPersisting(this.CommonStore);

    await resetAllStorage(this.logger);
  };

  initQuickConnectApp = async (): Promise<void> => {
    /* initialiser les stores */
    stopPersisting(this.UserSettingsStore);
    this.UserSettingsStore = new UserSettingsStore(this, this.logger, 'UserSettingsStore');
  };
}

export default RootStore;
