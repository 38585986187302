import { API_MOBILE } from '../ClientapiRoutes';
import API_HOST from './apiHost';

// informations de l'applications
export const API_GET_API_INFORMATION = `${API_HOST}information`;

export const API_GET_TODO_LIST_TASKS = `${API_MOBILE}downloadtodolist`;

export const API_DEV_DISPATCHER = process.env.REACT_APP_QC_API_DEV_DISPATCHER;

export const API_PROD_DISPATCHER = process.env.REACT_APP_QC_API_PROD_DISPATCHER;
