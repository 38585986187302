import { SxProps } from '@mui/system';

export const PageSx: SxProps = {
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  marginX: '5vw',
  gap: 3,
  '.MuiDataGrid-cell': {
    padding: '10px !important',
  },
  '.MuiDataGrid-virtualScroller': {
    scrollbarWidth: 'thin !important',
    overflowY: 'unset !important',
    overflowX: 'hidden',
    maxHeight: '50vh',
  },
  '.MuiDataGrid-virtualScrollerRenderZone': {
    overflowY: 'unset !important',
  },
};
