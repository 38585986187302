import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, DropResult } from '@hello-pangea/dnd';
import DraggableListItem from './DraggableListItem';
import { DraggableItem } from './DraggableListItem/type';
import { reorder, initializeList } from './helper';
import type { DraggableListProps } from './type';

/**
 * @component DraggableList
 * @prop {ComponentType<PossibleProps>} renderComponent - Composant utilisé en tant que ListItem
 * @prop {PossibleProps[]} componentsProps          - Tableau de Props passé au composant pour chaque itération
 * @prop {Dispatch<SetStateAction<PossibleProps>>} dispatchProps - SetState qui permet de récupérer la liste de props dans l'ordre final
 * @example
 *  return(
 *   <DraggableList<ItemExempleProps>
 *     renderComponent={(item) => <ItemComponent {...item.props}></ItemComponent>}
 *     componentsProps={exampleProps}
 *   />
 * )
 */

const DraggableList = <P,>({ renderComponent, componentsProps, dispatchProps }: DraggableListProps<P>) => {
  // items correspond au tableau de props + un id pour garder sa trace lors du dnd
  const itemsInit = componentsProps ? initializeList<P>(componentsProps) : [];
  const [items, setItems] = useState<DraggableItem<P>[]>(itemsInit);

  const handleDragEnd = ({ destination, source }: DropResult) => {
    // if dropped outside the list
    if (!destination) return;
    const newItems = reorder(items, source.index, destination.index);
    setItems(newItems);
    if (dispatchProps) {
      const newProps: P[] = [];
      newItems.forEach((item) => {
        newProps.push(item.props);
      });
      dispatchProps(newProps);
    }
  };

  // si on ajoute un item à l'exterieur du composant
  useEffect(() => {
    setItems(itemsInit);
    //eslint-disable-next-line
  }, [componentsProps]);

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="droppable-list">
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {items.map((item, index) => (
              <DraggableListItem<P> renderComponent={renderComponent} item={item} index={index} key={item.id} />
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default DraggableList;
