import { Dispatch, SetStateAction, useReducer, useState } from 'react';
import {
  deleteConfirmationReducer,
  DeleteActionType,
  ReducerState,
  DeleteAction,
} from '70.quickConnect.Components/siteAdmin/Other/DeleteConfirmationDialog/type';

type DeleteDialogHookReturnType = [
  openDeleteDialog: boolean,
  setOpenDeleteDialog: Dispatch<SetStateAction<boolean>>,
  deleteState: ReducerState,
  deleteDispatch: Dispatch<DeleteAction>,
];
export const useDeleteDialog = (): DeleteDialogHookReturnType => {
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteState, deleteDispatch] = useReducer(deleteConfirmationReducer, { choice: DeleteActionType.Cancel });

  return [openDeleteDialog, setOpenDeleteDialog, deleteState, deleteDispatch];
};
