import { useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router';
import { NavElementName } from '../types';
import { UseDataProps } from './types';
import { ACTIVITIES, DECLARATIONS, FORMS, HOME, SCAN, USERS } from '10.quickConnect.app/siteAdmin/routes/paths';
import { useStore } from '30.quickConnect.Store/siteAdmin';

export const useData = (): UseDataProps => {
  const {
    UserSettingsStore: { role },
    SideMenuStore: { focusedBtn, setFocusedBtn },
  } = useStore();
  const { pathname } = useLocation();
  const [valueNavIndex, setValueNavIndex] = useState<NavElementName>(() => {
    switch (pathname.toLowerCase()) {
      case HOME:
        return 'home';
      case FORMS:
        return 'forms';
      case ACTIVITIES:
        return 'activities';
      case DECLARATIONS:
        return 'declarations';
      default:
        return 'plus';
    }
  });

  const valueNavIndexs = useMemo(() => {
    switch (pathname.toLowerCase()) {
      case HOME:
        return 'home';
      case FORMS:
        return 'forms';
      case ACTIVITIES:
        return 'activities';
      case DECLARATIONS:
        return 'declarations';
      default:
        return 'plus';
    }
  }, [pathname]);

  useEffect(() => {
    setValueNavIndex(valueNavIndexs);
  }, [valueNavIndexs]);

  const mapNavNamesToRoutes = (name: NavElementName) => {
    switch (name) {
      case 'home':
        return HOME;
      case 'forms':
        return FORMS;
      case 'activities':
        return ACTIVITIES;
      case 'declarations':
        return DECLARATIONS;
      case 'scan':
        return SCAN;
      case 'users':
        return USERS;
      default:
        return pathname.toLowerCase();
    }
  };

  return {
    valueNavIndex,
    setValueNavIndex,
    mapNavNamesToRoutes,
    focusedBtn,
    setFocusedBtn,
    role,
  };
};
