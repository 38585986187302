import { Button, useTheme } from '@mui/material';
import { GridRenderCellParams, GridRowId } from '@mui/x-data-grid';
import React from 'react';
import { useParams } from 'react-router';
import { useSafeNavigation } from '80.quickConnect.core/siteAdmin/hooks/useSafeNavigation';
import { getReplacedPath } from '80.quickConnect.core/shared/helpers/getReplacedPath';
import { EQUIPMENTSETTINGS } from '10.quickConnect.app/siteAdmin/routes/paths';

// eslint-disable-next-line
const RenderCellBtn = ({ params }: { params: GridRenderCellParams<any, any, any> }) => {
  const { entityId } = useParams();
  const theme = useTheme();
  const navigate = useSafeNavigation();

  const handleCellClick = (id: GridRowId) => {
    return navigate(getReplacedPath(EQUIPMENTSETTINGS, [entityId, id] as string[]));
  };

  return (
    <Button
      variant="text"
      onClick={() => handleCellClick(params.id)}
      sx={{ textTransform: 'none', color: theme.palette.text.primary }}
    >
      {params.value}
    </Button>
  );
};

export default RenderCellBtn;
