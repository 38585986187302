import { useTheme } from '@mui/material';
import { SxProps } from '@mui/system';

export const TreeViewStyle: () => { root: SxProps; item: SxProps } = () => {
  return {
    root: {
      maxHeight: 240,
      flexGrow: 1,
      overflowY: 'scroll',
      scrollbarWidth: 'thin',

      '& .MuiTreeItem-label': {
        fontSize: '1.5rem',
      },
    },
    item: {
      '& .MuiTreeItem-label': {
        fontSize: '1.2rem !important',
      },
    },
  };
};

export const PopperStyle = (anchorEl: HTMLElement | null): SxProps => {
  const theme = useTheme();
  return {
    backgroundColor: theme.palette.background.default,
    zIndex: 10000,
    width: (anchorEl?.offsetWidth ?? 400) - 15,
    left: '-20px',
    border: `1px solid ${theme.palette.borderGroup.main}`,
    // borderRadius: '8px 8px 8px 8px',
    overflow: 'hidden',
  };
};
