import { SxProps, Theme } from '@mui/material';

export const ElementContainerStyle = (theme: Theme): SxProps => ({
  width: '100%',
  marginY: '10px',
  paddingY: '10px',
  paddingLeft: '15px',
  paddingRight: '3px',
  backgroundColor: theme.palette.toolsBackground.main,
  position: 'relative',
  borderRadius: '6px',
  ':focus': {
    backgroundColor: theme.palette.itemListHover.main,
  },
});
