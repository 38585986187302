import API_HOST from './apiHost';

const API_USER = `${API_HOST}User/`;

const ApiUser = {
  getUser: `${API_USER}getuser/`,
  getUserList: `${API_USER}getuserlist`,
  designUser: `${API_USER}designuser`,
  createUser: `${API_USER}createuser`,
  updateUser: `${API_USER}updateuser`,
  deleteUser: `${API_USER}deleteuser`,
  changeUserPwd: `${API_USER}changeuserpassword`,
  forgetPwd: `${API_USER}forgetpassword`,
  exportCsvSample: `${API_USER}exportCsvSample`,
  import: `${API_USER}import`,
} as const;

export default ApiUser;
