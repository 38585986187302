import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';

export const InputContainerStyle = (theme: Theme, mandatory?: boolean) => {
  return {
    display: 'flex',
    minWidth: '200px',
    flexDirection: 'column',
    // marginLeft: '2vw',
    // marginRight: '2vw',
    marginTop: '1vh',
    marginBottom: '1vh',
    height: '70%',
    flexGrow: 1,

    '& .MuiFormControl-root': mandatory
      ? {
          borderLeft: `10px solid ${theme.palette.mandatoryText.main}`,
          borderTopLeftRadius: '8px',
          borderBottomLeftRadius: '8px',
        }
      : {},

    '& .MuiOutlinedInput-notchedOutline': mandatory
      ? {
          borderTopLeftRadius: '0px',
          borderBottomLeftRadius: '0px',
          borderLeftWidth: '0px',
        }
      : {},
  } as SxProps;
};
