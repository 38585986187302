import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GridColumns, GridRowId } from '@mui/x-data-grid';
import { BinDataTableMultipleActions } from './const';
import { BinElement } from './types';
import { DeleteBinElementsRequest, GetBinElementsRequest, RestoreBinElementRequest } from './service/request';
import { useBinServices } from './service/services';
import { HOME } from '10.quickConnect.app/siteAdmin/routes/paths';
import { useStore } from '30.quickConnect.Store/siteAdmin';
import useEffectOnce from '80.quickConnect.core/shared/hooks/useEffectOnce';
import { Features } from '90.quickConnect.Models/siteAdmin/enums/features/features';
import { toReadableDate } from '80.quickConnect.core/siteAdmin/helpers/toReadableDate';
import { useReadElementType } from '80.quickConnect.core/siteAdmin/hooks/readBinElementType';
import { useSafeNavigation } from '80.quickConnect.core/siteAdmin/hooks/useSafeNavigation';

export const useBinData = () => {
  const { t } = useTranslation();
  const {
    UserSettingsStore: { subscribedFeatures },
  } = useStore();
  const navigate = useSafeNavigation();
  const { getBinElements, deleteBinElements, restoreBinElements } = useBinServices();

  const { getBinElementTypeText } = useReadElementType();

  const [binElements, setBinElements] = useState<BinElement[]>([]);
  const columns: GridColumns = useMemo(
    () => [
      {
        field: 'type',
        headerName: t('bin.viewer.table.type'),
        flex: 1,
        renderCell: (params) => getBinElementTypeText(params.value),
      },
      { field: 'name', headerName: t('bin.viewer.table.name'), flex: 2 },
      { field: 'id', headerName: t('bin.viewer.table.deletedElementId'), flex: 2.5 },
      {
        field: 'deletedAt',
        headerName: t('bin.viewer.table.deletedAt'),
        flex: 2,
        renderCell: (params) => toReadableDate(params.value),
      },
      {
        field: 'expireAt',
        headerName: t('bin.viewer.table.expireAt'),
        flex: 2,
        renderCell: (params) => toReadableDate(params.value),
      },
      { field: 'deletedByName', headerName: t('bin.viewer.table.deletedByName'), flex: 2 },
    ],
    [t, getBinElementTypeText],
  );

  const initBinElements = useCallback(async () => {
    const request: GetBinElementsRequest = {
      start: 0,
      length: 100,
      orderBy: [],
      filters: [],
      lightSelect: false,
    };
    const data = await getBinElements(request);
    if (data) {
      setBinElements(data.binElements);
    }
  }, [setBinElements, getBinElements]);

  const [selectedMultipleAction, setSelectedMultipleAction] = useState<{ option: string; rowIds: GridRowId[] }>({
    option: '',
    rowIds: [],
  });

  useEffectOnce(() => {
    if (!subscribedFeatures[Features.Bin]) {
      navigate(HOME);
      return;
    }

    initBinElements();
  });

  useEffect(() => {
    if (selectedMultipleAction.option === t(BinDataTableMultipleActions.Delete)) {
      const request: DeleteBinElementsRequest = {
        ids: selectedMultipleAction.rowIds as string[],
      };
      deleteBinElements(request).then(() => initBinElements());
    } else if (selectedMultipleAction.option === t(BinDataTableMultipleActions.Restore)) {
      const request: RestoreBinElementRequest = {
        ids: selectedMultipleAction.rowIds as string[],
      };
      restoreBinElements(request).then(() => initBinElements());
    }
    //eslint-disable-next-line
  }, [selectedMultipleAction]);

  return {
    t,
    binElements,
    columns,
    setSelectedMultipleAction,
  };
};
