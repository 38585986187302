import React, { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Box, Collapse, Dialog } from '@mui/material';
import SharedListSettingsContext from '../../SharedListSettingsContext';
import { AddElementDialogProps } from './types';
import QCTextInput from '70.quickConnect.Components/siteAdmin/Inputs/QCTextInput';
import { SharedListData } from '90.quickConnect.Models/siteAdmin/models/sharedList/sharedList';
import QCButtonGlobal from '70.quickConnect.Components/siteAdmin/Buttons/QCButtonGlobal';
import { doesLabelExist } from '80.quickConnect.core/siteAdmin/helpers/sharedListData';
import { SharedListType } from '90.quickConnect.Models/siteAdmin/enums/sharedList/sharedListType';
import QCSwitchSelect from '70.quickConnect.Components/siteAdmin/Inputs/QCSwitchSelect';

const AddElementDialog = ({ open, setOpen }: AddElementDialogProps) => {
  const { t } = useTranslation();
  const {
    sharedListElements,
    setSharedListElements,
    sharedListType,
    addElementLabel: completeLabel,
    setAddElementLabel,
  } = useContext(SharedListSettingsContext);
  const [newElement, setNewElement] = useState<SharedListData>({ label: '', value: '' });

  const [subLabel, setSubLabel] = useState('');

  const [newElementType, setNewElementType] = useState(SharedListType.Simple);

  const handleAddElement = () => {
    if (doesLabelExist(sharedListElements, newElement.label)) {
      toast.error(t('sharedList.designer.elements.addElement.alreadyExists'));
      return;
    }
    const newSharedListElements = [...sharedListElements];

    switch (newElementType) {
      case SharedListType.Simple:
        console.log(completeLabel);
        console.log(`${completeLabel !== undefined ? completeLabel + '\\' : ''}${newElement.label}`);
        newSharedListElements.push({
          value: newElement.value,
          label: `${completeLabel !== undefined ? completeLabel + '\\' : ''}${newElement.label}`,
        });
        break;
      case SharedListType.Hierarchical:
        // console.log(`${completeLabel !== undefined ? completeLabel + '\\' : ''}${newElement.label}\\${subLabel}`)
        newSharedListElements.push({
          value: newElement.value,
          label: `${completeLabel !== undefined ? completeLabel + '\\' : ''}${newElement.label}\\${subLabel}`,
        });
        break;
      default:
        const exhaustiveCheck: never = newElementType;
        throw new Error(`Unknown Shared List Type: ${exhaustiveCheck}`);
    }
    setSharedListElements(newSharedListElements);
    setOpen(false);
  };

  const handleClose = () => {
    setOpen(false);
    setAddElementLabel(undefined);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <Box
        sx={{
          paddingBottom: '20px',
          paddingX: '30px',
          display: 'flex',
          width: '350px',
          flexDirection: 'column',
          alignContent: 'center',
          h2: {
            alignSelf: 'center',
          },
        }}
      >
        <h2>{t('sharedList.designer.elements.addElement.title')}</h2>

        <QCTextInput
          label={t('sharedList.designer.elements.addElement.label')}
          value={newElement.label}
          dispatch={(value: string) => setNewElement((curr) => ({ ...curr, label: value }))}
        />

        {/* Affiché si type hiérarchique sélectionné */}
        <Collapse in={newElementType === SharedListType.Hierarchical}>
          <QCTextInput label="Label du sous élément" dispatch={setSubLabel} value={subLabel} />
        </Collapse>

        <QCTextInput
          label={t('sharedList.designer.elements.addElement.value')}
          value={newElement.value}
          dispatch={(value: string) => setNewElement((curr) => ({ ...curr, value: value }))}
        />

        {sharedListType === SharedListType.Hierarchical && (
          <QCSwitchSelect
            label={t('sharedList.sharedLists.table.type')}
            text1={t('sharedList.designer.form.type.simple')}
            text2={t('sharedList.designer.form.type.hierarchical')}
            value1={`${SharedListType.Simple}`}
            value2={`${SharedListType.Hierarchical}`}
            dispatch={(value: string) => {
              const newChoice = parseInt(value);
              if (newChoice !== SharedListType.Simple && newChoice !== SharedListType.Hierarchical) return;
              setNewElementType(newChoice);
            }}
            variant="outlined"
            option={`${newElementType}`}
            sx={{
              // marginY: '7px',
              ' .innerBox': {
                height: '50px',
              },
            }}
          />
        )}

        <QCButtonGlobal
          action={handleAddElement}
          variant="contained"
          value={t('sharedList.designer.elements.addData.confirm')}
          className="confirm-shared-list-data-type"
          sx={{ width: '70%', alignSelf: 'center', marginTop: '20px' }}
        />
      </Box>
    </Dialog>
  );
};

export default AddElementDialog;
