import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { AxiosError, AxiosProgressEvent, AxiosRequestConfig } from 'axios';
import { InputFileType } from '../ResourceSettings/documents/types';
import { DOWNLOAD_MANAGER_MAXIMUN_PROGRESS_VALUE } from '../ResourceSettings/documents/const';
import { EquipmentIndexFilesRequest, StorageDocRequest } from './requests';
import { StoreArrayIndexesResponse } from './response';
import ApiResource from '40.quickConnect.DataAcces/siteAdmin/apiRoutes/resources';
import QCHttpRequest from '40.quickConnect.DataAcces/siteAdmin/QCHttpRequest';
import { BaseResponse } from '40.quickConnect.DataAcces/siteAdmin/services/baseResponses';
import { CommonFileState } from '90.quickConnect.Models/siteAdmin/enums/commonFileState';

const useDocServices = () => {
  const { t } = useTranslation();
  const requestHandler = useMemo(() => new QCHttpRequest(), []);

  const sendDocsRequest = useCallback(
    async (request: EquipmentIndexFilesRequest) => {
      try {
        const response = await requestHandler.post<StoreArrayIndexesResponse, EquipmentIndexFilesRequest>(
          ApiResource.indexFiles,
          request,
        );
        if (response.status === 200) return response.data;
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      }
    },
    [requestHandler],
  );

  const deleteDocsRequest = useCallback(
    async (docUri: string, docTitle: string) => {
      try {
        const response = await requestHandler.delete(ApiResource.deleteDocuments, { params: { documentUri: docUri } });
        if (response.status === 200) {
          if (response.data.status === 0) {
            toast.success(t('shared.downloadManager.popup.deleteFile.success', { fileTitle: docTitle }));
            return response.data;
          } else toast.error(response.data.message);
        }
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
      }
    },
    [t, requestHandler],
  );

  const uploadDoc = useCallback(
    async (
      request: StorageDocRequest,
      storageUri: string,
      file: InputFileType,
      setInputFiles: Dispatch<SetStateAction<InputFileType[]>>,
    ) => {
      const config: AxiosRequestConfig = {
        onUploadProgress: function (progressEvent: AxiosProgressEvent) {
          if (progressEvent.progress) {
            const percentCompleted = Math.round(progressEvent.progress * 100);
            if (percentCompleted === DOWNLOAD_MANAGER_MAXIMUN_PROGRESS_VALUE) {
              setInputFiles((prev) => {
                const changed = [...prev];
                changed.forEach((f) => {
                  if (f.id === file.id) f.state = CommonFileState.processing;
                });
                return changed;
              });
            }
          }
        },
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      try {
        const response = await requestHandler.post<BaseResponse, StorageDocRequest>(storageUri, request, config);
        if (response.status === 200) return response.data;
      } catch (error) {
        const err = error as AxiosError;
        toast.error(err.message);
        setInputFiles((prev) => {
          const changed = [...prev];
          changed.forEach((f) => {
            if (f.id === file.id) f.state = CommonFileState.loadingFailed;
          });
          return changed;
        });
      }
    },
    [requestHandler],
  );

  return {
    sendDocsRequest,
    deleteDocsRequest,
    uploadDoc,
  };
};

export default useDocServices;
