import { LinkRouteType } from '../types';

export const isPathInRoutes = (routes: LinkRouteType[], path: string): boolean => {
  for (const route of routes) {
    if (route.path === path || route.path + '/' === path) {
      return true;
    }

    if (/:[a-zA-Z]+/g.exec(route.path ?? '')) {
      const regex = /^(?<url>\/[a-zA-Z/]+):?/g.exec(route.path ?? '');
      if (regex?.groups?.url) {
        const { url } = regex.groups;

        const regexUrl = new RegExp(`${url}`);
        const regexToFind = new RegExp(regexUrl.source + '[a-zA-Z0-9-]+');

        if (path.match(regexToFind)) {
          // console.log('matched : ' + path);
          return true;
        }
      }
    }
  }
  return false;
};
