import React, { ChangeEvent, useState } from 'react';
import { Box, IconButton, useTheme } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useTranslation } from 'react-i18next';
import { SearchBarProps } from './type';
import { pureWhiteColor } from '00.app/theme/colors';

const SearchBar = ({ dispatch }: SearchBarProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const [searchText, setSearchText] = useState<string>('');

  const handleClick = () => {
    console.log(searchText);
    dispatch(searchText);
  };

  // const handleKeyUp = (event: KeyboardEvent): void => {
  //   if (event.key === 'Enter') handleClick();
  // };

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
    dispatch(event.target.value);
  };

  return (
    <Box
      sx={{
        backgroundColor: pureWhiteColor,
        ' > input': {
          marginLeft: '0.75vw',
          border: 'none',
          backgroundColor: 'inherit',
          width: '100%',
        },
        '& > input:focus': {
          outline: 'none',
        },
        '& > input::placeholder': {
          color: theme.palette.secondary.main,
        },
        border: 'solid 2px',
        borderRadius: '8px',
        borderColor: theme.palette.secondary.main,

        display: 'flex',
        justifyContent: 'space-between',
        marginY: '5px',
        padding: '2px',
      }}
    >
      <input
        type="text"
        placeholder={t('sharedList.dialog.form.tooltips.search')}
        value={searchText}
        onChange={handleChange}
      />
      <IconButton onClick={handleClick}>
        <SearchIcon
          sx={{
            color: theme.palette.secondary.main,
          }}
        />
      </IconButton>
    </Box>
  );
};

export default SearchBar;
