import React from 'react';
import { useTranslation } from 'react-i18next';
import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/material';
import { DocMultiplesActions, DocRowActions } from './const';
import { ResDocumentsProps } from './types';
import useData from './hooks';
import { actionsToChoiceMenuOption } from './helpers';
import { docBoxStyle } from './styles';
import DataTable from '70.quickConnect.Components/siteAdmin/Other/DataTable';
import QCButtonGlobal from '70.quickConnect.Components/siteAdmin/Buttons/QCButtonGlobal';
import DeleteConfirmationDialog from '70.quickConnect.Components/siteAdmin/Other/DeleteConfirmationDialog';

const ResDocuments = ({ documents }: ResDocumentsProps) => {
  const { t } = useTranslation();
  const {
    columns,
    rows,
    setSearchValue,
    setRowAction,
    setRowsAction,
    openDeleteDialog,
    deleteDispatch,
    FilesInput,
    handleFilesInput,
    uploadFiles,
  } = useData(documents ?? []);

  return (
    <Box sx={docBoxStyle}>
      <input ref={FilesInput} type="file" multiple onChange={uploadFiles} hidden />
      <DeleteConfirmationDialog open={openDeleteDialog} dispatch={deleteDispatch} />
      <DataTable
        title={t('entityData.editor.designInstance.documentTitle')}
        rowActions={actionsToChoiceMenuOption([DocRowActions.Delete, DocRowActions.Send, DocRowActions.View])}
        onSelectRowAction={setRowAction}
        multipleActions={actionsToChoiceMenuOption([DocMultiplesActions.Delete, DocMultiplesActions.Send])}
        onSelectMultipleAction={setRowsAction}
        columns={columns}
        rows={rows}
        getSearchValue={setSearchValue}
        renderAfterTitle={
          <QCButtonGlobal
            className="add-documents"
            startIcon={<AddIcon />}
            value={t('shared.downloadManager.form.actions.addFile')}
            action={handleFilesInput}
            datacy="add-documents"
          />
        }
        pageSize={2}
      />
    </Box>
  );
};

export default ResDocuments;
