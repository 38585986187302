/* eslint-disable */
import dayjs from 'dayjs';
import { createContext } from 'react';
import { AllDataElementType, DataValueType } from './types';
/**
 * @typedef QCDialogFormContext
 * @param {number} activeStep Indice de l'étape courante
 * @param {number} steps Nombre Total d'étape de la dialog
 * @param {action} nextStep Permet d'incrémenter de 1 l'activeStep
 * @param {action} prevStep Permet de décrémenter de 1 l'activeStep
 * @param {action} toogleIsOpen Permet de rendre la dialog visible si value=true et non-visible si value=false
 * @param {action} handleChange Fonction permettant le traitement des données récupérées dans la dialog lors de sa complétion
 * @param {object[]} allData Tableau contenant toutes les données récupérée dans la dialog
 * @param {action} setAllData Fonction permettant d'écrire la valeur de allData
 * @param {action} updateAllDataArray Fonction permettant d'actualiser la valeur de allData
 * @param {action} addFieldAllDataArray Fonction permettant d'ajouter un champ / une étape dans allData
 * **/

export const QCDialogFormContext = createContext({
  activeStep: 0,
  steps: 0,
  nextStep: () => {},
  prevStep: () => {},
  setActiveStep: (value: number) => {},
  toogleIsOpen: (value: boolean) => {},
  handleConfirm: (async (value: AllDataElementType[]) => false) as (value: AllDataElementType[]) => Promise<boolean>,
  allData: [{ step: 1, data: [{ label: '', value: '' as string | dayjs.Dayjs }] }] as AllDataElementType[],
  setAllData: (state: AllDataElementType[]) => {},
  updateAllDataArray: (label: string, data: DataValueType, index: number) => {},
  addFieldAllDataArray: (label: string, data: DataValueType, index: number) => {},
  resetAllDataArray: () => {},
  deleteFieldAllDataArray: (label: string, index: number) => {},
});
