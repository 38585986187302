import React, { Dispatch, SetStateAction, useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { OnChange } from '@monaco-editor/react';
import { Box, List, useTheme } from '@mui/material';
import CodeIcon from '@mui/icons-material/Code';
import CodeOffIcon from '@mui/icons-material/CodeOff';
import TranslateIcon from '@mui/icons-material/Translate';
import SharedListSettingsContext from '../SharedListSettingsContext';
import { SharedListElementsContainer } from '../styles';
import HierarchicalItem from './HierarchicalItem';
import SimpleSharedListElement from './SimpleSharedListElement';
import SearchBar from '70.quickConnect.Components/siteAdmin/Inputs/SearchBar';
import QCButtonGlobal from '70.quickConnect.Components/siteAdmin/Buttons/QCButtonGlobal';
import QCIconButton from '70.quickConnect.Components/siteAdmin/Buttons/QCIconButton';
import { elementsToText, textToElements } from '80.quickConnect.core/siteAdmin/helpers/sharedListData';
import QCCodeInput from '70.quickConnect.Components/siteAdmin/Inputs/QCCodeInput';
import { SharedListDataType, SharedListType } from '90.quickConnect.Models/siteAdmin/enums/sharedList/sharedListType';
import mapHierarchicalChoices from '90.quickConnect.Models/siteAdmin/models/sharedList/mapHierarchicalChoice';
import { useSafeNavigation } from '80.quickConnect.core/siteAdmin/hooks/useSafeNavigation';
import { SharedListData } from '90.quickConnect.Models/siteAdmin/models/sharedList/sharedList';
import { HierarchicalChoice } from '90.quickConnect.Models/siteAdmin/models/sharedList/choice';

const SharedListElementsList = ({ filter }: { filter: string }) => {
  const { sharedListElements } = useContext(SharedListSettingsContext);

  const filterFunc = (el: SharedListData) =>
    el.label.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) ||
    el.value.toLocaleLowerCase().includes(filter.toLocaleLowerCase());

  return (
    <List>
      {sharedListElements.filter(filterFunc).map((el) => (
        <SimpleSharedListElement key={el.label} label={el.label}></SimpleSharedListElement>
      ))}
    </List>
  );
};

const SharedListHierarchicalElementsList = ({ filter }: { filter: string }) => {
  const { sharedListElements } = useContext(SharedListSettingsContext);
  // const hierarchicalsElements = elementsToHierarchicalElements(sharedListElements);
  const hierarchicalsElements = mapHierarchicalChoices(sharedListElements);

  const filterFunc = (el: HierarchicalChoice) =>
    el.label.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) ||
    el.value.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) ||
    el.hierarchicalLabel.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) ||
    el.hierarchicalNodePath.toLocaleLowerCase().includes(filter.toLocaleLowerCase()) ||
    el.children.find((child) => child.label.includes(filter));

  return (
    <List sx={{ paddingRight: 0 }}>
      {hierarchicalsElements
        .filter(filterFunc)
        .map((el) =>
          el.children.length > 0 ? (
            <HierarchicalItem key={el.hierarchicalNodePath + el.hierarchicalLabel} hierarchicalChoice={el} />
          ) : (
            <SimpleSharedListElement key={el.label} label={el.label} />
          ),
        )}
    </List>
  );
};

const SharedListElementsCode = ({ setCodeOpen }: { setCodeOpen: Dispatch<SetStateAction<boolean>> }) => {
  const { t } = useTranslation();
  const { sharedListElements, setSharedListElements, dataType } = useContext(SharedListSettingsContext);

  const codeDefaultValue = useMemo(() => elementsToText(sharedListElements), [sharedListElements]);

  const codeValue = useRef<string>();

  useEffect(() => {
    return () => {
      // si on ferme l'éditeur de code et qu'il y a eu une modification
      if (codeValue.current !== undefined) {
        try {
          const newElements = textToElements(codeValue.current, dataType ?? SharedListDataType.Json);
          setSharedListElements(newElements);
        } catch {
          setCodeOpen(true);
          toast.error(t('sharedList.designer.popup.save.warning'));
        }
      }
    };
  });

  const handleCodeChange: OnChange = (value) => {
    if (value) codeValue.current = value;
  };

  return (
    <Box sx={{ position: 'relative', marginTop: '20px' }}>
      <QCCodeInput
        defaultLanguage="txt"
        defaultValue={codeValue.current ?? codeDefaultValue}
        onChange={handleCodeChange}
        height="30vh"
      />
    </Box>
  );
};

const SharedListElements = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const navigate = useSafeNavigation();

  const { sharedListType, setOpenAddElementDialog, initialToBeTranslated } = useContext(SharedListSettingsContext);

  const [codeOn, setCodeOn] = useState(false);

  const [searchQuery, setSearchQuery] = useState('');

  return (
    <Box sx={SharedListElementsContainer(theme)}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', h2: { margin: 0 } }}>
        <h2>{t('sharedList.designer.elements.title')}</h2>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <QCIconButton
            Icon={
              codeOn ? (
                <CodeOffIcon sx={{ color: theme.palette.primary.main }} />
              ) : (
                <CodeIcon sx={{ color: theme.palette.primary.main }} />
              )
            }
            onClickFunc={() => {
              setCodeOn((curr) => !curr);
            }}
            variantValue="outlined"
          />
          {initialToBeTranslated && (
            <QCIconButton
              Icon={<TranslateIcon sx={{ color: theme.palette.primary.main }} />}
              onClickFunc={() => {
                navigate('translation');
              }}
              variantValue="outlined"
            />
          )}
        </Box>
      </Box>
      <Box sx={{ display: 'flex', gap: 2 }}>
        <Box sx={{ width: '45%' }}>
          <SearchBar dispatch={(value: string) => setSearchQuery(value)} />
        </Box>
        {!codeOn && (
          <QCButtonGlobal
            value={t('sharedList.designer.elements.addElement.title')}
            action={() => setOpenAddElementDialog(true)}
            className="add-shared-list-element"
            sx={{ height: '', alignSelf: 'center' }}
          />
        )}
      </Box>

      {codeOn ? (
        <SharedListElementsCode setCodeOpen={setCodeOn} />
      ) : sharedListType === SharedListType.Simple ? (
        <SharedListElementsList filter={searchQuery} />
      ) : (
        <SharedListHierarchicalElementsList filter={searchQuery} />
      )}
    </Box>
  );
};

export default SharedListElements;
