import { useStore } from '30.quickConnect.Store/siteAdmin';

const useData = () => {
  const {
    SubMenuStore: {
      isVisible,
      data,
      rows,
      subMenuPath,
      rowActions,
      setSelectedRowAction,
      setSelectedRowsMultipleAction,
      sortingTypes,
      multipleActions,
      addIcon,
      handleAddClick,
      addText,
      setAddIcon,
      setHandleAddClick,
      setSortingTypes,
      setAddText,
      setMultipleActions,
      setRowsBySearch,
      initialRows,
    },
    SideMenuStore: { isOpenSideMenu },
  } = useStore();

  const getSearchValue = (value: string) => {
    setRowsBySearch(initialRows.filter((elem) => elem.name.toLocaleLowerCase().includes(value.toLocaleLowerCase())));
  };

  return {
    isVisible,
    data,
    isOpenSideMenu,
    rows,
    subMenuPath,
    rowActions,
    setSelectedRowAction,
    setSelectedRowsMultipleAction,
    sortingTypes,
    multipleActions,
    addIcon,
    handleAddClick,
    addText,
    setAddIcon,
    setHandleAddClick,
    setSortingTypes,
    setAddText,
    setMultipleActions,
    getSearchValue,
  };
};

export default useData;
