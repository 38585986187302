import React, { useCallback, useEffect, useState } from 'react';
import { AuthMethod, Organization } from '../types';
import { useServices } from './services';
import { IdentityProviders } from '90.quickConnect.Models/siteAdmin/enums/user/identityProviders';

export const useData = (open: boolean) => {
  const { getCreateUserData } = useServices();

  const [optionsAuthMethod, setOptionsAuthMethod] = useState<AuthMethod[]>([]);
  const [optionsOrgs, setOptionsOrgs] = useState<Organization[]>([]);

  const [isAzureAD, setIsAzureAD] = React.useState<boolean>(false);
  const handleChangeAuthMethod = (value: string) => {
    const azureAdIndex = optionsAuthMethod.findIndex(
      (method) => method.providerValue == IdentityProviders.AzureActiveDirectory,
    );
    if (value === optionsAuthMethod[azureAdIndex].name) {
      setIsAzureAD(true);
    } else {
      setIsAzureAD(false);
    }
  };

  const getOptions = useCallback(async () => {
    const result = await getCreateUserData();
    if (result) {
      setOptionsAuthMethod(
        result.availableAuthMethods.map((option) => {
          return {
            name: option.value,
            providerValue: option.key,
          } as AuthMethod;
        }),
      );
      const organizations: Organization[] = result.availableOrganizationalUnits.map((option) => {
        return {
          id: option.id,
          name: option.name,
          parentId: option.parentId,
          parentCode: option.parentCode,
          parentName: option.parentName,
          code: option.code,
          description: option.description,
          address: option.address,
          zipCode: option.zipCode,
          town: option.town,
          country: option.country,
          siret: option.siret,
          email: option.email,
          logo: option.logo,
          companionQCId: option.companionQCId,
          correlationId: option.correlationId,
          isActive: option.isActive,
          isHeadQuarter: option.isHeadQuarter,
          level: option.level,
        } as Organization;
      });
      setOptionsOrgs(organizations);
    }
  }, [getCreateUserData]);

  useEffect(() => {
    if (open) getOptions();
  }, [getOptions, open]);

  return {
    isAzureAD,
    optionsAuthMethod,
    optionsOrgs,
    handleChangeAuthMethod,
  };
};
