import { SxProps, useTheme } from '@mui/system';
import { grey6, pureWhiteColor } from '00.app/theme/colors';

export const HeaderMainStyle = () => {
  const theme = useTheme();
  return {
    width: '100%',
    // height: '100px',
    marginTop: '10px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    pr: '90px',
    gap: '30px',
    lineHeight: '21px',
    '& .MuiSvgIcon-root': {
      fill: `${theme.palette.mode === 'light' ? grey6 : pureWhiteColor} !important`,
      fontSize: '1.6rem !important',
    },
    '*:not(.MuiSvgIcon-root)': {
      fontSize: '1.1rem !important',
      color: `${theme.palette.mode === 'light' ? grey6 : pureWhiteColor} !important`,
    },
    '*:hover': {
      backgroundColor: '#00000000',
    },
  } as SxProps;
};
