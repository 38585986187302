import React, { cloneElement } from 'react';
import { Box, List, Stack, Typography, Button, IconButton, Collapse, Chip } from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import { WidgetElementsDef } from './const';
import { useHomeWidgetData } from './hooks';
import { headerStyle, ListContainerStyle } from './styles';
import { HomeWidgetProps, isWidgetType } from './types';
import WidgetListItem from './WidgetListItem';
import { isAlertPreview } from '10.quickConnect.app/siteAdmin/components/domains/Home/services/types';
import QCChoiceMenu from '70.quickConnect.Components/siteAdmin/Inputs/QCChoiceMenu';
import { toReadableDate } from '80.quickConnect.core/siteAdmin/helpers/toReadableDate';

const HomeWidget = ({ defaultType, data, widgetIndex }: HomeWidgetProps) => {
  const {
    t,
    theme,
    readFormType,
    drawFormType,
    readFormPublishedState,
    openWidgetTypeSelect,
    selectAnchorEl,
    setSelectAnchorEl,
    selectedWidgetType,
    setSelectedWidgetType,
    handleWidgetChange,
    handleSeeMoreClick,
    listDivider,
  } = useHomeWidgetData(defaultType, widgetIndex);
  return (
    <Box width={'36vw'} minWidth="425px">
      <Box sx={headerStyle(theme)}>
        <Stack direction="row" alignItems="center" alignContent="center" gap={1}>
          {cloneElement(WidgetElementsDef[selectedWidgetType].icon, { fontSize: 'large' })}
          <h2>{t(WidgetElementsDef[selectedWidgetType].title)}</h2>
          <IconButton onClick={handleWidgetChange} sx={{ color: 'inherit', marginLeft: '-0.5rem' }}>
            <KeyboardArrowDownIcon fontSize="large" />
          </IconButton>
        </Stack>

        <Button onClick={handleSeeMoreClick} sx={{ color: 'inherit' }}>
          <Typography textTransform="uppercase" fontWeight={500}>
            {t('home.widget.more')}
          </Typography>
        </Button>
      </Box>
      <Box sx={ListContainerStyle(theme)}>
        <Collapse in={openWidgetTypeSelect}>
          <QCChoiceMenu
            anchorElState={[selectAnchorEl, setSelectAnchorEl]}
            options={Object.values(WidgetElementsDef).map((widgetEl) => ({
              text: t(widgetEl.title),
              value: widgetEl.title.split('.')[2],
            }))}
            dispatch={(newValue: string) => {
              const newValueString = newValue as string;
              if (isWidgetType(newValueString)) setSelectedWidgetType(newValueString);
            }}
          />
        </Collapse>
        <List sx={{ paddingX: '10px' }}>
          {(() => {
            switch (selectedWidgetType) {
              case 'forms':
                return data.formsPreview.map((form, index) => {
                  return 'name' in form ? (
                    <div key={form.id}>
                      <WidgetListItem
                        key={form.id}
                        id={form.id}
                        title={form.name}
                        descriptionFirst={readFormType(form.formType)}
                        descriptionSecond={
                          ' - ' +
                          toReadableDate(form.lastModificationDate) +
                          ' - ' +
                          readFormPublishedState(form.published)
                        }
                        startIcon={drawFormType(form.formType)}
                      />
                      {listDivider(index, data.formsPreview.length)}
                    </div>
                  ) : (
                    <></>
                  );
                });
              case 'declarations':
                return data.declarationsPreview.map((decl, index) => {
                  return 'stringExtract' in decl ? (
                    <div key={decl.id}>
                      <WidgetListItem
                        key={decl.id}
                        id={decl.id}
                        title={decl.stringExtract}
                        descriptionFirst={readFormType(decl.formType)}
                        descriptionSecond={' - ' + toReadableDate(decl.editedAt)}
                        startIcon={drawFormType(decl.formType)}
                      />
                      {listDivider(index, data.declarationsPreview.length)}{' '}
                    </div>
                  ) : (
                    <></>
                  );
                });
              case 'importsexports':
                return data.jobsPreview.map((job, index) => {
                  return 'title' in job ? (
                    <div key={job.id}>
                      <WidgetListItem
                        key={job.id}
                        id={job.id}
                        title={job.title}
                        descriptionFirst={job.contentType}
                        descriptionSecond={
                          ' - ' + t('home.widget.jobs.expire') + ' ' + toReadableDate(job.expirationDate)
                        }
                        endIcon={<UploadOutlinedIcon color="finishedExportsIconColor" />}
                      />
                      {listDivider(index, data.jobsPreview.length)}
                    </div>
                  ) : (
                    <></>
                  );
                });
              case 'alerts':
                return data.alertsPreview.map((alert, index) => {
                  return isAlertPreview(alert) ? (
                    <div key={alert.id}>
                      <WidgetListItem
                        key={alert.id}
                        id={alert.id}
                        title={alert.stringExtract}
                        descriptionFirst={readFormType(alert.formType)}
                        descriptionSecond={' - ' + toReadableDate(alert.editedAt)}
                        startIcon={drawFormType(alert.formType)}
                        endIcon={
                          <Chip
                            color={'backgroundColorAlerteChip'}
                            label={t('alerts.tags.alert')}
                            size="small"
                            sx={{ textTransform: 'capitalize' }}
                          />
                        }
                      />
                      {listDivider(index, data.alertsPreview.length)}
                    </div>
                  ) : (
                    <></>
                  );
                });

              default:
                return <h5>Not Implemented</h5>;
            }
          })()}
        </List>
      </Box>
    </Box>
  );
};

export default HomeWidget;
