import { SxProps, Theme } from '@mui/material';

export const DataTableContainerStyle = (theme: Theme): SxProps => ({
  display: 'flex',
  flexDirection: 'column',
  flex: 1,
  width: '100%',
  height: '100%',
  // marginY: '2vh',
  paddingLeft: '1vw',
  paddingRight: '2vw',
  paddingY: '2vh',
  border: 'solid 2px',
  borderRadius: '8px',
  borderColor: theme.palette.secondary.main,
  backgroundColor: theme.palette.background.default,
});

export const TitleAndRenderCompStyle: SxProps = {
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  justifyContent: 'space-between',
};

export const TitleStyle: SxProps = {
  marginY: '2vh',
  display: 'flex',
  flexGrow: 1,
  justifyContent: 'space-between',
  '& > h2': {
    verticalAlign: 'middle',
    display: 'inline-flex',
    marginY: '0',
    paddingY: '0',
    lineHeight: '1em',
  },
};
