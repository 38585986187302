import React, { useContext, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Dialog } from '@mui/material';
import { observer } from 'mobx-react';
import SharedListSettingsContext from '../../SharedListSettingsContext';
import { AddDataDialogProps } from './types';
import QCSwitchSelect from '70.quickConnect.Components/siteAdmin/Inputs/QCSwitchSelect';
import { SharedListDataType } from '90.quickConnect.Models/siteAdmin/enums/sharedList/sharedListType';
import QCButtonGlobal from '70.quickConnect.Components/siteAdmin/Buttons/QCButtonGlobal';
import QCTextInput from '70.quickConnect.Components/siteAdmin/Inputs/QCTextInput';
import { parseCsvSharedListData } from '80.quickConnect.core/siteAdmin/helpers/sharedListData';
import { JsonObject, SharedListData } from '90.quickConnect.Models/siteAdmin/models/sharedList/sharedList';

const AddDataDialog = ({ open, setOpen, elementLabel }: AddDataDialogProps) => {
  const { t } = useTranslation();
  const { sharedListElements, setSharedListElements, dataType, setDataType } = useContext(SharedListSettingsContext);

  const [dataTypeChoice, setDataTypeChoice] = useState<'Json' | 'Csv'>('Csv');
  // pour forcer le rerender
  const [hasChanged, setHasChanged] = useState(false);

  // data à ajouter pour csv
  const [csvNewData, setCsvNewData] = useState('');
  //data à ajouter pour json
  const [jsonNewData, setJsonNewData] = useState<{ key: string; value: string }>({ key: '', value: '' });

  const element = useMemo(
    () => sharedListElements.find((el) => el.label === elementLabel),
    [elementLabel, sharedListElements],
  );
  if (!element) {
    return <></>;
  }

  const handleDataTypeChoice = (choice: string) => {
    if (choice !== 'Json' && choice !== 'Csv') throw new Error('AddDataDialog: Unknonw type choice');
    setDataTypeChoice(choice);
  };

  const handleDataTypeConfirm = () => {
    if (dataTypeChoice === 'Csv') setDataType(SharedListDataType.Csv);
    else if (dataTypeChoice === 'Json') setDataType(SharedListDataType.Json);
    else throw new Error('AddDataDialog: Invalid datatype option');
    setHasChanged(!hasChanged);
  };

  const handleAddDataConfirm = () => {
    const newElements: SharedListData[] = [...sharedListElements];
    switch (dataType) {
      case SharedListDataType.Csv:
        console.log('csv');
        if (csvNewData === '') break;
        if (element.data) {
          if (typeof element.data !== 'string') throw new Error('SharedListDataType should be string');
          const values = parseCsvSharedListData(element.data);
          values.push(csvNewData);
          // TODO : prendre en compte d'autres séparateurs
          element.data = values.join(',');
        } else {
          element.data = csvNewData;
        }
        break;
      case SharedListDataType.Json:
        console.log('json');
        if (jsonNewData.key === '' || jsonNewData.value === '') break;
        if (element.data) {
          if (typeof element.data === 'string') throw new Error('AddDataDialog: SharedList should be JSON');
          element.data[jsonNewData.key] = jsonNewData.value;
        } else {
          const values: JsonObject = {};
          values[jsonNewData.key] = jsonNewData.value;
          console.log(values);
          element.data = values;
          console.log(element.data);
        }
    }

    const indexToChange = newElements.findIndex((el) => el.label === element.label);
    newElements[indexToChange] = element;
    setSharedListElements(newElements);
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)}>
      {dataType === undefined ? (
        <Box
          sx={{
            paddingBottom: '20px',
            paddingX: '30px',
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
          }}
        >
          <h2>{t('sharedList.designer.elements.addData.chooseType.title')}</h2>
          <QCSwitchSelect
            text1="Csv"
            text2="Json"
            value1="Csv"
            value2="Json"
            dispatch={handleDataTypeChoice}
            variant="outlined"
            option={dataTypeChoice}
          />
          <QCButtonGlobal
            action={handleDataTypeConfirm}
            variant="contained"
            value={t('sharedList.designer.elements.addData.confirm')}
            className="confirm-shared-list-data-type"
            sx={{ width: '70%', alignSelf: 'center', marginTop: '20px' }}
          />
        </Box>
      ) : (
        <Box
          sx={{
            paddingBottom: '20px',
            paddingX: '30px',
            display: 'flex',
            flexDirection: 'column',
            alignContent: 'center',
          }}
        >
          <h2>{t('sharedList.designer.elements.addData.title')}</h2>

          {dataType === SharedListDataType.Json ? (
            <>
              <QCTextInput
                label={t('sharedList.designer.elements.addData.key')}
                value={jsonNewData.key}
                dispatch={(value: string) => setJsonNewData((curr) => ({ ...curr, key: value }))}
              />
              <QCTextInput
                label={t('sharedList.designer.elements.addData.value')}
                value={jsonNewData.value}
                dispatch={(value: string) => setJsonNewData((curr) => ({ ...curr, value: value }))}
              />
            </>
          ) : (
            <>
              <QCTextInput
                label={t('sharedList.designer.elements.addData.value')}
                value={csvNewData}
                dispatch={setCsvNewData}
              />
            </>
          )}
          <QCButtonGlobal
            action={handleAddDataConfirm}
            variant="contained"
            value={t('sharedList.designer.elements.addData.confirm')}
            className="confirm-shared-list-data-type"
            sx={{ width: '70%', alignSelf: 'center', marginTop: '20px' }}
          />
        </Box>
      )}
    </Dialog>
  );
};

export default observer(AddDataDialog);
