import { TreeViewNode } from '70.quickConnect.Components/siteAdmin/Other/TreeView/types';
import { Family } from '90.quickConnect.Models/siteAdmin/models/families/family';

export const familiesToTreeViewNodes = (initialList: Family[]) => {
  const layers: TreeViewNode[][] = [[]];

  initialList.forEach((family) => {
    // console.log(family.parentId);
    if (family.parentId === null || family.parentId === undefined) {
      layers[0].push({
        id: family.id,
        name: family.name,
        children: [],
        parentId: '',
        parentName: '',
      });
    } else {
      for (let i = 0; i < layers.length; i++) {
        const index = layers[i].findIndex((el) => el.id === family.parentId);
        if (index !== -1) {
          if (layers[i + 1] === undefined) layers[i + 1] = [];
          layers[i + 1].push({
            id: family.id,
            name: family.name,
            children: [],
            parentId: family.parentId,
            parentName: family.parentId,
          });
        }
      }
    }
  });

  layers.forEach((layer, index) => {
    if (index >= layers.length - 1) return;
    layer.forEach((node, nodeIndex) => {
      const children: TreeViewNode[] = [];
      layers[index + 1].forEach((subNode) => {
        if (subNode.parentId === node.id) children.push(subNode);
      });
      layers[index][nodeIndex].children = children;
    });
  });

  return layers[0];
};
