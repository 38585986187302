import { Box } from '@mui/system';
import React from 'react';
import QCDialogForm from '..';
import { dialogFormBodyStyle } from '../style';
import { BodyContext } from './context';
import useData from './hook';
import { StepBodyProps } from './type';

const QCStepBody = ({ children, title, desc, displayStepping, nbColumn, index }: StepBodyProps) => {
  const {
    mandatoryList,
    addMandatoryField,
    deleteMandatoryField,
    setFilled,
    isAllMandatoryFieldsFilled,
    controlList,
    addControlledField,
    setValid,
    isAllControlledFieldValid,
  } = useData();

  return (
    <BodyContext.Provider
      value={{
        mandatoryList: mandatoryList,
        addMandatoryField: addMandatoryField,
        deleteMandatoryField: deleteMandatoryField,
        setFilled: setFilled,
        isAllMandatoryFieldsFilled: isAllMandatoryFieldsFilled,
        index: index,
        controlList: controlList,
        addControlledField: addControlledField,
        setValid: setValid,
        isAllControlledFieldValid: isAllControlledFieldValid,
      }}
    >
      <>
        <Box sx={{ width: '100%' }}>
          <QCDialogForm.Header desc={desc} title={title} />
          <Box sx={dialogFormBodyStyle(nbColumn)} data-cy={`dialog-form-body-${title}`}>
            {children}
          </Box>
          <QCDialogForm.Footer displayStepping={displayStepping} index={index} />
        </Box>
      </>
    </BodyContext.Provider>
  );
};

export default QCStepBody;
