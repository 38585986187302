import axios, { AxiosError } from 'axios';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { defaultGetOrganizationalUnitListResponse, defaultOrganization } from '../const';
import { GetOrganizationalUnitListResponse, Organization } from '../types';
import {
  CreateOrganizationRequest,
  DeleteOrganizationRequest,
  GetOrganizationRequest,
  UpdateOrganizationRequest,
} from './requests';
import { DeleteOrganizationResponse, OrganizationResponse } from './responses';
import QCHttpRequest from '40.quickConnect.DataAcces/siteAdmin/QCHttpRequest';
import { ApiOrganization } from '40.quickConnect.DataAcces/siteAdmin/apiRoutes';
import { isResponseValid } from '40.quickConnect.DataAcces/siteAdmin/responseValidator';

const useOrganizationServices = () => {
  const { t } = useTranslation();

  const getOrganizationalUnitListRequest = useCallback(async () => {
    const requestHandler = new QCHttpRequest();
    const request = {
      start: 0,
      length: 0,
      orderBy: [
        { direction: 0, column: 'level' },
        { direction: 0, column: 'name' },
      ],
      textFilter: null,
      count: 1,
      filters: [],
      code: null,
      isActiveFilter: true,
    };
    try {
      const result = await requestHandler.post(ApiOrganization.getOrganizationalUnitList, request, {
        withCredentials: true,
      });
      if (result) return result.data as GetOrganizationalUnitListResponse;
    } catch (error) {
      const err = error as AxiosError;
      return err.response?.status;
    }
  }, []);

  const getOrganizationalUnitList = useCallback(async (): Promise<GetOrganizationalUnitListResponse> => {
    let ret: GetOrganizationalUnitListResponse = defaultGetOrganizationalUnitListResponse;

    const post = async () => {
      const result = await getOrganizationalUnitListRequest();
      ret = (result as GetOrganizationalUnitListResponse) ?? defaultGetOrganizationalUnitListResponse;
    };

    await post();
    return ret;
  }, [getOrganizationalUnitListRequest]);

  // eslint-disable-next-line
  const getOrganization = async (request: GetOrganizationRequest): Promise<Organization> => {
    const requestHandler = new QCHttpRequest();

    const response = await requestHandler.post<OrganizationResponse, GetOrganizationRequest>(
      ApiOrganization.getOrganizationalUnit,
      request,
    );

    const isValid = isResponseValid(response.data);

    if (isValid) {
      return response.data.organizationalUnit;
    } else {
      return defaultOrganization;
    }
  };

  const getOrganizationById = async (id: string): Promise<Organization> => {
    const request: GetOrganizationRequest = {
      id: id,
    };

    const organization = await getOrganization(request);

    return organization;
  };

  const createOrganizationalUnit = useCallback(
    async (request: CreateOrganizationRequest): Promise<Organization> => {
      let ret = defaultOrganization;
      const post = async () => {
        const requestHandler = new QCHttpRequest();

        try {
          const response = await requestHandler.post<OrganizationResponse, CreateOrganizationRequest>(
            ApiOrganization.createOrganizationalUnit,
            request,
            { withCredentials: true },
          );
          const isValid = isResponseValid(
            response.data,
            t('organization.designer.popup.save.title') + ` : ${response.data.organizationalUnit.name}`,
          );

          if (isValid) {
            ret = response.data.organizationalUnit;
          }
        } catch (error) {
          toast.error(`${(error as AxiosError<OrganizationResponse, unknown>).response?.data?.message}`);
          ret = defaultOrganization;
        }
      };
      await post();
      return ret;
    },
    [t],
  );

  const updateOrganization = async (request: UpdateOrganizationRequest) => {
    const requestHandler = new QCHttpRequest();

    requestHandler
      .post<OrganizationResponse, UpdateOrganizationRequest>(ApiOrganization.updateOrganizationalUnit, request)
      .then((response) => {
        const isValid = isResponseValid(
          response.data,
          t('organization.designer.popup.save.title') + ` : ${response.data.organizationalUnit.name}`,
          t,
        );

        if (isValid) {
          return response.data.organizationalUnit;
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(`${error.response?.data?.message}`);
      });

    return defaultOrganization;
  };

  const deleteOrganization = async (request: DeleteOrganizationRequest, organizationName: string) => {
    const requestHandler = new QCHttpRequest();

    try {
      const response = await requestHandler.post<DeleteOrganizationResponse, DeleteOrganizationRequest>(
        ApiOrganization.deleteOrganizationalUnit,
        request,
      );

      isResponseValid(
        response.data,
        t('organization.designer.popup.delete.success', { organizationName: organizationName }),
        t,
      );
    } catch (error) {
      if (axios.isAxiosError(error)) {
        toast.error(`${error.response?.data?.message}`);
      }
    }
  };

  return {
    getOrganizationalUnitList,
    getOrganization,
    getOrganizationById,
    createOrganizationalUnit,
    updateOrganization,
    deleteOrganization,
  };
};

export default useOrganizationServices;
