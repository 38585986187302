import React from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react';
import { AddVersionFormProps } from './types';
import { useData } from './hooks';
import QCDialogForm from '70.quickConnect.Components/siteAdmin/Other/QCDialogForm';

const AddVersionDialogForm = ({ open, handleConfirm, toggleIsOpen }: AddVersionFormProps) => {
  const { t } = useTranslation();
  const { compagnonOptions } = useData();
  return (
    <QCDialogForm open={open} handleConfirm={handleConfirm} toggleIsOpen={toggleIsOpen}>
      <QCDialogForm.Body title={t('setting.viewer.actions.add')} index={1} nbColumn={1} displayStepping={false} desc="">
        <QCDialogForm.Text label={t('setting.designer.placeholder.name')} mandatory />
        <QCDialogForm.Text label={t('setting.designer.placeholder.description')} />
        <QCDialogForm.Choice
          label={t('setting.designer.placeholder.companionId')}
          options={compagnonOptions.map((elem) => elem.name)}
        />
        <QCDialogForm.TextArea label={t('setting.designer.placeholder.value')} mandatory />
      </QCDialogForm.Body>
    </QCDialogForm>
  );
};

export default observer(AddVersionDialogForm);
