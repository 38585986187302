import { useTheme } from '@mui/material';
import { useCallback, useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { toast } from 'react-toastify';
import { Version } from '../services/responses';
import useVersionServices from '../services/versions';
import useVersionData from '../hooks';
import {
  DeleteActionType,
  deleteConfirmationReducer,
} from '70.quickConnect.Components/siteAdmin/Other/DeleteConfirmationDialog/type';
import { saveConfirmationReducer } from '70.quickConnect.Components/siteAdmin/Other/SaveConfirmationDialog/type';
import useControls from '90.quickConnect.Models/siteAdmin/inputValidator/controls';
import { useSafeNavigation } from '80.quickConnect.core/siteAdmin/hooks/useSafeNavigation';

export const useData = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { versionId } = useParams();
  const navigate = useSafeNavigation();

  const control = useControls();
  const { getVersionById, updateVersion, deleteVersion } = useVersionServices();
  const { compagnonOptions } = useVersionData();

  const [pageVersion, setPageVersion] = useState<Version | null>(null);
  const [newPageVersion, setNewPageVersion] = useState<Version>();

  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [saveState, saveDispatch] = useReducer(saveConfirmationReducer, { isSaved: false });

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [deleteState, deleteDispatch] = useReducer(deleteConfirmationReducer, { choice: DeleteActionType.Cancel });

  const initVersion = useCallback(async () => {
    console.log(versionId);
    if (!versionId) {
      navigate(-1);
      return;
    }

    const version = await getVersionById(versionId);
    if (version === null) navigate(-1);
    setPageVersion(version);
    setNewPageVersion(version);
    //eslint-disable-next-line
  }, [versionId]);

  const handleSave = () => {
    setOpenSaveDialog(() => {
      let error = false;
      if (newPageVersion?.name === '') {
        toast.error(t('control.input.notnull.field', { field: t('setting.designer.placeholder.name') }));
        error = true;
      }
      if (newPageVersion?.paramValue === '') {
        toast.error(t('control.input.notnull.field', { field: t('setting.designer.placeholder.value') }));
        error = true;
      }
      return !error;
    });
  };

  const handleDelete = () => {
    setOpenDeleteDialog(true);
  };

  useEffect(() => {
    switch (saveState.isSaved) {
      case true:
        setOpenSaveDialog(false);
        if (newPageVersion) updateVersion(newPageVersion);
        break;
      case false:
        setOpenSaveDialog(false);
        break;
    }
    //eslint-disable-next-line
  }, [saveState]);

  useEffect(() => {
    switch (deleteState.choice) {
      case DeleteActionType.Delete:
        setOpenDeleteDialog(false);
        if (pageVersion) {
          deleteVersion({ ids: [pageVersion.id], sendToRecycleBin: false });
          navigate('/versions');
        }
        break;
      case DeleteActionType.Cancel:
        setOpenDeleteDialog(false);
        break;
    }
    //eslint-disable-next-line
  }, [deleteState]);

  useEffect(() => {
    initVersion();
  }, [initVersion]);

  return {
    initVersion,
    t,
    theme,
    openSaveDialog,
    openDeleteDialog,
    handleSave,
    handleDelete,
    saveDispatch,
    deleteDispatch,
    pageVersion,
    newPageVersion,
    setNewPageVersion,
    setPageVersion,
    compagnonOptions,
    control,
  };
};
