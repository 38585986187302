import { toast } from 'react-toastify';
import { TFunction } from 'react-i18next';
import { BaseResponse } from '40.quickConnect.DataAcces/siteAdmin/services/baseResponses';
import { ResponseStatus } from '90.quickConnect.Models/siteAdmin/enums/requests/responseStatus';

export const isResponseValid = (response: BaseResponse, template?: string, t?: TFunction): boolean => {
  let isSuccess = false;
  switch (response.status) {
    case ResponseStatus.OK:
      isSuccess = true;

      if (typeof template !== undefined && !!template && t) {
        const message = t('shared.responseValidator.isValid.ok', { template: template });
        toast.success(message);
      }

      break;

    case ResponseStatus.KO:
      isSuccess = false;

      if (typeof template !== undefined && !!template && !!t) {
        const message = t('shared.responseValidator.isValid.ko', {
          template: template,
          responseMessage: response.message,
        });
        toast(message);
      }

      break;

    case ResponseStatus.Pending:
      isSuccess = true;

      if (typeof template !== undefined && !!template && !!t) {
        const message = t('shared.responseValidator.isValid.pending', {
          template: template,
          responseMessage: response.message,
        });
        toast(message);
      }

      break;

    default:
      break;
  }

  return isSuccess;
};
