import { Dispatch } from 'react';
import { UpdateResourceRequest } from '../../../services/requests';
import { AttributeValue } from '90.quickConnect.Models/siteAdmin/models/common/entities';

const useData = (setUpdateResourceData: Dispatch<React.SetStateAction<UpdateResourceRequest | undefined>>) => {
  const getValue = (attribut: AttributeValue) => {
    return attribut.value !== null ? (String(attribut.value) as string) : '';
  };

  const handleChange = (value: string, attId: string) => {
    setUpdateResourceData((prevRes) => {
      if (prevRes) {
        const newRes = { ...prevRes };
        const attIndex = newRes.attributes.findIndex((a) => a.attributeId === attId);
        newRes.attributes[attIndex].value = new String(value);
        return newRes;
      }
    });
  };

  return {
    getValue,
    handleChange,
  };
};

export default useData;
