import React from 'react';
import { useTranslation } from 'react-i18next';
import { FormProps } from './types';
import QCDialogForm from '70.quickConnect.Components/siteAdmin/Other/QCDialogForm';

const AddClientForm = ({ open, handleConfirm, toggleIsOpen }: FormProps) => {
  const { t } = useTranslation();
  return (
    <QCDialogForm open={open} handleConfirm={handleConfirm} toggleIsOpen={toggleIsOpen}>
      <QCDialogForm.Body
        title={t('customer.customerDesigner.title.new-customer')}
        desc=""
        index={1}
        displayStepping={true}
        nbColumn={1}
      >
        <QCDialogForm.Text label={t('customer.customerDesigner.name')} mandatory />
        <QCDialogForm.Password label={t('customer.customerDesigner.pwd')} mandatory control={undefined} />
      </QCDialogForm.Body>
    </QCDialogForm>
  );
};

export default AddClientForm;
