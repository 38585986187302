import { Box, Card, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useServices from '../services';
import useData from '../hook';
import { AccountContentProps } from './type';
import { AccountContentCardSxProps, AccountContentMainSxProps, AccountContentPasswordSxProps } from './styles';
import QCTextInput from '70.quickConnect.Components/siteAdmin/Inputs/QCTextInput';
import QCButtonGlobal from '70.quickConnect.Components/siteAdmin/Buttons/QCButtonGlobal';
import { QCLogoSM } from '00.app/icons/QCLogoIcon';
import LogoutButton from '70.quickConnect.Components/siteAdmin/Buttons/LogoutButton';

const AccountContent = ({ toggleIsUpdatePasswordFormOpen }: AccountContentProps) => {
  const {
    signInInfos: {
      firstName,
      lastName,
      organizationalUnitName,
      job,
      phone,
      email,
      userUPN,
      authenticationMethod,
      appInformation,
    },
    getAuthenticationMethode,
  } = useServices();

  const { assignName } = useData();
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <Box className="account-content-main" sx={AccountContentMainSxProps}>
      <Card className="panel" sx={AccountContentCardSxProps(theme)}>
        <Box className="Informations">
          <h2>{t('users.designer.form.title')}</h2>
          <QCTextInput
            className="identity"
            data-cy="identity"
            label={t('account.identity')}
            placeholder={assignName(lastName) + ' ' + assignName(firstName)}
            editable={false}
          />
          <QCTextInput
            className="organization"
            data-cy="organization"
            label={t('account.organization')}
            placeholder={assignName(organizationalUnitName)}
            editable={false}
          />
          <QCTextInput
            className="job"
            data-cy="job"
            label={t('users.designer.form.placeholder.job')}
            placeholder={assignName(job)}
            editable={false}
          />
        </Box>
        <Box className="contact">
          <h2>{t('form.searchfieldsModal.contact')}</h2>
          <Box className="contact-content" sx={{ columnGap: '11px', width: '100%' }}>
            <QCTextInput
              className="contact-field"
              data-cy="contact-field-phone"
              label={t('users.designer.form.placeholder.phone')}
              placeholder={assignName(phone)}
              editable={false}
            />
            <QCTextInput
              className="contact-field"
              data-cy="contact-field-email"
              label={t('users.designer.form.placeholder.email')}
              placeholder={assignName(email)}
              editable={false}
            />
          </Box>
        </Box>
      </Card>
      <Box className="panel">
        <Card className="authentication" sx={AccountContentCardSxProps(theme)}>
          <h2>{t('account.authentication')}</h2>
          <QCTextInput
            className="id"
            data-cy="id"
            label={t('account.authentication.id')}
            placeholder={assignName(userUPN)}
            editable={false}
          />
          <QCTextInput
            className="method"
            data-cy="method"
            label={t('users.designer.form.placeholder.methodAuth')}
            placeholder={assignName(getAuthenticationMethode(authenticationMethod))}
            editable={false}
          />
          <Box sx={AccountContentPasswordSxProps}>
            {t('webhooks.designer.form.placeholder.password')}
            <QCButtonGlobal
              className="password-form"
              variant="outlined"
              value={t('header.tooltip.updatePwd')}
              action={() => toggleIsUpdatePasswordFormOpen(true)}
              disable={authenticationMethod === 1}
            />
          </Box>
        </Card>
        <Box
          className="license"
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: '13px',
            paddingY: { xs: '20px', md: '5px', lg: 0 },
          }}
        >
          <QCLogoSM />
          <Box className="description">
            {t('account.license.product')}
            <br />
            {t('account.license.copyright')}
            <br />
            {t('account.license.api', {
              version: assignName(appInformation['Numéro de version api']),
            })}
          </Box>
        </Box>
        <Box sx={{ display: 'flex', justifyContent: { xs: 'center', lg: 'flex-start' } }}>
          <LogoutButton />
        </Box>
      </Box>
    </Box>
  );
};

export default AccountContent;
