import React, { useContext } from 'react';
import { Box } from '@mui/material';
import { characBoxStyle } from '../styles';
import { ResCharacContext } from '../context';
import useData from './hooks';
import { AttributeValue } from '90.quickConnect.Models/siteAdmin/models/common/entities';
import QCAutocomplete from '70.quickConnect.Components/siteAdmin/Inputs/QCAutocomplete';

const CharacSharedListInput = ({ att }: { att: AttributeValue }) => {
  const { getLabel, resSettingsData, setUpdateResourceData } = useContext(ResCharacContext);
  const { getValue, getOptions, handleChange } = useData(resSettingsData, setUpdateResourceData);

  return (
    <Box sx={characBoxStyle}>
      <QCAutocomplete
        label={getLabel(att.attributeId)}
        options={getOptions(att.attributeId)}
        className="carac-sharedList"
        value={getValue(att)}
        dispatch={(value: string) => {
          handleChange(value, att.attributeId);
        }}
      />
    </Box>
  );
};

export default CharacSharedListInput;
