import { useCallback } from 'react';
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { GetDesignUserRequest, GetDesignUserResponse } from './types';
import QCHttpRequest from '40.quickConnect.DataAcces/siteAdmin/QCHttpRequest';
import ApiUser from '40.quickConnect.DataAcces/siteAdmin/apiRoutes/users';

export const useServices = () => {
  const { t } = useTranslation();

  const getCreateUserData = useCallback(async () => {
    const requestHandler = new QCHttpRequest();
    const res = requestHandler
      .post<GetDesignUserResponse, GetDesignUserRequest>(ApiUser.designUser, { id: null }, { withCredentials: true })
      .then(async (response) => {
        if (response.status == 200) {
          const {
            availableAuthMethods,
            availableOrganizationalUnits,
            availableProfils,
            availableSharedLists,
            availableUserParameter,
          } = response.data;
          return {
            availableAuthMethods,
            availableOrganizationalUnits,
            availableProfils,
            availableSharedLists,
            availableUserParameter,
          };
        }
      })
      .catch(async (error) => {
        toast.error(`${t('shared.message.notifyError.badRequest', { errorMessage: error.response.data.message })}`);
      });
    return res;
  }, [t]);

  return {
    getCreateUserData,
  };
};
