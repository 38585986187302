import { QuickConnectRoles } from '90.quickConnect.Models/siteAdmin/enums/user/quickConnectRoles';

const isAdmin = (rights: number): boolean => {
  //   tslint:disable-next-line:no-bitwise
  return (rights & 16) === 16;
};

const isC2S = (rigths: number, customerName: string): boolean => {
  return isAdmin(rigths) && !!customerName && customerName.toLocaleUpperCase().startsWith('C2S');
};

const isDesigner = (rights: number): boolean => {
  // tslint:disable-next-line:no-bitwise
  return (rights & 8) === 8 || isAdmin(rights);
};

const isDesignerInter = (rights: number): boolean => {
  // tslint:disable-next-line:no-bitwise
  return (rights & 4) === 4 || isDesigner(rights);
};

const isAnalyst = (rights: number): boolean => {
  // tslint:disable-next-line:no-bitwise
  return (rights & 2) === 2 || isDesignerInter(rights);
};

const isUser = (rights: number): boolean => {
  // tslint:disable-next-line:no-bitwise

  return (rights & 1) === 1 || isAnalyst(rights);
};

export const getRoleFromRigths = (rights: number, customerName: string) => {
  // console.log(rights);
  if (isC2S(rights, customerName)) return QuickConnectRoles.C2SAdministrator;

  if (isAdmin(rights)) return QuickConnectRoles.Administrator;

  if (isDesigner(rights)) return QuickConnectRoles.Designer;

  if (isDesignerInter(rights)) return QuickConnectRoles.DesignerInter;

  if (isAnalyst(rights)) return QuickConnectRoles.Analyst;

  if (isUser(rights)) return QuickConnectRoles.User;

  return QuickConnectRoles.Anonymous;
};

export const canAccess = (rights: number, accesRequired: QuickConnectRoles, customerName = ''): boolean => {
  switch (accesRequired) {
    case QuickConnectRoles.User:
      return isUser(rights);
    case QuickConnectRoles.Analyst:
      return isAnalyst(rights);
    case QuickConnectRoles.DesignerInter:
      return isDesignerInter(rights);
    case QuickConnectRoles.Designer:
      return isDesigner(rights);
    case QuickConnectRoles.Administrator:
      return isAdmin(rights);
    case QuickConnectRoles.C2SAdministrator:
      return isC2S(rights, customerName);
  }

  return false;
};
