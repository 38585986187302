import { Theme } from '@mui/material';
import { SxProps } from '@mui/system';

export const DataChipStyle = (theme: Theme, editInput: boolean): SxProps => ({
  marginX: '3px',
  marginY: '3px',
  maxWidth: '400px',
  height: '32px',
  fontSize: '0.8125rem',
  fontWeight: '500',
  display: 'inline-flex',
  alignItems: 'center',
  WebkitBoxAlign: 'center',
  borderRadius: '16px',
  whiteSpace: 'nowrap',
  textDecoration: 'none',
  verticalAlign: 'middle',
  overflow: 'hidden',
  backgroundColor: theme.palette.background.default,

  '.scroll-animation:hover:not(shared-list-data-chip-value)': editInput
    ? {}
    : {
        animation: 'scroll-rtl 15s linear forwards',
      },

  '@keyframes scroll-rtl': {
    '0%': {
      transform: 'translate(0)',
    },
    '100%': {
      transform: 'translate(-100%)',
    },
  },
});

export const DataKeyStyle = (theme: Theme): SxProps => ({
  display: 'inline-block',
  width: 'fit-content',
  backgroundColor: theme.palette.background.default,
  zIndex: 3,
  paddingRight: '5px',
  paddingLeft: '1rem',
});

export const DataValueStyle = (theme: Theme, hasKey: boolean): SxProps => ({
  display: 'inline-block',
  position: 'relative',
  paddingLeft: hasKey ? '0' : '1rem',
  paddingRight: '1rem',
  textOverflow: 'clip',

  '.input-edit-shared-list-data-value': {
    outline: 0,
    border: 0,
    maxWidth: '400px',
    borderBottom: `solid 2px ${theme.palette.menuBorder.main}`,
  },
});
