import { useCallback, useEffect, useState } from 'react';

import useServices from './services';
import { AlertsData, GetDataListResponseForAdmin } from './types';
import { DataType } from './QCDropDown/types';
import { MYACCOUNT } from '10.quickConnect.app/siteAdmin/routes/paths';
import getLocalDateTime from '80.quickConnect.core/siteAdmin/helpers/getLocalDateTime';
import { useSafeNavigation } from '80.quickConnect.core/siteAdmin/hooks/useSafeNavigation';

const useData = () => {
  const { getAlertList } = useServices();

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const navigate = useSafeNavigation();

  const [isOpenImportExport, setIsOpenImportExport] = useState<boolean>(false);
  const [isOpenAlertes, setIsOpenAlerte] = useState<boolean>(false);
  const [isOpenActivities, setIsOpenActivities] = useState<boolean>(false);

  const [tabsValueAlerts, setTabsValueAlerts] = useState<string>('my');
  const [tabsValueActivities, setTabsValueActivities] = useState<string>('my');
  const [tabsValueImportExport, setTabsValueImportExport] = useState<string>('my');

  const [alertsData, setAlertsData] = useState<DataType[]>([]);

  const handleAlertsData = useCallback(async () => {
    const data: GetDataListResponseForAdmin = await getAlertList(0, 5, tabsValueAlerts as 'my' | 'organization');
    const li: Array<DataType> = [];
    if (data.indicatorsResult) {
      const array = JSON.parse(data.indicatorsResult);
      array.forEach((alert: AlertsData) => {
        li.push({
          // à améliorer
          // 1) comment définir une alerte ou un incident
          // 2) améliorer la texte de description
          title: alert.stringExtract,
          description: getLocalDateTime(alert.editedAt) + ' / ' + alert.userUPN,
          tags: 'alert',
        });
      });
    }
    setAlertsData(li);
  }, [tabsValueAlerts, getAlertList]);

  useEffect(() => {
    if (isOpenAlertes) handleAlertsData();
  }, [handleAlertsData, isOpenAlertes]);

  const accountNavigation = () => navigate(MYACCOUNT);

  const handleClickImportExport = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsOpenImportExport((prevIsOpen) => !prevIsOpen);
  };

  const handleClickAlertes = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsOpenAlerte((prevIsOpen) => !prevIsOpen);
  };

  const handleClickActivities = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    setIsOpenActivities((prevIsOpen) => !prevIsOpen);
  };

  return {
    accountNavigation,
    anchorEl,
    isOpenImportExport,
    setIsOpenImportExport,
    handleClickImportExport,
    isOpenAlertes,
    setIsOpenAlerte,
    handleClickAlertes,
    isOpenActivities,
    setIsOpenActivities,
    handleClickActivities,
    setTabsValueAlerts,
    setTabsValueActivities,
    setTabsValueImportExport,
    tabsValueAlerts,
    tabsValueActivities,
    tabsValueImportExport,
    alertsData,
  };
};

export default useData;
