import React from 'react';
import {
  CATALOG,
  CATALOGS,
  CONNECTIONS,
  NEWCATALOG,
  NEWORGA,
  NEWTODOLIST,
  NEWUSER,
  ORGANIZATION,
  ORGASETTINGS,
  ROLES,
  ROLE,
  SETTINGS,
  USERS,
  USERSETTINGS,
} from '../paths';
import { LinkRouteType } from '../types';
import ComingSoon from './comingSoon';
import DESIGNER_ROUTES from './designerRoutes';
import ConnectionHistory from '10.quickConnect.app/siteAdmin/components/domains/ConnectionHistory';
import NoOrganizationalUnitFocusedContent from '10.quickConnect.app/siteAdmin/components/domains/Users/NoOrganizationalUnitFocused';
import UserList from '10.quickConnect.app/siteAdmin/components/domains/Users/UserList';
import OrganizationSettings from '10.quickConnect.app/siteAdmin/components/domains/OrganizationSettings';
import UserSettings from '10.quickConnect.app/siteAdmin/components/domains/UserSettings';
import Roles from '10.quickConnect.app/siteAdmin/components/domains/Roles';
import RoleSettings from '10.quickConnect.app/siteAdmin/components/domains/Roles/RoleSettings';

const ADMINISTRATOR_ROUTES: LinkRouteType[] = [
  // A compléter quand les composants seront créés
  ...DESIGNER_ROUTES,

  { path: USERS, key: 'USERS', element: <NoOrganizationalUnitFocusedContent /> },
  { path: CATALOGS, key: 'CATALOGS', element: <ComingSoon /> },
  { path: SETTINGS, key: 'SETTINGS', element: <ComingSoon /> },
  { path: CONNECTIONS, key: 'CONNECTIONS', element: <ConnectionHistory /> },
  { path: ROLES, key: 'ROLES', element: <Roles /> },
  { path: ROLE, key: 'ROLESETTINGs', element: <RoleSettings /> },

  { path: ORGANIZATION, key: 'ORGANIZATION', element: <UserList /> },
  { path: NEWORGA, key: 'NEWORGA', element: <ComingSoon /> },
  { path: ORGASETTINGS, key: 'ORGASETTINGS', element: <OrganizationSettings /> },
  { path: NEWUSER, key: 'NEWUSER', element: <ComingSoon /> },
  { path: USERSETTINGS, key: 'USERSETTINGS', element: <UserSettings /> },

  { path: NEWTODOLIST, key: 'NEWTODOLIST', element: <ComingSoon /> },

  { path: CATALOG, key: 'CATALOG', element: <ComingSoon /> },
  { path: NEWCATALOG, key: 'NEWCATALOG', element: <ComingSoon /> },
];

export default ADMINISTRATOR_ROUTES;
