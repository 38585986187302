import { observer } from 'mobx-react';
import React from 'react';
import { useTranslation } from 'react-i18next';
import SettingsIcon from '@mui/icons-material/Settings';
import AddIcon from '@mui/icons-material/Add';
import { Box } from '@mui/system';
import MenuWrapper from '../../../shared/MenuWrapper';
import useVersionData from '../hooks';
import AddVersionDialogForm from '../AddVersionDialogForm';
import { CircleIcon } from '../icons/Cercle';
import { Content, MainBox } from './style';
import useEffectOnce from '80.quickConnect.core/shared/hooks/useEffectOnce';
import DeleteConfirmationDialog from '70.quickConnect.Components/siteAdmin/Other/DeleteConfirmationDialog';
import QCButtonGlobal from '70.quickConnect.Components/siteAdmin/Buttons/QCButtonGlobal';

const VersionQC = () => {
  const {
    initVersions,
    isAddVersionDialogOpen,
    setIsAddVersionDialogOpen,
    handleConfirm,
    deleteDispatch,
    openDeleteDialog,
  } = useVersionData();
  useEffectOnce(() => {
    initVersions();
  });
  const { t } = useTranslation();
  return (
    <MenuWrapper>
      <Box sx={MainBox}>
        <Box sx={Content}>
          {t('version.home').replace(/,/, ',\n')}
          <QCButtonGlobal
            className="OU-creation"
            value={t('setting.viewer.actions.add')}
            startIcon={<SettingsIcon />}
            endIcon={<AddIcon />}
            sx={{ width: '350px' }}
            action={() => setIsAddVersionDialogOpen(true)}
            datacy="add-version-button"
          />
        </Box>
        <CircleIcon />
      </Box>
      <AddVersionDialogForm
        open={isAddVersionDialogOpen}
        toggleIsOpen={setIsAddVersionDialogOpen}
        handleConfirm={handleConfirm}
      />
      <DeleteConfirmationDialog dispatch={deleteDispatch} open={openDeleteDialog} />
    </MenuWrapper>
  );
};

export default observer(VersionQC);
