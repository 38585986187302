import { DesignResourceResponse } from '../../services/response';

const useData = (resSettingsData: DesignResourceResponse | undefined) => {
  const getLabel = (attId: string) => {
    return resSettingsData?.schema.attributes.find((el) => el.id === attId)?.name ?? '';
  };
  return {
    getLabel,
  };
};

export default useData;
