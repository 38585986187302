import React from 'react';
import { useTranslation } from 'react-i18next';
import { UpdatePasswordFormProps } from './types';
import QCDialogForm from '70.quickConnect.Components/siteAdmin/Other/QCDialogForm';
import useControls from '90.quickConnect.Models/siteAdmin/inputValidator/controls';

const UpdatePasswordDialog = ({ open, handleConfirm, toggleIsOpen }: UpdatePasswordFormProps) => {
  const { t } = useTranslation();
  return (
    <QCDialogForm open={open} handleConfirm={handleConfirm} toggleIsOpen={toggleIsOpen}>
      <QCDialogForm.Body displayStepping={false} nbColumn={1} index={1} desc={t('header.tooltip.updatePwd')}>
        <QCDialogForm.Password label={t('users.editPassword.form.placeholder.oldPassword')} mandatory />
        <QCDialogForm.Password
          label={t('login.forgotPassword.secondStep.newPassword.label')}
          mandatory
          control={useControls().password}
        />
        <QCDialogForm.Password label={t('login.forgotPassword.secondStep.confirmNewPassword.placeholder')} mandatory />
      </QCDialogForm.Body>
    </QCDialogForm>
  );
};

export default UpdatePasswordDialog;
