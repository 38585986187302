import React, { useCallback } from 'react';
import { Box, Button, Chip, Typography } from '@mui/material';
import HistoryIcon from '@mui/icons-material/History';
import { RenderCellesProps } from '../types';
import { innerBoxStyle, outerBoxStyle, typographyPrimary, typographySecondary } from './styles';
import { FormType } from '90.quickConnect.Models/siteAdmin/enums/forms/formType';
import { useSafeNavigation } from '80.quickConnect.core/siteAdmin/hooks/useSafeNavigation';
import { getReplacedPath } from '80.quickConnect.core/shared/helpers/getReplacedPath';
import { DECLARATION } from '10.quickConnect.app/siteAdmin/routes/paths';

const NameDeclaCell = ({ params }: RenderCellesProps) => {
  const navigate = useSafeNavigation();

  const handleClick = useCallback(() => {
    navigate(getReplacedPath(DECLARATION, [params.id.toString()]));
  }, [navigate, params.id]);

  return (
    <Box sx={outerBoxStyle}>
      <Box sx={innerBoxStyle}>
        <HistoryIcon />
        <Button sx={typographyPrimary} onClick={handleClick}>
          {params.row.stringExtract}
        </Button>
        {/* à changer la valeur du label => statut d'étapes complétées */}
        {params.row.formType === FormType.Workflow && <Chip color="bgColorWorkFlowChip" label="WorkFlow" />}
      </Box>
      <Typography sx={typographySecondary}>
        {params.row.userUpn}, {params.row.organizationalUnitStringExtract}
      </Typography>
    </Box>
  );
};

export default NameDeclaCell;
