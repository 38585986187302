import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import axios, { AxiosResponse } from 'axios';
import { defaultRoleListResponse } from '../const';
import {
  CreateRoleRequest,
  DeleteRoleRequest,
  GetRoleDependenciesRequest,
  GetRoleListRequest,
  GetRoleRequest,
  UpdateRoleRequest,
} from './request';
import {
  CreateRoleResponse,
  DeleteRoleResponse,
  GetRoleDependenciesResponse,
  GetRoleListResponse,
  GetRoleResponse,
  GetSupportedLanguagesResponse,
  UpdateRoleResponse,
} from './response';
import ApiProfiles from '40.quickConnect.DataAcces/siteAdmin/apiRoutes/profiles';
import QCHttpRequest from '40.quickConnect.DataAcces/siteAdmin/QCHttpRequest';
import { isResponseValid } from '40.quickConnect.DataAcces/siteAdmin/responseValidator';
import { SupportedLanguage } from '90.quickConnect.Models/siteAdmin/models/internationalization/supportedLanguage';
import { ApiInternationalization } from '40.quickConnect.DataAcces/siteAdmin/apiRoutes/internationalization';

const useRoleService = () => {
  const { t } = useTranslation();
  const getRoleList = async (): Promise<GetRoleListResponse> => {
    const requestHandler = new QCHttpRequest();

    const response = await requestHandler.post<GetRoleListResponse, GetRoleListRequest>(ApiProfiles.getProfilList, {});

    const isValid = isResponseValid(response.data);

    if (isValid) {
      return response.data;
    } else {
      return defaultRoleListResponse;
    }
  };

  const getRoleById = async (request: GetRoleRequest) => {
    const requestHandler = new QCHttpRequest();

    const response = await requestHandler.post<GetRoleResponse, GetRoleRequest>(ApiProfiles.getProfil, request);

    return response.data;
  };

  const createRole = async (request: CreateRoleRequest): Promise<CreateRoleResponse> => {
    const requestHandler = new QCHttpRequest();

    const response = await requestHandler.post<CreateRoleResponse, CreateRoleRequest>(
      ApiProfiles.createProfil,
      request,
    );

    const isValid = isResponseValid(response.data);

    if (isValid) {
      toast.success(t('profil.create.success'));
    }
    return response.data;
  };

  // eslint-disable-next-line
  const deleteRole = async (request: DeleteRoleRequest): Promise<AxiosResponse<DeleteRoleResponse, any>> => {
    const requestHandler = new QCHttpRequest();

    const response = await requestHandler.post<DeleteRoleResponse, DeleteRoleRequest>(
      ApiProfiles.deleteProfil,
      request,
    );

    const isValid = isResponseValid(response.data);

    if (isValid) {
      toast.success(t('profil.delete.success'));
      return response;
    } else {
      return response;
    }
  };

  const updateRole = async (request: UpdateRoleRequest): Promise<AxiosResponse<UpdateRoleResponse, unknown>> => {
    const requestHandler = new QCHttpRequest();

    const response = await requestHandler.post<UpdateRoleResponse, UpdateRoleRequest>(
      ApiProfiles.updateProfil,
      request,
    );

    const isValid = isResponseValid(response.data);

    if (isValid) {
      toast.success(t('profil.update.success'));
      return response;
    } else {
      return response;
    }
  };

  const getRoleDependencies = async (request: GetRoleDependenciesRequest) => {
    const requestHandler = new QCHttpRequest();

    const response = await requestHandler.post<GetRoleDependenciesResponse, GetRoleDependenciesRequest>(
      ApiProfiles.getProfilDependencies,
      request,
      { responseType: 'text' },
    );
    const newBlob = new Blob([new Uint8Array([0xef, 0xbb, 0xbf]), response.data], {
      type: 'text/csv;charset=utf-8',
    });
    const data = window.URL.createObjectURL(newBlob);
    const link = document.createElement('a');
    link.href = data;
    link.download = 'index.csv';

    link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
    setTimeout(function () {
      // For Firefox it is necessary to delay revoking the ObjectURL
      window.URL.revokeObjectURL(data);
      link.remove();
    }, 3000);
    return response;
  };

  const getSupportedLanguages = async (): Promise<SupportedLanguage[] | undefined> => {
    const requestHandler = new QCHttpRequest();
    try {
      const response = await requestHandler.get<GetSupportedLanguagesResponse>(ApiInternationalization.getInfos);

      return Object.entries(response.data.infos.SupportedLanguages).map(([lg, val]) => ({
        lang: lg,
        value: val,
      }));
    } catch (error) {
      if (axios.isAxiosError(error)) toast.error(error.response?.data.message);
    }
  };
  return { getRoleList, deleteRole, getSupportedLanguages, createRole, getRoleById, updateRole, getRoleDependencies };
};

export default useRoleService;
