import React, { Dispatch, SetStateAction, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useState, useCallback } from 'react';
import ListIcon from '@mui/icons-material/List';
import useSharedListsServices from '../services';
import { GetSharedListsRequest } from '../services/requests';
import { SubMenuRow } from '10.quickConnect.app/siteAdmin/components/shared/SubMenu/types';
import { useStore } from '30.quickConnect.Store/siteAdmin';
import useEffectOnce from '80.quickConnect.core/shared/hooks/useEffectOnce';

const useInitSharedListSubMenu = (setOpenDeleteDialog: Dispatch<SetStateAction<boolean>>) => {
  const { t } = useTranslation();
  const {
    SubMenuStore: {
      setRows,
      setSubMenuPath,
      setRouteBase,
      setAddText,
      setAddIcon,
      setHandleAddClick,
      setRowActions,
      setMultipleActions,
      selectedRowAction,
      setSelectedRowAction,
      selectedRowsMultipleAction,
      setSelectedRowsMultipleAction,
    },
  } = useStore();

  const { getSharedLists } = useSharedListsServices();

  const [openAddDialog, setOpenAddDialog] = useState(false);

  const idsToDelete = useRef<string[]>([]);

  const initSharedLists = useCallback(async () => {
    setRows([]);
    const request: GetSharedListsRequest = {
      count: 1,
      filters: [],
      length: 1000,
      start: 0,
      textFilter: null,
    };

    const data = await getSharedLists(request);

    if (data) {
      const rows: SubMenuRow[] = data.map((el) => ({
        id: el.id,
        name: el.name,
        childrenCount: 0,
        startIcon: <ListIcon />,
        type: 'item',
      }));
      setRows(rows);
    }
    //eslint-disable-next-line
  }, []);

  useEffectOnce(() => {
    setSubMenuPath([{ id: 'root', name: t('navigation.menu.sharedlists'), rows: [] }]);
    setRouteBase('/sharedlists');
    initSharedLists();
    setAddText(t('sharedList.sharedLists.form.actions.addSharedList'));
    setHandleAddClick(() => setOpenAddDialog(true));
    setAddIcon(<ListIcon />);
    setMultipleActions([{ text: t('common.actions.delete') }]);
    setRowActions([t('common.actions.delete')]);
  });

  // gestion d'une action sous-menu
  useEffect(() => {
    if (!selectedRowAction) return;
    switch (selectedRowAction.option) {
      case t('common.actions.delete'):
        idsToDelete.current.push(selectedRowAction.rowId);
        setOpenDeleteDialog(true);
        break;
    }
    setSelectedRowAction(undefined);
    //eslint-disable-next-line
  }, [selectedRowAction]);

  useEffect(() => {
    if (!selectedRowsMultipleAction) return;
    switch (selectedRowsMultipleAction.option) {
      case t('common.actions.delete'):
        idsToDelete.current = selectedRowsMultipleAction.rowsId;
        setOpenDeleteDialog(true);
        break;
    }
    setSelectedRowsMultipleAction(undefined);
    //eslint-disable-next-line
  }, [selectedRowsMultipleAction]);

  return {
    openAddDialog,
    setOpenAddDialog,
    idsToDelete,
    initSharedLists,
  };
};

export default useInitSharedListSubMenu;
