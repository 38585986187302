import { action, makeAutoObservable, observable } from 'mobx';
import { EntitySchemaAttribute } from '90.quickConnect.Models/siteAdmin/models/families/entitySchemaAttribute';
import { SharedList } from '90.quickConnect.Models/siteAdmin/models/sharedList/sharedList';
import { Webhook } from '90.quickConnect.Models/siteAdmin/models/webhook/webhook';

// store interne à la page FamilySettings

class FamilySettingsStore {
  attributes: EntitySchemaAttribute[];

  newAttributesIds: string[];

  simpleSharedLists: SharedList[] = [];

  hierarchicalSharedLists: SharedList[] = [];

  webhooks: Webhook[] = [];

  // webhooks utilisé dans la famille
  familyWebhookIds: string[] = [];

  constructor(attributes?: EntitySchemaAttribute[]) {
    this.attributes = attributes ?? [];
    this.newAttributesIds = [];

    makeAutoObservable(this, {
      attributes: observable,
      newAttributesIds: observable,
      simpleSharedLists: observable,
      hierarchicalSharedLists: observable,
      webhooks: observable,
      familyWebhookIds: observable,

      setFamilyWebhookIds: action,
      setWebhooks: action,
      setSharedLists: action,
      changeAttribute: action,
      deleteAttribute: action,
      addAttribute: action,
      setAttributes: action,
      reorderAttributes: action,
    });
  }

  setSharedLists = (simpleSharedLists: SharedList[], hierarchicalSharedLists: SharedList[]) => {
    this.simpleSharedLists = simpleSharedLists;
    this.hierarchicalSharedLists = hierarchicalSharedLists;
  };

  setWebhooks = (webhooks: Webhook[]) => {
    this.webhooks = webhooks;
  };

  setFamilyWebhookIds = (familyWebhookIds: string[]) => {
    this.familyWebhookIds = familyWebhookIds;
  };

  setAttributes = (attributes: EntitySchemaAttribute[]) => {
    this.attributes = attributes;
  };

  changeAttribute = <K extends keyof EntitySchemaAttribute>(
    newValue: EntitySchemaAttribute[K],
    propertyToChange: K,
    attId: string,
  ) => {
    this.attributes.forEach((attribute) => {
      if (attribute.id === attId) {
        if (propertyToChange === 'type' && newValue !== attribute.type) {
          attribute.sharedListId = undefined;
        }
        attribute[propertyToChange] = newValue;
      }
    });
  };

  addAttribute = (newAttribute: EntitySchemaAttribute) => {
    this.attributes.push(newAttribute);
    this.newAttributesIds.push(newAttribute.id);
  };

  deleteAttribute = (idToDelete: string) => {
    const indexToDelete = this.attributes.findIndex((att) => att.id === idToDelete);
    if (indexToDelete !== -1) {
      this.attributes.splice(indexToDelete, 1);
    }

    // actualiser le listOrder de chaque attribut
    const idsToOrder = this.attributes.map((att) => att.id);
    this.reorderAttributes(idsToOrder);

    // si c'est un nouvel attribut
    if (indexToDelete !== -1) {
      this.newAttributesIds.slice(indexToDelete, 1);
    }
  };

  reorderAttributes = (orderedIds: string[]) => {
    const reorderedAttributes: EntitySchemaAttribute[] = [];

    orderedIds.forEach((id, index) => {
      const attToAdd = this.attributes.find((att) => att.id === id);
      if (attToAdd) {
        attToAdd.listOrder = index;
        reorderedAttributes.push(attToAdd);
      }
    });
    this.attributes = reorderedAttributes;
  };
}

export default FamilySettingsStore;
