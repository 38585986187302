import { useTheme } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useFamilySettingsStore } from '../FamilySettingsStore';
import { SETTINGS } from '10.quickConnect.app/siteAdmin/routes/paths';
import { useSafeNavigation } from '80.quickConnect.core/siteAdmin/hooks/useSafeNavigation';

const useWebhooksCard = () => {
  const { t } = useTranslation();
  const theme = useTheme();

  const navigate = useSafeNavigation();

  const { webhooks, familyWebhookIds, setFamilyWebhookIds } = useFamilySettingsStore();

  const [openAddWebhookDialog, setOpenAddWebhookDialog] = useState(false);

  const deleteWebhook = (webhookId: string) => {
    const indexToDelete = familyWebhookIds.findIndex((id) => id === webhookId);
    if (indexToDelete === -1) return;
    const newIds = Array.from(familyWebhookIds);
    newIds.splice(indexToDelete, 1);
    setFamilyWebhookIds(newIds);
  };

  const handleWebhookSettingsClick = () => {
    navigate(SETTINGS);
  };

  return {
    t,
    theme,
    webhooks,
    familyWebhookIds,
    openAddWebhookDialog,
    setOpenAddWebhookDialog,
    deleteWebhook,
    handleWebhookSettingsClick,
  };
};

export default useWebhooksCard;
