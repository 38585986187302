import React from 'react';
import useData from './hooks';
import QCDatePicker from '70.quickConnect.Components/siteAdmin/Inputs/QCDatePicker';
import { DatePickerProps } from '70.quickConnect.Components/siteAdmin/Inputs/QCDatePicker/types';

const QCDialogFormDate = ({ label, maxDate, minDate, info, mandatory }: DatePickerProps & { label: string }) => {
  const { defaultDate, handleChange } = useData(label, mandatory ?? false);
  return (
    <QCDatePicker
      localDate={defaultDate}
      maxDate={maxDate}
      minDate={minDate}
      setDate={handleChange}
      className="dialog-date-picker"
      info={info}
      label={label}
      mandatory={mandatory}
    />
  );
};

export default QCDialogFormDate;
