import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { SubMenuRowItem } from '../../shared/SubMenu/types';
import useVersionServices from './services/versions';
import { CreateVersionRequest, defaultGetCompagnonsRequest, defaultGetVersionsRequest } from './services/requests';
import { VersionRowAction } from './actions';
import { Compagnon } from './services/responses';
import useCompagnonServices from './services/compagnions';
import { useStore } from '30.quickConnect.Store/siteAdmin';
import useEffectOnce from '80.quickConnect.core/shared/hooks/useEffectOnce';
import { DeleteActionType } from '70.quickConnect.Components/siteAdmin/Other/DeleteConfirmationDialog/type';
import { AllDataElementType } from '70.quickConnect.Components/siteAdmin/Other/QCDialogForm/types';
import { useDeleteDialog } from '80.quickConnect.core/siteAdmin/hooks/useDeleteDialog';
import { useSafeNavigation } from '80.quickConnect.core/siteAdmin/hooks/useSafeNavigation';

const useVersionData = () => {
  const {
    SubMenuStore: {
      setRows,
      setSubMenuPath,
      setRouteBase,
      setRowActions,
      selectedRowAction,
      setSelectedRowAction,
      setAddIcon,
      setHandleAddClick,
      setSortingTypes,
      setAddText,
      setMultipleActions,
      selectedRowsMultipleAction,
    },
  } = useStore();

  const { t } = useTranslation();
  const { getVersionList, createVersion, deleteVersion } = useVersionServices();
  const { getCompagnonList } = useCompagnonServices();
  const navigate = useSafeNavigation();

  const [isAddVersionDialogOpen, setIsAddVersionDialogOpen] = useState(false);

  const [compagnonOptions, setCompagnonOptions] = useState<Compagnon[]>([]);

  useEffectOnce(() => {
    setAddIcon(<></>);
    setHandleAddClick(() => setIsAddVersionDialogOpen(true));
    setSortingTypes([
      t('submenu.sortingType.alphabetic'),
      t('submenu.sortingType.type'),
      t('submenu.sortingType.favourite'),
    ]);
    setAddText(t('setting.viewer.actions.add'));
    setMultipleActions([{ text: t('submenu.multipleAction.delete') }]);
  });

  const initVersions = useCallback(async () => {
    const ret = await getVersionList(defaultGetVersionsRequest);
    const rows: SubMenuRowItem[] = ret.parameters.map((elem) => {
      return {
        id: elem.id,
        name: elem.name,
        type: 'item',
        childrenCount: 0,
      } as SubMenuRowItem;
    });
    setSubMenuPath([
      {
        id: 'root',
        name: t('setting.settings.tabs.globalParameters'),
        rows: rows,
      },
    ]);
    setRows(rows);
    setRouteBase('/versions');
    setRowActions(Object.values(VersionRowAction));
    // eslint-disable-next-line
  }, [getVersionList, setRows]);

  const [openDeleteDialog, setOpenDeleteDialog, deleteState, deleteDispatch] = useDeleteDialog();

  useEffect(() => {
    if (!selectedRowAction) return;
    switch (selectedRowAction.option) {
      case t(VersionRowAction.Delete):
        setOpenDeleteDialog(true);
        break;
      case t(VersionRowAction.Settings):
        navigate(`/versions/${selectedRowAction.rowId}`);
        setSelectedRowAction(undefined);
        break;
      default:
        break;
    }
    //eslint-disable-next-line
  }, [selectedRowAction]);

  useEffect(() => {
    console.log(selectedRowsMultipleAction?.option);
    console.log(t(VersionRowAction.Delete));
    if (!selectedRowsMultipleAction) return;
    switch (selectedRowsMultipleAction.option) {
      case t(VersionRowAction.Delete):
        setOpenDeleteDialog(true);
        break;
      default:
        break;
    }
    // eslint-disable-next-line
  }, [selectedRowsMultipleAction]);

  useEffect(() => {
    switch (deleteState.choice) {
      case DeleteActionType.Delete:
        setOpenDeleteDialog(false);
        if (selectedRowAction && selectedRowAction.rowId) {
          // deleteOrganization({ ids: [selectedRowAction.rowId] }, '');
          deleteVersion({ ids: [selectedRowAction.rowId], sendToRecycleBin: false });
          navigate('/versions');
          initVersions();
        }
        if (selectedRowsMultipleAction && selectedRowsMultipleAction.rowsId) {
          deleteVersion({ ids: selectedRowsMultipleAction.rowsId, sendToRecycleBin: false });
          navigate('/versions');
          initVersions();
        }
        setSelectedRowAction(undefined);
        break;
      case DeleteActionType.Cancel:
        setOpenDeleteDialog(false);
        break;
    }
    //eslint-disable-next-line
  }, [deleteState]);

  const getCompagnons = useCallback(async () => {
    const result = await getCompagnonList(defaultGetCompagnonsRequest);
    setCompagnonOptions(result.data);
  }, [getCompagnonList]);

  useEffectOnce(() => {
    getCompagnons();
  });

  const handleConfirm = async (value: AllDataElementType[]) => {
    const VersionName = value[0].data.find((elem) => elem.label === t('setting.designer.placeholder.name'))?.value;
    const VersionDesc = value[0].data.find(
      (elem) => elem.label === t('setting.designer.placeholder.description'),
    )?.value;
    const VersionCompagnon = value[0].data.find(
      (elem) => elem.label === t('setting.designer.placeholder.companionId'),
    )?.value;
    const VersionValue = value[0].data.find((elem) => elem.label === t('setting.designer.placeholder.value'))?.value;

    const VersionCompagnonId = compagnonOptions.find((elem) => elem.name === VersionCompagnon)?.id;

    const request: CreateVersionRequest = {
      name: VersionName?.toString() ?? '',
      description: VersionDesc?.toString() ?? '',
      isGlobalParam: true,
      companionQCId: VersionCompagnonId?.toString() ?? '',
      paramValue: VersionValue?.toString() ?? '',
    };

    const response = await createVersion(request);
    if (response) {
      initVersions();
      return true;
    }
    return false;
  };

  return {
    initVersions,
    isAddVersionDialogOpen,
    setIsAddVersionDialogOpen,
    handleConfirm,
    compagnonOptions,
    deleteDispatch,
    openDeleteDialog,
  };
};

export default useVersionData;
