import { useCallback, useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useTheme } from '@mui/material';
import { toast } from 'react-toastify';
import useOrganizationServices from '../Users/services/organization';
import { Organization } from '../Users/types';
import { saveConfirmationReducer } from '70.quickConnect.Components/siteAdmin/Other/SaveConfirmationDialog/type';
import { DeleteActionType } from '70.quickConnect.Components/siteAdmin/Other/DeleteConfirmationDialog/type';
import useControls from '90.quickConnect.Models/siteAdmin/inputValidator/controls';
import { useDeleteDialog } from '80.quickConnect.core/siteAdmin/hooks/useDeleteDialog';
import { useSafeNavigation } from '80.quickConnect.core/siteAdmin/hooks/useSafeNavigation';

export const useData = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { organizationId } = useParams();
  const navigate = useSafeNavigation();
  const { getOrganizationById, updateOrganization, deleteOrganization } = useOrganizationServices();

  const control = useControls();

  const [pageOrganization, setPageOrganization] = useState<Organization | null>(null);

  const [newPageOrganization, setNewPageOrganization] = useState<Organization>();

  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [saveState, saveDispatch] = useReducer(saveConfirmationReducer, { isSaved: false });

  const [openDeleteDialog, setOpenDeleteDialog, deleteState, deleteDispatch] = useDeleteDialog();

  const initOrganization = useCallback(async () => {
    if (!organizationId) {
      navigate(-1);
      return;
    }

    const organization = await getOrganizationById(organizationId);
    if (organization === null) navigate(-1);
    setPageOrganization(organization);
    setNewPageOrganization(organization);
    //eslint-disable-next-line
  }, [organizationId]);

  const handleSave = () => {
    setOpenSaveDialog(() => {
      if (control.email.regex.exec(newPageOrganization?.email ?? '') === null) {
        toast.error(t('control.input.email'));
        return false;
      } else return true;
    });
  };

  const handleDelete = () => {
    setOpenDeleteDialog(true);
  };

  useEffect(() => {
    switch (saveState.isSaved) {
      case true:
        setOpenSaveDialog(false);
        if (newPageOrganization) updateOrganization(newPageOrganization);
        break;
      case false:
        setOpenSaveDialog(false);
        break;
    }
    //eslint-disable-next-line
  }, [saveState]);

  useEffect(() => {
    const sendToTrash = false;
    switch (deleteState.choice) {
      case DeleteActionType.SendToTrash:
      case DeleteActionType.Delete:
        //si corbeille activée
        // console.log(deleteState.choice);
        // if (deleteState.choice === DeleteActionType.SendToTrash) sendToTrash = true;

        setOpenDeleteDialog(false);
        if (pageOrganization) {
          deleteOrganization({ ids: [pageOrganization.id], sendToRecycleBin: sendToTrash }, pageOrganization.name);
          navigate('/users');
        }
        break;
      case DeleteActionType.Cancel:
        setOpenDeleteDialog(false);
        break;
      default:
        setOpenDeleteDialog(false);
        break;
    }
    //eslint-disable-next-line
  }, [deleteState]);

  useEffect(() => {
    initOrganization();
  }, [initOrganization]);

  return {
    t,
    theme,
    pageOrganization,
    newPageOrganization,
    setNewPageOrganization,
    openSaveDialog,
    openDeleteDialog,
    handleSave,
    handleDelete,
    saveDispatch,
    deleteDispatch,
    control,
  };
};
