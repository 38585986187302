import React, { ChangeEvent } from 'react';
import { observer } from 'mobx-react';
import { Box, Stack } from '@mui/material';
import ListIcon from '@mui/icons-material/List';
import SaveOutlinedIcon from '@mui/icons-material/SaveOutlined';
// import DownloadOutlinedIcon from '@mui/icons-material/DownloadOutlined';
// import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import CreateSharedListDialog from '../CreateSharedListDialog';
import useSharedListSettingsData from './hooks';
import SharedListElements from './SharedListElements';
import SharedListSettingsContext from './SharedListSettingsContext';
import { SharedListSettingsContextType } from './SharedListSettingsContext/context';
import AddElementDialog from './SharedListElements/AddElementDialog';
import { InputsGridContainer, SharedListAttributesContainer, SharedListDesignContainer } from './styles';
import MenuWrapper from '10.quickConnect.app/siteAdmin/components/shared/MenuWrapper';
import ContentHeader from '70.quickConnect.Components/siteAdmin/Other/ContentHeader';
import QCFabCorbeille from '70.quickConnect.Components/siteAdmin/Buttons/QCFabCorbeille';
import QCTextInput from '70.quickConnect.Components/siteAdmin/Inputs/QCTextInput';
import QCSwitchSelect from '70.quickConnect.Components/siteAdmin/Inputs/QCSwitchSelect';
import { SharedListType } from '90.quickConnect.Models/siteAdmin/enums/sharedList/sharedListType';
import QCSelect from '70.quickConnect.Components/siteAdmin/Inputs/QCSelect';
import { capitalize } from '80.quickConnect.core/shared/helpers/capitalize';
import { SharedList } from '90.quickConnect.Models/siteAdmin/models/sharedList/sharedList';
import QCIconButton from '70.quickConnect.Components/siteAdmin/Buttons/QCIconButton';
import QCSwitch from '70.quickConnect.Components/siteAdmin/Inputs/QCSwitch';
import DeleteConfirmationDialog from '70.quickConnect.Components/siteAdmin/Other/DeleteConfirmationDialog';
// import QCButtonGlobal from '70.quickConnect.Components/siteAdmin/Buttons/QCButtonGlobal';

const SharedListSettings = () => {
  const {
    t,
    theme,
    pageSharedList,
    setPageSharedList,
    sharedListType,
    setSharedListType,
    supportedLanguages,
    languageValue,
    handleSave,
    dataType,
    setDataType,
    sharedListElements,
    setSharedListElements,
    addElementLabel,
    setAddElementLabel,
    openAddElementDialog,
    setOpenAddElementDialog,
    openAddSharedListDialog,
    setOpenAddSharedListDialog,
    initialToBeTranslated,
    openDeleteDialog,
    setOpenDeleteDialog,
    deleteDispatch,
    canSendToTrash,
  } = useSharedListSettingsData();

  const contextValue: SharedListSettingsContextType = {
    sharedListElements: sharedListElements,
    setSharedListElements: setSharedListElements,
    dataType: dataType,
    setDataType: setDataType,
    sharedListType: sharedListType,
    setSharedListType: setSharedListType,
    addElementLabel: addElementLabel.current,
    setOpenAddElementDialog: setOpenAddElementDialog,
    setAddElementLabel: setAddElementLabel,
    initialToBeTranslated: initialToBeTranslated.current,
  };
  return (
    <MenuWrapper>
      <DeleteConfirmationDialog dispatch={deleteDispatch} open={openDeleteDialog} trashAvailable={canSendToTrash} />
      <SharedListSettingsContext.Provider value={contextValue}>
        <Stack sx={{ paddingX: '30px', marginTop: '1vh', paddingBottom: '2vh' }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
            <ContentHeader>
              <ContentHeader.TitlesGroup>
                <ContentHeader.OverTitle>{t('sharedList.designer.overtitle')}</ContentHeader.OverTitle>
                <ContentHeader.Title startIcon={<ListIcon />}>{pageSharedList?.name}</ContentHeader.Title>
              </ContentHeader.TitlesGroup>
            </ContentHeader>

            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <QCFabCorbeille onClickFunc={() => setOpenDeleteDialog(true)}></QCFabCorbeille>
              <QCIconButton Icon={<SaveOutlinedIcon />} onClickFunc={handleSave} variantValue="contained" />
            </Box>
          </Box>

          <Box sx={SharedListDesignContainer()}>
            <Box sx={SharedListAttributesContainer(theme)}>
              <Stack direction="row" justifyContent="space-between">
                <h2>{t('core.helpers.common.userSettingsType.sharedList')}</h2>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                  <label>
                    {t('entitySchema.entityDesigner.entitySchema.label.toBeTranslated')}
                    <QCSwitch
                      onChange={(event: ChangeEvent, checked: boolean) =>
                        setPageSharedList((curr) => (curr ? { ...curr, toBeTranslated: checked } : undefined))
                      }
                      checked={pageSharedList?.toBeTranslated ?? false}
                    />
                  </label>
                  {/* Appels API pas implémentés */}
                  {/* <QCButtonGlobal
                    startIcon={<UploadOutlinedIcon />}
                    className="import-shared-list"
                    value={t('common.actions.import')}
                    variant="contained"
                  />
                  <QCButtonGlobal
                    startIcon={<DownloadOutlinedIcon />}
                    className="export-shared-list"
                    value={t('common.actions.export')}
                    variant="outlined"
                  /> */}
                </Box>
              </Stack>
              <Box sx={InputsGridContainer}>
                <QCTextInput
                  label={t('sharedList.sharedLists.table.name')}
                  value={pageSharedList?.name}
                  dispatch={(value: string) => {
                    if (!pageSharedList) return;
                    const newPageSharedList: SharedList = { ...pageSharedList };
                    newPageSharedList.name = value;
                    setPageSharedList(newPageSharedList);
                  }}
                />
                <QCTextInput
                  label={t('sharedList.sharedLists.table.description')}
                  value={pageSharedList?.description}
                  dispatch={(value: string) => {
                    if (!pageSharedList) return;
                    const newPageSharedList: SharedList = { ...pageSharedList };
                    newPageSharedList.description = value;
                    setPageSharedList(newPageSharedList);
                  }}
                />
                <QCSwitchSelect
                  label={t('sharedList.sharedLists.table.type')}
                  text1={t('sharedList.designer.form.type.simple')}
                  text2={t('sharedList.designer.form.type.hierarchical')}
                  value1="simple"
                  value2="hierarchique"
                  dispatch={(value: string) =>
                    setSharedListType(() => {
                      if (value === 'simple') return SharedListType.Simple;
                      return SharedListType.Hierarchical;
                    })
                  }
                  option={sharedListType === SharedListType.Hierarchical ? 'hierarchique' : 'simple'}
                  variant="outlined"
                  disabled
                  sx={{
                    marginTop: '4.69px',
                    ' .innerBox': {
                      height: '56px',
                    },
                  }}
                />
                <QCSelect
                  label={t('common.internationalization.defaultLanguage')}
                  options={supportedLanguages.map((lg) => capitalize(lg.lang))}
                  value={languageValue}
                  defaultValue={languageValue}
                  setOption={(value: string) => {
                    const newLang = supportedLanguages.find((el) => capitalize(el.lang) === value);
                    if (newLang && pageSharedList) {
                      const newPageSharedList: SharedList = { ...pageSharedList };
                      newPageSharedList.defaultLanguage = newLang.value;
                      setPageSharedList(newPageSharedList);
                    }
                  }}
                />
              </Box>
            </Box>
            <SharedListElements />
          </Box>
          {openAddElementDialog && <AddElementDialog open={openAddElementDialog} setOpen={setOpenAddElementDialog} />}
        </Stack>
        <CreateSharedListDialog open={openAddSharedListDialog} setOpen={setOpenAddSharedListDialog} />
      </SharedListSettingsContext.Provider>
    </MenuWrapper>
  );
};

export default observer(SharedListSettings);
