import { v4 as uuidv4 } from 'uuid';
import { EntitySchemaAttribute } from '90.quickConnect.Models/siteAdmin/models/families/entitySchemaAttribute';

export const defaultFamilyAttribute = (length: number): EntitySchemaAttribute => ({
  id: uuidv4(),
  columnName: '',
  createdAt: new Date(),
  customerId: '',
  entityId: '',
  eTag: '',
  isRequired: false,
  propertyName: '',
  value: '',
  description: '',
  isSearchable: false,
  listOrder: length,
  name: '',
  sharedListId: undefined,
  type: 1,
});
