import { useContext, useEffect, useState } from 'react';
import { QCDialogFormContext } from '../../context';
import { BodyContext } from '../../StepBody/context';

const useData = (label: string, mandatory: boolean) => {
  const { addMandatoryField, setFilled, index } = useContext(BodyContext);
  const { allData, updateAllDataArray, addFieldAllDataArray } = useContext(QCDialogFormContext);

  const [files, setFiles] = useState<File[]>([]);

  useEffect(() => {
    addFieldAllDataArray(label, files, index);
  }, [files, addFieldAllDataArray, label, index]);

  useEffect(() => {
    if (allData[index - 1] && allData[index - 1].data) {
      const inputData = allData[index - 1].data;
      const valueIndex = inputData.findIndex((el) => el.label === label);
      if (valueIndex !== -1) {
        const { value } = inputData[valueIndex];
        if (value instanceof Array) setFiles(value);
        if (mandatory) {
          addMandatoryField({ label: label, filled: true });
        }
      }
    }
  }, [allData, addMandatoryField, index, label, mandatory]);

  const handleChange = (value: File[]) => {
    setFilled(label, true);
    updateAllDataArray(label, value, index);
    setFiles(value);
  };

  return {
    files,
    setFiles,
    handleChange,
  };
};

export default useData;
