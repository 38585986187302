import { action, makeAutoObservable, observable } from 'mobx';
import { Organization } from '10.quickConnect.app/siteAdmin/components/domains/Users/types';

class OrganizationalUnitStore {
  allOUList: Organization[];

  constructor() {
    this.allOUList = [];
    makeAutoObservable(this, { allOUList: observable, setallOUList: action }, { autoBind: true });
  }

  setallOUList = (list: Organization[]) => {
    this.allOUList = list;
  };
}

export default OrganizationalUnitStore;
