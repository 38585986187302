export const API_HOST = process.env.REACT_APP_QC_API_ENDPOINT;
export const API_ACCOUNT = `${API_HOST}Account/`;
export const API_SIGNIN = `${API_ACCOUNT}signin`;
export const API_VALIDATE_TOKEN = `${API_ACCOUNT}validateToken`;
export const API_SIGNOUT = `${API_ACCOUNT}signout`;

export const API_MOBILE = `${API_HOST}Mobile/`;
export const API_SYNCHRONIZE_REPOSITORY = `${API_MOBILE}synchronizerepository`;
export const API_SYNCHRONIZE_DECLARATION = `${API_MOBILE}synchronizedeclaration`;
export const API_SYNCHRONIZE_EQUIPMENT = `${API_MOBILE}synchronizeequipment`;

// Nouvelles API pour la création, la récupération et la duplication d'une déclaration
export const API_DECLARATION = `${API_HOST}Declaration/`;
export const API_DELETE_DECLARATION_DRAFTS = `${API_DECLARATION}deletedeclarationsdrafts`;

// Document
export const API_DOCUMENT = `${API_HOST}storage/`;
export const API_GET_DOCUMENT_INDEX = `${API_DOCUMENT}StoreArrayIndexes`;

// Form
export const API_FORM = `${API_HOST}form/`;
export const API_GET_FORM = `${API_FORM}getform`;

// Shared List
export const API_SHARED_LIST = `${API_HOST}sharedList/`;

// User Param
export const API_USER = `${API_HOST}user/`;
export const API_USER_PARAMS = `${API_USER}designuser`;

// Entities
export const API_GET_SEARCH_FIELD_VALUES = `${API_HOST}entities/datasource/getsearchfieldvalues`;

// AADQuery
export const API_GET_SEARCH_ADD_QUERY = `${API_HOST}AADQuery/searchuser`;

// Instrumentation
export const API_POST_INSTRUMENTATION = `${API_HOST}Instrumentation/saveinstrumentation`;

// Historique declarations
export const API_POST_HISTORIQUEDECLARATION = `${API_MOBILE}searchdeclarationformobile`;

// informations de l'applications
export const API_GET_API_INFORMATION = `${API_HOST}information`;

export const API_GET_TODO_LIST_TASKS = `${API_MOBILE}downloadtodolist`;

export const API_DEV_DISPATCHER = process.env.REACT_APP_QC_API_DEV_DISPATCHER;

export const API_PROD_DISPATCHER = process.env.REACT_APP_QC_API_PROD_DISPATCHER;
