import { Components, Theme } from '@mui/material/styles';
import { PaletteMode } from '@mui/material';
import {
  kraft500,
  dippedGreen500,
  slateGrey700,
  slateGrey300,
  ghostWhite,
  terraCotta600,
  slateGrey100,
  slateGrey900,
  kraft700,
  green5,
  green2,
  green0,
  green4,
  blackColor,
  green3,
  dippedGreen400,
  dippedGreen700,
  brown2,
  slateGrey400,
  slateGrey800,
  green1,
  red2,
  red1,
  grey2,
  pureWhiteColor,
  grey6,
  brown5,
  kraft400,
} from './colors';
import RubikVar from '00.app/fonts/Rubik/Rubik-VariableFont_wght.ttf';
import N27 from '00.app/fonts/N27/n27-regular-webfont.woff2';

/* eslint-disable-next-line */
export const getComponents = (muiMode: PaletteMode, theme: Theme): Components<Theme> =>
  ({
    MuiModal: {
      styleOverrides: {
        root: {
          maxWidth: '100vw',
        },
      },
    },
    MuiToolbar: {
      styleOverrides: {
        root: {
          height: '100%',
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          maxWidth: '100vw',
        },
      },
    },
    MuiCheckbox: {
      styleOverrides: {
        colorPrimary: {
          color: kraft500,
        },
        colorSecondary: {
          color: dippedGreen500,
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        colorPrimary: {
          color: kraft500,
        },
      },
    },
    MuiBottomNavigation: {
      styleOverrides: {
        root: {
          borderTop: theme.palette.mode === 'dark' ? `1px solid ${slateGrey700}` : `1px solid ${slateGrey300}`,
          height: '80px',
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paperAnchorRight: {
          borderLeft: `2px solid ${ghostWhite}`,
          boxShadow: 'inset 0 7px 9px -7px rgba(0,0,0,0.4)',
        },
      },
    },
    MuiFab: {
      styleOverrides: {
        root: {
          zIndex: 10,
        },
      },
    },
    MuiSvgIcon: {
      styleOverrides: {
        colorSuccess: {
          color: dippedGreen500,
        },
        colorError: {
          color: terraCotta600,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: muiMode === 'light' ? 'auto' : slateGrey100,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          width: '100%',
          marginLeft: 0,
          marginRight: 0,
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          borderColor: muiMode === 'light' ? kraft400 : kraft700,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: `
      @font-face {
        font-family: 'Rubik-var';
        font-style: normal;
        font-display: swap;
        src: url(${RubikVar}) format('ttf');
        font-weight: 100 900;
        unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
      }
      @font-face {
        font-family: 'N27-Regular';
        font-style: normal;
        font-display: swap;
        src: url(${N27}) format('woff2');
        font-weight: 100 900;
        unicodeRange: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF;
      }


      /* Chrome Scrollbar */

      ::-webkit-scrollbar {import { terraCotta600 } from './theme';

        -webkit-appearance: none;
       height: 4px;
        width: 2px;
      }
      ::-webkit-scrollbar-track-piece {
        background-color:  transparent;
      }
      *:hover::-webkit-scrollbar {
        width: 2px;
      }
      ::-webkit-scrollbar-thumb {
        background-clip: padding-box;
        background-color: ${grey2};
        border-radius: 9999px;
      }

      /* Buttons */
      ::-webkit-scrollbar-button:single-button {
        background-color: transparent;
      }
      /* Up */
      ::-webkit-scrollbar-button:single-button:vertical:decrement {
        background-color: transparent;
      }

      ::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
        background-color: transparent;
      }
      /* Down */
      ::-webkit-scrollbar-button:single-button:vertical:increment {
        background-color: transparent;
      }

      ::-webkit-scrollbar-button:vertical:single-button:increment:hover {
        border-color: transparent;
      }

      /* Firefox Scrollbar */
      
      scrollbar-width: thin;
      `,
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          border: 'none',
          backgroundColor: muiMode === 'light' ? pureWhiteColor : grey6,

          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: muiMode === 'light' ? brown2 : brown5,
          },

          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: muiMode === 'light' ? brown2 : brown5,
          },
        },
        notchedOutline: {
          border: '2px solid',
          borderColor: muiMode === 'light' ? brown2 : brown5,
        },
      },
    },
    QCCheckBoxButton: {
      default: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        width: 'fit-parent',
        height: '100%',
        my: '10px',
        gap: '4px',
        borderRadius: '8px',
        fontWeight: 'bold',
        borderWidth: '2px',
        '&:hover': {
          border: `2px solid ${green5}`,
          background: green2,
        },
      },
      variants: {
        checked: {
          background: muiMode === 'light' ? green0 : green4,
          border: `2px solid ${green4}`,
          color: blackColor,
        },
        notChecked: {
          background: muiMode === 'light' ? green0 : 'none',
          border: `2px solid ${dippedGreen500}`,
          color: green3,
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        list: {
          background: muiMode === 'light' ? ghostWhite : slateGrey900,
        },
      },
    },
    QCDatePicker: {
      variants: {
        checked: {
          display: 'flex',
          flexDirection: 'column',
          width: '222px',
          '& label': {
            display: 'flex',
            alignItems: 'center',
            textTransform: 'uppercase',
            marginBottom: '1vh',
            fontWeight: 'bold',
            color: muiMode === 'dark' ? dippedGreen400 : dippedGreen700,
          },
          '.MuiOutlinedInput-notchedOutline': {
            borderWidth: '2px',
            borderLeftWidth: '8px',
            borderColor: muiMode === 'dark' ? kraft700 : brown2,
            borderLeftColor: muiMode === 'dark' ? dippedGreen400 : dippedGreen500,
            width: 'fit-parent',
          },
          '& input:hover + fieldset': {
            borderWidth: '2px',
            borderLeftWidth: '8px',
            borderColor: muiMode === 'dark' ? kraft700 : brown2,
            borderLeftColor: muiMode === 'dark' ? dippedGreen400 : dippedGreen500,
            width: 'fit-parent',
          },
        },
        notChecked: {
          display: 'flex',
          flexDirection: 'column',
          width: '222px',
          '& label': {
            display: 'flex',
            alignItems: 'center',
            textTransform: 'uppercase',
            marginBottom: '1vh',
            fontWeight: 'bold',
            color: muiMode === 'dark' ? slateGrey400 : slateGrey800,
          },
          '.MuiOutlinedInput-notchedOutline': {
            border: `2px solid ${muiMode === 'dark' ? kraft700 : brown2}`,
            width: 'fit-parent',
          },
          '& input:hover + fieldset': {
            borderLeftWidth: 8,
            borderColor: kraft500,
          },
        },
      },
    }, //Il faut changer les couleurs et la taille de la bordure gauche
    QcCountCard: {
      variants: {
        complete: {
          display: 'flex',
          flexDirection: 'column',
          width: 'fit-content',
          height: 'fit-content',
          borderRadius: '7.80102px',
          background: muiMode === 'dark' ? green2 : green1,
          p: '10px',
        },
        incomplete: {
          display: 'flex',
          flexDirection: 'column',
          width: 'fit-content',
          height: 'fit-content',
          borderRadius: '7.80102px',
          background: muiMode === 'dark' ? red2 : red1,
          p: '10px',
        },
      },
    },
    MuiStepper: {
      styleOverrides: {
        root: {
          display: 'contents',
        },
      },
    },
    MuiStepContent: {
      styleOverrides: {
        root: {
          border: 'none',
        },
        transition: {},
      },
      defaultProps: {},
    },
    MuiStep: {
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          '&.Mui-selected': {
            color: `${theme.palette.text.primary} !important`,
          },
        },
      },
    },
  } as Components<Theme>);
