import { CreateSharedListRequest } from '../services/requests';
import { SharedListType } from '90.quickConnect.Models/siteAdmin/enums/sharedList/sharedListType';

export const defaultCreateSharedListRequest: CreateSharedListRequest = {
  name: '',
  defaultLanguage: 'fr',
  description: '',
  listType: SharedListType.Simple,
  SharedListItems: [],
  toBeTranslated: false,
};
