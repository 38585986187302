import { SetStateAction, useCallback, useEffect, useReducer, useState } from 'react';

import useClientsData from '../hooks';
import useClientSettingsServices from '../services/clientSettings';
import { UpdateClientRequest } from '../services/requests';
import { Limit } from './types';
import { saveConfirmationReducer } from '70.quickConnect.Components/siteAdmin/Other/SaveConfirmationDialog/type';
import { CustomerWithLimits, Feature } from '90.quickConnect.Models/siteAdmin/models/user/customer';
import { DeleteActionType } from '70.quickConnect.Components/siteAdmin/Other/DeleteConfirmationDialog/type';
import { useStore } from '30.quickConnect.Store/siteAdmin';
import { useSafeNavigation } from '80.quickConnect.core/siteAdmin/hooks/useSafeNavigation';

const useClientSettingsData = () => {
  const {
    SubMenuStore: {
      selectedRowAction,
      setSelectedRowAction,
      selectedRowsMultipleAction,
      setSelectedRowsMultipleAction,
    },
  } = useStore();
  const navigate = useSafeNavigation();
  const { getClientRequest, updateClientRequest, deleteClientRequest } = useClientSettingsServices();

  const [openSaveDialog, setOpenSaveDialog] = useState<boolean>(false);
  const [saveState, saveDispatch] = useReducer(saveConfirmationReducer, { isSaved: false });

  const [clientData, setClientData] = useState<CustomerWithLimits | undefined>(undefined);

  const handleChangeLimits = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, limit: Limit) => {
    setClientData((prevClientData) => {
      if (prevClientData) {
        const newClientData = { ...prevClientData };
        const index = newClientData.limits.findIndex((el) => el.id === limit.id);
        newClientData.limits[index].limitValue = Number(event.target.value);
        return newClientData;
      }
    });
  };

  const handleChangeFeatures = (value: SetStateAction<boolean>, feat: Feature) => {
    setClientData((prevClientData) => {
      if (prevClientData) {
        const newClientData = { ...prevClientData };
        const index = newClientData.customer.enabledFeatures.findIndex((el) => el === feat.id);
        if (value === true && index === -1) newClientData.customer.enabledFeatures.push(feat.id);
        if (value === false && index !== -1) {
          newClientData.customer.enabledFeatures = newClientData.customer.enabledFeatures.filter(
            (el) => el !== feat.id,
          );
        }
        return newClientData;
      }
    });
  };

  const getClient = useCallback(async () => {
    const ret = await getClientRequest();
    if (ret) setClientData({ customer: ret.customer, limits: ret.limits });
  }, [getClientRequest]);

  useEffect(() => {
    getClient();
  }, [getClient]);

  useEffect(() => {
    if (saveState.isSaved) {
      if (clientData) {
        const req = {
          id: clientData.customer.id,
          name: clientData.customer.name,
          eTag: clientData.customer.eTag,
          allFeaturesToEnable: clientData.customer.enabledFeatures,
          addInConfiguration: clientData.customer.addInConfiguration,
          limits: clientData.limits,
        } as UpdateClientRequest;
        updateClientRequest(req);
      }
    }
    setOpenSaveDialog(false);
    //eslint-disable-next-line
  }, [saveState]);

  const {
    openDeleteDialog,
    setOpenDeleteDialog,
    deleteState,
    deleteDispatch,
    isAddClientDialogOpen,
    setIsAddClientDialogOpen,
    handleConfirmAddClient,
    clients,
  } = useClientsData();

  const handleDeleteClient = useCallback(async () => {
    setOpenDeleteDialog(false);
    if (
      deleteState.choice === DeleteActionType.Delete &&
      selectedRowAction?.option === undefined &&
      selectedRowsMultipleAction?.option == undefined
    ) {
      if (clientData) {
        const res = await deleteClientRequest(clientData.customer.name);
        if (res === true) navigate('/clients');
      }
    }
    setSelectedRowAction(undefined);
    setSelectedRowsMultipleAction(undefined);
    // eslint-disable-next-line
  }, [deleteState]);

  useEffect(() => {
    handleDeleteClient();
  }, [handleDeleteClient]);

  return {
    openSaveDialog,
    setOpenSaveDialog,
    saveDispatch,
    openDeleteDialog,
    setOpenDeleteDialog,
    deleteDispatch,
    clientData,
    handleChangeFeatures,
    handleChangeLimits,
    isAddClientDialogOpen,
    setIsAddClientDialogOpen,
    handleConfirmAddClient,
    clients,
    selectedRowsMultipleAction,
  };
};

export default useClientSettingsData;
