import { ReactElement } from 'react';
import { IconProps } from '@mui/material';
import {
  AlertPreview,
  DeclarationPreview,
  FormPreview,
  JobPreview,
} from '10.quickConnect.app/siteAdmin/components/domains/Home/services/types';

export type WidgetType = 'activities' | 'forms' | 'declarations' | 'scans' | 'data' | 'importsexports' | 'alerts';

export const isWidgetType = (type: string): type is WidgetType => {
  return ['activities', 'forms', 'declarations', 'scans', 'data', 'importsexports', 'alerts'].includes(type);
};

export type WidgetData = {
  formsPreview: FormPreview[];
  declarationsPreview: DeclarationPreview[];
  jobsPreview: JobPreview[];
  alertsPreview: AlertPreview[];
};

export type HomeWidgetProps = {
  defaultType: WidgetType;
  widgetIndex: 0 | 1;
  data: WidgetData;
};

export type WidgetElement = {
  type: WidgetType;
  title: string;
  icon: ReactElement<IconProps>;
  link: string;
};

export type WidgetElementsType = {
  [type in WidgetType]: WidgetElement;
};
