import API_HOST from './apiHost';
import { ApiRoutesType } from './type';

const API_PROFILES = `${API_HOST}Profil/`;

const ApiProfiles: ApiRoutesType = {
  getProfil: `${API_PROFILES}getProfil`,
  getProfilList: `${API_PROFILES}getProfillist`,
  getProfilDependencies: `${API_PROFILES}getprofildependencies`,
  updateProfil: `${API_PROFILES}updateProfil`,
  createProfil: `${API_PROFILES}createProfil`,
  deleteProfil: `${API_PROFILES}deleteProfil`,
};

export default ApiProfiles;
