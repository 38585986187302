import { SxProps } from '@mui/material';

export const ActionButtonsContainer: SxProps = {
  position: { md: 'sticky' },
  right: '20px',
  bottom: '50px',
  display: 'flex',
  flexDirection: { md: 'column' },
  alignContent: 'center',
  marginBottom: { xs: '32px', md: '0' },
  gap: '8px',
};
