import styled from '@emotion/styled';
import { ToastContainer } from 'react-toastify';
import { dippedGreen500, terraCotta300 } from './theme/colors';

export const StyledContainer = styled(ToastContainer)`
  .Toastify__progress-bar--success {
    background: ${dippedGreen500};
  }
  --toastify-icon-color-success: ${dippedGreen500};
  .Toastify__progress-bar--error {
    background: ${terraCotta300};
  }
  --toastify-icon-color-error: ${terraCotta300};
`;
