import { TFunction } from 'react-i18next';
import { toast } from 'react-toastify';
import { Role } from '../../domains/Roles/types';
import { defaultTranslateEntity } from './const';
import { I18nEntity, RawEntity } from './types';
import { I18nEntityType } from './enum';
import { Family } from '90.quickConnect.Models/siteAdmin/models/families/family';
import { Form } from '90.quickConnect.Models/siteAdmin/models/forms/form';
import { SharedList } from '90.quickConnect.Models/siteAdmin/models/sharedList/sharedList';

const isRole = (entity: RawEntity): entity is Role => (entity as Role).isInternal !== undefined;
const isFamily = (entity: RawEntity): entity is Family => (entity as Family).attributes !== undefined;
const isForm = (entity: RawEntity): entity is Form => (entity as Form).formType !== undefined;
const isSharedList = (entity: RawEntity): entity is SharedList => (entity as SharedList).listType !== undefined;

export const createI18nEntity = (rawEntity: RawEntity | null): I18nEntity => {
  if (rawEntity === null) return defaultTranslateEntity;
  const i18nEntity = {
    id: '00000000-0000-0000-0000-000000000000',
    entityId: rawEntity.id,
    name: rawEntity.name,
    description: rawEntity.description,
    defaultLanguage: rawEntity.defaultLanguage,
    eTag: rawEntity.eTag,
  };
  if (isSharedList(rawEntity))
    return {
      ...i18nEntity,
      entityType: 1,
      fields: [],
      translate: [],
    };
  if (isFamily(rawEntity))
    return {
      ...i18nEntity,
      entityType: 2,
      fields: [],
      translate: [],
    };
  if (isRole(rawEntity))
    return {
      ...i18nEntity,
      entityType: 3,
      fields: [],
      translate: [],
    };
  if (isForm(rawEntity))
    return {
      ...i18nEntity,
      entityType: 4,
      fields: [],
      translate: [],
    };

  return defaultTranslateEntity;
};

export const getUrlParams = (t: TFunction) => {
  const params = /.*(?<entityType>roles|equipments\/entity|sharedlists|forms)\/(?<entityId>[a-zA-Z0-9-]+)\//gm.exec(
    window.location.pathname,
  )?.groups;

  if (params === undefined) {
    toast.error(t('webhooks.designer.form.errors.urlNotValid'));
    throw new Error(t('webhooks.designer.form.errors.urlNotValid'));
  }

  let entityType = I18nEntityType.None;
  switch (params.entityType) {
    case 'roles':
      entityType = I18nEntityType.Role;
      break;
    case 'equipments/entity':
      entityType = I18nEntityType.Family;
      break;
    case 'sharedlists':
      entityType = I18nEntityType.SharedList;
      break;
    case 'forms':
      entityType = I18nEntityType.Form;
      break;
  }

  const ret = {
    entityType: entityType,
    entityId: params.entityId,
  };

  return ret;
};

export const getOverTitle = (t: TFunction) => {
  const params = /.*(?<entityName>roles|equipments\/entity|sharedlists|forms)/gm.exec(window.location.pathname)?.groups;

  if (params === undefined) {
    toast.error(t('webhooks.designer.form.errors.urlNotValid'));
    throw new Error(t('webhooks.designer.form.errors.urlNotValid'));
  }
  switch (params.entityName) {
    case 'roles':
      return t('internationalization.entity.profile');
    case 'equipments/entity':
      return t('internationalization.entity.family');
    case 'sharedlists':
      return t('internationalization.entity.sharedlist');
    case 'forms':
      return t('internationalization.entity.form');
  }
};
