// Login
export const LOGIN = '/login';
export const LOGIN_ADAL = '/login/adal/';
export const HOME = '/';

// Menu
export const FORMS = '/forms';
export const DECLARATIONS = '/declarations';
export const SCAN = '/scan';
export const USERS = '/users';
export const EQUIPMENTS = '/equipments';
export const SHAREDLISTS = '/sharedlists';
export const TODOLISTS = '/todolists';
export const CATALOGS = '/catalogs';
export const SETTINGS = '/settings';
export const CONNECTIONS = '/connections';
export const ROLES = '/roles';
export const ROLE = `${ROLES}/:roleId`;
export const CLIENTS = '/clients';
export const VERSION = '/versions';

// Forms
export const NEWFORM = `${FORMS}/new`;
export const FILLFORM = `${FORMS}/fill`;
export const FORMCREATOR = `${FORMS}/creator`;

// Declarations
export const DECLARATION = `${DECLARATIONS}/:declarationId`;

// Scan
export const WEBCAMSCAN = `${SCAN}/webcam`;
export const BARCODESCAN = `${SCAN}/barcode`;

// User
export const ORGANIZATION = `${USERS}/organization/:organizationId`;
export const NEWORGA = `${USERS}/organization/new`;
export const ORGASETTINGS = `${ORGANIZATION}/settings`;
export const NEWUSER = `${USERS}/new`;
export const USERSETTINGS = `${USERS}/:userId/settings`;

// Equipments
export const ENTITY = `${EQUIPMENTS}/entity/:entityId`;
export const NEWENTITY = `${EQUIPMENTS}/entity/new`;
export const ENTITYSETTINGS = `${ENTITY}/settings`;
export const ENTITYTRANSLATION = `${ENTITY}/translation`;
export const NEWEQUIPMENT = `${EQUIPMENTS}/new`;
export const EQUIPMENTSETTINGS = `${EQUIPMENTS}/:entityId/:equipmentId/settings`;

// Shared Lists
export const SHAREDLIST = `${SHAREDLISTS}/:sharedListId`;
export const SHAREDLISTTRANSLATION = `${SHAREDLIST}/translation`;
export const NEWSHAREDLIST = `${SHAREDLISTS}/new`;

// ToDo Lists
export const TODOLIST = `${TODOLISTS}/:todolistId`;
export const NEWTODOLIST = `${TODOLISTS}/new`;

// Catalogs
export const CATALOG = `${CATALOGS}/:catalogId`;
export const NEWCATALOG = `${CATALOGS}/new`;

// Settings

// Roles
export const ROLETRANSLATION = `${ROLES}/:roleId/translation`;

// Clients
export const CLIENT = `${CLIENTS}/:clientId`;

// Versions
export const VERSIONS = `${VERSION}/:versionId`;

// Account
export const MYACCOUNT = '/myaccount';
export const CHANGEPASSWORD = `${MYACCOUNT}/changepassword`;

// Header
export const IMPORTSEXPORTS = '/importsandexports';
export const ALERTS = '/alerts';
export const ACTIVITIES = '/activities';

// Common
export const ERROR = '/error';

// Features
export const BIN = '/bin';
