import { useCallback, useEffect, useReducer, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { AxiosError } from 'axios';
import { toast } from 'react-toastify';
import { Role } from '../types';
import useRoleService from '../services/roles';
import {
  DeleteActionType,
  deleteConfirmationReducer,
} from '70.quickConnect.Components/siteAdmin/Other/DeleteConfirmationDialog/type';
import { saveConfirmationReducer } from '70.quickConnect.Components/siteAdmin/Other/SaveConfirmationDialog/type';
import useControls from '90.quickConnect.Models/siteAdmin/inputValidator/controls';
import { SupportedLanguage } from '90.quickConnect.Models/siteAdmin/models/internationalization/supportedLanguage';
import useEffectOnce from '80.quickConnect.core/shared/hooks/useEffectOnce';
import { useSafeNavigation } from '80.quickConnect.core/siteAdmin/hooks/useSafeNavigation';

const useRoleSettingsData = () => {
  const { t } = useTranslation();
  const { roleId } = useParams();
  const navigate = useSafeNavigation();

  const control = useControls();

  const { getRoleById, getSupportedLanguages, deleteRole, updateRole, getRoleDependencies } = useRoleService();

  const [pageRole, setPageRole] = useState<Role | null>(null);
  const [newPageRole, setNewPageRole] = useState<Role>();

  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const [saveState, saveDispatch] = useReducer(saveConfirmationReducer, { isSaved: false });

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [openDeleteDepDialog, setOpenDeleteDepDialog] = useState(false);
  const [deleteState, deleteDispatch] = useReducer(deleteConfirmationReducer, { choice: DeleteActionType.Cancel });
  const [deleteDepState, deleteDepDispatch] = useReducer(deleteConfirmationReducer, {
    choice: DeleteActionType.Cancel,
  });

  const [supportedLanguages, setSupportedLanguages] = useState<Array<SupportedLanguage>>([]);

  const initSuppLanguages = useCallback(async () => {
    const languages = await getSupportedLanguages();
    if (languages) {
      setSupportedLanguages(languages);
    }
    // eslint-disable-next-line
  }, []);

  useEffectOnce(() => {
    initSuppLanguages();
  });

  const initRole = useCallback(async () => {
    console.log(roleId);
    if (!roleId) {
      navigate(-1);
      return;
    }

    const role = await getRoleById({ id: roleId });
    if (role === null) navigate(-1);
    setPageRole(role.profil);
    setNewPageRole(role.profil);
    //eslint-disable-next-line
  }, [roleId]);

  useEffect(() => {
    initRole();
  }, [initRole]);

  useEffect(() => {
    switch (deleteState.choice) {
      case DeleteActionType.Delete:
        const post = async () => {
          try {
            await deleteRole({ ids: [pageRole?.id ?? ''], forceDeletion: false });
            navigate(-1);
          } catch (error) {
            const axiosError = error as AxiosError;
            console.log(axiosError);
            if (axiosError.response?.status === 422) setOpenDeleteDepDialog(true);
          }
        };

        post();
        setOpenDeleteDialog(false);
        break;
      default:
        setOpenDeleteDialog(false);
    }
    // eslint-disable-next-line
  }, [deleteState]);

  useEffect(() => {
    switch (deleteDepState.choice) {
      case DeleteActionType.Delete:
        deleteRole({ ids: [pageRole?.id ?? ''], forceDeletion: true });
        setOpenDeleteDepDialog(false);
        navigate(-1);
        break;
      default:
        setOpenDeleteDepDialog(false);
    }
    // eslint-disable-next-line
  }, [deleteDepState]);

  useEffect(() => {
    switch (saveState.isSaved) {
      case true:
        setOpenSaveDialog(false);
        if (newPageRole) {
          updateRole(newPageRole);
        }
        break;
      case false:
        setOpenSaveDialog(false);
        break;
    }
    //eslint-disable-next-line
  }, [saveState]);

  const handleDelete = () => {
    setOpenDeleteDialog(true);
  };

  const handleConfirm = () => {
    setOpenSaveDialog(() => {
      let error = false;
      if (newPageRole?.name === '') {
        toast.error(t('control.input.notnull.field', { field: t('profil.designer.placeholder.name') }));
        error = true;
      }
      if (newPageRole?.description === '') {
        toast.error(t('control.input.notnull.field', { field: t('profil.designer.placeholder.description') }));
        error = true;
      }
      return !error;
    });
  };

  const getRoleDependenciesFile = () => {
    getRoleDependencies({ profilId: pageRole?.id ?? '' });
  };

  return {
    pageRole,
    setPageRole,
    newPageRole,
    setNewPageRole,
    supportedLanguages,
    handleConfirm,
    handleDelete,
    openDeleteDialog,
    openDeleteDepDialog,
    deleteDispatch,
    deleteDepDispatch,
    openSaveDialog,
    saveDispatch,
    control,
    getRoleDependenciesFile,
  };
};

export default useRoleSettingsData;
