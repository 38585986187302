import { BaseResponse } from '40.quickConnect.DataAcces/siteAdmin/services/baseResponses';

export type GetVersionsResponse = BaseResponse & {
  parameters: Version[];
  count: number;
  totalCount: number;
  isThereMoreItems: boolean;
  researchToken: string | null;
  errorCode: number | null;
};

export type Version = {
  name: string;
  description: string;
  paramValue: string;
  lastUpdate: string;
  companionQCId: string;
  id: string;
  eTag: string;
};

export type CreateVersionResponse = {
  parameter: Version;
  status: number;
  message: string | null;
  errorCode: number | null;
};

export type UpdateVersionResponse = {
  parameter: Version;
  status: number;
  message: string | null;
  errorCode: number | null;
};

export type GetVersionByIdResponse = {
  parameter: Version;
  status: number;
  message: string | null;
  errorCode: number | null;
};

export type DeleteVersionResponse = {
  result: Version[];
  status: number;
  message: string | null;
  errorCode: number | null;
};

export type GetCompagnonsResponse = BaseResponse & {
  data: Compagnon[];
  count: number;
  totalCount: number;
  isThereMoreItems: boolean;
  researchToken: string | null;
  errorCode: number | null;
};

export type Compagnon = {
  customerId: string;
  name: string;
  qcId: string;
  eTag: string;
  id: string;
  createdAt: string;
  updatedAt: string | null;
};

export const defaultVersion: Version = {
  name: '',
  id: '',
  description: '',
  companionQCId: '',
  eTag: '',
  lastUpdate: '',
  paramValue: '',
};
