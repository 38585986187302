import { RefObject, useCallback, useEffect, useState } from 'react';
import useEffectOnce from '80.quickConnect.core/shared/hooks/useEffectOnce';

export const useProgressBarData = (targetRef: RefObject<HTMLDivElement>) => {
  const [readingStep, setReadingStep] = useState<number>(0);

  const scrollListener = useCallback(() => {
    if (!targetRef.current) return;
    let tempStep = 0;
    const totalLength = targetRef.current.children.length;
    for (let i = 0; i < totalLength; i++) {
      const element = targetRef.current.children.item(i);
      if (element && element.getBoundingClientRect().top < targetRef.current.getBoundingClientRect().bottom)
        tempStep += 1;
      else break;
    }
    return setReadingStep(tempStep);
  }, [targetRef]);

  useEffect(() => {
    const element = targetRef.current;
    element?.addEventListener('scroll', scrollListener);
    return () => element?.removeEventListener('scroll', scrollListener);
  }, [targetRef, scrollListener]);

  useEffectOnce(() => {
    setTimeout(() => {
      scrollListener();
    }, 300);
  });
  return {
    readingStep,
  };
};
