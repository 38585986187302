import React from 'react';
import { Box } from '@mui/material';
// import { SvgIcon } from '@mui/material';
// import { ReactComponent as SquareSvg } from '../contentHeaderSquare.svg';
import { TitlesContainerStyle } from '../style';
// import { SquareSvgStyle } from '../style';
import { TitleGroupProps } from './type';

const TitlesGroup = ({ children }: TitleGroupProps) => {
  return (
    <Box sx={TitlesContainerStyle}>
      {/* <SvgIcon component={SquareSvg} inheritViewBox sx={SquareSvgStyle} /> */}
      {children}
    </Box>
  );
};

export default TitlesGroup;
