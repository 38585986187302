import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import useSharedListsServices from '../../SharedLists/services';
import {
  CreateFamilyRequest,
  DeleteFamilyRequest,
  GetFamiliesRequest,
  GetFamilyRequest,
  GetWebhooksRequest,
  UpdateFamilyRequest,
} from './requests';
import {
  CreateFamilyResponse,
  DeleteFamilyResponse,
  GetFamiliesResponse,
  GetFamilyResponse,
  GetSupportedLanguagesResponse,
  GetWebhooksResponse,
  UpdateFamilyResponse,
} from './response';
import { ApiFamily } from '40.quickConnect.DataAcces/siteAdmin/apiRoutes/family';
import QCHttpRequest from '40.quickConnect.DataAcces/siteAdmin/QCHttpRequest';
import { isResponseValid } from '40.quickConnect.DataAcces/siteAdmin/responseValidator';
import { ApiInternationalization } from '40.quickConnect.DataAcces/siteAdmin/apiRoutes/internationalization';
import { SupportedLanguage } from '90.quickConnect.Models/siteAdmin/models/internationalization/supportedLanguage';
import { ApiWebhook } from '40.quickConnect.DataAcces/siteAdmin/apiRoutes/webhook';

export const useRessourcesServices = () => {
  const { t } = useTranslation();
  const httpRequest = new QCHttpRequest();
  const getFamilies = async (request: GetFamiliesRequest) => {
    try {
      const response = await httpRequest.post<GetFamiliesResponse, GetFamiliesRequest>(
        ApiFamily.getAllSchemas,
        request,
      );

      if (isResponseValid(response.data)) return response.data.data;
    } catch (error) {
      if (axios.isAxiosError(error)) toast.error(error.response?.data.message);
    }
  };

  const getFamily = async (request: GetFamilyRequest) => {
    try {
      const response = await httpRequest.post<GetFamilyResponse, GetFamilyRequest>(ApiFamily.getSchema, request);

      if (isResponseValid(response.data)) return response.data.schema;
    } catch (error) {
      if (axios.isAxiosError(error)) toast.error(error.response?.data.message);
    }
  };

  const getSupportedLanguages = async (): Promise<SupportedLanguage[] | undefined> => {
    try {
      const response = await httpRequest.get<GetSupportedLanguagesResponse>(ApiInternationalization.getInfos);

      return Object.entries(response.data.infos.SupportedLanguages).map(([lg, val]) => ({
        lang: lg,
        value: val,
      }));
    } catch (error) {
      if (axios.isAxiosError(error)) toast.error(error.response?.data.message);
    }
  };

  const createFamily = async (request: CreateFamilyRequest) => {
    try {
      const response = await httpRequest.post<CreateFamilyResponse, CreateFamilyRequest>(
        ApiFamily.createNewSchema,
        request,
      );

      if (
        isResponseValid(
          response.data,
          t('entitySchema.entityDesigner.popup.saveEntity.saveSuccess', {
            actionName: t('entitySchema.entityDesigner.popup.saveEntity.create'),
          }),
        )
      ) {
        return response.data;
      }
    } catch (error) {
      if (axios.isAxiosError(error)) toast.error(error.response?.data.message);
    }
  };

  const updateFamily = async (request: UpdateFamilyRequest) => {
    try {
      const response = await httpRequest.post<UpdateFamilyResponse, UpdateFamilyRequest>(
        ApiFamily.updateSchema,
        request,
      );

      const isValid = isResponseValid(
        response.data,
        t('entitySchema.entityDesigner.popup.saveEntity.saveSuccess', {
          actionName: t('entitySchema.entityDesigner.popup.saveEntity.update'),
        }),
        t,
      );
      if (isValid) return response.data.schema;
    } catch (error) {
      if (axios.isAxiosError(error)) toast.error(error.response?.data.message);
    }
  };

  const deleteFamily = async (request: DeleteFamilyRequest) => {
    try {
      const response = await httpRequest.post<DeleteFamilyResponse, DeleteFamilyRequest>(
        ApiFamily.deleteSchema,
        request,
      );

      if (response.status === 200 && isResponseValid(response.data)) {
        toast.success(
          t('entitySchema.entityDesigner.popup.saveEntity.saveSuccess', {
            actionName: t('entitySchema.entityDesigner.popup.deleteEntitySchema.title'),
          }),
        );
      }
    } catch (error) {
      if (axios.isAxiosError(error)) toast.error(error.response?.data.message);
    }
  };

  // pour la modification d'attribut
  const { getSharedLists } = useSharedListsServices();

  // pour les paramètres webhooks
  const getWebhooks = async (request: GetWebhooksRequest) => {
    try {
      const response = await httpRequest.post<GetWebhooksResponse, GetWebhooksRequest>(ApiWebhook.getWebhooks, request);

      if (isResponseValid(response.data)) return response.data;
    } catch (error) {
      if (axios.isAxiosError(error)) toast.error(error.response?.data.message);
    }
  };

  return {
    getFamilies,
    getFamily,
    getSupportedLanguages,
    createFamily,
    updateFamily,
    deleteFamily,
    getSharedLists,
    getWebhooks,
  };
};
