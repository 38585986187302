import React from 'react';
import { Box, Divider, List, Stack } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import SettingsIcon from '@mui/icons-material/SettingsOutlined';
import { observer } from 'mobx-react';
import AddWebhookDialog from './AddWebhookDialog';
import useWebhooksCard from './hooks';
import { WebhookListStyle, WebhooksCardStyle } from './styles';
import WebhookListItem from './WebhookListItem';
import QCIconButton from '70.quickConnect.Components/siteAdmin/Buttons/QCIconButton';

const WebhooksCard = () => {
  const {
    t,
    theme,
    webhooks,
    familyWebhookIds,
    openAddWebhookDialog,
    setOpenAddWebhookDialog,
    deleteWebhook,
    handleWebhookSettingsClick,
  } = useWebhooksCard();
  return (
    <Box sx={WebhooksCardStyle(theme)}>
      <Stack
        sx={{ flexDirection: 'row', alignContent: 'center', justifyContent: 'space-between', '>h2': { marginY: 0 } }}
      >
        <h2>{t('entitySchema.entityDesigner.settings.webhooks')}</h2>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <QCIconButton
            Icon={<SettingsIcon />}
            onClickFunc={handleWebhookSettingsClick}
            variantValue="outlined"
            sx={{ color: theme.palette.primary.main, backgroundColor: theme.palette.background.default }}
          />
          <QCIconButton
            Icon={<AddIcon />}
            onClickFunc={() => setOpenAddWebhookDialog(true)}
            variantValue="outlined"
            sx={{ color: theme.palette.primary.main, backgroundColor: theme.palette.background.default }}
          />
        </Box>
      </Stack>
      <List sx={WebhookListStyle}>
        {webhooks.map((webhook) => {
          if (familyWebhookIds.includes(webhook.id))
            return (
              <div key={webhook.id}>
                <WebhookListItem webhook={webhook} onDelete={deleteWebhook} />
                <Divider variant="inset" component="li" sx={{ borderColor: theme.palette.menuBorder.main }} />
              </div>
            );
          return <div key={webhook.id}></div>;
        })}
      </List>

      {openAddWebhookDialog && <AddWebhookDialog onClose={() => setOpenAddWebhookDialog(false)} />}
    </Box>
  );
};

export default observer(WebhooksCard);
