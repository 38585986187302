import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Checkbox, Dialog, IconButton, List, ListItem } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { observer } from 'mobx-react';
import { useFamilySettingsStore } from '../../FamilySettingsStore';
// { initialOpen }: { initialOpen: boolean }
const AddWebhookDialog = ({ onClose }: { onClose: () => void }) => {
  const { t } = useTranslation();
  const { webhooks, familyWebhookIds, setFamilyWebhookIds } = useFamilySettingsStore();
  const [openDialog, setOpenDialog] = useState(true);

  const handleCheckboxClick = (webhookId: string) => {
    const webhookIndex = familyWebhookIds.findIndex((id) => id === webhookId);
    const newIds = Array.from(familyWebhookIds);

    if (webhookIndex !== -1) {
      newIds.splice(webhookIndex, 1);
    } else {
      newIds.push(webhookId);
    }

    setFamilyWebhookIds(newIds);
  };

  const onDialogClose = () => {
    setOpenDialog(false);
    onClose();
  };

  return (
    <Dialog open={openDialog}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginX: '1.2vw' }}>
        <h2>{t('webhooks.viewer.form.actions.addWebhook')}</h2>
        <IconButton sx={{ padding: '2px' }} onClick={onDialogClose}>
          <CloseIcon />
        </IconButton>
      </Box>
      <List sx={{ marginX: '1.5vw' }}>
        {webhooks.map((webhook) => (
          <ListItem key={webhook.id} sx={{ paddingX: 0 }}>
            <label>
              <Checkbox
                checked={familyWebhookIds.includes(webhook.id)}
                onClick={() => handleCheckboxClick(webhook.id)}
              />
              {webhook.name}
            </label>
          </ListItem>
        ))}
      </List>
    </Dialog>
  );
};

export default observer(AddWebhookDialog);
