import React from 'react';
import { useTranslation } from 'react-i18next';
import useRoleData from '../hooks';
import { AddRoleFormProps } from './types';
import QCDialogForm from '70.quickConnect.Components/siteAdmin/Other/QCDialogForm';
import { capitalize } from '80.quickConnect.core/shared/helpers/capitalize';

const AddRoleForm = ({ open, handleConfirm, toggleIsOpen }: AddRoleFormProps) => {
  const { t } = useTranslation();
  const { supportedLanguages } = useRoleData();

  return (
    <QCDialogForm open={open} handleConfirm={handleConfirm} toggleIsOpen={toggleIsOpen}>
      <QCDialogForm.Body desc={t('profil.designer.title')} index={1} nbColumn={1} displayStepping>
        <QCDialogForm.Text label={t('profil.designer.placeholder.name')} mandatory />
        <QCDialogForm.TextArea label={t('profil.designer.placeholder.description')} mandatory />
        {/* <QCDialogForm.TreeViewChoice label={t('common.internationalization.defaultLanguage')} /> */}
        <QCDialogForm.Choice
          label={t('common.internationalization.defaultLanguage')}
          options={supportedLanguages.map((lang) => capitalize(lang.lang))}
          emptyOption={false}
          mandatory
        />
      </QCDialogForm.Body>
    </QCDialogForm>
  );
};

export default AddRoleForm;
