import { SxProps } from '@mui/system';
import { brown4, green1 } from '00.app/theme/colors';

export const dialogPaperStyle = (column = 1) => {
  return {
    width: { sm: '480px', md: '70vw' },
    maxWidth: `${(600 * column).toString()}px`,
    height: 'fit-content',

    backgroundColor: 'background',

    border: '2px solid #E3F2F0',
    borderRadius: '16px',

    transition: 'all .4s linear',
  } as SxProps;
};

export const dialogFormHeaderStyle: SxProps = () => ({
  width: 'fit-parent',
  display: 'grid',
  gridTemplateColumns: '1fr 10fr 1fr',
  gridTemplateRows: '1fr',
  gap: '0px',
  marginY: '5px',
  paddingBottom: '10px',
  borderBottom: `1px solid ${green1}`,

  '& .MuiPaper-root-MuiDialog-paper': {
    borderWidth: 0,
    boxShadow: 'none',
  },
});

export const stepContentStyle: SxProps = () => ({
  width: '100%',
  height: '100%',
  display: 'flex',
  margin: 0,
  padding: 0,
  flexDirection: 'column',
  alignItems: 'center',
  '& .MuiCollapse-root': {
    width: '100%',
  },
});

export const dialogFormBodyStyle = (column: number) => {
  return {
    display: 'grid',
    gridTemplateColumns: `repeat(${column}, 1fr)`,
    columnGap: '30px',
    flexDirection: 'column',
    marginTop: '15px',
    marginBottom: '25px',
    paddingX: '40px',
    maxHeight: '280px',
    height: '280px',
    overflowY: 'scroll',
    scrollbarWidth: 'thin',
  } as SxProps;
};

export const dialogFormFooterStyle: SxProps = () => ({
  display: 'flex',
  width: '100%',
  paddingX: '40px',
  marginBottom: '20px',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  color: brown4,
});
