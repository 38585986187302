import { useCallback, useEffect, useState } from 'react';
import { Organization } from '../../Users/types';
import useResourcesServices from '../services/resources';
import { EntitySchemaAttribute } from '90.quickConnect.Models/siteAdmin/models/families/entitySchemaAttribute';
import { SharedList } from '90.quickConnect.Models/siteAdmin/models/sharedList/sharedList';

const useData = (entitySchemaId: string, open: boolean) => {
  const { getDesignResourceData } = useResourcesServices();
  const [optionsOrgs, setOptionsOrgs] = useState<Organization[]>([]);
  const [schemaAttributs, setSchemaAttributs] = useState<EntitySchemaAttribute[]>([]);
  const [sharedLists, setSharedLists] = useState<SharedList[]>([]);

  const getDesignData = useCallback(async () => {
    const result = await getDesignResourceData({ entitySchemaId: entitySchemaId });
    if (result) {
      setOptionsOrgs(
        result.availableOrganizationalUnits.map((org) => {
          if (org.code) org.level = org.code?.split('\\').length - 1;
          return org;
        }),
      );
      setSchemaAttributs(result.schema.attributes);
      setSharedLists(result.sharedLists);
    }
  }, [getDesignResourceData, entitySchemaId]);

  useEffect(() => {
    if (open) getDesignData();
  }, [getDesignData, open]);

  return {
    optionsOrgs,
    schemaAttributs,
    sharedLists,
  };
};

export default useData;
