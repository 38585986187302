import { grey3, slateGrey700 } from '00.app/theme/colors';

export const outerBoxStyle = {
  display: 'flex',
  flexDirection: 'column',
};

export const innerBoxStyle = {
  display: 'flex',
  gap: 1,
  // justifyContent: 'center',
  alignItems: 'center',
};

export const typographyPrimary = {
  color: slateGrey700,
  whiteSpace: 'normal',
  wordBreak: 'break-all',
};

export const typographySecondary = {
  color: grey3,
  whiteSpace: 'normal',
  wordBreak: 'break-all',
};
