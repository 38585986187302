import { UseDataProps } from './types';
import { useStore } from '30.quickConnect.Store/siteAdmin';

export const useData = (): UseDataProps => {
  const {
    SideMenuStore: { focusedBtn, setFocusedBtn },
    UserSettingsStore: { role, subscribedFeatures },
  } = useStore();

  return {
    focusedBtn,
    setFocusedBtn,
    role,
    subscribedFeatures,
  };
};
