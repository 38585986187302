import React from 'react';
import ListItem from '@mui/material/ListItem';
import { Draggable } from '@hello-pangea/dnd';
import { DraggableListItemProps } from './type';

const DraggableListItem = <P,>({ renderComponent, item, index }: DraggableListItemProps<P>) => {
  return (
    <Draggable draggableId={item.id} index={index}>
      {(provided /*, snapshot*/) => (
        <ListItem
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          sx={{ paddingX: 0 }}
        >
          {/* <Component {...item.props} /> */}
          {renderComponent(item)}
        </ListItem>
      )}
    </Draggable>
  );
};

export default DraggableListItem;
