import React from 'react';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { IconButton, styled } from '@mui/material';
import { FabCorbeilleProps } from './types';

const QCFabCorbeilleSkeleton = ({ onClickFunc, className }: FabCorbeilleProps) => {
  return (
    <IconButton data-cy="bin" className={className} onClick={(e) => onClickFunc(e)}>
      <DeleteOutlineIcon />
    </IconButton>
  );
};

/**
 * Un bouton pour supprimer des éléments.
 * @component
 * @param {function} onClickFunc - Fonction déclenchée quand le bouton est cliqué.
 * @param {string | undefined} [className] - Nom de la classification
 * @param {'btn-fab' | 'btn-icon' | undefined} [buttonType] - La variante de bouton
 * @example <caption>Exemple QCFabCorbeille</caption>
 * const handleClick = (event) => {...}
 * <QCFabCorbeille onClickFunc={handleClick} buttonType='btn-fab' className='btn-fab-corbeille' />
 * <QCFabCorbeille onClickFunc={handleClick} buttonType='btn-icon' className='btn-icon-corbeille' />
 */
const QCFabCorbeille = styled(QCFabCorbeilleSkeleton)(({ theme, buttonType }) => {
  const { palette } = theme;
  const iconBinButtonColor = palette.iconBinButtonColor.main;
  switch (buttonType) {
    case 'btn-icon':
      return {
        color: iconBinButtonColor,
        height: 'fit-content',
        width: 'fit-content',
      };
    case 'btn-fab':
    default:
      return {
        boxSizing: 'border-box',
        border: `2px solid ${iconBinButtonColor}`,
        borderRadius: '8px',
        height: 'fit-content',
        width: 'fit-content',
        color: iconBinButtonColor,
      };
  }
});

export default QCFabCorbeille;
