import React from 'react';
import {
  FORMCREATOR,
  FORMS,
  NEWEQUIPMENT,
  NEWFORM,
  NEWSHAREDLIST,
  SHAREDLIST,
  SHAREDLISTS,
  SHAREDLISTTRANSLATION,
} from '../paths';
import { LinkRouteType } from '../types';
import ANALYST_ROUTES from './analystRoutes';
import ComingSoon from './comingSoon';
import SharedListsHome from '10.quickConnect.app/siteAdmin/components/domains/SharedLists/Home';
import SharedListSettings from '10.quickConnect.app/siteAdmin/components/domains/SharedLists/SharedListSettings';
import TestSubMenu from '10.quickConnect.app/siteAdmin/components/domains/TestSousMenu';
import TraductionPage from '10.quickConnect.app/siteAdmin/components/shared/TraductionPage';

const DESIGNERINTER_ROUTES: LinkRouteType[] = [
  // A compléter quand les composants seront créés
  ...ANALYST_ROUTES,

  { path: FORMS, key: 'FORMS', element: <TestSubMenu /> },
  { path: NEWFORM, key: 'NEWFORM', element: <ComingSoon /> },
  { path: FORMCREATOR, key: 'FORMCREATOR', element: <ComingSoon /> },

  { path: SHAREDLISTS, key: 'SHAREDLISTS', element: <SharedListsHome /> },
  { path: SHAREDLIST, key: 'SHAREDLIST', element: <SharedListSettings /> },
  { path: NEWSHAREDLIST, key: 'NEWSHAREDLIST', element: <ComingSoon /> },
  { path: SHAREDLISTTRANSLATION, key: 'SHAREDLISTTRANSLATION', element: <TraductionPage /> },

  { path: NEWEQUIPMENT, key: 'NEWEQUIPMENT', element: <ComingSoon /> },

  //PEUT EDIT : EQUIPMENTSETTINGS, TODOLIST, et TRANSLATION
];

export default DESIGNERINTER_ROUTES;
