import { IconButton } from '@mui/material';
import React, { useCallback, useEffect, useState, Dispatch, SetStateAction, useContext } from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { ConnectionHistoryContext } from '10.quickConnect.app/siteAdmin/components/domains/ConnectionHistory/context';

const ExpandButtonCell = ({
  params,
  state,
  dispatch,
}: {
  params: GridRenderCellParams;
  state: boolean;
  dispatch: Dispatch<SetStateAction<boolean>>;
}) => {
  const [isDown, setIsDown] = useState(state);

  const { selectedRowId, setSelectedRowId } = useContext(ConnectionHistoryContext);

  const update = useCallback(() => {
    setIsDown(!isDown);
    dispatch(!isDown);
    setSelectedRowId(selectedRowId !== params.row.id ? params.row.id : -1);
  }, [setIsDown, dispatch, isDown, params.row.id, selectedRowId, setSelectedRowId]);

  useEffect(() => {
    setIsDown(selectedRowId !== params.row.id);
  }, [selectedRowId, setIsDown, params.row.id]);

  // const handleCellClick: GridEventListener<'cellClick'> = (
  //   eventParams: GridCellParams,
  //   event: MuiEvent<React.MouseEvent>,
  // ) => {
  //   event.defaultMuiPrevented = true;
  // };

  // const handleButtonClick = useCallback(() => {
  //   setIsDown((curr) => {
  //     dispatch(!curr);
  //     return !curr;
  //   });
  // }, [dispatch, setIsDown, isDown]);

  // // useEffect(() => {
  // //   console.log(isDown);
  // // }, [isDown]);

  // const apiRef = useGridApiContext();

  // useGridApiEventHandler(apiRef, 'cellClick', handleCellClick);
  return (
    <>
      <IconButton onClick={update}>{isDown ? <ExpandMoreIcon /> : <ExpandLessIcon />}</IconButton>
    </>
  );
};

export default ExpandButtonCell;
